import React from "react";
import { Box, Typography } from "@mui/material";
import { default as ConversationIcon } from "../../../assets/icons/conversation";

export default function NoConversationsFound() {
	return (
		<React.Fragment>
			<Box
				sx={{
					display: "flex",
				}}
			>
				{ConversationIcon(37, 37, "#666666")}
			</Box>
			<Typography
				fontSize={17}
				fontWeight={400}
				color="rgba(0, 0, 0, 0.6)"
			>
				No Conversations Found
			</Typography>
		</React.Fragment>
	);
}
