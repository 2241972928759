import React, { useEffect, useState } from "react";
import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import { FormFieldName, MenuItemText } from "../../../../../../styles/twozo";
import MenuSearchBar from "../../../../../Elements/MenuSearchBar";
import { CircularProgress, Skeleton } from "@mui/material";
import { useLocalityList } from "../../../../../../hooks/services/phone";
import { useLastRowRef } from "../../../../../../hooks/common/InfiniteScroll";
import { useDebouncedValue } from "../../../../../../hooks/debounce";
const fieldName = "locality";

export default function LocalityMenu(props) {
	const { value, onChange, dependentFieldsConfig } = props;

	const [searchedValue, setSearchedValue] = useState("");
	const [isMenuListOpened, setIsMenuListOpened] = useState(false);

	const debouncedSearchValue = useDebouncedValue(searchedValue);

	const {
		data: localityList,
		isLoading: isLoadingLocalityList,
		isFetching: isFetchingLocalityList,
		hasNextPage: hasNextLocalityListPage,
		fetchNextPage: fetchNextLocalityListPage,
	} = useLocalityList(
		dependentFieldsConfig?.countryId,
		dependentFieldsConfig?.stateId,
		debouncedSearchValue
	);

	useEffect(() => {
		if (Array.isArray(localityList) && localityList.length > 0) {
			const checkIfTheValueMatches = localityList.some(
				(locality) => locality.id === value?.id
			);

			if (!searchedValue && (!value || !checkIfTheValueMatches)) {
				const defaultStateValue = localityList.find(
					(locality) => locality?.isDefault
				);
				onChange(defaultStateValue, fieldName);
			}
		}
	}, [onChange, value, searchedValue, localityList]);

	const lastOptionRef = useLastRowRef(
		fetchNextLocalityListPage,
		hasNextLocalityListPage,
		isFetchingLocalityList
	);

	const handleSearchValue = (event) => {
		const { value } = event.target;
		setSearchedValue(value);
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					{searchedValue && localityList?.length === 0
						? "No Results Found"
						: "No Options Found"}
				</Typography>
			</Stack>
		);
	};

	const openMenuList = () => {
		setIsMenuListOpened(true);
		setSearchedValue("");
	};

	const closeMenuList = () => {
		setIsMenuListOpened(false);
	};

	const handleSelectLocality = (selectedLocality) => {
		onChange(selectedLocality, fieldName);
		closeMenuList();
	};

	return (
		<React.Fragment>
			<Stack spacing={0.5}>
				<FormFieldName>Locality</FormFieldName>

				<Select
					size="small"
					fullWidth
					MenuProps={{
						autoFocus: false,
						PaperProps: {
							style: {
								marginTop: "4px",
								borderRadius: "8px",
								maxHeight: "290px",
								maxWidth: "400px",
							},
						},
					}}
					open={isMenuListOpened}
					onClose={closeMenuList}
					onOpen={openMenuList}
					displayEmpty
					renderValue={() => (
						<Typography
							fontSize={14}
							noWrap
							color={value?.name ? "#000" : "rgba(0, 0, 0, 0.6)"}
						>
							{value?.name ?? "Select"}
						</Typography>
					)}
				>
					<Box
						p={0.5}
						marginTop="-8px"
						sx={{
							position: "sticky",
							top: 0,
							zIndex: 1,
							backgroundColor: "#fff",
						}}
					>
						<MenuSearchBar
							value={searchedValue}
							onChange={handleSearchValue}
						/>
					</Box>

					{isLoadingLocalityList &&
					dependentFieldsConfig?.countryId ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="150px"
						>
							<CircularProgress size="25px" />
						</Stack>
					) : localityList?.length > 0 ? (
						localityList.map((locality, index) => (
							<MenuItem
								key={index}
								style={{
									height: "40px",
								}}
								onClick={() => handleSelectLocality(locality)}
							>
								<MenuItemText whiteSpace="wrap">
									{locality.name}
								</MenuItemText>
							</MenuItem>
						))
					) : (
						renderEmptyView()
					)}

					<Box ref={lastOptionRef}>
						{!isLoadingLocalityList &&
							isFetchingLocalityList &&
							localityList?.length > 0 && (
								<MenuItem style={{ height: "40px" }}>
									<Skeleton width="190px" height="16px" />
								</MenuItem>
							)}
					</Box>
				</Select>
			</Stack>
		</React.Fragment>
	);
}
