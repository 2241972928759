import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	IconButton,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { default as AttachmentIcon } from "../../../../assets/icons/attachment";
import Subject from "../../EmailComposer/EmailComposerUI/Subject";
import TemplateInput from "./TemplateInput";
import TemplateTagsInput from "./TemplateTagsInput";
import { useTemplateTagsDropdownList } from "../../../../hooks/services/mail/templateTags";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes } from "@lexical/html";
import { parseHTML } from "../../../../utils/EmailUtils/parseHTML";
import { twozoStyles } from "../../../../styles/twozo";
import { usePlaceHolderFields } from "../../../../hooks/services/mail/emailTemplate";
import { modules } from "../../../../utils/common/ModulesName";
import AddFile from "../../../Summary/ShortFiles/AddFile";
import { getFormattedFileSize } from "../../../../utils/fileUtills";

export default function AddTemplate(props) {
	const {
		onCloseEmailTemplateDrawer,
		onSaveEmailTemplate,
		templateFormData,
		isEditMode,
		isSaving,
	} = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const styles = {
		text: {
			fontSize: "14px",
			fontWeight: 500,
		},
	};

	const [editor] = useLexicalComposerContext();

	const [templateName, setTemplateName] = useState("");
	const [subject, setSubject] = useState("");
	const [selectedTemplateTags, setSelectedTemplateTags] = useState([]);
	const [isAddFileDialogOpened, setIsAddFileDialogOpened] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState([]);

	const { data: templateTags } = useTemplateTagsDropdownList();

	const { data: contactPlaceHolderFields } = usePlaceHolderFields(
		modules.CONTACT.toLocaleLowerCase()
	);
	const { data: companyPlaceHolderFields } = usePlaceHolderFields(
		modules.COMPANY.toLocaleLowerCase()
	);
	const { data: dealPlaceHolderFields } = usePlaceHolderFields(
		modules.DEAL.toLocaleLowerCase()
	);

	useEffect(() => {
		if (templateFormData) {
			if (templateFormData?.tags) {
				setSelectedTemplateTags(
					templateFormData.tags?.map((tag) => tag.name)
				);
			}
			if (templateFormData?.subject) {
				setSubject(templateFormData?.subject);
			}
			if (templateFormData?.attachments?.length > 0) {
				setUploadedFiles(templateFormData?.attachments);
			}
			setTemplateName(templateFormData?.name);
		}
	}, [templateFormData]);

	const handleTemplateNameChange = (event) => {
		const { value } = event.target;
		setTemplateName(value.trimStart());
	};

	const handleSubjectChange = (event) => {
		const { value } = event.target;
		setSubject(value.trimStart());
	};

	const handleSelectTags = (values) => {
		setSelectedTemplateTags(values);
	};

	const getTagIdByName = (tagName) => {
		let tagData = templateTags?.find(
			(templateTag) => templateTag?.name === tagName
		);
		if (tagData) {
			return tagData.id;
		}
	};

	const getFormatedTemplateTags = () => {
		let formatedTemplateTags = [];
		for (let templateTag of selectedTemplateTags) {
			let templateTagId = getTagIdByName(templateTag);
			if (templateTagId) {
				formatedTemplateTags.push({ id: templateTagId });
			} else {
				formatedTemplateTags.push({ name: templateTag });
			}
		}
		return formatedTemplateTags;
	};

	const isFieldIncludesOfPlaceHolderFields = (placeHolderFields, fieldId) => {
		return placeHolderFields?.fields?.some(
			(field) => field?.id === parseInt(fieldId)
		);
	};

	const getSourceByFieldId = (fieldId) => {
		let sourceId = "";

		if (
			contactPlaceHolderFields &&
			isFieldIncludesOfPlaceHolderFields(
				contactPlaceHolderFields,
				fieldId
			)
		) {
			sourceId = contactPlaceHolderFields?.sourceId;
		} else if (
			companyPlaceHolderFields &&
			isFieldIncludesOfPlaceHolderFields(
				companyPlaceHolderFields,
				fieldId
			)
		) {
			sourceId = companyPlaceHolderFields?.sourceId;
		} else if (
			dealPlaceHolderFields &&
			isFieldIncludesOfPlaceHolderFields(dealPlaceHolderFields, fieldId)
		) {
			sourceId = dealPlaceHolderFields?.sourceId;
		}

		return sourceId;
	};

	const getFormatedTemplateHtmlData = () => {
		let templateBodyData = $generateHtmlFromNodes(editor);

		let placeHolderStartPosition = "";
		let placeHolderEndPosition = "";
		let formatedTemplateHtmlData = "";

		for (let index = 0; index < templateBodyData?.length; index++) {
			if (templateBodyData[index] === "$") {
				placeHolderStartPosition = index;
			}
			if (templateBodyData[index] === "}") {
				placeHolderEndPosition = index;
			}

			if (!placeHolderStartPosition) {
				formatedTemplateHtmlData += templateBodyData[index];
			} else if (placeHolderStartPosition && placeHolderEndPosition) {
				let placeHolderFieldName = templateBodyData.substring(
					placeHolderStartPosition,
					placeHolderEndPosition
				);
				let fieldId = placeHolderFieldName.split("_")[1];
				let placeHolderTag = `<span data-info='{source: ${getSourceByFieldId(
					fieldId
				)}, fieldId: ${fieldId}}'>${placeHolderFieldName}}</span>`;

				formatedTemplateHtmlData += placeHolderTag;

				placeHolderStartPosition = "";
				placeHolderEndPosition = "";
			}
		}
		return formatedTemplateHtmlData;
	};

	const getTemplateContent = () => {
		let editorState = editor.getEditorState();
		let htmlBodyContent = editorState.read(() => {
			let formatedTemplateHtmlData = getFormatedTemplateHtmlData();
			return parseHTML(formatedTemplateHtmlData, classes);
		});

		return htmlBodyContent;
	};

	const handleSaveEmailTemplate = () => {
		let templateFormData = {
			name: templateName,
			tags: getFormatedTemplateTags(),
			subject: subject,
			content: getTemplateContent(),
			attachmentIds: uploadedFiles?.map((file) => file?.id),
		};

		onSaveEmailTemplate(templateFormData);
	};

	const getFormHeaderLabel = () => {
		if (isEditMode) {
			return "Edit Email Template";
		} else {
			return "New Email Template";
		}
	};

	const openAddFileDialog = () => {
		setIsAddFileDialogOpened(true);
	};

	const closeAddFileDialog = () => {
		setIsAddFileDialogOpened(false);
	};

	const onFilesSaved = (uploadedFiles) => {
		setUploadedFiles(uploadedFiles);
	};

	const removeUploadedFile = (removedFileId) => {
		setUploadedFiles(
			uploadedFiles.filter(
				(uploadedFile) => uploadedFile.id !== removedFileId
			)
		);
	};

	return (
		<React.Fragment>
			{/*File upload dialog*/}
			<AddFile
				isAddFileDialogOpened={isAddFileDialogOpened}
				onCloseAddFileDialog={closeAddFileDialog}
				onFilesSaved={onFilesSaved}
				hasUploadedFromComposer={true}
			/>

			{isSaving ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="100vh"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : (
				<Stack sx={{ height: "100%" }}>
					<Box p={2}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={2}
						>
							<Typography fontWeight={600} fontSize={17}>
								{getFormHeaderLabel()}
							</Typography>

							<IconButton
								onClick={onCloseEmailTemplateDrawer}
								size="small"
								style={{ padding: 0 }}
							>
								{CloseIcon(24, 24, theme.palette.primary.main)}
							</IconButton>
						</Stack>
					</Box>

					<Divider />

					<Box
						style={{
							flex: "1 1 auto",
							minHeight: 0,
							overflowY: "auto",
						}}
					>
						<Box p={2}>
							<Stack spacing={2}>
								<Stack spacing={0.5}>
									<Typography style={styles.text}>
										Email Template Name
									</Typography>

									<TextField
										value={templateName}
										onChange={handleTemplateNameChange}
									/>
								</Stack>

								<Stack spacing={0.5}>
									<Typography style={styles.text}>
										Tags
									</Typography>

									<TemplateTagsInput
										tagsList={templateTags || []}
										handleSelectTags={handleSelectTags}
										selectedTags={selectedTemplateTags}
									/>
								</Stack>
							</Stack>
						</Box>

						<Divider />

						<Stack direction="row" px={2} alignItems="center">
							<Typography fontSize={14}>Sub :</Typography>

							<Box flexGrow={1}>
								<Subject
									value={subject}
									onChange={handleSubjectChange}
								/>
							</Box>
						</Stack>

						<Divider />

						<Box>
							<TemplateInput
								initialTemplateContent={
									templateFormData?.content
								}
							/>
						</Box>
					</Box>

					<Box>
						<Box p={1}>
							{uploadedFiles?.length > 0 ? (
								<Stack
									px={1.5}
									direction="row"
									spacing={0.5}
									flexWrap="wrap"
								>
									{uploadedFiles.map((file) => (
										<Stack
											key={file.id}
											direction="row"
											alignItems="center"
											pb={0.5}
										>
											<Stack
												direction="row"
												alignItems="center"
												borderRadius={2}
												bgcolor={
													theme.palette.secondary.main
												}
												p={0.5}
												spacing={0.6}
											>
												<Typography
													fontSize={14}
													component="span"
												>
													{file?.fileName}{" "}
													<Typography
														fontSize={14}
														component="span"
														color="rgba(0, 0, 0, 0.6)"
													>
														{`(${getFormattedFileSize(
															file?.fileSize
														)})`}
													</Typography>
												</Typography>

												<IconButton
													style={{
														padding: "0px",
													}}
													onClick={() =>
														removeUploadedFile(
															file.id
														)
													}
												>
													{CloseIcon(
														16,
														16,
														"rgba(0, 0, 0, 0.6)"
													)}
												</IconButton>
											</Stack>
										</Stack>
									))}
								</Stack>
							) : null}

							<IconButton onClick={openAddFileDialog}>
								{AttachmentIcon(20, 20, "#666666")}
							</IconButton>
						</Box>

						<Divider />

						<Box p={2}>
							<Button
								variant="contained"
								disableElevation
								onClick={handleSaveEmailTemplate}
								disabled={isSaving}
							>
								{isEditMode ? "Update" : "Save"}
							</Button>
						</Box>
					</Box>
				</Stack>
			)}
		</React.Fragment>
	);
}
