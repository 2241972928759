import React, { useEffect, useMemo, useState } from "react";
import {
	alpha,
	Box,
	Button,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	MenuItem,
	Select,
	Stack,
	TextField,
	ToggleButton,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../assets/icons/close";
import { default as ContactIcon } from "../../../assets/icons/contact";
import { default as CompanyIcon } from "../../../assets/icons/company";
import { default as ContactImage } from "../../../assets/images/contact/unknownContact.png";
import AddProductTable from "../AddProductTable";
import MenuSearchBar from "../../Elements/MenuSearchBar";
import { removeFieldsWithEmptyValues } from "../../../utils/common";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import {
	useDealRelatedEntitiesData,
	useAddProductsToDeal,
} from "../../../hooks/services/deal";
import ToggleButtonGroup from "../../Elements/ToggleButtonGroup";
import { useCurrencies } from "../../../hooks/settings/currency";
import DealAutocomplete from "../../Elements/DealAutoCompleteComponent";
import { MenuItemText } from "../../../styles/twozo";

export default function AddDeal(props) {
	const theme = useTheme();
	const styles = {
		text: {
			fontSize: 14,
			fontWeight: 500,
		},
	};
	const {
		selectedProducts,
		onAddProductsToDeal,
		onClose,
		defaulValues,
		dealData,
	} = props;

	const getInitialProductData = () => {
		let productPriceList = [];
		if (selectedProducts?.length > 0) {
			for (let product of selectedProducts) {
				let productData = {
					product: product,
					price: 0,
					quantity: 1,
					tax: 0,
					discount: 0,
				};
				productPriceList.push(productData);
			}
		} else {
			productPriceList.push({
				product: "",
				price: "",
				quantity: "",
				tax: "",
				discount: "",
			});
		}
		return productPriceList;
	};

	const taxTypes = {
		taxInclusive: "taxInclusive",
		noTax: "noTax",
	};
	const [taxType, setTaxType] = useState(taxTypes.taxInclusive);
	const [selectedDeal, setSelectedDeal] = useState(null);
	const [searchCurrencyValue, setSearchCurrencyValue] = useState("");
	const [productsData, setProductsData] = useState(getInitialProductData());

	const { activeCurrencyList, getCurrencyDataById, baseCurrency } =
		useCurrencies(searchCurrencyValue);
	const [currencyValue, setCurrencyValue] = useState("");

	const addProductsToDealMutation = useAddProductsToDeal(dealData?.value);
	const { data: relatedEntitiesData } = useDealRelatedEntitiesData(
		selectedDeal?.value
	);

	const isTaxInclusive = taxType === taxTypes.taxInclusive;
	const companyValue = relatedEntitiesData?.company?.name;
	const contactValue = relatedEntitiesData?.primaryContact?.fullName;
	const dealInputDisabled = dealData?.value;

	const hasAnyProductSelected = () => {
		return productsData[0].product;
	};

	const isSaveButtonDisabled =
		addProductsToDealMutation.isLoading || !hasAnyProductSelected();

	useEffect(() => {
		if (baseCurrency) {
			setCurrencyValue(baseCurrency?.id);
		}
	}, [baseCurrency]);

	useEffect(() => {
		if (defaulValues) {
			const taxTypes = {
				taxInclusive: "taxInclusive",
				noTax: "noTax",
			};

			if (defaulValues?.items?.length > 0) {
				setProductsData(defaulValues.items);
			}

			if (defaulValues?.currency?.id) {
				setCurrencyValue(defaulValues.currency.id);
			}

			setTaxType(
				defaulValues?.taxInclusive
					? taxTypes.taxInclusive
					: taxTypes?.noTax
			);
		}

		if (dealData) {
			setSelectedDeal(dealData);
		}
	}, [defaulValues, taxTypes.taxInclusive, taxTypes.noTax, dealData]);

	const handleSelectDeal = (value) => {
		setSelectedDeal(value);
	};

	const handleCurrencyValue = (event) => {
		const { value } = event.target;
		setCurrencyValue(value);
	};

	const handleSearchCurrency = (event) => {
		const { value } = event.target;
		setSearchCurrencyValue(value);
	};

	const handleSave = () => {
		let requestData = getUpdateRequestData();
		addProductsToDealMutation.mutate(requestData, {
			onSuccess: (_, payload) => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message:
						payload?.items?.length > 1
							? `${payload?.items?.length} products have been added to the deal successfully.`
							: "The product has been added to the deal successfully.",
				});
				onClose();
				!!onAddProductsToDeal && onAddProductsToDeal();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const getUpdateRequestData = () => {
		let requestData = {
			dealId: selectedDeal?.value,
			currency: currencyValue,
			taxInclusive: isTaxInclusive,
			items: [],
		};

		for (let productData of productsData) {
			if (productData?.product) {
				requestData.items.push({
					...productData,
					product: productData.product?.value,
				});
			}
		}
		removeFieldsWithEmptyValues(requestData);
		return requestData;
	};

	const handleTax = (_, type) => {
		if (type) {
			setTaxType(type);
		}
	};

	const selectedCurrency = useMemo(() => {
		const currencyData = getCurrencyDataById(currencyValue);
		return currencyData;
	}, [currencyValue, getCurrencyDataById]);

	const getInitialProductIds = () => {
		if (selectedProducts?.length > 0) {
			return selectedProducts?.map((product) => product?.value);
		} else if (defaulValues?.items?.length > 0) {
			return defaulValues.items.map((productData) => productData.product);
		}
		return [];
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					No Results Found
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
					>
						<Typography fontWeight={600} fontSize={17}>
							Add Products to Deal
						</Typography>

						<IconButton
							onClick={props.onClose}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(24, 24, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<Divider />

				<Box
					p={2}
					style={{
						flex: "1 1 auto",
						minHeight: 0,
						overflowY: "auto",
					}}
				>
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						spacing={2}
					>
						<Grid item xs={12}>
							<Stack spacing={0.5}>
								<Typography
									style={{
										...styles.text,
										opacity: dealInputDisabled ? 0.6 : 1,
									}}
								>
									Deal Name
								</Typography>

								<DealAutocomplete
									value={selectedDeal}
									onChange={handleSelectDeal}
									onCancel={() => setSelectedDeal("")}
									disabled={dealInputDisabled}
								/>
							</Stack>
						</Grid>

						<Grid item xs={12}>
							<Stack spacing={0.5} sx={{ opacity: "0.6" }}>
								<Typography style={styles.text}>
									Contact Person
								</Typography>

								<TextField
									sx={{
										"&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
											{
												borderColor: "#e5e5e5",
											},
									}}
									focused={false}
									value={contactValue || ""}
									InputProps={{
										readOnly: true,
										startAdornment: (
											<InputAdornment position="start">
												{selectedDeal ? (
													<img
														src={ContactImage}
														alt="ContactImage"
														width={20}
														height={20}
													/>
												) : (
													ContactIcon(
														20,
														20,
														"#666666"
													)
												)}
											</InputAdornment>
										),
									}}
								/>
							</Stack>
						</Grid>

						<Grid item xs={12}>
							<Stack spacing={0.5} sx={{ opacity: "0.6" }}>
								<Typography style={styles.text}>
									Company
								</Typography>

								<TextField
									sx={{
										"&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
											{
												borderColor: "#e5e5e5",
											},
									}}
									focused={false}
									value={companyValue || ""}
									InputProps={{
										readOnly: true,
										startAdornment: (
											<InputAdornment position="start">
												{CompanyIcon(20, 20, "#666666")}
											</InputAdornment>
										),
									}}
								/>
							</Stack>
						</Grid>

						<Grid item xs={12}>
							<Stack spacing={0.5}>
								<Typography style={styles.text}>
									Currency
								</Typography>

								<Select
									value={currencyValue}
									onChange={handleCurrencyValue}
									onOpen={() => setSearchCurrencyValue("")}
									renderValue={() => selectedCurrency?.code}
									displayEmpty
									MenuProps={{
										autoFocus: false,
										PaperProps: {
											style: {
												maxHeight: "240px",
												width: "300px",
											},
										},
									}}
								>
									<Box
										p={0.5}
										marginTop="-8px"
										sx={{
											position: "sticky",
											top: 0,
											zIndex: 1,
											backgroundColor: "#fff",
										}}
									>
										<MenuSearchBar
											value={searchCurrencyValue}
											onChange={handleSearchCurrency}
										/>
									</Box>
									{activeCurrencyList?.length > 0
										? activeCurrencyList.map(
												(currency, index) => (
													<MenuItem
														key={index}
														value={currency.id}
														sx={{
															height: "40px",
														}}
													>
														<Stack
															direction="row"
															justifyContent="space-between"
															width="100%"
														>
															<MenuItemText>
																{currency.name}
															</MenuItemText>
															<MenuItemText>
																{currency.code}
															</MenuItemText>
														</Stack>
													</MenuItem>
												)
											)
										: renderEmptyView()}
								</Select>
							</Stack>
						</Grid>

						<Grid item xs={12}>
							<Box pb={2}>
								<ToggleButtonGroup
									value={taxType}
									onChange={handleTax}
								>
									<ToggleButton value={taxTypes.taxInclusive}>
										Tax Inclusive
									</ToggleButton>
									<ToggleButton value={taxTypes.noTax}>
										No tax
									</ToggleButton>
								</ToggleButtonGroup>
							</Box>

							<AddProductTable
								currencyValue={selectedCurrency}
								isTaxInclusive={isTaxInclusive}
								productsData={productsData}
								updateProductsData={setProductsData}
								productIds={getInitialProductIds()}
							/>
						</Grid>
					</Grid>
				</Box>

				<Divider />

				<Box p={2}>
					<Button
						variant="contained"
						disableElevation
						onClick={handleSave}
						sx={{
							"&.Mui-disabled": {
								backgroundColor: "rgba(51, 188, 126)",
								color: alpha("#FFFFFF", 0.6),
							},
						}}
						disabled={isSaveButtonDisabled}
					>
						Save
					</Button>
				</Box>
			</Box>
		</React.Fragment>
	);
}
