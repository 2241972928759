import React from "react";
import { DealProvider } from "../Deals/DealProvider";
import { FilterSaveViewProvider } from "../Elements/Table/Filter/Context/FilterSaveViewContext";
import { FilterDataProvider } from "../Elements/Table/Filter/Context/FilterDataContext";
import DealDashboard from "./DealDashboard";
import { PERMISSIONS } from "../../utils/Auth";
import Can from "../Auth/Can";
import CanNot from "../Auth/CanNot";
import UnAuthorized from "../Auth/UnAuthorized";
import { Stack } from "@mui/material";

export default function Deals() {
	return (
		<React.Fragment>
			<DealProvider>
				<FilterDataProvider>
					<FilterSaveViewProvider>
						<Can permission={PERMISSIONS.deal.accessView}>
							<DealDashboard />
						</Can>

						<CanNot permission={PERMISSIONS.deal.accessView}>
							<Stack height="80vh">
								<UnAuthorized />
							</Stack>
						</CanNot>
					</FilterSaveViewProvider>
				</FilterDataProvider>
			</DealProvider>
		</React.Fragment>
	);
}
