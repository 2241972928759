import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { default as ContactIcon } from "../../../../../assets/icons/contact";
import { styles } from "../../../styles";
import Email from "../Fields/Email";
import Phone from "../Fields/Phone";
import Address from "../Fields/Address";
import Note from "../Fields/Note";
import CustomField from "../Fields/CustomField";

export default function Contact(props) {
	const {
		getFormattedDescription,
		highlightText,
		matchedContacts,
		onMouseOverContactMenu,
		handleCloseContactMenu,
	} = props;

	return (
		<React.Fragment>
			<Stack
				direction="row"
				alignItems="flex-start"
				justifyContent="space-between"
				spacing={3}
				width="95%"
			>
				<Box>
					<IconButton
						style={{
							padding: 0,
						}}
					>
						{ContactIcon(20, 20, "rgba(0, 0, 0, 0.6)")}
					</IconButton>
				</Box>

				<Stack width="100%" spacing={"5px"}>
					<Typography
						noWrap
						width="100%"
						fontSize={15}
						onMouseEnter={onMouseOverContactMenu}
						onMouseLeave={handleCloseContactMenu}
					>
						{highlightText(matchedContacts.name)}
					</Typography>

					<Typography noWrap maxWidth="100%" style={styles.subText}>
						{getFormattedDescription([
							!!matchedContacts.company &&
								matchedContacts.company.name,
						])}
					</Typography>

					{matchedContacts.emails && (
						<Email
							emails={matchedContacts.emails}
							highlightText={highlightText}
						/>
					)}

					{matchedContacts.phones && (
						<Phone
							phones={matchedContacts.phones}
							highlightText={highlightText}
						/>
					)}

					{matchedContacts.address && (
						<Address
							highlightText={highlightText}
							address={matchedContacts.address}
						/>
					)}

					{matchedContacts.notes && (
						<Note
							highlightText={highlightText}
							notes={matchedContacts.notes}
						/>
					)}

					{matchedContacts.customFields && (
						<CustomField
							highlightText={highlightText}
							customFields={matchedContacts.customFields}
						/>
					)}
				</Stack>
			</Stack>
		</React.Fragment>
	);
}
