import { getTableUrl } from "../apiConfig";
import { assertError } from "../apiHelper";
import { apiClient } from "..";

export const getTableData = async (
	table,
	config = {},
	criteriaList,
	additionalCriteria
) => {
	const url = getTableUrl(table);
	const tableRequestData = getTableRequestData(
		config,
		criteriaList,
		additionalCriteria
	);
	return await getTableDataApi(url, tableRequestData);
};

const getTableDataApi = async (url, requestData) => {
	let response = await apiClient.post(url, requestData);
	assertError(response);
	return response.data.data.table;
};

const getTableRequestData = (tableConfig, criteriaList, additionalCriteria) => {
	let tableRequestData = {
		...tableConfig,
		...criteriaList,
	};

	if (Array.isArray(additionalCriteria) && additionalCriteria.length > 0) {
		tableRequestData = {
			...tableRequestData,
			additionalCriteria: additionalCriteria,
		};
	}

	return tableRequestData;
};
