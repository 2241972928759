import {
	Box,
	Button,
	Divider,
	MenuItem,
	Select,
	Stack,
	Typography,
	alpha,
	useTheme,
} from "@mui/material";
import Dialog from "../Dialog";
import { FormFieldName, MenuItemText, Tooltip } from "../../../styles/twozo";
import { getDropdownDataByName } from "../../../utils/dropdownData";
import React, { useState } from "react";

import { default as AddIcon } from "../../../assets/icons/add";
import { default as ContactIcon } from "../../../assets/icons/contact";
import { default as ArrowSwapIcon } from "../../../assets/icons/arrowSwap";
import { default as CloudConnectionIcon } from "../../../assets/icons/cloudConnection";
import { default as CalendarIcon } from "../../../assets/icons/activity";
import { default as ActivityIcon } from "../../../assets/icons/addActivity";
import { default as ShareIcon } from "../../../assets/icons/share";
import { emailsSyncTypes } from "../../../utils/emailsSyncUtils";

export default function EmailManagementSyncDialog(props) {
	const {
		emailsSyncType,
		isSyncButtonDisabled = false,
		tooltipText = "",
	} = props;
	const theme = useTheme();

	const [isSyncDialogOpen, setIsSyncDialogOpen] = useState(false);
	const [selectedProvider, setSelectedProvider] = useState(null);
	const providerList = getDropdownDataByName("PROVIDER_LIST");
	const title = "Connect Email Account";

	const handleChangeProvider = (provider) => {
		setSelectedProvider(provider);
	};

	const handleCloseContactSyncDialog = () => {
		setIsSyncDialogOpen(false);
		setSelectedProvider(null);
	};

	const connectSelectedProvider = () => {
		// eslint-disable-next-line no-undef
		if (process.env.NODE_ENV === "development") {
			window.location.href = `/api/oauth/${emailsSyncType}/sync/${selectedProvider?.name}?localRedirectionRequired=true`;
		} else {
			window.location.href = `/api/oauth/${emailsSyncType}/sync/${selectedProvider?.name}`;
		}
	};

	const FeatureText = (props) => (
		<Stack
			direction="row"
			justifyContent="center"
			spacing={2}
			py={2}
			pl={3}
		>
			{props.icon(20, 20, theme.palette.primary.main)}

			<Typography fontSize={15}>{props.text}</Typography>
		</Stack>
	);

	const ContactSyncFeatureText = () => {
		return (
			<>
				<FeatureText
					icon={ContactIcon}
					text="All Contacts or a specific group of contacts"
				/>

				<Divider style={{ width: "110%" }} />

				<FeatureText
					icon={ArrowSwapIcon}
					text="One way or Two way sync"
				/>

				<Divider style={{ width: "110%" }} />

				<FeatureText
					icon={CloudConnectionIcon}
					text="Sync with Google, Outlook.com"
				/>
			</>
		);
	};

	const CalendarSyncFeatureText = () => {
		return (
			<>
				<FeatureText
					icon={CalendarIcon}
					text="Stop switching between Twozo and your external calendar"
				/>

				<Divider style={{ width: "110%" }} />

				<FeatureText
					icon={ShareIcon}
					text="Share your availability and propose meeting times"
				/>

				<Divider style={{ width: "110%" }} />

				<FeatureText
					icon={ActivityIcon}
					text="Never miss an activity or make a double- booking again"
				/>
			</>
		);
	};

	const renderSyncFeatureText = (syncType) => {
		switch (syncType) {
			case emailsSyncTypes.contact:
				return <ContactSyncFeatureText />;
			case emailsSyncTypes.calendar:
				return <CalendarSyncFeatureText />;
			default:
				return null;
		}
	};

	return (
		<React.Fragment>
			<Stack alignItems="center" py={3}>
				<Stack alignItems="flex-start">
					{renderSyncFeatureText(emailsSyncType)}
				</Stack>

				<Box pt={5}>
					<Tooltip
						title={isSyncButtonDisabled ? tooltipText : ""}
						placement="top"
					>
						<Button
							variant="contained"
							startIcon={AddIcon(
								20,
								20,
								isSyncButtonDisabled
									? alpha("#FFFFFF", 0.6)
									: "#fff"
							)}
							disableElevation
							onClick={() => setIsSyncDialogOpen(true)}
							disabled={isSyncButtonDisabled}
							sx={{
								"&.Mui-disabled": {
									backgroundColor: "rgba(51, 188, 126)",
									color: alpha("#FFFFFF", 0.6),
								},
							}}
							style={{
								pointerEvents: "auto",
							}}
						>
							Add New Account
						</Button>
					</Tooltip>
				</Box>
			</Stack>

			<Dialog open={isSyncDialogOpen} title={title}>
				<Stack spacing={2} p={2} pb={3}>
					<Stack spacing={0.5}>
						<FormFieldName>Select Email Provider</FormFieldName>

						<Select
							value={selectedProvider?.value || ""}
							renderValue={() =>
								selectedProvider?.displayName ? (
									<MenuItemText>
										{selectedProvider.displayName}
									</MenuItemText>
								) : (
									<MenuItemText color={"rgba(0, 0, 0, 0.4)"}>
										Select
									</MenuItemText>
								)
							}
							fullWidth
							displayEmpty
						>
							{providerList.map((provider) => (
								<MenuItem
									value={provider.value}
									key={provider.value}
									onClick={() =>
										handleChangeProvider(provider)
									}
								>
									<MenuItemText>
										{provider.displayName}
									</MenuItemText>
								</MenuItem>
							))}
						</Select>
					</Stack>

					<Stack
						direction="row"
						pt={1}
						spacing={2}
						justifyContent="end"
					>
						<Button
							variant="contained"
							color="secondary"
							onClick={handleCloseContactSyncDialog}
							disableElevation
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							onClick={connectSelectedProvider}
							disableElevation
							disabled={!selectedProvider}
							sx={{
								"&.Mui-disabled": {
									backgroundColor: theme.palette.primary.main,
									color: "rgba(255,255,255, 0.6)",
								},
							}}
						>
							Connect
						</Button>
					</Stack>
				</Stack>
			</Dialog>
		</React.Fragment>
	);
}
