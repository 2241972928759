import React, { useMemo, useState } from "react";
import {
	Box,
	FormHelperText,
	ListSubheader,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { MenuItemText } from "../../../../../../../../styles/twozo";
import { default as DropdownIcon } from "../../../../../../../../assets/icons/dropDownRight";
import MenuSearchBar from "../../../../../../../Elements/MenuSearchBar";

export default function DropdownBaseComponent(props) {
	const {
		options = [],
		grouping,
		name,
		control,
		rules,
		fieldKey,
		handleChangeModule,
		...other
	} = props;
	const theme = useTheme();
	const [searchValue, setSearchValue] = useState("");

	const getMenuItems = (options, style = {}) => {
		return options.map((option) => (
			<MenuItem
				key={option.id}
				value={option.id}
				style={{ minHeight: "40px", ...style }}
			>
				<Stack
					width="100%"
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<MenuItemText>{option.label}</MenuItemText>

					{option?.isCustom && (
						<Box display="flex">
							{DropdownIcon(16, 16, "#000", 0.6)}
						</Box>
					)}
				</Stack>
			</MenuItem>
		));
	};

	if (!control) {
		return <Typography fontSize={14}>control is required</Typography>;
	}

	const getPlaceholder = (selected) => {
		let selectedOption = null;
		if (grouping) {
			for (let index = 0; index < options.length; index++) {
				selectedOption = options[index].options?.find(
					(option) => option.id === selected
				);
				if (selectedOption) {
					break;
				}
			}
		} else {
			selectedOption = options.find((option) => option.id === selected);
		}
		return (
			selectedOption?.label || (
				<Typography
					fontSize={14}
					color={theme.palette.secondary.contrastText}
				>
					Select
				</Typography>
			)
		);
	};

	const handleSearchValue = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const filterOptions = useMemo(() => {
		if (grouping) {
			return options
				?.map((group) => ({
					...group,
					options: group.options.filter((option) =>
						option?.label
							?.toLowerCase()
							?.startsWith(searchValue.toLowerCase())
					),
				}))
				.filter((group) => group.options.length > 0);
		}

		if (searchValue.trim()) {
			return options?.filter((option) =>
				//filter for search
				option?.label
					?.toLowerCase()
					?.startsWith(searchValue.toLowerCase())
			);
		}
		return options;
	}, [grouping, options, searchValue]);

	const renderNoResultsMessage = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					No Results Found
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			<Controller
				name={name}
				control={control}
				rules={rules}
				render={({
					field: { onChange, ...fieldState },
					fieldState: { error },
				}) => (
					<>
						<Select
							key={fieldKey}
							defaultValue={""}
							MenuProps={{
								style: {
									maxHeight: "380px",
								},
							}}
							{...other}
							{...fieldState}
							displayEmpty
							onClose={() => {
								setSearchValue("");
							}}
							renderValue={getPlaceholder}
							value={fieldState.value}
							error={!!error}
							onChange={(e) => {
								onChange(e);
								if (handleChangeModule) {
									handleChangeModule(e.target.value);
								}
							}}
						>
							<Box
								p={0.5}
								marginTop="-8px"
								sx={{
									position: "sticky",
									top: 0,
									zIndex: 999,
									backgroundColor: "#fff",
								}}
							>
								<MenuSearchBar
									value={searchValue}
									onChange={handleSearchValue}
								/>
							</Box>
							{filterOptions?.length > 0
								? grouping
									? filterOptions.reduce((option, module) => {
											option.push(
												<CustomListSubheader
													key={module.id}
												>
													{module.label}
												</CustomListSubheader>
											);
											option.push(
												...getMenuItems(
													module.options,
													{
														paddingLeft: "32px",
														width: "100%",
													}
												)
											);
											return option;
										}, [])
									: getMenuItems(filterOptions)
								: renderNoResultsMessage()}
						</Select>

						{!!error && (
							<FormHelperText
								style={{ paddingLeft: "16px" }}
								error
							>
								{error.message}
							</FormHelperText>
						)}
					</>
				)}
			/>
		</React.Fragment>
	);
}

const CustomListSubheader = (props) => {
	const { style, ...other } = props;
	return (
		<ListSubheader
			style={{
				lineHeight: "normal",
				paddingTop: "4px",
				paddingBottom: "4px",
				color: "#000",
				fontSize: 14,
				fontWeight: 500,
				...style,
			}}
			{...other}
		/>
	);
};

CustomListSubheader.muiSkipListHighlight = true;
