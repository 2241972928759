import { emailPattern } from "./validation";

const fieldTypes = {
	email: "EMAIL",
	phone: "PHONE",
	emails: "EMAILS",
	phones: "PHONES",
	singleString: "SINGLE_STRING",
	monetary: "MONETARY",
	dealValue: "DEAL_VALUE",
};

export const phoneNumberLimit = 10;
export const emailLimit = 10;

let emailValidationMessage = "Please enter a valid email id";
let fieldValidationMessage = "This field is mandatory";

const getFieldValueLength = (value) => {
	// Define a safe maximum value (the largest number JavaScript can safely handle).
	// JavaScript has a safe integer limit of 9007199254740991, but we are using a custom large value
	// (999999999999999) to represent a reasonably large number that can be safely processed.
	const maxSafeInteger = 999999999999999;
	if (value > maxSafeInteger) {
		return value?.toString()?.length - 1;
	}
	return value?.toString()?.length;
};

export const handleFieldValidation = (field, subFields) => {
	return {
		required: handleRequiredField(field),
		validate: (value) => handleValidate(value, field, subFields),
		pattern: getFieldValidationPattern(field),
		minLength: {
			value: field.config.minLength,
			message: `Min. of ${field.config.minLength} characters are required`,
		},
		maxLength: {
			value: field.config.maxLength,
			message: `Max. of ${field.config.maxLength} characters are allowed`,
		},
		min: {
			value: field.config.min,
			message: field.config.min
				? `Min. of ${
						field.config.min?.toString()?.length
					} numbers are required`
				: "Negative numbers are not permitted",
		},
		max: {
			value: field.config.max,
			message: `Max. of ${getFieldValueLength(
				field?.config?.max
			)} numbers are allowed`,
		},
	};
};

const getSubFieldObject = (subFields, subFieldName) => {
	if (Array.isArray(subFields)) {
		return subFields.find((field) => field.name === subFieldName);
	}
	return undefined;
};

const handleRequiredField = (field) => {
	let isRequired = field.config.required;
	if (isRequired) return fieldValidationMessage;
};

const isString = (value) => {
	return !!value && typeof value === "string";
};

const isValidEmail = (email) => {
	return emailPattern.test(email);
};

const validateEmail = (value, field, subFields) => {
	const emailValueField = getSubFieldObject(subFields, "EMAIL_VALUE");

	// Case 1: String validation (used for summary edit)
	if (isString(value)) {
		return isValidEmail(value) ? true : emailValidationMessage;
	}

	// Case 2: Object validation (used for Add Form)
	const emailValue = value?.[emailValueField?.id];

	if (!emailValue) return handleRequiredField(field);
	return isValidEmail(emailValue) ? true : emailValidationMessage;
};

const validatePhoneNumber = (phoneData, field, subFields) => {
	const phoneValueField = getSubFieldObject(subFields, "PHONE_NUMBER");

	// Case 1: Object handling (used for Add Form)
	// This case handles objects where the phone value is stored in a property.

	// Case 2: Value handling (used for summary edit)
	// This case directly uses 'phoneData', defaulting to an empty string if falsy.

	// If phoneData is a string, use it directly.
	// If phoneData is an object, retrieve the phone number using the ID from the subField
	let phoneValue = isString(phoneData)
		? phoneData
		: phoneData?.[phoneValueField?.id] || "";

	const fieldValidationData = field?.config?.validation;
	if (!phoneValue) {
		return handleRequiredField(field);
	}

	if (phoneValue && fieldValidationData) {
		const regexString = fieldValidationData?.regex;
		const phoneNumberRegexPattern = new RegExp(regexString);
		if (phoneNumberRegexPattern.test(phoneValue)) {
			return true;
		}
		return fieldValidationData?.violationMessage;
	}
	return true;
};
const validateSingleString = (value, field) => {
	const fieldValidationData = field?.config?.validation;
	if (value && fieldValidationData) {
		const regexString = fieldValidationData?.regex;
		const regexPattern = new RegExp(regexString);
		if (regexPattern.test(value)) {
			return true;
		}
		return fieldValidationData?.violationMessage;
	}
	return true;
};

const validateMonetary = (value, field, subFields) => {
	const monetaryValueField = getSubFieldObject(subFields, "VALUE");
	const monetaryValue = isString(value)
		? value
		: value?.[monetaryValueField?.id];

	const maxLength = field.config?.max?.toString()?.length;

	if (!monetaryValue && monetaryValue !== 0)
		return handleRequiredField(field);
	if (monetaryValue < field.config.min) {
		return "Negative numbers are not permitted";
	}
	if (maxLength && monetaryValue?.toString()?.length > maxLength) {
		return `Max. of ${maxLength} numbers are allowed`;
	}
};

const getEmailPattern = () => {
	return {
		value: emailPattern,
		message: emailValidationMessage,
	};
};

const handleValidate = (value, field, subFields) => {
	switch (field.type || field.fieldType) {
		case fieldTypes.email:
			return validateEmail(value, field, subFields);
		case fieldTypes.phones:
		case fieldTypes.phone:
			return validatePhoneNumber(value, field, subFields);
		case fieldTypes.singleString:
			return validateSingleString(value, field);
		case fieldTypes.monetary:
		case fieldTypes.dealValue:
			return validateMonetary(value, field, subFields);
		default:
			break;
	}
};

const getFieldValidationPattern = (field) => {
	switch (field.type) {
		case fieldTypes.emails:
			return getEmailPattern(field);
		default:
			break;
	}
};
