import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import {
	getNotificationAlertStateKey,
	getNotificationsDataKey,
	getPendingNotificationKey,
	getReminderNotificationKey,
	getTopNotificationsKey,
} from "../../../../utils/queryKeys/appNotification";
import {
	getNotificationAlertState,
	getNotificationsData,
	getPendingNotification,
	getReminderNotificationData,
	getTopNotifications,
	updateMarkAllAsRead,
	updateMarkAsRead,
	updateNotificationAlertState,
	updatePendingNotification,
} from "../../../../api/notification/appNotification/appNotificationApi";
import { notificationPageSize } from "../../../../utils/queryConstants/notification";

const notificationSelect = (notificationData) => {
	return notificationData?.pages?.flatMap((page) =>
		page?.notifications?.length > 0 ? page.notifications : []
	);
};

const useNotificationsData = () => {
	return useInfiniteQuery({
		queryKey: getNotificationsDataKey(),
		queryFn: ({ pageParam }) => {
			return getNotificationsData({
				start: pageParam
					? (pageParam - 1) * notificationPageSize + 1
					: 1,
				limit: notificationPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: notificationSelect,
	});
};

const usePendingNotification = () => {
	return useQuery(
		getPendingNotificationKey(),
		() => getPendingNotification(),
		{
			select: (data) => data.pendingNotification,
			refetchInterval: (data) => pendingNotificationRefetchInterval(data),
		}
	);
};

const pendingNotificationRefetchInterval = (pendingNotification) => {
	if (!pendingNotification) {
		return 30000;
	}
};

const useTopNotifications = (enabledTopNotifications) => {
	return useQuery(getTopNotificationsKey(), () => getTopNotifications(), {
		enabled: Boolean(enabledTopNotifications),
	});
};

const useNotificationAlertState = () => {
	return useQuery(
		getNotificationAlertStateKey(),
		() => getNotificationAlertState(),
		{
			select: (data) => data.notificationState,
		}
	);
};

const useReminderNotification = () => {
	return useQuery(
		getReminderNotificationKey(),
		() => getReminderNotificationData(),
		{
			refetchInterval: () => reminderNotificationRefetchInterval(),
		}
	);
};

const reminderNotificationRefetchInterval = () => {
	return 30000;
};

const useInvalidateNotificationsData = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getNotificationsDataKey());
	};
};

const useInvalidateNotificationAlertState = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getNotificationAlertStateKey());
	};
};

const useInvalidatePendingNotification = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getPendingNotificationKey());
	};
};

const useUpdateNotificationAlertStateMutation = () => {
	let invalidateNotificationAlertState =
		useInvalidateNotificationAlertState();
	return useMutation(updateNotificationAlertState, {
		onSuccess: () => {
			invalidateNotificationAlertState();
		},
	});
};

const useMarkAllAsReadMutation = () => {
	let invalidateNotificationsData = useInvalidateNotificationsData();
	return useMutation(updateMarkAllAsRead, {
		onSuccess: () => {
			invalidateNotificationsData();
		},
	});
};

const useMarkAsReadMutation = () => {
	let invalidateNotificationsData = useInvalidateNotificationsData();
	return useMutation(updateMarkAsRead, {
		onSuccess: () => {
			invalidateNotificationsData();
		},
	});
};

const useUpdatePendingNotification = () => {
	let invalidatePendingNotification = useInvalidatePendingNotification();
	return useMutation(updatePendingNotification, {
		onSuccess: () => {
			invalidatePendingNotification();
		},
	});
};

export {
	useNotificationsData,
	useNotificationAlertState,
	useUpdateNotificationAlertStateMutation,
	useMarkAllAsReadMutation,
	useMarkAsReadMutation,
	usePendingNotification,
	useTopNotifications,
	useUpdatePendingNotification,
	useReminderNotification,
};
