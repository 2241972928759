import { getRootKey } from "../rootKey";

const searchKey = "search";

// search query key
const getSearchKey = () => {
	return [...getRootKey(), searchKey];
};

// query keys
export const searchedAndViewedKey = [
	...getSearchKey(),
	"recentlySearchedAndViewed",
];

// query key methods
export const getCommonSearchKey = () => {
	return [...getSearchKey(), "commonSearch"];
};

export const getCommonSearchDataWithFilterKey = (filter, searchText) => {
	return [...getCommonSearchKey(), filter, searchText];
};
