import {
	Box,
	Button,
	MenuItem,
	Radio,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import { MenuItemText, TextButton, Tooltip } from "../../../../styles/twozo";
import { default as AddIcon } from "../../../../assets/icons/add";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { default as RadioOffIcon } from "../../../../assets/icons/radioOff";
import { default as RadioTickedIcon } from "../../../../assets/icons/tickCircle";
import { getDropdownDataByName } from "../../../../utils/dropdownData";
import { useFieldArray, useForm } from "react-hook-form";
import { handleFieldValidation } from "../../../../utils/fieldValidation";
import { removeInvalidPhoneNumberCharacters } from "../../../../utils/phoneNumberUtils";
import { useCallback } from "react";
export const MultiplePhoneFieldForm = (props) => {
	const { subFields, field, onClose, onSave, value, isSaving } = props;

	const theme = useTheme();
	const fieldId = field?.fieldId;
	const phoneTypeOptions = getDropdownDataByName("PHONE_TYPE");

	const {
		control,
		formState: { errors },
		clearErrors,
		register,
		handleSubmit,
		setValue,
		getValues,
	} = useForm({
		defaultValues: value,
		reValidateMode: false,
		values: value,
	});

	const {
		fields: phoneFields,
		append: addPhone,
		remove: removePhone,
	} = useFieldArray({
		control,
		name: fieldId + "",
	});

	const phoneNumber = "PHONE_NUMBER";
	const phoneType = "PHONE_TYPE";

	const getSubFieldObject = (subFieldName) => {
		if (subFields && Array.isArray(subFields)) {
			return subFields.find((field) => field.name === subFieldName);
		}
		return;
	};

	const phoneNumberObj = getSubFieldObject(phoneNumber);
	const phoneTypeObj = getSubFieldObject(phoneType);

	const handleAddPhone = useCallback(
		(isPrimary = false) => {
			addPhone({
				[phoneNumberObj?.id]: "",
				[phoneTypeObj?.id]: phoneTypeOptions?.[0]?.value,
				isPrimary: isPrimary,
			});
		},
		[addPhone, phoneNumberObj?.id, phoneTypeObj?.id, phoneTypeOptions]
	);

	const handlePrimaryChange = (index) => {
		const currentFields = getValues(fieldId + "");
		const updatedFields = currentFields.map((field, fieldIndex) => ({
			...field,
			isPrimary: fieldIndex === index,
		}));
		setValue(fieldId + "", updatedFields);
	};

	const isPhoneNumberLimitExceeded = () => {
		const phoneNumberLimit = 10;
		return phoneFields.length >= phoneNumberLimit;
	};

	const getDefaultValue = (phoneField) => {
		let defaultValue = phoneTypeOptions.find((phoneTypeOption) => {
			return phoneField?.[phoneTypeObj?.id] === phoneTypeOption?.value;
		});

		return defaultValue?.value;
	};

	const getRemovedEmptyPhoneNumber = (obj) => {
		const fieldId = field.fieldId?.toString();
		if (Array.isArray(obj[fieldId])) {
			obj[fieldId] = obj[fieldId].filter(
				(item) => item && item[phoneNumberObj?.id] !== ""
			);
		}
		return obj;
	};

	const handleSave = (phonesData) => {
		let removedEmptyPhoneData = getRemovedEmptyPhoneNumber(phonesData);
		onSave(removedEmptyPhoneData);
	};

	useEffect(() => {
		if (phoneFields.length === 0) {
			handleAddPhone(true);
		}
	}, [phoneFields, handleAddPhone]);

	const handlePhoneFieldChange = (event, index) => {
		let phoneValue = event.target.value.trimStart();
		phoneValue = removeInvalidPhoneNumberCharacters(phoneValue);
		setValue(`${fieldId}.${index}.${phoneNumberObj?.id}`, phoneValue);
		clearErrors(`${fieldId}.${index}.${phoneNumberObj?.id}`);
	};

	return (
		<React.Fragment>
			<Stack
				direction="column"
				alignItems="center"
				justifyContent="center"
				padding={`8px ${
					phoneFields?.length > 1 ? "16px" : "0px"
				} 16px 16px`}
				spacing={0.5}
				maxHeight="40vh"
			>
				<Stack
					sx={{ overflowY: "auto" }}
					spacing={0.5}
					direction="column"
					alignItems="center"
					justifyContent="flex-start"
				>
					{phoneFields.map((phoneField, index) => (
						<Stack
							key={phoneField.id}
							direction="row"
							minWidth="400px"
							justifyContent="center"
							alignItems={
								errors[fieldId]?.[index]?.[phoneNumberObj?.id]
									? "baseline"
									: "center"
							}
							spacing={1}
						>
							<Box
								width="7%"
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								<Tooltip
									placement="left"
									title={
										phoneField.isPrimary
											? ""
											: "Mark as Primary"
									}
									PopperProps={{
										modifiers: [
											{
												name: "offset",
												options: {
													offset: [0, -10],
												},
											},
										],
									}}
								>
									<Radio
										checked={phoneField.isPrimary}
										onChange={() =>
											handlePrimaryChange(index)
										}
										icon={RadioOffIcon(25, 25)}
										checkedIcon={RadioTickedIcon(
											18,
											18,
											theme.palette.primary.main
										)}
										style={{ padding: 0 }}
									/>
								</Tooltip>
							</Box>

							<Box width="93%" display="flex">
								<Stack width="100%">
									<Stack width="100%" direction="row">
										<TextField
											{...register(
												`${fieldId}.${index}.${phoneNumberObj?.id}`,
												handleFieldValidation(field)
											)}
											onChange={(event) =>
												handlePhoneFieldChange(
													event,
													index
												)
											}
											placeholder="9876543210"
											type="tel"
											InputProps={{
												endAdornment: null,
											}}
											sx={{
												width: "67%",
												"& .MuiOutlinedInput-notchedOutline":
													{
														borderRadius:
															"8px 0px 0px 8px",
													},
												"& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button":
													{
														"-webkit-appearance":
															"none",
														margin: 0,
													},
											}}
											error={
												!!errors[fieldId]?.[index]?.[
													phoneNumberObj?.id
												]
											}
										/>

										<Select
											{...register(
												`${fieldId}.${index}.${phoneTypeObj?.id}`
											)}
											sx={{
												width: "35%",
												"& .MuiOutlinedInput-notchedOutline":
													{
														borderRadius:
															"0px 8px 8px 0px",
														marginLeft: "-1px",
														borderLeftWidth: 0,
													},
											}}
											defaultValue={() =>
												getDefaultValue(phoneField)
											}
											error={
												!!errors[fieldId]?.[index]?.[
													phoneNumberObj?.id
												]
											}
										>
											{phoneTypeOptions?.map(
												(phoneType) => (
													<MenuItem
														key={phoneType.value}
														value={phoneType.value}
													>
														<MenuItemText>
															{phoneType.name}
														</MenuItemText>
													</MenuItem>
												)
											)}
										</Select>
									</Stack>
									{errors[fieldId]?.[index]?.[
										phoneNumberObj?.id
									] ? (
										<Typography
											maxWidth="340px"
											style={{
												width: "100%",
												fontSize: 13,
												color: theme.palette.error.main,
												marginLeft: "6px",
											}}
										>
											{
												errors[fieldId]?.[index]?.[
													phoneNumberObj?.id
												]?.message
											}
										</Typography>
									) : null}
								</Stack>

								<Box width="6%">
									{phoneFields?.length > 1 &&
										!phoneField.isPrimary && (
											<Box
												display="flex"
												width="100%"
												sx={{ opacity: 0.6 }}
												style={{
													border: "1px solid rgba(0, 0, 0, 0.2)",
													borderRadius:
														"0px 5px 5px 0px",
													borderLeft: "none",
													marginLeft: "-1px",
													display: "flex",
													marginTop: "9px",
													cursor: "pointer",
													height: "23px",
												}}
												onClick={() =>
													removePhone(index)
												}
											>
												{CloseIcon(20, 20)}
											</Box>
										)}
								</Box>
							</Box>
						</Stack>
					))}

					{!isPhoneNumberLimitExceeded() && (
						<Stack width="78%" alignItems="flex-start">
							<TextButton
								size="small"
								startIcon={AddIcon(
									18,
									18,
									theme.palette.secondary.contrastText
								)}
								onClick={() => handleAddPhone()}
							>
								Add
							</TextButton>
						</Stack>
					)}
				</Stack>
				<Stack
					direction="row"
					spacing={2}
					justifyContent="end"
					width="90%"
					pt={2}
				>
					<Box display="flex">
						<Button
							variant="contained"
							color="secondary"
							disableElevation
							onClick={onClose}
							disabled={isSaving}
						>
							Cancel
						</Button>
					</Box>

					<Box display="flex">
						<Button
							variant="contained"
							color="primary"
							disableElevation
							disabled={isSaving}
							onClick={handleSubmit(handleSave)}
						>
							Save
						</Button>
					</Box>
				</Stack>
			</Stack>
		</React.Fragment>
	);
};
