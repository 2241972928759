import { getRootKey } from "../rootKey";

const lifecycleStageKey = "lifecycleStage";

// lifecycle stage key
const getLifecycleStageKey = () => {
	return [...getRootKey(), lifecycleStageKey];
};

// query keys:-
export const allLifecycleStagesKey = [...getLifecycleStageKey(), "allStages"];

export const rulesKey = [...getLifecycleStageKey(), "rules"];

// query key methods:-
export const getLifecycleStatusListKey = (lifecycleStageId, contactId) => {
	return [
		...getLifecycleStageKey(),
		"lifecycleStatusList",
		String(contactId),
		String(lifecycleStageId),
	];
};

export const getLifecycleStatusListKeyById = (contactId) => {
	return [
		...getLifecycleStageKey(),
		"lifecycleStatusList",
		String(contactId),
	];
};
