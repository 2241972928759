import React, { useEffect, useState } from "react";
import {
	Box,
	CircularProgress,
	Dialog,
	IconButton,
	MenuItem,
	Skeleton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../assets/icons/close";
import { default as MinusIcon } from "../../../assets/icons/minus";
import { default as DropdownIcon } from "../../../assets/icons/dropDownCentered";
import { useAvailablePhoneNumberList } from "../../../hooks/services/phone";
import { useLastRowRef } from "../../../hooks/common/InfiniteScroll";
import { MenuItemText, Tooltip } from "../../../styles/twozo";
import WelcomeScreen from "./WelcomeScreen";
import CallerScreen from "./CallerScreen";
import IncomingCall from "./IncomingCall";
import Dialer from "./Dialer";
import Menu from "../../Elements/Menu";

const styles = {
	container: {
		backgroundColor: "#272937",
		color: "#fff",
		pointerEvents: "auto",
		position: "absolute",
		width: "440px",
		boxShadow: "none",
		left: 68,
		bottom: 8,
		margin: "0px",
		borderRadius: "8px",
	},
	menu: {
		padding: "8px 16px",
		backgroundColor: "#323441",
	},
};

export default function CallDialog(props) {
	const {
		isPhoneCallDialogOpened,
		onClosePhoneCallDialog,
		onMinimizePhoneCallDialog,
		hiddenPhoneCallDialog = false,
		incomingCallConnection,
		device,
	} = props;
	const theme = useTheme();

	const [selectedPurchasedNumber, setSelectedPurchasedNumber] = useState("");
	const [phoneNumberMenuElement, setPhoneNumberMenuElement] = useState(null);
	const isPhoneNumberMenuOpened = Boolean(phoneNumberMenuElement);
	const [isCallInprogress, setIsCallInprogress] = useState(false);
	const [isOutgoingCallInitiated, setIsOutgoingCallInitiated] =
		useState(false);
	const [outgoingCallConnection, setOutgoingCallConnection] = useState(null);
	const [outgoingPhoneNumber, setOutgoingPhoneNumber] = useState(null);

	const {
		data: purchasedPhoneNumbers,
		isLoading: isLoadingPhoneNumbers,
		isFetching: isFetchingPhoneNumbers,
		hasNextPage: hasNextPhoneNumbersPage,
		fetchNextPage: fetchNextPhoneNumbersPage,
	} = useAvailablePhoneNumberList();

	const isPhoneNumberPurchased = purchasedPhoneNumbers?.length > 0;
	const lastOptionRef = useLastRowRef(
		fetchNextPhoneNumbersPage,
		hasNextPhoneNumbersPage,
		isFetchingPhoneNumbers
	);

	useEffect(() => {
		if (
			Array.isArray(purchasedPhoneNumbers) &&
			purchasedPhoneNumbers.length > 0
		) {
			setSelectedPurchasedNumber(purchasedPhoneNumbers[0]);
		}
	}, [purchasedPhoneNumbers]);

	const openPhoneNumberMenu = (event) => {
		if (purchasedPhoneNumbers?.length > 1) {
			setPhoneNumberMenuElement(event.currentTarget);
		}
	};

	const closePhoneNumberMenu = () => {
		setPhoneNumberMenuElement(null);
	};

	const handleSelectPhoneNumber = (phoneNumber) => {
		setSelectedPurchasedNumber(phoneNumber);
		closePhoneNumberMenu();
	};

	const makeOutgoingCall = async (phoneNumberData) => {
		if (device && phoneNumberData?.number) {
			const params = {
				To: phoneNumberData?.number,
				callType: "outbound",
				caller: selectedPurchasedNumber?.number,
				callerName: selectedPurchasedNumber?.displayName,
			};
			const connection = await device.connect({
				params,
			});

			connection.on("accept", () => {
				setIsCallInprogress(true);
				setIsOutgoingCallInitiated(false);
			});
			connection.on("disconnect", () => {
				setIsCallInprogress(false);
				setIsOutgoingCallInitiated(false);
				setOutgoingPhoneNumber(null);
			});

			setOutgoingPhoneNumber(phoneNumberData);
			setOutgoingCallConnection(connection);
			setIsOutgoingCallInitiated(true);
		}
	};

	const acceptIncomingCall = () => {
		if (incomingCallConnection) {
			incomingCallConnection.accept();
			setIsCallInprogress(true);
		}
	};

	const rejectCall = () => {
		if (incomingCallConnection) {
			incomingCallConnection.reject();
			incomingCallConnection.disconnect();
			setIsCallInprogress(false);
			onClosePhoneCallDialog();
		}
		if (outgoingCallConnection) {
			outgoingCallConnection.disconnect();
			setIsCallInprogress(false);
			setOutgoingCallConnection(null);
			setOutgoingPhoneNumber(null);
		}
	};

	incomingCallConnection?.on("cancel", () => {
		onClosePhoneCallDialog();
		setIsCallInprogress(false);
	});

	incomingCallConnection?.on("disconnect", () => {
		onClosePhoneCallDialog();
		setIsCallInprogress(false);
	});

	return (
		<React.Fragment>
			<Dialog
				open={isPhoneCallDialogOpened}
				disableEnforceFocus
				style={{ pointerEvents: "none" }}
				hideBackdrop={true}
				PaperProps={{
					style: {
						...styles.container,
						border: isOutgoingCallInitiated
							? `3px solid ${theme.palette.primary.main}`
							: "none",
					},
				}}
				hidden={hiddenPhoneCallDialog}
			>
				{/* Buyed phone numbers menu */}
				<Menu
					minWidth="300px"
					anchorEl={phoneNumberMenuElement}
					open={isPhoneNumberMenuOpened}
					onClose={closePhoneNumberMenu}
					style={{ maxHeight: "130px" }}
				>
					{isLoadingPhoneNumbers ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="80px"
						>
							<CircularProgress size="25px" />
						</Stack>
					) : (
						purchasedPhoneNumbers?.map((phoneNumber) => (
							<MenuItem
								key={phoneNumber.id}
								style={{
									minHeight: "40px",
								}}
								selected={
									selectedPurchasedNumber?.id ===
									phoneNumber.id
								}
								onClick={() =>
									handleSelectPhoneNumber(phoneNumber)
								}
							>
								<MenuItemText>
									<span>{phoneNumber.displayName}</span>

									<span style={{ padding: "0px 4px" }}>
										{" "}
										-{" "}
									</span>

									<span>{phoneNumber.number}</span>
								</MenuItemText>
							</MenuItem>
						))
					)}
					<Box ref={lastOptionRef}>
						{!isLoadingPhoneNumbers &&
							isFetchingPhoneNumbers &&
							purchasedPhoneNumbers?.length > 0 && (
								<MenuItem style={{ height: "40px" }}>
									<Skeleton width="190px" height="16px" />
								</MenuItem>
							)}
					</Box>
				</Menu>

				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					style={styles.menu}
				>
					{isPhoneNumberPurchased ? (
						<Stack
							direction="row"
							alignItems="center"
							spacing={0.5}
							onClick={openPhoneNumberMenu}
						>
							{incomingCallConnection?.parameters?.To ? (
								<Typography fontSize={13} fontWeight={500}>
									<span style={{ color: "#fff" }}>
										Incoming Call
									</span>

									<span
										style={{
											padding: "0px 4px",
											color: "rgba(255, 255, 255, 0.75)",
										}}
									>
										via
									</span>

									<span
										style={{
											color: "rgba(255, 255, 255, 0.75)",
										}}
									>
										{incomingCallConnection.parameters.To}
									</span>
								</Typography>
							) : (
								<Stack direction="row">
									<Typography
										fontSize={13}
										fontWeight={500}
										color="rgba(255, 255, 255, 0.75)"
									>
										<span style={{ color: "#fff" }}>
											{
												selectedPurchasedNumber?.displayName
											}
										</span>

										<span style={{ padding: "0px 4px" }}>
											{" "}
											-{" "}
										</span>

										<span>
											{selectedPurchasedNumber?.number}
										</span>
									</Typography>
									{purchasedPhoneNumbers?.length > 1 ? (
										<Box display="flex">
											{DropdownIcon(
												16,
												16,
												"rgba(255, 255, 255, 0.75)"
											)}
										</Box>
									) : null}
								</Stack>
							)}
						</Stack>
					) : (
						<Typography fontSize={13} fontWeight={500}>
							Twozo Caller
						</Typography>
					)}

					<Stack
						direction="row"
						alignItems="center"
						justifyContent="center"
						spacing={1.5}
					>
						{!hiddenPhoneCallDialog ? (
							<Tooltip title="Minimize" placement="top">
								<IconButton
									size="small"
									onClick={onMinimizePhoneCallDialog}
								>
									{MinusIcon(20, 2, "#fff")}
								</IconButton>
							</Tooltip>
						) : null}

						<Tooltip title="Close" placement="top">
							<IconButton
								size="small"
								onClick={onClosePhoneCallDialog}
							>
								{CloseIcon(20, 20, "#fff")}
							</IconButton>
						</Tooltip>
					</Stack>
				</Stack>

				<Box p={2}>
					{incomingCallConnection && !isCallInprogress ? (
						<IncomingCall
							incomingCallConnection={incomingCallConnection}
							acceptCall={acceptIncomingCall}
							rejectCall={rejectCall}
						/>
					) : isCallInprogress || isOutgoingCallInitiated ? (
						<CallerScreen
							incomingCallConnection={incomingCallConnection}
							outgoingPhoneNumber={outgoingPhoneNumber}
							isOutgoingCallInitiated={isOutgoingCallInitiated}
							isCallInprogress={isCallInprogress}
							rejectCall={rejectCall}
						/>
					) : isPhoneNumberPurchased ? (
						<Dialer makeOutgoingCall={makeOutgoingCall} />
					) : (
						<WelcomeScreen />
					)}
				</Box>
			</Dialog>
		</React.Fragment>
	);
}
