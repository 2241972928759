import React, { useEffect } from "react";
import PermissionList from "../PermisionList";
import { usePermissionContext } from "../../../PermissionContext";
import { quotasAndForecastingIds } from "../../../../../../../utils/PermissionUtils";

export default function QuotasAndForecasting(props) {
	const { permissions, isEditable, isCreateMode } = props;

	const {
		permissionState,
		updateQuotasAndForecastingList,
		initializeQuotasAndForecastingListForPermission,
	} = usePermissionContext();

	useEffect(() => {
		if (permissions) {
			initializeQuotasAndForecastingListForPermission(permissions);
		}
	}, [permissions]);

	const handleAccessChange = (event, id) => {
		if (
			id === quotasAndForecastingIds.viewQuotas ||
			id === quotasAndForecastingIds.teamView
		) {
			if (!event.target.checked) {
				const updatedQuotasAndForecastingList =
					permissionState.quotasAndForecastingList.map((list) => {
						if (
							list.id === id ||
							list.id === quotasAndForecastingIds.teamView ||
							list.id === quotasAndForecastingIds.viewQuotas
						) {
							return {
								...list,
								access: event.target.checked,
							};
						} else if (
							list.id === quotasAndForecastingIds.commitDeal
						) {
							return {
								...list,
							};
						} else {
							return {
								...list,
								access: event.target.checked,
								accessDisable: !event.target.checked,
							};
						}
					});
				updateQuotasAndForecastingList(updatedQuotasAndForecastingList);
			} else {
				const updatedQuotasAndForecastingList =
					permissionState.quotasAndForecastingList.map((list) => {
						if (
							list.id === id ||
							list.id === quotasAndForecastingIds.teamView ||
							list.id === quotasAndForecastingIds.viewQuotas
						) {
							return {
								...list,
								access: event.target.checked,
							};
						} else if (
							list.id === quotasAndForecastingIds.commitDeal
						) {
							return {
								...list,
							};
						} else {
							return {
								...list,
								accessDisable: !event.target.checked,
							};
						}
					});
				updateQuotasAndForecastingList(updatedQuotasAndForecastingList);
			}
		} else {
			const updatedQuotasAndForecastingList =
				permissionState.quotasAndForecastingList.map((list) => {
					if (list.id === id) {
						return {
							...list,
							access: event.target.checked,
						};
					} else {
						return list;
					}
				});
			updateQuotasAndForecastingList(updatedQuotasAndForecastingList);
		}
	};

	const handleChange = (event, id) => {
		const updatedQuotasAndForecastingList =
			permissionState.quotasAndForecastingList.map((list) => {
				if (list.id === id) {
					return {
						...list,
						permissions: {
							...list.permissions,
							permission: list.permissions.permission.map(
								(permission) =>
									permission.name === event.target.value
										? { ...permission, value: true }
										: { ...permission, value: false }
							),
						},
					};
				} else {
					return list;
				}
			});
		updateQuotasAndForecastingList(updatedQuotasAndForecastingList);
	};

	return (
		<React.Fragment>
			<PermissionList
				isViewName="Quotas and Forecasting"
				lists={permissionState.quotasAndForecastingList}
				handleAccessChange={handleAccessChange}
				handleChange={handleChange}
				isEditable={isEditable}
				isCreateMode={isCreateMode}
			/>
		</React.Fragment>
	);
}
