import React, { useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { hexToRgba, MenuItemText } from "../../../../../styles/twozo";
import {
	useContactLostReasonList,
	useUpdateContactLifecycleStatus,
} from "../../../../../hooks/services/contact";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import MenuSearchBar from "../../../../Elements/MenuSearchBar";

export default function ClosedLost(props) {
	const {
		closeLostDialog,
		statusData,
		contactId,
		lifecycleStageId,
		lifecycleStatusId,
		onUpdateStatusSuccess,
	} = props;

	const theme = useTheme();

	const [searchValue, setSearchValue] = useState("");

	// query call
	const { data: lostReasonsList, isLoading: isLostReasonsListLoading } =
		useContactLostReasonList(searchValue);

	// mutation call:-
	const updateMutation = useUpdateContactLifecycleStatus(contactId);

	const [selectedStatus, setSelectedStatus] = useState(
		statusData || { name: "", value: "" }
	);

	const [selectedLostReason, setSelectedLostReason] = useState({
		name: "",
		value: "",
	});

	const handleChangeLostReason = (event, child) => {
		const { value } = event.target;
		setSelectedLostReason({ name: child.props.name, value: value });
	};

	const handleChange = (event) => {
		setSelectedStatus(event.target.value);
	};

	const onSave = () => {
		let requestData = {
			id: contactId,
			lifecycleStageId: lifecycleStageId,
			lifecycleStatusId: lifecycleStatusId,
			contactLostReason: selectedLostReason.value,
		};

		updateMutation.mutate(requestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.contactLifecycleUpdated,
				});
				onUpdateStatusSuccess();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const isSaveButtonDisabled = () => {
		return !selectedLostReason.value;
	};

	const handleSearchValue = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const getLostReasonRenderValue = () => {
		return selectedLostReason?.name ? selectedLostReason.name : "Select";
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					{searchValue == ""
						? "No Options Found"
						: "No Results Found"}
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			<Stack p={2} spacing={2}>
				<Stack spacing={0.5}>
					<Typography
						fontSize={13}
						fontWeight={500}
						style={{ opacity: 0.6 }}
					>
						Status
					</Typography>

					<Select
						size="small"
						value={selectedStatus?.value}
						onChange={handleChange}
						readOnly={true}
					>
						<MenuItem
							style={{ color: "rgba(0, 0, 0, 1)" }}
							value={selectedStatus?.value}
						>
							{selectedStatus?.name}
						</MenuItem>
					</Select>
				</Stack>

				<Stack spacing={0.5}>
					<Typography fontSize={13} fontWeight={500}>
						Reason
						<span
							style={{
								color: theme.palette.error.main,
								marginLeft: "5px",
							}}
						>
							*
						</span>
					</Typography>

					<Select
						size="small"
						onChange={handleChangeLostReason}
						value={selectedLostReason.value}
						displayEmpty
						MenuProps={{
							PaperProps: {
								style: {
									maxHeight: 290,
									marginTop: "4px",
								},
							},
							autoFocus: false,
						}}
						renderValue={getLostReasonRenderValue}
						onOpen={() => setSearchValue("")}
					>
						<Box
							p={0.5}
							marginTop="-8px"
							sx={{
								position: "sticky",
								top: 0,
								zIndex: 1,
								backgroundColor: "#fff",
							}}
						>
							<MenuSearchBar
								value={searchValue}
								onChange={handleSearchValue}
							/>
						</Box>
						{isLostReasonsListLoading ? (
							<Stack
								justifyContent="center"
								alignItems="center"
								width="100%"
								height="100px"
							>
								<CircularProgress size="25px" />
							</Stack>
						) : lostReasonsList?.length > 0 ? (
							lostReasonsList?.map((option) => (
								<MenuItem
									value={option.value}
									key={option.value}
									name={option.name}
								>
									<Stack
										direction="row"
										spacing={1}
										alignItems="center"
									>
										<MenuItemText>
											{option.name}
										</MenuItemText>
									</Stack>
								</MenuItem>
							))
						) : (
							renderEmptyView()
						)}
					</Select>
				</Stack>

				<Box p={1}>
					<Stack
						direction="row"
						justifyContent="flex-end"
						spacing={2}
					>
						<Button
							onClick={closeLostDialog}
							variant="contained"
							color="secondary"
							disableElevation
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							onClick={onSave}
							disableElevation
							disabled={isSaveButtonDisabled()}
							sx={{
								"&.Mui-disabled": {
									backgroundColor: theme.palette.primary.main,
									color: hexToRgba("#fff", 0.6),
								},
							}}
						>
							Save
						</Button>
					</Stack>
				</Box>
			</Stack>
		</React.Fragment>
	);
}
