import React, { useState } from "react";
import {
	IconButton,
	Divider,
	InputAdornment,
	Stack,
	useTheme,
	Box,
	Typography,
	TextField,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../../assets/icons/close";
import { default as UserIcon } from "../../../../../../assets/icons/singleContact";
import { default as TeamIcon } from "../../../../../../assets/icons/teams";
import { default as TerritoryIcon } from "../../../../../../assets/icons/location";
import { default as SearchIcon } from "../../../../../../assets/icons/search";
import { TableHeaderLabel } from "../../../../../../styles/twozo";
import Autocomplete from "../../../../Autocomplete";

export default function SelectedView(props) {
	const {
		selectedUserTeamTerritoryValue,
		updateUserTeamTerritoryValue,
		getSelectedUserList,
		getSelectedTeamList,
		getSelectedTerritoryList,
		userTeamTerritoryList,
	} = props;
	const theme = useTheme();
	const [searchInputValue, setSearchInputValue] = useState("");

	const styles = {
		tableData: {
			backgroundColor: "#FFF",
			borderRadius: "8px",
			justifyContent: "space-between",
			alignItems: "center",
			padding: "4px",
			margin: "6px 12px",
			cursor: "pointer",
		},
		searchFieldStyle: {
			"& .MuiInputBase-root": {
				backgroundColor: "#FFF",
				borderRadius: "8px",
			},
			input: {
				"&::placeholder": {
					color: "black",
					opacity: 0.6,
				},
			},
			"& .MuiOutlinedInput-notchedOutline": {
				borderRadius: "8px",
			},
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					border: "none",
				},
				"&:hover fieldset": {
					border: "none",
				},
				"&.Mui-focused fieldset": {
					border: "none",
				},
			},
		},
	};

	const optionsType = {
		user: "USER",
		team: "TEAM",
		territory: "TERRITORY",
	};

	const getOptionsIcon = (optionType) => {
		switch (optionType) {
			case optionsType.user:
				return UserIcon(16, 16, "rgba(0, 0, 0, 0.4)");
			case optionsType.team:
				return TeamIcon(16, 16, "rgba(0, 0, 0, 0.4)");
			case optionsType.territory:
				return TerritoryIcon(16, 16, "rgba(0, 0, 0, 0.4)");
			default:
				return null;
		}
	};

	const isLoggedInUser = (option) => {
		return option?.config?.isLoggedInUser;
	};

	const handleSelectUserTeamTerritory = (_, values) => {
		updateUserTeamTerritoryValue(values);
		setSearchInputValue("");
	};

	const removeSelectedUserTeamTerritory = (removedData) => {
		updateUserTeamTerritoryValue((selectedUserTeamTerritoryValue) =>
			selectedUserTeamTerritoryValue.filter(
				(selectedValue) => selectedValue.value !== removedData.value
			)
		);
	};

	return (
		<React.Fragment>
			<Stack
				padding={0.5}
				borderRadius={2}
				bgcolor={theme.palette.secondary.main}
			>
				<Autocomplete
					freeSolo={false}
					options={userTeamTerritoryList || []}
					value={selectedUserTeamTerritoryValue}
					onChange={handleSelectUserTeamTerritory}
					open={!!searchInputValue}
					renderTags={() => null}
					noOptionsText="no results found"
					filterSelectedOptions
					renderOption={(props, option) => (
						<Box
							component="li"
							key={option?.value}
							{...props}
							style={{
								minHeight: "40px",
								opacity: isLoggedInUser(option) ? 0.6 : 1,
								pointerEvents: isLoggedInUser(option)
									? "none"
									: "auto",
							}}
						>
							<Stack
								style={{
									width: "100%",
								}}
								direction="row"
								alignItems="center"
								justifyContent="space-between"
							>
								<Typography fontSize={13} fontWeight={400}>
									{option.name}
								</Typography>

								{getOptionsIcon(option.type)}
							</Stack>
						</Box>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							value={searchInputValue}
							onChange={(event) =>
								setSearchInputValue(event.target.value)
							}
							onBlur={() => setSearchInputValue("")}
							sx={{
								...styles.searchFieldStyle,
								"& input::placeholder": {
									fontSize: "13px",
									fontWeight: 500,
									opacity: 0.6,
								},
							}}
							placeholder="Search Users, Teams, Territories"
							inputProps={{
								...params.inputProps,
								style: {
									...params.inputProps?.style,
									fontSize: "14px",
								},
							}}
							InputProps={{
								...params.InputProps,
								startAdornment: (
									<>
										<InputAdornment
											position="start"
											sx={{
												paddingLeft: "10px",
											}}
										>
											{SearchIcon(
												14,
												14,
												"rgba(0, 0, 0, 0.6)",
												"2"
											)}
										</InputAdornment>
										{params.InputProps.startAdornment}
									</>
								),
							}}
						/>
					)}
				/>

				<Stack direction="row" width="100%">
					<Box width="33%" style={{ padding: "6px 12px" }}>
						<TableHeaderLabel>Users</TableHeaderLabel>
					</Box>

					<Box width="33%" style={{ padding: "6px 12px" }}>
						<TableHeaderLabel>Teams</TableHeaderLabel>
					</Box>

					<Box width="33%" style={{ padding: "6px 12px" }}>
						<TableHeaderLabel>Territories</TableHeaderLabel>
					</Box>
				</Stack>

				<Divider style={{ margin: "0px 6px" }} />

				<Stack
					direction="row"
					width="100%"
					style={{
						height: "110px",
						overflowY: "auto",
					}}
				>
					<Box width="33%">
						{getSelectedUserList().length > 0
							? getSelectedUserList().map((userData) => (
									<Stack
										key={userData.value}
										direction="row"
										alignItems="center"
										style={styles.tableData}
										width="135px"
									>
										<Typography
											fontSize={13}
											fontWeight={500}
										>
											{userData.name}
										</Typography>

										<IconButton
											style={{
												padding: "0px",
											}}
											onClick={() =>
												removeSelectedUserTeamTerritory(
													userData
												)
											}
										>
											{CloseIcon(
												16,
												16,
												theme.palette.primary.main
											)}
										</IconButton>
									</Stack>
								))
							: null}
					</Box>

					<Box width="33%">
						{getSelectedTeamList().length > 0
							? getSelectedTeamList().map((teamData) => (
									<Stack
										key={teamData.value}
										direction="row"
										alignItems="center"
										style={styles.tableData}
										width="135px"
									>
										<Typography
											fontSize={13}
											fontWeight={500}
										>
											{teamData.name}
										</Typography>

										<IconButton
											style={{
												padding: "0px",
											}}
											onClick={() =>
												removeSelectedUserTeamTerritory(
													teamData
												)
											}
										>
											{CloseIcon(
												16,
												16,
												theme.palette.primary.main
											)}
										</IconButton>
									</Stack>
								))
							: null}
					</Box>

					<Box width="33%">
						{getSelectedTerritoryList().length > 0
							? getSelectedTerritoryList().map(
									(territoryData) => (
										<Stack
											key={territoryData.value}
											direction="row"
											alignItems="center"
											style={styles.tableData}
											width="135px"
										>
											<Typography
												fontSize={13}
												fontWeight={500}
											>
												{territoryData.name}
											</Typography>

											<IconButton
												style={{
													padding: "0px",
												}}
												onClick={() =>
													removeSelectedUserTeamTerritory(
														territoryData
													)
												}
											>
												{CloseIcon(
													16,
													16,
													theme.palette.primary.main
												)}
											</IconButton>
										</Stack>
									)
								)
							: null}
					</Box>
				</Stack>
			</Stack>
		</React.Fragment>
	);
}
