import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
	Divider,
	Stack,
	ToggleButton,
	Typography,
	useTheme,
	MenuItem,
	Checkbox,
	ButtonGroup,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { MenuItemText, twozoStyles } from "../../../styles/twozo";
import { default as DropDownIcon } from "../../../assets/icons/dropDownCentered";
import { default as AddIcon } from "../../../assets/icons/add";
import ToggleButtonGroup from "../../Elements/ToggleButtonGroup";
import Prices from "./Prices";
import Deals from "./Deals";
import VariationList from "./VariationList";
import Menu from "../../Elements/Menu";
import SummaryCardView from "../../Elements/SummaryCardView";
import EditProduct from "../EditProduct";
import ProductSummaryLoader from "../../Elements/Loader/SummaryLoader/ProductSummaryLoader";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import { useModuleName } from "../../../hooks/modules";
import { modules } from "../../../utils/common/ModulesName";
import ProductMenu from "./Menu";
import { default as CloseIcon } from "../../../assets/icons/close";
import { getDropdownDataByName } from "../../../utils/dropdownData";
import {
	usePartialUpdateProduct,
	useProductSummaryData,
} from "../../../hooks/services/product";
import { useAuth } from "../../../hooks/auth";
import Can from "../../Auth/Can";
import CanNot from "../../Auth/CanNot";
import UnAuthorized from "../../Auth/UnAuthorized";
import { PERMISSIONS } from "../../../utils/Auth";
import { useDealFieldPermissions } from "../../../hooks/modules/auth/deal";
import FieldPermissionContextProvider from "../../Elements/FieldPermissionContext/FieldPermissionContextProvider";
import { SummaryContextProvider } from "../../Summary/SummaryContext/SummaryContextProvider";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";

export default function ProductDetails() {
	let { productId } = useParams();
	const theme = useTheme();
	const navigate = useNavigate();
	const classes = twozoStyles();
	const { getModuleName } = useModuleName();

	// mutation call:-
	const updateMutation = usePartialUpdateProduct(productId);

	const productDetailsRef = useRef(null);
	const detailsCardContainerRef = useRef(null);
	const variationRef = useRef(null);
	const [view, setView] = useState("prices");
	const [productDetailsStartingPosition, setProductDetailsStartingPosition] =
		useState(0);
	const [dealStatusDropDownMenu, setDealStatusDropDownMenu] = useState(null);
	const openMenuItem = Boolean(dealStatusDropDownMenu);
	const [clientWidth, setClientWidth] = useState(0);
	const [scrollWidth, setScrollWidth] = useState(0);
	const [isEditFormDialogOpen, setIsEditFormDialogOpen] = useState(false);
	const [summaryFields, setSummaryFields] = useState([]);
	const [summarySubFields, setSummarySubFields] = useState([]);
	const [profile, setProfile] = useState({});
	const viewName = getModuleName(modules.PRODUCT);
	const [selectedDealStateId, setSelectedDealStateId] = useState([]);

	const selectedDealStateCount = selectedDealStateId.length;

	const dealState = getDropdownDataByName("STATE");

	const dealFieldPermissions = useDealFieldPermissions();
	const { isUserAllowedFor } = useAuth();

	// permission
	const hasEditPermission = isUserAllowedFor(PERMISSIONS.product.edit);

	const {
		data: productSummaryData,
		status: productSummaryApiStatus,
		isLoading: isProductSummaryDetailsLoading,
	} = useProductSummaryData(productId);

	useEffect(() => {
		setClientWidth(detailsCardContainerRef.current?.clientWidth);
		setScrollWidth(detailsCardContainerRef.current?.scrollWidth);
	}, [scrollWidth, clientWidth]);

	const updateClientWidth = () => {
		setClientWidth(detailsCardContainerRef.current?.clientWidth);
	};

	useEffect(() => {
		window.addEventListener("resize", updateClientWidth);
		return () => window.removeEventListener("resize", updateClientWidth);
	}, []);

	useEffect(() => {
		if (productSummaryApiStatus === "success") {
			if (productSummaryData.summary) {
				setSummaryFields(productSummaryData.summary);
			}

			if (productSummaryData.subFields) {
				setSummarySubFields(productSummaryData.subFields);
			}

			if (productSummaryData.profile) {
				setProfile(productSummaryData.profile);
			}
		}
	}, [productSummaryApiStatus, productSummaryData]);

	useEffect(() => {
		if (productDetailsRef?.current) {
			setProductDetailsStartingPosition(
				productDetailsRef.current.getBoundingClientRect().top
			);
		}
	}, [productDetailsRef]);

	const handleViewToggleButtonGroup = (e, type) => {
		if (type) {
			setView(type);
		}
	};

	const openDropDownMenu = (event) => {
		setDealStatusDropDownMenu(event.currentTarget);
	};

	const closeDropDowmMenu = () => {
		setDealStatusDropDownMenu(null);
	};

	const handleDealState = (dealStateId) => {
		if (selectedDealStateId.includes(dealStateId)) {
			setSelectedDealStateId((selectedDealStateId) =>
				selectedDealStateId.filter((id) => {
					return id !== dealStateId;
				})
			);
		} else {
			setSelectedDealStateId([...selectedDealStateId, dealStateId]);
		}
	};

	const handleRemoveSelectedDealState = () => {
		setSelectedDealStateId([]);
	};

	const addVariation = () => {
		if (isUserAllowedFor(PERMISSIONS.product.edit)) {
			variationRef.current.handleCreateNewVariation();
		}
	};

	const toggleEditFormDialog = () => {
		setIsEditFormDialogOpen(
			(isEditFormDialogOpen) => !isEditFormDialogOpen
		);
	};

	const renderDealComponent = () => {
		return (
			<>
				<Can permission={PERMISSIONS.deal.create}>
					<Deals
						productId={productId}
						selectedDealStateId={selectedDealStateId}
					/>
				</Can>

				<CanNot permission={PERMISSIONS.deal.create}>
					<Stack height="50vh">
						<UnAuthorized />
					</Stack>
				</CanNot>
			</>
		);
	};

	const updateSummaryField = (summaryData, onClose) => {
		updateMutation.mutate(summaryData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.productUpdated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isEditFormDialogOpen}
				onOpen={toggleEditFormDialog}
				onClose={toggleEditFormDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<EditProduct
						productId={productId}
						onClose={toggleEditFormDialog}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Box>
				<Box>
					<Menu
						minWidth="150px"
						anchorEl={dealStatusDropDownMenu}
						open={openMenuItem}
						onClose={closeDropDowmMenu}
						style={{
							marginTop: "4px",
						}}
					>
						{dealState.map((state) => (
							<MenuItem
								key={state.value}
								onClick={() => handleDealState(state.value)}
								style={{ height: "40px" }}
							>
								<Stack
									direction="row"
									alignItems="center"
									spacing={2}
								>
									<Checkbox
										checked={selectedDealStateId.includes(
											state.value
										)}
									/>
									<MenuItemText>{state.name}</MenuItemText>
								</Stack>
							</MenuItem>
						))}
					</Menu>
				</Box>

				{isProductSummaryDetailsLoading ? (
					<ProductSummaryLoader moduleName={`All ${viewName}`} />
				) : (
					<Box>
						<Box className={classes.menuBar}>
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								height="100%"
							>
								<Stack
									direction="row"
									spacing={1.5}
									alignItems="center"
								>
									<Box
										style={{ cursor: "pointer" }}
										onClick={() => navigate("/products")}
									>
										<Typography
											fontWeight={500}
											fontSize={15}
											color={
												theme.palette.secondary
													.contrastText
											}
										>
											{`All ${viewName}`}
										</Typography>
									</Box>

									<Box
										style={{
											transform: "rotate(-90deg)",
										}}
									>
										{DropDownIcon(16, 16)}
									</Box>

									<Typography
										fontWeight={500}
										fontSize={15}
										noWrap
										maxWidth="1000px"
									>
										{profile?.name?.value}
									</Typography>
								</Stack>

								<Box>
									<ProductMenu
										toggleEditFormDialog={
											toggleEditFormDialog
										}
										productId={productId}
									/>
								</Box>
							</Stack>
						</Box>

						<FieldPermissionContextProvider
							value={dealFieldPermissions}
						>
							<SummaryContextProvider
								hasEditPermission={hasEditPermission}
								entityId={productId}
								source={modules.PRODUCT}
								isSaving={updateMutation.isLoading}
								updateSummaryField={updateSummaryField}
							>
								<SummaryCardView
									summaryFields={summaryFields}
									summarySubFields={summarySubFields}
								/>
							</SummaryContextProvider>
						</FieldPermissionContextProvider>

						<Box px={2}>
							<Divider />
						</Box>
						<Box p={1}>
							<Box
								ref={productDetailsRef}
								className={classes.detailsContainer}
								style={{
									minHeight: `calc(100vh - ${
										productDetailsStartingPosition + 8
									}px)`,
								}}
							>
								<Stack direction="row" spacing={3}>
									<ToggleButtonGroup
										value={view}
										onChange={handleViewToggleButtonGroup}
									>
										<ToggleButton value="prices">
											Prices
										</ToggleButton>
										<ToggleButton value="deals">
											Deals
										</ToggleButton>
										<ToggleButton value="variations">
											Variations
										</ToggleButton>
									</ToggleButtonGroup>

									{view === "deals" && (
										<ButtonGroup
											variant="contained"
											disableElevation
											color="secondary"
											sx={{
												".MuiButtonGroup-grouped:not(:last-of-type)":
													{
														borderRight: "none",
														borderRadius:
															selectedDealStateCount ===
															0
																? "8px"
																: "8px 0px 0px 8px",
													},
											}}
										>
											<Button
												variant="contained"
												endIcon={DropDownIcon(
													16,
													16,
													theme.palette.secondary
														.contrastText
												)}
												color="secondary"
												disableElevation
												onClick={openDropDownMenu}
											>
												{`State ${
													selectedDealStateCount > 0
														? `(${selectedDealStateCount})`
														: ""
												}`}
											</Button>

											<Divider
												orientation="vertical"
												sx={{
													height: "95%",
													display:
														selectedDealStateCount ===
															0 && "none",
												}}
											/>

											<Button
												sx={{
													padding: 0,
													borderLeft: "none",
													display:
														selectedDealStateCount ===
															0 && "none",
												}}
												onClick={
													handleRemoveSelectedDealState
												}
											>
												{CloseIcon(
													19,
													19,
													theme.palette.secondary
														.contrastText
												)}
											</Button>
										</ButtonGroup>
									)}

									{view === "variations" && (
										<Button
											variant="contained"
											startIcon={AddIcon(
												20,
												20,
												theme.palette.secondary
													.contrastText
											)}
											color="secondary"
											disableElevation
											onClick={addVariation}
										>
											Variation
										</Button>
									)}
								</Stack>

								<Box pt={2} mx={-1}>
									{(function () {
										switch (view) {
											case "prices":
												return (
													<Prices
														productId={productId}
													/>
												);
											case "deals":
												return renderDealComponent();
											case "variations":
												return (
													<VariationList
														productId={productId}
														ref={variationRef}
													/>
												);
											default:
												return null;
										}
									})()}
								</Box>
							</Box>
						</Box>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}
