import React, { createContext, useContext, useEffect, useState } from "react";
import { useCurrentUserDetails } from "../hooks/services/userProfile";
import { useAuthContext } from "./AuthContextProvider";
import { getZoneIdByTimeZoneValue } from "../utils/DateUtils";
import moment from "moment-timezone";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const UserConfigContext = createContext();

export const useUserConfigContext = () => {
	return useContext(UserConfigContext);
};

export const UserConfigContextProvider = (props) => {
	const { children } = props;
	const { permissions } = useAuthContext();
	const [currentUserZoneId, setCurrentUserZoneId] = useState("");

	const { data: currentUserDetails } = useCurrentUserDetails(
		!permissions?.isUserNotLoggedIn
	);

	useEffect(() => {
		const userTimeZoneValue =
			currentUserDetails?.user?.preferences?.userRegionalPreference
				?.timeZone;
		const updateTimeZone = (timeZoneId) => {
			// Set the default timezone
			dayjs.tz.setDefault(timeZoneId);
			moment.tz.setDefault(timeZoneId);
			setCurrentUserZoneId(timeZoneId);
		};

		if (userTimeZoneValue) {
			let currentUserZoneId = getZoneIdByTimeZoneValue(userTimeZoneValue);
			updateTimeZone(currentUserZoneId);
		} else {
			updateTimeZone(dayjs.tz.guess());
		}
	}, [currentUserDetails]);

	return (
		<UserConfigContext.Provider value={{ timeZoneId: currentUserZoneId }}>
			{children}
		</UserConfigContext.Provider>
	);
};
