import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Checkbox,
	CircularProgress,
	Stack,
	Typography,
} from "@mui/material";
import MenuSearchBar from "../../../Elements/MenuSearchBar";
import { useMailList } from "../../../../hooks/services/mail";
import { mailListName } from "../../../../utils/EmailUtils/mailListName";
import MetricsListRow from "./MetricsListRow";
import { useLastRowRef } from "../../../../hooks/common/InfiniteScroll";
import NoConversationsFound from "../../NoConversationsFound";

const styles = {
	headerText: {
		fontSize: "14px",
		fontWeight: 500,
		color: "rgba(0, 0, 0, 0.6)",
	},
	text: {
		fontSize: "14px",
	},
};

export default function Metrics() {
	const mailListRef = useRef();
	let bulkMetrics = mailListName.BULK_METRICS;
	const {
		data: mails,
		isLoading: isMailListLoading,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useMailList(bulkMetrics);

	const [topBarHeight, setTopBarHeight] = useState(0);

	const firstPageMetrics = mails?.pages?.[0]?.list;
	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	useEffect(() => {
		if (mailListRef) {
			setTopBarHeight(mailListRef.current.getBoundingClientRect().top);
		}
	}, [mailListRef]);

	return (
		<React.Fragment>
			<Box>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					py={1.25}
					sx={{ height: "50px" }}
				>
					<Stack
						direction="row"
						alignItems="center"
						spacing={2}
						px={2}
					>
						<Box>
							<Checkbox disabled />
						</Box>

						<Typography fontWeight={500} fontSize={15}>
							Bulk Email - Metrics
						</Typography>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						spacing={3}
						pr={3}
					>
						<MenuSearchBar style={{ width: "300px" }} />
					</Stack>
				</Stack>

				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					style={{
						borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
					}}
					pl={2}
					pr={3}
					py={1}
				>
					<Box width="25%" />

					<Stack direction="row" width="60%">
						<Box width="20%">
							<Typography style={styles.headerText}>
								Sent
							</Typography>
						</Box>

						<Box width="20%">
							<Typography style={styles.headerText}>
								Opened
							</Typography>
						</Box>

						<Box width="20%">
							<Typography style={styles.headerText}>
								Clicked
							</Typography>
						</Box>

						<Box width="20%">
							<Typography style={styles.headerText}>
								Replied
							</Typography>
						</Box>

						<Box width="20%">
							<Typography style={styles.headerText}>
								Bounced
							</Typography>
						</Box>

						<Box width="20%">
							<Typography style={styles.headerText}>
								Unsubscribed
							</Typography>
						</Box>
					</Stack>

					<Box width="15%"></Box>
				</Stack>

				<Box
					ref={mailListRef}
					sx={{
						height: `calc(100vh - ${topBarHeight + 8}px)`,
						overflowY: "auto",
					}}
				>
					{isMailListLoading ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="60vh"
						>
							<CircularProgress />
						</Stack>
					) : mails?.pages?.length > 0 &&
					  firstPageMetrics?.length > 0 ? (
						mails.pages.map((page) =>
							page?.list?.map((metricData) => (
								<MetricsListRow
									key={metricData.id}
									metricData={metricData}
								/>
							))
						)
					) : (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="65vh"
							spacing={0.5}
						>
							<NoConversationsFound />
						</Stack>
					)}

					<Box style={{ height: "52px" }} ref={lastRowRef}>
						{!isMailListLoading && isFetching ? (
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="center"
								spacing={1}
								py={2}
							>
								<CircularProgress size={18} />
								<Typography fontSize={12}>
									Loading More Data
								</Typography>
							</Stack>
						) : null}
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	);
}
