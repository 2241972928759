import {
	Box,
	Button,
	CircularProgress,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { TableHeaderLabel, twozoStyles } from "../../../../styles/twozo";
import ContactImage from "../../../../assets/images/contact/unknownContact.png";
import AddReviewDuplicates from "./ReviewDuplicates";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import {
	useDismissDuplicateContact,
	useDuplicateContactList,
} from "../../../../hooks/services/contact/contactDuplicates";
import ConfirmDialog from "../../ConfirmDialog";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import Can from "../../../Auth/Can";
import { PERMISSIONS } from "../../../../utils/Auth";
import CanNot from "../../../Auth/CanNot";
import UnAuthorized from "../../../Auth/UnAuthorized";
import TooltipComponent from "../../TooltipComponent";
import { useLastRowRef } from "../../../../hooks/common/InfiniteScroll";
import { useAuth } from "../../../../hooks/auth";

export default function DuplicateContact() {
	const classes = twozoStyles();
	const contactDuplicateListRef = useRef();
	const [isReviewDialogOpened, setIsReviewDialogOpened] = useState(false);
	const [primaryContact, setPrimaryContact] = useState({});
	const [isDismissDialogOpened, setIsDismissDialogOpened] = useState(false);
	const [
		contactDuplicateListStartingPosition,
		setContactDuplicateListStartingPosition,
	] = useState(0);

	const { isUserAllowedFor } = useAuth();
	const hasMergeRecordPermission = isUserAllowedFor(
		PERMISSIONS.contact.mergeRecords
	);

	const notificationTitle = "Success";
	const dismissDuplicateContactMutation = useDismissDuplicateContact();

	const {
		data: contactDuplicateList,
		isLoading: isContactDuplicateListLoading,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useDuplicateContactList(hasMergeRecordPermission);

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const hasDuplicateRecords =
		contactDuplicateList &&
		contactDuplicateList?.pages?.length > 0 &&
		contactDuplicateList?.pages[0]?.contacts &&
		contactDuplicateList?.pages[0]?.contacts?.length > 0;

	useEffect(() => {
		if (contactDuplicateListRef && hasMergeRecordPermission) {
			setContactDuplicateListStartingPosition(
				contactDuplicateListRef.current.getBoundingClientRect().top
			);
		}
	}, [hasMergeRecordPermission]);

	const toggleReviewDialog = (primaryContact) => {
		setIsReviewDialogOpened(
			(isReviewDialogOpened) => !isReviewDialogOpened
		);
		setPrimaryContact(primaryContact);
	};

	const openDismissDialog = (primaryContact) => {
		setIsDismissDialogOpened(true);
		setPrimaryContact(primaryContact);
	};

	const closeDismissDialog = () => {
		setIsDismissDialogOpened(false);
	};

	const dismissDuplicateContact = () => {
		dismissDuplicateContactMutation.mutate(
			{
				id: primaryContact.id,
				fieldName: primaryContact.email ? "emails" : "phones",
				value: primaryContact.email
					? primaryContact.email
					: primaryContact.phone,
			},
			{
				onSuccess: () => {
					closeDismissDialog();
					enqueueSnackbar({
						title: notificationTitle,
						variant: notificationVariants.error,
						message: notificationMessage.contactDismissMessage,
					});
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.errorMessage,
					});
				},
			}
		);
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: {
						boxShadow: "-3px 0px 14px rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
					},
				}}
				open={isReviewDialogOpened}
				onOpen={toggleReviewDialog}
				onClose={toggleReviewDialog}
				ModalProps={{
					BackdropProps: {
						style: { backgroundColor: "transparent" },
					},
				}}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddReviewDuplicates
						primaryContactId={primaryContact?.id}
						onClose={toggleReviewDialog}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<ConfirmDialog
				open={isDismissDialogOpened}
				onCancel={closeDismissDialog}
				onConfirm={dismissDuplicateContact}
				title="Are you sure you want to dismiss these contacts?"
				subtitle="The duplicates found will no longer be suggested for merge."
				confirmButtonText="Dismiss"
			/>

			<Can permission={PERMISSIONS.contact.mergeRecords}>
				<Box px={3}>
					<Typography fontSize={15} fontWeight={400}>
						Contacts with the same email or phone fields are
						considered duplicates.
					</Typography>
				</Box>

				<Box
					ref={contactDuplicateListRef}
					pt={1}
					sx={{
						height: `calc(100vh - ${contactDuplicateListStartingPosition}px)`,
						overflowY: "auto",
					}}
				>
					<TableContainer sx={{ maxHeight: "100%" }}>
						<Table stickyHeader sx={{ minWidth: 650 }} size="small">
							<TableHead>
								<TableRow>
									<TableCell>
										<TableHeaderLabel ml={1}>
											Primary Contact
										</TableHeaderLabel>
									</TableCell>
									<TableCell>
										<TableHeaderLabel m={1}>
											Secondary Contact
										</TableHeaderLabel>
									</TableCell>
									<TableCell>
										<TableHeaderLabel ml={1}>
											Actions
										</TableHeaderLabel>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{isContactDuplicateListLoading ? (
									<TableRow
										style={{
											height: "60vh",
											border: "2px solid red",
										}}
									>
										<TableCell
											colSpan={3}
											sx={{ borderBottom: 0 }}
										>
											<Stack
												height="100%"
												justifyContent="center"
												alignItems="center"
												width="100%"
											>
												<CircularProgress size={30} />
											</Stack>
										</TableCell>
									</TableRow>
								) : hasDuplicateRecords ? (
									contactDuplicateList.pages.map((page) =>
										page.contacts?.map(
											(contactData, index) => (
												<TableRow key={index}>
													<TableCell>
														<Box p={1}>
															<Stack
																display="flex"
																alignItems="center"
																direction="row"
																spacing={2}
															>
																<img
																	style={{
																		borderRadius:
																			"100%",
																	}}
																	src={
																		ContactImage
																	}
																	alt="contactImage"
																	width="48px"
																	height="48px"
																/>
																<Stack
																	spacing={
																		0.5
																	}
																>
																	<TooltipComponent
																		title={
																			contactData
																				?.primary
																				?.fullName
																		}
																		placement="top"
																		width="420px"
																		verticalDisplacement={
																			-3
																		}
																	>
																		<Typography
																			fontSize={
																				15
																			}
																			maxWidth={
																				"411px"
																			}
																			noWrap
																		>
																			{
																				contactData
																					?.primary
																					?.fullName
																			}
																		</Typography>
																	</TooltipComponent>
																	<TooltipComponent
																		title={
																			contactData
																				?.primary
																				?.email
																		}
																		placement="top"
																		width="420px"
																		verticalDisplacement={
																			-3
																		}
																	>
																		<Typography
																			color="rgba(0, 0, 0, 0.6)"
																			maxWidth={
																				"411px"
																			}
																			noWrap
																			fontSize={
																				15
																			}
																		>
																			{
																				contactData
																					?.primary
																					?.email
																			}
																			{
																				contactData
																					?.primary
																					?.phone
																			}
																		</Typography>
																	</TooltipComponent>
																</Stack>
															</Stack>
														</Box>
													</TableCell>
													<TableCell>
														<Box p={1}>
															<Stack
																display="flex"
																alignItems="center"
																direction="row"
																spacing={2}
															>
																<img
																	style={{
																		borderRadius:
																			"100%",
																	}}
																	src={
																		ContactImage
																	}
																	alt="contactImage"
																	width="48px"
																	height="48px"
																/>
																<Stack
																	spacing={
																		0.5
																	}
																>
																	<TooltipComponent
																		title={
																			contactData
																				?.secondary
																				?.fullName
																		}
																		placement="top"
																		width="420px"
																		verticalDisplacement={
																			-3
																		}
																	>
																		<Typography
																			fontSize={
																				15
																			}
																			maxWidth={
																				"411px"
																			}
																			noWrap
																		>
																			{
																				contactData
																					?.secondary
																					?.fullName
																			}
																		</Typography>
																	</TooltipComponent>
																	<TooltipComponent
																		title={
																			contactData
																				?.secondary
																				?.email
																		}
																		placement="top"
																		width="420px"
																		verticalDisplacement={
																			-3
																		}
																	>
																		<Typography
																			color="rgba(0, 0, 0, 0.6)"
																			fontSize={
																				15
																			}
																			maxWidth={
																				"411px"
																			}
																			noWrap
																		>
																			{
																				contactData
																					?.secondary
																					?.email
																			}
																			{
																				contactData
																					?.secondary
																					?.phone
																			}
																		</Typography>
																	</TooltipComponent>
																</Stack>
															</Stack>
														</Box>
													</TableCell>
													<TableCell>
														<Stack
															p={1}
															direction="row"
															spacing={2}
															alignItems="center"
														>
															<Button
																variant="contained"
																disableElevation
																onClick={() =>
																	toggleReviewDialog(
																		contactData?.primary
																	)
																}
															>
																Review
															</Button>
															<Button
																variant="contained"
																color="secondary"
																disableElevation
																onClick={() =>
																	openDismissDialog(
																		contactData.primary
																	)
																}
															>
																Dismiss
															</Button>
														</Stack>
													</TableCell>
												</TableRow>
											)
										)
									)
								) : (
									<TableRow>
										<TableCell
											colSpan={7}
											sx={{
												border: "none",
											}}
										>
											<Stack
												alignItems="center"
												justifyContent="center"
												style={{
													height: "400px",
													width: "100%",
												}}
											>
												<Typography
													fontSize={14}
													fontWeight={400}
													style={{
														opacity: 0.6,
													}}
												>
													No Duplicates Found
												</Typography>
											</Stack>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>

						<Box ref={lastRowRef}>
							{!isContactDuplicateListLoading &&
								isFetching &&
								hasDuplicateRecords && (
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="center"
										spacing={1}
										py={2}
									>
										<CircularProgress size={18} />

										<Typography fontSize={12}>
											Loading More Data
										</Typography>
									</Stack>
								)}
						</Box>
					</TableContainer>
				</Box>
			</Can>

			<CanNot permission={PERMISSIONS.contact.mergeRecords}>
				<Stack height="50vh">
					<UnAuthorized />
				</Stack>
			</CanNot>
		</React.Fragment>
	);
}
