import { useMemo } from "react";
import { useMailData, useSingleConversationData } from "../services/mail";
import { getFormattedMailAddress } from "../../utils/EmailUtils";

export const useDraftMailDataByThreadId = (threadId) => {
	const { data: emailThreadData, isFetching: isFetchingThreadData } =
		useMailData(threadId);

	const draftMailThreadData = useMemo(() => {
		let initialComposerData = {
			toAddress: [],
			ccAddress: [],
			bccAddress: [],
			subject: "",
			body: "",
			draftConversationId: "",
			attachmentData: [],
		};
		if (emailThreadData) {
			let conversationData = { ...emailThreadData?.emails?.[0] };

			initialComposerData.toAddress =
				conversationData?.recipients?.to?.map((toAddress) =>
					getFormattedMailAddress(toAddress)
				);

			initialComposerData.ccAddress =
				conversationData?.recipients?.cc?.map((ccAddress) =>
					getFormattedMailAddress(ccAddress)
				);

			initialComposerData.bccAddress =
				conversationData?.recipients?.bcc?.map((bccAddress) =>
					getFormattedMailAddress(bccAddress)
				);

			initialComposerData.draftConversationId =
				conversationData?.conversationId;

			if (conversationData?.attachments?.length > 0) {
				initialComposerData.attachmentData =
					conversationData?.attachments;
			}
			initialComposerData.subject = conversationData?.subject;
			initialComposerData.body = conversationData?.message;

			return initialComposerData;
		}
	}, [emailThreadData]);

	return { draftMailThreadData, isFetchingThreadData };
};

export const useDraftMailDataByConversationId = (conversationId) => {
	const {
		data: singleConversationData,
		isFetching: isFetchingConversationData,
	} = useSingleConversationData(conversationId);

	const draftMailConversationData = useMemo(() => {
		let initialComposerData = {
			toAddress: [],
			ccAddress: [],
			bccAddress: [],
			subject: "",
			body: "",
			draftConversationId: "",
			attachmentData: [],
		};
		if (singleConversationData) {
			let conversationData = { ...singleConversationData };

			initialComposerData.toAddress =
				conversationData?.recipients?.to?.map((toAddress) =>
					getFormattedMailAddress(toAddress)
				);

			initialComposerData.ccAddress =
				conversationData?.recipients?.cc?.map((ccAddress) =>
					getFormattedMailAddress(ccAddress)
				);

			initialComposerData.bccAddress =
				conversationData?.recipients?.bcc?.map((bccAddress) =>
					getFormattedMailAddress(bccAddress)
				);

			initialComposerData.draftConversationId = conversationData.id;

			if (conversationData?.attachments?.length > 0) {
				initialComposerData.attachmentData =
					conversationData?.attachments;
			}

			initialComposerData.subject = conversationData?.subject;
			initialComposerData.body = conversationData?.message;

			return initialComposerData;
		}
	}, [singleConversationData]);

	return { draftMailConversationData, isFetchingConversationData };
};
