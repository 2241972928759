import React, { useMemo, useState } from "react";
import {
	Box,
	CircularProgress,
	MenuItem,
	Select,
	Skeleton,
	Stack,
	Typography,
} from "@mui/material";
import CloseIcon from "../../../assets/icons/close";
import { default as UnKnownImage } from "../../../assets/images/contact/unknownContact.png";
import { useUsers } from "../../../hooks/account/user";
import { useLastRowRef } from "../../../hooks/common/InfiniteScroll";
import MenuSearchBar from "../MenuSearchBar";
import { MenuItemText } from "../../../styles/twozo";

export default function UsersMenu(props) {
	const {
		value,
		onChange,
		placeHolder,
		inputRef = null,
		error = false,
		userMenuStyle,
		removeActionEnabled = false,
		handleRemoveUser,
		filterCriteria,
	} = props;

	const [searchedValue, setSearchedValue] = useState("");

	const {
		activeUserList,
		isLoadingUsers,
		isFetchingUsers,
		hasNextUsersPage,
		fetchNextUsersPage,
	} = useUsers(searchedValue);

	const lastOptionRef = useLastRowRef(
		fetchNextUsersPage,
		hasNextUsersPage,
		isFetchingUsers
	);

	const handleSelectUser = (event, child) => {
		const { value } = event.target;
		onChange({ name: child?.props?.name, value: value });
		setSearchedValue("");
	};

	const handleSearchValue = (event) => {
		const { value } = event.target;
		setSearchedValue(value);
	};

	const filteredUserList = useMemo(() => {
		if (filterCriteria?.excludeUserId) {
			return activeUserList.filter(
				(user) => user?.value !== filterCriteria.excludeUserId
			);
		} else {
			return activeUserList;
		}
	}, [activeUserList, filterCriteria?.excludeUserId]);

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					{searchedValue && filteredUserList?.length === 0
						? "No Results Found"
						: "No Options Found"}
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			<Select
				size="small"
				fullWidth
				value={value?.value || ""}
				inputRef={inputRef}
				onChange={handleSelectUser}
				sx={{ ...userMenuStyle }}
				MenuProps={{
					autoFocus: false,
					PaperProps: {
						style: {
							marginTop: "4px",
							borderRadius: "8px",
							maxHeight: "255px",
							maxWidth: "400px",
						},
					},
				}}
				displayEmpty
				renderValue={() => (
					<Typography
						fontSize={14}
						noWrap
						color={value?.name ? "#000" : "rgba(0, 0, 0, 0.6)"}
					>
						{value?.name ? value.name : placeHolder || "Select"}
					</Typography>
				)}
				onOpen={() => setSearchedValue("")}
				error={error}
			>
				<Box
					p={0.5}
					marginTop="-8px"
					sx={{
						position: "sticky",
						top: 0,
						zIndex: 1,
						backgroundColor: "#fff",
					}}
				>
					<MenuSearchBar
						value={searchedValue}
						onChange={handleSearchValue}
					/>
				</Box>

				{isLoadingUsers ? (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="150px"
					>
						<CircularProgress size="25px" />
					</Stack>
				) : filteredUserList?.length > 0 ? (
					filteredUserList.map((user) => (
						<MenuItem
							value={user.value}
							key={user.value}
							name={user.name}
							style={{
								height: "40px",
							}}
						>
							<Stack
								width="100%"
								direction="row"
								spacing={1}
								alignItems="center"
								justifyContent="space-between"
							>
								<Stack
									direction="row"
									spacing={1}
									alignItems="center"
								>
									<img
										src={UnKnownImage}
										alt="owner_image"
										height="24px"
										width="24px"
									/>
									<MenuItemText whiteSpace="wrap">
										{user.name}
									</MenuItemText>
								</Stack>
								{value?.value === user?.value &&
								removeActionEnabled ? (
									<Box
										display="flex"
										onClick={handleRemoveUser}
									>
										{CloseIcon(20, 20, "#000", 0.6)}
									</Box>
								) : null}
							</Stack>
						</MenuItem>
					))
				) : (
					renderEmptyView()
				)}

				<Box ref={lastOptionRef}>
					{!isLoadingUsers &&
						isFetchingUsers &&
						filteredUserList?.length > 0 && (
							<MenuItem style={{ height: "40px" }}>
								<Stack
									direction="row"
									alignItems="center"
									justifyContent="center"
									spacing={1}
								>
									<Skeleton
										variant="circular"
										width="24px"
										height="24px"
									/>
									<Skeleton width="190px" height="16px" />
								</Stack>
							</MenuItem>
						)}
				</Box>
			</Select>
		</React.Fragment>
	);
}
