import { apiClient } from "../..";
import { searchTypes } from "../../../utils/dropdownData";
import { getTerritoryUrl } from "../../apiConfig";
import { assertError } from "../../apiHelper";
import postFieldsApi from "../../fields/postFieldsApi";

export const getTerritoryList = async (fieldToSort, pageParams) => {
	let requestData = getTerritoryListRequest(fieldToSort, pageParams);
	let response = postTerritoryManagementApi(requestData);
	return await response;
};

export const getTerritoryDropdownList = async (pageParam, searchValue) => {
	let requestData = getTerritoryDropdownListRequest(pageParam, searchValue);
	return postFieldsApi(requestData);
};

export const getTerritoryUsersList = async (territoryId) => {
	let requestData = getTerritoryUsersListRequest(territoryId);
	let response = postTerritoryManagementApi(requestData);
	return await response;
};

export const getTerritoryData = async (territoryId) => {
	let requestData = getTerritoryRequestData(territoryId);
	let response = postTerritoryManagementApi(requestData);
	return await response;
};

export const createTerritory = async ({ name, users, description }) => {
	let requestData = getCreateTerritoryRequest(name, users, description);
	let response = postTerritoryManagementApi(requestData);
	return await response;
};

export const updateTerritory = async ({ name, users, id, description }) => {
	let requestData = getUpdateTerritoryRequest(name, users, id, description);
	let response = postTerritoryManagementApi(requestData);
	return await response;
};

export const deleteTerritory = async (territoryId) => {
	let requestData = getDeleteTerritoryRequest(territoryId);
	let response = postTerritoryManagementApi(requestData);
	return await response;
};

export const getTeamUsers = async (searchValue, pageParam) => {
	let requestData = getTeamUsersRequest(searchValue, pageParam);
	let response = postTerritoryManagementApi(requestData);
	return await response;
};

const getUpdateTerritoryRequest = (name, users, id, description) => {
	return {
		type: "update",
		data: {
			id: id,
			name: name,
			users: users,
			description: description,
		},
	};
};

const getTerritoryRequestData = (id) => {
	return {
		type: "getUsers",
		data: {
			id: id,
		},
	};
};

const getDeleteTerritoryRequest = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
		},
	};
};

const getTerritoryUsersListRequest = (territoryId) => {
	return {
		type: "getPartialUsers",
		data: {
			id: territoryId,
		},
	};
};

const getCreateTerritoryRequest = (name, users, description) => {
	return {
		type: "create",
		data: {
			name: name,
			users: users,
			description: description,
		},
	};
};

const getTerritoryDropdownListRequest = (pageParam, searchValue = "") => {
	return {
		type: "dropdownData",
		data: {
			name: "territoryList",
			criteria: {
				type: searchTypes.contains,
				value: searchValue,
			},
			...pageParam,
		},
	};
};

const getTerritoryListRequest = (fieldToSort, pageParams) => {
	let territoryListRequestData = {
		type: "list",
		data: {
			...pageParams,
		},
	};

	if (Object.keys(fieldToSort).length > 0) {
		territoryListRequestData.data.sort = fieldToSort;
	}

	return territoryListRequestData;
};

const getTeamUsersRequest = (searchValue, pageParam) => {
	return {
		type: "getTeamsAndUsers",
		data: {
			criteria: {
				type: "contains",
				value: searchValue,
			},
			...pageParam,
		},
	};
};

const postTerritoryManagementApi = async (requestData) => {
	let territoryManagementUrl = getTerritoryManagementUrl();
	let response = await apiClient.post(territoryManagementUrl, requestData);
	assertError(response, territoryManagementUrl);
	return response.data.data;
};

const getTerritoryManagementUrl = () => {
	return getTerritoryUrl() + "/mgmt";
};
