import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	Box,
	Checkbox,
	Stack,
	Table,
	TableBody,
	Typography,
	IconButton,
	useTheme,
	CircularProgress,
	Badge,
} from "@mui/material";
import { default as FilterIcon } from "../../../assets/icons/filter";
import { twozoStyles } from "../../../styles/twozo";
import EmailFilter from "../EmailFilter";
import MailSearch from "../MailSearch";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import {
	useFilterData,
	useMailList,
	useUpdateMailState,
} from "../../../hooks/services/mail";
import SelectedMenu from "./SelectedMenu";
import DraftComposerDrawer from "../Drafts/DraftComposer/DraftComposerDrawer";
import EmailViewer from "./EmailViewer";
import { useLastRowRef } from "../../../hooks/common/InfiniteScroll";
import EmailTableRow from "./EmailTableRow";
import { emailListPageSize } from "../../../utils/queryConstants/email";
import { useAuth } from "../../../hooks/auth";
import { PERMISSIONS } from "../../../utils/Auth";
import NoConversationsFound from "../NoConversationsFound";

export default function EmailList(props) {
	const {
		listHeader,
		mailListName,
		hasTrashed,
		menuConfig,
		hiddenBodyContent,
		filterConfig,
		hiddenFavoriteAction,
	} = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const inboxRef = useRef(null);
	const [selectedMailIds, setSelectedMailIds] = useState(new Set());
	const [isMailViewerOpened, setIsMailViewerOpened] = useState(false);
	const [openedMailId, setOpenedMailId] = useState("");
	const [isEmailFilterOpened, setIsEmailFilterOpened] = useState(false);
	const [isDraftComposerOpened, setIsDraftComposerOpened] = useState(false);
	const [criteria, setCriteria] = useState({});
	const [isMailListEnabled, setIsMailListEnabled] = useState(false);
	const [filterCount, setFilterCount] = useState("");
	const [topBarHeight, setTopBarHeight] = useState(0);

	// get Mail Filter Data
	const {
		data: filterData,
		status: filterStatus,
		isLoading: isFilterDataLoading,
	} = useFilterData(mailListName);

	// get MailList
	const {
		data: mails,
		isLoading: isMailListLoading,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useMailList(mailListName, criteria, isMailListEnabled);

	const { isUserAllowedFor } = useAuth();
	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	useEffect(() => {
		if (filterStatus === "success") {
			setFilterCount(filterData?.filterCount);
			let initialFilters = { ...filterData };
			delete initialFilters?.filterCount;
			if (filterConfig && filterData) {
				setCriteria({ criteria: initialFilters });
			}
			setIsMailListEnabled(true);
		}
	}, [filterData, filterStatus, filterConfig]);

	const lastPage = mails?.pages?.[mails?.pages?.length - 1];
	const tableRowCount =
		(mails?.pages?.length - 1) * emailListPageSize + lastPage?.list?.length;
	const totalMailCount = lastPage?.totalRecords ?? "";

	const toggleSelectMail = (event, mail) => {
		event.stopPropagation();

		if (isSelected(mail?.id)) {
			setSelectedMailIds((selected) => {
				const newSelected = new Set(selected);
				newSelected.delete(mail?.id);
				return newSelected;
			});
		} else {
			setSelectedMailIds((selected) => new Set([...selected, mail?.id]));
		}
	};

	const updateMailStateMutation = useUpdateMailState(mailListName);

	const clearSelectedMail = () => {
		setSelectedMailIds(new Set());
	};

	const toggleSelectAllMail = (event) => {
		const { checked } = event.target;

		if (checked) {
			const allRowIds = [];
			mails?.pages?.forEach((page) => {
				if (page?.list?.length > 0) {
					allRowIds.push(...page.list.map((row) => row.id));
				}
			});

			setSelectedMailIds(new Set(allRowIds));
		} else {
			setSelectedMailIds(new Set());
		}
	};

	const openMailViewerView = () => {
		setIsMailViewerOpened(true);
	};

	const closeMailViewerView = () => {
		setIsMailViewerOpened(false);
		setOpenedMailId("");
	};

	const toggleSwipeableFilter = () => {
		setIsEmailFilterOpened((isEmailFilterOpened) => !isEmailFilterOpened);
	};

	useEffect(() => {
		if (inboxRef) {
			setTopBarHeight(inboxRef.current.getBoundingClientRect().top);
		}
	}, [inboxRef]);

	const closeDraftComposer = () => {
		setIsDraftComposerOpened(false);
		setOpenedMailId("");
	};

	const openDraftComposer = () => {
		setIsDraftComposerOpened(true);
	};

	const handleUpdateMailState = (openedMailData) => {
		if (!openedMailData?.isRead) {
			updateMailStateMutation.mutate({
				ids: [openedMailData?.id],
				isRead: true,
			});
		}
	};

	const onRowClicked = (mail) => {
		let isSingleConversationThread = mail?.conversationCount === 1;

		if (hasTrashed) {
			if (!mail.hasDraft || !isSingleConversationThread) {
				openMailViewerView();
				setOpenedMailId(mail?.id);
			}
		} else {
			if (mail?.hasDraft) {
				if (
					isSingleConversationThread &&
					isUserAllowedFor(PERMISSIONS.email.individualEmailLimit)
				) {
					openDraftComposer();
				} else {
					openMailViewerView();
				}
			} else {
				openMailViewerView();
				handleUpdateMailState(mail);
			}
			setOpenedMailId(mail?.id);
		}
	};

	const handleFilterData = (selectedFilters) => {
		setCriteria({ criteria: selectedFilters });
		setFilterCount(Object.keys(selectedFilters).length);
	};

	const isSelected = useCallback(
		(mailId) => selectedMailIds.has(mailId),
		[selectedMailIds]
	);

	return (
		<React.Fragment>
			{/*Draft Composer*/}
			{isDraftComposerOpened ? (
				<DraftComposerDrawer
					onClose={closeDraftComposer}
					threadId={openedMailId}
					mailListName={mailListName}
				/>
			) : null}

			{/*Mail Filter*/}
			<CustomSwipeableDrawer
				sx={{
					"& .MuiDrawer-paper": {
						marginRight: "8px",
						marginTop: `${topBarHeight}px`,
						height: `calc(100vh - ${topBarHeight + 8}px)`,
						width: "260px",
					},
				}}
				variant="persistent"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent", zIndex: 3 },
				}}
				anchor="right"
				open={isEmailFilterOpened}
				onOpen={toggleSwipeableFilter}
				onClose={toggleSwipeableFilter}
			>
				<Box>
					<EmailFilter
						initialFilterData={criteria?.criteria}
						onChangeFilter={handleFilterData}
						filterConfig={filterConfig}
					/>
				</Box>
			</CustomSwipeableDrawer>

			{/*Mail Viewer*/}
			<CustomSwipeableDrawer
				anchor="right"
				BackdropProps={{ invisible: true }}
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isMailViewerOpened}
				onOpen={openMailViewerView}
				onClose={closeMailViewerView}
				disableSwipeToOpen
			>
				<Box className={classes.mailViewContainer}>
					<Box className={classes.mailViewCard}>
						<EmailViewer
							mailListName={mailListName}
							onClose={closeMailViewerView}
							mailId={openedMailId}
							isTrash={hasTrashed}
						/>
					</Box>
				</Box>
			</CustomSwipeableDrawer>

			<Box>
				<Stack
					sx={{ height: "50px" }}
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					py={1.25}
					style={{
						borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
					}}
				>
					<Stack direction="row" alignItems="center">
						<Box px={2}>
							<Checkbox
								indeterminate={
									selectedMailIds.size > 0 &&
									selectedMailIds.size < tableRowCount
								}
								checked={
									tableRowCount > 0 &&
									selectedMailIds.size === tableRowCount
								}
								onChange={toggleSelectAllMail}
							/>
						</Box>

						<Typography fontWeight={500} fontSize={15}>
							{listHeader}
						</Typography>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						spacing={3}
						pr={3}
					>
						{selectedMailIds.size > 0 ? (
							<SelectedMenu
								mailCount={tableRowCount}
								mailListName={mailListName}
								clearSelectedMail={clearSelectedMail}
								selectedMailIds={[...selectedMailIds]}
								menuConfig={menuConfig}
							/>
						) : null}

						<MailSearch />

						{filterConfig ? (
							filterCount ? (
								<Badge
									badgeContent={filterCount}
									color="primary"
								>
									<IconButton
										onClick={toggleSwipeableFilter}
										style={{ padding: "0px" }}
									>
										{FilterIcon(
											20,
											20,
											theme.palette.primary.main
										)}
									</IconButton>
								</Badge>
							) : (
								<IconButton onClick={toggleSwipeableFilter}>
									{FilterIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>
							)
						) : null}
					</Stack>
				</Stack>

				<Box
					sx={{
						height: `calc(100vh - ${topBarHeight + 8}px)`,
						overflowY: "auto",
						display: "flex",
						flexDirection: "column",
					}}
					ref={inboxRef}
					style={{
						marginRight: isEmailFilterOpened ? "260px" : null,
						transition: isEmailFilterOpened
							? theme.transitions.create("margin", {
									easing: theme.transitions.easing.easeOut,
									duration:
										theme.transitions.duration
											.enteringScreen,
								})
							: theme.transitions.create("margin", {
									easing: theme.transitions.easing.sharp,
									duration:
										theme.transitions.duration
											.leavingScreen,
								}),
					}}
				>
					{isMailListLoading || isFilterDataLoading ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="80vh"
						>
							<CircularProgress />
						</Stack>
					) : (
						<Box>
							{mails?.pages?.length > 0 && tableRowCount ? (
								<Table
									size="small"
									style={{
										width: "100%",
										tableLayout: "fixed",
									}}
								>
									<TableBody>
										{mails.pages.map((page) =>
											page?.list?.map((mail) => (
												<EmailTableRow
													key={mail?.id}
													mailData={mail}
													isSelected={isSelected(
														mail?.id
													)}
													onRowClicked={onRowClicked}
													handleCheckboxClick={
														toggleSelectMail
													}
													mailListName={mailListName}
													tableRowMenuConfig={
														menuConfig
													}
													hiddenFavoriteAction={
														hiddenFavoriteAction
													}
													isRowHighlighted={
														mail?.id ===
														openedMailId
													}
													hiddenBodyContent={
														hiddenBodyContent
													}
													hasTrashed={hasTrashed}
												/>
											))
										)}
									</TableBody>
								</Table>
							) : (
								<Stack
									alignItems="center"
									justifyContent="center"
									height="70vh"
									spacing={0.5}
								>
									<NoConversationsFound />
								</Stack>
							)}

							<Box style={{ height: "52px" }} ref={lastRowRef}>
								{!isMailListLoading && isFetching ? (
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="center"
										spacing={1}
										py={2}
									>
										<CircularProgress size={18} />
										<Typography fontSize={12}>
											Loading More Data
										</Typography>
									</Stack>
								) : null}
							</Box>
						</Box>
					)}

					{totalMailCount ? (
						<Stack
							sx={{
								position: "sticky",
								bottom: 0,
								right: 0,
							}}
							flexGrow={1}
							justifyContent="flex-end"
						>
							<Box
								m={2}
								py={1}
								px={2}
								style={{
									backgroundColor:
										theme.palette.secondary.main,
									borderRadius: "8px",
									position: "absolute",
									bottom: 0,
									right: 0,
								}}
							>
								<Typography
									fontSize={14}
									fontWeight={500}
									color="rgba(0, 0, 0, 0.6)"
								>
									Total : {totalMailCount}
								</Typography>
							</Box>
						</Stack>
					) : null}
				</Box>
			</Box>
		</React.Fragment>
	);
}
