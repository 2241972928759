import { useQuery } from "@tanstack/react-query";
import { getUserTeamTerritoryListKey } from "../../../utils/queryKeys/userManagement";
import { getUserTeamTerritoryList } from "../../../api/userManagement";

//User Team Territory List
const useUserTeamTerritoryList = (data = {}, options = {}) => {
	return useQuery(
		getUserTeamTerritoryListKey(data),
		() => getUserTeamTerritoryList(data),
		{
			select: (data) => data?.options,
			...options,
		}
	);
};

export { useUserTeamTerritoryList };
