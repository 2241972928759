import { getDropdownKeyByFieldName } from "../commonQueryKeys";
import { getRootKey } from "../rootKey";

const phoneKey = [...getRootKey(), "phone"]; // Return the phone query key
export const phoneNumbersTableDataKey = [...phoneKey, "PhoneNumbersTableData"];
export const phoneCallTokenKey = [...phoneKey, "phone-call-token"];

export const getAvailablePhoneNumberListKey = () => {
	return [...getDropdownKeyByFieldName("availablePhoneNumberList")];
};

export const getContactPhoneNumberListKey = (searchValue) => {
	return [
		...getDropdownKeyByFieldName("contactPhoneNumberList"),
		searchValue,
	];
};

export const getPhoneNumbersTableDataWithSortKey = (
	fieldToSort,
	searchValue
) => {
	return [...phoneNumbersTableDataKey, fieldToSort, searchValue];
};

export const getCountryDropdownKey = (searchValue) => {
	return [...getDropdownKeyByFieldName("countryList"), searchValue];
};

export const getStateDropdownKey = (countryId, searchValue) => {
	return [
		...getDropdownKeyByFieldName("stateList"),
		String(countryId),
		searchValue,
	];
};

export const getLocalityDropdownKey = (countryId, stateId, searchValue) => {
	return [
		...getDropdownKeyByFieldName("localityList"),
		String(countryId),
		String(stateId),
		searchValue,
	];
};

export const getPhoneNumberDropdownKey = (
	countryCode,
	countryName,
	stateName,
	localityName,
	searchValue
) => {
	return [
		...getDropdownKeyByFieldName("phoneNumberList"),
		countryCode,
		countryName,
		stateName,
		localityName,
		searchValue,
	];
};
