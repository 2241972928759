import { IconButton, useTheme } from "@mui/material";
import React from "react";
import { default as MoreIcon } from "../../../assets/icons/more";

function MoreIconButton(props) {
	const { onClick, isFocused, iconProps = {}, sx = {} } = props;
	const theme = useTheme();

	const handleClick = (event) => {
		if (!onClick) return;
		onClick(event);
	};

	return (
		<React.Fragment>
			<IconButton
				size="small"
				onClick={handleClick}
				sx={{
					backgroundColor: isFocused
						? theme.palette.secondary.main
						: "transparent",
					"&:hover": {
						backgroundColor: theme.palette.secondary.main,
					},
					...sx,
				}}
			>
				{MoreIcon(
					iconProps.width ?? 20,
					iconProps.height ?? 20,
					iconProps.color ?? theme.palette.primary.main,
					iconProps.opacity ?? 1
				)}
			</IconButton>
		</React.Fragment>
	);
}

export default MoreIconButton;
