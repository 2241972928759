import { removeFieldsWithEmptyValues } from "../../../../utils/common";
import UpdateFieldForm from "./UpdateFieldForm";

export default function UpdateField({
	selected = [],
	onSave,
	moduleName,
	onClose,
	isSaving,
	filterData,
	isAllSelected,
	tableRowsData = [],
	additionalCriteria,
	open,
}) {
	const getUpdateFieldRequestData = (data) => {
		let requestData = {
			...filterData,
			additionalCriteria,
			updateItem: { ...data },
		};

		if (isAllSelected) {
			requestData.isAllSelected = true;
			const selectedSet = new Set(selected);

			const excludedIds = tableRowsData
				.filter(({ id }) => !selectedSet.has(id))
				.map(({ id }) => id);

			if (
				selected.length !== tableRowsData.length &&
				excludedIds.length > 0
			) {
				requestData.excludedIds = excludedIds;
			}
		} else {
			requestData.ids = selected;
		}

		return requestData;
	};

	const handleSave = (formData) => {
		removeFieldsWithEmptyValues(formData);
		let requestData = getUpdateFieldRequestData(formData);
		onSave(requestData);
	};

	return (
		<UpdateFieldForm
			open={open}
			onClose={onClose}
			onSave={handleSave}
			isSaving={isSaving}
			moduleName={moduleName}
		/>
	);
}
