import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "dayjs/locale/en-gb";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { makeStyles } from "@mui/styles";
import { SnackbarProvider } from "notistack";
import {
	autoHideDuration,
	components,
} from "./utils/notification/notificationConfig";
import { AuthContextProvider } from "./providers/AuthContextProvider";
import { Router } from "./router";
import { UserConfigContextProvider } from "./providers/UserConfigContextProvider";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

const useStyles = makeStyles({
	containerRoot: {
		right: "0px",
	},
});

export default function Twozo(props) {
	const classes = useStyles();

	return (
		<SnackbarProvider
			maxSnack={5}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			Components={components}
			autoHideDuration={autoHideDuration}
			classes={{
				containerRoot: classes.containerRoot,
			}}
		>
			<QueryClientProvider client={queryClient}>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<AuthContextProvider>
						<UserConfigContextProvider>
							<Router {...props}></Router>
						</UserConfigContextProvider>
					</AuthContextProvider>
				</LocalizationProvider>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</SnackbarProvider>
	);
}
