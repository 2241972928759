import React from "react";
import AddForm from "../AddForm";
import { useUserData } from "../../../../../hooks/services/userManagement/user";

export default function EditUser(props) {
	const { onClose, userId, isEditable } = props;
	const { data: userData, isLoading: isUserDataLoading } =
		useUserData(userId);

	const getformatedData = (data) => {
		let formData = { ...data };

		formData.reportingManager = formData?.reportingManagerId
			? {
					name: formData["reportingManagerId"]?.name,
					value: formData["reportingManagerId"]?.id,
				}
			: null;
		formData.pipelineId = formData["pipeline"]?.id;
		formData.role = {
			name: formData.role?.name,
			value: formData.role?.id,
		};

		if (formData["teams"]?.length > 0) {
			let formatTeamsData = [];
			for (let team of formData["teams"]) {
				formatTeamsData.push({ name: team?.name, value: team?.id });
			}
			formData.teams = formatTeamsData;
		}

		delete formData.pipeline;
		delete formData.reportingManagerId;
		delete formData.createdTime;
		delete formData.locale;
		delete formData.orgId;
		delete formData.stateId;

		return formData;
	};

	return (
		<React.Fragment>
			<AddForm
				isEditMode={true}
				onClose={onClose}
				userData={getformatedData(userData)}
				isUserDataLoading={isUserDataLoading}
				isEditable={isEditable}
			/>
		</React.Fragment>
	);
}
