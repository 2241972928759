import React, { useState } from "react";
import {
	Box,
	IconButton,
	MenuItem,
	Stack,
	TableCell,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import {
	MenuItemText,
	TableCellText,
	twozoStyles,
} from "../../../../styles/twozo";
import { default as MoreIcon } from "../../../../assets/icons/more";
import { default as EditIcon } from "../../../../assets/icons/edit";
import { default as DeleteIcon } from "../../../../assets/icons/delete";
import Menu from "../../../Elements/Menu";
import DeleteDialog from "../../../Elements/DeleteDialog";
import { useDeletePhoneNumber } from "../../../../hooks/services/phone";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import EditPhoneNumber from "../EditPhoneNumber";

export default function PhoneNumberTableRow(props) {
	const { phoneNumberRowData } = props;
	const theme = useTheme();
	const classes = twozoStyles();

	const [hoveredTableRow, setHoveredTableRow] = useState(false);
	const [tableRowMenuElement, setTableRowMenuElement] = useState(null);
	const isTableRowMenuOpened = Boolean(tableRowMenuElement);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [isEditDrawerOpened, setIsEditDrawerOpened] = useState(false);

	const deletePhoneNumberMutation = useDeletePhoneNumber();

	const { localityName, stateName, name } =
		phoneNumberRowData.locationDetails;

	const onMouseOverTableRow = () => {
		setHoveredTableRow(true);
	};

	const onMouseOutTableRow = () => {
		if (!isTableRowMenuOpened) {
			setHoveredTableRow(false);
		}
	};

	const openTableRowMenu = (event) => {
		setTableRowMenuElement(event.currentTarget);
	};

	const closeTableRowMenu = () => {
		setTableRowMenuElement(null);
		setHoveredTableRow(false);
	};

	const openDeleteDialog = () => {
		setShowDeleteDialog(true);
		closeTableRowMenu();
	};

	const closeDeleteDialog = () => {
		setShowDeleteDialog(false);
		closeTableRowMenu();
	};

	const handleDeletePhoneNumber = () => {
		deletePhoneNumberMutation.mutate(phoneNumberRowData?.id, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.deletePhoneNumber,
				});
				closeDeleteDialog();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const openEditPhoneNumberDrawer = () => {
		setIsEditDrawerOpened(true);
		closeTableRowMenu();
	};

	const closeEditPhoneNumberDrawer = () => {
		setIsEditDrawerOpened(false);
		closeTableRowMenu();
	};

	const menuOptions = [
		{
			id: 1,
			name: "Edit",
			icon: EditIcon,
			action: openEditPhoneNumberDrawer,
		},
		{
			id: 2,
			name: "Delete",
			icon: DeleteIcon,
			action: openDeleteDialog,
		},
	];

	return (
		<React.Fragment>
			{/* Edit drawer */}
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isEditDrawerOpened}
				onOpen={openEditPhoneNumberDrawer}
				onClose={closeEditPhoneNumberDrawer}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<EditPhoneNumber
						onClose={closeEditPhoneNumberDrawer}
						phoneNumberFormData={phoneNumberRowData}
					/>
				</Box>
			</CustomSwipeableDrawer>

			{/* Delete dialog */}
			<DeleteDialog
				title="Are you sure you want to delete the number?"
				subtitle="Once deleted, you will no longer be able to make and receive calls from this number."
				open={showDeleteDialog}
				onCancel={closeDeleteDialog}
				onDelete={handleDeletePhoneNumber}
				disableDeleteButton={deletePhoneNumberMutation.isLoading}
			/>

			{/* Table row menu */}
			<Menu
				minWidth="200px"
				anchorEl={tableRowMenuElement}
				open={isTableRowMenuOpened}
				onClose={closeTableRowMenu}
				style={{ marginTop: "6px" }}
			>
				{menuOptions.map((menu) => (
					<MenuItem
						key={menu.id}
						style={{ height: "40px" }}
						onClick={menu.action}
					>
						<Stack direction="row" spacing={2}>
							<Box display="flex">
								{menu.icon(20, 20, theme.palette.primary.main)}
							</Box>

							<MenuItemText
								style={{
									fontWeight: 500,
									color: theme.palette.primary.main,
								}}
							>
								{menu.name}
							</MenuItemText>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			<TableRow
				onMouseOver={() => onMouseOverTableRow()}
				onMouseOut={() => onMouseOutTableRow()}
			>
				<TableCell>
					<TableCellText>
						{phoneNumberRowData?.displayName}
					</TableCellText>
				</TableCell>

				<TableCell>
					<Typography fontSize={15}>
						<span>{phoneNumberRowData?.number}</span>{" "}
						<span> - </span>{" "}
						<span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
							{localityName}, {stateName}, {name}
						</span>
					</Typography>
				</TableCell>

				<TableCell
					style={{
						minWidth: "52px",
						textAlign: "center",
						padding: 0,
						paddingRight: "8px",
					}}
				>
					{hoveredTableRow ? (
						<Box
							style={{
								border: `1px solid ${theme.palette.primary.main}`,
								borderRadius: "8px",
								paddingTop: "2px",
								paddingBottom: "2px",
							}}
						>
							<IconButton size="small" onClick={openTableRowMenu}>
								{MoreIcon(16, 16, theme.palette.primary.main)}
							</IconButton>
						</Box>
					) : null}
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}
