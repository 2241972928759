import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";

const dialpadKeys = [
	{ num: "1", letters: "" },
	{ num: "2", letters: "ABC" },
	{ num: "3", letters: "DEF" },
	{ num: "4", letters: "GHI" },
	{ num: "5", letters: "JKL" },
	{ num: "6", letters: "MNO" },
	{ num: "7", letters: "PQRS" },
	{ num: "8", letters: "TUV" },
	{ num: "9", letters: "WXYZ" },
	{ num: "*", letters: "" },
	{ num: "0", letters: "+" },
	{ num: "#", letters: "" },
];

export default function DialPad(props) {
	const { handleNumberClick } = props;
	const theme = useTheme();

	return (
		<React.Fragment>
			<Box
				style={{
					display: "grid",
					gridTemplateColumns: `repeat(${3}, 1fr)`,
					rowGap: "7px",
					paddingTop: "8px",
				}}
			>
				{dialpadKeys.map(({ num, letters }) => (
					<Button
						key={num}
						variant="text"
						fullWidth
						sx={{
							height: "46px",
							color: "#fff",

							"&:hover": {
								border: `1px solid ${theme.palette.secondary.contrastText}`,
								color: theme.palette.secondary.contrastText,
							},
						}}
						onClick={() => handleNumberClick(num)}
					>
						<Box>
							<Typography fontSize={25} style={{ lineHeight: 1 }}>
								{num}
							</Typography>
							<Typography fontSize={10}>{letters}</Typography>
						</Box>
					</Button>
				))}
			</Box>
		</React.Fragment>
	);
}
