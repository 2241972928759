import React, { useCallback, useState } from "react";
import { Box, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { default as sortIcon } from "../../../assets/icons/sort";
import TooltipComponent from "../../Elements/TooltipComponent";
import SortMenu from "./DealSortMenu";

export default function BoardHeading(props) {
	const {
		forecastView,
		name,
		amount,
		dealSize,
		symbol,
		isSummaryLoading = false,
		sort,
		updateSortRequest,
	} = props;
	const theme = useTheme();
	const [sortIconElement, setSortIconElement] = useState(null);
	const sortMenu = Boolean(sortIconElement);
	const [selectedSortOption, setSelectedSortOption] = useState({});

	const openSortMenu = (event) => {
		setSortIconElement(event.currentTarget);
	};

	const closeSortMenu = () => {
		setSortIconElement(null);
	};

	const updateSortOptions = useCallback((sortOptions) => {
		setSelectedSortOption(sortOptions);
	}, []);

	return (
		<React.Fragment>
			<Box p={1.5} width="100%">
				<Stack alignItems={forecastView && "center"} spacing={"5px"}>
					<Stack direction="row" justifyContent="space-between">
						<TooltipComponent
							title={name}
							placement="top"
							width="350px"
						>
							<Typography
								fontSize="15px"
								fontWeight={500}
								noWrap
								maxWidth="350px"
								sx={{ cursor: "pointer" }}
							>
								{name}
							</Typography>
						</TooltipComponent>

						{!forecastView && (
							<Box
								style={{
									display: "flex",
									cursor: "pointer",
								}}
								onClick={openSortMenu}
							>
								{sortIcon(
									20,
									20,
									Object.keys(selectedSortOption).length > 0
										? theme.palette.primary.main
										: "rgba(0, 0, 0, 0.6)"
								)}
							</Box>
						)}
					</Stack>

					{isSummaryLoading ? (
						<Skeleton width="45%" height="19.5px" />
					) : (
						<Stack direction="row" spacing={1}>
							<Typography fontSize="14px">
								{symbol}
								{amount % 1 === 0
									? amount?.toLocaleString()
									: amount}
							</Typography>
							<Typography fontSize="14px">&#8226;</Typography>
							<Typography
								fontSize="14px"
								style={{ color: "rgba(0, 0, 0, 0.6)" }}
							>
								{dealSize} {dealSize > 1 ? "Deals" : "Deal"}
							</Typography>
						</Stack>
					)}
				</Stack>
			</Box>

			<SortMenu
				sortIconElement={sortIconElement}
				closeSortMenu={closeSortMenu}
				isSortMenuOpened={sortMenu}
				sort={sort}
				updateSortRequest={updateSortRequest}
				updateSortOptions={updateSortOptions}
				selectedSortOption={selectedSortOption}
			/>
		</React.Fragment>
	);
}
