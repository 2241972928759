export const visibility = {
	justMe: 1,
	sharedWith: 2,
};

export const access = {
	canView: 1,
	canEdit: 2,
	canManage: 3,
	owner: 4,
};

export const accessName = {
	1: "Can View",
	2: "Can Edit",
	3: "Can Manage",
	4: "Owner",
};

export const getMaximumPermissionLevel = (permission1, permission2) => {
	try {
		return Math.min(parseInt(permission1), parseInt(permission2));
	} catch (e) {
		console.error(e);
		return 1;
	}
};

export const checkPermission = (permissionNeeded, actualPermission) => {
	try {
		return parseInt(actualPermission) >= parseInt(permissionNeeded);
	} catch (e) {
		console.error(e);
		return false;
	}
};
