import React, { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Box,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	Stack,
	Toolbar,
	useTheme,
} from "@mui/material";
import twozo_logo from "../../assets/images/logo/twozo.png";
import { default as DealsIcon } from "../../assets/icons/deals";
import { default as ContactsIcon } from "../../assets/icons/contact";
import { default as CompanyIcon } from "../../assets/icons/company";
import { default as ProductIcon } from "../../assets/icons/product";
import { default as SettingsIcon } from "../../assets/icons/settings";
import { default as ConversationIcon } from "../../assets/icons/conversation";
import { default as ReportsIcon } from "../../assets/icons/reports";
import { default as ActivityIcon } from "../../assets/icons/activity";
import { default as UnKnownImage } from "../../assets/images/contact/unknownContact.png";
import { hexToRgba, Tooltip, twozoStyles } from "../../styles/twozo";
import UserProfile from "./userProfile";
import CustomSwipeableDrawer from "../Elements/CustomSwipeableDrawer";
import { useSearchContext } from "../Search/SearchContext";
import { useAuth } from "../../hooks/auth";
import { PERMISSIONS } from "../../utils/Auth";
import { useModuleList } from "../../hooks/services/common";
import { modules } from "../../utils/common/ModulesName";
import { useModuleName } from "../../hooks/modules";
import Phone from "../Phone";

export default function NavigationBar(props) {
	const { drawerWidth } = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const { getModuleName } = useModuleName();

	// query call:-
	const { isUserAllowedFor } = useAuth();

	//search context
	const { searchState, closeSearchBar } = useSearchContext();

	const [isUserProfileOpened, setIsUserProfileOpened] = useState(false);

	const { data: moduleList } = useModuleList();

	const isProductModuleEnabled = useMemo(() => {
		if (Array.isArray(moduleList)) {
			let module = moduleList.find(
				(module) => module.sourceName === modules.PRODUCT
			);
			if (module) {
				return (
					module?.isEnabled &&
					isUserAllowedFor(PERMISSIONS.product.view)
				);
			}
		}
	}, [moduleList, isUserAllowedFor]);

	const drawerOptions = [
		{
			name: getModuleName(modules.DEAL),
			icon: DealsIcon,
			path: "deals",
			permission: isUserAllowedFor(PERMISSIONS.deal.view),
		},
		{
			name: getModuleName(modules.CONTACT),
			icon: ContactsIcon,
			path: "contacts",
			permission: isUserAllowedFor(PERMISSIONS.contact.view),
		},
		{
			name: getModuleName(modules.COMPANY),
			icon: CompanyIcon,
			path: "companies",
			permission: isUserAllowedFor(PERMISSIONS.company.view),
		},
		{
			name: "Activities",
			icon: ActivityIcon,
			path: "activities",
			permission: true,
		},
		{
			name: getModuleName(modules.PRODUCT),
			icon: ProductIcon,
			path: "products",
			permission: isProductModuleEnabled,
		},
		{
			name: "Settings",
			icon: SettingsIcon,
			path: "settings",
			permission: true,
		},
		{
			name: "Conversations",
			icon: ConversationIcon,
			path: "mail/inbox",
			hint: "mail",
			permission: true,
		},
		{
			name: "Analytics",
			icon: ReportsIcon,
			path: "analytics",
			hint: "analytics",
			permission: true,
		},
	];

	const getDrawerOptions = () => {
		let filteredDrawerOptions = drawerOptions.filter(
			(option) => option.permission
		);

		return filteredDrawerOptions;
	};

	const toggleUserProfile = () => {
		setIsUserProfileOpened((isUserProfileOpened) => !isUserProfileOpened);
		if (searchState.isSearchbarOpen) {
			closeSearchBar();
		}
	};

	const onModuleClicked = (drawerOption) => {
		navigate(drawerOption.path);
		if (searchState.isSearchbarOpen) {
			closeSearchBar();
		}
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="left"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isUserProfileOpened}
				onOpen={toggleUserProfile}
				onClose={toggleUserProfile}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.userProfileContainer}>
					<UserProfile onClose={toggleUserProfile} />
				</Box>
			</CustomSwipeableDrawer>

			<Drawer
				variant="permanent"
				sx={{
					"& .MuiDrawer-paper": {
						boxSizing: "border-box",
						width: drawerWidth,
						backgroundColor: "#272937",
						color: "#fff",
					},
				}}
			>
				<Toolbar disableGutters>
					<Stack
						justifyContent="center"
						alignItems="center"
						width="100%"
						overflow="hidden"
					>
						<img width="85px" src={twozo_logo} alt="logo" />
					</Stack>
				</Toolbar>

				<Box>
					<List>
						{getDrawerOptions()?.map((drawerOption, index) => (
							<ListItem key={index} disablePadding>
								<Tooltip
									title={drawerOption.name}
									placement="right"
								>
									<ListItemButton
										sx={{
											height: "52px",
											backgroundColor:
												location.pathname.startsWith(
													`/${
														drawerOption.hint ||
														drawerOption.path
													}`
												)
													? theme.palette.primary.main
													: "transparent",
											justifyContent: "center",
											borderRadius: "8px",
											margin: "5px",
											"&:hover": {
												backgroundColor:
													location.pathname.startsWith(
														`/${
															drawerOption.hint ||
															drawerOption.path
														}`
													)
														? theme.palette.primary
																.main
														: hexToRgba(
																theme.palette
																	.primary
																	.main,
																0.12
															),
											},
										}}
										onClick={() =>
											onModuleClicked(drawerOption)
										}
									>
										<Box display="flex">
											{drawerOption.icon(22, 22, "#fff")}
										</Box>
									</ListItemButton>
								</Tooltip>
							</ListItem>
						))}
					</List>
				</Box>

				<Stack
					height="100%"
					alignItems="center"
					justifyContent="flex-end"
				>
					<Stack
						spacing={2}
						justifyContent="center"
						alignItems="center"
					>
						<Box display="flex">
							<Phone />
						</Box>

						<Box
							onClick={toggleUserProfile}
							style={{
								cursor: "pointer",
								marginBottom: "20px",
							}}
						>
							<img
								src={UnKnownImage}
								alt="profile"
								width="28px"
								height="28px"
							/>
						</Box>
					</Stack>
				</Stack>
			</Drawer>
		</React.Fragment>
	);
}
