import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Divider, Stack } from "@mui/material";
import NameField from "../FieldType/Name";
import MetricsYAxis from "../FieldType/MetricsYAxis";
import Duration from "../FieldType/Duration";
import CompareTo from "../FieldType/CompareTo";
import { FormFieldName } from "../../../../../../styles/twozo";
import { isEmptyObject } from "../../../../../../utils/common";
import { useDashboardBuilderContext } from "../../Context";

const chartPropertiesDefaultValue = {
	viewName: "",
	viewProperties: {
		metrics: [
			{
				measureId: "",
				moduleData: "",
				moduleId: "",
				fieldId: "",
				label: "",
			},
		],
		duration: {
			fieldId: "",
			label: "",
			timeUnitId: "",
		},
		compareTo: {
			compareUnitId: "",
		},
	},
};

export default function KpiCardProperties(props) {
	const { chartConfiguration, onSubmit } = props;
	const { setIsSelectedChartDirty } = useDashboardBuilderContext();
	const {
		control,
		handleSubmit,
		setValue,
		formState: { isDirty, isValid: isApplyButtonEnabled },
		reset,
		getValues,
		unregister,
	} = useForm({
		defaultValues: { ...chartPropertiesDefaultValue },
	});

	useEffect(() => {
		setIsSelectedChartDirty(isDirty);
	}, [setIsSelectedChartDirty, isDirty]);

	useEffect(() => {
		const defaultValues = { ...chartPropertiesDefaultValue };
		if (chartConfiguration.viewName) {
			defaultValues["viewName"] = chartConfiguration.viewName;
		}
		if (!isEmptyObject(chartConfiguration.viewProperties)) {
			defaultValues["viewProperties"] = JSON.parse(
				JSON.stringify(chartConfiguration.viewProperties)
			); // Deep clone chartConfiguration.viewProperties

			defaultValues.viewProperties.metrics =
				defaultValues.viewProperties.metrics.map((viewProperty) => ({
					...viewProperty,
					moduleData: JSON.stringify({
						moduleId: viewProperty.moduleId,
						fieldId: viewProperty.fieldId,
					}),
				}));
		}
		reset(defaultValues);
	}, [chartConfiguration, reset]);

	const onFormSubmit = (data) => {
		onSubmit(data);
		reset({}, { keepValues: true });
	};

	return (
		<React.Fragment>
			<form
				onSubmit={handleSubmit(onFormSubmit)}
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					flex={1}
					style={{
						overflowY: "auto",
					}}
				>
					<Box p={1}>
						<NameField control={control} />
					</Box>

					<Box pt={1}>
						<Divider />
					</Box>

					<Box p={1} pt={2}>
						<FormFieldName py={1}>Metrics</FormFieldName>

						<MetricsYAxis
							index={0}
							getValues={getValues}
							control={control}
							setValue={setValue}
						/>
					</Box>

					<Box>
						<Divider />
					</Box>

					<Box p={1} pt={2}>
						<FormFieldName py={1}>Duration</FormFieldName>

						<Duration
							index={0}
							control={control}
							setValue={setValue}
							unregister={unregister}
						/>
					</Box>

					<Box>
						<Divider />
					</Box>

					<Box p={1} pt={2}>
						<CompareTo
							control={control}
							unregister={unregister}
							index={0}
						/>
					</Box>
				</Box>

				<Box>
					<Divider />
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						p={2}
					>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => reset()}
							disableElevation
						>
							Clear All
						</Button>

						<Button
							type="submit"
							variant="contained"
							disableElevation
							disabled={!isApplyButtonEnabled}
						>
							Apply
						</Button>
					</Stack>
				</Box>
			</form>
		</React.Fragment>
	);
}
