import { Box } from "@mui/material";
import GroupField from "./GroupField";
import SingleField from "./SingleField";

export default function FieldRow({
	field,
	moduleName,
	fieldState,
	unregister,
	selectedFieldIds,
	handleSelectChange,
	handleRemoveField,
	isRemoveAllowed,
}) {
	const isGroupedField = field?.config?.isGroupedField;

	return (
		<Box style={{ height: "100%" }}>
			{isGroupedField ? (
				<GroupField
					field={field}
					moduleName={moduleName}
					fieldState={fieldState}
					unregister={unregister}
					selectedFieldIds={selectedFieldIds}
					isRemoveAllowed={isRemoveAllowed}
					handleSelectChange={handleSelectChange}
					handleRemoveField={handleRemoveField}
				/>
			) : (
				<SingleField
					field={field}
					moduleName={moduleName}
					fieldState={fieldState}
					selectedFieldIds={selectedFieldIds}
					isRemoveAllowed={isRemoveAllowed}
					handleSelectChange={handleSelectChange}
					handleRemoveField={handleRemoveField}
				/>
			)}
		</Box>
	);
}
