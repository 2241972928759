import { useCallback, useReducer } from "react";

export const filterActions = {
	toggleSwipeableFilter: "TOGGLE_SWIPEABLE_FILTER",
	updateFilterUICriteriaList: "UPDATE_FILTER_UI_CRITERIA_LIST",
	applyFilter: "APPLY_FILTER",
	removeAppliedFilter: "REMOVE_APPLIED_FILTER",
	disableApplyButton: "DISABLE_APPLY_BUTTON",
	enableApplyButton: "ENABLE_APPLY_BUTTON",
	updateApplyFilterId: "UPDATE_APPLY_FILTER_ID",
	openSwipeableFilter: "OPEN_SWIPEABLE_FILTER",
	closeSwipeableFilter: "CLOSE_SWIPEABLE_FILTER",
	updateActiveFilterConfig: "UPDATE_ACTIVE_FILTER_CONFIG",
	updateActiveFilterDetails: "UPDATE_ACTIVE_FILTER_DETAILS",
	updateAdditionalCriteria: "UPDATE_ADDITIONAL_CRITERIA",
};

export const initialState = {
	isSwipeableFilterOpened: false,
	filterConditions: {},
	filterUICriteriaList: {
		lastAppliedCriteria: [],
		currentCriteria: [],
	},
	filterCount: null,
	isEdited: true,
	appliedFilterId: null,
	activeFilterConfig: {},
	activeFilterDetails: {},
	additionalCriteria: [],
};

export const reducer = (state, action) => {
	switch (action.type) {
		case filterActions.toggleSwipeableFilter:
			return {
				...state,
				isSwipeableFilterOpened: !state.isSwipeableFilterOpened,
			};
		case filterActions.updateFilterUICriteriaList: {
			const { currentCriteria, lastAppliedCriteria } = action.payload;
			const updatedLastAppliedCriteria =
				lastAppliedCriteria ??
				state.filterUICriteriaList.lastAppliedCriteria;
			return {
				...state,
				filterUICriteriaList: {
					...state.filterUICriteriaList,
					currentCriteria: currentCriteria,
					lastAppliedCriteria: updatedLastAppliedCriteria,
				},
				filterCount: updatedLastAppliedCriteria.length,
			};
		}
		case filterActions.applyFilter:
			return {
				...state,
				filterConditions: action.payload,
			};
		case filterActions.removeAppliedFilter:
			return {
				...state,
				filterConditions: {},
				filterUICriteriaList: {
					...state.filterUICriteriaList,
					currentCriteria: [],
				},
				filterCount: null,
			};
		case filterActions.disableApplyButton:
			return {
				...state,
				isEdited: false,
			};
		case filterActions.enableApplyButton:
			return {
				...state,
				isEdited: true,
			};
		case filterActions.updateApplyFilterId:
			return {
				...state,
				appliedFilterId: action.payload,
			};
		case filterActions.openSwipeableFilter:
			return {
				...state,
				isSwipeableFilterOpened: true,
			};
		case filterActions.closeSwipeableFilter:
			return {
				...state,
				isSwipeableFilterOpened: false,
			};
		case filterActions.updateActiveFilterConfig:
			return {
				...state,
				activeFilterConfig: action.payload,
			};
		case filterActions.updateActiveFilterDetails:
			return {
				...state,
				activeFilterDetails: action.payload,
			};
		case filterActions.updateAdditionalCriteria:
			return {
				...state,
				additionalCriteria: action.payload,
			};
		default:
			return state;
	}
};

export const useFilterDataReducer = () => {
	const [filterState, filterStateDispatcher] = useReducer(
		reducer,
		initialState
	);

	const toggleSwipeableFilter = () => {
		filterStateDispatcher({ type: filterActions.toggleSwipeableFilter });
	};

	const filterListByCondition = useCallback((filterCriteria) => {
		filterStateDispatcher({
			type: filterActions.applyFilter,
			payload: filterCriteria,
		});
	}, []);

	const removeAppliedFilter = () => {
		filterStateDispatcher({ type: filterActions.removeAppliedFilter });
	};

	const disableApplyButton = useCallback(() => {
		filterStateDispatcher({ type: filterActions.disableApplyButton });
	}, []);

	const updateAppliedFilterId = useCallback((id) => {
		filterStateDispatcher({
			type: filterActions.updateApplyFilterId,
			payload: id,
		});
	}, []);

	const updateFilterUICriteriaList = useCallback(
		(currentCriteria, lastAppliedCriteria) => {
			filterStateDispatcher({
				type: filterActions.updateFilterUICriteriaList,
				payload: { currentCriteria, lastAppliedCriteria },
			});
		},
		[]
	);

	const enableApplyButton = useCallback(() => {
		filterStateDispatcher({ type: filterActions.enableApplyButton });
	}, []);

	const openSwipeableFilter = () => {
		filterStateDispatcher({ type: filterActions.openSwipeableFilter });
		filterStateDispatcher({
			type: filterActions.updateApplyFilterId,
			payload: filterState?.activeFilterConfig?.id,
		});
	};

	const closeSwipeableFilter = useCallback(() => {
		filterStateDispatcher({ type: filterActions.closeSwipeableFilter });
	}, []);
	const updateActiveFilterConfig = useCallback((filterDetails) => {
		filterStateDispatcher({
			type: filterActions.updateActiveFilterConfig,
			payload: filterDetails,
		});
	}, []);

	const updateActiveFilterDetails = useCallback((filterDetails) => {
		filterStateDispatcher({
			type: filterActions.updateActiveFilterDetails,
			payload: filterDetails,
		});
	}, []);

	const updateAdditionalCriteria = (filterCriteria) => {
		filterStateDispatcher({
			type: filterActions.updateAdditionalCriteria,
			payload: filterCriteria,
		});
	};

	return {
		filterState,
		toggleSwipeableFilter,
		filterListByCondition,
		removeAppliedFilter,
		disableApplyButton,
		updateAppliedFilterId,
		updateFilterUICriteriaList,
		enableApplyButton,
		openSwipeableFilter,
		closeSwipeableFilter,
		updateActiveFilterConfig,
		updateActiveFilterDetails,
		updateAdditionalCriteria,
	};
};
