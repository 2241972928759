import React from "react";
import { Button, Stack, TextField, useTheme } from "@mui/material";
import { FormFieldName } from "../../../styles/twozo";
import { useForm } from "react-hook-form";

export default function UpsertDashboardDetails(props) {
	const {
		onSubmit,
		onCancel,
		submitButtonLabel,
		defaultValues = {},
		disableSubmit = false,
	} = props;
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
	} = useForm({
		defaultValues: { ...defaultValues },
		reValidateMode: false,
	});
	const theme = useTheme();

	return (
		<React.Fragment>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Stack p={2} spacing={2}>
					<Stack spacing={0.5}>
						<FormFieldName
							color={
								!!errors.dashboardName &&
								theme.palette.error.main
							}
						>
							Dashboard Name
							<span
								style={{
									color: theme.palette.error.main,
									marginLeft: "5px",
								}}
							>
								*
							</span>
						</FormFieldName>

						<TextField
							placeholder="Eg: Financial performance dashboard"
							{...register("dashboardName", {
								required: "This field is mandatory",
								maxLength: {
									value: 100,
									message:
										"Max. of 100 characters are allowed",
								},
							})}
							onChange={(event) => {
								clearErrors("dashboardName");
								setValue("dashboardName", event.target.value);
							}}
							error={!!errors.dashboardName}
							helperText={
								errors.dashboardName
									? errors.dashboardName.message
									: ""
							}
							FormHelperTextProps={{
								sx: {
									fontSize: 13,
									fontWeight: 500,
									ml: 1,
								},
							}}
						/>
					</Stack>

					<Stack spacing={0.5}>
						<FormFieldName
							color={
								!!errors.description && theme.palette.error.main
							}
						>
							Description
						</FormFieldName>

						<TextField
							{...register("description", {
								maxLength: {
									value: 255,
									message:
										"Max. of 255 characters are allowed",
								},
							})}
							error={!!errors.description}
							helperText={
								errors.description
									? errors.description.message
									: ""
							}
							onChange={(event) => {
								clearErrors("description");
								setValue("description", event.target.value);
							}}
							FormHelperTextProps={{
								sx: {
									ml: 1,
									fontSize: 13,
									fontWeight: 500,
								},
							}}
							multiline
							rows={2}
						/>
					</Stack>

					<Stack
						direction="row"
						spacing={2}
						justifyContent="end"
						pt={1}
					>
						<Button
							variant="contained"
							color="secondary"
							onClick={onCancel}
							disableElevation
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							type="submit"
							disableElevation
							disabled={disableSubmit}
						>
							{submitButtonLabel}
						</Button>
					</Stack>
				</Stack>
			</form>
		</React.Fragment>
	);
}
