import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import React, {
	forwardRef,
	useCallback,
	useImperativeHandle,
	useEffect,
	useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { handleFieldValidation } from "../../../../utils/fieldValidation";
import TerritoryMenu from "../../TerritoryMenu";

const customStyle = {
	"& .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&:hover .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
};

const TerritoryFieldEditForm = forwardRef(
	({ value, field, onError, onSave, onClose }, ref) => {
		const theme = useTheme();
		const fieldId = field?.fieldId;

		const {
			clearErrors,
			control,
			formState: { errors },
			handleSubmit,
			setValue,
		} = useForm({ values: { [fieldId]: value } });

		const [selectedValue, setSelectedValue] = useState({
			name: "",
			value: "",
		});

		useEffect(() => {
			// This useEffect is used to handle the edit case only:
			// If `value` is an object with a `name` property, we assume it's valid data from the server or edit case,

			const isValidServerSideData = () => {
				return value && value?.name;
			};

			if (isValidServerSideData()) {
				setSelectedValue({
					value: value.id,
					name: value.name,
				});
				setValue(fieldId + "", value?.id);
			}
		}, [value, setValue, fieldId]);

		const handleChangeTerritoryOption = (selectedValue, onChange) => {
			onChange(selectedValue?.value);
			setSelectedValue(selectedValue);
			clearErrors(fieldId + "");
			onError(null);
		};

		const submitForm = useCallback(() => {
			return handleSubmit(
				(data) => {
					onSave(data, onClose);
					onError(null);
					return data;
				},
				(errors) => {
					if (errors[fieldId]) {
						onError(errors[fieldId]);
					}
				}
			)();
		}, [handleSubmit, fieldId, onError, onSave, onClose]);

		useImperativeHandle(ref, () => ({
			submitForm,
		}));

		const handleRemove = (onChange) => {
			setSelectedValue({
				name: "",
				value: "",
			});
			onChange(null);
		};

		return (
			<React.Fragment>
				<Controller
					control={control}
					name={fieldId + ""}
					defaultValue=""
					rules={handleFieldValidation(field)}
					render={({ field: { onChange } }) => (
						<TerritoryMenu
							value={selectedValue}
							onChange={(selectedValue) =>
								handleChangeTerritoryOption(
									selectedValue,
									onChange
								)
							}
							error={!!errors[field.id]}
							handleRemoveTerritory={() => handleRemove(onChange)}
							territoryMenuStyle={customStyle}
						/>
					)}
				></Controller>

				{errors[fieldId] ? (
					<Typography
						pl={1}
						pb={1.2}
						style={{
							fontSize: 13,
							marginLeft: "6px",
							color: theme.palette.error.main,
						}}
					>
						{errors[fieldId]?.message}
					</Typography>
				) : null}
			</React.Fragment>
		);
	}
);

TerritoryFieldEditForm.displayName = "TerritoryFieldEditForm";

export default TerritoryFieldEditForm;
