import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { getIconByName } from "../../../../../../../utils";

export default function ActivityIcon(props) {
	const { icon, selected, selectIcon, id } = props;
	const theme = useTheme();
	const [hovered, setHovered] = useState(false);

	return (
		<React.Fragment>
			<Box
				component={"button"}
				style={{
					backgroundColor: selected
						? theme.palette.primary.main
						: "transparent",
					border:
						selected || hovered
							? `1px solid ${theme.palette.primary.main}`
							: "none",
					borderRadius: "8px",
					height: "44px",
					width: "44px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					cursor: "pointer",
				}}
				onMouseOver={() => setHovered(true)}
				onMouseOut={() => setHovered(false)}
				onClick={() => selectIcon({ id: id, name: icon })}
			>
				{getIconByName(icon.toLowerCase())(
					20,
					20,
					selected
						? "#fff"
						: hovered
							? theme.palette.primary.main
							: "#000"
				)}
			</Box>
		</React.Fragment>
	);
}
