import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import SingleDateTimePicker from "../../../../Elements/SingleDateTimePicker";

export default function DateTimeComponent(props) {
	const {
		field = {},
		updateFieldValue,
		error,
		style = {},
		value,
		clearError,
		...other
	} = props;

	const [dateValue, setDateValue] = useState(null);

	useEffect(() => {
		if (value) {
			setDateValue(dayjs(value?.value));
		}
	}, [value]);

	const handleChange = (event) => {
		setDateValue(event.valueOf());
		updateFieldValue([{ value: event.valueOf() || "" }], field.fieldId);
		clearError(field.fieldId);
	};

	return (
		<React.Fragment>
			<SingleDateTimePicker
				textFieldProps={{
					fullWidth: true,
					sx: {
						backgroundColor: "#fff",
						...style,
					},
				}}
				value={dateValue}
				error={error?.error}
				onChange={handleChange}
				{...other}
			/>
		</React.Fragment>
	);
}
