import React, { useState } from "react";
import { default as MuiAutocomplete } from "@mui/material/Autocomplete";
import {
	Box,
	CircularProgress,
	IconButton,
	InputAdornment,
	Paper,
	Popper,
	Skeleton,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../assets/icons/close";
import { default as AddIcon } from "../../../assets/icons/add";
import { default as CompanyIcon } from "../../../assets/icons/company";
import { useAuth } from "../../../hooks/auth";
import { PERMISSIONS } from "../../../utils/Auth";
import { useDebouncedCallback } from "use-debounce";
import { useLastRowRef } from "../../../hooks/common/InfiniteScroll";
import { useCompanyList } from "../../../hooks/services/company";
import { MenuItemText } from "../../../styles/twozo";

const CustomPopper = function (props) {
	return <Popper {...props} style={{ ...props.style, padding: "4px 0px" }} />;
};

const CustomPaper = function (props) {
	return (
		<Paper
			{...props}
			style={{
				...props.style,
				borderRadius: "8px",
				boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
			}}
		/>
	);
};

export default function CompanyAutoComplete(props) {
	const {
		inputRef,
		value,
		onChange,
		onCancel,
		textFieldSx,
		isAllowNewOption = false,
		isIconNeeded = true,
		placeHolder = "Select",
		error,
		clearErrors,
		disabled = false,
	} = props;

	const theme = useTheme();

	const [companyInputValue, setCompanyInputValue] = useState("");

	const { isUserAllowedFor } = useAuth();
	const isAllowToAddNewOption =
		isUserAllowedFor(PERMISSIONS.company.create) && isAllowNewOption;
	const isCompanyListEnabled = isUserAllowedFor(PERMISSIONS.company.view);

	const displayCancelIcon = Boolean(value?.name);

	const {
		data: companies,
		isLoading: isLoadingCompanies,
		isFetching: isFetchingCompanies,
		hasNextPage: hasNextPage,
		fetchNextPage: fetchNextPage,
	} = useCompanyList(isCompanyListEnabled, companyInputValue);

	const lastOptionRef = useLastRowRef(
		fetchNextPage,
		hasNextPage,
		isFetchingCompanies
	);

	const onInputChange = (event) => {
		const { value } = event.target;
		setCompanyInputValue(value?.trimStart());
		clearErrors ? clearErrors() : null;
	};

	const handleCompanyInputChange = useDebouncedCallback(
		(event) => {
			onInputChange(event);
		},
		500 // delay in ms
	);

	const onBlurCompanyInput = () => {
		setCompanyInputValue("");
	};

	const handleSelectCompany = (_, value) => {
		setCompanyInputValue("");
		onChange(value);
	};

	const filterOptions = (options, params) => {
		if (isLoadingCompanies) {
			return options;
		} else {
			const { inputValue } = params;
			const filteredOption = [...options];
			const isExistingCompanyName = filteredOption.some(
				(option) => inputValue === option.name
			);

			if (!isExistingCompanyName && isAllowToAddNewOption) {
				filteredOption.unshift({
					name: inputValue,
					isNewOption: true,
				});
			}
			return filteredOption;
		}
	};

	const hasLastOption = (option) => {
		let renderOptionIndex = companies?.indexOf(option);
		let lastOptionIndex = companies?.indexOf(
			companies?.[companies?.length - 1]
		);

		return renderOptionIndex === lastOptionIndex;
	};

	const renderOption = (option) => {
		if (isAllowToAddNewOption && option.isNewOption) {
			return (
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					width="100%"
				>
					<MenuItemText
						fontWeight={500}
						color={theme.palette.primary.main}
					>
						{option?.name}
					</MenuItemText>

					<Box display="flex">
						{AddIcon(20, 20, theme.palette.primary.main)}
					</Box>
				</Stack>
			);
		} else {
			return (
				<Stack spacing={1}>
					<Stack direction="row" spacing={2} alignItems="center">
						<MenuItemText>{option?.name}</MenuItemText>
					</Stack>

					{hasNextPage && hasLastOption(option) ? (
						<Box ref={lastOptionRef}>
							{!isLoadingCompanies && isFetchingCompanies && (
								<Stack
									direction="row"
									alignItems="center"
									justifyContent="center"
									spacing={1}
								>
									<Skeleton width="200px" height="16px" />
								</Stack>
							)}
						</Box>
					) : null}
				</Stack>
			);
		}
	};

	return (
		<MuiAutocomplete
			size="small"
			value={value}
			disabled={disabled}
			onChange={handleSelectCompany}
			options={companies || []}
			noOptionsText={
				<Typography style={{ fontSize: "14px" }}>
					No Results Found
				</Typography>
			}
			open={Boolean(companyInputValue)}
			readOnly={Boolean(value)}
			PopperComponent={CustomPopper}
			PaperComponent={CustomPaper}
			forcePopupIcon={false}
			disableClearable
			filterSelectedOptions
			loading={isLoadingCompanies}
			loadingText={
				<Stack
					alignItems="center"
					justifyContent="center"
					height="140px"
				>
					<CircularProgress size="18px" />
				</Stack>
			}
			style={{
				width: "100%",
			}}
			getOptionLabel={(option) => {
				return option?.name ?? "";
			}}
			renderOption={(props, option) => {
				return (
					<Box
						{...props}
						key={option?.value}
						style={{
							minHeight: "40px",
							width: "100%",
						}}
					>
						{renderOption(option)}
					</Box>
				);
			}}
			filterOptions={filterOptions}
			renderInput={(params) => (
				<TextField
					{...params}
					inputRef={inputRef || null}
					placeholder={placeHolder || "Enter"}
					value={companyInputValue}
					onChange={handleCompanyInputChange}
					onBlur={onBlurCompanyInput}
					InputProps={{
						...params.InputProps,
						startAdornment: isIconNeeded && (
							<>
								<InputAdornment
									position="start"
									style={{
										paddingLeft: "8px",
									}}
								>
									{CompanyIcon(
										20,
										20,
										error
											? theme.palette.error.main
											: "#666666"
									)}
								</InputAdornment>
								{params.InputProps.startAdornment}
							</>
						),
						endAdornment: (
							<InputAdornment position="end">
								{displayCancelIcon && (
									<IconButton onClick={onCancel}>
										{CloseIcon(
											20,
											20,
											theme.palette.primary.main
										)}
									</IconButton>
								)}
							</InputAdornment>
						),
					}}
					inputProps={{
						...params.inputProps,
						onKeyDown: (event) => {
							if (event.key === "Enter") {
								event.stopPropagation();
							}
						},
						style: {
							fontSize: "14px",
						},
					}}
					sx={{
						...textFieldSx,
					}}
					error={!!error}
				/>
			)}
		/>
	);
}
