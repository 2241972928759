import Row from "../../../Elements/AddForm/Row/index";
import { Box, Grid } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import React, { useState, useEffect } from "react";
import AddFormField from "../../../Elements/AddFormField";
import { modules } from "../../../../utils/common/ModulesName";
import { useFormFieldsData } from "../../../../hooks/services/common/field";
import { useContactFieldPermissions } from "../../../../hooks/modules/auth/contact";
import FieldPermissionContextProvider from "../../../Elements/FieldPermissionContext/FieldPermissionContextProvider";

export default function AddNewContact({ onSubmit, onClose, contactFormData }) {
	const [fields, setFields] = useState([]);
	const [subFields, setSubFields] = useState([]);
	const [source, setSource] = useState({});
	const moduleName = modules.CONTACT;
	const formMethods = useForm({ values: contactFormData });
	const { status: formFieldsStatus, data: formFieldsData } =
		useFormFieldsData(moduleName);
	const contactFieldPermissions = useContactFieldPermissions();

	useEffect(() => {
		if (formFieldsStatus === "success") {
			if (formFieldsData.fields) {
				let requiredContactFields = [];
				for (
					let index = 0;
					index < formFieldsData.fields.length;
					index++
				) {
					for (let field of formFieldsData.fields[index]) {
						if (field?.config?.required) {
							requiredContactFields = [
								...requiredContactFields,
								[field],
							];
						}
					}
				}
				setFields(requiredContactFields);
			}

			if (formFieldsData.subFields) {
				setSubFields(formFieldsData.subFields);
			}

			if (formFieldsData.source) {
				setSource(formFieldsData.source);
			}
		}
	}, [formFieldsData, formFieldsStatus]);

	return (
		<React.Fragment>
			<FieldPermissionContextProvider value={contactFieldPermissions}>
				<AddFormField
					onSave={formMethods.handleSubmit(onSubmit)}
					onClose={onClose}
				>
					<Box
						style={{
							height: "100%",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<FormProvider {...formMethods}>
							<Box
								p={2}
								style={{
									flex: "1 1 auto",
									minHeight: 0,
									overflowY: "auto",
								}}
							>
								<Grid
									container
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
									spacing={2}
								>
									{fields?.map((field, index) => (
										<Row
											field={field}
											subFields={subFields}
											key={index}
											source={source}
										/>
									))}
								</Grid>
							</Box>
						</FormProvider>
					</Box>
				</AddFormField>
			</FieldPermissionContextProvider>
		</React.Fragment>
	);
}
