import { useEffect, useState } from "react";
import {
	useStepOptions,
	useWorkflowDropdownList,
} from "../../../../../hooks/services/workflow";
import MultiSelectBaseComponent from "./MultiSelectBaseComponent";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";

export default function MultiSelectComponent(params) {
	const {
		field = {},
		hasError,
		metadata,
		updateFieldValue,
		value,
		style = {},
		clearError,
		isConditionField,
	} = params;
	const [selectedValues, setSelectedValues] = useState([]);
	const [searchValue, setSearchValue] = useState("");

	const { data: stepOptions } = useStepOptions(metadata, field);

	const {
		data: options,
		isLoading: isOptionsLoading,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useWorkflowDropdownList(field, searchValue);

	const formattedOptions = options?.map((option) => ({
		value: {
			name: option.name,
			id: option.id || option.value,
		},
	}));

	useEffect(() => {
		if (Array.isArray(value)) {
			setSelectedValues(value);
		}
	}, [value]);

	const handleFieldChange = (values) => {
		setSelectedValues(values);
		updateFieldValue(values, field.fieldId);
		clearError(field.fieldId);
	};
	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const updateSearchValue = (value) => {
		setSearchValue(value);
	};

	return (
		<MultiSelectBaseComponent
			style={style}
			isConditionField={isConditionField}
			options={formattedOptions || []}
			hasError={hasError}
			stepOptions={stepOptions || []}
			selectedValues={selectedValues}
			onFieldChange={handleFieldChange}
			isFetching={isFetching}
			isOptionsLoading={isOptionsLoading}
			lastRowRef={lastRowRef}
			searchValue={searchValue}
			updateSearchValue={updateSearchValue}
			hasStepOptions={field?.config?.hasStepOptions}
		/>
	);
}
