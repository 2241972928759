import React, { useState } from "react";
import { Box, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import { default as FileIcon } from "../../../../assets/icons/files";
import { default as ImageIcon } from "../../../../assets/icons/image";
import {
	getDateMonthAndYearFormat,
	getHourAndMinuteFormat,
} from "../../../../utils/DateUtils";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import TooltipComponent from "../../../Elements/TooltipComponent";
import { useDeleteFile } from "../../../../hooks/services/summary/files";
import Menu from "../../../Elements/Menu";
import DeleteDialog from "../../../Elements/DeleteDialog";
import { getFormattedFileSize } from "../../../../utils/fileUtills";
import { imageExtension } from "../../../../utils/fileUtills";
import { MenuItemText } from "../../../../styles/twozo";
import MoreIconButton from "../../../Elements/MoreIconButton";

const styles = {
	text: {
		fontSize: "14px",
		color: "rgba(0, 0, 0, 0.6)",
	},
	greyedText: {
		fontSize: "15px",
		color: "rgba(0, 0, 0, 0.6)",
	},
};
export default function FileItem(props) {
	const { fileData, sourceName, entityId } = props;
	const theme = useTheme();

	const [hoveredFile, setHoveredFile] = useState(false);
	const [fileMenuElement, setFileMenuElement] = useState(null);
	const isFileMenuOpened = Boolean(fileMenuElement);
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);

	const deleteFileMutation = useDeleteFile(sourceName, entityId);

	const handleDeleteFile = () => {
		deleteFileMutation.mutate(
			{ id: fileData?.id },
			{
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.filesDeleted,
					});
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.errorMessage,
					});
				},
			}
		);
	};

	const onMouseOverFile = () => setHoveredFile(true);
	const onMouseOutFile = () => setHoveredFile(false);

	const openFileMenu = (event) => {
		setFileMenuElement(event.currentTarget);
	};

	const closeFileMenu = () => {
		setFileMenuElement(null);
	};

	const openDeleteDialog = () => {
		setIsDeleteDialogOpened(true);
		closeFileMenu();
	};

	const closeDeleteDialog = () => {
		setIsDeleteDialogOpened(false);
	};

	const handleDownloadFile = () => {
		closeFileMenu();
		window.location.href = `/api/file/download?fileId=${fileData?.id}`;
	};

	return (
		<React.Fragment>
			{/* File Menu */}
			<Menu
				width="200px"
				anchorEl={fileMenuElement}
				open={isFileMenuOpened}
				onClose={closeFileMenu}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				style={{
					marginTop: "2px",
				}}
			>
				<MenuItem
					style={{ height: "40px" }}
					onClick={handleDownloadFile}
				>
					<MenuItemText
						sx={{ fontWeight: 500 }}
						color={theme.palette.secondary.contrastText}
					>
						Download
					</MenuItemText>
				</MenuItem>

				<MenuItem style={{ height: "40px" }} onClick={openDeleteDialog}>
					<MenuItemText
						sx={{ fontWeight: 500 }}
						color={theme.palette.secondary.contrastText}
					>
						Delete
					</MenuItemText>
				</MenuItem>
			</Menu>

			{/* Delete Dialog */}
			<DeleteDialog
				open={isDeleteDialogOpened}
				title="Are you sure you want to delete this file?"
				subtitle="This action cannot be undone."
				onCancel={closeDeleteDialog}
				onDelete={handleDeleteFile}
				disableDeleteButton={deleteFileMutation.isLoading}
			/>

			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				onMouseOver={onMouseOverFile}
				onMouseOut={onMouseOutFile}
				sx={{
					borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
				}}
				px={3}
				py={2}
			>
				<Stack direction="row" alignItems="center" spacing={2}>
					<Box display="flex">
						{imageExtension?.includes(
							fileData?.extension?.toLowerCase()
						)
							? ImageIcon(24, 24)
							: FileIcon(24, 24)}
					</Box>

					<Stack>
						<Stack direction="row" alignItems="center" spacing={1}>
							<Box display="flex" onClick={handleDownloadFile}>
								<TooltipComponent
									title={fileData.fileName}
									placement="top"
									width="300px"
									verticalDisplacement={-3}
								>
									<Typography
										fontSize={15}
										fontWeight={500}
										color={
											hoveredFile
												? theme.palette.secondary
														.contrastText
												: "#000"
										}
										style={{
											cursor: "pointer",
											maxWidth: "800px",
										}}
										noWrap
									>
										{fileData.fileName}
									</Typography>
								</TooltipComponent>
							</Box>

							{fileData.size ? (
								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
								>
									<Typography style={styles.greyedText}>
										&#8226;
									</Typography>

									<Typography style={styles.greyedText}>
										{getFormattedFileSize(fileData.size)}
									</Typography>
								</Stack>
							) : null}
						</Stack>

						<Stack direction="row" spacing={1}>
							<TooltipComponent
								title={fileData.ownerName}
								placement="top"
								width="259px"
							>
								<Typography
									noWrap
									maxWidth="700px"
									style={styles.text}
								>
									{fileData.ownerName}
								</Typography>
							</TooltipComponent>

							<Typography style={styles.text}>&#8226;</Typography>

							<Typography style={styles.text}>
								{getDateMonthAndYearFormat(
									fileData.uploadedTime
								)}
								,{" "}
								{getHourAndMinuteFormat(fileData.uploadedTime)}
							</Typography>
						</Stack>
					</Stack>
				</Stack>

				<Box style={{ width: "30px" }}>
					<MoreIconButton
						onClick={openFileMenu}
						isFocused={isFileMenuOpened}
					/>
				</Box>
			</Stack>
		</React.Fragment>
	);
}
