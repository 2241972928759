import React from "react";
import Menu from "../../../Elements/Menu";
import { Stack, Typography, useTheme } from "@mui/material";
import CompanyProfile from "../../../../assets/images/company/companyProfile.png";
import { useNavigate } from "react-router-dom";

export default function CompanyCard(props) {
	const {
		companyDetails,
		companyCardMenuElement,
		isCompanyCardDisplayed,
		hideCompanyCard,
	} = props;
	const theme = useTheme();
	const navigate = useNavigate();

	return (
		<React.Fragment>
			<Menu
				minWidth="320px"
				anchorEl={companyCardMenuElement}
				open={isCompanyCardDisplayed}
				onClose={hideCompanyCard}
				style={{
					marginTop: "6px",
				}}
				sx={{
					"& .MuiMenu-list": {
						paddingTop: "4px",
						paddingBottom: "4px",
					},
				}}
			>
				<Stack
					px={2}
					py={2}
					direction="row"
					justifyContent="center"
					alignItems="center"
					spacing={3}
				>
					<img
						src={CompanyProfile}
						alt="img"
						width={64}
						height={64}
					/>

					<Stack justifyContent="center">
						<Typography
							fontSize={18}
							fontWeight={600}
							color={theme.palette.secondary.contrastText}
							style={{
								cursor: "pointer",
								maxWidth: "200px",
							}}
							noWrap
							onClick={() =>
								navigate(`/companies/${companyDetails.id}`)
							}
						>
							{companyDetails?.name}
						</Typography>

						<Typography
							fontSize={14}
							fontWeight={500}
							style={{ maxWidth: "200px" }}
							color={theme.palette.secondary.contrastText}
							noWrap
						>
							{companyDetails?.website}
						</Typography>
					</Stack>
				</Stack>
			</Menu>
		</React.Fragment>
	);
}
