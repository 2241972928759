import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	getEmailTemplateDataKey,
	getEmailTemplateDataWithValuesKey,
	getEmailTemplateListKey,
	getPlaceHolderFieldsKey,
	getPlaceHolderFieldsWithValueKey,
	getPlaceHolderSourcesKey,
	getTemplateListKey,
} from "../../../../utils/queryKeys/email/emailTemplate";
import {
	bulkDeleteEmailTemplate,
	changeEmailTemplateToFolder,
	cloneEmailTemplate,
	createTemplate,
	deleteEmailTemplate,
	getEmailTemplateData,
	getPlaceHolderFields,
	getPlaceHolderFieldsWithValue,
	getPlaceHolderSources,
	getTemplateList,
	updateTemplate,
} from "../../../../api/email/emailTemplate/emailTemplateApi";
import { useInvalidateEmailTemplates } from "../emailTemplateFolder";

const usePlaceHolderSources = () => {
	return useQuery(getPlaceHolderSourcesKey(), () => getPlaceHolderSources(), {
		select: (data) => data.source,
	});
};

const usePlaceHolderFields = (sourceName) => {
	return useQuery(
		getPlaceHolderFieldsKey(sourceName),
		() => getPlaceHolderFields(sourceName),
		{
			enabled: !!sourceName,
		}
	);
};

const usePlaceHolderFieldsWithValues = (sourceName, contactId) => {
	return useQuery(
		getPlaceHolderFieldsWithValueKey(sourceName, contactId),
		() => getPlaceHolderFieldsWithValue(sourceName, contactId),
		{
			enabled: Boolean(contactId),
		}
	);
};

const useTemplateList = () => {
	return useQuery(getTemplateListKey(), () => getTemplateList(), {
		select: (data) => data.list,
	});
};

const useInvalidateTemplateList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getTemplateListKey());
	};
};

const useTemplateData = (templateId) => {
	return useQuery(
		getEmailTemplateDataKey(templateId),
		() => getEmailTemplateData(templateId),
		{
			select: (data) => data.template,
			enabled: Boolean(templateId),
		}
	);
};

const useTemplateDataWithValues = (templateId, entityId) => {
	return useQuery(
		getEmailTemplateDataWithValuesKey(templateId, entityId),
		() => getEmailTemplateData(templateId, entityId),
		{
			select: (data) => data.template,
			enabled: Boolean(entityId) && Boolean(templateId),
		}
	);
};

const useInvalidateTemplateData = (templateId) => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getEmailTemplateDataKey(templateId));
	};
};

const useCreateEmailTemplate = (folderId) => {
	let invalidateEmailTemplates = useInvalidateEmailTemplates(folderId);
	let invalidateTemplateList = useInvalidateTemplateList();
	return useMutation(createTemplate, {
		onSuccess: () => {
			invalidateEmailTemplates();
			invalidateTemplateList();
		},
	});
};

const useUpdateEmailTemplate = (folderId, templateId) => {
	let invalidateEmailTemplates = useInvalidateEmailTemplates(folderId);
	let invalidateTemplateData = useInvalidateTemplateData(templateId);
	return useMutation(updateTemplate, {
		onSuccess: () => {
			invalidateEmailTemplates();
			invalidateTemplateData();
		},
	});
};

const useCloneEmailTemplate = (folderId) => {
	let invalidateEmailTemplates = useInvalidateEmailTemplates(folderId);
	return useMutation(cloneEmailTemplate, {
		onSuccess: () => {
			invalidateEmailTemplates();
		},
	});
};

const useDeleteEmailTemplate = (folderId) => {
	let invalidateEmailTemplates = useInvalidateEmailTemplates(folderId);
	return useMutation(deleteEmailTemplate, {
		onSuccess: () => {
			invalidateEmailTemplates();
		},
	});
};

const useBulkDeleteEmailTemplate = (folderId) => {
	let invalidateEmailTemplates = useInvalidateEmailTemplates(folderId);
	return useMutation(bulkDeleteEmailTemplate, {
		onSuccess: () => {
			invalidateEmailTemplates();
		},
	});
};

const useChangeEmailTemplateToFolder = () => {
	let queryClient = useQueryClient();
	return useMutation(changeEmailTemplateToFolder, {
		onSuccess: (_, payload) => {
			queryClient.invalidateQueries(
				getEmailTemplateListKey(payload.sourceId)
			);
			queryClient.invalidateQueries(
				getEmailTemplateListKey(payload.destinationId)
			);
		},
	});
};

export {
	usePlaceHolderSources,
	usePlaceHolderFields,
	useCreateEmailTemplate,
	useUpdateEmailTemplate,
	useCloneEmailTemplate,
	useDeleteEmailTemplate,
	useBulkDeleteEmailTemplate,
	useTemplateData,
	useChangeEmailTemplateToFolder,
	useTemplateList,
	usePlaceHolderFieldsWithValues,
	useTemplateDataWithValues,
};
