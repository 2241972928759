import React, { useEffect, useState } from "react";
import { Box, Dialog, Stack, Typography, useTheme } from "@mui/material";
import LinearProgress, {
	linearProgressClasses,
} from "@mui/material/LinearProgress";
import { default as RightArrowIcon } from "../../assets/icons/rightArrow";
import { hexToRgba } from "../../styles/twozo";
import { useNavigate } from "react-router-dom";
import { useCurrentUserDetails } from "../../hooks/services/userProfile";

const style = {
	buttonContainer: {
		border: "1px solid rgba(0, 0, 0, 0.1)",
		borderRadius: "8px",
		overflow: "hidden",
	},
	textButton: {
		border: "none",
		borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
		background: "transparent",
		cursor: "pointer",
		height: "42px",
		width: "100%",
		textAlign: "left",
		padding: "0px 12px",
	},
};

export default function Welcome() {
	const theme = useTheme();
	const navigate = useNavigate();
	const [openWelcomeScreen, setOpenWelcomeScreen] = useState(false);
	const [step, setStep] = useState(1);

	const { data: userDetails } = useCurrentUserDetails();

	const skipToHome = () => {
		navigateToUrl("/deals");
	};

	useEffect(() => {
		const isFirstSignIn = JSON.parse(localStorage.getItem("isFirstSignIn"));
		setOpenWelcomeScreen(!!isFirstSignIn);
	}, []);

	const navigateToUrl = (url) => {
		localStorage.setItem("isFirstSignIn", false);
		setOpenWelcomeScreen(false);
		navigate(url);
	};

	const IndustrySelection = () => (
		<React.Fragment>
			<Typography fontSize={13} fontWeight={500}>
				Which industry your company in
			</Typography>

			<Box style={style.buttonContainer}>
				<Box
					component="button"
					style={style.textButton}
					onClick={() => setStep(2)}
				>
					<Typography fontSize={14}>Business Service</Typography>
				</Box>

				<Box
					component="button"
					style={style.textButton}
					onClick={() => setStep(2)}
				>
					<Typography fontSize={14}>SaaS</Typography>
				</Box>

				<Box
					component="button"
					style={style.textButton}
					onClick={() => setStep(2)}
				>
					<Typography fontSize={14}>Financial Services</Typography>
				</Box>

				<Box
					component="button"
					style={style.textButton}
					onClick={() => setStep(2)}
				>
					<Typography fontSize={14}>Manufacturing</Typography>
				</Box>

				<Box
					component="button"
					style={style.textButton}
					onClick={() => setStep(2)}
				>
					<Typography fontSize={14}>E-Commerce</Typography>
				</Box>

				<Box
					component="button"
					style={{ ...style.textButton, borderBottom: "none" }}
					onClick={() => setStep(2)}
				>
					<Typography fontSize={14}>Others</Typography>
				</Box>
			</Box>
		</React.Fragment>
	);

	const CompanySizeSelection = () => (
		<React.Fragment>
			<Typography fontSize={13} fontWeight={500}>
				How big is your company
			</Typography>

			<Box style={style.buttonContainer}>
				<Box
					component="button"
					style={style.textButton}
					onClick={() => setStep(3)}
				>
					<Typography fontSize={14}>1</Typography>
				</Box>

				<Box
					component="button"
					style={style.textButton}
					onClick={() => setStep(3)}
				>
					<Typography fontSize={14}>2 - 10 People</Typography>
				</Box>

				<Box
					component="button"
					style={style.textButton}
					onClick={() => setStep(3)}
				>
					<Typography fontSize={14}>11 - 50 People</Typography>
				</Box>

				<Box
					component="button"
					style={style.textButton}
					onClick={() => setStep(3)}
				>
					<Typography fontSize={14}>51 - 100 People</Typography>
				</Box>

				<Box
					component="button"
					style={style.textButton}
					onClick={() => setStep(3)}
				>
					<Typography fontSize={14}>101 - 250 People</Typography>
				</Box>

				<Box
					component="button"
					style={style.textButton}
					onClick={() => setStep(3)}
				>
					<Typography fontSize={14}>251 - 500 People</Typography>
				</Box>

				<Box
					component="button"
					style={style.textButton}
					onClick={() => setStep(3)}
				>
					<Typography fontSize={14}>501 - 1000 People</Typography>
				</Box>

				<Box
					component="button"
					style={{ ...style.textButton, borderBottom: "none" }}
					onClick={() => setStep(3)}
				>
					<Typography fontSize={14}>1000 + People</Typography>
				</Box>
			</Box>
		</React.Fragment>
	);

	const CustomerSizeSelection = () => (
		<React.Fragment>
			<Box>
				<Typography fontSize={13} fontWeight={500}>
					How many people will use{" "}
					<span
						style={{ color: theme.palette.secondary.contrastText }}
					>
						Twozo
					</span>{" "}
					in your company.
				</Typography>

				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					This will not affect the actual users
				</Typography>
			</Box>

			<Box style={style.buttonContainer}>
				<Box
					component="button"
					style={style.textButton}
					onClick={() => setStep(4)}
				>
					<Typography fontSize={14}>1</Typography>
				</Box>

				<Box
					component="button"
					style={style.textButton}
					onClick={() => setStep(4)}
				>
					<Typography fontSize={14}>2 - 5 People</Typography>
				</Box>

				<Box
					component="button"
					style={style.textButton}
					onClick={() => setStep(4)}
				>
					<Typography fontSize={14}>6 - 10 People</Typography>
				</Box>

				<Box
					component="button"
					style={style.textButton}
					onClick={() => setStep(4)}
				>
					<Typography fontSize={14}>11 - 50 People</Typography>
				</Box>

				<Box
					component="button"
					style={{ ...style.textButton, borderBottom: "none" }}
					onClick={() => setStep(4)}
				>
					<Typography fontSize={14}>50 + People</Typography>
				</Box>
			</Box>
		</React.Fragment>
	);

	const NavigationButton = (props) => (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			style={{
				backgroundColor: "rgba(51, 188, 126, 0.12)",
				padding: "10px 16px",
				borderRadius: "8px",
				cursor: "pointer",
				boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.08)",
			}}
			onClick={props.onclick}
		>
			<Typography
				fontSize={14}
				fontWeight={500}
				color={theme.palette.secondary.contrastText}
			>
				{props.name}
			</Typography>

			{RightArrowIcon(16, 16, "#000")}
		</Stack>
	);

	return (
		<React.Fragment>
			<Dialog
				open={openWelcomeScreen}
				sx={{
					backdropFilter: "blur(5px)",
				}}
				PaperProps={{
					sx: {
						borderRadius: "8px",
						width: "450px",
					},
				}}
			>
				<Box>
					<LinearProgress
						variant="determinate"
						value={25 * step}
						sx={{
							height: "10px",
							[`&.${linearProgressClasses.colorPrimary}`]: {
								backgroundColor: (theme) =>
									hexToRgba(theme.palette.primary.main, 0.12),
							},
						}}
					/>

					{step < 4 ? (
						<React.Fragment>
							<Stack spacing={0.5} p={5} pb={3}>
								<Typography fontSize={18} fontWeight={600}>
									Hello{" "}
									<span
										style={{
											color: theme.palette.secondary
												.contrastText,
										}}
									>
										{userDetails?.user?.firstName}
									</span>
									, Nice to meet you!
								</Typography>

								<Typography fontSize={13}>
									We’d like to know more about your company to
									tailor the experience.
								</Typography>
							</Stack>

							<Stack px={5} pb={4} spacing={0.5}>
								{(function () {
									switch (step) {
										case 1:
											return <IndustrySelection />;
										case 2:
											return <CompanySizeSelection />;
										case 3:
											return <CustomerSizeSelection />;
										default:
											return null;
									}
								})()}
							</Stack>
						</React.Fragment>
					) : (
						<React.Fragment>
							<Stack spacing={0.5} p={5} pb={3}>
								<Typography fontSize={18} fontWeight={600}>
									Thank You!
								</Typography>

								<Typography fontSize={13}>
									Kickstart your journey from below
								</Typography>
							</Stack>

							<Stack px={5} pb={4} spacing={1}>
								<NavigationButton
									name="Home"
									onclick={() => navigateToUrl("/deals")}
								/>
								<NavigationButton
									name="Invite users"
									onclick={() =>
										navigateToUrl(
											"/settings/user-management?type=users"
										)
									}
								/>
								<NavigationButton
									name="Import contacts"
									onclick={() =>
										navigateToUrl(
											"/contacts?openImport=true"
										)
									}
								/>
								<NavigationButton
									name="Migrate from another CRM"
									onclick={() => navigateToUrl("/settings")}
								/>
								<NavigationButton
									name="Link email & calendar"
									onclick={() =>
										navigateToUrl(
											"/settings/email?type=email_sync"
										)
									}
								/>
								<NavigationButton
									name="Setup the organization"
									onclick={() =>
										navigateToUrl(
											"/settings/account-settings?type=settings"
										)
									}
								/>
							</Stack>
						</React.Fragment>
					)}

					{step < 4 && (
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="center"
							style={{
								borderTop: "1px solid rgba(0, 0, 0, 0.1)",
								cursor: "pointer",
							}}
							onClick={() => skipToHome()}
							p={2}
							spacing={1}
						>
							<Typography
								fontSize={13}
								fontWeight={500}
								color={theme.palette.secondary.contrastText}
							>
								Skip to Home
							</Typography>

							{RightArrowIcon(
								16,
								16,
								theme.palette.secondary.contrastText
							)}
						</Stack>
					)}
				</Box>
			</Dialog>
		</React.Fragment>
	);
}
