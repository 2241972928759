import { phoneNumberPattern } from "../../config";

export const validatePhoneField = (field, config, isActionField) => {
	const value = field?.values?.[0]?.value?.trim() ?? "";
	const min = 5;
	const max = 17;

	if (!value?.toString()) {
		return { error: true, message: isActionField ? "" : "Can’t be empty" };
	}
	if (!value.toString() || value.length < min) {
		return {
			error: true,
			message: `Min. of ${min} numbers are required`,
		};
	}
	if (max && value && value.length > max) {
		return {
			error: true,
			message: `Max. of ${max} numbers are allowed`,
		};
	}
	if (!phoneNumberPattern.test(value)) {
		return {
			error: true,
			message:
				"Please enter a valid number in the format '+1 (202) 555-0143'.",
		};
	}

	return { error: false, message: "" };
};
