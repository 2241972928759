import { getRootKey } from "../rootKey";

const importKey = "import";

//get the import query key
export const getImportKey = () => {
	return [...getRootKey(), importKey];
};

// query keys
export const importHistoryKey = [...getImportKey(), "history"];

export const importStatisticsKey = [...getImportKey(), "importStatistics"];

export const importModulesListKey = [...getImportKey(), "importModulesList"];

// query key methods
export const getImportReadDataKey = (importId) => {
	return [...getImportKey(), String(importId), "read"];
};

export const getImportFieldsListKey = (sourceType) => {
	return [...getImportKey(), sourceType, "importFields"];
};

export const getImportDetailsKey = (importHistoryId) => {
	return [...getImportKey(), String(importHistoryId), "importDetails"];
};

export const getImportStatusKey = (importedFileId) => {
	return [...getImportKey(), String(importedFileId), "importStatus"];
};

export const getImportRevertStatusKey = (reveredFileId) => {
	return [...getImportKey(), "revertStatus", String(reveredFileId)];
};

export const getImportPreviewKey = (updatedMappingId) => {
	return [...getImportKey(), String(updatedMappingId), "preview"];
};
