import React, { useState } from "react";
import {
	Box,
	IconButton,
	InputAdornment,
	MenuItem,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as DragIcon } from "../../../../../assets/icons/drag";
import { default as EditIcon } from "../../../../../assets/icons/edit";
import { default as TickIcon } from "../../../../../assets/icons/tick";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import Menu from "../../../../Elements/Menu";
import {
	useDeletePipelineStage,
	useUpdatePipelineStage,
} from "../../../../../hooks/services/deal/pipeline";
import { MenuItemText } from "../../../../../styles/twozo";
import MoreIconButton from "../../../../Elements/MoreIconButton";

export default function Stage(props) {
	const { stage, updateIsHideEditMoreIcon, isHideEditMoreIcon } = props;
	const theme = useTheme();
	const [hoveredId, setHoveredId] = useState(null);
	const [stageHoveredId, setStageHoveredId] = useState(null);
	const [isStageInEditMode, setIsStageInEditMode] = useState(false);
	const [pipelineStage, setPipelineStage] = useState(stage);
	const [deletePipelineStageMenuElement, setDeletePipelineStageMenuElement] =
		useState(null);
	const isDeletePipelineStageMenuOpened = Boolean(
		deletePipelineStageMenuElement
	);

	const updatePipelineStageMutation = useUpdatePipelineStage();
	const deletePipelineStageMutation = useDeletePipelineStage();

	const handleUpdateStage = () => {
		if (
			pipelineStage.name !== stage.name ||
			pipelineStage.probability !== stage.probability
		) {
			updatePipelineStageMutation.mutate(pipelineStage, {
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.pipelineStageUpdated,
					});
					setIsStageInEditMode(false);
					updateIsHideEditMoreIcon(false);
				},
				onError: (error) => {
					let errorMessage = error.message;
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: errorMessage
							? errorMessage
							: notificationMessage.errorMessage,
					});
				},
			});
		}
	};

	const handleCancelStage = () => {
		setIsStageInEditMode(false);
		updateIsHideEditMoreIcon(false);
		setPipelineStage(stage);
	};

	const closeDeletePipelineStageMenu = () => {
		setDeletePipelineStageMenuElement(null);
		setStageHoveredId(null);
	};

	const handleDeletePipelineStage = () => {
		deletePipelineStageMutation.mutate(stage.id, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.pipelineStageDeleted,
				});
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const handleOpenDeleteMenu = (event) => {
		setDeletePipelineStageMenuElement(event.currentTarget);
		setStageHoveredId(stage.id);
	};

	const handleChangeStageProbability = (event) => {
		const { value } = event.target;
		const maxProbabilityValue = 100;
		const newValue = value === "" ? "" : Number(value);
		if (newValue <= maxProbabilityValue && newValue >= 0) {
			setPipelineStage((stage) => {
				let modifiedStage = { ...stage };
				modifiedStage.probability = newValue;
				return modifiedStage;
			});
		}
	};

	const handleChangeStageName = (event) => {
		const { value } = event.target;
		setPipelineStage((stage) => {
			let modifiedStage = { ...stage };
			modifiedStage.name = value;
			return modifiedStage;
		});
	};

	const isEditableStage = () => {
		return !stage.isEditable && !stage.isDefault;
	};

	const isMoreIconHidden = () => {
		return (
			(stageHoveredId !== stage.id && hoveredId !== stage.id) ||
			isStageInEditMode ||
			isHideEditMoreIcon ||
			(stage.isEditable && stage.isDefault)
		);
	};

	const onEditClicked = () => {
		setIsStageInEditMode(true);
		updateIsHideEditMoreIcon(true);
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="80px"
				anchorEl={deletePipelineStageMenuElement}
				open={isDeletePipelineStageMenuOpened}
				onClose={closeDeletePipelineStageMenu}
			>
				<MenuItem onClick={handleDeletePipelineStage}>
					<MenuItemText color={theme.palette.primary.main}>
						delete
					</MenuItemText>
				</MenuItem>
			</Menu>

			<Box
				py={1.5}
				pl={2.5}
				pr={2}
				style={{
					border: `1px solid ${
						pipelineStage.name === ""
							? theme.palette.primary.main
							: "rgba(0, 0, 0, 0.1)"
					}`,
					borderRadius: "8px",
					cursor: "pointer",
					boxShadow: hoveredId
						? "0px 2px 4px rgba(0, 0, 0, 0.1)"
						: "none",
					backgroundColor: "#fff",
				}}
				onMouseOver={() => setHoveredId(stage.id)}
				onMouseLeave={() => setHoveredId(null)}
			>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
				>
					<Stack direction="row" alignItems="center" spacing={2.5}>
						<Box
							display="flex"
							{...props.dragHandleProps}
							sx={{
								pointerEvents: stage.isDraggable
									? "auto"
									: "none",
							}}
						>
							{DragIcon(
								24,
								24,
								"grey",
								stage.isDraggable ? 1 : 0.5
							)}
						</Box>

						{isStageInEditMode ? (
							<Stack spacing={0.75}>
								<TextField
									variant="standard"
									sx={{
										"& .MuiInput-underline:before": {
											borderBottomColor:
												"rgba(0, 0, 0, 0.1)",
										},
									}}
									inputProps={{
										style: {
											fontSize: "15px",
											padding: "0px 0px 1px 0px",
										},
									}}
									onChange={handleChangeStageName}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<Stack direction="row">
													<Box>
														<IconButton
															onClick={
																handleUpdateStage
															}
														>
															{TickIcon(
																20,
																20,
																theme.palette
																	.primary
																	.main
															)}
														</IconButton>
													</Box>

													<Box>
														<IconButton
															onClick={
																handleCancelStage
															}
														>
															{CloseIcon(
																20,
																20,
																theme.palette
																	.primary
																	.main
															)}
														</IconButton>
													</Box>
												</Stack>
											</InputAdornment>
										),
									}}
									defaultValue={stage.name}
								/>

								<TextField
									variant="standard"
									sx={{
										"& .MuiInput-underline:before": {
											borderBottomColor:
												"rgba(0, 0, 0, 0.1)",
										},
									}}
									onChange={handleChangeStageProbability}
									value={pipelineStage.probability}
									inputProps={{
										style: {
											fontSize: "15px",
											color: "rgba(0, 0, 0, 0.6)",
											padding: "0px 0px 1px 0px",
										},
										min: 0,
										max: 100,
									}}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Typography
													fontSize={15}
													color="rgba(0, 0, 0, 0.6)"
												>
													Probability :
												</Typography>
											</InputAdornment>
										),
									}}
									defaultValue={stage.probability}
								/>
							</Stack>
						) : (
							<Stack spacing={0.75}>
								<Typography
									width="180px"
									fontSize={15}
									noWrap
									style={{
										opacity: isEditableStage() ? 0.6 : 1,
									}}
								>
									{stage.name}
								</Typography>

								<Typography
									fontSize={15}
									style={{
										opacity: isEditableStage() ? 0.6 : 1,
									}}
									color="rgba(0, 0, 0, 0.6)"
								>
									Probability : {stage?.probability + "%"}
								</Typography>
							</Stack>
						)}
					</Stack>

					{isEditableStage() ? null : (
						<Stack direction="row" spacing={1}>
							<Box
								hidden={
									!hoveredId ||
									isStageInEditMode ||
									isHideEditMoreIcon
								}
							>
								<IconButton
									style={{ padding: 0 }}
									onClick={onEditClicked}
								>
									{EditIcon(
										18,
										18,
										theme.palette.primary.main
									)}
								</IconButton>
							</Box>

							<Box hidden={isMoreIconHidden()}>
								<MoreIconButton
									sx={{ padding: "2px" }}
									onClick={handleOpenDeleteMenu}
									isFocused={isDeletePipelineStageMenuOpened}
									iconProps={{ width: 18, height: 18 }}
								/>
							</Box>
						</Stack>
					)}
				</Stack>
			</Box>
		</React.Fragment>
	);
}
