import React from "react";
import { Typography } from "@mui/material";
import { Tooltip } from "../../../../styles/twozo";

const styles = {
	highlightedText: {
		fontSize: "15px",
		fontWeight: 500,
	},
};

export default function CompanyMerged(props) {
	const { eventData } = props;

	if (!eventData) return;

	const maxCompaniesToShow = 1;
	const maxCompaniesForTooltip = 2;

	const hasMoreThanMaxCompaniesToShow = (companies) => {
		return (
			Array.isArray(companies) && companies.length > maxCompaniesToShow
		);
	};

	const getSupplementaryCompaniesNames = (companies) => {
		const supplementaryCompanies = companies?.slice(maxCompaniesToShow);

		const companiesForTooltip = supplementaryCompanies
			.slice(0, maxCompaniesForTooltip)
			.join(", "); // Index 0 (inclusive) to Index 2 (exclusive)

		return supplementaryCompanies?.length > maxCompaniesForTooltip
			? `${companiesForTooltip}, +${
					supplementaryCompanies.length - maxCompaniesForTooltip
				} others`
			: companiesForTooltip;
	};

	return (
		<React.Fragment>
			<Typography fontSize={15} pt={1.3}>
				<span style={styles.highlightedText}>
					{eventData?.oldValues?.[0]}
				</span>{" "}
				{hasMoreThanMaxCompaniesToShow(eventData?.oldValues) ? (
					<Tooltip
						title={getSupplementaryCompaniesNames(
							eventData?.oldValues
						)}
						placement="top"
					>
						<span
							style={{
								...styles.highlightedText,
								cursor: "pointer",
							}}
						>
							+{eventData?.oldValues?.length - maxCompaniesToShow}{" "}
						</span>
					</Tooltip>
				) : null}
				companies was merged with{" "}
				<span style={{ fontSize: "15px", fontWeight: 500 }}>
					{eventData?.newValue}
				</span>{" "}
				to avoid duplicates
			</Typography>
		</React.Fragment>
	);
}
