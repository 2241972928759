import React, { useState } from "react";
import Menu from "../../../Elements/Menu";
import { Checkbox, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import { default as DropDownIcon } from "../../../../assets/icons/dropDownCentered";
import { activityFilterKeys } from "../../../../utils/ActivitiesUtils";
import { useActivityTypeList } from "../../../../hooks/services/activityType";
import { MenuItemText } from "../../../../styles/twozo/twozo";

export default function ActivityTaskType(props) {
	const { handleSelectOption, handleUnselectOption } = props;
	const theme = useTheme();

	const style = {
		filterButton: {
			cursor: "pointer",
			alignItems: "center",
			borderRadius: "8px",
		},
	};

	const [taskTypeMenuElement, setTaskTypeMenuElement] = useState(null);
	const isTaskTypeMenuOpened = Boolean(taskTypeMenuElement);
	const [selectedTaskType, setSelectedTaskType] = useState([]);

	const openTaskTypeMenu = (event) => {
		setTaskTypeMenuElement(event.currentTarget);
	};

	const closeTaskTypeMenu = () => {
		setTaskTypeMenuElement(null);
	};

	const { data: taskTypeList } = useActivityTypeList();

	const toggleTaskTypeMenu = (type) => {
		let updatedTaskType;

		const isTaskRemoved =
			selectedTaskType.findIndex(
				(selectedType) => selectedType.id === type.id
			) !== -1;

		if (isTaskRemoved) {
			updatedTaskType = selectedTaskType.filter(
				(selectedType) => selectedType.id !== type.id
			);
		} else {
			updatedTaskType = [...selectedTaskType, type];
		}

		const criteria = getTaskTypeFilterRequestData(updatedTaskType);
		setSelectedTaskType(updatedTaskType);

		if (updatedTaskType.length > 0) {
			handleSelectOption(activityFilterKeys.taskType, criteria);
		} else {
			handleUnselectOption(activityFilterKeys.taskType);
		}
	};

	const getTaskTypeFilterRequestData = (types) => {
		const taskTypeFilterRequestData = {};
		taskTypeFilterRequestData.field = "typeId";
		taskTypeFilterRequestData.comparator = "equal";
		taskTypeFilterRequestData.values = types.map((type) => ({
			valueEntityId: type.id,
		}));
		return taskTypeFilterRequestData;
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="253px"
				anchorEl={taskTypeMenuElement}
				open={isTaskTypeMenuOpened}
				onClose={closeTaskTypeMenu}
				style={{ marginTop: "8px" }}
				PaperProps={{ style: { maxHeight: "248px" } }}
			>
				{taskTypeList?.map((taskType) => (
					<MenuItem
						key={taskType.id}
						style={{ minHeight: "40px" }}
						onClick={() => toggleTaskTypeMenu(taskType)}
					>
						<Stack direction="row" spacing={1} alignItems="center">
							<Checkbox
								checked={selectedTaskType.some(
									(selectedType) =>
										selectedType.id === taskType.id
								)}
							/>
							<MenuItemText>{taskType.name}</MenuItemText>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			<Stack
				style={{
					...style.filterButton,
					backgroundColor: isTaskTypeMenuOpened
						? theme.palette.secondary.main
						: "transparent",
				}}
				direction="row"
				spacing={1}
				py={0.5}
				px={1}
				onClick={openTaskTypeMenu}
			>
				<Typography
					color={theme.palette.secondary.contrastText}
					fontSize={14}
					fontWeight={500}
				>
					Task Type{" "}
					{selectedTaskType.length > 0
						? `(${selectedTaskType.length})`
						: ""}
				</Typography>

				{DropDownIcon(16, 16, theme.palette.primary.main)}
			</Stack>
		</React.Fragment>
	);
}
