import React, { useState } from "react";
import { ListItem, ListItemButton, Typography } from "@mui/material";

export default function NavigationBar(props) {
	const { settingsMenuList } = props;

	const [seletedSettingsId, setSelectedSettingsId] = useState(
		settingsMenuList[0].id
	);

	const handleScroll = (settingsMenuData) => {
		setSelectedSettingsId(settingsMenuData.id);
		settingsMenuData.ref.current.scrollIntoView({ behavior: "smooth" });
	};

	const isSelectedMenu = (settingsMenuData) => {
		return seletedSettingsId === settingsMenuData.id;
	};

	return (
		<React.Fragment>
			{settingsMenuList.map((settingsMenuData, index) => (
				<ListItem key={index} disablePadding>
					<ListItemButton
						style={{
							minHeight: "42px",
							backgroundColor: isSelectedMenu(settingsMenuData)
								? "#E7F7F0"
								: "transparent",
							borderRadius: "8px",
						}}
						onClick={() => handleScroll(settingsMenuData)}
					>
						<Typography
							fontSize={14}
							fontWeight={
								isSelectedMenu(settingsMenuData) ? 500 : 400
							}
						>
							{settingsMenuData.name}
						</Typography>
					</ListItemButton>
				</ListItem>
			))}
		</React.Fragment>
	);
}
