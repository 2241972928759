const AUTH_ENTITIES = {
	CONTACT: "contact",
	DEAL: "deal",
	COMPANY: "company",
	PRODUCT: "product",
	ACTIVITY: "activity",
	ROLE: "role",
	TEAM: "team",
	ALLOW_LISTED_ENTITY: "allowListedEntity",
	SETTINGS: "settings",
	ACTIVITY_TYPE: "activityType",
	EMAIL: "email",
	GOAL: "goal",
	USER: "user",
	TERRITORY: "territory",
	WEBFORM_TRACKING: "webFormTracking",
	QUOTES_AND_FORECASTING: "quotasAndForecasting",
	CONTACT_FOLLOWERS: "contactFollowers",
	COMPANY_FOLLOWERS: "companyFollowers",
	DEAL_FOLLOWERS: "dealFollowers",
};

const AUTH_ACTION = {
	ACCESS: "access",
	VIEW: "view",
	CREATE: "create",
	EDIT: "edit",
	DELETE: "delete",
	TAGS: "tags",
	SHARE_VIEW: "shareView",
	ACCESS_VIEW: "accessView",
	ASSIGN_RECORDS: "assignRecords",
	MERGE_RECORDS: "mergeRecords",
	RESTORE_RECORDS: "restoreRecords",
	BULK_ASSIGN_RECORDS: "bulkAssignRecords",
	BULK_UPDATE_RECORDS: "bulkUpdateRecords",
	CLONE_RECORDS: "cloneRecords",
	ACCESS_RECYCLE_BIN: "accessRecycleBin",
	EXPORT_RECORDS: "exportRecords",
	IMPORT_RECORDS: "importRecords",
	PHONE_SETTINGS: "phoneSettings",
	WORK_FLOW: "workFlow",
	SALES_FORECAST_CATEGORIES: "salesForecastCategories",
	MIGRATE_FROM_CRM: "migrateFromACrm",
	EXPORT_ALL_DATA: "exportAllData",
	ADMIN_SETTINGS: "adminSettings",
	CONVERSATIONAL_CHANNELS: "conversationalChannels",
	INTEGRATIONS_FOR_CHART: "integrationsForChat",
	VIEW_EMAIL_CONVERSATION: "viewEmailConversation",
	INDIVIDUAL_EMAIL_LIMIT: "individualEmailLimit",
	BULK_EMAIL_LIMIT: "bulkEmailLimit",
	ALLOW_LISTED_PERMISSION: "allowListedPermission",
	MANAGE_FOLLOWERS: "manageFollowers",
};

export const PERMISSIONS = Object.freeze({
	contact: {
		access: {
			entity: AUTH_ENTITIES.CONTACT,
			action: AUTH_ACTION.ACCESS,
		},
		view: {
			entity: AUTH_ENTITIES.CONTACT,
			action: AUTH_ACTION.VIEW,
		},
		create: {
			entity: AUTH_ENTITIES.CONTACT,
			action: AUTH_ACTION.CREATE,
		},
		edit: {
			entity: AUTH_ENTITIES.CONTACT,
			action: AUTH_ACTION.EDIT,
		},
		delete: {
			entity: AUTH_ENTITIES.CONTACT,
			action: AUTH_ACTION.DELETE,
		},
		cloneRecords: {
			entity: AUTH_ENTITIES.CONTACT,
			action: AUTH_ACTION.CLONE_RECORDS,
		},
		accessView: {
			entity: AUTH_ENTITIES.CONTACT,
			action: AUTH_ACTION.ACCESS_VIEW,
		},
		shareView: {
			entity: AUTH_ENTITIES.CONTACT,
			action: AUTH_ACTION.SHARE_VIEW,
		},
		bulkUpdateRecords: {
			entity: AUTH_ENTITIES.CONTACT,
			action: AUTH_ACTION.BULK_UPDATE_RECORDS,
		},
		bulkAssignRecords: {
			entity: AUTH_ENTITIES.CONTACT,
			action: AUTH_ACTION.BULK_ASSIGN_RECORDS,
		},
		assignRecords: {
			entity: AUTH_ENTITIES.CONTACT,
			action: AUTH_ACTION.ASSIGN_RECORDS,
		},
		importRecords: {
			entity: AUTH_ENTITIES.CONTACT,
			action: AUTH_ACTION.IMPORT_RECORDS,
		},
		exportRecords: {
			entity: AUTH_ENTITIES.CONTACT,
			action: AUTH_ACTION.EXPORT_RECORDS,
		},
		mergeRecords: {
			entity: AUTH_ENTITIES.CONTACT,
			action: AUTH_ACTION.MERGE_RECORDS,
		},
		restoreRecords: {
			entity: AUTH_ENTITIES.CONTACT,
			action: AUTH_ACTION.RESTORE_RECORDS,
		},
		accessRecycleBin: {
			entity: AUTH_ENTITIES.CONTACT,
			action: AUTH_ACTION.ACCESS_RECYCLE_BIN,
		},
	},
	deal: {
		access: {
			entity: AUTH_ENTITIES.DEAL,
			action: AUTH_ACTION.ACCESS,
		},
		view: {
			entity: AUTH_ENTITIES.DEAL,
			action: AUTH_ACTION.VIEW,
		},
		create: {
			entity: AUTH_ENTITIES.DEAL,
			action: AUTH_ACTION.CREATE,
		},
		edit: {
			entity: AUTH_ENTITIES.DEAL,
			action: AUTH_ACTION.EDIT,
		},
		delete: {
			entity: AUTH_ENTITIES.DEAL,
			action: AUTH_ACTION.DELETE,
		},
		shareView: {
			entity: AUTH_ENTITIES.DEAL,
			action: AUTH_ACTION.SHARE_VIEW,
		},
		accessView: {
			entity: AUTH_ENTITIES.DEAL,
			action: AUTH_ACTION.ACCESS_VIEW,
		},
		assignRecords: {
			entity: AUTH_ENTITIES.DEAL,
			action: AUTH_ACTION.ASSIGN_RECORDS,
		},
		importRecords: {
			entity: AUTH_ENTITIES.DEAL,
			action: AUTH_ACTION.IMPORT_RECORDS,
		},
		exportRecords: {
			entity: AUTH_ENTITIES.DEAL,
			action: AUTH_ACTION.EXPORT_RECORDS,
		},
		mergeRecords: {
			entity: AUTH_ENTITIES.DEAL,
			action: AUTH_ACTION.MERGE_RECORDS,
		},
		restoreRecords: {
			entity: AUTH_ENTITIES.DEAL,
			action: AUTH_ACTION.RESTORE_RECORDS,
		},
		cloneRecords: {
			entity: AUTH_ENTITIES.DEAL,
			action: AUTH_ACTION.CLONE_RECORDS,
		},
		bulkAssignRecords: {
			entity: AUTH_ENTITIES.DEAL,
			action: AUTH_ACTION.BULK_ASSIGN_RECORDS,
		},
		bulkUpdateRecords: {
			entity: AUTH_ENTITIES.DEAL,
			action: AUTH_ACTION.BULK_UPDATE_RECORDS,
		},
	},
	company: {
		access: {
			entity: AUTH_ENTITIES.COMPANY,
			action: AUTH_ACTION.ACCESS,
		},
		view: {
			entity: AUTH_ENTITIES.COMPANY,
			action: AUTH_ACTION.VIEW,
		},
		create: {
			entity: AUTH_ENTITIES.COMPANY,
			action: AUTH_ACTION.CREATE,
		},
		edit: {
			entity: AUTH_ENTITIES.COMPANY,
			action: AUTH_ACTION.EDIT,
		},
		delete: {
			entity: AUTH_ENTITIES.COMPANY,
			action: AUTH_ACTION.DELETE,
		},
		shareView: {
			entity: AUTH_ENTITIES.COMPANY,
			action: AUTH_ACTION.SHARE_VIEW,
		},
		accessView: {
			entity: AUTH_ENTITIES.COMPANY,
			action: AUTH_ACTION.ACCESS_VIEW,
		},
		assignRecords: {
			entity: AUTH_ENTITIES.COMPANY,
			action: AUTH_ACTION.ASSIGN_RECORDS,
		},
		importRecords: {
			entity: AUTH_ENTITIES.COMPANY,
			action: AUTH_ACTION.IMPORT_RECORDS,
		},
		exportRecords: {
			entity: AUTH_ENTITIES.COMPANY,
			action: AUTH_ACTION.EXPORT_RECORDS,
		},
		mergeRecords: {
			entity: AUTH_ENTITIES.COMPANY,
			action: AUTH_ACTION.MERGE_RECORDS,
		},
		restoreRecords: {
			entity: AUTH_ENTITIES.COMPANY,
			action: AUTH_ACTION.RESTORE_RECORDS,
		},
		cloneRecords: {
			entity: AUTH_ENTITIES.COMPANY,
			action: AUTH_ACTION.CLONE_RECORDS,
		},
		bulkAssignRecords: {
			entity: AUTH_ENTITIES.COMPANY,
			action: AUTH_ACTION.BULK_ASSIGN_RECORDS,
		},
		bulkUpdateRecords: {
			entity: AUTH_ENTITIES.COMPANY,
			action: AUTH_ACTION.BULK_UPDATE_RECORDS,
		},
	},
	product: {
		access: {
			entity: AUTH_ENTITIES.PRODUCT,
			action: AUTH_ACTION.ACCESS,
		},
		view: {
			entity: AUTH_ENTITIES.PRODUCT,
			action: AUTH_ACTION.VIEW,
		},
		create: {
			entity: AUTH_ENTITIES.PRODUCT,
			action: AUTH_ACTION.CREATE,
		},
		edit: {
			entity: AUTH_ENTITIES.PRODUCT,
			action: AUTH_ACTION.EDIT,
		},
		delete: {
			entity: AUTH_ENTITIES.PRODUCT,
			action: AUTH_ACTION.DELETE,
		},
		cloneRecords: {
			entity: AUTH_ENTITIES.PRODUCT,
			action: AUTH_ACTION.CLONE_RECORDS,
		},
		accessView: {
			entity: AUTH_ENTITIES.PRODUCT,
			action: AUTH_ACTION.ACCESS_VIEW,
		},
		shareView: {
			entity: AUTH_ENTITIES.PRODUCT,
			action: AUTH_ACTION.SHARE_VIEW,
		},
		bulkUpdateRecords: {
			entity: AUTH_ENTITIES.PRODUCT,
			action: AUTH_ACTION.BULK_UPDATE_RECORDS,
		},
		bulkAssignRecords: {
			entity: AUTH_ENTITIES.PRODUCT,
			action: AUTH_ACTION.BULK_ASSIGN_RECORDS,
		},
		assignRecords: {
			entity: AUTH_ENTITIES.PRODUCT,
			action: AUTH_ACTION.ASSIGN_RECORDS,
		},
		importRecords: {
			entity: AUTH_ENTITIES.PRODUCT,
			action: AUTH_ACTION.IMPORT_RECORDS,
		},
		exportRecords: {
			entity: AUTH_ENTITIES.PRODUCT,
			action: AUTH_ACTION.EXPORT_RECORDS,
		},
		restoreRecords: {
			entity: AUTH_ENTITIES.PRODUCT,
			action: AUTH_ACTION.RESTORE_RECORDS,
		},
		accessRecycleBin: {
			entity: AUTH_ENTITIES.PRODUCT,
			action: AUTH_ACTION.ACCESS_RECYCLE_BIN,
		},
	},
	quotasAndForecasting: {
		access: {
			entity: AUTH_ENTITIES.QUOTES_AND_FORECASTING,
			action: AUTH_ACTION.ACCESS,
		},
		view: {
			entity: AUTH_ENTITIES.QUOTES_AND_FORECASTING,
			action: AUTH_ACTION.VIEW,
		},
	},
	goal: {
		view: {
			entity: AUTH_ENTITIES.GOAL,
			action: AUTH_ACTION.VIEW,
		},
	},
	user: {
		access: {
			entity: AUTH_ENTITIES.USER,
			action: AUTH_ACTION.ACCESS,
		},
	},
	team: {
		access: {
			entity: AUTH_ENTITIES.TEAM,
			action: AUTH_ACTION.ACCESS,
		},
	},
	territory: {
		access: {
			entity: AUTH_ENTITIES.TERRITORY,
			action: AUTH_ACTION.ACCESS,
			exportRecords: {
				entity: AUTH_ENTITIES.PRODUCT,
				action: AUTH_ACTION.EXPORT_RECORDS,
			},
			restoreRecords: {
				entity: AUTH_ENTITIES.PRODUCT,
				action: AUTH_ACTION.RESTORE_RECORDS,
			},
			accessRecycleBin: {
				entity: AUTH_ENTITIES.PRODUCT,
				action: AUTH_ACTION.ACCESS_RECYCLE_BIN,
			},
		},
	},
	activity: {
		exportRecords: {
			entity: AUTH_ENTITIES.ACTIVITY,
			action: AUTH_ACTION.EXPORT_RECORDS,
		},
		importRecords: {
			entity: AUTH_ENTITIES.ACTIVITY,
			action: AUTH_ACTION.IMPORT_RECORDS,
		},
	},
	role: {
		access: {
			entity: AUTH_ENTITIES.ROLE,
			action: AUTH_ACTION.ACCESS,
		},
		view: {
			entity: AUTH_ENTITIES.ROLE,
			action: AUTH_ACTION.VIEW,
		},
		create: {
			entity: AUTH_ENTITIES.ROLE,
			action: AUTH_ACTION.CREATE,
		},
		edit: {
			entity: AUTH_ENTITIES.ROLE,
			action: AUTH_ACTION.EDIT,
		},
		delete: {
			entity: AUTH_ENTITIES.ROLE,
			action: AUTH_ACTION.DELETE,
		},
	},
	webFormTracking: {
		access: {
			entity: AUTH_ENTITIES.WEBFORM_TRACKING,
			action: AUTH_ACTION.ACCESS,
		},
		view: {
			entity: AUTH_ENTITIES.WEBFORM_TRACKING,
			action: AUTH_ACTION.VIEW,
		},
		create: {
			entity: AUTH_ENTITIES.WEBFORM_TRACKING,
			action: AUTH_ACTION.CREATE,
		},
		edit: {
			entity: AUTH_ENTITIES.WEBFORM_TRACKING,
			action: AUTH_ACTION.EDIT,
		},
		delete: {
			entity: AUTH_ENTITIES.WEBFORM_TRACKING,
			action: AUTH_ACTION.DELETE,
		},
	},
	settings: {
		phoneSettings: {
			entity: AUTH_ENTITIES.SETTINGS,
			action: AUTH_ACTION.PHONE_SETTINGS,
		},
		workFlow: {
			entity: AUTH_ENTITIES.SETTINGS,
			action: AUTH_ACTION.WORK_FLOW,
		},
		salesForecastCategories: {
			entity: AUTH_ENTITIES.SETTINGS,
			action: AUTH_ACTION.SALES_FORECAST_CATEGORIES,
		},
		migrateFromACrm: {
			entity: AUTH_ENTITIES.SETTINGS,
			action: AUTH_ACTION.MIGRATE_FROM_CRM,
		},
		exportAllData: {
			entity: AUTH_ENTITIES.SETTINGS,
			action: AUTH_ACTION.EXPORT_ALL_DATA,
		},
		adminSettings: {
			entity: AUTH_ENTITIES.SETTINGS,
			action: AUTH_ACTION.ADMIN_SETTINGS,
		},
		conversationalChannels: {
			entity: AUTH_ENTITIES.SETTINGS,
			action: AUTH_ACTION.CONVERSATIONAL_CHANNELS,
		},
		integrationsForChat: {
			entity: AUTH_ENTITIES.SETTINGS,
			action: AUTH_ACTION.INTEGRATIONS_FOR_CHART,
		},
		tags: {
			entity: AUTH_ENTITIES.SETTINGS,
			action: AUTH_ACTION.TAGS,
		},
	},
	email: {
		viewEmailConversation: {
			entity: AUTH_ENTITIES.EMAIL,
			action: AUTH_ACTION.VIEW_EMAIL_CONVERSATION,
		},
		individualEmailLimit: {
			entity: AUTH_ENTITIES.EMAIL,
			action: AUTH_ACTION.INDIVIDUAL_EMAIL_LIMIT,
		},
		bulkEmailLimit: {
			entity: AUTH_ENTITIES.EMAIL,
			action: AUTH_ACTION.BULK_EMAIL_LIMIT,
		},
	},
	allowlistedEntity: {
		allowlistedPermission: {
			entity: AUTH_ENTITIES.ALLOW_LISTED_ENTITY,
			action: AUTH_ACTION.ALLOW_LISTED_PERMISSION,
		},
	},
	activityType: {
		access: {
			entity: AUTH_ENTITIES.ACTIVITY_TYPE,
			action: AUTH_ACTION.ACCESS,
		},
		view: {
			entity: AUTH_ENTITIES.ACTIVITY_TYPE,
			action: AUTH_ACTION.VIEW,
		},
		create: {
			entity: AUTH_ENTITIES.ACTIVITY_TYPE,
			action: AUTH_ACTION.CREATE,
		},
		edit: {
			entity: AUTH_ENTITIES.ACTIVITY_TYPE,
			action: AUTH_ACTION.EDIT,
		},
		delete: {
			entity: AUTH_ENTITIES.ACTIVITY_TYPE,
			action: AUTH_ACTION.DELETE,
		},
	},
	contactFollowers: {
		manageFollowers: {
			entity: AUTH_ENTITIES.CONTACT_FOLLOWERS,
			action: AUTH_ACTION.MANAGE_FOLLOWERS,
		},
	},
	companyFollowers: {
		manageFollowers: {
			entity: AUTH_ENTITIES.COMPANY_FOLLOWERS,
			action: AUTH_ACTION.MANAGE_FOLLOWERS,
		},
	},
	dealFollowers: {
		manageFollowers: {
			entity: AUTH_ENTITIES.DEAL_FOLLOWERS,
			action: AUTH_ACTION.MANAGE_FOLLOWERS,
		},
	},
});

export const redirectRefQueryParamName = "redirectRef";
