import React, { useMemo, useState } from "react";
import Menu from "../../../Elements/Menu";
import {
	Box,
	CircularProgress,
	MenuItem,
	Skeleton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import MenuSearchBar from "../../../Elements/MenuSearchBar";
import { default as AddIcon } from "../../../../assets/icons/add";
import { useProductList } from "../../../../hooks/services/product";
import { useLastRowRef } from "../../../../hooks/common/InfiniteScroll";
import { MenuItemText } from "../../../../styles/twozo";

export default function ProductMenu(props) {
	const {
		value,
		onProductInputChange,
		selectedProductIds,
		isNewProductsAllowed = true,
		onSelectNewProduct,
	} = props;
	const theme = useTheme();

	const [productMenuElement, setProductMenuElement] = useState(null);
	const isProductMenuOpened = Boolean(productMenuElement);
	const [searchValue, setSearchValue] = useState("");

	const {
		data: productList,
		isLoading: isLoadingProducts,
		isFetching: isFetchingProducts,
		hasNextPage: hasProductNextPage,
		fetchNextPage: fetchProductNextPage,
	} = useProductList(searchValue);

	const productLastOptionRef = useLastRowRef(
		fetchProductNextPage,
		hasProductNextPage,
		isFetchingProducts
	);

	// Filter products that are not yet selected
	const filteredProducts = useMemo(() => {
		if (selectedProductIds?.length > 0) {
			return productList?.filter(
				(product) =>
					product?.value === value?.value ||
					!selectedProductIds?.includes(product?.value)
			);
		}

		return productList;
	}, [productList, value, selectedProductIds]);

	const openProductMenu = (event) => {
		setProductMenuElement(event.currentTarget);
		setSearchValue("");
	};

	const closeProductMenu = () => {
		setProductMenuElement(null);
	};

	const handleProductChange = (product) => {
		onProductInputChange(product);
		setProductMenuElement(null);
	};

	const handleSearchProduct = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const handleSelectNewProduct = () => {
		onSelectNewProduct(searchValue);
		closeProductMenu();
	};

	const renderEmptyView = () => {
		if (!isNewProductsAllowed) {
			return (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="40px"
				>
					<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
						No Products Found
					</Typography>
				</Stack>
			);
		}
		return "";
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="300px"
				sx={{
					"& .MuiMenu-list": { paddingTop: 0 },
					marginTop: "15px",
				}}
				open={isProductMenuOpened}
				anchorEl={productMenuElement}
				onClose={closeProductMenu}
			>
				<Box p={0.5}>
					<MenuSearchBar
						value={searchValue}
						onChange={handleSearchProduct}
					/>
				</Box>

				<Box style={{ maxHeight: "200px", overflow: "auto" }}>
					<Box>
						{searchValue && isNewProductsAllowed ? (
							<MenuItem
								style={{ minHeight: "40px" }}
								onClick={handleSelectNewProduct}
							>
								<Stack
									direction="row"
									alignItems="center"
									justifyContent="space-between"
									width="100%"
								>
									<MenuItemText
										color={
											theme.palette.secondary.contrastText
										}
									>
										{searchValue}
									</MenuItemText>

									{AddIcon(
										16,
										16,
										theme.palette.secondary.contrastText
									)}
								</Stack>
							</MenuItem>
						) : null}

						{isLoadingProducts ? (
							<Stack
								alignItems="center"
								justifyContent="center"
								height="100px"
							>
								<CircularProgress size="25px" />
							</Stack>
						) : filteredProducts?.length > 0 ? (
							filteredProducts.map((product, index) => (
								<MenuItem
									key={index}
									sx={{
										backgroundColor:
											selectedProductIds.includes(
												product.value
											)
												? "rgba(51, 188, 126, 0.12)"
												: null,
									}}
									style={{ minHeight: "40px" }}
									value={product.value}
									onClick={() => handleProductChange(product)}
								>
									<MenuItemText>{product.name}</MenuItemText>
								</MenuItem>
							))
						) : (
							renderEmptyView()
						)}
					</Box>

					<Box ref={productLastOptionRef}>
						{!isLoadingProducts &&
							isFetchingProducts &&
							productList?.length > 0 && (
								<MenuItem style={{ height: "40px" }}>
									<Skeleton width="190px" height="12px" />
								</MenuItem>
							)}
					</Box>
				</Box>
			</Menu>

			<Stack
				sx={{
					justifyContent: "center",
					paddingTop: "12px",
					paddingBottom: "12px",
				}}
				onClick={openProductMenu}
			>
				{value ? (
					<Typography
						sx={{
							fontSize: "14px",
							fontWeight: 500,
							overflow: "hidden",
							textOverflow: "ellipsis",
							display: "-webkit-box",
							WebkitLineClamp: 2,
							WebkitBoxOrient: "vertical",
							color: `${theme.palette.secondary.contrastText}`,
						}}
					>
						{value?.name ?? ""}
					</Typography>
				) : (
					<Typography
						sx={{
							fontSize: "14px",
							fontWeight: 500,
							opacity: 0.6,
							color: `${theme.palette.secondary.contrastText}`,
						}}
					>
						{isNewProductsAllowed ? "Search" : "Select"}
					</Typography>
				)}
			</Stack>
		</React.Fragment>
	);
}
