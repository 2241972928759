import FieldRow from "./FieldRow";

export default function FieldList({
	fieldToUpdate,
	moduleName,
	fieldState,
	unregister,
	selectedFieldIds,
	handleSelectChange,
	handleRemoveField,
}) {
	return (
		<>
			{fieldToUpdate?.map((field, index) => (
				<FieldRow
					key={`${field.id}-field-row`}
					field={field}
					moduleName={moduleName}
					fieldState={fieldState}
					unregister={unregister}
					selectedFieldIds={selectedFieldIds}
					handleSelectChange={(value, fields) =>
						handleSelectChange(value, fields, index)
					}
					handleRemoveField={() => handleRemoveField(field, index)}
					isRemoveAllowed={fieldToUpdate.length !== 1}
				/>
			))}
		</>
	);
}
