import React from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function DealAssociatedWithCompany(props) {
	const { eventData } = props;
	const theme = useTheme();
	const navigate = useNavigate();

	if (!eventData) return;

	const navigateToDeal = (dealId) => {
		navigate(`/deals/${dealId.toString()}`);
	};

	return (
		<React.Fragment>
			<Stack pb={1}>
				<Typography fontSize={15}>
					Deal{" "}
					<span
						style={{
							fontSize: "15px",
							fontWeight: 500,
							color: theme.palette.secondary.contrastText,
							cursor: "pointer",
						}}
						onClick={() => navigateToDeal(eventData?.dealId)}
					>
						{eventData?.newValue}
					</span>{" "}
					associated with this company{" "}
				</Typography>

				<Typography
					style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.6)" }}
				>
					{eventData?.salesOwner?.name}
				</Typography>
			</Stack>
		</React.Fragment>
	);
}
