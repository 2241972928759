import { useMemo } from "react";
import { replaceEmptyFieldWithNullValues } from "../../../../utils/common";
import { modules } from "../../../../utils/common/ModulesName";
import SummaryProfileEditForm from "../../../Elements/SummaryProfileEditForm";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { usePartialUpdateCompany } from "../../../../hooks/services/company";

export default function CompanySummaryProfileEdit(params) {
	const { profile, onClose, companyId } = params;

	// mutation call :-
	const updateMutation = usePartialUpdateCompany(companyId);

	const modifiedProfileData = useMemo(() => {
		const { name, website } = profile;
		return {
			[name?.fieldId]: name?.value,
			[website?.fieldId]: website?.value,
			id: companyId,
		};
	}, [profile, companyId]);

	const handleUpdateSummaryProfile = (profileData) => {
		replaceEmptyFieldWithNullValues(profileData);
		updateMutation.mutate(profileData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.companyUpdated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<>
			<SummaryProfileEditForm
				onClose={onClose}
				moduleName={modules.COMPANY}
				values={modifiedProfileData}
				onSave={handleUpdateSummaryProfile}
				isSaving={updateMutation.isLoading}
			/>
		</>
	);
}
