import React from "react";

function Call(width = "24", height = "24", color = "#000", opacity = 1) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			style={{ fill: "none" }}
			viewBox="0 0 20 20"
			opacity={opacity}
		>
			<path
				d="M17.3122 15.565C17.1845 15.835 17.0193 16.09 16.8015 16.33C16.4334 16.735 16.0278 17.0275 15.5696 17.215C15.1189 17.4025 14.6307 17.5 14.1049 17.5C13.3388 17.5 12.52 17.32 11.6562 16.9525C10.7924 16.585 9.92864 16.09 9.07236 15.4675C8.20856 14.8375 7.38983 14.14 6.60866 13.3675C5.835 12.5875 5.13645 11.77 4.51302 10.915C3.8971 10.06 3.40135 9.205 3.04081 8.3575C2.68027 7.5025 2.5 6.685 2.5 5.905C2.5 5.395 2.59014 4.9075 2.77041 4.4575C2.95068 4 3.2361 3.58 3.6342 3.205C4.11492 2.7325 4.64071 2.5 5.19654 2.5C5.40686 2.5 5.61718 2.545 5.80496 2.635C6.00025 2.725 6.17301 2.86 6.30821 3.055L8.05083 5.5075C8.18603 5.695 8.28368 5.8675 8.35128 6.0325C8.41888 6.19 8.45644 6.3475 8.45644 6.49C8.45644 6.67 8.40386 6.85 8.2987 7.0225C8.20105 7.195 8.05834 7.375 7.87807 7.555L7.30721 8.1475C7.22459 8.23 7.18703 8.3275 7.18703 8.4475C7.18703 8.5075 7.19454 8.56 7.20956 8.62C7.2321 8.68 7.25463 8.725 7.26965 8.77C7.40486 9.0175 7.63771 9.34 7.9682 9.73C8.30621 10.12 8.66675 10.5175 9.05734 10.915C9.46294 11.3125 9.85353 11.68 10.2516 12.0175C10.6422 12.3475 10.9652 12.5725 11.2206 12.7075C11.2581 12.7225 11.3032 12.745 11.3558 12.7675C11.4159 12.79 11.476 12.7975 11.5436 12.7975C11.6713 12.7975 11.7689 12.7525 11.8515 12.67L12.4224 12.1075C12.6102 11.92 12.7904 11.7775 12.9632 11.6875C13.136 11.5825 13.3087 11.53 13.4965 11.53C13.6392 11.53 13.7894 11.56 13.9547 11.6275C14.1199 11.695 14.2927 11.7925 14.4805 11.92L16.9667 13.6825C17.162 13.8175 17.2972 13.975 17.3798 14.1625C17.4549 14.35 17.5 14.5375 17.5 14.7475C17.5 15.0175 17.4399 15.295 17.3122 15.565Z"
				stroke={color}
				strokeWidth="1.5"
			/>
		</svg>
	);
}

export default Call;
