import React from "react";
import { fieldType } from "../../../../../../../../utils/FilterUtills";
import TextInputField from "./TextInputField";
import NumberInputField from "./NumberInputField";
import Dropdown from "./Dropdown";
import SingleSelectField from "./SingleSelectField";
import MultiSelectField from "./MultiSelectField";
import TimeDropdown from "./TimeDropdown";

export default function ValueField(props) {
	const { filterCondition, onChange } = props;

	const getField = (filterCondition) => {
		switch (filterCondition.fieldData.fieldType.name) {
			case fieldType.multiSelect:
			case fieldType.tags:
			case fieldType.contactsOnboard:
			case fieldType.owners:
				return (
					<MultiSelectField
						filterCondition={filterCondition}
						onChange={onChange}
					/>
				);
			//Single Select
			case fieldType.contact:
			case fieldType.contactOnboard:
			case fieldType.company:
			case fieldType.companyOnboard:
			case fieldType.deal:
				return (
					<SingleSelectField
						filterCondition={filterCondition}
						onChange={onChange}
					/>
				);
			case fieldType.time:
			case fieldType.timeRange:
				return (
					<TimeDropdown
						filterCondition={filterCondition}
						onChange={onChange}
					/>
				);
			case fieldType.premiumProduct:
			case fieldType.owner:
			case fieldType.date:
			case fieldType.dropdown:
			case fieldType.territory:
			case fieldType.dateRange:
			case fieldType.dateTimeRange:
			case fieldType.dateTime:
				return (
					<Dropdown
						filterCondition={filterCondition}
						onChange={onChange}
					/>
				);
			case fieldType.singleNumber:
			case fieldType.monetary:
				return (
					<NumberInputField
						filterCondition={filterCondition}
						onChange={onChange}
					/>
				);
			default:
				return (
					<TextInputField
						filterCondition={filterCondition}
						onChange={onChange}
					/>
				);
		}
	};

	return <React.Fragment>{getField(filterCondition)}</React.Fragment>;
}
