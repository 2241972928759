import { StaticDatePicker } from "@mui/x-date-pickers";
import React from "react";
import {
	convertDayjsToUnixTimeStamp,
	convertUnixTimeStampToDayjs,
} from "../../../utils/DateUtils";

export default function StaticDatePickerComponent(props) {
	const { value, onChange, ...others } = props;

	return (
		<React.Fragment>
			<StaticDatePicker
				{...others}
				value={convertUnixTimeStampToDayjs(value)}
				onChange={(date) => onChange(convertDayjsToUnixTimeStamp(date))}
				slotProps={{
					layout: {
						sx: {
							"& .MuiPickersLayout-toolbar": {
								display: "none",
							},
							"& .MuiPickersCalendarHeader-root": {
								margin: "12px 0px 0px 0px",
								padding: "0px 6px 0px 18px",
							},
							"& .MuiDayCalendar-header": {
								borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
								justifyContent: "space-evenly",
							},
							"& .MuiDayCalendar-slideTransition": {
								minHeight: "200px",
							},
							"& .MuiPickersLayout-actionBar": {
								display: "none",
							},
							"& .MuiDayCalendar-weekContainer": {
								justifyContent: "space-evenly",
							},
						},
					},
				}}
			/>
		</React.Fragment>
	);
}
