export const validateNumberField = (field, config, isActionField) => {
	const value = field?.values?.[0]?.value?.trim()
		? Number(field?.values?.[0]?.value?.trim())
		: "";
	const min = config?.min || 0;
	const max = config?.max;

	const getFieldValueLength = (value) => {
		// Define a safe maximum value (the largest number JavaScript can safely handle).
		// JavaScript has a safe integer limit of 9007199254740991, but we are using a custom large value
		// (999999999999999) to represent a reasonably large number that can be safely processed.
		const maxSafeInteger = 999999999999999;
		if (value > maxSafeInteger) {
			return value?.toString()?.length - 1;
		}
		return value?.toString()?.length;
	};

	if (!value?.toString()) {
		return { error: true, message: isActionField ? "" : "Can’t be empty" };
	}
	if (!value.toString() || value < min) {
		return {
			error: true,
			message: `Min. of ${
				min.toString().length || 1
			} numbers are required`,
		};
	}
	if (max && value && BigInt(value) > max) {
		return {
			error: true,
			message: `Max. of ${getFieldValueLength(max)} numbers are allowed`,
		};
	}
	return { error: false, message: "" };
};
