import {
	useInfiniteQuery,
	useMutation,
	useQueryClient,
} from "@tanstack/react-query";
import {
	dismissDulpicateContact,
	getContactDuplicateList,
	getReviewDuplicateData,
	mergeContactData,
} from "../../../../api/contacts/contactDuplicates/contactDuplicatesApi";
import {
	contactDuplicateListKey,
	getContactReviewDuplicateKey,
} from "../../../../utils/queryKeys";
import {
	manageDuplicatesListPageSize,
	reviewDuplicatesPageSize,
} from "../../../../utils/queryConstants/contact";

//Duplicate Contact List
const useDuplicateContactList = (enabled) => {
	return useInfiniteQuery({
		queryKey: contactDuplicateListKey,
		queryFn: ({ pageParam }) => {
			return getContactDuplicateList({
				start: pageParam
					? (pageParam - 1) * manageDuplicatesListPageSize + 1
					: 1,
				limit: manageDuplicatesListPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		enabled: !!enabled,
	});
};

//Merge Duplicate Contacts
const useMergeDuplicateContact = () => {
	let invalidateContactDuplicateList = useInvalidateContactDuplicateList();
	return useMutation(mergeContactData, {
		onSuccess: () => {
			invalidateContactDuplicateList();
		},
	});
};

//Invalidate Duplicate contact list
const useInvalidateContactDuplicateList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(contactDuplicateListKey);
	};
};

//Review Duplicates - Contact
const useReviewContactDuplicateList = (primaryContactId) => {
	return useInfiniteQuery({
		queryKey: getContactReviewDuplicateKey(primaryContactId),
		queryFn: ({ pageParam }) => {
			return getReviewDuplicateData(primaryContactId, {
				start: pageParam
					? (pageParam - 1) * reviewDuplicatesPageSize + 1
					: 1,
				limit: reviewDuplicatesPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		refetchOnWindowFocus: false,
		enabled: !!primaryContactId,
	});
};

//Dismiss contact dupliacte list
const useDismissDuplicateContact = () => {
	let invalidateContactDuplicateList = useInvalidateContactDuplicateList();
	return useMutation(dismissDulpicateContact, {
		onSuccess: () => {
			invalidateContactDuplicateList();
		},
	});
};

export {
	useDismissDuplicateContact,
	useDuplicateContactList,
	useReviewContactDuplicateList,
	useMergeDuplicateContact,
};
