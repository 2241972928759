import React from "react";
import { TableCellText } from "../../../../styles/twozo";
import { getDateAndTimeFormat } from "../../../../utils/DateUtils";

export default function DateTime(props) {
	const { data } = props;

	if (!data) {
		return null;
	}

	return (
		<React.Fragment>
			<TableCellText>{getDateAndTimeFormat(data)}</TableCellText>
		</React.Fragment>
	);
}
