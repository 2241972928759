function Hold(width = "24", height = "24", color = "#fff") {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.9745 6.25529C11.5605 6.90482 11.3747 7.64067 10.8566 8.33888C10.7337 8.50441 10.5921 8.66752 10.4211 8.84725C10.3391 8.93351 10.2751 8.99839 10.1412 9.1324C9.83714 9.43674 9.58155 9.69247 9.37441 9.89959C9.27396 10 9.95978 11.3705 11.2908 12.7028C12.621 14.0344 13.9914 14.7207 14.0924 14.6197L14.8586 13.853C15.2806 13.4307 15.504 13.2269 15.8111 13.0254C16.4495 12.6066 17.1458 12.4928 17.733 13.0179C19.6504 14.3894 20.7354 15.2311 21.267 15.7834C22.3038 16.8607 22.1679 18.5189 21.2729 19.465C20.9625 19.793 20.569 20.1867 20.1042 20.6348C17.2926 23.4479 11.359 21.736 6.81145 17.184C2.26291 12.631 0.551877 6.69674 3.35753 3.88959C3.86122 3.37797 4.0273 3.21199 4.51785 2.72862C5.43118 1.82869 7.16595 1.68778 8.22051 2.72951C8.77521 3.27747 9.65955 4.41531 10.9745 6.25529ZM16.2722 15.2671L15.5058 16.0339C14.2031 17.3373 11.9845 16.2263 9.87703 14.1167C7.76808 12.0057 6.65827 9.78797 7.96142 8.48493C8.16829 8.27808 8.42363 8.0226 8.72744 7.71849C8.85002 7.59579 8.90609 7.53895 8.97339 7.46822C9.06509 7.37185 9.14044 7.28721 9.20077 7.21231C8.0354 5.58897 7.2432 4.57461 6.81614 4.15274C6.59558 3.93487 6.10172 3.97498 5.92042 4.15363C5.43686 4.63009 5.27792 4.78895 4.77626 5.29846C2.97194 7.10377 4.35321 11.8943 8.22519 15.7701C12.096 19.6447 16.8858 21.0266 18.7038 19.2078C19.1614 18.7664 19.5342 18.3934 19.8212 18.09C20.0286 17.8708 20.0657 17.4185 19.8271 17.1707C19.4298 16.7578 18.457 15.9994 16.7774 14.7932C16.6549 14.8917 16.5044 15.0346 16.2722 15.2671ZM15 2.0009H17V10.0009H15V2.0009ZM19 2.0009H21V10.0009H19V2.0009Z"
				fill={color}
			/>
		</svg>
	);
}

export default Hold;
