import React from "react";
import { TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";

export default function TextComponent(props) {
	const { name, control, rules = {}, clearErrors, ...rest } = props;

	if (!control) {
		return <Typography fontSize={14}>control is required</Typography>;
	}

	return (
		<React.Fragment>
			<Controller
				name={name}
				control={control}
				rules={rules}
				render={({ field, fieldState: { error } }) => (
					<TextField
						{...rest}
						{...field}
						error={!!error}
						helperText={!!error && error.message}
						onChange={(event) => {
							if (rest.type === "number") {
								field.onChange(parseInt(event.target.value));
							} else {
								field.onChange(event);
							}
							clearErrors(name);
						}}
						fullWidth
					/>
				)}
			/>
		</React.Fragment>
	);
}
