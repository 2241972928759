// email validation regex
export const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

// domain validation regex
export const domainPattern = /^(?!-)(@[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

//URL validation regex
export const urlPattern = /^(https?:\/\/)?[\w.-]+\.\w+(\.[A-Za-z]{2,4})?$/;

export const urlRegex =
	/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

//phoneNumber validation regex
export const phoneNumberPattern =
	/^(?=(?:\D*\d){5,17}$)\+?(?:\d{1,4}[ -]?)?(?:\(?\d{1,4}\)?[ -]?)?\d{1,4}[ -]?\d{1,4}(?:[ -]?\d{1,4})?$/;

//Regex pattern to remove all characters from the phone number except digits, +, -, parentheses, and spaces
export const phoneNumberInvalidCharRegex = /[^0-9+\-()\s]/g;
