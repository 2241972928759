import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import {
	Box,
	Checkbox,
	Divider,
	IconButton,
	MenuItem,
	Radio,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { default as RoundCheckBoxOffIcon } from "../../../assets/icons/roundCheckBoxOff";
import { default as RoundCheckBoxOnIcon } from "../../../assets/icons/roundCheckBoxOn";
import { default as RightArrowIcon } from "../../../assets/icons/dropDownRight";
import { default as DropDownIcon } from "../../../assets/icons/dropDownCentered";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styleOverrides.css";
import {
	DwarfButton,
	twozoStyles,
	hexToRgba,
	MenuItemText,
} from "../../../styles/twozo";
import Menu from "../../Elements/Menu";
import { DateCalendar } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { activityView } from "../../../utils/ActivitiesUtils";
import ActivityFilters from "../ActivityFilters";
import ActivityMenu from "../Menu";
import { useFilterDataContext } from "../../Elements/Table/Filter/Context/FilterDataContext";
import {
	useActivitiesCalendarViewData,
	useUpdateActivityStatusFromCalendar,
} from "../../../hooks/services/activities";
import { getIconByName } from "../../../utils";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import moment from "moment-timezone";
import AddActivity from "../../Summary/Activities/AddActivity";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
const localizer = momentLocalizer(moment);

const activityStatus = {
	completed: "COMPLETED",
	pending: "PENDING",
	overdue: "OVERDUE",
};

export default function CalendarView(props) {
	const { openActivitySummaryDialog, handleViewToggleButtonGroup } = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const calendarRef = useRef();
	const [calendarStartingPosition, setCalendarStartingPosition] = useState(0);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [weekendMenuElement, setWeekendMenuElement] = useState(null);
	const isWeekendMenuOpened = Boolean(weekendMenuElement);
	const [datePickerMenuElement, setDatePickerMenuElement] = useState(null);
	const isDatePickerMenuElementOpened = Boolean(datePickerMenuElement);
	const [calendarView, setCalendarView] = useState(Views.WEEK);
	const [filterDependsOnWeek, setFilterDependsOnWeek] = useState({
		isWeekEnd: true,
		weekCount: 0,
	});
	const [isAddActivityDialogOpened, setIsAddActivityDialogOpened] =
		useState(false);
	const [activityFormData, setActivityFormData] = useState({});

	const { filterListByCondition, filterState } = useFilterDataContext();

	const { data: activitiesData } = useActivitiesCalendarViewData(
		filterDependsOnWeek.weekCount,
		filterDependsOnWeek.isWeekEnd,
		filterState.additionalCriteria
	);
	const updateActivityStatusMutation = useUpdateActivityStatusFromCalendar(
		filterDependsOnWeek.weekCount
	);

	const currentDayOfWeek = dayjs.tz().day();
	useEffect(() => {
		setTimeout(() => {
			const timeIndicator = document.querySelector(
				".rbc-current-time-indicator"
			);
			if (timeIndicator) {
				let left;
				let width;

				if (calendarView === Views.WEEK) {
					left = currentDayOfWeek * 100;
					width = 700;
				} else {
					left = (currentDayOfWeek - 1) * 100;
					width = 500;
				}
				timeIndicator.style.setProperty("width", `${width}%`);
				timeIndicator.style.setProperty("left", `-${left}%`);
			}
		}, 1000);
	}, [calendarView, currentDayOfWeek, selectedDate]);

	const CustomDateCellWrapper = ({ children, ...props }) => {
		const isSideBar = props.resource === undefined;

		if (isSideBar) {
			return React.cloneElement(React.Children.only(children), {
				style: {
					minHeight: "40px",
					width: "100px",
					backgroundColor: "#F4F5F5",
					paddingRight: "16px",
					fontSize: "14px",
				},
			});
		}

		return React.cloneElement(React.Children.only(children), {
			style: {
				minHeight: "40px",
			},
		});
	};

	const renderTotalAndCompletedActivitiesCount = useCallback(
		(date) => {
			let totalActivities = activitiesData?.filter(
				(activity) =>
					dayjs.tz(activity?.startTime).get("dates") ===
						dayjs.tz(date).get("dates") ||
					dayjs.tz(activity?.endTime).get("dates") ===
						dayjs.tz(date).get("dates")
			);

			let completedActivities = totalActivities?.filter(
				(activity) => activity?.rowState === activityStatus.completed
			);

			if (totalActivities?.length > 0) {
				return (
					<Typography>
						<span style={{ fontSize: "17px" }}>
							{completedActivities?.length}
						</span>
						/
						<span style={{ fontSize: "14px" }}>
							{totalActivities?.length}
						</span>
					</Typography>
				);
			}
			return "";
		},
		[activitiesData]
	);

	const CustomDateHeader = useCallback(
		({ date }) => {
			const dayOfMonth = date.getDate();
			const day = dayjs.tz(date).format("dddd");
			const isWeekend = date.getDay() === 0 || date.getDay() === 6;
			const isPresentDay = dayjs.tz(date).isSame(new Date(), "day");

			return (
				<Box
					style={{
						minHeight: "110px",
						textAlign: "left",
						padding: "8px 16px",
					}}
				>
					<Typography
						fontSize={17}
						fontWeight={500}
						color={
							isPresentDay
								? theme.palette.secondary.contrastText
								: isWeekend
									? "rgba(0, 0, 0, 0.3)"
									: "#000"
						}
					>
						{dayOfMonth}
					</Typography>

					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
					>
						<Typography
							fontSize={14}
							fontWeight={500}
							color={
								isPresentDay
									? theme.palette.secondary.contrastText
									: isWeekend
										? "rgba(0, 0, 0, 0.3)"
										: "#000"
							}
						>
							{day}
						</Typography>
						<Box display="flex">
							{renderTotalAndCompletedActivitiesCount(date)}
						</Box>
					</Stack>
				</Box>
			);
		},
		[theme, renderTotalAndCompletedActivitiesCount]
	);

	const handleNewActivity = useCallback((activityData) => {
		setActivityFormData({
			companyData: activityData?.company
				? {
						name: activityData.company?.name,
						value: activityData.company?.id,
					}
				: null,

			dealData: activityData?.deal
				? {
						name: activityData.deal?.title,
						value: activityData.deal?.id,
					}
				: null,

			contacts: activityData?.contact
				? [
						{
							name: activityData.contact?.name,
							value: activityData.contact?.id,
						},
					]
				: [],
		});
		openAddActivityDialog();
	}, []);

	const updateActivityStatus = useCallback(
		(event, activityData) => {
			event.stopPropagation();
			updateActivityStatusMutation.mutate(
				{
					id: activityData?.id,
					isDone: !activityData?.isDone,
				},
				{
					onSuccess: (activityData) => {
						if (activityData?.isActivityCreationRequired) {
							handleNewActivity(activityData);
						}
						enqueueSnackbar({
							variant: notificationVariants.success,
							message: notificationMessage.activityUpdated,
						});
					},
					onError: () => {
						enqueueSnackbar({
							variant: notificationVariants.error,
							message: notificationMessage.errorMessage,
						});
					},
				}
			);
		},
		[handleNewActivity, updateActivityStatusMutation]
	);

	const CustomEvent = useCallback(
		(props) => {
			const { title, event, isAllDay } = props;
			const { rowState } = event;
			let cardBackgroundColor = "transparent";
			let textColor = "#000";

			switch (rowState) {
				case activityStatus.overdue:
					cardBackgroundColor = hexToRgba(
						theme.palette.error.main,
						0.12
					);
					textColor = theme.palette.error.main;
					break;
				case activityStatus.pending:
					cardBackgroundColor = theme.palette.secondary.main;
					textColor = "#000";
					break;
				default:
					cardBackgroundColor = "transparent";
					textColor = "rgba(0, 0, 0, 0.6)";
					break;
			}

			return (
				<React.Fragment>
					<Box
						style={{
							height: "calc(100% - 4px)",
							display: "flex",
							alignItems: "center",
							backgroundColor: cardBackgroundColor,
							color: textColor,
							border:
								rowState !== activityStatus.overdue &&
								rowState !== activityStatus.pending
									? `1px solid ${theme.palette.secondary.main}`
									: "none",
							borderRadius: "8px",
							margin: "1px",
						}}
					>
						<Stack
							direction="row"
							alignItems="flex-start"
							justifyContent="space-between"
							spacing={1}
							height="100%"
							width="100%"
							p={0.4}
						>
							<Stack
								direction="row"
								alignItems="flex-start"
								spacing={1}
								overflow="hidden"
								height="100%"
							>
								<Box display="flex">
									{getIconByName(
										event?.activityType?.icon?.name
									)(18, 18, textColor)}
								</Box>

								<Typography fontSize={14} noWrap={!!isAllDay}>
									{title}
								</Typography>
							</Stack>

							<Checkbox
								icon={RoundCheckBoxOffIcon(18, 18)}
								checkedIcon={RoundCheckBoxOnIcon(
									18,
									18,
									theme.palette.primary.main
								)}
								checked={rowState === activityStatus.completed}
								onClick={(onClickEvent) =>
									updateActivityStatus(onClickEvent, event)
								}
								style={{
									padding: 0,
								}}
							/>
						</Stack>
					</Box>
				</React.Fragment>
			);
		},
		[theme, updateActivityStatus]
	);

	const customCalendarComponents = useMemo(
		() => ({
			timeSlotWrapper: CustomDateCellWrapper,
			week: { header: CustomDateHeader },
			work_week: { header: CustomDateHeader },
			event: CustomEvent,
		}),
		[CustomDateHeader, CustomEvent]
	);

	const getWeekDateLabel = (date) => {
		let dayjsObj = dayjs(date);
		let weekStart = dayjsObj.startOf("week");
		let weekEnd = dayjsObj.endOf("week");
		return (
			weekStart.format("DD MMM YYYY") +
			" - " +
			weekEnd.format("DD MMM YYYY")
		);
	};

	const selectPreviousWeek = () => {
		let newSelectedDate = new Date(selectedDate);
		newSelectedDate.setDate(newSelectedDate.getDate() - 7);
		setSelectedDate(newSelectedDate);
		setFilterDependsOnWeek((filterDependsOnWeek) => {
			return {
				...filterDependsOnWeek,
				weekCount: --filterDependsOnWeek.weekCount,
			};
		});
	};

	const selectNextWeek = () => {
		let newSelectedDate = new Date(selectedDate);
		newSelectedDate.setDate(newSelectedDate.getDate() + 7);
		setSelectedDate(newSelectedDate);
		setFilterDependsOnWeek((filterDependsOnWeek) => {
			return {
				...filterDependsOnWeek,
				weekCount: ++filterDependsOnWeek.weekCount,
			};
		});
	};

	const selectCurrentWeek = () => {
		let newSelectedDate = new Date();
		setSelectedDate(newSelectedDate);
		setFilterDependsOnWeek((filterDependsOnWeek) => {
			return {
				...filterDependsOnWeek,
				weekCount: 0,
			};
		});
	};

	const changeSelectedDate = (date) => {
		closeDatePickerMenu();
		setSelectedDate(date);

		const currentWeek = moment(new Date()).weeks();
		const selectedWeek = moment(new Date(date)).weeks();

		let differenceInWeek = selectedWeek - currentWeek;

		setFilterDependsOnWeek((filterDependsOnWeek) => {
			return {
				...filterDependsOnWeek,
				weekCount: differenceInWeek,
			};
		});
	};

	const changeCalenderView = (view) => {
		setCalendarView(view);
		closeWeekendMenu();
		setFilterDependsOnWeek((filterDependsOnWeek) => {
			return {
				...filterDependsOnWeek,
				isWeekEnd: view === Views.WEEK,
			};
		});
	};

	const openWeekendMenu = (event) => {
		setWeekendMenuElement(event.currentTarget);
	};

	const closeWeekendMenu = () => {
		setWeekendMenuElement(null);
	};

	const openDatePickerMenu = (event) => {
		setDatePickerMenuElement(event.currentTarget);
	};

	const closeDatePickerMenu = () => {
		setDatePickerMenuElement(null);
	};

	const openActivitySummary = (event) => {
		openActivitySummaryDialog(event?.id);
	};

	useLayoutEffect(() => {
		setCalendarStartingPosition(
			calendarRef.current.getBoundingClientRect().height
		);
	}, []);

	const openAddActivityDialog = () => {
		setIsAddActivityDialogOpened(true);
	};

	const closeAddActivityDialog = () => {
		setIsAddActivityDialogOpened(false);
		setActivityFormData({});
	};

	const onSelectSlot = (event) => {
		setActivityFormData({
			startTime: event?.start?.valueOf(),
			endTime: event?.end?.valueOf(),
		});
		openAddActivityDialog();
	};

	return (
		<React.Fragment>
			{/* AddActivity Drawer */}
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddActivityDialogOpened}
				onOpen={openAddActivityDialog}
				onClose={closeAddActivityDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddActivity
						onClose={closeAddActivityDialog}
						activityFormData={activityFormData}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Menu
				minWidth="150px"
				anchorEl={weekendMenuElement}
				open={isWeekendMenuOpened}
				onClose={closeWeekendMenu}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				style={{ marginTop: "8px" }}
			>
				<MenuItem onClick={() => changeCalenderView(Views.WEEK)}>
					<Stack direction="row" alignItems="center" spacing={1}>
						<Radio
							checked={calendarView === Views.WEEK}
							style={{ padding: 0 }}
						/>

						<MenuItemText>On</MenuItemText>
					</Stack>
				</MenuItem>

				<MenuItem onClick={() => changeCalenderView(Views.WORK_WEEK)}>
					<Stack direction="row" alignItems="center" spacing={1}>
						<Radio
							checked={calendarView === Views.WORK_WEEK}
							style={{ padding: 0 }}
						/>

						<MenuItemText>Off</MenuItemText>
					</Stack>
				</MenuItem>
			</Menu>

			<Menu
				minWidth="150px"
				anchorEl={datePickerMenuElement}
				open={isDatePickerMenuElementOpened}
				onClose={closeDatePickerMenu}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				style={{ marginTop: "8px" }}
			>
				<DateCalendar
					value={dayjs(selectedDate)}
					onChange={(props) =>
						changeSelectedDate(new Date(props.toISOString()))
					}
					showDaysOutsideCurrentMonth
				/>
			</Menu>

			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box className={classes.menuBar}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						height="100%"
					>
						<ActivityFilters
							filterListByCondition={filterListByCondition}
						/>

						<ActivityMenu
							view={activityView.calendar}
							handleViewToggleButtonGroup={
								handleViewToggleButtonGroup
							}
						/>
					</Stack>
				</Box>

				<Divider />

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="center"
					minHeight="52px"
					spacing={1}
				>
					<Stack direction="row" alignItems="center" spacing={0.5}>
						<IconButton
							onClick={selectPreviousWeek}
							style={{ transform: "rotate(180deg)" }}
						>
							{RightArrowIcon(
								16,
								16,
								theme.palette.secondary.contrastText
							)}
						</IconButton>

						<Box
							onClick={openDatePickerMenu}
							style={{
								cursor: "pointer",
								alignItems: "center",
								borderRadius: "8px",
								backgroundColor: isDatePickerMenuElementOpened
									? theme.palette.secondary.main
									: "transparent",
								userSelect: "none",
							}}
							py={0.5}
							px={1}
						>
							<Typography
								fontWeight={500}
								fontSize={15}
								color={theme.palette.secondary.contrastText}
							>
								{getWeekDateLabel(selectedDate)}
							</Typography>
						</Box>

						<IconButton onClick={selectNextWeek}>
							{RightArrowIcon(
								16,
								16,
								theme.palette.secondary.contrastText
							)}
						</IconButton>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						style={{
							position: "absolute",
							right: 0,
						}}
						pr={2}
						spacing={1}
					>
						<Stack
							direction="row"
							spacing={1}
							style={{
								cursor: "pointer",
								alignItems: "center",
								borderRadius: "8px",
								backgroundColor: isWeekendMenuOpened
									? theme.palette.secondary.main
									: "transparent",
								userSelect: "none",
							}}
							py={0.5}
							px={1}
							onClick={openWeekendMenu}
						>
							<Typography
								color={theme.palette.secondary.contrastText}
								fontSize={14}
								fontWeight={500}
							>
								Weekends
							</Typography>

							{DropDownIcon(16, 16, theme.palette.primary.main)}
						</Stack>

						<Divider orientation="vertical" flexItem />

						<DwarfButton
							variant="contained"
							color="secondary"
							onClick={selectCurrentWeek}
							style={{ marginLeft: "16px" }}
							disableElevation
						>
							Today
						</DwarfButton>
					</Stack>
				</Stack>

				<Box
					style={{
						flex: "1 1 auto",
						minHeight: 0,
					}}
				>
					<Box
						ref={calendarRef}
						style={{
							height: "100%",
						}}
					>
						<Calendar
							selectable
							onSelectSlot={onSelectSlot}
							header={CustomDateHeader}
							components={customCalendarComponents}
							localizer={localizer}
							date={selectedDate}
							view={calendarView}
							views={[Views.WEEK, Views.WORK_WEEK]}
							onView={() => {}}
							onNavigate={() => {}}
							events={activitiesData}
							onSelectEvent={openActivitySummary}
							startAccessor="startTime"
							endAccessor="endTime"
							toolbar={false}
							// scrollToTime={new Date()}
							formats={{
								timeGutterFormat: "h A",
							}}
							style={{
								height: `${calendarStartingPosition}px`,
							}}
							dayLayoutAlgorithm={"no-overlap"}
						/>
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	);
}
