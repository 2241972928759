import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import {
	createDashboard,
	getDashboardListData,
	getChartApplyData,
	getChartTypeList,
	getChartViewData,
	getDashboard,
	getDuration,
	getMetrics,
	getXAxisMetrics,
	updateDashboard,
	getMetricsFilterField,
	getUnderlyingList,
	getDashboardShareData,
	getUpdateDashboardShareData,
	getFavData,
} from "../../../api/analytics/analyticsApi";
import {
	getAllDashboardListKey,
	getAnalyticsDashboardDataKey,
	getAnalyticsDashboardShareKey,
	getAnalyticsDurationKey,
	getAnalyticsKey,
	getAnalyticsMetricsKey,
	getAnalyticsUnderlyingListKey,
	getAnalyticsXAxisMetricsKey,
	getChartApplyDataKey,
	getChartViewDataKey,
	getDashboardListKey,
	getMetricsFilterFieldKey,
} from "../../../utils/queryKeys/analytics";
import {
	analyticsListSize,
	underlyingListSize,
} from "../../../utils/queryConstants/analytics";

const analyticsKey = getAnalyticsKey();

const useCreateDashboardMutation = () => {
	let invalidateDashboardList = useInvalidateDashboardList();
	return useMutation(createDashboard, {
		onSuccess: () => {
			invalidateDashboardList();
		},
	});
};

const useUpdateDashboardMutation = (dashboardData) => {
	let invalidateDashboardList = useInvalidateDashboardList();
	let invalidateDashboardData = useInvalidateDashboardData(
		dashboardData?.dashboardId
	);
	let invalidateChartViewData = useInvalidateChartViewData(
		dashboardData?.dashboardId
	);
	return useMutation(updateDashboard, {
		onSuccess: () => {
			invalidateDashboardList();
			invalidateDashboardData();
			invalidateChartViewData();
		},
	});
};

const selectReports = (reports) => {
	return reports.pages.flatMap((page) => page?.table?.rows || []);
};

const useGetDashboardList = (
	reportName,
	fetchHits = false,
	criteria = [],
	sort = []
) => {
	return useInfiniteQuery({
		queryKey: getDashboardListKey(reportName, fetchHits, criteria, sort),
		queryFn: ({ pageParam }) => {
			return getDashboardListData(reportName, fetchHits, criteria, sort, {
				start: pageParam ? (pageParam - 1) * analyticsListSize + 1 : 1,
				count: analyticsListSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.table.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: selectReports,
	});
};

const useUnderlyingList = (
	viewId,
	moduleId,
	viewTypeId,
	childViewId,
	selectRequest
) => {
	return useInfiniteQuery({
		queryKey: getAnalyticsUnderlyingListKey(
			viewId,
			moduleId,
			viewTypeId,
			childViewId,
			selectRequest
		),
		queryFn: ({ pageParam }) => {
			return getUnderlyingList(
				viewId,
				moduleId,
				viewTypeId,
				childViewId,
				selectRequest,
				{
					start: pageParam
						? (pageParam - 1) * underlyingListSize + 1
						: 1,
					count: underlyingListSize,
				}
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage?.table?.hasMore
				? allPages.length + 1
				: undefined;
		},
	});
};

const useGetDashboard = (
	dashboardId,
	fetchViewProperties = false,
	options = {}
) => {
	return useQuery(
		getAnalyticsDashboardDataKey(dashboardId, fetchViewProperties),
		() => getDashboard(dashboardId, fetchViewProperties),
		options
	);
};

const useGetDashboardShare = (dashboardId, option = {}) => {
	return useQuery(
		getAnalyticsDashboardShareKey(dashboardId),
		() => getDashboardShareData(dashboardId),
		{
			...option,
			cacheTime: 0,
		}
	);
};

const useUpdateDashboardShare = (dashboardId) => {
	let invalidateDashboardShare = useInvalidateDashboardShare(dashboardId);
	return useMutation(getUpdateDashboardShareData, {
		onSuccess: () => {
			invalidateDashboardShare();
		},
	});
};

const useGetChartType = () => {
	return useQuery(analyticsKey, () => getChartTypeList());
};

const useGetMetrics = (viewTypeId) => {
	return useQuery(getAnalyticsMetricsKey(viewTypeId), () =>
		getMetrics(viewTypeId)
	);
};

const useGetXAxisMetrics = (moduleIds, options = {}) => {
	return useQuery(
		getAnalyticsXAxisMetricsKey(moduleIds),
		() => getXAxisMetrics(moduleIds),
		options
	);
};

const useDuration = (moduleId, options = {}) => {
	return useQuery(
		getAnalyticsDurationKey(moduleId),
		() => getDuration(moduleId),
		options
	);
};

const useChartApplyData = (chartProperty, options) => {
	return useQuery(
		getChartApplyDataKey(chartProperty),
		() => getChartApplyData(chartProperty),
		{
			cacheTime: 2000,
			...options,
		}
	);
};

const useChartViewData = (dashboardId, viewId, options) => {
	return useQuery(
		getChartViewDataKey(dashboardId, viewId),
		() => getChartViewData(dashboardId, viewId),
		{
			cacheTime: 2000,
			...options,
		}
	);
};

export const useMetricsFilterField = (moduleId) => {
	return useQuery(
		getMetricsFilterFieldKey(moduleId),
		() => getMetricsFilterField(moduleId),
		{
			enabled: !!moduleId,
		}
	);
};

export const useMetricsFilter = () => {
	return useMutation(getMetricsFilterField);
};

const useFavData = (dashboardId, fetchViewProperties) => {
	const invalidateDashboardList = useInvalidateDashboardList();
	let invalidateDashboardData = useInvalidateDashboardData(
		dashboardId,
		fetchViewProperties
	);
	return useMutation(getFavData, {
		onSuccess: () => {
			invalidateDashboardList();
			invalidateDashboardData();
		},
	});
};

// Query Invalidation call:-

const useInvalidateDashboardList = () => {
	let queryClient = useQueryClient();
	let dashBoardListKey = getAllDashboardListKey();

	return () => {
		queryClient.invalidateQueries(dashBoardListKey);
	};
};

const useInvalidateDashboardData = (dashboardId, fetchViewProperties) => {
	let queryClient = useQueryClient();
	let analyticsDashboardDataKey = getAnalyticsDashboardDataKey(
		dashboardId,
		fetchViewProperties
	);

	return () => {
		queryClient.invalidateQueries(analyticsDashboardDataKey);
	};
};

const useInvalidateChartViewData = (dashboardId) => {
	let queryClient = useQueryClient();
	let chartViewDataKey = getChartViewDataKey(dashboardId);

	return () => {
		queryClient.invalidateQueries(chartViewDataKey);
	};
};

const useInvalidateDashboardShare = (dashboardId) => {
	let queryClient = useQueryClient();
	let dashBoardShareKey = getAnalyticsDashboardShareKey(dashboardId);

	return () => {
		queryClient.invalidateQueries(dashBoardShareKey);
	};
};

export {
	useCreateDashboardMutation,
	useUpdateDashboardMutation,
	useGetDashboard,
	useFavData,
	useGetChartType,
	useGetMetrics,
	useGetXAxisMetrics,
	useDuration,
	useUnderlyingList,
	useChartApplyData,
	useGetDashboardList,
	useChartViewData,
	useGetDashboardShare,
	useUpdateDashboardShare,
};
