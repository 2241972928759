import React from "react";
import AddTeamDialog from "../AddTeamDialog";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import {
	useTeamData,
	useUpdateTeam,
} from "../../../../../hooks/services/userManagement/teams";
import { Box, CircularProgress } from "@mui/material";

export default function EditTeam(props) {
	const { onClose, teamId } = props;

	const updateTeamMutation = useUpdateTeam(teamId);
	const { data: teamData, isLoading: isLoadingTeamData } =
		useTeamData(teamId);

	const updateTeam = (teamData) => {
		updateTeamMutation.mutate(teamData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.teamUpdated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			{isLoadingTeamData ? (
				<Box
					display="flex"
					height="90vh"
					justifyContent="center"
					alignItems="center"
				>
					<CircularProgress />
				</Box>
			) : (
				<AddTeamDialog
					title="Edit Team"
					onClose={onClose}
					isEditMode={true}
					onUpdate={updateTeam}
					teamDetail={teamData || []}
					isSaving={updateTeamMutation.isLoading}
				/>
			)}
		</React.Fragment>
	);
}
