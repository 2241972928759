import React, { useState } from "react";
import {
	Box,
	CircularProgress,
	Drawer,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { default as ExpentIcon } from "../../../../assets/icons/expent";
import { useFromAddressDropdownList } from "../../../../hooks/services/mail";
import { twozoStyles } from "../../../../styles/twozo";
import EmailComposerUI from "../EmailComposerUI";
import Nodes from "../EmailComposerUI/Nodes";

export default function EmailComposerDrawer(props) {
	const {
		onClose,
		isBulkMailEnabled,
		composerValues,
		sourceName,
		entityId,
		hasDraftMail,
		emailContext,
		isloadingComposerValues,
		entityIds,
	} = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const [popout, setPopout] = useState(true);
	const iconColor = "#666666";

	const { data: fromAddressList, isLoading: isFromAddressLoading } =
		useFromAddressDropdownList();
	const fromAddress = fromAddressList?.[0];

	const togglePopout = () => {
		setPopout((popout) => !popout);
	};

	const handleCloseComposer = () => {
		onClose();
		setPopout(true);
	};

	const isLoadingComposer = () => {
		return isFromAddressLoading || isloadingComposerValues;
	};

	return (
		<React.Fragment>
			<Drawer
				anchor="bottom"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent", border: "none" },
				}}
				open={popout}
				variant="persistent"
				onClose={togglePopout}
			>
				<Stack direction="row" justifyContent="flex-end" px={5}>
					<Box
						style={{
							width: "640px",
							backgroundColor: "#fff",
							border: "1px solid #E0E0E0",
							borderRadius: "8px 8px 0px 0px",
						}}
					>
						<LexicalComposer
							initialConfig={{
								theme: {
									paragraph: classes.email_paragraph,
									text: {
										bold: classes.email_font_bold,
										italic: classes.email_italic,
										underline: classes.email_underline,
										strikethrough:
											classes.email_strikethrough,
									},
									quote: classes.email_quote,
								},
								nodes: [...Nodes],
								onError(error) {
									throw error;
								},
							}}
						>
							{isLoadingComposer() ? (
								<Stack
									alignItems="center"
									justifyContent="center"
									height="80vh"
								>
									<CircularProgress />
								</Stack>
							) : (
								<EmailComposerUI
									togglePopout={togglePopout}
									fromAddress={fromAddress}
									multipleSender={false}
									onClose={onClose}
									isBulkMailEnabled={isBulkMailEnabled}
									composerValues={composerValues}
									sourceName={sourceName}
									entityId={entityId}
									hasDraftMail={hasDraftMail}
									emailContext={emailContext}
									entityIds={entityIds}
								/>
							)}
						</LexicalComposer>
					</Box>
				</Stack>
			</Drawer>

			<Drawer
				anchor="bottom"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent", border: "none" },
				}}
				open={!popout}
				variant="persistent"
				onClose={togglePopout}
			>
				<Stack direction="row" justifyContent="flex-end" px={5}>
					<Box
						style={{
							width: "640px",
							backgroundColor: theme.palette.secondary.main,
							border: "1px solid #E0E0E0",
							borderRadius: "8px 8px 0px 0px",
						}}
					>
						<Stack
							direction="row"
							justifyContent="space-between"
							px={2}
							py={1}
							alignItems="center"
						>
							<Typography fontSize={14} fontWeight={500}>
								{fromAddress?.emailAddress}
							</Typography>

							<Stack direction="row" spacing={2}>
								<IconButton size="small" onClick={togglePopout}>
									{ExpentIcon(20, 20, iconColor)}
								</IconButton>

								<IconButton
									size="small"
									onClick={handleCloseComposer}
								>
									{CloseIcon(20, 20, iconColor)}
								</IconButton>
							</Stack>
						</Stack>
					</Box>
				</Stack>
			</Drawer>
		</React.Fragment>
	);
}
