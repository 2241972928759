import React, { useCallback, useEffect } from "react";
import dayjs from "dayjs";
import moment from "moment-timezone";
import DateTimeComponent from "../DateTimeRangeComponent";

export default function ActivityStartDateComponent(props) {
	const {
		field = {},
		updateFieldValue,
		error,
		style = {},
		value,
		clearError,
		formValues,
	} = props;

	const currentDate = dayjs().startOf("day").valueOf();

	useEffect(() => {
		if (!value?.value) {
			updateFieldValue([{ value: currentDate }], field.fieldId);
		}
	}, [currentDate, field, updateFieldValue, value]);

	const manuallySetEndTime = useCallback(
		(from, to, preFromDate) => {
			const startDate = new Date(from);
			const endDate = new Date(to);
			const preStartDate = new Date(preFromDate);

			// Calculate the difference in milliseconds
			const totalDiff = startDate.getTime() - preStartDate.getTime();

			// Adjust the end date with both date and time differences
			const adjustedEndDate = new Date(endDate.getTime() + totalDiff);

			updateFieldValue(
				[{ value: adjustedEndDate, isEndTimeChanged: true }],
				field?.config?.dependsOn,
				true
			);
		},
		[field?.config?.dependsOn, updateFieldValue]
	);

	const handleChangeDateTimeRangeValue = useCallback(
		(newDateRangeValue) => {
			const newStartDateValue = moment(
				newDateRangeValue?.[0]?.value ?? currentDate
			).valueOf();

			if (isNaN(newStartDateValue)) {
				return;
			}

			updateFieldValue([{ value: newStartDateValue }], field.fieldId);

			const dependentFieldValue =
				formValues?.[field?.config?.dependsOn]?.value;
			const isEndTimeChanged =
				formValues?.[field?.config?.dependsOn]?.isEndTimeChanged;

			if (isEndTimeChanged) {
				manuallySetEndTime(
					newStartDateValue,
					dependentFieldValue,
					formValues?.[field.fieldId]?.value ?? currentDate
				);
			} else {
				const newEndDateValue = new Date(
					dependentFieldValue ?? currentDate
				);
				const timeDiff =
					newStartDateValue -
					(formValues?.[field.fieldId]?.value ?? currentDate);

				newEndDateValue.setTime(newEndDateValue.getTime() + timeDiff);

				updateFieldValue(
					[{ value: newEndDateValue, isEndTimeChanged: true }],
					field?.config?.dependsOn,
					true
				);
			}
		},
		[
			field?.config?.dependsOn,
			field.fieldId,
			formValues,
			manuallySetEndTime,
			updateFieldValue,
			currentDate,
		]
	);

	return (
		<React.Fragment>
			<DateTimeComponent
				field={field}
				error={error}
				style={style}
				clearError={clearError}
				value={value ?? currentDate}
				updateFieldValue={handleChangeDateTimeRangeValue}
			/>
		</React.Fragment>
	);
}
