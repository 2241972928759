import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import ProductBaseComponent from "./ProductBaseComponent";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";

export function ProductComponent({ field, subField, inputRef }) {
	const { control, clearErrors } = useFormContext();

	return (
		<React.Fragment>
			<Controller
				name={field.id + ""}
				control={control}
				rules={handleFieldValidation(field, subField)}
				render={({
					field: { onChange, value },
					fieldState: { error },
				}) => (
					<ProductBaseComponent
						onProductInputChange={onChange}
						value={value}
						field={field}
						subField={subField}
						clearErrors={clearErrors}
						inputRef={inputRef}
						error={error}
					/>
				)}
				defaultValue={null}
			></Controller>
		</React.Fragment>
	);
}
