import React, { useState } from "react";
import {
	Box,
	CircularProgress,
	MenuItem,
	Select,
	Skeleton,
	Stack,
	Typography,
} from "@mui/material";
import CloseIcon from "../../../assets/icons/close";
import { useLastRowRef } from "../../../hooks/common/InfiniteScroll";
import MenuSearchBar from "../MenuSearchBar";
import { MenuItemText } from "../../../styles/twozo";
import { useRolesList } from "../../../hooks/services/userManagement/roles";

export default function RolesMenu(props) {
	const {
		value,
		onChange,
		onRemoveRole,
		disabled = false,
		error,
		removeActionEnabled = true,
		menuPositionConfig = {},
	} = props;

	const [searchedValue, setSearchedValue] = useState("");

	const {
		data: roleList,
		isLoading: isLoadingRoles,
		isFetching: isFetchingRoles,
		hasNextPage: hasNextRolesPage,
		fetchNextPage: fetchNextRolesPage,
	} = useRolesList(searchedValue);

	const lastOptionRef = useLastRowRef(
		fetchNextRolesPage,
		hasNextRolesPage,
		isFetchingRoles
	);

	const handleSelectRole = (event, child) => {
		const { value } = event.target;
		onChange({ name: child?.props?.name, value: value });
		setSearchedValue("");
	};

	const handleSearchValue = (event) => {
		const { value } = event.target;
		setSearchedValue(value);
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					{searchedValue && roleList?.length === 0
						? "No Results Found"
						: "No Options Found"}
				</Typography>
			</Stack>
		);
	};

	const displayRemoveIcon = (role) => {
		return value?.value === role?.value && !!removeActionEnabled;
	};

	return (
		<React.Fragment>
			<Select
				size="small"
				fullWidth
				error={!!error}
				value={value?.value || ""}
				onChange={handleSelectRole}
				MenuProps={{
					autoFocus: false,
					PaperProps: {
						style: {
							marginTop: menuPositionConfig?.anchorOrigin
								? "-4px"
								: "4px",
							borderRadius: "8px",
							maxHeight: "290px",
							maxWidth: "400px",
						},
					},
					...menuPositionConfig,
				}}
				displayEmpty
				renderValue={() => (
					<Typography
						fontSize={14}
						noWrap
						color={value?.name ? "#000" : "rgba(0, 0, 0, 0.6)"}
					>
						{value?.name ?? "Select"}
					</Typography>
				)}
				onOpen={() => setSearchedValue("")}
				disabled={disabled}
			>
				<Box
					p={0.5}
					marginTop="-8px"
					sx={{
						position: "sticky",
						top: 0,
						zIndex: 1,
						backgroundColor: "#fff",
					}}
				>
					<MenuSearchBar
						value={searchedValue}
						onChange={handleSearchValue}
					/>
				</Box>

				{isLoadingRoles ? (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="150px"
					>
						<CircularProgress size="25px" />
					</Stack>
				) : roleList?.length > 0 ? (
					roleList.map((role) => (
						<MenuItem
							value={role.value}
							key={role.value}
							name={role.name}
							style={{
								height: "40px",
							}}
						>
							<Stack
								width="100%"
								direction="row"
								spacing={1}
								alignItems="center"
								justifyContent="space-between"
							>
								<MenuItemText whiteSpace="wrap">
									{role.name}
								</MenuItemText>

								{displayRemoveIcon(role) ? (
									<Box display="flex" onClick={onRemoveRole}>
										{CloseIcon(20, 20, "#000", 0.6)}
									</Box>
								) : null}
							</Stack>
						</MenuItem>
					))
				) : (
					renderEmptyView()
				)}

				<Box ref={lastOptionRef}>
					{!isLoadingRoles &&
						isFetchingRoles &&
						roleList?.length > 0 && (
							<MenuItem style={{ height: "40px" }}>
								<Skeleton width="190px" height="16px" />
							</MenuItem>
						)}
				</Box>
			</Select>
		</React.Fragment>
	);
}
