import React, { useMemo } from "react";
import {
	Timeline,
	TimelineConnector,
	timelineConnectorClasses,
	TimelineContent,
	TimelineDot,
	timelineDotClasses,
	TimelineItem,
	TimelineOppositeContent,
	timelineOppositeContentClasses,
	TimelineSeparator,
} from "@mui/lab";
import {
	Box,
	CircularProgress,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { getIconByName } from "../../../utils";
import {
	getDateMonthAndYearFormat,
	getHourAndMinuteFormat,
} from "../../../utils/DateUtils";
import dayjs from "dayjs";
import { timelineTypes } from "../../../utils/timelineUtils";
import DealCreated from "./TimelineTypes/dealCreated";
import DealTitleUpdated from "./TimelineTypes/dealTitleUpdated";
import DealAssociatedWithContact from "./TimelineTypes/dealAssociatedWithContact";
import DealAssociatedWithCompany from "./TimelineTypes/dealAssociatedWithCompany";
import DealValueAssigned from "./TimelineTypes/dealValueAssigned";
import DealValueUpdated from "./TimelineTypes/dealValueUpdated";
import DealExpectedCloseDateAssigned from "./TimelineTypes/dealExpectedCloseDateAssigned";
import DealExpectedCloseDateChanged from "./TimelineTypes/dealExpectedCloseDateChanged";
import DealPipelineStageMoved from "./TimelineTypes/dealPipelineStageMoved";
import NowPointOfContactForThisDeal from "./TimelineTypes/nowPointOfContactForThisDeal";
import DealLostReasonSet from "./TimelineTypes/dealLostReasonSet";
import DealLostReasonUpdated from "./TimelineTypes/dealLostReasonUpdated";
import DealMerged from "./TimelineTypes/dealMerged";
import ContactCreated from "./TimelineTypes/contactCreated";
import ContactNameUpdated from "./TimelineTypes/contactNameUpdated";
import SubscriptionStatusChanged from "./TimelineTypes/subscriptionStatusChanged";
import ContactMerged from "./TimelineTypes/contactMerged";
import ContactLifecycleStageUpdated from "./TimelineTypes/contactLifecycleStageUpdated";
import ContactLifecycleStatusUpdated from "./TimelineTypes/contactLifecycleStatusUpdated";
import ContactClosedLostReasonUpdated from "./TimelineTypes/contactClosedLostReasonUpdated";
import ContactClosedLostReasonSet from "./TimelineTypes/contactClosedLostReasonSet";
import CompanyCreated from "./TimelineTypes/companyCreated";
import CompanyNameUpdated from "./TimelineTypes/companyNameUpdated";
import CompanyMerged from "./TimelineTypes/companyMerged";
import NotesAdded from "./TimelineTypes/notesAdded";
import NotesEdited from "./TimelineTypes/notesEdited";
import FileUploaded from "./TimelineTypes/fileUploaded";
import ActivityAdded from "./TimelineTypes/activityAdded";
import ActivityTitleUpdated from "./TimelineTypes/activityTitleUpdated";
import ActivityNoteAdded from "./TimelineTypes/activityNoteAdded";
import ActivityNoteEdited from "./TimelineTypes/activityNoteEdited";
import EmailSent from "./TimelineTypes/emailSent";
import EmailReceived from "./TimelineTypes/emailReceived";
import EmailReplied from "./TimelineTypes/emailReplied";
import SalesOwnerReassigned from "./TimelineTypes/salesOwnerReassigned";
import FollowersAdded from "./TimelineTypes/followersAdded";
import FollowersRemoved from "./TimelineTypes/followersRemoved";

export default function TimeLine(props) {
	const {
		timelineList,
		isLoadingTimelineDetails,
		lastRowRef,
		isFetchingMoreData,
	} = props;
	const theme = useTheme();

	const timelineData = useMemo(() => {
		if (!timelineList || !Array.isArray(timelineList.pages)) {
			return { date: "", data: [] };
		}

		let timelineData = [];

		timelineList.pages
			.flatMap((page) => page.timeline)
			.forEach((timeline) => {
				let lastTimelineData = timelineData[timelineData.length - 1];

				let timelineDataForOnlyKnownTimelineTypes =
					timeline?.data?.filter(
						(timeline) => !!timelineTypes[timeline?.type]
					);

				if (timelineDataForOnlyKnownTimelineTypes?.length > 0) {
					if (
						!lastTimelineData ||
						lastTimelineData?.date !== timeline?.date
					) {
						timelineData.push({
							date: timeline?.date,
							data: [...timelineDataForOnlyKnownTimelineTypes],
						});
					} else {
						lastTimelineData.data.push(
							...timelineDataForOnlyKnownTimelineTypes
						);
					}
				}
			});

		return timelineData;
	}, [timelineList]);

	return (
		<React.Fragment>
			{isLoadingTimelineDetails ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="50vh"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : timelineData?.length > 0 ? (
				<Timeline
					sx={{
						[`& .${timelineOppositeContentClasses.root}`]: {
							flex: 0,
							py: "18px",
							px: 2,
						},
						[`& .${timelineConnectorClasses.root}`]: {
							backgroundColor: theme.palette.secondary.main,
							margin: "-11.5px 0px",
						},
						[`& .${timelineDotClasses.root}`]: {
							boxShadow: "none",
						},
						padding: "0px 12px",
						wordBreak: "break-all",
					}}
				>
					{timelineData?.map((eventsForDay, eventsForDayIndex) => (
						<React.Fragment key={eventsForDayIndex}>
							<TimelineItem
								style={{
									minHeight:
										eventsForDay.data.length === 0
											? "58px"
											: "40px",
								}}
							>
								{/*Empty timeline opposite content to align the timeline*/}
								<TimelineOppositeContent
									style={{
										paddingLeft: "0px",
									}}
								>
									<Box width="110px"></Box>
								</TimelineOppositeContent>
								<TimelineSeparator>
									<TimelineDot color="primary" />
									<TimelineConnector />
								</TimelineSeparator>
								<TimelineContent sx={{ py: 1, px: 3 }}>
									<Typography
										fontSize={15}
										fontWeight={500}
										color={
											theme.palette.secondary.contrastText
										}
									>
										{getDateMonthAndYearFormat(
											dayjs(
												eventsForDay.date,
												"MM/DD/YYYY"
											)
												.tz()
												.valueOf()
										)}
									</Typography>
								</TimelineContent>
							</TimelineItem>

							{eventsForDay.data.map((event, eventIndex) => (
								<TimelineItem
									key={event.id}
									style={{
										minHeight:
											eventsForDay.data.length - 1 ===
											eventsForDayIndex
												? "75px"
												: "24px",
									}}
								>
									<TimelineOppositeContent
										style={{
											paddingLeft: "0px",
										}}
									>
										<Box width="100px">
											<Typography
												fontSize={14}
												fontWeight={500}
											>
												{getHourAndMinuteFormat(
													event.auditedTime
												)}
											</Typography>
										</Box>
									</TimelineOppositeContent>

									<TimelineSeparator>
										<TimelineDot color="secondary">
											{getIconByName(event.icon?.name)(
												20,
												20,
												"#000"
											)}
										</TimelineDot>
										{eventsForDayIndex !==
											timelineData.length - 1 ||
										eventIndex !==
											eventsForDay.data.length - 1 ? (
											<TimelineConnector />
										) : null}
									</TimelineSeparator>
									<TimelineContent>
										{(function () {
											switch (event.type) {
												case timelineTypes.DEAL_CREATED:
													return (
														<DealCreated
															eventData={event}
														/>
													);
												case timelineTypes.DEAL_TITLE_UPDATED:
													return (
														<DealTitleUpdated
															eventData={event}
														/>
													);
												case timelineTypes.DEAL_ASSOCIATED_WITH_CONTACT:
													return (
														<DealAssociatedWithContact
															eventData={event}
														/>
													);
												case timelineTypes.DEAL_ASSOCIATED_WITH_COMPANY:
													return (
														<DealAssociatedWithCompany
															eventData={event}
														/>
													);
												case timelineTypes.DEAL_VALUE_ASSIGNED:
													return (
														<DealValueAssigned
															eventData={event}
														/>
													);
												case timelineTypes.DEAL_VALUE_UPDATED:
													return (
														<DealValueUpdated
															eventData={event}
														/>
													);
												case timelineTypes.DEAL_EXPECTED_CLOSE_DATE_ASSIGNED:
													return (
														<DealExpectedCloseDateAssigned
															eventData={event}
														/>
													);
												case timelineTypes.DEAL_EXPECTED_CLOSE_DATE_CHANGED:
													return (
														<DealExpectedCloseDateChanged
															eventData={event}
														/>
													);
												case timelineTypes.DEAL_PIPELINE_STAGE_MOVED:
													return (
														<DealPipelineStageMoved
															eventData={event}
														/>
													);
												case timelineTypes.NOW_POINT_OF_CONTACT_FOR_THIS_DEAL:
													return (
														<NowPointOfContactForThisDeal
															eventData={event}
														/>
													);
												case timelineTypes.DEAL_LOST_REASON_SET:
													return (
														<DealLostReasonSet
															eventData={event}
														/>
													);
												case timelineTypes.DEAL_LOST_REASON_UPDATED:
													return (
														<DealLostReasonUpdated
															eventData={event}
														/>
													);
												case timelineTypes.DEAL_MERGED:
													return (
														<DealMerged
															eventData={event}
														/>
													);
												case timelineTypes.CONTACT_CREATED:
													return (
														<ContactCreated
															eventData={event}
														/>
													);
												case timelineTypes.CONTACT_NAME_UPDATED:
													return (
														<ContactNameUpdated
															eventData={event}
														/>
													);
												case timelineTypes.SUBSCRIPTION_STATUS_CHANGED:
													return (
														<SubscriptionStatusChanged
															eventData={event}
														/>
													);
												case timelineTypes.CONTACT_MERGED:
													return (
														<ContactMerged
															eventData={event}
														/>
													);
												case timelineTypes.CONTACT_LIFE_CYCLE_STAGE_UPDATED:
													return (
														<ContactLifecycleStageUpdated
															eventData={event}
														/>
													);
												case timelineTypes.CONTACT_LIFE_CYCLE_STATUS_UPDATED:
													return (
														<ContactLifecycleStatusUpdated
															eventData={event}
														/>
													);
												case timelineTypes.CONTACT_CLOSED_LOST_REASON_UPDATED:
													return (
														<ContactClosedLostReasonUpdated
															eventData={event}
														/>
													);
												case timelineTypes.CONTACT_CLOSED_LOST_REASON_SET:
													return (
														<ContactClosedLostReasonSet
															eventData={event}
														/>
													);
												case timelineTypes.COMPANY_CREATED:
													return (
														<CompanyCreated
															eventData={event}
														/>
													);
												case timelineTypes.COMPANY_NAME_UPDATED:
													return (
														<CompanyNameUpdated
															eventData={event}
														/>
													);
												case timelineTypes.COMPANY_MERGED:
													return (
														<CompanyMerged
															eventData={event}
														/>
													);
												case timelineTypes.NOTES_ADDED:
													return (
														<NotesAdded
															eventData={event}
														/>
													);
												case timelineTypes.NOTES_EDITED:
													return (
														<NotesEdited
															eventData={event}
														/>
													);
												case timelineTypes.FILE_UPLOADED:
													return (
														<FileUploaded
															eventData={event}
														/>
													);
												case timelineTypes.ACTIVITY_ADDED:
													return (
														<ActivityAdded
															eventData={event}
														/>
													);
												case timelineTypes.ACTIVITY_TITLE_UPDATED:
													return (
														<ActivityTitleUpdated
															eventData={event}
														/>
													);
												case timelineTypes.ACTIVITY_NOTE_ADDED:
													return (
														<ActivityNoteAdded
															eventData={event}
														/>
													);
												case timelineTypes.ACTIVITY_NOTE_EDITED:
													return (
														<ActivityNoteEdited
															eventData={event}
														/>
													);
												case timelineTypes.EMAIL_SENT:
													return (
														<EmailSent
															eventData={event}
														/>
													);
												case timelineTypes.EMAIL_RECEIVED:
													return (
														<EmailReceived
															eventData={event}
														/>
													);
												case timelineTypes.EMAIL_REPLIED:
													return (
														<EmailReplied
															eventData={event}
														/>
													);
												case timelineTypes.CONTACT_SALES_OWNER_REASSIGNED:
												case timelineTypes.COMPANY_SALES_OWNER_REASSIGNED:
												case timelineTypes.DEAL_SALES_OWNER_REASSIGNED:
													return (
														<SalesOwnerReassigned
															eventData={event}
														/>
													);
												case timelineTypes.FOLLOWERS_ADDED:
													return (
														<FollowersAdded
															eventData={event}
														/>
													);
												case timelineTypes.FOLLOWERS_REMOVED:
													return (
														<FollowersRemoved
															eventData={event}
														/>
													);
												default:
													return null;
											}
										})()}
									</TimelineContent>
								</TimelineItem>
							))}
						</React.Fragment>
					))}
				</Timeline>
			) : (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="30vh"
				>
					<Typography fontSize={14} style={{ opacity: 0.6 }}>
						No records found
					</Typography>
				</Stack>
			)}

			<Box ref={lastRowRef}>
				{isFetchingMoreData && (
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="center"
						spacing={1}
						py={2}
					>
						<CircularProgress size={18} />

						<Typography fontSize={12}>Loading More Data</Typography>
					</Stack>
				)}
			</Box>
		</React.Fragment>
	);
}
