import React, { useCallback, useEffect, useState } from "react";
import TerritoryMenu from "../../../../../TerritoryMenu";

export default function TerritoryBaseComponent({
	onChange,
	value,
	inputRef,
	clearErrors,
	field,
	errors,
}) {
	const [selectedTerritory, setSelectedTerritory] = useState({
		name: "",
		value: "",
	});

	const updateTerritoryValue = useCallback(
		(updatedTerritoryValue) => {
			setSelectedTerritory(updatedTerritoryValue);
			onChange(updatedTerritoryValue?.value);
		},
		[onChange]
	);

	// This useEffect will run only initial render
	useEffect(() => {
		// Check if the server value needs to be formatted
		const isNotServerSideFormatted = value && typeof value === "object";
		if (isNotServerSideFormatted) {
			updateTerritoryValue(value);
		}
	}, [value, updateTerritoryValue]);

	const onSelectTerritory = (selectedValue) => {
		updateTerritoryValue(selectedValue);
		clearErrors(field.id);
	};

	const handleRemoveTerritory = () => {
		setSelectedTerritory({ name: "", value: "" });
		onChange("");
	};

	return (
		<React.Fragment>
			<TerritoryMenu
				inputRef={inputRef}
				error={!!errors[field.id]}
				onChange={onSelectTerritory}
				value={selectedTerritory}
				handleRemoveTerritory={handleRemoveTerritory}
			/>
		</React.Fragment>
	);
}
