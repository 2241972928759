import { useEffect, useState } from "react";
import MultiSelectBaseComponent from "../MultiSelectComponent/MultiSelectBaseComponent";
import { useWorkflowDropdownList } from "../../../../../hooks/services/workflow";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";

export default function TagsComponent(params) {
	const {
		field,
		error,
		style,
		updateFieldValue,
		value,
		clearError,
		isConditionField,
	} = params;

	const [selectedTags, setSelectedTags] = useState([]);
	const [searchValue, setSearchValue] = useState("");

	const {
		data: options,
		isLoading: isOptionsLoading,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useWorkflowDropdownList(field, searchValue);

	const formattedOptions = options?.map((option) => ({
		value: {
			name: option.name,
			id: option.id || option.value,
		},
	}));

	const handleChangeTags = (tags) => {
		setSelectedTags(tags);
		updateFieldValue(tags, field.fieldId);
		clearError(field.fieldId);
	};

	useEffect(() => {
		if (Array.isArray(value)) {
			setSelectedTags(value);
		}
	}, [value]);

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const updateSearchValue = (value) => {
		setSearchValue(value);
	};

	return (
		<>
			<MultiSelectBaseComponent
				style={style}
				error={error}
				isConditionField={isConditionField}
				selectedValues={selectedTags}
				options={formattedOptions || []}
				isFetching={isFetching}
				isOptionsLoading={isOptionsLoading}
				lastRowRef={lastRowRef}
				searchValue={searchValue}
				updateSearchValue={updateSearchValue}
				onFieldChange={handleChangeTags}
			/>
		</>
	);
}
