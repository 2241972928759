export const removeFieldsWithEmptyValues = (jsonObj) => {
	Object.keys(jsonObj).forEach((key) => {
		if (
			jsonObj[key] === null ||
			jsonObj[key] === "" ||
			jsonObj[key] === undefined
		) {
			delete jsonObj[key];
		} else if (Array.isArray(jsonObj[key]) && jsonObj[key].length === 0) {
			delete jsonObj[key];
		} else if (
			Object.prototype.toString.call(jsonObj[key]) === "[object Object]"
		) {
			removeFieldsWithEmptyValues(jsonObj[key]);
		} else if (Array.isArray(jsonObj[key])) {
			jsonObj[key].forEach((obj) => {
				if (!obj || Object.values(obj).some((value) => value === "")) {
					delete jsonObj[key];
				}
			});
		}
	});
};

export const removeFieldsWithIdKey = (jsonObj, fieldKey = "id") => {
	Object.keys(jsonObj).forEach((key) => {
		if (
			Object.prototype.toString.call(jsonObj[key]) === "[object Object]"
		) {
			removeFieldsWithIdKey(jsonObj[key]);
		} else if (Array.isArray(jsonObj[key])) {
			jsonObj[key].forEach((obj) => {
				removeFieldsWithIdKey(obj);
			});
		} else if (key === fieldKey) {
			delete jsonObj[key];
		}
	});
};

export const isEmptyObject = (obj) => {
	for (const prop in obj) {
		if (Object.hasOwn(obj, prop)) {
			return false;
		}
	}
	return true;
};

export const replaceEmptyFieldWithNullValues = (jsonObj) => {
	Object.keys(jsonObj).forEach((key) => {
		if (
			jsonObj[key] &&
			Object.prototype.toString.call(jsonObj[key]) === "[object Object]"
		) {
			replaceEmptyFieldWithNullValues(jsonObj[key]);
		} else if (Array.isArray(jsonObj[key]) && jsonObj[key].length === 0) {
			jsonObj[key] = null;
		} else if (jsonObj[key] === "" || jsonObj[key] === undefined) {
			jsonObj[key] = null;
		}
	});
};
