import React, { useState } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { default as ContactIcon } from "../../../assets/icons/contact";
import AddContact from "../../Contacts/AddContact";
import { twozoStyles } from "../../../styles/twozo";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import { removeFieldsWithEmptyValues } from "../../../utils/common";
import CheckDuplicates from "../CheckDuplicates";
import Can from "../../Auth/Can";
import { PERMISSIONS } from "../../../utils/Auth";
import { useModuleName } from "../../../hooks/modules";
import { modules } from "../../../utils/common/ModulesName";

export default function NoContactFound() {
	const classes = twozoStyles();
	const theme = useTheme();
	const [isAddContactDialogOpened, setIsAddContactDialogOpened] =
		useState(false);
	const [isDuplicateCheckEnabled, setIsDuplicateCheckEnabled] =
		useState(false);
	const [contactDetails, setContactDetails] = useState({});
	const { getModuleName } = useModuleName();
	let moduleName = getModuleName(modules.CONTACT);

	const toggleAddContactDialog = () => {
		setIsAddContactDialogOpened(
			(isAddContactDialogOpened) => !isAddContactDialogOpened
		);
	};
	const toggleCheckDuplicatesDialog = () => {
		setIsDuplicateCheckEnabled(
			(isDuplicateCheckEnabled) => !isDuplicateCheckEnabled
		);
	};
	const checkContactDuplicates = (contactData) => {
		removeFieldsWithEmptyValues(contactData);
		setContactDetails(contactData);
	};
	const onDuplicateMergeSuccess = () => {
		toggleAddContactDialog();
		toggleCheckDuplicatesDialog();
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isDuplicateCheckEnabled}
				onOpen={toggleCheckDuplicatesDialog}
				onClose={toggleCheckDuplicatesDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
				BackdropProps={{ invisible: true }}
			>
				<Box
					style={{ height: "100vh" }}
					className={classes.addDialogContainer}
				>
					<CheckDuplicates
						onClose={toggleCheckDuplicatesDialog}
						contactDetails={contactDetails}
						onDuplicateMergeSuccess={onDuplicateMergeSuccess}
					/>
				</Box>
			</CustomSwipeableDrawer>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddContactDialogOpened}
				onOpen={toggleAddContactDialog}
				onClose={toggleAddContactDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box
					className={classes.addDialogContainer}
					hidden={isDuplicateCheckEnabled}
				>
					<AddContact
						setIsDuplicateCheckEnabled={setIsDuplicateCheckEnabled}
						onClose={toggleAddContactDialog}
						checkContactDuplicates={checkContactDuplicates}
					/>
				</Box>
			</CustomSwipeableDrawer>
			<Stack
				alignItems="center"
				justifyContent="center"
				style={{
					position: "absolute",
					top: "45%",
					left: "42%",
					minWidth: "22%",
				}}
			>
				<Box sx={{ display: "flex" }} pb={1}>
					{ContactIcon(37, 37, "rgba(0, 0, 0, 0.6)")}
				</Box>
				<Typography
					fontSize={17}
					fontWeight={400}
					color="rgba(0, 0, 0, 0.6)"
				>
					No {moduleName} Found
				</Typography>
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					Try resetting your filters
					<Can permission={PERMISSIONS.contact.create}>
						<span> or </span>
						<span
							onClick={toggleAddContactDialog}
							style={{ color: theme.palette.primary.main }}
						>
							Create new {moduleName.toLowerCase()}
						</span>
					</Can>
				</Typography>
			</Stack>
		</React.Fragment>
	);
}
