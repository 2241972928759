import { default as ContactIcon } from "../../../assets/icons/contact";
import { default as CompanyIcon } from "../../../assets/icons/company";
import { default as DealIcon } from "../../../assets/icons/deals";
import { default as ProductIcon } from "../../../assets/icons/product";
import { default as ContactSyncIcon } from "../../../assets/icons/contactSync";
import { default as ConversationIcon } from "../../../assets/icons/conversation";
import { default as CRMIcon } from "../../../assets/icons/crm";
import { default as CustomIcon } from "../../../assets/icons/custom";
import { default as PlanBillIcon } from "../../../assets/icons/planBill";
import { default as ActivityGoalsIcon } from "../../../assets/icons/activityGoals";
import { default as ProbabilityIcon } from "../../../assets/icons/probability";
import { default as TemplatesIcon } from "../../../assets/icons/template";
import { default as KanbanIcon } from "../../../assets/icons/kanban";
import { default as WorkflowIcon } from "../../../assets/icons/workflow";
import { default as CurrencyIcon } from "../../../assets/icons/multipleCurrency";
import { default as AttachmentIcon } from "../../../assets/icons/attachment";
import { default as ActivityIcon } from "../../../assets/icons/addActivity";
import { default as HistoryIcon } from "../../../assets/icons/history";
import { default as DataIcon } from "../../../assets/icons/data";
import { default as GetInIcon } from "../../../assets/icons/getIn";
import { default as ImportUserIcon } from "../../../assets/icons/importUser";
import { default as NoteIcon } from "../../../assets/icons/note";
import { default as WhatsappIcon } from "../../../assets/icons/whatsapp";
import { default as FacebookIcon } from "../../../assets/icons/facebook";
import { default as InstagramIcon } from "../../../assets/icons/instagram";
import { default as MailIcon } from "../../../assets/icons/mail";
import { default as MailSyncIcon } from "../../../assets/icons/mailSync";
import { default as CalendarSyncIcon } from "../../../assets/icons/calendarSync";
import { default as NotificationPendingIcon } from "../../../assets/icons/notificationPending";
import { default as MailOrgIcon } from "../../../assets/icons/mailOrg";
import { default as MailOpenIcon } from "../../../assets/icons/mailOpen";
import { default as CallIcon } from "../../../assets/icons/call";
import { default as UserIcon } from "../../../assets/icons/user";
import { default as KeyIcon } from "../../../assets/icons/key";
import { default as TeamsIcon } from "../../../assets/icons/teams";
import { default as LocationIcon } from "../../../assets/icons/location";
import { modules } from "../../../utils/common/ModulesName";
import { useModuleName } from "../../modules";
import { useAuth } from "../../auth";
import { PERMISSIONS } from "../../../utils/Auth";
import { useRef } from "react";

// Modules and fields settings
const useModulesAndFieldsSettings = () => {
	const { getModuleName } = useModuleName();
	const { isUserAllowedFor } = useAuth();

	const modulesAndFieldsSettings = [
		{
			name: getModuleName(modules.CONTACT),
			link: "datafields?type=Contact",
			permission: isUserAllowedFor(PERMISSIONS.contact.view),
			icon: ContactIcon,
		},
		{
			name: getModuleName(modules.COMPANY),
			link: "datafields?type=Company",
			permission: isUserAllowedFor(PERMISSIONS.company.view),
			icon: CompanyIcon,
		},
		{
			name: getModuleName(modules.DEAL),
			link: "datafields?type=Deal",
			permission: isUserAllowedFor(PERMISSIONS.deal.view),
			icon: DealIcon,
		},
		{
			name: getModuleName(modules.PRODUCT),
			link: "datafields?type=Product",
			permission: isUserAllowedFor(PERMISSIONS.product.view),
			icon: ProductIcon,
		},
		{
			name: "Contact Life Cycle Stage",
			link: "lifecycle-stage",
			permission: true,
			icon: ContactSyncIcon,
		},
	];

	const filteredModulesAndFieldsSettings = modulesAndFieldsSettings.filter(
		(settings) => settings.permission
	);

	return filteredModulesAndFieldsSettings;
};

// Pipeline and goals settings
const usePipelineGoalSettings = () => {
	const { isUserAllowedFor } = useAuth();

	const pipelineAndGoalsSettings = [
		{
			name: "Pipelines",
			link: "",
			permission: isUserAllowedFor(PERMISSIONS.deal.view),
			icon: KanbanIcon,
		},
		{
			name: "Activity Goals",
			link: "",
			permission: isUserAllowedFor(PERMISSIONS.goal.view),
			icon: ActivityGoalsIcon,
		},
		{
			name: "Sales Goals",
			link: "",
			permission: true,
			icon: ProbabilityIcon,
		},
		{
			name: "Quotas and Forecasting ",
			link: "",
			permission: isUserAllowedFor(PERMISSIONS.quotasAndForecasting.view),
			icon: ConversationIcon,
		},
	];

	const filteredPipelineAndGoalsSettings = pipelineAndGoalsSettings.filter(
		(settings) => settings.permission
	);

	return filteredPipelineAndGoalsSettings;
};

// Workflow Automation settings
const useWorkflowAutomationSettings = () => {
	const workflowAutomationSettings = [
		{
			name: "Templates",
			link: "workflow-management?type=template",
			permission: true,
			icon: TemplatesIcon,
		},
		{
			name: "My Automations",
			link: "workflow-management?type=automation",
			permission: true,
			icon: WorkflowIcon,
		},
		{
			name: "History",
			link: "workflow-management?type=history",
			permission: true,
			icon: HistoryIcon,
		},
	];

	return workflowAutomationSettings;
};

// Import and migration
const useImportAndMigrationSettings = () => {
	const { isUserAllowedFor } = useAuth();

	const getImportPermissions = () => {
		return (
			isUserAllowedFor(PERMISSIONS.contact.importRecords) ||
			isUserAllowedFor(PERMISSIONS.company.importRecords) ||
			isUserAllowedFor(PERMISSIONS.deal.importRecords) ||
			isUserAllowedFor(PERMISSIONS.product.importRecords) ||
			isUserAllowedFor(PERMISSIONS.activity.importRecords)
		);
	};

	const importAndMigrationSettings = [
		{
			name: "Import",
			icon: DataIcon,
			link: "",
			permission: getImportPermissions(),
		},
		{
			name: "Migration",
			icon: GetInIcon,
			link: "",
			permission: isUserAllowedFor(PERMISSIONS.settings.migrateFromACrm),
		},
		{
			name: "User Import",
			icon: ImportUserIcon,
			link: "",
			permission: isUserAllowedFor(PERMISSIONS.user.access),
		},
		{
			name: "Webform",
			icon: NoteIcon,
			link: "webform",
			permission: true,
		},
	];

	const filteredImportAndMigrationSettings =
		importAndMigrationSettings.filter((settings) => settings.permission);

	return filteredImportAndMigrationSettings;
};

// Email settings
const useEmailSettings = () => {
	const { isUserAllowedFor } = useAuth();

	const emailSettings = [
		{
			name: "Calendar Sync",
			icon: CalendarSyncIcon,
			link: "email?type=calendar_sync",
			permission: true,
		},
		{
			name: "Email Sync",
			icon: MailSyncIcon,
			link: "email?type=email_sync",
			permission: isUserAllowedFor(
				PERMISSIONS.email.viewEmailConversation
			),
		},
		{
			name: "Contact Sync",
			icon: ContactSyncIcon,
			link: "email?type=contact_sync",
			permission: isUserAllowedFor(PERMISSIONS.contact.view),
		},
		{
			name: "Organization Emails",
			icon: MailOrgIcon,
			link: "email?type=organization_emails",
			permission: true,
		},
		{
			name: "Team Inbox",
			icon: MailOpenIcon,
			link: "team-inbox",
			permission: true,
		},
		{
			name: "Email Settings",
			icon: MailIcon,
			link: "email?type=email_settings",
			permission: isUserAllowedFor(
				PERMISSIONS.email.viewEmailConversation
			),
		},
		{
			name: "Notifications",
			icon: NotificationPendingIcon,
			link: "email?type=notifications",
			permission: true,
		},
	];

	const filteredEmailSettings = emailSettings.filter(
		(settings) => settings.permission
	);

	return filteredEmailSettings;
};

// Channel settings
const useChannelsSettings = () => {
	const channelsSettings = [
		{
			name: "",
			permission: useEmailSettings().length > 0,
			subSettings: {
				subSettingsName: "Emails",
				hasSubSettings: false,
				settings: useEmailSettings(),
			},
		},
		{
			name: "",
			permission: true,
			subSettings: {
				subSettingsName: "Phone",
				hasSubSettings: false,
				settings: [
					{
						name: "Phone Numbers",
						icon: CallIcon,
						link: "phone-numbers",
						permission: true,
					},
					{
						name: "Phone History",
						icon: HistoryIcon,
						link: "",
						permission: true,
					},
				],
			},
		},
		{
			name: "",
			permission: true,
			subSettings: {
				subSettingsName: "Chat & Messaging",
				hasSubSettings: false,
				settings: [
					{
						name: "Whatsapp",
						icon: WhatsappIcon,
						link: "",
						permission: true,
					},
					{
						name: "Facebook Messenger",
						icon: FacebookIcon,
						link: "",
						permission: true,
					},
					{
						name: "Instagram DM",
						icon: InstagramIcon,
						link: "",
						permission: true,
					},
					{
						name: "SMS",
						icon: ConversationIcon,
						link: "",
						permission: true,
					},
				],
			},
		},
	];

	const filteredChannelsSettings = channelsSettings.filter(
		(setting) => setting.permission
	);
	return filteredChannelsSettings;
};

// Users and permissions settings
const useUserPermissionSettings = () => {
	const { isUserAllowedFor } = useAuth();

	const usersAndPermissionsSettings = [
		{
			name: "User",
			icon: UserIcon,
			link: "user-management?type=users",
			permissions: isUserAllowedFor(PERMISSIONS.user.access),
		},
		{
			name: "Roles",
			icon: KeyIcon,
			link: "user-management?type=roles",
			permissions: isUserAllowedFor(PERMISSIONS.role.access),
		},
		{
			name: "Teams",
			icon: TeamsIcon,
			link: "user-management?type=teams",
			permissions: true,
		},
		{
			name: "Territories",
			icon: LocationIcon,
			link: "user-management?type=territory",
			permissions: isUserAllowedFor(PERMISSIONS.territory.access),
		},
	];

	const filteredUsersAndPermissionSettings =
		usersAndPermissionsSettings.filter((settings) => settings.permissions);

	return filteredUsersAndPermissionSettings;
};

// Account settings
const useAccountSettings = () => {
	const { isUserAllowedFor } = useAuth();

	const accountSettings = [
		{
			name: "CRM Settings",
			icon: CRMIcon,
			link: "account-settings?type=settings",
			permission: true,
		},
		{
			name: "Account",
			icon: CustomIcon,
			link: "account-settings?type=account",
			permission: true,
		},
		{
			name: "Plans & Billings",
			icon: PlanBillIcon,
			link: "account-settings?type=plans_and_billings",
			permission: true,
		},
		{
			name: "Currency",
			icon: CurrencyIcon,
			link: "currency",
			permission: true,
		},
		{
			name: "Tags",
			icon: AttachmentIcon,
			link: "tags",
			permission: isUserAllowedFor(PERMISSIONS.settings.tags),
		},
		{
			name: "Sales Activities",
			icon: ActivityIcon,
			link: "company/activities",
			permission: isUserAllowedFor(PERMISSIONS.activityType.view),
		},
	];

	const filteredAccountSettings = accountSettings.filter(
		(setting) => setting.permission
	);

	return filteredAccountSettings;
};

// Settings menu
const useSettingsMenu = () => {
	const modulesAndFieldsRef = useRef();
	const pipelineAndGoalsRef = useRef();
	const workflowAutomationRef = useRef();
	const importAndMigrationRef = useRef();
	const userAndPermissionRef = useRef();
	const accountSettingsRef = useRef();
	const channelRef = useRef();

	const { isUserAllowedFor } = useAuth();

	const settingsMenu = [
		{
			id: 1,
			name: "Modules & Fields",
			hasSubSettings: false,
			settings: useModulesAndFieldsSettings(),
			permission: true,
			ref: modulesAndFieldsRef,
		},
		{
			id: 2,
			name: "Pipeline & Goals",
			hasSubSettings: false,
			settings: usePipelineGoalSettings(),
			permission: true,
			ref: pipelineAndGoalsRef,
		},
		{
			id: 3,
			name: "Workflow Automation",
			hasSubSettings: false,
			settings: useWorkflowAutomationSettings(),
			permission: isUserAllowedFor(PERMISSIONS.settings.workFlow),
			ref: workflowAutomationRef,
		},
		{
			id: 4,
			name: "Leads, Data Import and Migration",
			hasSubSettings: false,
			settings: useImportAndMigrationSettings(),
			permission: true,
			ref: importAndMigrationRef,
		},
		{
			id: 5,
			name: "Channels",
			hasSubSettings: true,
			settings: useChannelsSettings(),
			permission: true,
			ref: channelRef,
		},
		{
			id: 6,
			name: "Users & Permissions",
			hasSubSettings: false,
			permission: useUserPermissionSettings().length > 0,
			settings: useUserPermissionSettings(),
			ref: userAndPermissionRef,
		},
		{
			id: 7,
			name: "Account Settings",
			hasSubSettings: false,
			permission: isUserAllowedFor(PERMISSIONS.settings.adminSettings),
			settings: useAccountSettings(),
			ref: accountSettingsRef,
		},
	];

	return settingsMenu;
};

export { useSettingsMenu };
