import { Stack, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import SingleDateTimePicker from "../../../../../SingleDateTimePicker";
import {
	convertDayjsToUnixTimeStamp,
	convertUnixTimeStampToDayjs,
} from "../../../../../../../utils/DateUtils";

export default function DateTimeRangeFieldBaseComponent({
	value,
	onChange,
	fromTextFieldSx,
	toTextFieldSx,
	onClearError,
	error,
}) {
	const theme = useTheme();
	const [dateTimeRangeValue, setDateTimeRangeValue] = useState({});

	useEffect(() => {
		if (value) {
			setDateTimeRangeValue(value);
		}
	}, [value]);

	const handleDateTimeRangeValueChange = (dateTimeRangeValue) => {
		if (onChange) {
			onChange(dateTimeRangeValue);
		}
	};

	const onStartDateTimeChange = (value) => {
		//here value is a dayjs object, valueOf gives unixTimestamp
		let newValue = {
			...dateTimeRangeValue,
			from: convertDayjsToUnixTimeStamp(value),
		};
		setDateTimeRangeValue(newValue);
		handleDateTimeRangeValueChange(newValue);
		onClearError();
	};

	const onEndDateTimeChange = (value) => {
		//here value is a dayjs object, valueOf gives unixTimestamp
		let newValue = {
			...dateTimeRangeValue,
			to: convertDayjsToUnixTimeStamp(value),
		};
		setDateTimeRangeValue(newValue);
		handleDateTimeRangeValueChange(newValue);
		onClearError();
	};

	return (
		<>
			<Stack
				direction="column"
				color={error && theme.palette.error.main}
				width="100%"
				spacing={0.5}
			>
				<SingleDateTimePicker
					textFieldProps={{
						sx: {
							width: "50%",
							"& .MuiOutlinedInput-notchedOutline": {
								borderRadius: "8px",
							},
							...fromTextFieldSx,
						},
						inputProps: {
							style: { textAlign: "left", fontSize: "14px" },
						},
					}}
					slotProps={{ inputAdornment: { position: "end" } }}
					popperProps={{ placement: "bottom-end" }}
					onChange={(value) => {
						onStartDateTimeChange(value);
					}}
					value={convertUnixTimeStampToDayjs(dateTimeRangeValue.from)}
					error={error}
				/>

				<SingleDateTimePicker
					textFieldProps={{
						sx: {
							width: "50%",
							"& .MuiOutlinedInput-notchedOutline": {
								borderRadius: "8px",
							},
							...toTextFieldSx,
						},
						inputProps: {
							style: { textAlign: "left", fontSize: "14px" },
						},
					}}
					slotProps={{ inputAdornment: { position: "end" } }}
					popperProps={{ placement: "bottom-end" }}
					onChange={(value) => {
						onEndDateTimeChange(value);
					}}
					value={convertUnixTimeStampToDayjs(dateTimeRangeValue.to)}
					minDate={convertUnixTimeStampToDayjs(
						dateTimeRangeValue.from
					)} //Prevents the selection of all values before minDate
					error={error}
				/>
			</Stack>
		</>
	);
}
