import { useCallback } from "react";
import { useFilterSaveViewContext } from "../../components/Elements/Table/Filter/Context/FilterSaveViewContext";
import { useFilterDataContext } from "../../components/Elements/Table/Filter/Context/FilterDataContext";
import {
	getApplyFilterRequestData,
	isCriteriaValid,
} from "../../utils/FilterUtills";

const useApplyFilter = (onClose) => {
	const { showSaveViewAsButton } = useFilterSaveViewContext();
	const { filterState, filterListByCondition, updateFilterUICriteriaList } =
		useFilterDataContext();

	const applyFilter = useCallback(() => {
		const validFilters =
			filterState.filterUICriteriaList.currentCriteria.filter(
				isCriteriaValid
			);
		updateFilterUICriteriaList(validFilters, validFilters);

		let requestData = getApplyFilterRequestData(validFilters);
		filterListByCondition({ criteria: requestData.criteriaList });
		showSaveViewAsButton();
		if (onClose) onClose();
	}, [
		onClose,
		filterState,
		filterListByCondition,
		updateFilterUICriteriaList,
		showSaveViewAsButton,
	]);

	return { applyFilter };
};

export default useApplyFilter;
