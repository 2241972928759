import React, { useEffect, useState } from "react";
import {
	Box,
	CircularProgress,
	Collapse,
	Divider,
	MenuItem,
	Select,
	Stack,
	ToggleButton,
	Typography,
} from "@mui/material";
import MenuSearchBar from "../../../../Elements/MenuSearchBar";
import ToggleButtonGroup from "../../../../Elements/ToggleButtonGroup";
import { default as DropdownIcon } from "../../../../../assets/icons/dropDownCentered";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { MenuItemText } from "../../../../../styles/twozo";

const StepOptionList = ({
	option,
	selected,
	onFieldChange,
	onClose,
	hasSelectedValue,
}) => {
	const [listOpened, setListOpened] = useState(hasSelectedValue);

	const handleValueChange = (field) => {
		onFieldChange({
			dependsOn: option.dependsOn,
			value: field,
		});
		onClose();
		setListOpened(false);
	};

	return (
		<React.Fragment>
			<MenuItem
				style={{ minHeight: "40px" }}
				onClick={() => setListOpened((listOpened) => !listOpened)}
			>
				<Stack direction="row" alignItems="center" spacing={0.5}>
					<Typography fontSize={14}>{option.source}</Typography>
					<Box pt={0.5}>{DropdownIcon(14, 14, "#000")}</Box>
				</Stack>
			</MenuItem>

			<Collapse in={listOpened} timeout="auto" unmountOnExit>
				{option.fields.map((field, index) => (
					<MenuItem
						key={index}
						selected={field?.id === selected?.value?.id}
						style={{ minHeight: "40px" }}
						onClick={() => {
							handleValueChange(field);
						}}
					>
						<MenuItemText pl={4}>{field.name}</MenuItemText>
					</MenuItem>
				))}
			</Collapse>
		</React.Fragment>
	);
};

export default function DropdownBaseComponent(props) {
	const {
		options,
		selected,
		hasStepOptions,
		stepOptions,
		onFieldChange,
		multiDropdown,
		error,
		lastRowRef,
		isFetching,
		updateSearchValue = () => {},
		isOptionsLoading,
		searchValue,
		style = {},
	} = props;

	const [optionType, setOptionType] = useState("field_options");
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	useEffect(() => {
		if (selected && selected.dependsOn) {
			setOptionType("step_options");
		} else {
			setOptionType("field_options");
		}
	}, [selected]);

	const getSelectedValue = (selected) => {
		if (!selected) return "";
		const { value } = selected;

		if (typeof value === "string") {
			return value || "";
		}
		if (typeof value === "object") {
			return value?.name || "";
		}

		return "";
	};

	const handleSearchValue = (event) => {
		const { value } = event.target;
		updateSearchValue(value);
	};

	const handleOptionsType = (_, type) => {
		if (type) {
			setOptionType(type);
		}
	};

	const renderEmptyView = (noOptionsFound = false) => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					{options?.length === 0 || noOptionsFound
						? "No Options Found"
						: "No Results Found"}
				</Typography>
			</Stack>
		);
	};

	const handleValueChange = (event) => {
		let fieldValue = {};

		if (multiDropdown) {
			for (let index = 0; index < options?.length; index++) {
				let value = options?.[index]?.options?.find(
					(option) => option.value === event.target.value
				);
				if (value) {
					fieldValue = {
						value: value,
					};
					break;
				}
			}
		} else {
			fieldValue = {
				value: options.find(
					(option) => option.value === event.target.value
				),
			};
		}
		onFieldChange(fieldValue);
	};

	const handleClose = () => {
		setIsDropdownOpen(false);
	};

	const handleClearSelectedField = (event) => {
		event.stopPropagation();
		onFieldChange(null);
		setIsDropdownOpen(false);
	};

	const onOpenDropdown = () => {
		updateSearchValue("");
		setIsDropdownOpen(true);
	};

	return (
		<React.Fragment>
			<Select
				value={selected?.value?.value || ""}
				open={isDropdownOpen}
				displayEmpty
				MenuProps={{
					autoFocus: false,
					PaperProps: {
						style: {
							maxHeight: 350,
						},
					},
				}}
				onOpen={onOpenDropdown}
				onClose={handleClose}
				onChange={handleValueChange}
				renderValue={() => (
					<Typography
						fontSize={15}
						color={
							getSelectedValue(selected)
								? "#000"
								: "rgba(0, 0, 0, 0.4)"
						}
					>
						{getSelectedValue(selected)
							? getSelectedValue(selected)
							: "-Select-"}
					</Typography>
				)}
				fullWidth
				sx={{
					backgroundColor: "#fff",
					...style,
				}}
				error={error?.error}
			>
				{hasStepOptions && (
					<Stack direction="row" justifyContent="center" pb={0.5}>
						<ToggleButtonGroup
							value={optionType}
							onChange={handleOptionsType}
						>
							<ToggleButton value="field_options">
								Field Options
							</ToggleButton>

							<ToggleButton value="step_options">
								Step Options
							</ToggleButton>
						</ToggleButtonGroup>
					</Stack>
				)}

				{optionType === "step_options" &&
					(stepOptions.length > 0
						? stepOptions.map((option, index) => {
								const hasSelectedValue =
									selected &&
									Array.isArray(option.fields) &&
									option.fields.some(
										(field) =>
											field?.id === selected?.value?.id
									);
								return (
									<StepOptionList
										key={index}
										option={option}
										onClose={handleClose}
										hasSelectedValue={hasSelectedValue}
										selected={selected}
										onFieldChange={onFieldChange}
									/>
								);
							})
						: renderEmptyView(true))}

				{optionType === "field_options" && (
					<Box
						p={0.5}
						sx={{
							position: "sticky",
							top: 0,
							zIndex: 1,
							backgroundColor: "#fff",
						}}
					>
						<MenuSearchBar
							autoFocus
							value={searchValue}
							onChange={handleSearchValue}
						/>
					</Box>
				)}

				{optionType === "field_options" &&
					(isOptionsLoading ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="300px"
						>
							<CircularProgress size="25px" />
						</Stack>
					) : options.length > 0 ? (
						options?.map((option, index) => {
							if (!option.options) {
								return (
									<MenuItem
										value={option.value || ""}
										key={index}
										style={{
											minHeight: "40px",
											width: "280px",
										}}
									>
										<Stack
											width="100%"
											direction="row"
											justifyContent="space-between"
										>
											<MenuItemText noWrap>
												{option.name}
											</MenuItemText>

											{selected?.value?.id ===
												option?.id && (
												<Box
													display="flex"
													onClick={
														handleClearSelectedField
													}
												>
													{CloseIcon(
														20,
														20,
														"#000",
														0.6
													)}
												</Box>
											)}
										</Stack>
									</MenuItem>
								);
							} else {
								let components = [];

								components.push(
									<Typography
										fontSize={14}
										fontWeight={500}
										px={1}
										py={0.5}
									>
										{option.name}
									</Typography>
								);

								option.options.forEach((option, index) => {
									components.push(
										<MenuItem
											value={option.value}
											key={index}
											style={{ minHeight: "40px" }}
										>
											<MenuItemText pl={2}>
												{option.name}
											</MenuItemText>
										</MenuItem>
									);
								});

								components.push(
									<Divider
										sx={{ margin: "0px !important" }}
									/>
								);

								return components;
							}
						})
					) : (
						renderEmptyView()
					))}

				{optionType === "field_options" && (
					<Box ref={lastRowRef}>
						{!isOptionsLoading &&
							isFetching &&
							options?.length > 0 && (
								<MenuItem
									style={{ height: "40px", width: "100%" }}
								>
									<Stack
										width="100%"
										direction="row"
										alignItems="center"
										justifyContent="center"
										spacing={1}
										py={2}
									>
										<CircularProgress size={18} />

										<Typography fontSize={12}>
											Loading More Data
										</Typography>
									</Stack>
								</MenuItem>
							)}
					</Box>
				)}
			</Select>
		</React.Fragment>
	);
}
