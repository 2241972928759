import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { handleFieldValidation } from "../../../../utils/fieldValidation";
import { useCallback, forwardRef, useImperativeHandle } from "react";
import { useUsers } from "../../../../hooks/account/user";
import UsersMenu from "../../UsersMenu";

const userMenuStyle = {
	"& .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&:hover .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
};

const SalesOwnerEditComponent = forwardRef(
	({ value, field, onError, onSave, onClose }, ref) => {
		const theme = useTheme();
		const fieldId = field?.fieldId;
		const removeActionEnabled = !field?.config?.isDefaultField;

		const {
			clearErrors,
			control,
			formState: { errors },
			handleSubmit,
			setValue,
		} = useForm({ values: { [fieldId]: value }, reValidateMode: false });

		const [selectedUser, setSelectedUser] = useState(null);

		const { loggedInUser } = useUsers();

		const updateUserValue = useCallback(
			(value, name) => {
				setSelectedUser({
					value: value,
					name: name,
				});
				setValue(fieldId + "", value);
			},
			[fieldId, setValue]
		);

		const handleOwnerInputChange = (selectedUser) => {
			updateUserValue(selectedUser?.value, selectedUser?.name);
			clearErrors(fieldId + "");
			onError(null);
		};

		const isDefaultField = field?.config?.isDefaultField;
		useEffect(() => {
			if (value && value?.name) {
				updateUserValue(value?.id, value.name);
			} else if (!value && loggedInUser && isDefaultField) {
				updateUserValue(loggedInUser?.value, loggedInUser?.name);
			}
		}, [isDefaultField, loggedInUser, updateUserValue, value]);

		const submitForm = useCallback(() => {
			return handleSubmit(
				(data) => {
					onSave(data, onClose);
					onError(null);
					return data;
				},
				(errors) => {
					if (errors[fieldId]) {
						onError(errors[fieldId]);
					}
				}
			)();
		}, [handleSubmit, fieldId, onError, onSave, onClose]);

		useImperativeHandle(ref, () => ({
			submitForm,
		}));

		const handleRemove = (onChange) => {
			setSelectedUser(null);
			onChange(null);
		};

		return (
			<React.Fragment>
				<Controller
					control={control}
					name={fieldId + ""}
					defaultValue=""
					rules={handleFieldValidation(field)}
					render={({ field: { onChange } }) => (
						<UsersMenu
							value={selectedUser}
							onChange={handleOwnerInputChange}
							userMenuStyle={userMenuStyle}
							handleRemoveUser={() => handleRemove(onChange)}
							removeActionEnabled={removeActionEnabled}
						/>
					)}
				/>
				{errors[fieldId] ? (
					<Typography
						pl={1}
						pb={1.2}
						style={{
							fontSize: 13,
							marginLeft: "6px",
							color: theme.palette.error.main,
						}}
					>
						{errors[fieldId]?.message}
					</Typography>
				) : null}
			</React.Fragment>
		);
	}
);

SalesOwnerEditComponent.displayName = "SalesOwnerEditComponent";

export default SalesOwnerEditComponent;
