import { apiClient } from "..";
import { getLifecycleStageUrl } from "../apiConfig";
import { assertError } from "../apiHelper";
import postFieldsApi from "../fields/postFieldsApi";

export const getAllLifecycleStage = async () => {
	let requestData = getAllLifecycleStageRequest();
	return await postLifecycleStageManagementApi(requestData);
};

export const updateStageOrder = async (stageIds) => {
	let requestData = getUpdateStageOrderRequest(stageIds);
	return await postLifecycleStageManagementApi(requestData);
};

export const updateStatusOrder = async (statusIds) => {
	let requestData = getUpdateStatusOrderRequest(statusIds);
	return await postLifecycleStatusManagementApi(requestData);
};

export const deleteLifecycleStage = async (stageId) => {
	let requestData = getDeleteStageRequest(stageId);
	return await postLifecycleStageManagementApi(requestData);
};

export const getRules = async () => {
	let requestData = getRulesRequest();
	return await postLifecycleStageManagementApi(requestData);
};

export const updateLifecycleStage = async (stageData) => {
	let requestData = getUpdateLifecycleStage(stageData);
	return await postLifecycleStageManagementApi(requestData);
};

export const updateLifecycleStageStatus = async ({ isActive, id }) => {
	let requestData = getUpdateLifecycleStageStatusRequest(isActive, id);
	return await postLifecycleStageManagementApi(requestData);
};

export const createLifecycleStage = async (stageData) => {
	let requestData = getCreateLifecycleStageRequest(stageData);
	return await postLifecycleStageManagementApi(requestData);
};

export const updateRules = async (rules) => {
	let requestData = getUpdateRulesRequest(rules);
	return await postLifecycleStageManagementApi(requestData);
};

export const getLifecycleStatusList = async (lifecycleStageId, contactId) => {
	let requestData = getLifecycleStatusListRequest(
		lifecycleStageId,
		contactId
	);
	return await postFieldsApi(requestData);
};

const getAllLifecycleStageRequest = () => {
	return {
		type: "getAllStages",
		data: {},
	};
};

const getUpdateStageOrderRequest = (stageIds) => {
	return {
		type: "updateOrder",
		data: {
			ids: stageIds,
		},
	};
};

const getUpdateStatusOrderRequest = (statusIds) => {
	return {
		type: "updateOrder",
		data: {
			ids: statusIds,
		},
	};
};

const getDeleteStageRequest = (stageId) => {
	return {
		type: "delete",
		data: {
			id: stageId,
		},
	};
};

const getRulesRequest = () => {
	return {
		type: "getRules",
		data: {},
	};
};

const getUpdateLifecycleStage = (stageData) => {
	return {
		type: "update",
		data: stageData,
	};
};

const getUpdateLifecycleStageStatusRequest = (isActive, id) => {
	return {
		type: "updateStatus",
		data: {
			id: id,
			isActive: isActive,
		},
	};
};

const getCreateLifecycleStageRequest = (stageData) => {
	return {
		type: "create",
		data: stageData,
	};
};

const getUpdateRulesRequest = (rules) => {
	return {
		type: "updateRules",
		data: {
			rules: rules,
		},
	};
};

const getLifecycleStatusListRequest = (lifecycleStageId, contactId) => {
	return {
		type: "dropdownData",
		data: {
			name: "contactLifeCycleStatusList",
			lifeCycleStageId: lifecycleStageId,
			contactId: contactId,
		},
	};
};

const postLifecycleStageManagementApi = async (requestData) => {
	let lifecycleStageUrl = getLifecycleStageManagementUrl();
	let response = await apiClient.post(lifecycleStageUrl, requestData);
	assertError(response, lifecycleStageUrl);
	return response.data.data;
};

const postLifecycleStatusManagementApi = async (requestData) => {
	let lifecycleStatusUrl = getLifecycleStatusManagementUrl();
	let response = await apiClient.post(lifecycleStatusUrl, requestData);
	assertError(response, lifecycleStatusUrl);
	return response.data.data;
};

const getLifecycleStageManagementUrl = () => {
	return getLifecycleStageUrl() + "/stage/mgmt";
};

const getLifecycleStatusManagementUrl = () => {
	return getLifecycleStageUrl() + "/status/mgmt";
};
