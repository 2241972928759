import MultiSelectComponent from "../../../../AddForm/Field/FieldComponents/MultiSelectComponent";
import { getFieldGroupStyle } from "../../../styles";

export default function MultiSelectFieldComponent({
	field,
	isGroupedFieldBottom,
	isGroupedFieldTop,
}) {
	const isGroupField = field?.config?.isGroupedField;

	const groupFieldStyles = isGroupField
		? {
				"& .MuiOutlinedInput-root": {
					"& fieldset": getFieldGroupStyle(
						isGroupedFieldTop,
						isGroupedFieldBottom
					),
					"&:hover fieldset": getFieldGroupStyle(
						isGroupedFieldTop,
						isGroupedFieldBottom
					),
					"&.Mui-focused fieldset": getFieldGroupStyle(
						isGroupedFieldTop,
						isGroupedFieldBottom
					),
				},
			}
		: {};

	return (
		<MultiSelectComponent
			field={field}
			fieldSpecificProps={{
				multiSelectSx: groupFieldStyles,
			}}
		/>
	);
}
