import React, { useEffect, useMemo, useState } from "react";
import DropdownBaseComponent from "../DropdownBaseComponent";
import { useQuery } from "@tanstack/react-query";
import { getActionStepOptionKey } from "../../../../../utils/queryKeys/workflow";
import { getActionStepOptionData } from "../../../../../api/workflow/workflowApi";
import { useWorkflowDropdownList } from "../../../../../hooks/services/workflow";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";

export default function DropdownComponent(props) {
	const {
		field,
		metadata,
		style,
		error,
		updateFieldValue,
		formValues,
		value,
		clearError,
	} = props;
	const [selected, setSelected] = useState("");

	const dependentDropdownValue = formValues?.[field?.config?.dependsOn];
	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		if (value) {
			setSelected(value);
		} else {
			setSelected("");
		}
	}, [value]);

	const {
		data: optionsList,
		isLoading: isOptionsLoading,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useWorkflowDropdownList(
		field,
		searchValue,
		dependentDropdownValue,
		formValues
	);

	const formattedOptions = useMemo(() => {
		if (!Array.isArray(optionsList) || optionsList.length === 0) return [];

		return optionsList
			.map((option) => ({
				...option,
				value: option.id || option.value,
				id: option.value || option.id,
			}))
			.filter((option) =>
				field?.dropdown?.name === "userList"
					? option?.config?.isActive
					: true
			);
	}, [field, optionsList]);

	const { data: stepOptions } = useQuery(
		getActionStepOptionKey(metadata.triggerSourceId, field.fieldId, [
			{ source: metadata.actionSource, event: metadata.actionEvent },
		]),
		() =>
			getActionStepOptionData(metadata.triggerSourceId, field.fieldId, [
				{ source: metadata.actionSource, event: metadata.actionEvent },
			]),
		{
			enabled: !!(
				!metadata ||
				(field?.fieldId && field?.config?.hasStepOptions)
			),
			select: (data) => data?.stepOptions,
		}
	);

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const onFieldChange = (value) => {
		setSelected(value);
		updateFieldValue(value?.value ? [value] : "", field.fieldId);
		clearError(field.fieldId);
	};

	const updateSearchValue = (value) => {
		setSearchValue(value);
	};

	return (
		<React.Fragment>
			<DropdownBaseComponent
				hasStepOptions={field?.config?.hasStepOptions}
				selected={selected}
				options={formattedOptions || []}
				onFieldChange={onFieldChange}
				multiDropdown={field.fieldType === "MULTI_DROPDOWN"}
				stepOptions={stepOptions || []}
				style={style}
				error={error}
				searchValue={searchValue}
				isFetching={isFetching}
				isOptionsLoading={isOptionsLoading}
				lastRowRef={lastRowRef}
				updateSearchValue={updateSearchValue}
			/>
		</React.Fragment>
	);
}
