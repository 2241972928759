import { useEffect, useMemo, useState } from "react";
import { useStepOptions } from "../../../../../hooks/services/workflow";
import MultiSelectBaseComponent from "../MultiSelectComponent/MultiSelectBaseComponent";
import { useContactList } from "../../../../../hooks/services/contact";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";

export default function RelatedContacts(params) {
	const {
		field = {},
		error,
		metadata,
		updateFieldValue,
		value,
		clearError,
		isConditionField,
	} = params;
	const [selectedValues, setSelectedValues] = useState([]);
	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		if (Array.isArray(value)) {
			setSelectedValues(value);
		}
	}, [value]);

	const {
		data: contacts,
		isLoading: isLoadingContacts,
		isFetching: isFetchingContacts,
		hasNextPage: hasNextPage,
		fetchNextPage: fetchNextPage,
	} = useContactList(true, searchValue);

	const { data: stepOptions } = useStepOptions(metadata, field);

	const formattedOptions = useMemo(() => {
		return (
			contacts?.map((option) => ({
				value: {
					name: option.name,
					id: option.id || option.value,
				},
			})) || []
		);
	}, [contacts]);

	const handleFieldChange = (value) => {
		setSelectedValues(value);
		updateFieldValue(value, field.fieldId);
		clearError(field.fieldId);
	};

	const updateSearchValue = (value) => {
		setSearchValue(value);
	};
	const lastRowRef = useLastRowRef(
		fetchNextPage,
		hasNextPage,
		isFetchingContacts
	);

	return (
		<MultiSelectBaseComponent
			options={formattedOptions || []}
			error={error}
			isConditionField={isConditionField}
			stepOptions={stepOptions || []}
			selectedValues={selectedValues}
			onFieldChange={handleFieldChange}
			isFetching={isFetchingContacts}
			isOptionsLoading={isLoadingContacts}
			lastRowRef={lastRowRef}
			searchValue={searchValue}
			updateSearchValue={updateSearchValue}
			hasStepOptions={field?.config?.hasStepOptions}
		/>
	);
}
