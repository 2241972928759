import React from "react";
import {
	Box,
	Button,
	Divider,
	Grid,
	IconButton,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { FormFieldName, MenuItemText } from "../../../../styles/twozo";
import { useForm } from "react-hook-form";
import { getDropdownDataByName } from "../../../../utils/dropdownData";
import { useUpdatePhoneNumber } from "../../../../hooks/services/phone";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";

export default function EditPhoneNumber(props) {
	const { phoneNumberFormData, onClose } = props;
	const theme = useTheme();
	const recordTypeList = getDropdownDataByName("CALL_RECORD_TYPES");

	const updatePhoneNumberMutation = useUpdatePhoneNumber();

	const {
		watch,
		register,
		formState: { errors },
		clearErrors,
		handleSubmit,
		setValue,
	} = useForm({
		values: phoneNumberFormData,
		reValidateMode: false,
	});

	const onUpdatePhoneNumber = (updatedFormData) => {
		updatePhoneNumberMutation.mutate(updatedFormData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.updatePhoneNumber,
				});
				onClose();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack direction="row" justifyContent="space-between">
						<Typography fontWeight={600} fontSize={17}>
							Edit Number
						</Typography>

						<IconButton
							size="small"
							style={{ padding: 0 }}
							onClick={onClose}
						>
							{CloseIcon(24, 24, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<Divider />

				<Box
					p={2}
					style={{
						flex: "1 1 auto",
						minHeight: 0,
						overflowY: "auto",
					}}
				>
					<Grid
						container
						direction="row"
						alignItems="baseline"
						justifyContent="center"
						spacing={2}
					>
						<Grid item xs={12}>
							<Stack spacing={0.5}>
								<FormFieldName
									color={
										errors.displayName &&
										theme.palette.error.main
									}
								>
									Display Name
									<span
										style={{
											color: theme.palette.error.main,
											marginLeft: "5px",
										}}
									>
										*
									</span>
								</FormFieldName>

								<TextField
									placeholder="Enter"
									{...register("displayName", {
										required: "This field is mandatory",
									})}
									onChange={() => {
										clearErrors("displayName");
									}}
									error={Boolean(errors.displayName)}
								/>

								{errors.displayName ? (
									<Typography
										fontSize={13}
										fontWeight={500}
										color={theme.palette.error.main}
										pl={1}
									>
										{errors.displayName.message}
									</Typography>
								) : null}
							</Stack>
						</Grid>

						<Grid item xs={12}>
							<Stack spacing={0.5}>
								<FormFieldName
									color={
										errors.recordType &&
										theme.palette.error.main
									}
								>
									Record Type
									<span
										style={{
											color: theme.palette.error.main,
											marginLeft: "5px",
										}}
									>
										*
									</span>
								</FormFieldName>

								<Select
									displayEmpty
									value={watch("recordType") || ""}
									{...register("recordType", {
										onChange: (event) =>
											setValue(
												"recordType",
												event.target.value
											),
									})}
								>
									{recordTypeList.map((recordType) => (
										<MenuItem
											key={recordType.value}
											value={recordType.value}
											style={{ minHeight: "40px" }}
										>
											<MenuItemText whiteSpace="wrap">
												{recordType.name}
											</MenuItemText>
										</MenuItem>
									))}
								</Select>
							</Stack>
						</Grid>
					</Grid>
				</Box>

				<Divider />

				<Box p={2}>
					<Button
						variant="contained"
						color="primary"
						sx={{
							"&.Mui-disabled": {
								backgroundColor: theme.palette.primary.main,
								color: "rgba(255,255,255, 0.6)",
							},
						}}
						disableElevation
						// disabled={isBuyNumberButtonDisabled}
						onClick={handleSubmit(onUpdatePhoneNumber)}
					>
						Update
					</Button>
				</Box>
			</Box>
		</React.Fragment>
	);
}
