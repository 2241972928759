import React, { useEffect, useMemo, useState } from "react";
import DropdownBaseComponent from "../DropdownBaseComponent";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";
import { useUsers } from "../../../../../hooks/account/user";

export default function OwnerComponent(props) {
	const { field, style, updateFieldValue, error, value, clearError } = props;
	const [selected, setSelected] = useState(null);
	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		if (value) {
			setSelected(value);
		} else {
			setSelected(null);
		}
	}, [value]);

	const {
		activeUserList,
		isLoadingUsers,
		isFetchingUsers,
		hasNextUsersPage,
		fetchNextUsersPage,
	} = useUsers(searchValue);

	const lastOptionRef = useLastRowRef(
		fetchNextUsersPage,
		hasNextUsersPage,
		isFetchingUsers
	);

	const formattedOptions = useMemo(() => {
		if (Array.isArray(activeUserList) && activeUserList.length > 0) {
			return activeUserList.map((option) => {
				if (option.id) {
					option["value"] = option["id"];
				} else if (option.value) {
					option["id"] = option["value"];
				}
				return option;
			});
		}

		return [];
	}, [activeUserList]);

	const onFieldChange = (value) => {
		setSelected(value);
		updateFieldValue([value], field.fieldId);
		clearError(field.fieldId);
	};

	const updateSearchValue = (value) => {
		setSearchValue(value);
	};

	return (
		<React.Fragment>
			<DropdownBaseComponent
				selected={selected}
				options={formattedOptions || []}
				onFieldChange={onFieldChange}
				style={style}
				error={error}
				isFetching={isFetchingUsers}
				isOptionsLoading={isLoadingUsers}
				lastRowRef={lastOptionRef}
				searchValue={searchValue}
				updateSearchValue={updateSearchValue}
			/>
		</React.Fragment>
	);
}
