import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import {
	getTeamUsersAndUsersListKey,
	getTerritoryDataKey,
	getTerritoryTableDataKey,
	getTerritoryTableDataWithSortKey,
	getTerritoryUserListKey,
	getTerritoryDropdownListKey,
} from "../../../../utils/queryKeys/userManagement/territory";
import {
	createTerritory,
	deleteTerritory,
	getTeamUsers,
	getTerritoryData,
	getTerritoryDropdownList,
	getTerritoryList,
	getTerritoryUsersList,
	updateTerritory,
} from "../../../../api/userManagement/territory/territory";
import {
	usersOrTeamsDropdownListPageSize,
	territoryDropdownListPageSize,
	territoryListPageSize,
} from "../../../../utils/queryConstants/userManagement/Territory";

const useTerritoryList = (fieldToSort, enabled) => {
	return useInfiniteQuery({
		queryKey: getTerritoryTableDataWithSortKey(fieldToSort),
		queryFn: ({ pageParam }) => {
			return getTerritoryList(fieldToSort, {
				start: pageParam
					? (pageParam - 1) * territoryListPageSize + 1
					: 1,
				limit: territoryListPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		enabled: enabled,
	});
};

const selectTerritory = (territory) => {
	return territory.pages.flatMap((page) => page?.options);
};

const useTerritoryDropdownList = (searchValue) => {
	return useInfiniteQuery({
		queryKey: getTerritoryDropdownListKey(searchValue),
		queryFn: ({ pageParam }) => {
			return getTerritoryDropdownList(
				{
					start: pageParam
						? (pageParam - 1) * territoryDropdownListPageSize + 1
						: 1,
					limit: territoryDropdownListPageSize,
				},
				searchValue
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: selectTerritory,
	});
};

const useTerritoryUsersListData = (territoryId) => {
	let territoryUsersListKey = getTerritoryUserListKey(territoryId);
	return useQuery(
		territoryUsersListKey,
		() => getTerritoryUsersList(territoryId),
		{
			select: (data) => data.users,
			enabled: !!territoryId,
		}
	);
};

const useTerritoryData = (territoryId) => {
	let territoryDataKey = getTerritoryDataKey(territoryId);
	return useQuery(territoryDataKey, () => getTerritoryData(territoryId), {
		select: (data) => data.territory,
		enabled: !!territoryId,
	});
};

const useInvalidateTerritoryList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getTerritoryTableDataKey());
	};
};

const useInvalidateTerritoryUserList = (territoryId) => {
	let queryClient = useQueryClient();
	let territoryUserListKey = getTerritoryUserListKey(territoryId);
	return () => {
		queryClient.invalidateQueries(territoryUserListKey);
	};
};

const useInvalidateTerritoryData = (territoryId) => {
	let queryClient = useQueryClient();
	let territoryDataKey = getTerritoryDataKey(territoryId);
	return () => {
		queryClient.invalidateQueries(territoryDataKey);
	};
};

const useCreateTerritory = () => {
	let invalidateTerritoryList = useInvalidateTerritoryList();
	return useMutation(createTerritory, {
		onSuccess: () => {
			invalidateTerritoryList();
		},
	});
};

const useUpdateTerritory = (territoryId) => {
	let invalidateTerritoryList = useInvalidateTerritoryList();
	let invalidateTerritoryData = useInvalidateTerritoryData(territoryId);
	let invalidateUserList = useInvalidateTerritoryUserList(territoryId);
	return useMutation(updateTerritory, {
		onSuccess: () => {
			invalidateTerritoryList();
			invalidateTerritoryData();
			invalidateUserList();
		},
	});
};

const useDeleteTerritory = () => {
	let invalidateTerritoryList = useInvalidateTerritoryList();
	return useMutation(deleteTerritory, {
		onSuccess: () => {
			invalidateTerritoryList();
		},
	});
};

const usersAndTeamsSelect = (users) => {
	const lastIndex = users?.pages.length - 1;
	const userAndTeamsData = {
		totalRecords: users?.pages[lastIndex]?.totalRecords,
		hasMore: users?.pages[lastIndex]?.hasMore,
		users: [],
		teams: [],
	};

	userAndTeamsData.users = users?.pages.flatMap((page) => page?.users || []);
	userAndTeamsData.teams = users?.pages.flatMap((page) => page?.teams || []);

	return userAndTeamsData;
};

const useTeamUsersAndUsersList = (searchValue) => {
	return useInfiniteQuery({
		queryKey: getTeamUsersAndUsersListKey(searchValue),
		queryFn: ({ pageParam }) => {
			return getTeamUsers(searchValue, {
				start: pageParam
					? (pageParam - 1) * usersOrTeamsDropdownListPageSize + 1
					: 1,
				limit: usersOrTeamsDropdownListPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: usersAndTeamsSelect,
	});
};

export {
	useTerritoryList,
	useTerritoryUsersListData,
	useCreateTerritory,
	useUpdateTerritory,
	useDeleteTerritory,
	useTerritoryData,
	useTerritoryDropdownList,
	useTeamUsersAndUsersList,
};
