import React, { useEffect, useState } from "react";
import BoardHeading from "../../BoardHeading";
import DealCardList from "../DealCardList";
import { Droppable } from "react-beautiful-dnd";
import { Stack } from "@mui/material";
import { useDealsByStage } from "../../../../hooks/services/deal";
import { useLastRowRef } from "../../../../hooks/common/InfiniteScroll";
import DealCardLoader from "../../../Elements/Loader/DealCardLoader";

const styles = {
	table: {
		borderCollapse: "collapse",
		textAlign: "left",
		minWidth: "320px",
		width: "100%",
		height: "100%",
	},
	tableHead: {
		width: "320px",
		border: "1px solid rgba(0, 0, 0, 0.1)",
	},
	tableCell: {
		width: "320px",
		border: "1px solid rgba(0, 0, 0, 0.1)",
	},
};

export default function StageColumn(props) {
	const { stage, stageIndex, criteria } = props;
	const [dealsByStage, setDealsByStage] = useState(null);
	const [sortRequest, setSortRequest] = useState({});

	const {
		data: dealsByStageData,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isLoading: isDealsByStageDataLoading,
	} = useDealsByStage(stage?.id, criteria, sortRequest);

	useEffect(() => {
		if (!(isFetching || isDealsByStageDataLoading) && dealsByStageData) {
			setDealsByStage(
				dealsByStageData["pages"][dealsByStageData["pages"].length - 1][
					"data"
				]
			);
		}
	}, [dealsByStageData, isFetching, isDealsByStageDataLoading]);

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	useEffect(() => {
		if (stage?.sort) {
			setSortRequest(stage.sort);
		}
	}, [stage]);

	const updateSortRequest = (selectedSortOption) => {
		setSortRequest(selectedSortOption);
	};

	return (
		<React.Fragment>
			<table style={styles.table}>
				<thead>
					<tr>
						<th style={{ ...styles.tableHead, borderLeft: "none" }}>
							<BoardHeading
								name={stage?.name}
								amount={dealsByStage?.summary?.totalValue}
								dealSize={dealsByStage?.summary?.totalDeals}
								symbol={dealsByStage?.summary?.symbol}
								isSummaryLoading={isDealsByStageDataLoading}
								sort={sortRequest}
								updateSortRequest={updateSortRequest}
							/>
						</th>
					</tr>
				</thead>

				<tbody>
					<tr
						style={{
							height: "100%",
						}}
					>
						<td style={{ ...styles.tableCell, borderLeft: "none" }}>
							<Droppable droppableId={stageIndex?.toString()}>
								{(droppableProvided) => (
									<div
										ref={droppableProvided.innerRef}
										{...droppableProvided.droppableProps}
										style={{
											height: "100%",
											overflow: "auto",
										}}
									>
										{isDealsByStageDataLoading ? (
											<Stack p={1} spacing={1}>
												{new Array(4)
													.fill(0)
													.map((_, index) => (
														<DealCardLoader
															key={index}
															width="100%"
															height="120px"
														/>
													))}
											</Stack>
										) : (
											<DealCardList
												dealPageData={
													dealsByStageData?.pages ||
													[]
												}
												pipelineStageId={stage?.id}
												lastRowRef={lastRowRef}
												isFetching={isFetching}
											/>
										)}
										{droppableProvided.placeholder}
									</div>
								)}
							</Droppable>
						</td>
					</tr>
				</tbody>
			</table>
		</React.Fragment>
	);
}
