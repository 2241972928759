import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	TextField,
	ToggleButton,
	Paper,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { StyledDwarfToggleButtonGroup } from "../../../styles/twozo";
import { default as TimeIcon } from "../../../assets/icons/time";
import dayjs from "dayjs";
import {
	convertDayjsToUnixTimeStamp,
	convertUnixTimeStampToDayjs,
	timeFormatter,
} from "../../../utils/DateUtils";

const styles = {
	timeTextField: {
		width: "25px",
		"& .MuiOutlinedInput-notchedOutline": {
			borderRadius: "8px",
		},
		"& .MuiOutlinedInput-root": {
			height: "auto",
			minHeight: "20px",
			padding: "0px 0px",
			"& input": {
				padding: "0px",
			},
			"& fieldset": {
				border: "none",
			},
			"&:hover fieldset": {
				border: "none",
			},
			"&.Mui-focused fieldset": {
				border: "none",
			},
		},
		input: {
			"&[type=number]": {
				MozAppearance: "textfield",
			},
			"&::-webkit-outer-spin-button": {
				WebkitAppearance: "none",
				margin: 0,
			},
			"&::-webkit-inner-spin-button": {
				WebkitAppearance: "none",
				margin: 0,
			},
		},
	},
};

const meridiemObj = {
	AM: "AM",
	PM: "PM",
};

export default function SingleTimePicker(props) {
	const {
		style,
		reverse,
		value,
		onChange,
		id,
		removeBorder = false,
		inputRef,
		error,
		onClearError,
		hiddenTimeIcon,
		toggleButtonStyle,
	} = props;

	const theme = useTheme();
	const onlyOnceRef = useRef(true);
	const [timeValue, setTimeValue] = useState({
		hour: "",
		minute: "",
		meridiem: meridiemObj.AM,
	});

	const isGreaterThanZero = (hour) => {
		return parseInt(hour) > 0;
	};

	const handleMeridiem = (_, meridiem) => {
		if (meridiem) {
			setTimeValue((timeValue) => {
				return {
					...timeValue,
					meridiem: meridiem,
				};
			});
			onChangeTimeValue(timeValue.hour, timeValue.minute, meridiem);
		}
	};

	const handleHour = (event) => {
		const { value } = event.target;
		const minuteValue = timeValue.minute;
		if (value <= 12) {
			setTimeValue((timeValue) => {
				return {
					...timeValue,
					hour: isGreaterThanZero(value)
						? timeFormatter.format(value)
						: "",
					minute: isGreaterThanZero(value)
						? minuteValue
							? minuteValue
							: "00"
						: "",
				};
			});
			onChangeTimeValue(value, timeValue.minute, timeValue.meridiem);
		}
	};

	const handleMinute = (event) => {
		if (!timeValue.hour) return;
		const { value } = event.target;
		if (value < 60) {
			setTimeValue((timeValue) => {
				return {
					...timeValue,
					minute: timeFormatter.format(value),
				};
			});
			onChangeTimeValue(timeValue.hour, value, timeValue.meridiem);
		}
	};

	useEffect(() => {
		//update the inputs
		if (value && onlyOnceRef.current) {
			let hourAndMinuteValue = convertUnixTimeStampToDayjs(value);
			let hourValue = hourAndMinuteValue.get("hours");
			let minuteValue = hourAndMinuteValue.get("minutes");

			if (hourValue === 0) {
				hourValue = 12;
			} else if (hourValue > 12) {
				hourValue = hourValue % 12;
			}

			setTimeValue(() => {
				return {
					hour: timeFormatter.format(hourValue),
					minute: timeFormatter.format(minuteValue),
					meridiem:
						hourAndMinuteValue.get("hours") >= 12
							? meridiemObj.PM
							: meridiemObj.AM,
				};
			});
			onlyOnceRef.current = false;
		}
	}, [value]);

	const onChangeTimeValue = (hourValue, minuteValue, meridiemValue) => {
		if (onChange) {
			let hour = parseInt(hourValue);
			if (hour === 0) return onChange("");
			if (meridiemValue === meridiemObj.PM && hour !== 12) {
				hour = hour + 12;
			} else if (meridiemValue === meridiemObj.AM && hour === 12) {
				hour = 0;
			}

			let unixTimeStampForTimeValue = convertDayjsToUnixTimeStamp(
				dayjs().set("hours", hour).set("minutes", minuteValue)
			);

			onChange(unixTimeStampForTimeValue);
		}
		onClearError && onClearError();
	};

	return (
		<React.Fragment>
			<Stack
				direction={reverse ? "row-reverse" : "row"}
				alignItems="center"
				style={{
					border: error
						? `1px solid ${theme.palette.error.main}`
						: removeBorder
							? "none"
							: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
					minHeight: "42px",
					padding: "0px 8px",
					width: "100%",
					...style?.timeFieldStyle,
				}}
				spacing={1}
			>
				{!hiddenTimeIcon ? (
					<Box display="flex">
						{TimeIcon(
							20,
							20,
							error ? theme.palette.error.main : "#666666"
						)}
					</Box>
				) : null}

				<Stack
					direction="row"
					alignItems="center"
					spacing={1}
					style={{
						marginLeft: "0px",
						width: "100%",
						...style?.timeFieldPosition,
					}}
				>
					<TextField
						inputRef={inputRef}
						sx={styles.timeTextField}
						placeholder="HH"
						id={id}
						type="number"
						inputProps={{
							style: {
								textAlign: "right",
								fontSize: "14px",
							},
							min: 1,
							max: 12,
						}}
						value={timeValue.hour}
						onChange={handleHour}
						error={!!error}
					/>

					<Typography fontSize={12}>:</Typography>

					<TextField
						sx={styles.timeTextField}
						placeholder="MM"
						type="number"
						inputProps={{
							style: {
								fontSize: "14px",
							},
							min: 0,
							max: 59,
						}}
						value={timeValue.minute}
						onChange={handleMinute}
						error={!!error}
					/>

					<Paper
						elevation={0}
						sx={{
							display: "flex",
							flexWrap: "wrap",
							backgroundColor: "secondary.main",
							borderRadius: "8px !important",
							width: "auto",
							// Position unset !important is necessary to use this element in dialog, because of css overlaps between paper component in dialog.
							position: "unset !important",
						}}
						style={{
							...toggleButtonStyle,
						}}
					>
						<StyledDwarfToggleButtonGroup
							value={timeValue.meridiem}
							onChange={handleMeridiem}
							exclusive
						>
							<ToggleButton value={meridiemObj.AM}>
								AM
							</ToggleButton>
							<ToggleButton value={meridiemObj.PM}>
								PM
							</ToggleButton>
						</StyledDwarfToggleButtonGroup>
					</Paper>
				</Stack>
			</Stack>
		</React.Fragment>
	);
}
