import React, { useCallback, useEffect, useState } from "react";
import { Badge, Box, IconButton, useTheme } from "@mui/material";
import { default as CallIcon } from "../../assets/icons/call";
import CallDialog from "./CallDialog";
import { useGetCallToken } from "../../hooks/services/phone";
import { Device } from "@twilio/voice-sdk";

export default function Phone() {
	const theme = useTheme();

	const [isPhoneCallDialogOpened, setIsPhoneCallDialogOpened] =
		useState(false);
	const [isPhoneCallDialogMinimized, setIsPhoneCallDialogMinimized] =
		useState(false);
	const [device, setDevice] = useState(null);
	const [incomingCallConnection, setIncomingCallConnection] = useState(null);

	const { data: callToken, refetch: refetchCallToken } = useGetCallToken();

	const initializeDevice = useCallback(async (callToken) => {
		await navigator.mediaDevices.getUserMedia({ audio: true });

		const twilioDevice = new Device(callToken, {
			debug: true,
		});

		twilioDevice.on("registered", () =>
			console.log("Twilio Device Ready!")
		);

		twilioDevice.register();
		setDevice(twilioDevice);
	}, []);

	device?.on("error", async (error) => {
		if (error.code === 31205) {
			await refetchCallToken(); // JWT expired. Fetching new token...
		}
	});

	useEffect(() => {
		if (!callToken) return;

		initializeDevice(callToken);
	}, [callToken, initializeDevice]);

	const openPhoneCallDialog = () => {
		setIsPhoneCallDialogOpened(true);
		setIsPhoneCallDialogMinimized(false);
	};

	const closePhoneCallDialog = () => {
		setIsPhoneCallDialogOpened(false);
		setIncomingCallConnection(null);
	};

	const minimizePhoneCallDialog = () => {
		setIsPhoneCallDialogMinimized(true);
	};

	device?.on("incoming", (call) => {
		// incoming call receiving...
		setIncomingCallConnection(call);
		openPhoneCallDialog(true);
	});

	return (
		<React.Fragment>
			<CallDialog
				isPhoneCallDialogOpened={isPhoneCallDialogOpened}
				hiddenPhoneCallDialog={isPhoneCallDialogMinimized}
				onClosePhoneCallDialog={closePhoneCallDialog}
				onMinimizePhoneCallDialog={minimizePhoneCallDialog}
				incomingCallConnection={incomingCallConnection}
				device={device}
			/>

			<IconButton onClick={openPhoneCallDialog}>
				<Badge
					invisible={!isPhoneCallDialogMinimized}
					badgeContent={
						<Box
							style={{
								height: "8px",
								width: "8px",
								backgroundColor: theme.palette.error.main,
								borderRadius: "100%",
							}}
						></Box>
					}
				>
					{CallIcon(22, 22, "#fff")}
				</Badge>
			</IconButton>
		</React.Fragment>
	);
}
