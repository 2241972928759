import { useState } from "react";
import {
	SummaryFieldName,
	Tooltip,
	twozoStyles,
} from "../../../../../../styles/twozo";
import { getDateAndTimeFormat } from "../../../../../../utils/DateUtils";

export default function DateTimeComponent(params) {
	const { summaryField } = params;

	const classes = twozoStyles();
	const [openToolTip, setOpenToolTip] = useState(false);

	const handleOpenTooltip = (event) => {
		setOpenToolTip(event.target.scrollWidth > event.target.clientWidth);
	};

	return (
		<>
			{summaryField.value?.toString() ? (
				<Tooltip
					open={openToolTip}
					placement="top"
					title={getDateAndTimeFormat(summaryField.value) || ""}
				>
					<SummaryFieldName
						className={classes.summaryData}
						onMouseOver={handleOpenTooltip}
						onMouseLeave={() => setOpenToolTip(false)}
					>
						{getDateAndTimeFormat(summaryField.value)}
					</SummaryFieldName>
				</Tooltip>
			) : (
				<SummaryFieldName style={{ opacity: 0.6 }}>-</SummaryFieldName>
			)}
		</>
	);
}
