import dayjs from "dayjs";
import { getDropdownDataByName } from "./dropdownData";

export const convertUnixTimeStampToDayjs = (unixTimestamp) => {
	if (!unixTimestamp) return null;
	return dayjs(dayjs.tz(unixTimestamp).format("YYYY-MM-DD HH:mm:ss"));
};

export const convertDayjsToUnixTimeStamp = (dayjsObj) => {
	if (!dayjsObj) return null;
	return dayjs.tz(dayjsObj?.format("YYYY-MM-DD HH:mm:ss")).valueOf();
};

export const getZoneIdByTimeZoneValue = (timeZoneValue) => {
	const timeZoneList = getDropdownDataByName("ACCOUNT_TIMEZONE");

	return timeZoneList?.find(
		(timeZoneData) => timeZoneData?.value === timeZoneValue
	)?.zoneId;
};

export const getDateAndMonthFormat = (unixTimestamp) => {
	return dayjs.tz(unixTimestamp).format("DD MMM"); // Eg: 04 Jul
};

export const getFormattedDateMonthYearAndTime = (unixTimestamp) => {
	return dayjs.tz(unixTimestamp).format("MMM DD YYYY hh:mm A"); // Eg: May 19 2023 11:29 AM
};

export const getDateMonthAndYearFormat = (unixTimestamp) => {
	return dayjs.tz(unixTimestamp).format("DD MMM YYYY"); // Eg: 19 Oct 2021
};

export const getHourAndMinuteFormat = (unixTimestamp) => {
	return dayjs.tz(unixTimestamp).format("h:mm A"); // Eg: 10:20 AM
};

export const getMonthAndDateFormat = (unixTimestamp) => {
	return dayjs.tz(unixTimestamp).format("ddd, MMM D"); // Eg: Mon, Sep 4
};

export const getDayMonthDateAndTimeFormat = (unixTimestamp) => {
	return dayjs.tz(unixTimestamp).format("ddd, MMM D, h:mm A"); // Eg: Mon, Dec 23, 6:16 PM
};

export const getDayMonthTimeAndMinuteFormat = (unixTimestamp) => {
	return dayjs.tz(unixTimestamp).format("ddd, MMM D, h:mm A"); // Fri, Feb 23, 12:45 PM
};

export const getMonthDateAndTimeFormat = (unixTimestampTime) => {
	return dayjs.tz(unixTimestampTime).format("ddd, MMM D, hh.mm A"); // Eg: Wed, May 2, 09.32 AM
};

export const getDateAndTimeFormat = (unixTimestampTime) => {
	return dayjs.tz(unixTimestampTime).format("DD MMM YYYY hh:mm A"); // 02 Dec 2024 06:11 AM
};

export const getDateDifference = (
	unixTimestamp,
	isShortFormat = false,
	surroundWithParenthesis = true
) => {
	const currentDate = new Date().getTime() / 1000;
	const expectedClosedDate = new Date(unixTimestamp).getTime() / 1000;

	let timeDifference = expectedClosedDate - currentDate;

	//seconds calculation
	let secondsPerMinute = 60;
	let secondsPerHour = secondsPerMinute * 60;
	let secondsPerDay = secondsPerHour * 24;
	let secondsPerYear = secondsPerDay * 365;

	//Year Difference
	let yearDifference = Math.round(timeDifference / secondsPerYear);

	//Month Difference
	timeDifference = timeDifference % secondsPerYear;
	let monthDifference = Math.round(timeDifference / (secondsPerDay * 30));

	//Day Difference
	timeDifference = timeDifference % (secondsPerDay * 30);
	let dayDifference = Math.round(timeDifference / secondsPerDay);

	let differenceString = "";

	if (yearDifference === 0 && monthDifference === 0 && dayDifference === 0) {
		differenceString = "Today";
	} else if (yearDifference !== 0) {
		differenceString = isShortFormat
			? `${Math.abs(yearDifference)}y`
			: Math.abs(yearDifference) > 1
				? `${Math.abs(yearDifference)} years ago`
				: `${Math.abs(yearDifference)} year ago`;
	} else if (monthDifference !== 0) {
		differenceString = isShortFormat
			? `${Math.abs(monthDifference)}m`
			: Math.abs(monthDifference) > 1
				? `${Math.abs(monthDifference)} months ago`
				: `${Math.abs(monthDifference)} month ago`;
	} else {
		differenceString = isShortFormat
			? `${Math.abs(dayDifference)}d`
			: Math.abs(dayDifference) > 1
				? `${Math.abs(dayDifference)} days ago`
				: `${Math.abs(dayDifference)} day ago`;
	}

	return surroundWithParenthesis ? `(${differenceString})` : differenceString;
};

export const getDateDifferenceWithHourAndMinute = (timestamp) => {
	if (!timestamp) return "";

	let currentDate = dayjs().tz();
	let destinationDate = convertUnixTimeStampToDayjs(timestamp);

	let monthDifference = currentDate.diff(destinationDate, "months");
	let dayDifference = currentDate.diff(destinationDate, "days");
	let hourDifference = currentDate.diff(destinationDate, "hours");
	let minuteDifference = currentDate.diff(destinationDate, "minutes");

	if (monthDifference) {
		return monthDifference > 1
			? `${monthDifference} Months Ago`
			: "1 Month Ago";
	} else if (dayDifference) {
		return dayDifference > 1 ? `${dayDifference} Days Ago` : "1 Day Ago";
	} else if (hourDifference) {
		return hourDifference > 1
			? `${hourDifference} Hours Ago`
			: "1 Hour Ago";
	} else if (minuteDifference) {
		return minuteDifference > 1
			? `${minuteDifference} Minutes Ago`
			: "1 Minute Ago";
	} else {
		return "Just Now";
	}
};

export const timeFormatter = new Intl.NumberFormat("en-US", {
	minimumIntegerDigits: 2, // Eg: 01:00
	useGrouping: false,
});
