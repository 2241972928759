import { mailListName } from "../../EmailUtils/mailListName";
import { getDropdownKeyByFieldName } from "../commonQueryKeys";
import { getRootKey } from "../rootKey";

const emailKey = "email";

export const getEmailKey = () => {
	return [...getRootKey(), emailKey]; // Return the email query key
};

// query keys
export const emailSettingsDataKey = [...getEmailKey(), "emailSettingsData"];

// query key methods

export const getMailListKey = (mailListName) => {
	return [...getEmailKey(), mailListName];
};

export const getMailListWithFilterKey = (mailListName, filter) => {
	return [...getMailListKey(mailListName), filter];
};

export const getMailFilterDataKey = (mailListName) => {
	return [...getEmailKey(), mailListName, "filtersData"];
};

export const getMailDataKey = (threadId) => {
	return [...getEmailKey(), "mailData", toString(threadId)];
};

export const getSingleConversationDataKey = (conversationId) => {
	return [...getEmailKey(), "singleConversationData", String(conversationId)];
};

export const getFromAddressDropdownKey = () => {
	return [...getDropdownKeyByFieldName("fromAddressList")];
};

export const getMetricsKey = (metricId) => {
	return [
		...getEmailKey(),
		mailListName.BULK_METRICS,
		"metricsTypes",
		String(metricId),
	];
};

export const getMetricsInsightsKey = (metricId, metricType) => {
	return [...getMetricsKey(metricId), "metricsData", metricType];
};

export const getRelatedContactsAndDealKey = (threadId) => {
	return [...getEmailKey(), "relatedContactsAndDeal", String(threadId)];
};
