import { useWatch } from "react-hook-form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { default as CloseIcon } from "../../../../../../assets/icons/close";
import { default as DropdownIcon } from "../../../../../../assets/icons/dropDown";
import {
	Box,
	Divider,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { useUpdateFieldList } from "../../../../../../hooks/services/common/field";
import Field from "../../../Field";
import { FormFieldName, MenuItemText } from "../../../../../../styles/twozo";
import MenuSearchBar from "../../../../MenuSearchBar";
import { checkFieldVisibility } from "../../../../../../utils/fieldVisibility";
import { getFieldGroupStyle } from "../../../styles";

export default function GroupField({
	field,
	fieldState,
	unregister,
	selectedFieldIds,
	handleSelectChange,
	handleRemoveField,
	isRemoveAllowed,
	moduleName,
}) {
	const theme = useTheme();
	const formValues = useWatch();
	const [groupFieldList, setGroupFieldList] = useState([]);

	// query call
	const { data: fieldListData } = useUpdateFieldList(moduleName);

	const [searchValue, setSearchValue] = useState("");

	// Static data: The fields array is derived from fieldListData and does not involve expensive calculations or frequent updates.
	// Therefore, using useMemo is unnecessary here.
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const fields = fieldListData?.fields || [];
	const subFields = fieldListData?.subFields || [];

	const getVisibleFields = useCallback(
		(currentField) => {
			if (!currentField) return [];

			const visibleFields = [];

			visibleFields.push(currentField);

			if (
				currentField?.subFields &&
				Array.isArray(currentField.subFields)
			) {
				currentField.subFields.forEach((subField) => {
					const isFieldVisible = checkFieldVisibility(
						subField,
						formValues
					);

					if (isFieldVisible) {
						visibleFields.push(...getVisibleFields(subField));
					}
					return;
				});
			}

			return visibleFields;
		},
		[formValues]
	);

	useEffect(() => {
		if (field) {
			const visibleFields = getVisibleFields(field);
			setGroupFieldList(visibleFields);
		}
	}, [field, getVisibleFields]);

	const removeField = () => {
		handleRemoveField();
		groupFieldList.forEach((groupField) => {
			unregister(groupField.id + "");
		});
	};

	const filteredFields = useMemo(() => {
		if (Array.isArray(fields)) {
			if (searchValue?.trim()) {
				return fields.filter((field) =>
					field?.fieldName
						?.toLowerCase()
						?.startsWith(searchValue?.toLowerCase())
				);
			}
			return fields;
		}
		return [];
	}, [searchValue, fields]);

	const renderEmptyView = () => {
		return (
			<Box sx={{ cursor: "not-allowed", opacity: 0.7 }} width="100%">
				<TextField
					placeholder="Enter Value"
					sx={{ pointerEvents: "none", width: "100%" }}
				/>
			</Box>
		);
	};

	const renderNoResultsMessage = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					No Results Found
				</Typography>
			</Stack>
		);
	};

	const handleSearchField = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	return (
		<>
			{groupFieldList.map((field, index) => {
				const isGroupedFieldTop = index === 0;
				const isGroupedFieldBottom =
					index === groupFieldList.length - 1;

				return (
					<Stack
						direction="row"
						width="100%"
						key={`${field.id}-group-field`}
					>
						<Box
							height="100%"
							display="flex"
							style={{ width: "45%" }}
						>
							<Select
								size="small"
								displayEmpty
								name="field"
								onChange={(event) =>
									handleSelectChange(event, fields)
								}
								IconComponent={(props) => (
									<Box {...props}>
										{isGroupedFieldTop &&
											DropdownIcon(16, 16)}
									</Box>
								)}
								style={{
									pointerEvents: isGroupedFieldTop
										? "auto"
										: "none",
								}}
								sx={{
									".MuiOutlinedInput-notchedOutline":
										getFieldGroupStyle(
											isGroupedFieldTop,
											isGroupedFieldBottom
										),
								}}
								value={field.fieldName}
								fullWidth
								renderValue={(value) => {
									if (value === "" || value === undefined) {
										return (
											<Typography
												color={"rgba(0, 0, 0, 0.5)"}
												fontSize={14}
											>
												Select
											</Typography>
										);
									} else {
										return (
											<FormFieldName>
												{field.fieldName}
												{field.config.required ? (
													<span
														style={{
															color: theme.palette
																.error.main,
															marginLeft: "5px",
														}}
													>
														*
													</span>
												) : null}
											</FormFieldName>
										);
									}
								}}
								MenuProps={{
									autoFocus: false,
									PaperProps: {
										style: {
											maxHeight: "375px",
											marginTop: "5px",
											borderRadius: "5px",
										},
									},
								}}
								error={
									fieldState.isErrorMode &&
									field.fieldName === ""
								}
								onClose={() => setSearchValue("")}
							>
								<Box
									p={0.5}
									sx={{
										backgroundColor: "#fff",
									}}
									marginTop="-8px"
									position="sticky"
									top={0}
									zIndex={1}
								>
									<MenuSearchBar
										value={searchValue}
										onChange={handleSearchField}
										autoFocus={false}
									/>
								</Box>

								{filteredFields?.length > 0
									? filteredFields?.map((fieldData) => (
											<MenuItem
												key={fieldData.id}
												value={fieldData.fieldName}
												sx={{
													height: "40px",
												}}
												disabled={selectedFieldIds.includes(
													fieldData.id
												)}
											>
												<MenuItemText
													sx={{
														fontWeight: 500,
													}}
												>
													{fieldData.fieldName}
													{fieldData.config
														.required ? (
														<span
															style={{
																color: theme
																	.palette
																	.error.main,
																marginLeft:
																	"5px",
															}}
														>
															*
														</span>
													) : null}
												</MenuItemText>
											</MenuItem>
										))
									: renderNoResultsMessage()}
							</Select>
						</Box>

						<Box style={{ width: "5%" }} px={0.5} pt={2.8}>
							<Divider />
						</Box>

						<Box
							height="100%"
							display="flex"
							width="45%"
							flexGrow={1}
							overflow="hidden"
						>
							{field.fieldName === "" ? (
								renderEmptyView()
							) : (
								<Field
									field={field}
									subFields={subFields}
									isGroupedFieldTop={isGroupedFieldTop}
									isGroupedFieldBottom={isGroupedFieldBottom}
								/>
							)}
						</Box>

						<Box
							display="flex"
							style={{
								width: "5%",
								cursor: "pointer",
								paddingLeft: "8px",
							}}
							onClick={removeField}
							pt={1.5}
						>
							{isRemoveAllowed &&
								isGroupedFieldTop &&
								CloseIcon(20, 20, "rgba(0, 0, 0, 1)", 0.6)}
						</Box>
					</Stack>
				);
			})}
		</>
	);
}
