import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import {
	deleteWorkflow,
	getActionStepOptionData,
	getAutomationFilterList,
	getWorkflowActionsList,
	getWorkflowData,
	getWorkflowFilterUserList,
	getWorkflowHistoryFilter,
	getWorkflowHistoryList,
	getWorkflowNamesList,
	getWorkflowList,
	updateWorkflow,
	updateWorkflowStatus,
	getWorkflowHistoryData,
	getAllTemplateData,
} from "../../../api/workflow/workflowApi";
import {
	automationFilterKey,
	getActionStepOptionKey,
	getAllTemplateListKey,
	getAllWorkflowListKey,
	getWorkflowDataKey,
	getWorkflowDependentDropdownKeyById,
	getWorkflowDropdownKeyById,
	getWorkflowDropdownKeyByName,
	getWorkflowHistoryKey,
	getWorkflowHistoryListKey,
	getWorkflowListKey,
	workflowFilterActionsListKey,
	workflowFilterUserListKey,
	workFlowHistoryFilterKey,
	workflowNamesListKey,
} from "../../../utils/queryKeys/workflow";
import {
	workflowAutomationPageSize,
	workflowHistoryPageSize,
} from "../../../utils/queryConstants/workflow";
import { getFieldDropdownData } from "../../../api/fields/config/configApi";
import { searchTypes } from "../../../utils/dropdownData";

const getSelectStepOption = (data) => {
	return data?.stepOptions;
};

// query call :-
const useStepOptions = (metadata, field) => {
	let actionsStepOptionKey = getActionStepOptionKey(
		metadata.triggerSourceId,
		field?.fieldId,
		[{ source: metadata.actionSource, event: metadata.actionEvent }]
	);

	return useQuery(
		actionsStepOptionKey,
		() =>
			getActionStepOptionData(metadata.triggerSourceId, field?.fieldId, [
				{ source: metadata.actionSource, event: metadata.actionEvent },
			]),
		{
			enabled: !!(
				!metadata ||
				(field?.fieldId && field?.config?.hasStepOptions)
			),
			select: getSelectStepOption,
		}
	);
};

const useWorkflowData = (workflowId, historyId) => {
	const workflowDataKey = getWorkflowDataKey(workflowId);
	return useQuery(workflowDataKey, () => getWorkflowData(workflowId), {
		enabled: Boolean(workflowId) && workflowId !== "-1" && !historyId,
		cacheTime: 0,
	});
};

const useAutomationFilterList = () => {
	return useQuery(automationFilterKey, () => getAutomationFilterList(), {
		select: (filterList) => filterList?.filters,
	});
};

const useWorkflowFilterUserList = () => {
	return useQuery(
		workflowFilterUserListKey,
		() => getWorkflowFilterUserList(),
		{
			select: (usersList) => usersList?.options,
		}
	);
};

const useWorkflowHistoryFilter = () => {
	return useQuery(
		workFlowHistoryFilterKey,
		() => getWorkflowHistoryFilter(),
		{
			select: (historyFilter) => historyFilter?.filter,
		}
	);
};

const useWorkflowNamesList = () => {
	return useQuery(workflowNamesListKey, () => getWorkflowNamesList(), {
		select: (usersList) => usersList?.options,
	});
};

const useWorkflowActionsList = () => {
	return useQuery(
		workflowFilterActionsListKey,
		() => getWorkflowActionsList(),
		{
			select: (actionsList) => actionsList?.actions,
		}
	);
};

const automationListSelect = (automationList) => {
	return automationList.pages.flatMap((page) =>
		page?.list?.length > 0 ? page?.list : []
	);
};

const useWorkflowAutomationList = (filterCriteria, searchText, sortData) => {
	return useInfiniteQuery({
		queryKey: getWorkflowListKey(filterCriteria, searchText, sortData),
		queryFn: ({ pageParam }) => {
			return getWorkflowList(filterCriteria, searchText, sortData, {
				start: pageParam
					? (pageParam - 1) * workflowAutomationPageSize + 1
					: 1,
				limit: workflowAutomationPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: automationListSelect,
	});
};

const historyListSelect = (historyList) => {
	return historyList.pages.flatMap((page) =>
		page?.list?.length > 0 ? page?.list : []
	);
};

const useWorkflowHistoryList = (criteria, sort) => {
	return useInfiniteQuery({
		queryKey: getWorkflowHistoryListKey(criteria, sort),
		queryFn: ({ pageParam }) => {
			return getWorkflowHistoryList(criteria, sort, {
				start: pageParam
					? (pageParam - 1) * workflowHistoryPageSize + 1
					: 1,
				limit: workflowHistoryPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: historyListSelect,
	});
};

const workflowDropdownSelect = (optionsList) => {
	return optionsList.pages.flatMap((page) =>
		page?.options?.length > 0 ? page?.options : []
	);
};

const useWorkflowDropdownList = (
	field,
	searchValue = "",
	dependentDropdownValue,
	formValues
) => {
	const { fieldId, dropdown, dropDown, config } = field || {};
	const dependentId = dependentDropdownValue?.value?.id;
	const dependsOnKey = config?.dependsOn;
	const isDependsOnKeyAvailable = Boolean(dependsOnKey);
	const dropdownName = dropdown?.name || dropDown?.name;
	const dropdownId = dropDown?.id || fieldId;
	const criteria = {
		type: searchTypes.contains,
		value: searchValue,
	};

	const getDropdownKey = () => {
		if (isDependsOnKeyAvailable) {
			return getWorkflowDependentDropdownKeyById(
				dropdownId,
				dependentId,
				searchValue
			);
		}

		if (dropdownName) {
			return getWorkflowDropdownKeyByName(dropdownName, searchValue);
		}

		return getWorkflowDropdownKeyById(dropdownId, searchValue);
	};

	const getDropdownRequestData = () => {
		if (isDependsOnKeyAvailable && formValues?.[dependsOnKey]) {
			return {
				id: dropdownId,
				dependentId,
				criteria: criteria,
			};
		}

		if (dropdownName) {
			return {
				name: dropdownName,
				criteria: criteria,
			};
		}

		return {
			id: dropdownId,
			criteria: criteria,
		};
	};

	return useInfiniteQuery({
		queryKey: getDropdownKey(),
		queryFn: ({ pageParam }) =>
			getFieldDropdownData(getDropdownRequestData(), {
				start: pageParam
					? (pageParam - 1) * workflowHistoryPageSize + 1
					: 1,
				limit: workflowHistoryPageSize,
			}),
		getNextPageParam: (lastPage, allPages) =>
			lastPage?.hasMore ? allPages.length + 1 : undefined,
		select: workflowDropdownSelect,
	});
};

const useTemplateList = () => {
	const templateListKey = getAllTemplateListKey();
	return useQuery(templateListKey, () => getAllTemplateData(), {
		select: (template) => template?.list,
	});
};

const useWorkflowHistoryData = (historyId) => {
	let historyDataKey = getWorkflowHistoryKey(historyId);
	return useQuery(historyDataKey, () => getWorkflowHistoryData(historyId), {
		enabled: !!historyId,
		select: (historyData) => historyData?.history,
		cacheTime: 0,
	});
};

// Mutation call:-
const useUpdateWorkflow = () => {
	let invalidateWorkflowList = useInvalidateWorkflowList();
	return useMutation(updateWorkflow, {
		onSuccess: () => {
			invalidateWorkflowList();
		},
	});
};

const useDeleteWorkflow = () => {
	let invalidateWorkflowList = useInvalidateWorkflowList();
	return useMutation(deleteWorkflow, {
		onSuccess: () => {
			invalidateWorkflowList();
		},
	});
};

const useUpdateWorkflowStatus = () => {
	let invalidateWorkflowList = useInvalidateWorkflowList();
	return useMutation(updateWorkflowStatus, {
		onSuccess: () => {
			invalidateWorkflowList();
		},
	});
};

// Invalidation call:-
const useInvalidateWorkflowList = () => {
	let queryClient = useQueryClient();
	let workflowListKey = getAllWorkflowListKey();
	return () => {
		queryClient.invalidateQueries(workflowListKey);
	};
};

export {
	useWorkflowData,
	useStepOptions,
	useTemplateList,
	useUpdateWorkflow,
	useDeleteWorkflow,
	useAutomationFilterList,
	useWorkflowFilterUserList,
	useWorkflowActionsList,
	useUpdateWorkflowStatus,
	useWorkflowHistoryList,
	useWorkflowNamesList,
	useWorkflowDropdownList,
	useWorkflowHistoryFilter,
	useWorkflowAutomationList,
	useWorkflowHistoryData,
};
