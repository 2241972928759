import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import {
	getCommonSearchKey,
	getCommonSearchDataWithFilterKey,
	searchedAndViewedKey,
} from "../../../utils/queryKeys";
import {
	updateSearchedAndViewedData,
	getSearchedAndViewedData,
	getSearchedResults,
} from "../../../api/commonSearch/commonSearchApi";
import { commonSearchPageSize } from "../../../utils/queryConstants/commonSearch";

const useSearchedAndViewedData = (isSearchedAndViewedEnabled) => {
	return useQuery(searchedAndViewedKey, () => getSearchedAndViewedData(), {
		enabled: isSearchedAndViewedEnabled,
		refetchOnWindowFocus: false,
	});
};

const commonSearchSelect = (data) => {
	return data.pages.flatMap((page) => {
		if (!page.associatedFields) {
			return page.table.results;
		}
		return page;
	});
};

const useCommonSearchData = (filter, searchText, isSearchEnabled) => {
	return useInfiniteQuery({
		queryKey: getCommonSearchDataWithFilterKey(filter, searchText),
		queryFn: ({ pageParam }) => {
			return getSearchedResults(filter, searchText, {
				from: pageParam
					? (pageParam - 1) * commonSearchPageSize + 1
					: 1,
				count: commonSearchPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage?.table && lastPage?.table.hasMore
				? allPages.length + 1
				: undefined;
		},
		enabled: isSearchEnabled,
		select: commonSearchSelect,
	});
};

const useUpdateSearchedAndViewed = () => {
	let invalidateSearchedAndViewed = useInvalidateSearchedAndViewed();
	return useMutation(updateSearchedAndViewedData, {
		onSuccess: () => {
			invalidateSearchedAndViewed();
		},
	});
};

const useSearchResultsMutation = () => {
	return useMutation(getSearchedResults);
};

export const useInvalidateSearchedAndViewed = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(searchedAndViewedKey);
	};
};

const useInvalidateCommonSearch = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getCommonSearchKey());
	};
};

export {
	useSearchedAndViewedData,
	useUpdateSearchedAndViewed,
	useSearchResultsMutation,
	useCommonSearchData,
	useInvalidateCommonSearch,
};
