import React, { useState } from "react";
import { Box, Button, Stack, ToggleButton } from "@mui/material";
import { default as AddIcon } from "../../../assets/icons/add";
import { default as listIcon } from "../../../assets/icons/list";
import { default as activityIcon } from "../../../assets/icons/activity";
import ToggleButtonGroup from "../../Elements/ToggleButtonGroup";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import { Tooltip, twozoStyles } from "../../../styles/twozo";
import AddActivity from "../../Summary/Activities/AddActivity";
import { activityView } from "../../../utils/ActivitiesUtils";
import Can from "../../Auth/Can";
import { PERMISSIONS } from "../../../utils/Auth";
import ImportModuleDialog from "../../Elements/ImportModuleDialog";
import { modules } from "../../../utils/common/ModulesName";

export default function ActivityMenu(props) {
	const { view, handleViewToggleButtonGroup } = props;

	const classes = twozoStyles();
	const [isAddActivityDialogOpened, setIsAddActivityDialogOpened] =
		useState(false);

	const toggleAddActivityDialog = () => {
		setIsAddActivityDialogOpened(
			(openAddCompanyDialog) => !openAddCompanyDialog
		);
	};

	const [isActivityImportDialogOpened, setIsActivityImportDialogOpened] =
		useState(false);

	const handleCloseImportDialog = () => {
		setIsActivityImportDialogOpened(false);
	};

	const onImportClicked = () => {
		setIsActivityImportDialogOpened(true);
	};

	const openAddActivityDialog = () => {
		setIsAddActivityDialogOpened(true);
	};

	return (
		<React.Fragment>
			<ImportModuleDialog
				open={isActivityImportDialogOpened}
				onClose={handleCloseImportDialog}
				moduleName={modules.ACTIVITY}
				disableBackdropClick={true}
			/>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddActivityDialogOpened}
				onOpen={toggleAddActivityDialog}
				onClose={toggleAddActivityDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddActivity
						onClose={toggleAddActivityDialog}
						openAddActivityDialog={openAddActivityDialog}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Stack direction="row" spacing={3} alignItems="center">
				<ToggleButtonGroup
					value={view}
					onChange={handleViewToggleButtonGroup}
				>
					<ToggleButton value={activityView.list}>
						<Tooltip title="List View">
							{view === activityView.list
								? listIcon(20, 20, "#fff")
								: listIcon(20, 20, "rgba(0, 0, 0, 0.6)")}
						</Tooltip>
					</ToggleButton>

					<ToggleButton value={activityView.calendar}>
						<Tooltip title="Calendar View">
							{view === activityView.calendar
								? activityIcon(20, 20, "#fff")
								: activityIcon(20, 20, "rgba(0, 0, 0, 0.6)")}
						</Tooltip>
					</ToggleButton>
				</ToggleButtonGroup>

				<Stack direction="row" spacing={1}>
					<Stack direction="row" spacing={2}>
						<Can permission={PERMISSIONS.activity.importRecords}>
							<Button
								variant="contained"
								color="secondary"
								disableElevation
								onClick={onImportClicked}
							>
								Import Activities
							</Button>
						</Can>

						<Button
							variant="contained"
							startIcon={AddIcon(20, 20, "#fff")}
							disableElevation
							onClick={toggleAddActivityDialog}
						>
							Activity
						</Button>
					</Stack>
				</Stack>
			</Stack>
		</React.Fragment>
	);
}
