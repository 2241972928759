import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { default as CallIcon } from "../../../../../assets/icons/call";
import UnKnownImage from "../../../../../assets/images/contact/unknownContact.png";
import { FormFieldName } from "../../../../../styles/twozo";

const recentCalls = [
	{
		id: 1,
		name: "Ann Dorwart",
		number: "+919876543210",
		time: "16 Min Ago",
	},
	{
		id: 2,
		name: "Maglin",
		number: "+9876356118",
		time: "20 Min Ago",
	},
	{
		id: 3,
		name: "Mugesh",
		number: "+9876356118",
		time: "30 Min Ago",
	},
	{
		id: 4,
		name: "Ancy",
		number: "8576511110",
		time: "58 Min Ago",
	},
];

export default function RecentCalls() {
	const [hoveredRecentCallId, setHoveredRecentCallId] = useState(null);

	const onMouseOverRecentCall = (recentCallId) => {
		setHoveredRecentCallId(recentCallId);
	};

	const onMouseOutRecentCall = () => {
		setHoveredRecentCallId(null);
	};

	return (
		<React.Fragment>
			{recentCalls.length > 0 ? (
				<Box>
					<FormFieldName pb={0.5}>Recent Calls</FormFieldName>

					<Box style={{ maxHeight: "188px", overflow: "auto" }}>
						{recentCalls.map((recentCall) => (
							<Stack
								key={recentCall.id}
								direction="row"
								alignItems="center"
								justifyContent="space-between"
								p="10px 16px"
								onMouseOver={() =>
									onMouseOverRecentCall(recentCall.id)
								}
								onMouseOut={onMouseOutRecentCall}
								style={{
									backgroundColor:
										hoveredRecentCallId === recentCall?.id
											? "#323441"
											: "transparent",
									borderRadius: "8px",
								}}
							>
								<Stack direction="row" spacing={2}>
									<Box>
										<img
											src={UnKnownImage}
											alt="profile"
											width="40px"
											height="40px"
											style={{ borderRadius: "100%" }}
										/>
									</Box>

									<Box>
										<Typography
											fontSize={14}
											style={{
												wordBreak: "break-all",
												width: "210px",
											}}
											noWrap
										>
											{recentCall.name}
										</Typography>

										<Typography fontSize={14}>
											{recentCall.number}
										</Typography>
									</Box>
								</Stack>

								<Box>
									{hoveredRecentCallId === recentCall.id ? (
										<Button
											variant="contained"
											color="primary"
											disableElevation
											onClick={() => {}}
										>
											{CallIcon(20, 20, "#fff")}
										</Button>
									) : (
										<Typography fontSize={13}>
											{recentCall.time}
										</Typography>
									)}
								</Box>
							</Stack>
						))}
					</Box>
				</Box>
			) : null}
		</React.Fragment>
	);
}
