import React from "react";
import { DwarfButton } from "../../../styles/twozo";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import { default as CloseIcon } from "../../../assets/icons/close";
import DeleteDialog from "../../Elements/DeleteDialog";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import {
	useDeleteActivities,
	useUpdateActivityStatusFromList,
} from "../../../hooks/services/activities";
import ExportDialog from "../../Elements/Export/ExportDialog";
import CancelExportDialog from "../../Elements/Export/CancelDialog";
import Can from "../../Auth/Can";
import { PERMISSIONS } from "../../../utils/Auth";
import { modules } from "../../../utils/common/ModulesName";
import UpdateField from "./UpdateField";

export default function SelectedMenu(props) {
	const {
		selected,
		tableRowsData,
		deSelectAll,
		filterData,
		isAllSelected,
		additionalCriteria,
	} = props;
	const successNotificationTitle = "Success!";
	const moduleName = modules.ACTIVITY;

	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
	const [isExportDialogOpened, setIsExportDialogOpened] = useState(false);
	const [isCancelExportingDialogOpened, setIsCancelExportingDialogOpened] =
		useState(false);
	const [isUpdateFieldDialogOpened, setIsUpdateFieldDialogOpened] =
		useState(false);

	const selectedRowIds = [...selected];
	const deleteActivitiesMutation = useDeleteActivities();
	const updateActivityMutation = useUpdateActivityStatusFromList();

	const handleUnSelectActivities = () => {
		deSelectAll();
	};

	const openDeleteDialog = () => {
		setIsDeleteDialogOpened(true);
	};

	const closeDeleteDialog = () => {
		setIsDeleteDialogOpened(false);
	};

	const openExportDialog = () => {
		setIsExportDialogOpened(true);
	};

	const closeExportDialog = () => {
		setIsExportDialogOpened(false);
	};

	const openCancelDialog = () => {
		setIsCancelExportingDialogOpened(true);
		closeExportDialog();
	};

	const cancelExporting = () => {
		setIsExportDialogOpened(false);
		setIsCancelExportingDialogOpened(false);
	};

	const exportFile = () => {
		openCancelDialog();
	};

	const handleOpenUpdateFieldDialog = () => {
		setIsUpdateFieldDialogOpened(true);
	};

	const handleCloseUpdateFieldDialog = () => {
		setIsUpdateFieldDialogOpened(false);
	};

	const performDeleteConfirm = () => {
		deleteActivitiesMutation.mutate(selectedRowIds, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					title: successNotificationTitle,
					message:
						selectedRowIds.length > 1
							? `${selectedRowIds.length} activities deleted.`
							: notificationMessage.activityDeleted,
				});
				deSelectAll();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
		closeDeleteDialog();
	};

	const getActivityStatusUpdateRequestData = () => {
		let requestData = {
			...filterData,
			additionalCriteria,
			updateItem: { isDone: true },
		};

		if (isAllSelected) {
			requestData.isAllSelected = true;
			const selectedSet = new Set(selectedRowIds);

			const excludedIds = tableRowsData
				.filter(({ id }) => !selectedSet.has(id))
				.map(({ id }) => id);

			if (
				selectedRowIds.length !== tableRowsData.length &&
				excludedIds.length > 0
			) {
				requestData.excludedIds = excludedIds;
			}
		} else {
			requestData.ids = selectedRowIds;
		}

		return requestData;
	};

	const updateActivityStatus = () => {
		const updateActivityRequest = getActivityStatusUpdateRequestData();

		updateActivityMutation.mutate(updateActivityRequest, {
			onSuccess: (data) => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message:
						data?.message ?? notificationMessage.activityUpdated,
				});
				deSelectAll();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	const handleSaveUpdateField = (fieldData) => {
		updateActivityMutation.mutate(fieldData, {
			onSuccess: (data) => {
				handleCloseUpdateFieldDialog();
				if (selectedRowIds.length > 1) {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message:
							data?.message ??
							`${selectedRowIds.length} Activities updated.`,
					});
				} else {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message:
							data?.message ??
							notificationMessage.activityUpdated,
					});
				}
				deSelectAll();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<DeleteDialog
				title="Are you sure you want to delete the selected activities?"
				subtitle="It will delete all the selected activities and the data associated with it"
				open={isDeleteDialogOpened}
				onCancel={closeDeleteDialog}
				onDelete={performDeleteConfirm}
				disableDeleteButton={deleteActivitiesMutation.isLoading}
			/>

			<ExportDialog
				open={isExportDialogOpened}
				onClose={closeExportDialog}
				exportFile={exportFile}
			/>

			<CancelExportDialog
				open={isCancelExportingDialogOpened}
				onCancel={cancelExporting}
			/>

			<UpdateField
				selected={selectedRowIds}
				moduleName={moduleName}
				tableRowsData={tableRowsData}
				filterData={filterData}
				isAllSelected={isAllSelected}
				additionalCriteria={additionalCriteria}
				onSave={handleSaveUpdateField}
				open={isUpdateFieldDialogOpened}
				onClose={handleCloseUpdateFieldDialog}
				isSaving={updateActivityMutation.isLoading}
			/>

			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={2}
				height="100%"
			>
				<Stack direction="row" spacing={0.5} alignItems="center">
					<Typography fontSize={14} fontWeight={500}>
						{selectedRowIds.length} Selected
					</Typography>

					<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
						of {tableRowsData.length}
					</Typography>

					<IconButton
						disableRipple
						style={{ cursor: "pointer", padding: 0 }}
						onClick={handleUnSelectActivities}
					>
						{CloseIcon(18, 18, "rgba(0,0,0,0.6)")}
					</IconButton>
				</Stack>

				<Divider orientation="vertical" style={{ height: "20px" }} />

				<DwarfButton
					variant="contained"
					color="secondary"
					disableElevation
					onClick={handleOpenUpdateFieldDialog}
				>
					Update Field
				</DwarfButton>

				<DwarfButton
					variant="contained"
					color="secondary"
					disableElevation
					disabled={updateActivityMutation.isLoading}
					onClick={updateActivityStatus}
				>
					Mark As Completed
				</DwarfButton>

				<DwarfButton
					variant="contained"
					color="secondary"
					disableElevation
					onClick={openDeleteDialog}
				>
					Delete
				</DwarfButton>

				<Can permission={PERMISSIONS.activity.exportRecords}>
					<DwarfButton
						variant="contained"
						color="secondary"
						disableElevation
						onClick={openExportDialog}
					>
						Export
					</DwarfButton>
				</Can>
			</Stack>
		</React.Fragment>
	);
}
