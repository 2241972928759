import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { default as CompanyIcon } from "../../../../../assets/icons/company";
import Address from "../Fields/Address";
import { styles } from "../../../styles";
import Note from "../Fields/Note";
import CustomField from "../Fields/CustomField";

export default function Company(props) {
	const {
		matchedCompanies,
		highlightText,
		onMouseOverCompanyMenu,
		handleCloseCompanyMenu,
	} = props;

	return (
		<React.Fragment>
			<Stack
				direction="row"
				alignItems="flex-start"
				justifyContent="space-between"
				spacing={3}
				width="95%"
			>
				<Box alignItems="start">
					<IconButton
						style={{
							padding: 0,
						}}
					>
						{CompanyIcon(20, 20, "rgba(0, 0, 0, 0.6)")}
					</IconButton>
				</Box>

				<Stack width="100%" spacing={"5px"}>
					<Typography
						noWrap
						fontSize={15}
						width="100%"
						onMouseEnter={onMouseOverCompanyMenu}
						onMouseLeave={handleCloseCompanyMenu}
					>
						{highlightText(matchedCompanies.name)}
					</Typography>

					{matchedCompanies.address && (
						<Address
							highlightText={highlightText}
							address={matchedCompanies.address}
						/>
					)}
					{!!matchedCompanies.website && (
						<Typography
							noWrap
							maxWidth="100%"
							style={styles.subText}
						>
							Website: {highlightText(matchedCompanies.website)}
						</Typography>
					)}
					{matchedCompanies.notes && (
						<Note
							highlightText={highlightText}
							notes={matchedCompanies.notes}
						/>
					)}

					{matchedCompanies.customFields && (
						<CustomField
							highlightText={highlightText}
							customFields={matchedCompanies.customFields}
						/>
					)}
				</Stack>
			</Stack>
		</React.Fragment>
	);
}
