import React, { useState } from "react";
import Dialog from "../Dialog";
import { default as AddIcon } from "../../../assets/icons/add";
import { alpha, Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import FieldList from "./FieldList";
import { useUpdateFieldList } from "../../../hooks/services/common/field";

const dialogBoxSx = {
	"& .MuiPaper-root": {
		width: "578px",
		borderRadius: "10px",
		position: "absolute",
		top: 40,
	},
};

export default function UpdateFieldForm(props) {
	const { moduleName, onSave, open, onClose, isSaving } = props;

	// update field form
	const formMethods = useForm({ reValidateMode: false });
	const { handleSubmit, unregister, reset } = formMethods;

	const theme = useTheme();
	const [fieldToUpdate, setFieldToUpdate] = useState([
		{ fieldName: "", id: "fieldId" },
	]);
	const [selectedFieldIds, setSelectedFieldIds] = useState([]);
	const [fieldState, setFieldState] = useState({
		isEmptyField: true,
		isErrorMode: false,
	});

	const { data: fieldListData } = useUpdateFieldList(moduleName);

	const handleSelectChange = (event, fields, index) => {
		const { value } = event.target;
		let fieldData = fields.find((field) => field.fieldName === value);

		// Unregister the previously selected field
		if (fieldToUpdate[index].id) {
			unregister(fieldToUpdate[index].id + "");
		}

		setFieldToUpdate((fieldToUpdate) => {
			let updatedFieldToUpdate = fieldToUpdate.map(
				(field, fieldIndex) => {
					if (fieldIndex === index) {
						return fieldData;
					}
					return { ...field };
				}
			);

			return [...updatedFieldToUpdate];
		});

		setSelectedFieldIds((selectedFieldIds) => {
			selectedFieldIds.splice(index, 1, fieldData.id);
			return [...selectedFieldIds];
		});

		setFieldState((fieldState) => ({
			...fieldState,
			isEmptyField: false,
			isErrorMode: false,
		}));
	};

	const isAddMoreButtonDisabled =
		fieldState.isErrorMode ||
		fieldListData?.fields?.length === fieldToUpdate.length;

	const onAddMoreClick = () => {
		if (fieldState.isEmptyField) {
			setFieldState((fieldState) => ({
				...fieldState,
				isErrorMode: true,
			}));
		} else {
			setFieldState((fieldState) => ({
				...fieldState,
				isEmptyField: true,
			}));
			setFieldToUpdate((fieldToUpdate) => [
				...fieldToUpdate,
				{ fieldName: "" },
			]);
		}
	};

	const handleRemoveField = (field, index) => {
		setFieldToUpdate((fieldToUpdate) => {
			fieldToUpdate.splice(index, 1);
			return [...fieldToUpdate];
		});
		setSelectedFieldIds((selectedFieldIds) => {
			selectedFieldIds.splice(index, 1);
			return [...selectedFieldIds];
		});

		const hasLastFieldEmpty = fieldToUpdate.some(
			(field) => field.fieldName === ""
		);

		setFieldState((fieldState) => ({
			...fieldState,
			isEmptyField: hasLastFieldEmpty,
			isErrorMode: false,
		}));
		unregister(field.id + "");
	};

	const isSaveButtonDisabled =
		fieldState.isErrorMode || isSaving || fieldState.isEmptyField;

	const handleCloseMenu = () => {
		onClose();
		setFieldToUpdate([{ fieldName: "" }]);
		setSelectedFieldIds([]);
		setFieldState((fieldState) => ({
			...fieldState,
			isEmptyField: true,
			isErrorMode: false,
		}));
		reset();
	};

	return (
		<React.Fragment>
			<Dialog open={open} title="Update Field" sx={dialogBoxSx}>
				<Box
					display="flex"
					flexDirection="column"
					width="100%"
					maxHeight="600px"
					p={1}
				>
					<Stack direction="row" px={1} pb={0.5}>
						<Box width="45%">
							<Typography fontSize={13} fontWeight={500}>
								Select Field
							</Typography>
						</Box>

						<Box style={{ width: "5%" }}></Box>

						<Box width="45%">
							<Typography fontSize={13} fontWeight={500}>
								Value
							</Typography>
						</Box>

						<Box style={{ width: "5%" }}></Box>
					</Stack>

					<Stack
						style={{
							minHeight: 0,
							maxHeight: "410px",
							flex: "1 1 auto",
							overflow: "auto",
						}}
						spacing={0.5}
						px={1}
					>
						<FormProvider {...formMethods}>
							<FieldList
								fieldToUpdate={fieldToUpdate}
								moduleName={moduleName}
								fieldState={fieldState}
								unregister={unregister}
								selectedFieldIds={selectedFieldIds}
								handleSelectChange={handleSelectChange}
								handleRemoveField={handleRemoveField}
							/>
						</FormProvider>
					</Stack>

					<Box>
						<Box pt={1}>
							<Button
								onClick={onAddMoreClick}
								startIcon={AddIcon(
									20,
									20,
									theme.palette.primary.main
								)}
								size="small"
								disabled={isAddMoreButtonDisabled}
								sx={{
									"&.Mui-disabled": {
										color: alpha("#33BC7E", 0.6),
									},
								}}
							>
								Add More Field
							</Button>
						</Box>

						<Stack
							p={1}
							direction="row"
							justifyContent="flex-end"
							spacing={2}
							width="100%"
						>
							<Box>
								<Button
									variant="contained"
									color="secondary"
									disableElevation
									disabled={isSaving}
									onClick={handleCloseMenu}
								>
									Cancel
								</Button>
							</Box>

							<Box>
								<Button
									variant="contained"
									disableElevation
									sx={{
										"&.Mui-disabled": {
											backgroundColor:
												theme.palette.primary.main,
											color: "rgba(255,255,255, 0.6)",
										},
									}}
									disabled={isSaveButtonDisabled}
									onClick={handleSubmit(onSave)}
								>
									Save
								</Button>
							</Box>
						</Stack>
					</Box>
				</Box>
			</Dialog>
		</React.Fragment>
	);
}
