import React, { useEffect, useState } from "react";
import { alpha, Box, Button, useTheme } from "@mui/material";
import { Tooltip } from "../../../../../../styles/twozo";
import { useFilterSaveViewContext } from "../../Context/FilterSaveViewContext";
import { isCriteriaValid } from "../../../../../../utils/FilterUtills";
import { useFilterDataContext } from "../../Context/FilterDataContext";
import useApplyFilter from "../../../../../../hooks/filter";

export default function FooterButtons() {
	const theme = useTheme();
	const {
		filterState,
		filterListByCondition,
		updateFilterUICriteriaList,
		updateAppliedFilterId,
		updateActiveFilterDetails,
	} = useFilterDataContext();

	const { hideSaveViewAsButton } = useFilterSaveViewContext();

	const { applyFilter } = useApplyFilter();

	//To identify if the filter is fulfilled.
	const [isValidFilter, setIsValidFilter] = useState(false);

	useEffect(() => {
		const hasValidFilterCondition =
			filterState.filterUICriteriaList.currentCriteria.some(
				isCriteriaValid
			);
		setIsValidFilter(hasValidFilterCondition);
	}, [filterState.filterUICriteriaList.currentCriteria]);

	//Clear All filter condition
	const clearFilterCondition = () => {
		hideSaveViewAsButton();
		updateFilterUICriteriaList([], []);
		updateAppliedFilterId(null);
		updateActiveFilterDetails({});
		filterListByCondition({
			filterId: filterState.activeFilterConfig.id,
		});
	};

	return (
		<Box
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-around",
				backgroundColor: "#FFF",
				padding: "15px 0px",
			}}
		>
			<Button
				variant="contained"
				color="secondary"
				disableElevation
				disabled={
					filterState.filterUICriteriaList.currentCriteria.length ===
					0
				}
				sx={{
					"&.Mui-disabled": {
						backgroundColor: theme.palette.secondary.main,
						color: alpha(theme.palette.secondary.contrastText, 0.6),
					},
				}}
				onClick={clearFilterCondition}
			>
				Clear All
			</Button>

			<Tooltip
				title={
					!isValidFilter
						? "Please add at least one condition to the filter"
						: ""
				}
				placement="right"
			>
				<span
					style={{
						cursor:
							!filterState.isEdited || !isValidFilter
								? "not-allowed"
								: "pointer",
					}}
				>
					<Button
						variant="contained"
						disableElevation
						disabled={!filterState.isEdited || !isValidFilter}
						sx={{
							"&.Mui-disabled": {
								backgroundColor: "rgba(51, 188, 126)",
								color: alpha("#FFFFFF", 0.6),
							},
						}}
						onClick={applyFilter}
					>
						Apply
					</Button>
				</span>
			</Tooltip>
		</Box>
	);
}
