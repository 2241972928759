import React from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function DealTitleUpdated(props) {
	const { eventData } = props;
	const theme = useTheme();
	const navigate = useNavigate();

	if (!eventData) return;

	const navigateToDeal = (dealId) => {
		navigate(`/deals/${dealId.toString()}`);
	};

	return (
		<React.Fragment>
			<Stack pt={1.2}>
				<Typography fontSize={15}>
					<span
						style={{
							fontSize: "15px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						{eventData?.salesOwner?.name}
					</span>{" "}
					updated the deal title from{" "}
					<span
						style={{
							fontSize: "15px",
							fontWeight: 500,
							color: eventData?.dealId
								? theme.palette.secondary.contrastText
								: "#000",
							cursor: eventData?.dealId ? "pointer" : "auto",
						}}
						onClick={() => navigateToDeal(eventData?.dealId)}
					>
						{eventData?.oldValue}
					</span>{" "}
					to{" "}
					<span
						style={{
							fontSize: "15px",
							fontWeight: 500,
							color: eventData?.dealId
								? theme.palette.secondary.contrastText
								: "#000",
							cursor: eventData?.dealId ? "pointer" : "auto",
						}}
						onClick={() => navigateToDeal(eventData?.dealId)}
					>
						{eventData?.newValue}
					</span>
				</Typography>
			</Stack>
		</React.Fragment>
	);
}
