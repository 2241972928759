import {
	Box,
	Button,
	Divider,
	List,
	ListItem,
	ListItemButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { getDropdownDataByName } from "../../../../../utils/dropdownData";
import React, { useEffect, useState } from "react";
import StaticDatePicker from "../../../../Elements/StaticDatePicker";
import SingleTimePicker from "../../../../Elements/SingleTimePicker";
import TimezoneMenu from "../../../../Elements/TimezoneMenu";
import { useUserConfigContext } from "../../../../../providers/UserConfigContextProvider";
import dayjs from "dayjs";
import { getZoneIdByTimeZoneValue } from "../../../../../utils/DateUtils";

const timeZoneList = getDropdownDataByName("ACCOUNT_TIMEZONE");

const ScheduledMailUI = (props) => {
	const { onScheduledMail, disableScheduledButton } = props;
	const theme = useTheme();

	const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState("");
	const [scheduledDate, setScheduledDate] = useState(new Date());
	const [scheduledTime, setScheduledTime] = useState(new Date().valueOf());

	const [selectedTimeZoneValue, setSelectedTimeZoneValue] = useState("");
	const { timeZoneId } = useUserConfigContext();

	useEffect(() => {
		if (timeZoneId) {
			let defaultTimeZone = timeZoneList?.find((timeZone) => {
				return timeZone?.zoneId === timeZoneId;
			});
			setSelectedTimeZoneValue(defaultTimeZone?.value);
		}
	}, [timeZoneId]);

	const handleChangeTimeZone = (event) => {
		event.stopPropagation();
		const { value } = event.target;
		setSelectedTimeZoneValue(value);
	};

	const getNextMonday = () => {
		const date = new Date();
		const nextMonday = new Date(
			date.setDate(date.getDate() + ((8 - date.getDay()) % 7 || 7))
		);
		return nextMonday;
	};

	const getNextDay = () => {
		const today = new Date();
		const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
		return tomorrow;
	};

	const handleNextMonday = (suggestionDateIndex) => {
		const newDate = new Date(getNextMonday());
		newDate.setHours(9, 0);
		setSelectedSuggestionIndex(suggestionDateIndex);
		setScheduledDate(newDate);
		setScheduledTime(newDate);
	};

	const handleNextDayMorning = (suggestionDateIndex) => {
		const newDate = new Date(getNextDay());
		newDate.setHours(9, 0);
		setSelectedSuggestionIndex(suggestionDateIndex);
		setScheduledDate(newDate);
		setScheduledTime(newDate);
	};

	const handleNextDayAfternoon = (suggestionDateIndex) => {
		const newDate = new Date(getNextDay());
		newDate.setHours(14, 0);
		setSelectedSuggestionIndex(suggestionDateIndex);
		setScheduledDate(newDate);
		setScheduledTime(newDate);
	};

	const onDateInputChange = (value) => {
		setScheduledDate(value.valueOf());
		setSelectedSuggestionIndex("");
	};

	const onTimeInputChange = (unixTimeStamp) => {
		setScheduledTime(unixTimeStamp);
		setSelectedSuggestionIndex("");
	};

	const suggestionsForScheduledMail = [
		{
			name: "Next day Morning",
			key: "next day morning",
			action: handleNextDayMorning,
		},
		{
			name: "Next day Afternoon",
			path: "next day afternoon",
			action: handleNextDayAfternoon,
		},
		{
			name: "Monday Morning",
			path: "monday morning",
			action: handleNextMonday,
		},
	];

	const handleSchedule = () => {
		let selectedZoneId = getZoneIdByTimeZoneValue(selectedTimeZoneValue);
		let scheduledDateAndTime = dayjs.tz(scheduledDate, selectedZoneId);

		let hours = dayjs(scheduledTime).get("hours");
		let minutes = dayjs(scheduledTime).get("minutes");
		scheduledDateAndTime = scheduledDateAndTime
			.set("hours", hours)
			.set("minutes", minutes);

		onScheduledMail(scheduledDateAndTime.valueOf());
	};

	return (
		<React.Fragment>
			<Box p={1}>
				<TimezoneMenu
					value={selectedTimeZoneValue}
					onChange={handleChangeTimeZone}
				/>
			</Box>

			<Divider />

			<Stack direction="row" justifyContent="space-between">
				<Box width="40%">
					<List>
						{suggestionsForScheduledMail.map(
							(suggestion, index) => (
								<ListItem key={index} disablePadding>
									<ListItemButton
										style={{
											height: "40px",
											backgroundColor:
												selectedSuggestionIndex ===
												index
													? "#E7F7F0"
													: "transparent",
										}}
										onClick={() => suggestion.action(index)}
									>
										<Typography fontSize={13}>
											{suggestion.name}
										</Typography>
									</ListItemButton>
								</ListItem>
							)
						)}
					</List>
				</Box>

				<Divider orientation="vertical" flexItem />

				<Stack width="60%">
					<StaticDatePicker
						onChange={onDateInputChange}
						value={scheduledDate}
						disablePast
					/>

					<Divider flexItem style={{ margin: "0px 10px" }} />

					<Box style={{ padding: "16px 16px 0px 16px" }}>
						<SingleTimePicker
							value={scheduledTime}
							onChange={onTimeInputChange}
							style={{
								timeFieldStyle: {
									border: "none",
									padding: "0px",
									backgroundColor:
										theme.palette.secondary.main,
									backfaceVisibility: "hidden",
									width: "fit-content",
									minHeight: "35px",
									margin: "0px",
								},
							}}
							hiddenTimeIcon={true}
							toggleButtonStyle={{ marginLeft: "0px" }}
						/>
					</Box>

					<Stack direction="row" justifyContent="end" pr={2} pb={2}>
						<Button
							variant="contained"
							disableElevation
							onClick={handleSchedule}
							disabled={disableScheduledButton}
						>
							Schedule
						</Button>
					</Stack>
				</Stack>
			</Stack>
		</React.Fragment>
	);
};

export default ScheduledMailUI;
