import { default as CallIcon } from "../assets/icons/call";
import { default as MailIcon } from "../assets/icons/mail";
import { default as TaskIcon } from "../assets/icons/task";
import { default as MeetingIcon } from "../assets/icons/meeting";
import { default as ContactIcon } from "../assets/icons/contact";
import { default as SingleContactIcon } from "../assets/icons/singleContact";
import { default as NoteIcon } from "../assets/icons/note";
import { default as QuoteIcon } from "../assets/icons/quote";
import { default as LunchIcon } from "../assets/icons/lunch";
import { default as DateIcon } from "../assets/icons/date";
import { default as NotificationIcon } from "../assets/icons/notification";
import { default as CompanyIcon } from "../assets/icons/company";
import { default as DealsIcon } from "../assets/icons/deals";
import { default as SearchIcon } from "../assets/icons/search";
import { default as AddIcon } from "../assets/icons/add";
import { default as CopyIcon } from "../assets/icons/copy";
import { default as CustomIcon } from "../assets/icons/custom";
import { default as EditIcon } from "../assets/icons/edit";
import { default as ExportIcon } from "../assets/icons/export";
import { default as FilesIcon } from "../assets/icons/files";
import { default as InfoIcon } from "../assets/icons/info";
import { default as KanbanIcon } from "../assets/icons/kanban";
import { default as TimeIcon } from "../assets/icons/time";
import { default as FavouriteIcon } from "../assets/icons/favouriteOff";
import { default as ProductIcon } from "../assets/icons/product";
import { default as SendIcon } from "../assets/icons/send";
import { default as SettingsIcon } from "../assets/icons/settings";
import { default as TickIcon } from "../assets/icons/tick";
import { default as ActivityIcon } from "../assets/icons/activity";
import { default as CalendarSyncIcon } from "../assets/icons/calendarSync";
import { default as LostIcon } from "../assets/icons/thumbsDown";
import { default as CalendarIcon } from "../assets/icons/activity";
import { default as KeyIcon } from "../assets/icons/key";
import { default as TeamsIcon } from "../assets/icons/teams";
import { default as UserIcon } from "../assets/icons/user";

const iconsByname = {
	search: {
		name: "search",
		icon: SearchIcon,
	},
	call: {
		name: "call",
		icon: CallIcon,
	},
	activity: {
		name: "activity",
		icon: ActivityIcon,
	},
	add: {
		name: "add",
		icon: AddIcon,
	},
	company: {
		name: "company",
		icon: CompanyIcon,
	},
	contact: {
		name: "contact",
		icon: ContactIcon,
	},
	singleContact: {
		name: "singleContact",
		icon: SingleContactIcon,
	},
	copy: {
		name: "copy",
		icon: CopyIcon,
	},
	custom: {
		name: "custom",
		icon: CustomIcon,
	},
	date: {
		name: "date",
		icon: DateIcon,
	},
	deal: {
		name: "deal",
		icon: DealsIcon,
	},
	edit: {
		name: "edit",
		icon: EditIcon,
	},
	export: {
		name: "export",
		icon: ExportIcon,
	},
	file: {
		name: "file",
		icon: FilesIcon,
	},
	info: {
		name: "info",
		icon: InfoIcon,
	},
	kanban: {
		name: "kanban",
		icon: KanbanIcon,
	},
	lunch: {
		name: "lunch",
		icon: LunchIcon,
	},
	mail: {
		name: "mail",
		icon: MailIcon,
	},
	meeting: {
		name: "meeting",
		icon: MeetingIcon,
	},
	note: {
		name: "note",
		icon: NoteIcon,
	},
	time: {
		name: "time",
		icon: TimeIcon,
	},
	favourite: {
		name: "favourite",
		icon: FavouriteIcon,
	},
	notification: {
		name: "notification",
		icon: NotificationIcon,
	},
	product: {
		name: "product",
		icon: ProductIcon,
	},
	quote: {
		name: "quote",
		icon: QuoteIcon,
	},
	send: {
		name: "send",
		icon: SendIcon,
	},
	settings: {
		name: "settings",
		icon: SettingsIcon,
	},
	task: {
		name: "task",
		icon: TaskIcon,
	},
	tick: {
		name: "tick",
		icon: TickIcon,
	},
	calendarSynced: {
		name: "calendarSync",
		icon: CalendarSyncIcon,
	},
	lost: {
		name: "lost",
		icon: LostIcon,
	},
	calendar: {
		name: "calendar",
		icon: CalendarIcon,
	},
	key: {
		name: "key",
		icon: KeyIcon,
	},
	team: {
		name: "team",
		icon: TeamsIcon,
	},
	collaborator: {
		name: "collaborator",
		icon: UserIcon,
	},
	user: {
		name: "user",
		icon: UserIcon,
	},
};

export const getIconByName = (name) => {
	return iconsByname[name]
		? iconsByname[name].icon
		: iconsByname["info"].icon;
};
