import React, { useState } from "react";
import {
	Box,
	Stack,
	TextField,
	Typography,
	useTheme,
	Chip,
	CircularProgress,
	MenuItem,
	Skeleton,
} from "@mui/material";
import { default as AddIcon } from "../../../../../../../../assets/icons/add";
import { default as CloseIcon } from "../../../../../../../../assets/icons/close";
import Autocomplete from "../../../../../../Autocomplete";
import { MenuItemText } from "../../../../../../../../styles/twozo";
import { useTagDropdownList } from "../../../../../../../../hooks/services/tags";
import { useLastRowRef } from "../../../../../../../../hooks/common/InfiniteScroll";
import { useDebouncedCallback } from "use-debounce";

export default function TagsInput(props) {
	const {
		inputRef,
		value,
		onChange,
		error,
		placeHolder,
		textFieldSx,
		isTagAdditionAllowed = true,
	} = props;

	const theme = useTheme();

	const [tagInputValue, setTagInputValue] = useState("");

	const {
		data: tagList,
		isLoading: isLoadingTagList,
		isFetching: isFetchingTagList,
		hasNextPage: hasNextPage,
		fetchNextPage: fetchNextPage,
	} = useTagDropdownList(tagInputValue);

	const lastOptionRef = useLastRowRef(
		fetchNextPage,
		hasNextPage,
		isFetchingTagList
	);

	const handleTagInputChange = (event) => {
		const { value } = event.target;
		setTagInputValue(value.trim());
	};

	const onInputChange = useDebouncedCallback(
		(event) => {
			handleTagInputChange(event);
		},
		500 // delay in ms
	);

	const filterOptions = (options, params) => {
		if (isLoadingTagList) {
			return options;
		} else {
			const { inputValue } = params;
			const filteredOption = [...options];
			const isExistingTagName = filteredOption.some(
				(option) => inputValue === option.name
			);

			const isSelectedTagName = value?.some((tag) => {
				return tag?.name === inputValue;
			});

			if (
				!isExistingTagName &&
				isTagAdditionAllowed &&
				!isSelectedTagName
			) {
				filteredOption.unshift({
					name: inputValue,
					isNewOption: true,
				});
			}
			return filteredOption;
		}
	};

	const onChangeTagsValue = (_, values) => {
		onChange(values);
		setTagInputValue("");
	};

	const isDropdownMenuOpen = () => {
		return tagInputValue.length > 0;
	};

	const CustomListboxComponent = function (props) {
		return (
			<Box {...props}>
				{props.children}

				<Box ref={lastOptionRef}>
					{!isLoadingTagList && isFetchingTagList && hasNextPage ? (
						<MenuItem
							style={{
								height: "40px",
								width: "100%",
							}}
						>
							<Skeleton width="200px" height="16px" />
						</MenuItem>
					) : null}
				</Box>
			</Box>
		);
	};

	const renderOption = (props, option) => {
		return (
			<Box
				{...props}
				key={option?.value}
				style={{
					minHeight: "40px",
					width: "100%",
				}}
			>
				{isTagAdditionAllowed && option.isNewOption ? (
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						width="100%"
					>
						<MenuItemText
							fontWeight={500}
							color={theme.palette.primary.main}
						>
							{option?.name}
						</MenuItemText>

						<Box display="flex">
							{AddIcon(20, 20, theme.palette.primary.main)}
						</Box>
					</Stack>
				) : (
					<MenuItemText>{option?.name}</MenuItemText>
				)}
			</Box>
		);
	};

	return (
		<React.Fragment>
			<Autocomplete
				options={tagList || []}
				filterOptions={filterOptions}
				open={isDropdownMenuOpen()}
				value={value || []}
				onChange={onChangeTagsValue}
				filterSelectedOptions={true}
				freeSolo={false}
				noOptionsText="No results found"
				loading={isLoadingTagList}
				isOptionEqualToValue={(option, value) =>
					option.name === value.name
				}
				loadingText={
					<Stack
						alignItems="center"
						justifyContent="center"
						height="140px"
					>
						<CircularProgress size="18px" />
					</Stack>
				}
				ListboxComponent={CustomListboxComponent}
				renderTags={(value, getTagProps) =>
					value?.map((option, index) => {
						const { key, ...tagProps } = getTagProps({ index });
						return (
							<Chip
								key={key}
								size="small"
								label={
									<Typography fontSize={14} color="#000">
										{option?.name}
									</Typography>
								}
								color="secondary"
								deleteIcon={CloseIcon(16, 16, "#000")}
								sx={{
									borderRadius: "8px",
								}}
								{...tagProps}
							/>
						);
					})
				}
				renderOption={renderOption}
				renderInput={(params) => (
					<TextField
						{...params}
						inputRef={inputRef}
						value={tagInputValue}
						onChange={onInputChange}
						placeholder={placeHolder || "Enter tag name"}
						inputProps={{
							...params.inputProps,
							style: {
								fontSize: "14px",
							},
						}}
						sx={{
							...textFieldSx,
						}}
						error={!!error}
						onBlur={() => setTagInputValue("")}
					/>
				)}
			/>
		</React.Fragment>
	);
}
