import { removeFieldsWithEmptyValues } from "../common";

export const filterFieldNames = {
	sourceId: "sourceId",
	connectorId: "connectorId",
	comparatorId: "comparatorId",
	fieldId: "fieldId",
	optionId: "optionId",
	additionalOption: "additionalOption",
	valueEntityId: "valueEntityId",
	value: "value",
	multiSelectValues: "multiSelectValues",
};

export const filterConnectorsList = {
	AND: "AND",
	OR: "OR",
};

export const fieldType = {
	time: "TIME",
	timeRange: "TIME_RANGE",
	monetary: "MONETARY",
	premiumProduct: "PREMIUM_PRODUCT",
	multiSelect: "MULTI_SELECT",
	singleNumber: "SINGLE_NUMBER",
	owner: "OWNER",
	date: "DATE",
	dropdown: "DROPDOWN",
	companyOnboard: "COMPANY_ONBOARD",
	company: "COMPANY",
	tags: "TAGS",
	territory: "TERRITORY",
	contactsOnboard: "CONTACTS_ONBOARD",
	contact: "CONTACT",
	contactOnboard: "CONTACT_ONBOARD",
	deal: "DEAL",
	dealValue: "DEAL_VALUE",
	dateRange: "DATE_RANGE",
	dateTimeRange: "DATE_TIME_RANGE",
	dateTime: "DATE_TIME",
	owners: "OWNERS",
};

export const filterVisibility = {
	justMe: 1,
	everyone: 2,
	selected: 3,
};

export const filterTypes = {
	filter: "filter",
	salesOwner: "salesOwner",
};

export const isCriteriaEqual = (currentCriteria, lastAppliedCriteria) => {
	if (!currentCriteria || !lastAppliedCriteria) return false;

	const areMultiselectValuesSame = (
		currentValues = [],
		appliedValues = []
	) => {
		if (!currentValues.length && !appliedValues.length) return true;
		if (currentValues.length !== appliedValues.length) return false;

		return appliedValues.every(({ value }) =>
			currentValues.some((currentValue) => currentValue.value === value)
		);
	};

	return (
		(currentCriteria?.fieldId?.value?.name ===
			lastAppliedCriteria?.fieldId?.value?.name ||
			currentCriteria?.optionId?.value?.name ===
				lastAppliedCriteria?.optionId?.value?.name) &&
		currentCriteria?.comparatorId?.value?.name ===
			lastAppliedCriteria?.comparatorId?.value?.name &&
		currentCriteria?.value?.value === lastAppliedCriteria?.value?.value &&
		currentCriteria?.additionalOption?.displayName ===
			lastAppliedCriteria?.additionalOption?.displayName &&
		areMultiselectValuesSame(
			currentCriteria.multiSelectValues,
			lastAppliedCriteria.multiSelectValues
		)
	);
};

export const areCriteriaListsEqual = (
	currentCriteriaList,
	lastAppliedCriteriaList
) =>
	lastAppliedCriteriaList.length === currentCriteriaList.length &&
	currentCriteriaList.every((currentCriteria, index) =>
		isCriteriaEqual(currentCriteria, lastAppliedCriteriaList[index])
	);

export const hasMultipleOptions = (criteria) => {
	return (
		criteria?.fieldData?.fieldType?.name === fieldType.multiSelect ||
		criteria?.fieldData?.fieldType?.name === fieldType.tags ||
		criteria?.fieldData?.fieldType?.name === fieldType.contactsOnboard ||
		criteria?.fieldData?.fieldType?.name === fieldType.owners ||
		criteria?.comparatorId?.value?.isMultiValueAllowed
	);
};

export const isCriteriaValid = (criteria) => {
	const isMultiSelectField = hasMultipleOptions(criteria);

	const hasValidValue = isMultiSelectField
		? criteria.multiSelectValues.length > 0
		: criteria.value.value !== "" || criteria.valueEntityId.value !== "";

	return (
		(!hasValidValue &&
			(criteria.comparatorId.value.name === "is empty" ||
				criteria.comparatorId.value.name === "is not empty")) ||
		hasValidValue
	);
};

export const getApplyFilterRequestData = (criteria) => {
	let requestData = {};
	requestData.criteriaList = [];

	//Grouped and ordered filter conditions based on connector type (AND first, then OR)
	const andFilterConditions = criteria?.filter(
		(item) => item.connectorId.value.name === filterConnectorsList.AND
	);
	const orFilterConditions = criteria?.filter(
		(item) => item.connectorId.value.name === filterConnectorsList.OR
	);

	const filterConditions = [...andFilterConditions, ...orFilterConditions];

	for (let index = 0; index < filterConditions.length; index++) {
		const requestCriteriaListData = { field: {} };
		const inputValue = {};

		for (const filterDataElementKey in filterConditions[index]) {
			if (
				Object.hasOwnProperty.call(
					filterConditions[index],
					filterDataElementKey
				)
			) {
				const formElement =
					filterConditions[index][filterDataElementKey];
				const fieldName = formElement.fieldName;

				if (
					filterDataElementKey === filterFieldNames.multiSelectValues
				) {
					const values =
						formElement?.length > 0
							? formElement.map((option) => {
									const valueObject = {
										valueEntityId: option.value,
										value: option.name,
									};

									if (option.id) {
										valueObject.id = option.id;
									}

									return valueObject;
								})
							: "";
					requestCriteriaListData.values = values;
				}

				if (fieldName === filterFieldNames.valueEntityId) {
					inputValue[fieldName] = formElement.value;
				}

				if (fieldName === filterFieldNames.value) {
					inputValue[fieldName] = formElement.value;
					inputValue.id =
						formElement.id && formElement.value
							? formElement.id
							: "";
				}

				if (
					(fieldName === filterFieldNames.fieldId &&
						formElement.value.id !== "") ||
					fieldName === filterFieldNames.optionId
				) {
					requestCriteriaListData.field[fieldName] =
						formElement.value.id;
				}

				if (
					fieldName === filterFieldNames.additionalOption &&
					formElement.value.name !== ""
				) {
					requestCriteriaListData.field[fieldName] =
						formElement.value;
				}

				if (fieldName === filterFieldNames.sourceId) {
					requestCriteriaListData[fieldName] = formElement.value.id;
					requestCriteriaListData.id = formElement.id
						? formElement.id
						: "";
				}

				if (
					fieldName === filterFieldNames.comparatorId ||
					fieldName === filterFieldNames.connectorId
				) {
					requestCriteriaListData[fieldName] = formElement.value.id;
				}
			}
		}

		removeFieldsWithEmptyValues(inputValue);
		if (Object.keys(inputValue).length !== 0) {
			requestCriteriaListData.values = [inputValue];
		}

		removeFieldsWithEmptyValues(requestCriteriaListData.field);
		removeFieldsWithEmptyValues(requestCriteriaListData);
		requestData.criteriaList.push(requestCriteriaListData);
		removeFieldsWithEmptyValues(requestData);
	}

	return requestData;
};
