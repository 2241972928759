import { useState } from "react";
import ToggleButtonGroup from "../../../../Elements/ToggleButtonGroup";
import { Box, Stack, ToggleButton } from "@mui/material";
import UnderlyingDataTable from "./UnderlyingDataTable";

export default function UnderlyingData(params) {
	const { viewProperties, viewId, viewTypeId } = params;
	const [childViewId, setChildViewId] = useState(
		viewProperties?.underlyingConfig?.[0]?.childViewId
	);

	const handleViewToggleButtonGroup = (_, type) => {
		if (type) {
			let underlyingConfig = viewProperties?.underlyingConfig?.find(
				(config) => {
					return config?.childViewId === type;
				}
			);
			setChildViewId(underlyingConfig?.childViewId);
		}
	};

	return (
		<>
			<Box height="100%" display="flex" flexDirection="column">
				{viewProperties?.underlyingConfig?.length > 1 && (
					<Stack pl={2} pt={2} width="fit-content">
						<ToggleButtonGroup
							value={childViewId}
							onChange={handleViewToggleButtonGroup}
						>
							{viewProperties?.underlyingConfig?.map((config) => (
								<ToggleButton
									value={config?.childViewId}
									key={config?.childViewId}
								>
									{config?.module?.name?.toLowerCase()}
								</ToggleButton>
							))}
						</ToggleButtonGroup>
					</Stack>
				)}

				{viewProperties?.underlyingConfig?.length > 0 &&
					viewProperties?.underlyingConfig?.map((config) => (
						<Box
							key={config.viewId}
							hidden={config.childViewId !== childViewId}
							style={{
								flex: "1 1 auto",
								overflow: "auto",
								minHeight: 0,
							}}
						>
							<UnderlyingDataTable
								viewId={viewId}
								moduleId={config?.module?.id}
								viewTypeId={viewTypeId}
								childViewId={config?.childViewId}
							/>
						</Box>
					))}
			</Box>
		</>
	);
}
