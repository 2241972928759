import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthContextProvider";
import { PERMISSIONS } from "../../utils/Auth";
import { useCallback } from "react";

export const useAuth = () => {
	const { permissions: allowedPermissions } = useAuthContext();

	const isUserAuthenticated = useCallback(() => {
		return !allowedPermissions.isUserNotLoggedIn;
	}, [allowedPermissions.isUserNotLoggedIn]);

	const isUserAllowedFor = useCallback(
		(permission) => {
			if (!isUserAuthenticated()) {
				return false;
			}

			if (
				permission ===
				PERMISSIONS.allowlistedEntity.allowlistedPermission
			) {
				return true;
			}

			return !!allowedPermissions?.[permission.entity]?.[
				permission.action
			];
		},
		[allowedPermissions, isUserAuthenticated]
	);

	const navigateToHomeBasedOnPermission = () => {
		if (isUserAllowedFor(PERMISSIONS.deal.view)) {
			return <Navigate to="/deals" />;
		} else if (isUserAllowedFor(PERMISSIONS.contact.view)) {
			return <Navigate to="/contacts" />;
		} else if (isUserAllowedFor(PERMISSIONS.company.view)) {
			return <Navigate to="/companies" />;
		} else if (isUserAllowedFor(PERMISSIONS.product.view)) {
			return <Navigate to="/products" />;
		}
		return <Navigate to="/activities" />;
	};

	return {
		isUserAuthenticated,
		isUserAllowedFor,
		navigateToHomeBasedOnPermission,
	};
};
