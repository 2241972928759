import { apiClient } from "..";
import { searchTypes } from "../../utils/dropdownData";
import { getCallUrl } from "../apiConfig";
import { assertError } from "../apiHelper";
import postFieldsApi from "../fields/postFieldsApi";
import { getPhoneUrl } from "../apiConfig";

export const getAvailablePhoneNumberList = async (pageParam) => {
	let requestData = getAvailablePhoneNumberListRequest(pageParam);
	return await postFieldsApi(requestData);
};

export const getContactPhoneNumberList = async (pageParam, searchValue) => {
	let requestData = getContactPhoneNumberListRequest(pageParam, searchValue);
	return await postFieldsApi(requestData);
};

export const getPhoneCallToken = async () => {
	let callTokenUrl = getCallManagementUrl();
	let response = await apiClient.get(callTokenUrl);
	assertError(response, callTokenUrl);
	return response.data.data;
};

export const getPhoneNumbersTableData = async (
	pageParam,
	fieldToSort,
	searchValue
) => {
	let requestData = getPhoneNumbersTableDataRequest(
		pageParam,
		fieldToSort,
		searchValue
	);
	return await postPhoneNumberManagementApi(requestData);
};

export const buyPhoneNumber = async ({ number, locationDetails }) => {
	let requestData = getBuyPhoneNumberRequest(number, locationDetails);
	return await postPhoneNumberManagementApi(requestData);
};

export const updatePhoneNumber = async ({ id, displayName, recordType }) => {
	let requestData = getUpdatePhoneNumberRequest(id, displayName, recordType);
	return await postPhoneNumberManagementApi(requestData);
};

export const deletePhoneNumber = async (id) => {
	let requestData = getDeletePhoneNumberRequest(id);
	return await postPhoneNumberManagementApi(requestData);
};

export const getCountryList = async (pageParam, searchValue) => {
	let requestData = getCountryListRequest(pageParam, searchValue);
	return await postFieldsApi(requestData);
};

export const getStateList = async (countryId, pageParam, searchValue) => {
	let requestData = getStateListRequest(countryId, pageParam, searchValue);
	return await postFieldsApi(requestData);
};

export const getLocalityList = async (
	countryId,
	stateId,
	pageParam,
	searchValue
) => {
	let requestData = getLocalityListRequest(
		countryId,
		stateId,
		pageParam,
		searchValue
	);
	return await postFieldsApi(requestData);
};

export const getPhoneNumberList = async (
	countryCode,
	countryName,
	stateName,
	localityName,
	pageParam,
	searchValue
) => {
	let requestData = getPhoneNumberListRequest(
		countryCode,
		countryName,
		stateName,
		localityName,
		pageParam,
		searchValue
	);
	return await postFieldsApi(requestData);
};

const getAvailablePhoneNumberListRequest = (pageParam) => {
	return {
		type: "dropdownData",
		data: {
			name: "phoneNumberList",
			...pageParam,
		},
	};
};

const getContactPhoneNumberListRequest = (pageParam, searchValue = "") => {
	return {
		type: "dropdownData",
		data: {
			name: "contactNumberList",
			criteria: {
				type: searchTypes.contains,
				value: searchValue,
			},
			...pageParam,
		},
	};
};

const getPhoneNumbersTableDataRequest = (
	pageParam,
	fieldToSort,
	searchValue = ""
) => {
	return {
		type: "list",
		data: {
			searchText: searchValue,
			sort: fieldToSort,
			...pageParam,
		},
	};
};

const getBuyPhoneNumberRequest = (number, locationDetails) => {
	return {
		type: "buyNumber",
		data: {
			number: number,
			locationDetails: locationDetails,
			/* eslint no-undef: 0 */
			isUnderDevelopment: process.env.NODE_ENV === "development",
		},
	};
};

const getUpdatePhoneNumberRequest = (id, displayName, recordType) => {
	return {
		type: "update",
		data: {
			id: id,
			displayName: displayName,
			recordType: recordType,
			/* eslint no-undef: 0 */
			isUnderDevelopment: process.env.NODE_ENV === "development",
		},
	};
};

const getDeletePhoneNumberRequest = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
			/* eslint no-undef: 0 */
			isUnderDevelopment: process.env.NODE_ENV === "development",
		},
	};
};

const getCountryListRequest = (pageParam, searchValue = "") => {
	return {
		type: "dropdownData",
		data: {
			name: "availableCountryList",
			criteria: {
				type: searchTypes.contains,
				value: searchValue,
			},
			...pageParam,
		},
	};
};

const getStateListRequest = (countryId, pageParam, searchValue = "") => {
	return {
		type: "dropdownData",
		data: {
			name: "availableStateList",
			countryId: countryId,
			criteria: {
				type: searchTypes.contains,
				value: searchValue,
			},
			...pageParam,
		},
	};
};

const getLocalityListRequest = (
	countryId,
	stateId,
	pageParam,
	searchValue = ""
) => {
	return {
		type: "dropdownData",
		data: {
			name: "availableLocalityList",
			countryId: countryId,
			stateId: stateId,
			criteria: {
				type: searchTypes.contains,
				value: searchValue,
			},
			...pageParam,
		},
	};
};

const getPhoneNumberListRequest = (
	countryCode,
	countryName,
	stateName,
	localityName,
	pageParam,
	searchValue = ""
) => {
	return {
		type: "dropdownData",
		data: {
			name: "availableNumberList",
			countryCode: countryCode,
			countryName: countryName,
			stateName: stateName,
			localityName: localityName,
			criteria: {
				type: searchTypes.contains,
				value: searchValue,
			},
			...pageParam,
		},
	};
};

const postPhoneNumberManagementApi = async (requestData) => {
	let phoneUrl = getPhoneNumberManagementUrl();
	let response = await apiClient.post(phoneUrl, requestData);
	assertError(response, phoneUrl);
	return response.data.data;
};

const getPhoneNumberManagementUrl = () => {
	return getPhoneUrl() + "/number/mgmt";
};

const getCallManagementUrl = () => {
	return getCallUrl() + "/token/mgmt";
};
