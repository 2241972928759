export default function OutgoingCall(
	width = "24",
	height = "24",
	color = "#fff"
) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M23 7L23 1L17 1"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16 8L23 0.999999"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M20.1747 19.2776C20.0214 19.6016 19.8231 19.9076 19.5617 20.1956C19.1201 20.6816 18.6333 21.0326 18.0835 21.2576C17.5427 21.4826 16.9568 21.5996 16.3259 21.5996C15.4065 21.5996 14.424 21.3836 13.3875 20.9426C12.3509 20.5016 11.3144 19.9076 10.2868 19.1606C9.25027 18.4046 8.2678 17.5676 7.33039 16.6406C6.402 15.7046 5.56374 14.7236 4.81562 13.6976C4.07651 12.6716 3.48162 11.6456 3.04897 10.6286C2.61632 9.60261 2.39999 8.62161 2.39999 7.68561C2.39999 7.07361 2.50816 6.48861 2.72448 5.94861C2.94081 5.39961 3.28332 4.89561 3.76104 4.44561C4.3379 3.87861 4.96885 3.59961 5.63585 3.59961C5.88823 3.59961 6.1406 3.65361 6.36594 3.76161C6.60029 3.86961 6.80761 4.03161 6.96985 4.26561L9.06099 7.20861C9.22323 7.43361 9.3404 7.64061 9.42153 7.83861C9.50265 8.02761 9.54772 8.21661 9.54772 8.38761C9.54772 8.60361 9.48462 8.81961 9.35843 9.02661C9.24126 9.23361 9.07 9.44961 8.85367 9.66561L8.16865 10.3766C8.0695 10.4756 8.02443 10.5926 8.02443 10.7366C8.02443 10.8086 8.03344 10.8716 8.05147 10.9436C8.07851 11.0156 8.10555 11.0696 8.12358 11.1236C8.28582 11.4206 8.56524 11.8076 8.96184 12.2756C9.36744 12.7436 9.80009 13.2206 10.2688 13.6976C10.7555 14.1746 11.2242 14.6156 11.7019 15.0206C12.1706 15.4166 12.5582 15.6866 12.8647 15.8486C12.9098 15.8666 12.9638 15.8936 13.0269 15.9206C13.099 15.9476 13.1712 15.9566 13.2523 15.9566C13.4055 15.9566 13.5227 15.9026 13.6218 15.8036L14.3069 15.1286C14.5322 14.9036 14.7485 14.7326 14.9558 14.6246C15.1631 14.4986 15.3704 14.4356 15.5958 14.4356C15.767 14.4356 15.9473 14.4716 16.1456 14.5526C16.3439 14.6336 16.5512 14.7506 16.7766 14.9036L19.76 17.0186C19.9944 17.1806 20.1566 17.3696 20.2558 17.5946C20.3459 17.8196 20.4 18.0446 20.4 18.2966C20.4 18.6206 20.3279 18.9536 20.1747 19.2776Z"
				stroke={color}
				strokeWidth="1.5"
			/>
		</svg>
	);
}
