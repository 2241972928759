import { alpha, Box, Button, Stack, useTheme } from "@mui/material";
import React from "react";
import { default as CloseIcon } from "../../../../../../../assets/icons/close";
import { useFieldContext } from "../../../FieldContext";
import { default as TickIcon } from "../../../../../../../assets/icons/tick";
import { useCreateField } from "../../../../../../../hooks/services/dataFields";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../../../utils/notification/notificationMessages";
import { useFieldListContext } from "../../../../FieldListContext";

const selectFieldTypes = {
	DROPDOWN: "DROPDOWN",
	MULTI_SELECT: "MULTI_SELECT",
};

export default function CreateButtons(props) {
	const { clearCreatedField, field, moduleName } = props;
	const theme = useTheme();

	const createMutation = useCreateField(moduleName);

	const { resetCreateMode, resetUpdateMode } = useFieldListContext();
	const { fieldState, onFieldValueChange, resetFieldNameError } =
		useFieldContext();

	const isAddButtondisabled =
		!fieldState.unSavedFieldData.fieldName || createMutation.isLoading;

	const handleAddClick = () => {
		let requestData = getFieldCreateRequestData();
		if (
			isSelectType(requestData.type) &&
			Array.isArray(requestData.config.options) &&
			requestData.config.options.length <= 1
		) {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.selectChoiceError,
			});
			return;
		}
		createField(requestData);
	};

	const createField = (fieldData) => {
		delete fieldData?.id;
		createMutation.mutate(fieldData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.fieldCreated,
				});
				resetCreateMode();
				clearCreatedField();
				resetFieldNameError();
			},
			onError: (error) => {
				let parsedError = JSON.parse(error?.message);
				let errorMessage = parsedError?.failedRecords?.[0]?.error;

				enqueueSnackbar({
					variant: notificationVariants.error,
					message:
						errorMessage ?? notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	const isSelectType = (fieldType) => {
		return (
			fieldType === selectFieldTypes.DROPDOWN ||
			fieldType === selectFieldTypes.MULTI_SELECT
		);
	};

	const getFieldCreateRequestData = () => {
		let requestData = { ...field, config: { ...field.config } };
		let fieldType = fieldState.unSavedFieldData.fieldType || field.type;
		requestData.fieldName = fieldState.unSavedFieldData.fieldName;
		requestData.type = fieldType;
		requestData.config.required = fieldState.unSavedFieldData.isRequired;
		requestData.config.addView = fieldState.unSavedFieldData.isAddView;
		requestData.config.options = null;

		if (isSelectType(fieldType)) {
			requestData.config.options = getFilteredSelectOptions();
		}

		return requestData;
	};

	const getFilteredSelectOptions = () => {
		if (Array.isArray(fieldState.unSavedFieldData.selectChoices)) {
			const filteredSelectChoices =
				fieldState.unSavedFieldData.selectChoices.map((choice) => {
					if (choice.isNew) {
						let updatedChoice = { ...choice };
						delete updatedChoice.isNew;
						return updatedChoice;
					}
					return choice;
				});
			return filteredSelectChoices;
		}
		return [];
	};

	const handleRemoveClick = () => {
		onFieldValueChange(false);
		resetCreateMode();
		resetUpdateMode();
		clearCreatedField();
	};

	return (
		<React.Fragment>
			<Box>
				{fieldState.fieldInCreateMode ? (
					<Stack direction="row" spacing={2}>
						<Button
							variant="outlined"
							startIcon={CloseIcon(
								20,
								20,
								theme.palette.secondary.contrastText
							)}
							disableElevation
							onClick={handleRemoveClick}
							style={{
								borderColor: theme.palette.secondary.main,
							}}
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							color="secondary"
							disabled={isAddButtondisabled}
							startIcon={TickIcon(
								20,
								20,
								theme.palette.secondary.contrastText
							)}
							disableElevation
							sx={{
								"&.Mui-disabled": {
									backgroundColor:
										theme.palette.secondary.main,
									color: alpha(
										theme.palette.secondary.contrastText,
										0.6
									),
								},
							}}
							onClick={handleAddClick}
						>
							Add
						</Button>
					</Stack>
				) : null}
			</Box>
		</React.Fragment>
	);
}
