import React from "react";
import { TableCellText } from "../../../../styles/twozo";
import { getHourAndMinuteFormat } from "../../../../utils/DateUtils";

export default function TimeRange(props) {
	const { data } = props;

	if (!data) {
		return null;
	}

	return (
		<React.Fragment>
			<TableCellText>
				{getHourAndMinuteFormat(data.from)} -{" "}
				{getHourAndMinuteFormat(data.to)}
			</TableCellText>
		</React.Fragment>
	);
}
