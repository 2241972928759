import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import {
	createDeal,
	deleteDeal,
	getDealData,
	getDealProgressViewData,
	updateDealPipeline,
	updateDealStage,
	updateDealStateWithoutPipelineId,
	getDealSummaryData,
	updateDealSummary,
	updateExpectedCloseDate,
	getDealSwipeableSummaryData,
	addProductsToDeal,
	getDealList,
	getDealRelatedEntitiesData,
	getContactsByDeals,
	assignOwnerToDeals,
	getUpcomingActivitiesToDeal,
	getLinkedProductsToDeal,
	partialUpdateDeal,
	getDealsByStage,
	updateDeal,
} from "../../../api/deal/dealApi";
import {
	getDealDataKey,
	getDealProgressViewKey,
	getDealSummaryKey,
	getDealSwipeableSummaryKey,
	getDealTableKey,
	getDealListKey,
	getDealRelatedEntitiesKey,
	getContactsByDealsKey,
	getDealUpcomingActivitiesKey,
	getLinkedProductsToDealKey,
	getDealSummaryViewKey,
	getDealsByStageKey,
	getDealKanbanViewKey,
	getDealOverViewKey,
} from "../../../utils/queryKeys";
import { useInvalidateCompanyTimeline } from "../company/summary/timeline";
import { useInvalidateContactTimeline } from "../contact/summary/timeline";
import { useInvalidateDealParticipantsData } from "./dealParticipants";
import { useInvalidateDealTimelineData } from "./summary/timeline";
import { useInvalidateGroupFieldData } from "../common/field";
import { modules } from "../../../utils/common/ModulesName";
import { isEmptyObject } from "../../../utils/common";
import {
	dealDropdownPageSize,
	dealKanbanViewPageSize,
} from "../../../utils/queryConstants/deal";
import { useEffect } from "react";

const useUpcomingActivitiesToDeal = (dealId) => {
	const dealUpcomingActivitiesKey = getDealUpcomingActivitiesKey(dealId);
	return useQuery(
		dealUpcomingActivitiesKey,
		() => getUpcomingActivitiesToDeal(dealId),
		{
			enabled: !!dealId,
			select: (data) => data?.activities,
		}
	);
};

const useInvalidateDealTableData = () => {
	let queryClient = useQueryClient();
	let tableKey = getDealTableKey();
	return () => {
		queryClient.invalidateQueries({ queryKey: tableKey });
	};
};

const useInvalidateDealKanbanView = () => {
	let queryClient = useQueryClient();
	let dealKanbanViewKey = getDealKanbanViewKey();
	return () => {
		queryClient.invalidateQueries(dealKanbanViewKey);
	};
};

const useInvalidateSummary = (dealId) => {
	let queryClient = useQueryClient();
	let dealSummaryKey = getDealSummaryKey(dealId);
	let dealDataKey = getDealDataKey(dealId);
	return () => {
		queryClient.invalidateQueries(dealSummaryKey);
		queryClient.invalidateQueries(dealDataKey);
	};
};

const useDealData = (dealId) => {
	const dealKey = getDealDataKey(dealId);
	return useQuery(dealKey, () => getDealData(dealId));
};

const useInvalidateDealData = (dealId) => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getDealDataKey(dealId));
	};
};

const useDealsByStage = (stageId, criteria, sort) => {
	const queryClient = useQueryClient();

	//Keeping only first page on cache to avoid refetching when user comes to this page again.
	useEffect(() => {
		return () => {
			queryClient.setQueryData(
				getDealsByStageKey(stageId, criteria, sort),
				(currentData) => {
					return currentData
						? {
								pageParam:
									currentData.pageParams?.length > 0
										? [currentData.pageParams[0]]
										: currentData.pageParams,
								pages:
									currentData.pages?.length > 0
										? [currentData.pages[0]]
										: currentData.pages,
							}
						: undefined;
				}
			);
		};
	}, [criteria, queryClient, stageId, sort]);

	return useInfiniteQuery({
		queryKey: getDealsByStageKey(stageId, criteria, sort),
		queryFn: ({ pageParam }) => {
			const defaultTableRowFetchCount = dealKanbanViewPageSize;
			return getDealsByStage(
				stageId,
				{
					...criteria,
					start: pageParam
						? (pageParam - 1) * defaultTableRowFetchCount + 1
						: 1,
					limit: defaultTableRowFetchCount,
				},
				sort
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.data.hasMore
				? allPages.length + 1
				: undefined;
		},
		refetchOnWindowFocus: false,
		enabled: !isEmptyObject(criteria),
	});
};

const useInvalidateDealsByStage = (stageId) => {
	let queryClient = useQueryClient();
	const dealsByStageKey = getDealsByStageKey(stageId);
	return () => {
		queryClient.invalidateQueries(dealsByStageKey);
	};
};

const useCreateDeal = (contactId, companyId) => {
	let invalidateDealTableData = useInvalidateDealTableData();
	let invalidateCompanyTimeline = useInvalidateCompanyTimeline(companyId);
	let invalidateContactTimeline = useInvalidateContactTimeline(contactId);
	let invalidateDealKanbanView = useInvalidateDealKanbanView();
	return useMutation(createDeal, {
		onSuccess: () => {
			invalidateDealTableData();
			invalidateCompanyTimeline();
			invalidateContactTimeline();
			invalidateDealKanbanView();
		},
	});
};

const useEditDeal = (dealId) => {
	let invalidateLinkedProductsToDeal =
		useInvalidateLinkedProductsToDeal(dealId);
	let invalidateSummaryKey = useInvalidateSummary(dealId);
	let invalidateDealTableData = useInvalidateDealTableData();
	let invalidateParticipantsData = useInvalidateDealParticipantsData(dealId);
	let invalidateDealTimelineData = useInvalidateDealTimelineData(dealId);
	let invalidateDealOverViewData = useInvalidateDealOverViewData(dealId);
	let invalidateDealProgressViewData =
		useInvalidateDealProgressViewData(dealId);
	let invalidateDealData = useInvalidateDealData(dealId);
	let invalidateDealKanbanView = useInvalidateDealKanbanView();
	return useMutation(updateDealSummary, {
		onSuccess: () => {
			invalidateLinkedProductsToDeal();
			invalidateSummaryKey();
			invalidateDealTableData();
			invalidateParticipantsData();
			invalidateDealTimelineData();
			invalidateDealProgressViewData();
			invalidateDealData();
			invalidateDealOverViewData();
			invalidateDealKanbanView();
		},
	});
};

const useCloneDeal = () => {
	let invalidateDealTableData = useInvalidateDealTableData();
	return useMutation(createDeal, {
		onSuccess: () => {
			invalidateDealTableData();
		},
	});
};

const useUpdateExpectedCloseDateMutation = (dealId, stageId) => {
	let invalidateDealProgressViewData =
		useInvalidateDealProgressViewData(dealId);
	let invalidateDealOverViewData = useInvalidateDealOverViewData(dealId);
	let invalidateDealsByStage = useInvalidateDealsByStage(stageId);
	let invalidateDealTimelineData = useInvalidateDealTimelineData(dealId);
	let invalidateSummaryKey = useInvalidateSummary(dealId);
	return useMutation(updateExpectedCloseDate, {
		onSuccess: () => {
			invalidateDealProgressViewData();
			invalidateDealsByStage();
			invalidateDealTimelineData();
			invalidateSummaryKey();
			invalidateDealOverViewData();
		},
	});
};

const useDealSummaryData = (dealId) => {
	let dealSummaryKey = getDealSummaryViewKey(dealId);
	return useQuery(dealSummaryKey, () => getDealSummaryData(dealId));
};

const useInvalidateDealSummaryData = (dealId) => {
	let queryClient = useQueryClient();
	let dealSummaryKey = getDealSummaryKey(dealId);
	return () => {
		queryClient.invalidateQueries(dealSummaryKey);
	};
};

const useDealSwipeableSummaryData = (dealId) => {
	let dealSwipeableSummaryKey = getDealSwipeableSummaryKey(dealId);
	return useQuery(
		dealSwipeableSummaryKey,
		() => getDealSwipeableSummaryData(dealId),
		{
			select: (data) => data?.dealSummary,
		}
	);
};

const useDeleteMutation = () => {
	let invalidateDealTableData = useInvalidateDealTableData();
	let invalidateDealKanbanView = useInvalidateDealKanbanView();
	return useMutation(deleteDeal, {
		onSuccess: () => {
			invalidateDealTableData();
			invalidateDealKanbanView();
		},
	});
};

const useUpdateDealStateWithoutPipelineId = (dealId) => {
	let invalidateDealProgressViewData =
		useInvalidateDealProgressViewData(dealId);
	let invalidateDealOverViewData = useInvalidateDealOverViewData(dealId);
	let invalidateSummary = useInvalidateSummary(dealId);
	let invalidateDealTimelineData = useInvalidateDealTimelineData(dealId);
	return useMutation(updateDealStateWithoutPipelineId, {
		onSuccess: () => {
			invalidateDealProgressViewData();
			invalidateSummary();
			invalidateDealOverViewData();
			invalidateDealTimelineData();
		},
	});
};

const useDealProgressViewData = (dealId) => {
	let dealProgressViewKey = getDealProgressViewKey(dealId);
	return useQuery(dealProgressViewKey, () => getDealProgressViewData(dealId));
};

const useInvalidateDealProgressViewData = (dealId) => {
	let queryClient = useQueryClient();
	let dealProgressViewKey = getDealProgressViewKey(dealId);
	return () => {
		queryClient.invalidateQueries(dealProgressViewKey);
	};
};

const useUpdateStageMutation = (dealId) => {
	let invalidateDealProgressViewData =
		useInvalidateDealProgressViewData(dealId);
	let invalidateSummaryData = useInvalidateDealSummaryData(dealId);
	let invalidateDealOverViewData = useInvalidateDealOverViewData(dealId);
	let invalidateDealTimelineData = useInvalidateDealTimelineData(dealId);
	return useMutation(updateDealStage, {
		onSuccess: () => {
			invalidateDealProgressViewData();
			invalidateSummaryData();
			invalidateDealTimelineData();
			invalidateDealOverViewData();
		},
	});
};

const useUpdateStageWithDragAndDropMutation = (
	filter,
	sourceSortValue,
	destinationSortValue
) => {
	let queryClient = useQueryClient();
	let invalidateDealKanbanView = useInvalidateDealKanbanView();

	return useMutation(updateDealStage, {
		onSuccess: () => {
			invalidateDealKanbanView();
		},
		onMutate: (dragAndDropDetails) => {
			// handled optimistic update
			const { source, destination, sourceIndex, destinationIndex } =
				dragAndDropDetails;
			const defaultTableRowFetchCount = dealKanbanViewPageSize;

			let sourceStageKey = getDealsByStageKey(
				source,
				filter,
				sourceSortValue
			);
			let destinationStageKey = getDealsByStageKey(
				destination,
				filter,
				destinationSortValue
			);

			let sourcePreviousStageData =
				queryClient.getQueryData(sourceStageKey);

			let sourceDealDataIndex = Math.trunc(
				sourceIndex / defaultTableRowFetchCount
			);
			let sourceDealIndex = sourceIndex % defaultTableRowFetchCount;

			let destinationDealDataIndex = Math.trunc(
				destinationIndex / defaultTableRowFetchCount
			);
			let destinationDealIndex =
				destinationIndex % defaultTableRowFetchCount;

			let draggedDealData =
				sourcePreviousStageData.pages[sourceDealDataIndex].data.deals[
					sourceDealIndex
				];

			queryClient.setQueryData(
				destinationStageKey,
				(previousStageData) => {
					if (
						previousStageData.pages.length - 1 >
						destinationDealDataIndex
					) {
						previousStageData.pages.push({
							data: {
								deals: [draggedDealData],
							},
						});
					} else {
						if (
							!previousStageData.pages[destinationDealDataIndex]
								.data.deals
						) {
							previousStageData.pages[
								destinationDealDataIndex
							].data.deals = [];
						}
						previousStageData.pages[
							destinationDealDataIndex
						].data.deals.splice(
							destinationDealIndex,
							0,
							draggedDealData
						);
					}

					return previousStageData;
				}
			);

			queryClient.setQueryData(sourceStageKey, (previousStageData) => {
				previousStageData.pages[sourceDealDataIndex].data.deals.splice(
					sourceDealIndex,
					1
				);
				return previousStageData;
			});
		},
		onError: () => {
			invalidateDealKanbanView();
		},
	});
};

const useUpdatePipelineMutation = (dealId) => {
	let invalidateDealOverViewData = useInvalidateDealOverViewData(dealId);
	let invalidateDealProgressViewData =
		useInvalidateDealProgressViewData(dealId);
	return useMutation(updateDealPipeline, {
		onSuccess: () => {
			invalidateDealOverViewData();
			invalidateDealProgressViewData();
		},
	});
};

const dealSelect = (deals) => {
	return deals.pages.flatMap((page) => page?.options);
};

const useDealList = (isDealListEnabled = true, searchValue) => {
	return useInfiniteQuery({
		queryKey: getDealListKey(searchValue),
		queryFn: ({ pageParam }) => {
			return getDealList(searchValue, {
				start: pageParam
					? (pageParam - 1) * dealDropdownPageSize + 1
					: 1,
				limit: dealDropdownPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: dealSelect,
		enabled: Boolean(isDealListEnabled),
	});
};

const useDealRelatedEntitiesData = (dealId) => {
	return useQuery(
		getDealRelatedEntitiesKey(dealId),
		() => getDealRelatedEntitiesData(dealId),
		{
			enabled: !!dealId,
			select: (data) => data?.list,
		}
	);
};

const useAddProductsToDeal = (dealId) => {
	let invalidateSummaryData = useInvalidateDealSummaryData(dealId);
	let invalidateLinkedProductsToDeal =
		useInvalidateLinkedProductsToDeal(dealId);
	return useMutation(addProductsToDeal, {
		onSuccess: () => {
			invalidateSummaryData();
			invalidateLinkedProductsToDeal();
		},
	});
};

const useContactsByDeals = (dealIds) => {
	return useQuery(
		getContactsByDealsKey(dealIds),
		() => getContactsByDeals(dealIds),
		{
			select: (data) => data?.list,
		}
	);
};

const useLinkedProductsToDeal = (dealId) => {
	return useQuery(
		getLinkedProductsToDealKey(dealId),
		() => getLinkedProductsToDeal(dealId),
		{
			select: (data) => data?.list,
			enabled: !!dealId,
		}
	);
};

const useInvalidateLinkedProductsToDeal = (dealId) => {
	let queryClient = useQueryClient();
	let linkedProductsToDealKey = getLinkedProductsToDealKey(dealId);
	return () => {
		queryClient.invalidateQueries(linkedProductsToDealKey);
	};
};

//Assign owner mutation
const useAssignOwnerToDeals = () => {
	let invalidateDealTableData = useInvalidateDealTableData();
	return useMutation(assignOwnerToDeals, {
		onSuccess: () => {
			invalidateDealTableData();
		},
	});
};

// partial update
const usePartialUpdateDeal = (dealId) => {
	let invalidateSummaryData = useInvalidateDealSummaryData(dealId);
	let invalidateDealTimelineData = useInvalidateDealTimelineData(dealId);
	let invalidateDealProgressViewData =
		useInvalidateDealProgressViewData(dealId);
	let invalidateDealOverViewData = useInvalidateDealOverViewData(dealId);
	let invalidateGroupFieldData = useInvalidateGroupFieldData(
		modules.DEAL,
		dealId
	);
	return useMutation(partialUpdateDeal, {
		onSuccess: () => {
			invalidateSummaryData();
			invalidateDealOverViewData();
			invalidateGroupFieldData();
			invalidateDealProgressViewData();
			invalidateDealTimelineData();
		},
	});
};

// partial bulk update
const usePartialBulkUpdateDeal = () => {
	let invalidateDealTableData = useInvalidateDealTableData();
	return useMutation(updateDeal, {
		onSuccess: () => {
			invalidateDealTableData();
		},
	});
};

const useInvalidateDealOverViewData = (dealId) => {
	let queryClient = useQueryClient();
	const dealOverViewKey = getDealOverViewKey(dealId);
	return () => {
		queryClient.invalidateQueries(dealOverViewKey);
	};
};

export {
	useDealData,
	useCloneDeal,
	useCreateDeal,
	useUpdateDealStateWithoutPipelineId,
	useEditDeal,
	useDealSummaryData,
	useDeleteMutation,
	useDealProgressViewData,
	useUpdateStageMutation,
	useUpdateExpectedCloseDateMutation,
	useUpdatePipelineMutation,
	useDealSwipeableSummaryData,
	useDealsByStage,
	useInvalidateDealsByStage,
	useDealList,
	useDealRelatedEntitiesData,
	useAddProductsToDeal,
	useContactsByDeals,
	useAssignOwnerToDeals,
	useInvalidateDealTableData,
	useUpcomingActivitiesToDeal,
	useLinkedProductsToDeal,
	usePartialUpdateDeal,
	useInvalidateDealKanbanView,
	useUpdateStageWithDragAndDropMutation,
	usePartialBulkUpdateDeal,
	useInvalidateDealSummaryData,
};
