import {
	Box,
	MenuItem,
	MenuList,
	Popper,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getDropdownDataByName } from "../../../utils/dropdownData";
import { MenuItemText } from "../../../styles/twozo";
import { timeFormatter } from "../../../utils/DateUtils";

const styles = {
	timeTextField: {
		width: "20px",
		"& .MuiOutlinedInput-notchedOutline": {
			borderRadius: "8px",
		},
		"& .MuiOutlinedInput-root": {
			height: "auto",
			minHeight: "20px",
			padding: "0px 0px",
			"& input": {
				padding: "0px",
			},
			"& fieldset": {
				border: "none",
			},
			"&:hover fieldset": {
				border: "none",
			},
			"&.Mui-focused fieldset": {
				border: "none",
			},
		},
		input: {
			"&[type=number]": {
				MozAppearance: "textfield",
			},
			"&::-webkit-outer-spin-button": {
				WebkitAppearance: "none",
				margin: 0,
			},
			"&::-webkit-inner-spin-button": {
				WebkitAppearance: "none",
				margin: 0,
			},
		},
	},
};

const meridiemObj = {
	AM: "AM",
	PM: "PM",
};

export default function TimePicker(props) {
	const { value, onChange, timeSx, error } = props;
	const theme = useTheme();
	const timeMenuRef = useRef(null);
	const timeInputRef = useRef(null);

	const [timePickerMenuElement, setTimePickerMenuElement] = useState(null);
	const isTimePickerMenuOpened = Boolean(timePickerMenuElement);
	const [timeValue, setTimeValue] = useState({
		hour: "",
		minute: "",
		meridiem: "",
	});

	const timeOptions = getDropdownDataByName("TIME");

	const isGreaterThanZero = (hour) => {
		return parseInt(hour) > 0;
	};

	const onChangeTimeValue = (hourValue, minuteValue, meridiemValue) => {
		if (onChange) {
			let hour = parseInt(hourValue);

			if (meridiemValue === meridiemObj.PM && hour !== 12) {
				hour = hour + 12;
			} else if (meridiemValue === meridiemObj.AM && hour === 12) {
				hour = 0;
			}
			onChange(hour, minuteValue);
		}
	};

	const updateTimeValue = useCallback((hour, minute) => {
		let hourValue = null;
		if (hour === 0) {
			hourValue = 12;
		} else if (hour > 12) {
			hourValue = hour % 12;
		} else {
			hourValue = hour;
		}

		setTimeValue({
			hour: timeFormatter.format(hourValue),
			minute: timeFormatter.format(minute),
			meridiem: hour >= 12 ? meridiemObj.PM : meridiemObj.AM,
		});
	}, []);

	const onlyOnceRef = useRef(true);

	useEffect(() => {
		//update the inputs
		if (value && onlyOnceRef.current) {
			updateTimeValue(value.hour, value.minute);
			onlyOnceRef.current = false;
		}
	}, [updateTimeValue, value]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			// Close the popover if clicked outside
			if (
				timeMenuRef.current &&
				!timeMenuRef.current.contains(event.target) &&
				timeInputRef.current &&
				!timeInputRef.current.contains(event.target)
			) {
				setTimePickerMenuElement(null);
			}
		};

		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	const openTimePickerMenu = (event) => {
		setTimePickerMenuElement(event.currentTarget);
	};

	const closeTimePickerMenu = () => {
		setTimePickerMenuElement(null);
	};

	const onSelectTime = (selectedTime) => {
		let selectedHour = selectedTime?.meta?.hour;
		let selectedMinute = selectedTime?.meta?.minute;
		updateTimeValue(selectedHour, selectedMinute);
		onChange(selectedHour, selectedMinute);
		closeTimePickerMenu();
	};

	const updateMeridiemValue = (meridiem = "") => {
		setTimeValue((timeValue) => {
			return {
				...timeValue,
				meridiem: meridiem,
			};
		});
		onChangeTimeValue(
			timeValue.hour,
			timeValue.minute,
			meridiem ? meridiem : meridiemObj.AM
		);
	};

	const handleMeridiem = (event) => {
		const value = event.target.value?.toUpperCase();

		// Check if the value is AM or PM, and update state if valid
		if (value === "A" || value === meridiemObj.AM) {
			updateMeridiemValue(!timeValue?.meridiem ? meridiemObj.AM : value);
		} else if (value === "P" || value === meridiemObj.PM) {
			updateMeridiemValue(!timeValue?.meridiem ? meridiemObj.PM : value);
		} else if (
			!value.startsWith(meridiemObj.AM) &&
			!value.startsWith(meridiemObj.PM)
		) {
			updateMeridiemValue();
		}
	};

	const handleHour = (event) => {
		const { value } = event.target;
		let minute = timeValue.minute;
		if (value <= 12) {
			setTimeValue((timeValue) => {
				return {
					...timeValue,
					hour: isGreaterThanZero(value)
						? timeFormatter.format(value)
						: "",
					minute: isGreaterThanZero(value) && !minute ? "00" : minute,
					meridiem: timeValue.meridiem
						? timeValue.meridiem
						: meridiemObj.AM,
				};
			});
			onChangeTimeValue(value, timeValue.minute, timeValue.meridiem);
		}
	};

	const handleMinute = (event) => {
		const { value } = event.target;

		if (parseInt(value) < 60) {
			setTimeValue((timeValue) => {
				return {
					...timeValue,
					minute: parseInt(value) ? timeFormatter.format(value) : "",
				};
			});
			onChangeTimeValue(timeValue.hour, value, timeValue.meridiem);
		}
	};

	const isSelectedTime = (timeData) => {
		let formattedTime = `${timeFormatter.format(
			timeValue.hour
		)}:${timeFormatter.format(timeValue.minute)} ${timeValue.meridiem}`;

		return formattedTime === timeData?.name;
	};

	return (
		<React.Fragment>
			<Popper
				ref={timeMenuRef}
				placement="bottom-start"
				open={isTimePickerMenuOpened}
				anchorEl={timePickerMenuElement}
				style={{
					zIndex: theme.zIndex.modal + 1,
					backgroundColor: "#FFF",
					borderRadius: "8px",
					width: "160px",
					boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
				}}
			>
				<MenuList>
					<Box sx={{ maxHeight: "200px", overflowY: "auto" }}>
						{timeOptions.map((timeData) => (
							<MenuItem
								key={timeData.value}
								sx={{
									height: "40px",
									pl: 3,
									backgroundColor: isSelectedTime(timeData)
										? "rgba(51, 188, 126, 0.12)"
										: "transparent",
								}}
								onClick={() => onSelectTime(timeData)}
							>
								<MenuItemText>{timeData.name}</MenuItemText>
							</MenuItem>
						))}
					</Box>
				</MenuList>
			</Popper>

			<Stack
				ref={timeInputRef}
				direction={"row"}
				alignItems="center"
				style={{
					border: isTimePickerMenuOpened
						? `1px solid ${theme.palette.primary.main}`
						: error
							? `1px solid ${theme.palette.error.main}`
							: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
					minHeight: "42px",
					padding: "0px 8px",
					width: "100px",
					...timeSx,
				}}
				spacing={0.5}
				onClick={openTimePickerMenu}
			>
				<TextField
					value={timeValue.hour || ""}
					onChange={handleHour}
					sx={styles.timeTextField}
					placeholder="hh"
					inputProps={{
						style: {
							fontSize: "14px",
							textAlign: "right",
						},
					}}
					autoComplete="off"
				/>

				<Typography fontSize={12}>:</Typography>

				<TextField
					value={timeValue.minute || ""}
					onChange={handleMinute}
					sx={{
						...styles.timeTextField,
						width: "25px",
					}}
					placeholder="mm"
					inputProps={{
						style: {
							fontSize: "14px",
						},
					}}
					autoComplete="off"
				/>

				<TextField
					value={timeValue.meridiem || ""}
					onChange={handleMeridiem}
					sx={{ ...styles.timeTextField, width: "22px" }}
					placeholder="AM"
					autoComplete="off"
				/>
			</Stack>
		</React.Fragment>
	);
}
