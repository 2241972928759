import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import SingleConversationViewer from "../../Mail/SingleConversationView";
import ActivitySummary from "../../Activities/ActivitySummary";
import {
	useMarkAsReadMutation,
	useReminderNotification,
	useTopNotifications,
} from "../../../hooks/services/notification/appNotification";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationTypes } from "../../../utils/notificationUtils";
import { twozoStyles } from "../../../styles/twozo";

export default function ReminderNotification() {
	const navigate = useNavigate();
	const classes = twozoStyles();
	const [isMailViewerOpened, setIsMailViewerOpened] = useState(false);
	const [openedConversationId, setOpenedConversationId] = useState("");
	const [isActivitySummaryOpened, setIsActivitySummaryOpened] =
		useState(false);
	const [openedActivityId, setOpenedActivityId] = useState(null);
	const [lastShownNotification, setLastShownNotification] = useState([]);

	const showReminderNotification = JSON.parse(
		localStorage.getItem("showReminderNotification")
	);
	const { data: topNotifications } = useTopNotifications(
		showReminderNotification
	);
	const { data: reminderData } = useReminderNotification();
	const markAsReadMutation = useMarkAsReadMutation();

	const handleUpdateMarkAsRead = useCallback(
		(notificationId) => {
			markAsReadMutation.mutate(notificationId);
		},
		[markAsReadMutation]
	);

	const openMailViewer = useCallback(
		(notificationData) => {
			setIsMailViewerOpened(true);
			setOpenedConversationId(notificationData?.data?.id);
			handleUpdateMarkAsRead(notificationData?.id);
		},
		[handleUpdateMarkAsRead]
	);

	const closeMailViewer = () => {
		setIsMailViewerOpened(false);
		setOpenedConversationId("");
	};

	const openActivitySummary = useCallback(
		(notificationData) => {
			setIsActivitySummaryOpened(true);
			setOpenedActivityId(notificationData.data.id);
			handleUpdateMarkAsRead(notificationData?.id);
		},
		[handleUpdateMarkAsRead]
	);

	const closeActivitySummary = () => {
		setIsActivitySummaryOpened(false);
		setOpenedActivityId("");
	};

	const handleCalendarSyncNotification = useCallback(
		(notificationData) => {
			navigate("/settings/email?type=calendar_sync");
			handleUpdateMarkAsRead(notificationData?.id);
		},
		[handleUpdateMarkAsRead, navigate]
	);

	const handleReminder = useCallback(
		(notificationData) => {
			switch (notificationData?.type) {
				case notificationTypes.emailReceived: {
					return openMailViewer(notificationData);
				}
				case notificationTypes.emailReplied: {
					return openMailViewer(notificationData);
				}
				case notificationTypes.emailClicked: {
					return openMailViewer(notificationData);
				}
				case notificationTypes.emailOpened: {
					return openMailViewer(notificationData);
				}
				case notificationTypes.activityReminder: {
					return openActivitySummary(notificationData);
				}
				case notificationTypes.calendarSynced: {
					return handleCalendarSyncNotification(notificationData);
				}
				default:
					return handleUpdateMarkAsRead(notificationData?.id);
			}
		},
		[
			handleCalendarSyncNotification,
			handleUpdateMarkAsRead,
			openActivitySummary,
			openMailViewer,
		]
	);

	// last three unread notifications
	useEffect(() => {
		if (
			Array.isArray(topNotifications?.notifications) &&
			showReminderNotification
		) {
			for (let notification of topNotifications.notifications) {
				enqueueSnackbar({
					variant: notificationVariants.reminder,
					reminderData: notification,
					onClickReminder: handleReminder,
				});
			}
			localStorage.setItem("showReminderNotification", false);
		}
	}, [handleReminder, showReminderNotification, topNotifications]);

	// Activity reminder Notifications
	useEffect(() => {
		if (Array.isArray(reminderData?.notifications)) {
			if (lastShownNotification !== reminderData.notifications) {
				for (let notification of reminderData.notifications) {
					enqueueSnackbar({
						variant: notificationVariants.reminder,
						reminderData: notification,
						onClickReminder: handleReminder,
					});
				}

				setLastShownNotification(reminderData?.notifications);
			}
		}
	}, [handleReminder, reminderData, lastShownNotification]);

	return (
		<React.Fragment>
			{/*Mail Viewer*/}
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isMailViewerOpened}
				onOpen={openMailViewer}
				onClose={closeMailViewer}
				disableBackdropClick={true}
			>
				<Box className={classes.addDialogContainer}>
					<SingleConversationViewer
						onClose={closeMailViewer}
						conversationId={openedConversationId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			{/*Activity Summary*/}
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: {
						backgroundColor: "transparent",
					},
				}}
				open={isActivitySummaryOpened}
				onOpen={openActivitySummary}
				onClose={closeActivitySummary}
				disableBackdropClick={true}
				hideBackdrop
			>
				<Box className={classes.addDialogContainer}>
					<ActivitySummary
						onClose={closeActivitySummary}
						activityId={openedActivityId}
					/>
				</Box>
			</CustomSwipeableDrawer>
		</React.Fragment>
	);
}
