import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	Box,
	Divider,
	MenuItem,
	Select,
	Stack,
	ToggleButton,
	useTheme,
} from "@mui/material";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
	$createParagraphNode,
	$getSelection,
	$isDecoratorNode,
	$isElementNode,
	$isRangeSelection,
	$isRootOrShadowRoot,
	$isTextNode,
	COMMAND_PRIORITY_LOW,
	FORMAT_ELEMENT_COMMAND,
	FORMAT_TEXT_COMMAND,
	INDENT_CONTENT_COMMAND,
	OUTDENT_CONTENT_COMMAND,
} from "lexical";
import { $createQuoteNode, $isQuoteNode } from "@lexical/rich-text";
import {
	$getSelectionStyleValueForProperty,
	$patchStyleText,
	$setBlocksType,
} from "@lexical/selection";
import {
	$findMatchingParent,
	$getNearestBlockElementAncestorOrThrow,
	$getNearestNodeOfType,
	mergeRegister,
} from "@lexical/utils";
import {
	$isListNode,
	INSERT_ORDERED_LIST_COMMAND,
	INSERT_UNORDERED_LIST_COMMAND,
	insertList,
	ListNode,
	REMOVE_LIST_COMMAND,
	removeList,
} from "@lexical/list";
import {
	CustomisedToggleButtonGroup,
	MenuItemText,
	Tooltip,
} from "../../../../../styles/twozo";
import { default as BoldIcon } from "../../../../../assets/icons/bold";
import { default as ItalicIcon } from "../../../../../assets/icons/italic";
import { default as UnderlineIcon } from "../../../../../assets/icons/underline";
import { default as TextColorIcon } from "../../../../../assets/icons/textColor";
import { default as ListBulletIcon } from "../../../../../assets/icons/listBullet";
import { default as ListNumberIcon } from "../../../../../assets/icons/listNumber";
import { default as AlignLeftIcon } from "../../../../../assets/icons/alignLeft";
import { default as AlignCenterIcon } from "../../../../../assets/icons/alignCenter";
import { default as AlignRightIcon } from "../../../../../assets/icons/alignRight";
import { default as IndentLeftIcon } from "../../../../../assets/icons/indentLeft";
import { default as IndentRightIcon } from "../../../../../assets/icons/indentRight";
import { default as StrikeoutIcon } from "../../../../../assets/icons/strikeout";
import { default as QuoteIcon } from "../../../../../assets/icons/quotes";
import { default as MoreIcon } from "../../../../../assets/icons/more";
import { default as DataIcon } from "../../../../../assets/icons/data";
import { default as EraserIcon } from "../../../../../assets/icons/eraser";
import { default as TemplateIcon } from "../../../../../assets/icons/template";
import { getSelectedNode } from "../../EmailComposerUI/ToolBar/utils/getSelectedNode";
import Menu from "../../../../Elements/Menu";
import ColorPickerMenu from "./colorPickerMenu";
import { onFocusEmailComposer } from "./utils/onFocusEmailComposer";

const formattingOptionsTooltipObj = {
	BOLD: "Bold",
	LIST: "List",
	QUOTES: "Quotes",
	ITALIC: "Italics",
	UNDERLINE: "Underline",
	TEMPLATES: "Templates",
	PLACEHOLDERS: "Placeholders",
	FONT_FAMILY: "Font Family",
	FONT_SIZE: "Font Size",
	TEXT_COLOR: "Text Color",
	ALIGNMENT: "Alignment",
	MORE_OPTIONS: {
		OUTDENT: "Outdent",
		INDENT: "Indent",
		STRAIGHT_THROUGH: "Strike-Through",
		CLEAR_FORMATTING: "Clear Formatting",
	},
	TEXT_BACKGROUND_COLOR: "Text Background Color",
};

export default function ToolBar(props) {
	const {
		hiddenTemplateMenu,
		hiddenPlaceHolderMenu,
		onClickPlaceHolder,
		onClickTemplates,
		focusTemplateIcon = false,
		focusPlaceHolderIcon = false,
	} = props;
	const theme = useTheme();
	const iconColor = "rgba(0, 0, 0, 0.6)";
	const [editor] = useLexicalComposerContext();
	const [isBold, setIsBold] = useState(false);
	const [isItalic, setIsItalic] = useState(false);
	const [isUnderline, setIsUnderline] = useState(false);
	const [isQuote, setIsQuote] = useState(false);
	const [isStrikethrough, setIsStrikethrough] = useState(false);
	const [fontSize, setFontSize] = useState("14px");
	const [fontFamily, setFontFamily] = useState("Lexend");
	const [selectedListFormat, setSelectedListFormat] = useState(0);
	const [selectedTextAlignment, setSelectedTextAlignment] = useState(0);
	const [textColor, setTextColor] = useState("#000000");
	const [textHighlightColor, setTextHighlightColor] = useState("#ffffff");
	const [moreOptionElement, setMoreOptionElement] = useState(null);
	const openMoreOption = Boolean(moreOptionElement);
	const [textColorElement, setTextColorElement] = useState(null);
	const openTextColorPicker = Boolean(textColorElement);
	const [textHighlightColorElement, setTextHighlightColorElement] =
		useState(null);
	const openTextHighlightColorPicker = Boolean(textHighlightColorElement);

	const FONT_FAMILY_OPTIONS = [
		["Arial", "Arial"],
		["Courier New", "Courier New"],
		["Georgia", "Georgia"],
		["Lexend", "Lexend"],
		["Times New Roman", "Times New Roman"],
	];

	const FONT_SIZE_OPTIONS = [
		["8", "8px"],
		["10", "10px"],
		["12", "12px"],
		["14", "14px"],
		["18", "18px"],
		["24", "24px"],
		["36", "36px"],
	];

	const LIST_OPTIONS = useMemo(() => {
		return ["NONE", "UNORDERED_LIST", "ORDERED_LIST"];
	}, []);

	const ALIGNMENT_OPTIONS = useMemo(() => {
		return ["left", "center", "right"];
	}, []);

	const updateToolbar = React.useCallback(() => {
		const selection = $getSelection();

		if ($isRangeSelection(selection)) {
			const anchorNode = selection.anchor.getNode();
			let element =
				anchorNode.getKey() === "root"
					? anchorNode
					: $findMatchingParent(anchorNode, (e) => {
							const parent = e.getParent();
							return (
								parent !== null && $isRootOrShadowRoot(parent)
							);
						});
			if (element === null) {
				element = anchorNode.getTopLevelElementOrThrow();
			}

			const elementKey = element.getKey();
			const elementDOM = editor.getElementByKey(elementKey);
			const node = getSelectedNode(selection);
			const parent = node.getParent();

			setIsBold(selection.hasFormat("bold"));
			setIsItalic(selection.hasFormat("italic"));
			setIsUnderline(selection.hasFormat("underline"));
			setIsStrikethrough(selection.hasFormat("strikethrough"));
			setFontSize(
				$getSelectionStyleValueForProperty(
					selection,
					"font-size",
					"14px"
				)
			);
			setFontFamily(
				$getSelectionStyleValueForProperty(
					selection,
					"font-family",
					"Lexend"
				)
			);
			setSelectedTextAlignment(
				ALIGNMENT_OPTIONS.indexOf(
					($isElementNode(node)
						? node.getFormatType()
						: parent?.getFormatType()) || "left"
				)
			);
			setIsQuote($isQuoteNode(parent));
			// console.log($getSelectionStyleValueForProperty(selection, 'color', "#000000"));
			setTextColor(
				$getSelectionStyleValueForProperty(
					selection,
					"color",
					"#000000"
				)
			);
			// console.log($getSelectionStyleValueForProperty(selection, 'background-color', "#ffffff"));
			setTextHighlightColor(
				$getSelectionStyleValueForProperty(
					selection,
					"background-color",
					"#ffffff"
				)
			);

			if (elementDOM !== null) {
				if ($isListNode(element)) {
					const parentList = $getNearestNodeOfType(
						anchorNode,
						ListNode
					);
					const type = parentList
						? parentList.getListType()
						: element.getListType();
					switch (type) {
						case "number":
							setSelectedListFormat(
								LIST_OPTIONS.indexOf("ORDERED_LIST")
							);
							break;
						case "bullet":
							setSelectedListFormat(
								LIST_OPTIONS.indexOf("UNORDERED_LIST")
							);
							break;
						default:
							setSelectedListFormat(LIST_OPTIONS.indexOf("NONE"));
							break;
					}
				} else {
					setSelectedListFormat(LIST_OPTIONS.indexOf("NONE"));
				}
			}
		}
	}, [editor, LIST_OPTIONS, ALIGNMENT_OPTIONS]);

	useEffect(() => {
		return mergeRegister(
			editor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => {
					updateToolbar();
				});
			}),
			editor.registerCommand(
				INSERT_ORDERED_LIST_COMMAND,
				() => {
					insertList(editor, "number");
					return true;
				},
				COMMAND_PRIORITY_LOW
			),
			editor.registerCommand(
				INSERT_UNORDERED_LIST_COMMAND,
				() => {
					insertList(editor, "bullet");
					return true;
				},
				COMMAND_PRIORITY_LOW
			),
			editor.registerCommand(
				REMOVE_LIST_COMMAND,
				() => {
					removeList(editor);
					return true;
				},
				COMMAND_PRIORITY_LOW
			)
		);
	}, [updateToolbar, editor]);

	const applyStyleText = useCallback(
		(styles) => {
			editor.update(() => {
				const selection = $getSelection();
				if ($isRangeSelection(selection)) {
					$patchStyleText(selection, styles);
				}
			});
		},
		[editor]
	);

	const handleListFormatChange = () => {
		const newListFormat = (selectedListFormat + 1) % LIST_OPTIONS.length;
		switch (newListFormat) {
			case 0:
				editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
				break;
			case 1:
				editor.dispatchCommand(
					INSERT_UNORDERED_LIST_COMMAND,
					undefined
				);
				break;
			case 2:
				editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
				break;
			default:
				break;
		}
	};

	const handleTextAlignmentChange = () => {
		const newTextAlignment =
			(selectedTextAlignment + 1) % ALIGNMENT_OPTIONS.length;
		editor.dispatchCommand(
			FORMAT_ELEMENT_COMMAND,
			ALIGNMENT_OPTIONS[newTextAlignment]
		);
	};

	const handleMoreOptionMenu = (event) => {
		setMoreOptionElement(event.currentTarget);
	};

	const closeMoreOption = () => {
		setMoreOptionElement(null);
		onFocusEmailComposer(editor);
	};

	const OpenTextColorPicker = (event) => {
		setTextColorElement(event.currentTarget);
	};

	const CloseTextColorPicker = () => {
		setTextColorElement(null);
	};

	const OpenTextHighlightColorPicker = (event) => {
		setTextHighlightColorElement(event.currentTarget);
	};

	const CloseTextHighlightColorPicker = () => {
		setTextHighlightColorElement(null);
	};

	const MoreOptionTooltip = (props) => {
		return (
			<Tooltip
				title={props?.title || ""}
				placement="left"
				PopperProps={{
					modifiers: [
						{
							name: "offset",
							options: {
								offset: [0, 10],
							},
						},
					],
				}}
			>
				{props.children}
			</Tooltip>
		);
	};

	const clearFormatting = () => {
		editor.update(() => {
			const selection = $getSelection();
			if ($isRangeSelection(selection)) {
				const nodes = selection.getNodes();

				nodes.forEach((node) => {
					if ($isTextNode(node)) {
						let textNode = node;
						if (textNode.__style !== "") {
							textNode.setStyle("");
						}
						if (textNode.__format !== 0) {
							textNode.setFormat(0);
							$getNearestBlockElementAncestorOrThrow(
								textNode
							).setFormat("");
						}
						node = textNode;
					} else if ($isQuoteNode(node)) {
						node.replace($createParagraphNode(), true);
					} else if ($isDecoratorNode(node)) {
						node.setFormat("");
					}
				});
			}
		});
		closeMoreOption();
	};

	const handleApplyIndent = () => {
		editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
		closeMoreOption();
		onFocusEmailComposer(editor);
	};

	const handleApplyOutdent = () => {
		editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
		closeMoreOption();
		onFocusEmailComposer(editor);
	};

	const handleApplyStrikeout = () => {
		editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
		closeMoreOption();
	};

	return (
		<React.Fragment>
			<ColorPickerMenu
				color={textColor}
				anchorEl={textColorElement}
				onColorChange={(color) => {
					applyStyleText({ color: color });
				}}
				open={openTextColorPicker}
				onClose={CloseTextColorPicker}
			/>

			<ColorPickerMenu
				color={textHighlightColor}
				anchorEl={textHighlightColorElement}
				onColorChange={(color) => {
					applyStyleText({ "background-color": color });
				}}
				open={openTextHighlightColorPicker}
				onClose={CloseTextHighlightColorPicker}
			/>

			<Menu
				anchorEl={moreOptionElement}
				open={openMoreOption}
				onClose={closeMoreOption}
				width="50px"
			>
				<MenuItem
					style={{ paddingTop: "12px", paddingBottom: "12px" }}
					onClick={handleApplyOutdent}
				>
					<MoreOptionTooltip
						title={formattingOptionsTooltipObj.MORE_OPTIONS.OUTDENT}
					>
						{IndentLeftIcon(16, 16, iconColor)}
					</MoreOptionTooltip>
				</MenuItem>

				<MenuItem
					style={{ paddingTop: "12px", paddingBottom: "12px" }}
					onClick={handleApplyIndent}
				>
					<MoreOptionTooltip
						title={formattingOptionsTooltipObj.MORE_OPTIONS.INDENT}
					>
						{IndentRightIcon(16, 16, iconColor)}
					</MoreOptionTooltip>
				</MenuItem>

				<MenuItem
					style={{ paddingTop: "12px", paddingBottom: "12px" }}
					onClick={handleApplyStrikeout}
					selected={isStrikethrough}
				>
					<MoreOptionTooltip
						title={
							formattingOptionsTooltipObj.MORE_OPTIONS
								.STRAIGHT_THROUGH
						}
					>
						{StrikeoutIcon(16, 16, iconColor)}
					</MoreOptionTooltip>
				</MenuItem>

				<MenuItem
					style={{ paddingTop: "12px", paddingBottom: "12px" }}
					onClick={clearFormatting}
				>
					<MoreOptionTooltip
						title={
							formattingOptionsTooltipObj.MORE_OPTIONS
								.CLEAR_FORMATTING
						}
					>
						{EraserIcon(16, 16, iconColor)}
					</MoreOptionTooltip>
				</MenuItem>
			</Menu>

			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				style={{
					borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
				}}
				overflow="auto"
				px={1}
				spacing={1}
			>
				<Stack direction="row" alignItems="center" spacing={1}>
					<Stack direction="row" px={1} spacing={2}>
						<Tooltip
							title={formattingOptionsTooltipObj.FONT_FAMILY}
							placement="top"
							PopperProps={{
								modifiers: [
									{
										name: "offset",
										options: {
											offset: [0, -15],
										},
									},
								],
							}}
						>
							<Select
								size="small"
								variant="standard"
								value={fontFamily}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "left",
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "left",
									},
								}}
								onChange={(e) => {
									applyStyleText({
										"font-family": e.target.value,
									});
								}}
								sx={{
									"& .MuiSelect-icon": {
										right: "4px",
									},
								}}
								disableUnderline
								displayEmpty
							>
								{FONT_FAMILY_OPTIONS.map(
									(FONT_FAMILY, index) => (
										<MenuItem
											key={index}
											value={FONT_FAMILY[1]}
										>
											<MenuItemText
												style={{
													color: "rgba(0,0,0,0.6)",
												}}
											>
												{FONT_FAMILY[0]}
											</MenuItemText>
										</MenuItem>
									)
								)}
							</Select>
						</Tooltip>

						<Tooltip
							title={formattingOptionsTooltipObj.FONT_SIZE}
							placement="top"
							PopperProps={{
								modifiers: [
									{
										name: "offset",
										options: {
											offset: [0, -15],
										},
									},
								],
							}}
						>
							<Select
								size="small"
								variant="standard"
								value={fontSize}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "left",
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "left",
									},
								}}
								onChange={(e) => {
									applyStyleText({
										"font-size": e.target.value,
									});
								}}
								sx={{
									"& .MuiSelect-icon": {
										right: "4px",
									},
								}}
								disableUnderline
								displayEmpty
							>
								{FONT_SIZE_OPTIONS.map((FONT_SIZE, index) => (
									<MenuItem key={index} value={FONT_SIZE[1]}>
										<MenuItemText
											style={{ color: "rgba(0,0,0,0.6)" }}
										>
											{FONT_SIZE[0]}
										</MenuItemText>
									</MenuItem>
								))}
							</Select>
						</Tooltip>
					</Stack>

					<Divider
						flexItem
						orientation="vertical"
						style={{ margin: "8px 0px 8px 8px" }}
					/>

					<CustomisedToggleButtonGroup size="small">
						<ToggleButton
							value="text_color"
							onClick={OpenTextColorPicker}
						>
							<Tooltip
								title={formattingOptionsTooltipObj.TEXT_COLOR}
								placement="top"
							>
								<Stack>
									{TextColorIcon(16, 16, iconColor)}
									<Divider
										style={{
											backgroundColor: textColor,
											height: "2px",
											borderRadius: "10px",
										}}
									/>
								</Stack>
							</Tooltip>
						</ToggleButton>

						<ToggleButton
							value="text_highlight_color"
							onClick={OpenTextHighlightColorPicker}
						>
							<Tooltip
								title={
									formattingOptionsTooltipObj.TEXT_BACKGROUND_COLOR
								}
								placement="top"
							>
								<Box
									display="flex"
									style={{
										backgroundColor: textHighlightColor,
									}}
								>
									{TextColorIcon(16, 16, iconColor)}
								</Box>
							</Tooltip>
						</ToggleButton>
					</CustomisedToggleButtonGroup>

					<Divider
						flexItem
						orientation="vertical"
						style={{ margin: "8px 0px 8px 8px" }}
					/>

					<CustomisedToggleButtonGroup
						size="small"
						aria-label="text formatting"
					>
						<ToggleButton
							value="bold"
							aria-label="bold"
							onClick={() => {
								editor.dispatchCommand(
									FORMAT_TEXT_COMMAND,
									"bold"
								);
							}}
							selected={isBold}
						>
							<Tooltip
								title={formattingOptionsTooltipObj.BOLD}
								placement="top"
							>
								{BoldIcon(16, 16, iconColor)}
							</Tooltip>
						</ToggleButton>

						<ToggleButton
							value="italic"
							aria-label="italic"
							onClick={() => {
								editor.dispatchCommand(
									FORMAT_TEXT_COMMAND,
									"italic"
								);
							}}
							selected={isItalic}
						>
							<Tooltip
								title={formattingOptionsTooltipObj.ITALIC}
								placement="top"
							>
								{ItalicIcon(16, 16, iconColor)}
							</Tooltip>
						</ToggleButton>

						<ToggleButton
							value="underlined"
							aria-label="underlined"
							onClick={() => {
								editor.dispatchCommand(
									FORMAT_TEXT_COMMAND,
									"underline"
								);
							}}
							selected={isUnderline}
						>
							<Tooltip
								title={formattingOptionsTooltipObj.UNDERLINE}
								placement="top"
							>
								{UnderlineIcon(16, 16, iconColor)}
							</Tooltip>
						</ToggleButton>
					</CustomisedToggleButtonGroup>

					<Divider
						flexItem
						orientation="vertical"
						style={{ margin: "8px 0px 8px 8px" }}
					/>

					<CustomisedToggleButtonGroup size="small">
						<ToggleButton
							value="unorder list"
							aria-label="unorder list"
							onClick={handleListFormatChange}
							selected={
								LIST_OPTIONS[selectedListFormat] !== "NONE"
							}
						>
							<Tooltip
								title={formattingOptionsTooltipObj.LIST}
								placement="top"
							>
								{(function () {
									switch (LIST_OPTIONS[selectedListFormat]) {
										case "ORDERED_LIST":
											return ListNumberIcon(
												16,
												16,
												iconColor
											);
										default:
											return ListBulletIcon(
												16,
												16,
												iconColor
											);
									}
								})()}
							</Tooltip>
						</ToggleButton>

						<ToggleButton
							value="align left"
							aria-label="align left"
							onClick={handleTextAlignmentChange}
							selected={
								ALIGNMENT_OPTIONS[selectedTextAlignment] !==
								"left"
							}
						>
							<Tooltip
								title={formattingOptionsTooltipObj.ALIGNMENT}
								placement="top"
							>
								{(function () {
									switch (
										ALIGNMENT_OPTIONS[selectedTextAlignment]
									) {
										case "center":
											return AlignCenterIcon(
												16,
												16,
												iconColor
											);
										case "right":
											return AlignRightIcon(
												16,
												16,
												iconColor
											);
										default:
											return AlignLeftIcon(
												16,
												16,
												iconColor
											);
									}
								})()}
							</Tooltip>
						</ToggleButton>

						<ToggleButton
							value="quote"
							aria-label="quote"
							onClick={() => {
								editor.update(() => {
									const selection = $getSelection();
									if ($isRangeSelection(selection)) {
										if (isQuote) {
											$setBlocksType(selection, () =>
												$createParagraphNode()
											);
										} else {
											$setBlocksType(selection, () =>
												$createQuoteNode()
											);
										}
									}
								});
							}}
							selected={isQuote}
						>
							<Tooltip
								title={formattingOptionsTooltipObj.QUOTES}
								placement="top"
							>
								{QuoteIcon(16, 16, iconColor)}
							</Tooltip>
						</ToggleButton>
					</CustomisedToggleButtonGroup>

					<Divider
						flexItem
						orientation="vertical"
						style={{ margin: "8px 0px 8px 8px" }}
					/>

					<CustomisedToggleButtonGroup size="small">
						<ToggleButton
							value="more"
							aria-label="more"
							onClick={handleMoreOptionMenu}
							sx={{
								backgroundColor: openMoreOption
									? theme.palette.secondary.main
									: null,
								"&:hover": {
									backgroundColor:
										theme.palette.secondary.main,
								},
							}}
						>
							<Tooltip title="More Options" placement="top">
								{MoreIcon(16, 16, iconColor)}
							</Tooltip>
						</ToggleButton>
					</CustomisedToggleButtonGroup>
				</Stack>

				<Stack direction="row" alignItems="center" spacing={1}>
					<CustomisedToggleButtonGroup size="small">
						{!hiddenTemplateMenu ? (
							<ToggleButton
								value="template"
								aria-label="template"
								onClick={onClickTemplates}
								style={{
									backgroundColor: focusTemplateIcon
										? theme.palette.secondary.main
										: "transparent",
								}}
							>
								<Tooltip
									title={
										formattingOptionsTooltipObj.TEMPLATES
									}
									placement="top"
								>
									{TemplateIcon(
										18,
										18,
										theme.palette.primary.main
									)}
								</Tooltip>
							</ToggleButton>
						) : null}

						{!hiddenPlaceHolderMenu ? (
							<ToggleButton
								value="data"
								aria-label="data"
								onClick={onClickPlaceHolder}
								style={{
									backgroundColor: focusPlaceHolderIcon
										? theme.palette.secondary.main
										: "transparent",
								}}
							>
								<Tooltip
									title={
										formattingOptionsTooltipObj.PLACEHOLDERS
									}
									placement="top"
								>
									{DataIcon(
										18,
										18,
										theme.palette.primary.main
									)}
								</Tooltip>
							</ToggleButton>
						) : null}
					</CustomisedToggleButtonGroup>
				</Stack>
			</Stack>
		</React.Fragment>
	);
}
