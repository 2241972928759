import React, { useState } from "react";
import { TableCellText } from "../../../../styles/twozo";
import Can from "../../../Auth/Can";
import { PERMISSIONS } from "../../../../utils/Auth";
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import Menu from "../../Menu";
import { useNavigate } from "react-router-dom";
import CanNot from "../../../Auth/CanNot";

export default function Products(props) {
	const { data } = props;
	const theme = useTheme();
	const [productMenuElement, setProductMenuElement] = useState(null);
	const isProductMenuOpened = Boolean(productMenuElement);

	const navigate = useNavigate();

	const openProductMenu = (event) => {
		event.stopPropagation();
		setProductMenuElement(event.currentTarget);
	};

	const closeProductMenu = (event) => {
		event.stopPropagation();
		setProductMenuElement(null);
	};

	if (!data) {
		return <TableCellText>-</TableCellText>;
	}

	return (
		<React.Fragment>
			<Menu
				minWidth="350px"
				anchorEl={productMenuElement}
				open={isProductMenuOpened}
				onClose={(event) => closeProductMenu(event)}
			>
				{data?.items?.map((productDetail, index) => (
					<Box key={productDetail.id}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							height="40px"
							px={2}
							onClick={() =>
								navigate(`/products/${productDetail.id}`)
							}
							style={{
								cursor: "pointer",
							}}
						>
							<Typography
								color={theme.palette.secondary.contrastText}
								fontSize={13}
								fontWeight={500}
							>
								{productDetail.name}
							</Typography>

							<Typography fontSize={13} fontWeight={500}>
								{productDetail.price}
							</Typography>
						</Stack>
						{index < data.items.length - 1 && <Divider />}
					</Box>
				))}
			</Menu>

			<Can permission={PERMISSIONS.product.view}>
				<TableCellText
					onClick={(event) => openProductMenu(event)}
					color={theme.palette.secondary.contrastText}
				>
					{data?.items?.length}{" "}
					{data?.items?.length === 1 ? "Product" : "Products"}
				</TableCellText>
			</Can>

			<CanNot permission={PERMISSIONS.product.view}>
				<TableCellText>-</TableCellText>
			</CanNot>
		</React.Fragment>
	);
}
