import React from "react";
import { TextField } from "@mui/material";

import { Controller, useFormContext } from "react-hook-form";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";
import { fieldType } from "../../../../../../utils/FilterUtills";

export default function TextComponent({
	field,
	inputRef,
	textFieldSx = {},
	...rest
}) {
	const onChangeInterceptor = (event, onChange) => {
		const maxNumberLength = 16;
		let modifiedEvent = event.target.value.trimStart();
		let value = event.target.value;
		if (field.type === fieldType.singleNumber) {
			// This regex removes all non-numeric characters, keeping only digits (0-9).
			value = value.replace(/[^0-9]/g, "");
			let validNumber =
				value.length > maxNumberLength
					? value
					: value
						? Number(value)
						: "";

			modifiedEvent = {
				...event,
				target: { ...event.target, value: validNumber },
			};
		}

		onChange(modifiedEvent);
		clearErrors(field.id);
	};

	const { control, clearErrors } = useFormContext();

	return (
		<React.Fragment>
			<Controller
				name={field.id + ""}
				control={control}
				rules={handleFieldValidation(field)}
				render={({
					field: { onChange, ...renderField },
					fieldState: { error },
				}) => (
					<TextField
						fullWidth
						placeholder={field?.placeHolder || "Enter"}
						id={field.id + ""}
						sx={{ ...textFieldSx }}
						{...renderField}
						{...rest}
						onChange={(event) =>
							onChangeInterceptor(event, onChange)
						}
						inputRef={inputRef}
						error={!!error}
					/>
				)}
				defaultValue=""
			></Controller>
		</React.Fragment>
	);
}
