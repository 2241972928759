import React from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { default as ArrowIcon } from "../../../../assets/icons/dropDownCentered";

export default function KpiChart(props) {
	const { chartData } = props;
	const theme = useTheme();
	const fontColor =
		chartData.indicator?.symbol === "up"
			? theme.palette.secondary.contrastText
			: theme.palette.warning.contrastText;

	return (
		<React.Fragment>
			<Stack alignItems="center" justifyContent="center" height="85%">
				<Stack direction="row" alignItems="center" spacing={0.5}>
					<Typography fontSize="45px">
						{chartData.currentValue}
					</Typography>

					<Box
						style={{
							transform:
								chartData.indicator?.symbol === "up"
									? "rotate(180deg)"
									: "rotate(0deg)",
						}}
						pt={1}
					>
						{ArrowIcon(18, 18, fontColor)}
					</Box>

					<Typography color={fontColor}>
						{chartData.indicator?.value}
					</Typography>
				</Stack>

				{chartData.previousValue && (
					<Typography color={fontColor}>
						{chartData.previousValue.timeUnit}:{" "}
						{chartData.previousValue.value}
					</Typography>
				)}
			</Stack>
		</React.Fragment>
	);
}
