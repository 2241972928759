const accountTimezones = [
	{
		value: 1,
		name: "(UTC+00:00) Africa/Abidjan",
		zoneId: "Africa/Abidjan",
	},
	{
		value: 2,
		name: "(UTC+00:00) Africa/Accra",
		zoneId: "Africa/Accra",
	},
	{
		value: 3,
		name: "(UTC+03:00) Africa/Addis_Ababa",
		zoneId: "Africa/Addis_Ababa",
	},
	{
		value: 4,
		name: "(UTC+01:00) Africa/Algiers",
		zoneId: "Africa/Algiers",
	},
	{
		value: 5,
		name: "(UTC+03:00) Africa/Asmara",
		zoneId: "Africa/Asmara",
	},
	{
		value: 6,
		name: "(UTC+03:00) Africa/Asmera",
		zoneId: "Africa/Asmera",
	},
	{
		value: 7,
		name: "(UTC+00:00) Africa/Bamako",
		zoneId: "Africa/Bamako",
	},
	{
		value: 8,
		name: "(UTC+01:00) Africa/Bangui",
		zoneId: "Africa/Bangui",
	},
	{
		value: 9,
		name: "(UTC+00:00) Africa/Banjul",
		zoneId: "Africa/Banjul",
	},
	{
		value: 10,
		name: "(UTC+00:00) Africa/Bissau",
		zoneId: "Africa/Bissau",
	},
	{
		value: 11,
		name: "(UTC+02:00) Africa/Blantyre",
		zoneId: "Africa/Blantyre",
	},
	{
		value: 12,
		name: "(UTC+01:00) Africa/Brazzaville",
		zoneId: "Africa/Brazzaville",
	},
	{
		value: 13,
		name: "(UTC+02:00) Africa/Bujumbura",
		zoneId: "Africa/Bujumbura",
	},
	{
		value: 14,
		name: "(UTC+02:00) Africa/Cairo",
		zoneId: "Africa/Cairo",
	},
	{
		value: 15,
		name: "(UTC+00:00) Africa/Casablanca",
		zoneId: "Africa/Casablanca",
	},
	{
		value: 16,
		name: "(UTC+01:00) Africa/Ceuta",
		zoneId: "Africa/Ceuta",
	},
	{
		value: 17,
		name: "(UTC+00:00) Africa/Conakry",
		zoneId: "Africa/Conakry",
	},
	{
		value: 18,
		name: "(UTC+00:00) Africa/Dakar",
		zoneId: "Africa/Dakar",
	},
	{
		value: 19,
		name: "(UTC+03:00) Africa/Dar_es_Salaam",
		zoneId: "Africa/Dar_es_Salaam",
	},
	{
		value: 20,
		name: "(UTC+03:00) Africa/Djibouti",
		zoneId: "Africa/Djibouti",
	},
	{
		value: 21,
		name: "(UTC+01:00) Africa/Douala",
		zoneId: "Africa/Douala",
	},
	{
		value: 22,
		name: "(UTC+00:00) Africa/El_Aaiun",
		zoneId: "Africa/El_Aaiun",
	},
	{
		value: 23,
		name: "(UTC+00:00) Africa/Freetown",
		zoneId: "Africa/Freetown",
	},
	{
		value: 24,
		name: "(UTC+02:00) Africa/Gaborone",
		zoneId: "Africa/Gaborone",
	},
	{
		value: 25,
		name: "(UTC+02:00) Africa/Harare",
		zoneId: "Africa/Harare",
	},
	{
		value: 26,
		name: "(UTC+02:00) Africa/Johannesburg",
		zoneId: "Africa/Johannesburg",
	},
	{
		value: 27,
		name: "(UTC+02:00) Africa/Juba",
		zoneId: "Africa/Juba",
	},
	{
		value: 28,
		name: "(UTC+03:00) Africa/Kampala",
		zoneId: "Africa/Kampala",
	},
	{
		value: 29,
		name: "(UTC+02:00) Africa/Khartoum",
		zoneId: "Africa/Khartoum",
	},
	{
		value: 30,
		name: "(UTC+02:00) Africa/Kigali",
		zoneId: "Africa/Kigali",
	},
	{
		value: 31,
		name: "(UTC+01:00) Africa/Kinshasa",
		zoneId: "Africa/Kinshasa",
	},
	{
		value: 32,
		name: "(UTC+01:00) Africa/Lagos",
		zoneId: "Africa/Lagos",
	},
	{
		value: 33,
		name: "(UTC+01:00) Africa/Libreville",
		zoneId: "Africa/Libreville",
	},
	{
		value: 34,
		name: "(UTC+00:00) Africa/Lome",
		zoneId: "Africa/Lome",
	},
	{
		value: 35,
		name: "(UTC+01:00) Africa/Luanda",
		zoneId: "Africa/Luanda",
	},
	{
		value: 36,
		name: "(UTC+02:00) Africa/Lubumbashi",
		zoneId: "Africa/Lubumbashi",
	},
	{
		value: 37,
		name: "(UTC+02:00) Africa/Lusaka",
		zoneId: "Africa/Lusaka",
	},
	{
		value: 38,
		name: "(UTC+01:00) Africa/Malabo",
		zoneId: "Africa/Malabo",
	},
	{
		value: 39,
		name: "(UTC+02:00) Africa/Maputo",
		zoneId: "Africa/Maputo",
	},
	{
		value: 40,
		name: "(UTC+02:00) Africa/Maseru",
		zoneId: "Africa/Maseru",
	},
	{
		value: 41,
		name: "(UTC+02:00) Africa/Mbabane",
		zoneId: "Africa/Mbabane",
	},
	{
		value: 42,
		name: "(UTC+03:00) Africa/Mogadishu",
		zoneId: "Africa/Mogadishu",
	},
	{
		value: 43,
		name: "(UTC+00:00) Africa/Monrovia",
		zoneId: "Africa/Monrovia",
	},
	{
		value: 44,
		name: "(UTC+03:00) Africa/Nairobi",
		zoneId: "Africa/Nairobi",
	},
	{
		value: 45,
		name: "(UTC+01:00) Africa/Ndjamena",
		zoneId: "Africa/Ndjamena",
	},
	{
		value: 46,
		name: "(UTC+01:00) Africa/Niamey",
		zoneId: "Africa/Niamey",
	},
	{
		value: 47,
		name: "(UTC+00:00) Africa/Nouakchott",
		zoneId: "Africa/Nouakchott",
	},
	{
		value: 48,
		name: "(UTC+00:00) Africa/Ouagadougou",
		zoneId: "Africa/Ouagadougou",
	},
	{
		value: 49,
		name: "(UTC+01:00) Africa/Porto-Novo",
		zoneId: "Africa/Porto-Novo",
	},
	{
		value: 50,
		name: "(UTC+00:00) Africa/Sao_Tome",
		zoneId: "Africa/Sao_Tome",
	},
	{
		value: 51,
		name: "(UTC+00:00) Africa/Timbuktu",
		zoneId: "Africa/Timbuktu",
	},
	{
		value: 52,
		name: "(UTC+02:00) Africa/Tripoli",
		zoneId: "Africa/Tripoli",
	},
	{
		value: 53,
		name: "(UTC+01:00) Africa/Tunis",
		zoneId: "Africa/Tunis",
	},
	{
		value: 54,
		name: "(UTC+02:00) Africa/Windhoek",
		zoneId: "Africa/Windhoek",
	},
	{
		value: 55,
		name: "(UTC-09:00) America/Adak",
		zoneId: "America/Adak",
	},
	{
		value: 56,
		name: "(UTC-09:00) America/Anchorage",
		zoneId: "America/Anchorage",
	},
	{
		value: 57,
		name: "(UTC-04:00) America/Anguilla",
		zoneId: "America/Anguilla",
	},
	{
		value: 58,
		name: "(UTC-04:00) America/Antigua",
		zoneId: "America/Antigua",
	},
	{
		value: 59,
		name: "(UTC-03:00) America/Araguaina",
		zoneId: "America/Araguaina",
	},
	{
		value: 60,
		name: "(UTC-03:00) America/Argentina/Buenos_Aires",
		zoneId: "America/Argentina/Buenos_Aires",
	},
	{
		value: 61,
		name: "(UTC-03:00) America/Argentina/Catamarca",
		zoneId: "America/Argentina/Catamarca",
	},
	{
		value: 62,
		name: "(UTC-03:00) America/Argentina/ComodRivadavia",
		zoneId: "America/Argentina/ComodRivadavia",
	},
	{
		value: 63,
		name: "(UTC-03:00) America/Argentina/Cordoba",
		zoneId: "America/Argentina/Cordoba",
	},
	{
		value: 64,
		name: "(UTC-03:00) America/Argentina/Jujuy",
		zoneId: "America/Argentina/Jujuy",
	},
	{
		value: 65,
		name: "(UTC-03:00) America/Argentina/La_Rioja",
		zoneId: "America/Argentina/La_Rioja",
	},
	{
		value: 66,
		name: "(UTC-03:00) America/Argentina/Mendoza",
		zoneId: "America/Argentina/Mendoza",
	},
	{
		value: 67,
		name: "(UTC-03:00) America/Argentina/Rio_Gallegos",
		zoneId: "America/Argentina/Rio_Gallegos",
	},
	{
		value: 68,
		name: "(UTC-03:00) America/Argentina/Salta",
		zoneId: "America/Argentina/Salta",
	},
	{
		value: 69,
		name: "(UTC-03:00) America/Argentina/San_Juan",
		zoneId: "America/Argentina/San_Juan",
	},
	{
		value: 70,
		name: "(UTC-03:00) America/Argentina/San_Luis",
		zoneId: "America/Argentina/San_Luis",
	},
	{
		value: 71,
		name: "(UTC-03:00) America/Argentina/Tucuman",
		zoneId: "America/Argentina/Tucuman",
	},
	{
		value: 72,
		name: "(UTC-03:00) America/Argentina/Ushuaia",
		zoneId: "America/Argentina/Ushuaia",
	},
	{
		value: 73,
		name: "(UTC-04:00) America/Aruba",
		zoneId: "America/Aruba",
	},
	{
		value: 74,
		name: "(UTC-04:00) America/Asuncion",
		zoneId: "America/Asuncion",
	},
	{
		value: 75,
		name: "(UTC-05:00) America/Atikokan",
		zoneId: "America/Atikokan",
	},
	{
		value: 76,
		name: "(UTC-10:00) America/Atka",
		zoneId: "America/Atka",
	},
	{
		value: 77,
		name: "(UTC-03:00) America/Bahia",
		zoneId: "America/Bahia",
	},
	{
		value: 78,
		name: "(UTC-06:00) America/Bahia_Banderas",
		zoneId: "America/Bahia_Banderas",
	},
	{
		value: 79,
		name: "(UTC-04:00) America/Barbados",
		zoneId: "America/Barbados",
	},
	{
		value: 80,
		name: "(UTC-03:00) America/Belem",
		zoneId: "America/Belem",
	},
	{
		value: 81,
		name: "(UTC-06:00) America/Belize",
		zoneId: "America/Belize",
	},
	{
		value: 82,
		name: "(UTC-04:00) America/Blanc-Sablon",
		zoneId: "America/Blanc-Sablon",
	},
	{
		value: 83,
		name: "(UTC-04:00) America/Boa_Vista",
		zoneId: "America/Boa_Vista",
	},
	{
		value: 84,
		name: "(UTC-05:00) America/Bogota",
		zoneId: "America/Bogota",
	},
	{
		value: 85,
		name: "(UTC-07:00) America/Boise",
		zoneId: "America/Boise",
	},
	{
		value: 86,
		name: "(UTC-03:00) America/Buenos_Aires",
		zoneId: "America/Buenos_Aires",
	},
	{
		value: 87,
		name: "(UTC-07:00) America/Cambridge_Bay",
		zoneId: "America/Cambridge_Bay",
	},
	{
		value: 88,
		name: "(UTC-04:00) America/Campo_Grande",
		zoneId: "America/Campo_Grande",
	},
	{
		value: 89,
		name: "(UTC-05:00) America/Cancun",
		zoneId: "America/Cancun",
	},
	{
		value: 90,
		name: "(UTC-04:00) America/Caracas",
		zoneId: "America/Caracas",
	},
	{
		value: 91,
		name: "(UTC-03:00) America/Catamarca",
		zoneId: "America/Catamarca",
	},
	{
		value: 92,
		name: "(UTC-03:00) America/Cayenne",
		zoneId: "America/Cayenne",
	},
	{
		value: 93,
		name: "(UTC-05:00) America/Cayman",
		zoneId: "America/Cayman",
	},
	{
		value: 94,
		name: "(UTC-06:00) America/Chicago",
		zoneId: "America/Chicago",
	},
	{
		value: 95,
		name: "(UTC-06:00) America/Chihuahua",
		zoneId: "America/Chihuahua",
	},
	{
		value: 96,
		name: "(UTC-07:00) America/Ciudad_Juarez",
		zoneId: "America/Ciudad_Juarez",
	},
	{
		value: 97,
		name: "(UTC-05:00) America/Coral_Harbour",
		zoneId: "America/Coral_Harbour",
	},
	{
		value: 98,
		name: "(UTC-03:00) America/Cordoba",
		zoneId: "America/Cordoba",
	},
	{
		value: 99,
		name: "(UTC-06:00) America/Costa_Rica",
		zoneId: "America/Costa_Rica",
	},
	{
		value: 100,
		name: "(UTC-07:00) America/Creston",
		zoneId: "America/Creston",
	},
	{
		value: 101,
		name: "(UTC-04:00) America/Cuiaba",
		zoneId: "America/Cuiaba",
	},
	{
		value: 102,
		name: "(UTC-04:00) America/Curacao",
		zoneId: "America/Curacao",
	},
	{
		value: 103,
		name: "(UTC+00:00) America/Danmarkshavn",
		zoneId: "America/Danmarkshavn",
	},
	{
		value: 104,
		name: "(UTC-07:00) America/Dawson",
		zoneId: "America/Dawson",
	},
	{
		value: 105,
		name: "(UTC-07:00) America/Dawson_Creek",
		zoneId: "America/Dawson_Creek",
	},
	{
		value: 106,
		name: "(UTC-07:00) America/Denver",
		zoneId: "America/Denver",
	},
	{
		value: 107,
		name: "(UTC-05:00) America/Detroit",
		zoneId: "America/Detroit",
	},
	{
		value: 108,
		name: "(UTC-04:00) America/Dominica",
		zoneId: "America/Dominica",
	},
	{
		value: 109,
		name: "(UTC-07:00) America/Edmonton",
		zoneId: "America/Edmonton",
	},
	{
		value: 110,
		name: "(UTC-05:00) America/Eirunepe",
		zoneId: "America/Eirunepe",
	},
	{
		value: 111,
		name: "(UTC-06:00) America/El_Salvador",
		zoneId: "America/El_Salvador",
	},
	{
		value: 112,
		name: "(UTC-08:00) America/Ensenada",
		zoneId: "America/Ensenada",
	},
	{
		value: 113,
		name: "(UTC-07:00) America/Fort_Nelson",
		zoneId: "America/Fort_Nelson",
	},
	{
		value: 114,
		name: "(UTC-05:00) America/Fort_Wayne",
		zoneId: "America/Fort_Wayne",
	},
	{
		value: 115,
		name: "(UTC-03:00) America/Fortaleza",
		zoneId: "America/Fortaleza",
	},
	{
		value: 116,
		name: "(UTC-04:00) America/Glace_Bay",
		zoneId: "America/Glace_Bay",
	},
	{
		value: 117,
		name: "(UTC-02:00) America/Godthab",
		zoneId: "America/Godthab",
	},
	{
		value: 118,
		name: "(UTC-04:00) America/Goose_Bay",
		zoneId: "America/Goose_Bay",
	},
	{
		value: 119,
		name: "(UTC-05:00) America/Grand_Turk",
		zoneId: "America/Grand_Turk",
	},
	{
		value: 120,
		name: "(UTC-04:00) America/Grenada",
		zoneId: "America/Grenada",
	},
	{
		value: 121,
		name: "(UTC-04:00) America/Guadeloupe",
		zoneId: "America/Guadeloupe",
	},
	{
		value: 122,
		name: "(UTC-06:00) America/Guatemala",
		zoneId: "America/Guatemala",
	},
	{
		value: 123,
		name: "(UTC-05:00) America/Guayaquil",
		zoneId: "America/Guayaquil",
	},
	{
		value: 124,
		name: "(UTC-04:00) America/Guyana",
		zoneId: "America/Guyana",
	},
	{
		value: 125,
		name: "(UTC-04:00) America/Halifax",
		zoneId: "America/Halifax",
	},
	{
		value: 126,
		name: "(UTC-05:00) America/Havana",
		zoneId: "America/Havana",
	},
	{
		value: 127,
		name: "(UTC-07:00) America/Hermosillo",
		zoneId: "America/Hermosillo",
	},
	{
		value: 128,
		name: "(UTC-05:00) America/Indiana/Indianapolis",
		zoneId: "America/Indiana/Indianapolis",
	},
	{
		value: 129,
		name: "(UTC-06:00) America/Indiana/Knox",
		zoneId: "America/Indiana/Knox",
	},
	{
		value: 130,
		name: "(UTC-05:00) America/Indiana/Marengo",
		zoneId: "America/Indiana/Marengo",
	},
	{
		value: 131,
		name: "(UTC-05:00) America/Indiana/Petersburg",
		zoneId: "America/Indiana/Petersburg",
	},
	{
		value: 132,
		name: "(UTC-06:00) America/Indiana/Tell_City",
		zoneId: "America/Indiana/Tell_City",
	},
	{
		value: 133,
		name: "(UTC-05:00) America/Indiana/Vevay",
		zoneId: "America/Indiana/Vevay",
	},
	{
		value: 134,
		name: "(UTC-05:00) America/Indiana/Vincennes",
		zoneId: "America/Indiana/Vincennes",
	},
	{
		value: 135,
		name: "(UTC-05:00) America/Indiana/Winamac",
		zoneId: "America/Indiana/Winamac",
	},
	{
		value: 136,
		name: "(UTC-05:00) America/Indianapolis",
		zoneId: "America/Indianapolis",
	},
	{
		value: 137,
		name: "(UTC-07:00) America/Inuvik",
		zoneId: "America/Inuvik",
	},
	{
		value: 138,
		name: "(UTC-05:00) America/Iqaluit",
		zoneId: "America/Iqaluit",
	},
	{
		value: 139,
		name: "(UTC-05:00) America/Jamaica",
		zoneId: "America/Jamaica",
	},
	{
		value: 140,
		name: "(UTC-03:00) America/Jujuy",
		zoneId: "America/Jujuy",
	},
	{
		value: 141,
		name: "(UTC-09:00) America/Juneau",
		zoneId: "America/Juneau",
	},
	{
		value: 142,
		name: "(UTC-05:00) America/Kentucky/Louisville",
		zoneId: "America/Kentucky/Louisville",
	},
	{
		value: 143,
		name: "(UTC-05:00) America/Kentucky/Monticello",
		zoneId: "America/Kentucky/Monticello",
	},
	{
		value: 144,
		name: "(UTC-06:00) America/Knox_IN",
		zoneId: "America/Knox_IN",
	},
	{
		value: 145,
		name: "(UTC-04:00) America/Kralendijk",
		zoneId: "America/Kralendijk",
	},
	{
		value: 146,
		name: "(UTC-04:00) America/La_Paz",
		zoneId: "America/La_Paz",
	},
	{
		value: 147,
		name: "(UTC-05:00) America/Lima",
		zoneId: "America/Lima",
	},
	{
		value: 148,
		name: "(UTC-08:00) America/Los_Angeles",
		zoneId: "America/Los_Angeles",
	},
	{
		value: 149,
		name: "(UTC-05:00) America/Louisville",
		zoneId: "America/Louisville",
	},
	{
		value: 150,
		name: "(UTC-04:00) America/Lower_Princes",
		zoneId: "America/Lower_Princes",
	},
	{
		value: 151,
		name: "(UTC-03:00) America/Maceio",
		zoneId: "America/Maceio",
	},
	{
		value: 152,
		name: "(UTC-06:00) America/Managua",
		zoneId: "America/Managua",
	},
	{
		value: 153,
		name: "(UTC-04:00) America/Manaus",
		zoneId: "America/Manaus",
	},
	{
		value: 154,
		name: "(UTC-04:00) America/Marigot",
		zoneId: "America/Marigot",
	},
	{
		value: 155,
		name: "(UTC-04:00) America/Martinique",
		zoneId: "America/Martinique",
	},
	{
		value: 156,
		name: "(UTC-06:00) America/Matamoros",
		zoneId: "America/Matamoros",
	},
	{
		value: 157,
		name: "(UTC-07:00) America/Mazatlan",
		zoneId: "America/Mazatlan",
	},
	{
		value: 158,
		name: "(UTC-03:00) America/Mendoza",
		zoneId: "America/Mendoza",
	},
	{
		value: 159,
		name: "(UTC-06:00) America/Menominee",
		zoneId: "America/Menominee",
	},
	{
		value: 160,
		name: "(UTC-06:00) America/Merida",
		zoneId: "America/Merida",
	},
	{
		value: 161,
		name: "(UTC-09:00) America/Metlakatla",
		zoneId: "America/Metlakatla",
	},
	{
		value: 162,
		name: "(UTC-06:00) America/Mexico_City",
		zoneId: "America/Mexico_City",
	},
	{
		value: 163,
		name: "(UTC-03:00) America/Miquelon",
		zoneId: "America/Miquelon",
	},
	{
		value: 164,
		name: "(UTC-04:00) America/Moncton",
		zoneId: "America/Moncton",
	},
	{
		value: 165,
		name: "(UTC-06:00) America/Monterrey",
		zoneId: "America/Monterrey",
	},
	{
		value: 166,
		name: "(UTC-03:00) America/Montevideo",
		zoneId: "America/Montevideo",
	},
	{
		value: 167,
		name: "(UTC-05:00) America/Montreal",
		zoneId: "America/Montreal",
	},
	{
		value: 168,
		name: "(UTC-04:00) America/Montserrat",
		zoneId: "America/Montserrat",
	},
	{
		value: 169,
		name: "(UTC-05:00) America/Nassau",
		zoneId: "America/Nassau",
	},
	{
		value: 170,
		name: "(UTC-05:00) America/New_York",
		zoneId: "America/New_York",
	},
	{
		value: 171,
		name: "(UTC-05:00) America/Nipigon",
		zoneId: "America/Nipigon",
	},
	{
		value: 172,
		name: "(UTC-09:00) America/Nome",
		zoneId: "America/Nome",
	},
	{
		value: 173,
		name: "(UTC-02:00) America/Noronha",
		zoneId: "America/Noronha",
	},
	{
		value: 174,
		name: "(UTC-06:00) America/North_Dakota/Beulah",
		zoneId: "America/North_Dakota/Beulah",
	},
	{
		value: 175,
		name: "(UTC-06:00) America/North_Dakota/Center",
		zoneId: "America/North_Dakota/Center",
	},
	{
		value: 176,
		name: "(UTC-06:00) America/North_Dakota/New_Salem",
		zoneId: "America/North_Dakota/New_Salem",
	},
	{
		value: 177,
		name: "(UTC-02:00) America/Nuuk",
		zoneId: "America/Nuuk",
	},
	{
		value: 178,
		name: "(UTC-06:00) America/Ojinaga",
		zoneId: "America/Ojinaga",
	},
	{
		value: 179,
		name: "(UTC-05:00) America/Panama",
		zoneId: "America/Panama",
	},
	{
		value: 180,
		name: "(UTC-05:00) America/Pangnirtung",
		zoneId: "America/Pangnirtung",
	},
	{
		value: 181,
		name: "(UTC-03:00) America/Paramaribo",
		zoneId: "America/Paramaribo",
	},
	{
		value: 182,
		name: "(UTC-07:00) America/Phoenix",
		zoneId: "America/Phoenix",
	},
	{
		value: 183,
		name: "(UTC-05:00) America/Port-au-Prince",
		zoneId: "America/Port-au-Prince",
	},
	{
		value: 184,
		name: "(UTC-04:00) America/Port_of_Spain",
		zoneId: "America/Port_of_Spain",
	},
	{
		value: 185,
		name: "(UTC-05:00) America/Porto_Acre",
		zoneId: "America/Porto_Acre",
	},
	{
		value: 186,
		name: "(UTC-04:00) America/Porto_Velho",
		zoneId: "America/Porto_Velho",
	},
	{
		value: 187,
		name: "(UTC-04:00) America/Puerto_Rico",
		zoneId: "America/Puerto_Rico",
	},
	{
		value: 188,
		name: "(UTC-03:00) America/Punta_Arenas",
		zoneId: "America/Punta_Arenas",
	},
	{
		value: 189,
		name: "(UTC-06:00) America/Rainy_River",
		zoneId: "America/Rainy_River",
	},
	{
		value: 190,
		name: "(UTC-06:00) America/Rankin_Inlet",
		zoneId: "America/Rankin_Inlet",
	},
	{
		value: 191,
		name: "(UTC-03:00) America/Recife",
		zoneId: "America/Recife",
	},
	{
		value: 192,
		name: "(UTC-06:00) America/Regina",
		zoneId: "America/Regina",
	},
	{
		value: 193,
		name: "(UTC-06:00) America/Resolute",
		zoneId: "America/Resolute",
	},
	{
		value: 194,
		name: "(UTC-05:00) America/Rio_Branco",
		zoneId: "America/Rio_Branco",
	},
	{
		value: 195,
		name: "(UTC-03:00) America/Rosario",
		zoneId: "America/Rosario",
	},
	{
		value: 196,
		name: "(UTC-08:00) America/Santa_Isabel",
		zoneId: "America/Santa_Isabel",
	},
	{
		value: 197,
		name: "(UTC-03:00) America/Santarem",
		zoneId: "America/Santarem",
	},
	{
		value: 198,
		name: "(UTC-04:00) America/Santiago",
		zoneId: "America/Santiago",
	},
	{
		value: 199,
		name: "(UTC-04:00) America/Santo_Domingo",
		zoneId: "America/Santo_Domingo",
	},
	{
		value: 200,
		name: "(UTC-03:00) America/Sao_Paulo",
		zoneId: "America/Sao_Paulo",
	},
	{
		value: 201,
		name: "(UTC-01:00) America/Scoresbysund",
		zoneId: "America/Scoresbysund",
	},
	{
		value: 202,
		name: "(UTC-07:00) America/Shiprock",
		zoneId: "America/Shiprock",
	},
	{
		value: 203,
		name: "(UTC-09:00) America/Sitka",
		zoneId: "America/Sitka",
	},
	{
		value: 204,
		name: "(UTC-04:00) America/St_Barthelemy",
		zoneId: "America/St_Barthelemy",
	},
	{
		value: 205,
		name: "(UTC-03:30) America/St_Johns",
		zoneId: "America/St_Johns",
	},
	{
		value: 206,
		name: "(UTC-04:00) America/St_Kitts",
		zoneId: "America/St_Kitts",
	},
	{
		value: 207,
		name: "(UTC-04:00) America/St_Lucia",
		zoneId: "America/St_Lucia",
	},
	{
		value: 208,
		name: "(UTC-04:00) America/St_Thomas",
		zoneId: "America/St_Thomas",
	},
	{
		value: 209,
		name: "(UTC-04:00) America/St_Vincent",
		zoneId: "America/St_Vincent",
	},
	{
		value: 210,
		name: "(UTC-06:00) America/Swift_Current",
		zoneId: "America/Swift_Current",
	},
	{
		value: 211,
		name: "(UTC-06:00) America/Tegucigalpa",
		zoneId: "America/Tegucigalpa",
	},
	{
		value: 212,
		name: "(UTC-04:00) America/Thule",
		zoneId: "America/Thule",
	},
	{
		value: 213,
		name: "(UTC-05:00) America/Thunder_Bay",
		zoneId: "America/Thunder_Bay",
	},
	{
		value: 214,
		name: "(UTC-08:00) America/Tijuana",
		zoneId: "America/Tijuana",
	},
	{
		value: 215,
		name: "(UTC-05:00) America/Toronto",
		zoneId: "America/Toronto",
	},
	{
		value: 216,
		name: "(UTC-04:00) America/Tortola",
		zoneId: "America/Tortola",
	},
	{
		value: 217,
		name: "(UTC-08:00) America/Vancouver",
		zoneId: "America/Vancouver",
	},
	{
		value: 218,
		name: "(UTC-04:00) America/Virgin",
		zoneId: "America/Virgin",
	},
	{
		value: 219,
		name: "(UTC-07:00) America/Whitehorse",
		zoneId: "America/Whitehorse",
	},
	{
		value: 220,
		name: "(UTC-06:00) America/Winnipeg",
		zoneId: "America/Winnipeg",
	},
	{
		value: 221,
		name: "(UTC-09:00) America/Yakutat",
		zoneId: "America/Yakutat",
	},
	{
		value: 222,
		name: "(UTC-07:00) America/Yellowknife",
		zoneId: "America/Yellowknife",
	},
	{
		value: 223,
		name: "(UTC+11:00) Antarctica/Casey",
		zoneId: "Antarctica/Casey",
	},
	{
		value: 224,
		name: "(UTC+07:00) Antarctica/Davis",
		zoneId: "Antarctica/Davis",
	},
	{
		value: 225,
		name: "(UTC+10:00) Antarctica/DumontDUrville",
		zoneId: "Antarctica/DumontDUrville",
	},
	{
		value: 226,
		name: "(UTC+10:00) Antarctica/Macquarie",
		zoneId: "Antarctica/Macquarie",
	},
	{
		value: 227,
		name: "(UTC+05:00) Antarctica/Mawson",
		zoneId: "Antarctica/Mawson",
	},
	{
		value: 228,
		name: "(UTC+12:00) Antarctica/McMurdo",
		zoneId: "Antarctica/McMurdo",
	},
	{
		value: 229,
		name: "(UTC-03:00) Antarctica/Palmer",
		zoneId: "Antarctica/Palmer",
	},
	{
		value: 230,
		name: "(UTC-03:00) Antarctica/Rothera",
		zoneId: "Antarctica/Rothera",
	},
	{
		value: 231,
		name: "(UTC+12:00) Antarctica/South_Pole",
		zoneId: "Antarctica/South_Pole",
	},
	{
		value: 232,
		name: "(UTC+03:00) Antarctica/Syowa",
		zoneId: "Antarctica/Syowa",
	},
	{
		value: 233,
		name: "(UTC+00:00) Antarctica/Troll",
		zoneId: "Antarctica/Troll",
	},
	{
		value: 234,
		name: "(UTC+06:00) Antarctica/Vostok",
		zoneId: "Antarctica/Vostok",
	},
	{
		value: 235,
		name: "(UTC+01:00) Arctic/Longyearbyen",
		zoneId: "Arctic/Longyearbyen",
	},
	{
		value: 236,
		name: "(UTC+03:00) Asia/Aden",
		zoneId: "Asia/Aden",
	},
	{
		value: 237,
		name: "(UTC+06:00) Asia/Almaty",
		zoneId: "Asia/Almaty",
	},
	{
		value: 238,
		name: "(UTC+03:00) Asia/Amman",
		zoneId: "Asia/Amman",
	},
	{
		value: 239,
		name: "(UTC+12:00) Asia/Anadyr",
		zoneId: "Asia/Anadyr",
	},
	{
		value: 240,
		name: "(UTC+05:00) Asia/Aqtau",
		zoneId: "Asia/Aqtau",
	},
	{
		value: 241,
		name: "(UTC+05:00) Asia/Aqtobe",
		zoneId: "Asia/Aqtobe",
	},
	{
		value: 242,
		name: "(UTC+05:00) Asia/Ashgabat",
		zoneId: "Asia/Ashgabat",
	},
	{
		value: 243,
		name: "(UTC+05:00) Asia/Ashkhabad",
		zoneId: "Asia/Ashkhabad",
	},
	{
		value: 244,
		name: "(UTC+05:00) Asia/Atyrau",
		zoneId: "Asia/Atyrau",
	},
	{
		value: 245,
		name: "(UTC+03:00) Asia/Baghdad",
		zoneId: "Asia/Baghdad",
	},
	{
		value: 246,
		name: "(UTC+03:00) Asia/Bahrain",
		zoneId: "Asia/Bahrain",
	},
	{
		value: 247,
		name: "(UTC+04:00) Asia/Baku",
		zoneId: "Asia/Baku",
	},
	{
		value: 248,
		name: "(UTC+07:00) Asia/Bangkok",
		zoneId: "Asia/Bangkok",
	},
	{
		value: 249,
		name: "(UTC+07:00) Asia/Barnaul",
		zoneId: "Asia/Barnaul",
	},
	{
		value: 250,
		name: "(UTC+02:00) Asia/Beirut",
		zoneId: "Asia/Beirut",
	},
	{
		value: 251,
		name: "(UTC+06:00) Asia/Bishkek",
		zoneId: "Asia/Bishkek",
	},
	{
		value: 252,
		name: "(UTC+08:00) Asia/Brunei",
		zoneId: "Asia/Brunei",
	},
	{
		value: 253,
		name: "(UTC+05:30) Asia/Calcutta",
		zoneId: "Asia/Calcutta",
	},
	{
		value: 254,
		name: "(UTC+09:00) Asia/Chita",
		zoneId: "Asia/Chita",
	},
	{
		value: 255,
		name: "(UTC+08:00) Asia/Choibalsan",
		zoneId: "Asia/Choibalsan",
	},
	{
		value: 256,
		name: "(UTC+08:00) Asia/Chongqing",
		zoneId: "Asia/Chongqing",
	},
	{
		value: 257,
		name: "(UTC+08:00) Asia/Chungking",
		zoneId: "Asia/Chungking",
	},
	{
		value: 258,
		name: "(UTC+05:30) Asia/Colombo",
		zoneId: "Asia/Colombo",
	},
	{
		value: 259,
		name: "(UTC+06:00) Asia/Dacca",
		zoneId: "Asia/Dacca",
	},
	{
		value: 260,
		name: "(UTC+03:00) Asia/Damascus",
		zoneId: "Asia/Damascus",
	},
	{
		value: 261,
		name: "(UTC+06:00) Asia/Dhaka",
		zoneId: "Asia/Dhaka",
	},
	{
		value: 262,
		name: "(UTC+09:00) Asia/Dili",
		zoneId: "Asia/Dili",
	},
	{
		value: 263,
		name: "(UTC+04:00) Asia/Dubai",
		zoneId: "Asia/Dubai",
	},
	{
		value: 264,
		name: "(UTC+05:00) Asia/Dushanbe",
		zoneId: "Asia/Dushanbe",
	},
	{
		value: 265,
		name: "(UTC+02:00) Asia/Famagusta",
		zoneId: "Asia/Famagusta",
	},
	{
		value: 266,
		name: "(UTC+02:00) Asia/Gaza",
		zoneId: "Asia/Gaza",
	},
	{
		value: 267,
		name: "(UTC+08:00) Asia/Harbin",
		zoneId: "Asia/Harbin",
	},
	{
		value: 268,
		name: "(UTC+02:00) Asia/Hebron",
		zoneId: "Asia/Hebron",
	},
	{
		value: 269,
		name: "(UTC+07:00) Asia/Ho_Chi_Minh",
		zoneId: "Asia/Ho_Chi_Minh",
	},
	{
		value: 270,
		name: "(UTC+08:00) Asia/Hong_Kong",
		zoneId: "Asia/Hong_Kong",
	},
	{
		value: 271,
		name: "(UTC+07:00) Asia/Hovd",
		zoneId: "Asia/Hovd",
	},
	{
		value: 272,
		name: "(UTC+08:00) Asia/Irkutsk",
		zoneId: "Asia/Irkutsk",
	},
	{
		value: 273,
		name: "(UTC+03:00) Asia/Istanbul",
		zoneId: "Asia/Istanbul",
	},
	{
		value: 274,
		name: "(UTC+07:00) Asia/Jakarta",
		zoneId: "Asia/Jakarta",
	},
	{
		value: 275,
		name: "(UTC+09:00) Asia/Jayapura",
		zoneId: "Asia/Jayapura",
	},
	{
		value: 276,
		name: "(UTC+02:00) Asia/Jerusalem",
		zoneId: "Asia/Jerusalem",
	},
	{
		value: 277,
		name: "(UTC+04:30) Asia/Kabul",
		zoneId: "Asia/Kabul",
	},
	{
		value: 278,
		name: "(UTC+12:00) Asia/Kamchatka",
		zoneId: "Asia/Kamchatka",
	},
	{
		value: 279,
		name: "(UTC+05:00) Asia/Karachi",
		zoneId: "Asia/Karachi",
	},
	{
		value: 280,
		name: "(UTC+06:00) Asia/Kashgar",
		zoneId: "Asia/Kashgar",
	},
	{
		value: 281,
		name: "(UTC+05:45) Asia/Kathmandu",
		zoneId: "Asia/Kathmandu",
	},
	{
		value: 282,
		name: "(UTC+05:45) Asia/Katmandu",
		zoneId: "Asia/Katmandu",
	},
	{
		value: 283,
		name: "(UTC+09:00) Asia/Khandyga",
		zoneId: "Asia/Khandyga",
	},
	{
		value: 284,
		name: "(UTC+05:30) Asia/Kolkata",
		zoneId: "Asia/Kolkata",
	},
	{
		value: 285,
		name: "(UTC+07:00) Asia/Krasnoyarsk",
		zoneId: "Asia/Krasnoyarsk",
	},
	{
		value: 286,
		name: "(UTC+08:00) Asia/Kuala_Lumpur",
		zoneId: "Asia/Kuala_Lumpur",
	},
	{
		value: 287,
		name: "(UTC+08:00) Asia/Kuching",
		zoneId: "Asia/Kuching",
	},
	{
		value: 288,
		name: "(UTC+03:00) Asia/Kuwait",
		zoneId: "Asia/Kuwait",
	},
	{
		value: 289,
		name: "(UTC+08:00) Asia/Macao",
		zoneId: "Asia/Macao",
	},
	{
		value: 290,
		name: "(UTC+08:00) Asia/Macau",
		zoneId: "Asia/Macau",
	},
	{
		value: 291,
		name: "(UTC+11:00) Asia/Magadan",
		zoneId: "Asia/Magadan",
	},
	{
		value: 292,
		name: "(UTC+08:00) Asia/Makassar",
		zoneId: "Asia/Makassar",
	},
	{
		value: 293,
		name: "(UTC+08:00) Asia/Manila",
		zoneId: "Asia/Manila",
	},
	{
		value: 294,
		name: "(UTC+04:00) Asia/Muscat",
		zoneId: "Asia/Muscat",
	},
	{
		value: 295,
		name: "(UTC+02:00) Asia/Nicosia",
		zoneId: "Asia/Nicosia",
	},
	{
		value: 296,
		name: "(UTC+07:00) Asia/Novokuznetsk",
		zoneId: "Asia/Novokuznetsk",
	},
	{
		value: 297,
		name: "(UTC+07:00) Asia/Novosibirsk",
		zoneId: "Asia/Novosibirsk",
	},
	{
		value: 298,
		name: "(UTC+06:00) Asia/Omsk",
		zoneId: "Asia/Omsk",
	},
	{
		value: 299,
		name: "(UTC+05:00) Asia/Oral",
		zoneId: "Asia/Oral",
	},
	{
		value: 300,
		name: "(UTC+07:00) Asia/Phnom_Penh",
		zoneId: "Asia/Phnom_Penh",
	},
	{
		value: 301,
		name: "(UTC+07:00) Asia/Pontianak",
		zoneId: "Asia/Pontianak",
	},
	{
		value: 302,
		name: "(UTC+09:00) Asia/Pyongyang",
		zoneId: "Asia/Pyongyang",
	},
	{
		value: 303,
		name: "(UTC+03:00) Asia/Qatar",
		zoneId: "Asia/Qatar",
	},
	{
		value: 304,
		name: "(UTC+06:00) Asia/Qostanay",
		zoneId: "Asia/Qostanay",
	},
	{
		value: 305,
		name: "(UTC+05:00) Asia/Qyzylorda",
		zoneId: "Asia/Qyzylorda",
	},
	{
		value: 306,
		name: "(UTC+06:30) Asia/Rangoon",
		zoneId: "Asia/Rangoon",
	},
	{
		value: 307,
		name: "(UTC+03:00) Asia/Riyadh",
		zoneId: "Asia/Riyadh",
	},
	{
		value: 308,
		name: "(UTC+07:00) Asia/Saigon",
		zoneId: "Asia/Saigon",
	},
	{
		value: 309,
		name: "(UTC+11:00) Asia/Sakhalin",
		zoneId: "Asia/Sakhalin",
	},
	{
		value: 310,
		name: "(UTC+05:00) Asia/Samarkand",
		zoneId: "Asia/Samarkand",
	},
	{
		value: 311,
		name: "(UTC+09:00) Asia/Seoul",
		zoneId: "Asia/Seoul",
	},
	{
		value: 312,
		name: "(UTC+08:00) Asia/Shanghai",
		zoneId: "Asia/Shanghai",
	},
	{
		value: 313,
		name: "(UTC+08:00) Asia/Singapore",
		zoneId: "Asia/Singapore",
	},
	{
		value: 314,
		name: "(UTC+11:00) Asia/Srednekolymsk",
		zoneId: "Asia/Srednekolymsk",
	},
	{
		value: 315,
		name: "(UTC+08:00) Asia/Taipei",
		zoneId: "Asia/Taipei",
	},
	{
		value: 316,
		name: "(UTC+05:00) Asia/Tashkent",
		zoneId: "Asia/Tashkent",
	},
	{
		value: 317,
		name: "(UTC+04:00) Asia/Tbilisi",
		zoneId: "Asia/Tbilisi",
	},
	{
		value: 318,
		name: "(UTC+03:30) Asia/Tehran",
		zoneId: "Asia/Tehran",
	},
	{
		value: 319,
		name: "(UTC+02:00) Asia/Tel_Aviv",
		zoneId: "Asia/Tel_Aviv",
	},
	{
		value: 320,
		name: "(UTC+06:00) Asia/Thimbu",
		zoneId: "Asia/Thimbu",
	},
	{
		value: 321,
		name: "(UTC+06:00) Asia/Thimphu",
		zoneId: "Asia/Thimphu",
	},
	{
		value: 322,
		name: "(UTC+09:00) Asia/Tokyo",
		zoneId: "Asia/Tokyo",
	},
	{
		value: 323,
		name: "(UTC+07:00) Asia/Tomsk",
		zoneId: "Asia/Tomsk",
	},
	{
		value: 324,
		name: "(UTC+08:00) Asia/Ujung_Pandang",
		zoneId: "Asia/Ujung_Pandang",
	},
	{
		value: 325,
		name: "(UTC+08:00) Asia/Ulaanbaatar",
		zoneId: "Asia/Ulaanbaatar",
	},
	{
		value: 326,
		name: "(UTC+08:00) Asia/Ulan_Bator",
		zoneId: "Asia/Ulan_Bator",
	},
	{
		value: 327,
		name: "(UTC+06:00) Asia/Urumqi",
		zoneId: "Asia/Urumqi",
	},
	{
		value: 328,
		name: "(UTC+10:00) Asia/Ust-Nera",
		zoneId: "Asia/Ust-Nera",
	},
	{
		value: 329,
		name: "(UTC+07:00) Asia/Vientiane",
		zoneId: "Asia/Vientiane",
	},
	{
		value: 330,
		name: "(UTC+10:00) Asia/Vladivostok",
		zoneId: "Asia/Vladivostok",
	},
	{
		value: 331,
		name: "(UTC+09:00) Asia/Yakutsk",
		zoneId: "Asia/Yakutsk",
	},
	{
		value: 332,
		name: "(UTC+06:30) Asia/Yangon",
		zoneId: "Asia/Yangon",
	},
	{
		value: 333,
		name: "(UTC+05:00) Asia/Yekaterinburg",
		zoneId: "Asia/Yekaterinburg",
	},
	{
		value: 334,
		name: "(UTC+04:00) Asia/Yerevan",
		zoneId: "Asia/Yerevan",
	},
	{
		value: 335,
		name: "(UTC-01:00) Atlantic/Azores",
		zoneId: "Atlantic/Azores",
	},
	{
		value: 336,
		name: "(UTC-04:00) Atlantic/Bermuda",
		zoneId: "Atlantic/Bermuda",
	},
	{
		value: 337,
		name: "(UTC+00:00) Atlantic/Canary",
		zoneId: "Atlantic/Canary",
	},
	{
		value: 338,
		name: "(UTC-01:00) Atlantic/Cape_Verde",
		zoneId: "Atlantic/Cape_Verde",
	},
	{
		value: 339,
		name: "(UTC+00:00) Atlantic/Faeroe",
		zoneId: "Atlantic/Faeroe",
	},
	{
		value: 340,
		name: "(UTC+00:00) Atlantic/Faroe",
		zoneId: "Atlantic/Faroe",
	},
	{
		value: 341,
		name: "(UTC+01:00) Atlantic/Jan_Mayen",
		zoneId: "Atlantic/Jan_Mayen",
	},
	{
		value: 342,
		name: "(UTC+00:00) Atlantic/Madeira",
		zoneId: "Atlantic/Madeira",
	},
	{
		value: 343,
		name: "(UTC+00:00) Atlantic/Reykjavik",
		zoneId: "Atlantic/Reykjavik",
	},
	{
		value: 344,
		name: "(UTC-02:00) Atlantic/South_Georgia",
		zoneId: "Atlantic/South_Georgia",
	},
	{
		value: 345,
		name: "(UTC+00:00) Atlantic/St_Helena",
		zoneId: "Atlantic/St_Helena",
	},
	{
		value: 346,
		name: "(UTC-03:00) Atlantic/Stanley",
		zoneId: "Atlantic/Stanley",
	},
	{
		value: 347,
		name: "(UTC+10:00) Australia/ACT",
		zoneId: "Australia/ACT",
	},
	{
		value: 348,
		name: "(UTC+09:30) Australia/Adelaide",
		zoneId: "Australia/Adelaide",
	},
	{
		value: 349,
		name: "(UTC+10:00) Australia/Brisbane",
		zoneId: "Australia/Brisbane",
	},
	{
		value: 350,
		name: "(UTC+09:30) Australia/Broken_Hill",
		zoneId: "Australia/Broken_Hill",
	},
	{
		value: 351,
		name: "(UTC+10:00) Australia/Canberra",
		zoneId: "Australia/Canberra",
	},
	{
		value: 352,
		name: "(UTC+10:00) Australia/Currie",
		zoneId: "Australia/Currie",
	},
	{
		value: 353,
		name: "(UTC+09:30) Australia/Darwin",
		zoneId: "Australia/Darwin",
	},
	{
		value: 354,
		name: "(UTC+08:45) Australia/Eucla",
		zoneId: "Australia/Eucla",
	},
	{
		value: 355,
		name: "(UTC+10:00) Australia/Hobart",
		zoneId: "Australia/Hobart",
	},
	{
		value: 356,
		name: "(UTC+10:30) Australia/LHI",
		zoneId: "Australia/LHI",
	},
	{
		value: 357,
		name: "(UTC+10:00) Australia/Lindeman",
		zoneId: "Australia/Lindeman",
	},
	{
		value: 358,
		name: "(UTC+10:30) Australia/Lord_Howe",
		zoneId: "Australia/Lord_Howe",
	},
	{
		value: 359,
		name: "(UTC+10:00) Australia/Melbourne",
		zoneId: "Australia/Melbourne",
	},
	{
		value: 360,
		name: "(UTC+10:00) Australia/NSW",
		zoneId: "Australia/NSW",
	},
	{
		value: 361,
		name: "(UTC+09:30) Australia/North",
		zoneId: "Australia/North",
	},
	{
		value: 362,
		name: "(UTC+08:00) Australia/Perth",
		zoneId: "Australia/Perth",
	},
	{
		value: 363,
		name: "(UTC+10:00) Australia/Queensland",
		zoneId: "Australia/Queensland",
	},
	{
		value: 364,
		name: "(UTC+09:30) Australia/South",
		zoneId: "Australia/South",
	},
	{
		value: 365,
		name: "(UTC+10:00) Australia/Sydney",
		zoneId: "Australia/Sydney",
	},
	{
		value: 366,
		name: "(UTC+10:00) Australia/Tasmania",
		zoneId: "Australia/Tasmania",
	},
	{
		value: 367,
		name: "(UTC+10:00) Australia/Victoria",
		zoneId: "Australia/Victoria",
	},
	{
		value: 368,
		name: "(UTC+08:00) Australia/West",
		zoneId: "Australia/West",
	},
	{
		value: 369,
		name: "(UTC+09:30) Australia/Yancowinna",
		zoneId: "Australia/Yancowinna",
	},
	{
		value: 370,
		name: "(UTC-05:00) Brazil/Acre",
		zoneId: "Brazil/Acre",
	},
	{
		value: 371,
		name: "(UTC-02:00) Brazil/DeNoronha",
		zoneId: "Brazil/DeNoronha",
	},
	{
		value: 372,
		name: "(UTC-03:00) Brazil/East",
		zoneId: "Brazil/East",
	},
	{
		value: 373,
		name: "(UTC-04:00) Brazil/West",
		zoneId: "Brazil/West",
	},
	{
		value: 374,
		name: "(UTC+01:00) CET",
		zoneId: "CET",
	},
	{
		value: 375,
		name: "(UTC-06:00) CST6CDT",
		zoneId: "CST6CDT",
	},
	{
		value: 376,
		name: "(UTC-04:00) Canada/Atlantic",
		zoneId: "Canada/Atlantic",
	},
	{
		value: 377,
		name: "(UTC-06:00) Canada/Central",
		zoneId: "Canada/Central",
	},
	{
		value: 378,
		name: "(UTC-05:00) Canada/Eastern",
		zoneId: "Canada/Eastern",
	},
	{
		value: 379,
		name: "(UTC-07:00) Canada/Mountain",
		zoneId: "Canada/Mountain",
	},
	{
		value: 380,
		name: "(UTC-03:30) Canada/Newfoundland",
		zoneId: "Canada/Newfoundland",
	},
	{
		value: 381,
		name: "(UTC-08:00) Canada/Pacific",
		zoneId: "Canada/Pacific",
	},
	{
		value: 382,
		name: "(UTC-06:00) Canada/Saskatchewan",
		zoneId: "Canada/Saskatchewan",
	},
	{
		value: 383,
		name: "(UTC-07:00) Canada/Yukon",
		zoneId: "Canada/Yukon",
	},
	{
		value: 384,
		name: "(UTC-04:00) Chile/Continental",
		zoneId: "Chile/Continental",
	},
	{
		value: 385,
		name: "(UTC-06:00) Chile/EasterIsland",
		zoneId: "Chile/EasterIsland",
	},
	{
		value: 386,
		name: "(UTC-05:00) Cuba",
		zoneId: "Cuba",
	},
	{
		value: 387,
		name: "(UTC+02:00) EET",
		zoneId: "EET",
	},
	{
		value: 388,
		name: "(UTC-05:00) EST5EDT",
		zoneId: "EST5EDT",
	},
	{
		value: 389,
		name: "(UTC+02:00) Egypt",
		zoneId: "Egypt",
	},
	{
		value: 390,
		name: "(UTC+00:00) Eire",
		zoneId: "Eire",
	},
	{
		value: 391,
		name: "(UTC+00:00) Etc/GMT",
		zoneId: "Etc/GMT",
	},
	{
		value: 392,
		name: "(UTC+00:00) Etc/GMT+0",
		zoneId: "Etc/GMT+0",
	},
	{
		value: 393,
		name: "(UTC-01:00) Etc/GMT+1",
		zoneId: "Etc/GMT+1",
	},
	{
		value: 394,
		name: "(UTC-10:00) Etc/GMT+10",
		zoneId: "Etc/GMT+10",
	},
	{
		value: 395,
		name: "(UTC-11:00) Etc/GMT+11",
		zoneId: "Etc/GMT+11",
	},
	{
		value: 396,
		name: "(UTC-12:00) Etc/GMT+12",
		zoneId: "Etc/GMT+12",
	},
	{
		value: 397,
		name: "(UTC-02:00) Etc/GMT+2",
		zoneId: "Etc/GMT+2",
	},
	{
		value: 398,
		name: "(UTC-03:00) Etc/GMT+3",
		zoneId: "Etc/GMT+3",
	},
	{
		value: 399,
		name: "(UTC-04:00) Etc/GMT+4",
		zoneId: "Etc/GMT+4",
	},
	{
		value: 400,
		name: "(UTC-05:00) Etc/GMT+5",
		zoneId: "Etc/GMT+5",
	},
	{
		value: 401,
		name: "(UTC-06:00) Etc/GMT+6",
		zoneId: "Etc/GMT+6",
	},
	{
		value: 402,
		name: "(UTC-07:00) Etc/GMT+7",
		zoneId: "Etc/GMT+7",
	},
	{
		value: 403,
		name: "(UTC-08:00) Etc/GMT+8",
		zoneId: "Etc/GMT+8",
	},
	{
		value: 404,
		name: "(UTC-09:00) Etc/GMT+9",
		zoneId: "Etc/GMT+9",
	},
	{
		value: 405,
		name: "(UTC+00:00) Etc/GMT-0",
		zoneId: "Etc/GMT-0",
	},
	{
		value: 406,
		name: "(UTC+01:00) Etc/GMT-1",
		zoneId: "Etc/GMT-1",
	},
	{
		value: 407,
		name: "(UTC+10:00) Etc/GMT-10",
		zoneId: "Etc/GMT-10",
	},
	{
		value: 408,
		name: "(UTC+11:00) Etc/GMT-11",
		zoneId: "Etc/GMT-11",
	},
	{
		value: 409,
		name: "(UTC+12:00) Etc/GMT-12",
		zoneId: "Etc/GMT-12",
	},
	{
		value: 410,
		name: "(UTC+13:00) Etc/GMT-13",
		zoneId: "Etc/GMT-13",
	},
	{
		value: 411,
		name: "(UTC+14:00) Etc/GMT-14",
		zoneId: "Etc/GMT-14",
	},
	{
		value: 412,
		name: "(UTC+02:00) Etc/GMT-2",
		zoneId: "Etc/GMT-2",
	},
	{
		value: 413,
		name: "(UTC+03:00) Etc/GMT-3",
		zoneId: "Etc/GMT-3",
	},
	{
		value: 414,
		name: "(UTC+04:00) Etc/GMT-4",
		zoneId: "Etc/GMT-4",
	},
	{
		value: 415,
		name: "(UTC+05:00) Etc/GMT-5",
		zoneId: "Etc/GMT-5",
	},
	{
		value: 416,
		name: "(UTC+06:00) Etc/GMT-6",
		zoneId: "Etc/GMT-6",
	},
	{
		value: 417,
		name: "(UTC+07:00) Etc/GMT-7",
		zoneId: "Etc/GMT-7",
	},
	{
		value: 418,
		name: "(UTC+08:00) Etc/GMT-8",
		zoneId: "Etc/GMT-8",
	},
	{
		value: 419,
		name: "(UTC+09:00) Etc/GMT-9",
		zoneId: "Etc/GMT-9",
	},
	{
		value: 420,
		name: "(UTC+00:00) Etc/GMT0",
		zoneId: "Etc/GMT0",
	},
	{
		value: 421,
		name: "(UTC+00:00) Etc/Greenwich",
		zoneId: "Etc/Greenwich",
	},
	{
		value: 422,
		name: "(UTC+00:00) Etc/UCT",
		zoneId: "Etc/UCT",
	},
	{
		value: 423,
		name: "(UTC+00:00) Etc/UTC",
		zoneId: "Etc/UTC",
	},
	{
		value: 424,
		name: "(UTC+00:00) Etc/Universal",
		zoneId: "Etc/Universal",
	},
	{
		value: 425,
		name: "(UTC+00:00) Etc/Zulu",
		zoneId: "Etc/Zulu",
	},
	{
		value: 426,
		name: "(UTC+01:00) Europe/Amsterdam",
		zoneId: "Europe/Amsterdam",
	},
	{
		value: 427,
		name: "(UTC+01:00) Europe/Andorra",
		zoneId: "Europe/Andorra",
	},
	{
		value: 428,
		name: "(UTC+04:00) Europe/Astrakhan",
		zoneId: "Europe/Astrakhan",
	},
	{
		value: 429,
		name: "(UTC+02:00) Europe/Athens",
		zoneId: "Europe/Athens",
	},
	{
		value: 430,
		name: "(UTC+00:00) Europe/Belfast",
		zoneId: "Europe/Belfast",
	},
	{
		value: 431,
		name: "(UTC+01:00) Europe/Belgrade",
		zoneId: "Europe/Belgrade",
	},
	{
		value: 432,
		name: "(UTC+01:00) Europe/Berlin",
		zoneId: "Europe/Berlin",
	},
	{
		value: 433,
		name: "(UTC+01:00) Europe/Bratislava",
		zoneId: "Europe/Bratislava",
	},
	{
		value: 434,
		name: "(UTC+01:00) Europe/Brussels",
		zoneId: "Europe/Brussels",
	},
	{
		value: 435,
		name: "(UTC+02:00) Europe/Bucharest",
		zoneId: "Europe/Bucharest",
	},
	{
		value: 436,
		name: "(UTC+01:00) Europe/Budapest",
		zoneId: "Europe/Budapest",
	},
	{
		value: 437,
		name: "(UTC+01:00) Europe/Busingen",
		zoneId: "Europe/Busingen",
	},
	{
		value: 438,
		name: "(UTC+02:00) Europe/Chisinau",
		zoneId: "Europe/Chisinau",
	},
	{
		value: 439,
		name: "(UTC+01:00) Europe/Copenhagen",
		zoneId: "Europe/Copenhagen",
	},
	{
		value: 440,
		name: "(UTC+00:00) Europe/Dublin",
		zoneId: "Europe/Dublin",
	},
	{
		value: 441,
		name: "(UTC+01:00) Europe/Gibraltar",
		zoneId: "Europe/Gibraltar",
	},
	{
		value: 442,
		name: "(UTC+00:00) Europe/Guernsey",
		zoneId: "Europe/Guernsey",
	},
	{
		value: 443,
		name: "(UTC+02:00) Europe/Helsinki",
		zoneId: "Europe/Helsinki",
	},
	{
		value: 444,
		name: "(UTC+00:00) Europe/Isle_of_Man",
		zoneId: "Europe/Isle_of_Man",
	},
	{
		value: 445,
		name: "(UTC+03:00) Europe/Istanbul",
		zoneId: "Europe/Istanbul",
	},
	{
		value: 446,
		name: "(UTC+00:00) Europe/Jersey",
		zoneId: "Europe/Jersey",
	},
	{
		value: 447,
		name: "(UTC+02:00) Europe/Kaliningrad",
		zoneId: "Europe/Kaliningrad",
	},
	{
		value: 448,
		name: "(UTC+02:00) Europe/Kiev",
		zoneId: "Europe/Kiev",
	},
	{
		value: 449,
		name: "(UTC+03:00) Europe/Kirov",
		zoneId: "Europe/Kirov",
	},
	{
		value: 450,
		name: "(UTC+02:00) Europe/Kyiv",
		zoneId: "Europe/Kyiv",
	},
	{
		value: 451,
		name: "(UTC+00:00) Europe/Lisbon",
		zoneId: "Europe/Lisbon",
	},
	{
		value: 452,
		name: "(UTC+01:00) Europe/Ljubljana",
		zoneId: "Europe/Ljubljana",
	},
	{
		value: 453,
		name: "(UTC+00:00) Europe/London",
		zoneId: "Europe/London",
	},
	{
		value: 454,
		name: "(UTC+01:00) Europe/Luxembourg",
		zoneId: "Europe/Luxembourg",
	},
	{
		value: 455,
		name: "(UTC+01:00) Europe/Madrid",
		zoneId: "Europe/Madrid",
	},
	{
		value: 456,
		name: "(UTC+01:00) Europe/Malta",
		zoneId: "Europe/Malta",
	},
	{
		value: 457,
		name: "(UTC+02:00) Europe/Mariehamn",
		zoneId: "Europe/Mariehamn",
	},
	{
		value: 458,
		name: "(UTC+03:00) Europe/Minsk",
		zoneId: "Europe/Minsk",
	},
	{
		value: 459,
		name: "(UTC+01:00) Europe/Monaco",
		zoneId: "Europe/Monaco",
	},
	{
		value: 460,
		name: "(UTC+03:00) Europe/Moscow",
		zoneId: "Europe/Moscow",
	},
	{
		value: 461,
		name: "(UTC+02:00) Europe/Nicosia",
		zoneId: "Europe/Nicosia",
	},
	{
		value: 462,
		name: "(UTC+01:00) Europe/Oslo",
		zoneId: "Europe/Oslo",
	},
	{
		value: 463,
		name: "(UTC+01:00) Europe/Paris",
		zoneId: "Europe/Paris",
	},
	{
		value: 464,
		name: "(UTC+01:00) Europe/Podgorica",
		zoneId: "Europe/Podgorica",
	},
	{
		value: 465,
		name: "(UTC+01:00) Europe/Prague",
		zoneId: "Europe/Prague",
	},
	{
		value: 466,
		name: "(UTC+02:00) Europe/Riga",
		zoneId: "Europe/Riga",
	},
	{
		value: 467,
		name: "(UTC+01:00) Europe/Rome",
		zoneId: "Europe/Rome",
	},
	{
		value: 468,
		name: "(UTC+04:00) Europe/Samara",
		zoneId: "Europe/Samara",
	},
	{
		value: 469,
		name: "(UTC+01:00) Europe/San_Marino",
		zoneId: "Europe/San_Marino",
	},
	{
		value: 470,
		name: "(UTC+01:00) Europe/Sarajevo",
		zoneId: "Europe/Sarajevo",
	},
	{
		value: 471,
		name: "(UTC+04:00) Europe/Saratov",
		zoneId: "Europe/Saratov",
	},
	{
		value: 472,
		name: "(UTC+03:00) Europe/Simferopol",
		zoneId: "Europe/Simferopol",
	},
	{
		value: 473,
		name: "(UTC+01:00) Europe/Skopje",
		zoneId: "Europe/Skopje",
	},
	{
		value: 474,
		name: "(UTC+02:00) Europe/Sofia",
		zoneId: "Europe/Sofia",
	},
	{
		value: 475,
		name: "(UTC+01:00) Europe/Stockholm",
		zoneId: "Europe/Stockholm",
	},
	{
		value: 476,
		name: "(UTC+02:00) Europe/Tallinn",
		zoneId: "Europe/Tallinn",
	},
	{
		value: 477,
		name: "(UTC+01:00) Europe/Tirane",
		zoneId: "Europe/Tirane",
	},
	{
		value: 478,
		name: "(UTC+02:00) Europe/Tiraspol",
		zoneId: "Europe/Tiraspol",
	},
	{
		value: 479,
		name: "(UTC+04:00) Europe/Ulyanovsk",
		zoneId: "Europe/Ulyanovsk",
	},
	{
		value: 480,
		name: "(UTC+02:00) Europe/Uzhgorod",
		zoneId: "Europe/Uzhgorod",
	},
	{
		value: 481,
		name: "(UTC+01:00) Europe/Vaduz",
		zoneId: "Europe/Vaduz",
	},
	{
		value: 482,
		name: "(UTC+01:00) Europe/Vatican",
		zoneId: "Europe/Vatican",
	},
	{
		value: 483,
		name: "(UTC+01:00) Europe/Vienna",
		zoneId: "Europe/Vienna",
	},
	{
		value: 484,
		name: "(UTC+02:00) Europe/Vilnius",
		zoneId: "Europe/Vilnius",
	},
	{
		value: 485,
		name: "(UTC+03:00) Europe/Volgograd",
		zoneId: "Europe/Volgograd",
	},
	{
		value: 486,
		name: "(UTC+01:00) Europe/Warsaw",
		zoneId: "Europe/Warsaw",
	},
	{
		value: 487,
		name: "(UTC+01:00) Europe/Zagreb",
		zoneId: "Europe/Zagreb",
	},
	{
		value: 488,
		name: "(UTC+02:00) Europe/Zaporozhye",
		zoneId: "Europe/Zaporozhye",
	},
	{
		value: 489,
		name: "(UTC+01:00) Europe/Zurich",
		zoneId: "Europe/Zurich",
	},
	{
		value: 490,
		name: "(UTC+00:00) GB",
		zoneId: "GB",
	},
	{
		value: 491,
		name: "(UTC+00:00) GB-Eire",
		zoneId: "GB-Eire",
	},
	{
		value: 492,
		name: "(UTC+00:00) GMT",
		zoneId: "GMT",
	},
	{
		value: 493,
		name: "(UTC+00:00) GMT0",
		zoneId: "GMT0",
	},
	{
		value: 494,
		name: "(UTC+00:00) Greenwich",
		zoneId: "Greenwich",
	},
	{
		value: 495,
		name: "(UTC+08:00) Hongkong",
		zoneId: "Hongkong",
	},
	{
		value: 496,
		name: "(UTC+00:00) Iceland",
		zoneId: "Iceland",
	},
	{
		value: 497,
		name: "(UTC+03:00) Indian/Antananarivo",
		zoneId: "Indian/Antananarivo",
	},
	{
		value: 498,
		name: "(UTC+06:00) Indian/Chagos",
		zoneId: "Indian/Chagos",
	},
	{
		value: 499,
		name: "(UTC+07:00) Indian/Christmas",
		zoneId: "Indian/Christmas",
	},
	{
		value: 500,
		name: "(UTC+06:30) Indian/Cocos",
		zoneId: "Indian/Cocos",
	},
	{
		value: 501,
		name: "(UTC+03:00) Indian/Comoro",
		zoneId: "Indian/Comoro",
	},
	{
		value: 502,
		name: "(UTC+05:00) Indian/Kerguelen",
		zoneId: "Indian/Kerguelen",
	},
	{
		value: 503,
		name: "(UTC+04:00) Indian/Mahe",
		zoneId: "Indian/Mahe",
	},
	{
		value: 504,
		name: "(UTC+05:00) Indian/Maldives",
		zoneId: "Indian/Maldives",
	},
	{
		value: 505,
		name: "(UTC+04:00) Indian/Mauritius",
		zoneId: "Indian/Mauritius",
	},
	{
		value: 506,
		name: "(UTC+03:00) Indian/Mayotte",
		zoneId: "Indian/Mayotte",
	},
	{
		value: 507,
		name: "(UTC+04:00) Indian/Reunion",
		zoneId: "Indian/Reunion",
	},
	{
		value: 508,
		name: "(UTC+03:30) Iran",
		zoneId: "Iran",
	},
	{
		value: 509,
		name: "(UTC+02:00) Israel",
		zoneId: "Israel",
	},
	{
		value: 510,
		name: "(UTC-05:00) Jamaica",
		zoneId: "Jamaica",
	},
	{
		value: 511,
		name: "(UTC+09:00) Japan",
		zoneId: "Japan",
	},
	{
		value: 512,
		name: "(UTC+12:00) Kwajalein",
		zoneId: "Kwajalein",
	},
	{
		value: 513,
		name: "(UTC+02:00) Libya",
		zoneId: "Libya",
	},
	{
		value: 514,
		name: "(UTC+01:00) MET",
		zoneId: "MET",
	},
	{
		value: 515,
		name: "(UTC-07:00) MST7MDT",
		zoneId: "MST7MDT",
	},
	{
		value: 516,
		name: "(UTC-08:00) Mexico/BajaNorte",
		zoneId: "Mexico/BajaNorte",
	},
	{
		value: 517,
		name: "(UTC-07:00) Mexico/BajaSur",
		zoneId: "Mexico/BajaSur",
	},
	{
		value: 518,
		name: "(UTC-06:00) Mexico/General",
		zoneId: "Mexico/General",
	},
	{
		value: 519,
		name: "(UTC+12:00) NZ",
		zoneId: "NZ",
	},
	{
		value: 520,
		name: "(UTC+12:45) NZ-CHAT",
		zoneId: "NZ-CHAT",
	},
	{
		value: 521,
		name: "(UTC-07:00) Navajo",
		zoneId: "Navajo",
	},
	{
		value: 522,
		name: "(UTC+08:00) PRC",
		zoneId: "PRC",
	},
	{
		value: 523,
		name: "(UTC-08:00) PST8PDT",
		zoneId: "PST8PDT",
	},
	{
		value: 524,
		name: "(UTC+13:00) Pacific/Apia",
		zoneId: "Pacific/Apia",
	},
	{
		value: 525,
		name: "(UTC+12:00) Pacific/Auckland",
		zoneId: "Pacific/Auckland",
	},
	{
		value: 526,
		name: "(UTC+11:00) Pacific/Bougainville",
		zoneId: "Pacific/Bougainville",
	},
	{
		value: 527,
		name: "(UTC+12:45) Pacific/Chatham",
		zoneId: "Pacific/Chatham",
	},
	{
		value: 528,
		name: "(UTC+10:00) Pacific/Chuuk",
		zoneId: "Pacific/Chuuk",
	},
	{
		value: 529,
		name: "(UTC-06:00) Pacific/Easter",
		zoneId: "Pacific/Easter",
	},
	{
		value: 530,
		name: "(UTC+11:00) Pacific/Efate",
		zoneId: "Pacific/Efate",
	},
	{
		value: 531,
		name: "(UTC+13:00) Pacific/Enderbury",
		zoneId: "Pacific/Enderbury",
	},
	{
		value: 532,
		name: "(UTC+13:00) Pacific/Fakaofo",
		zoneId: "Pacific/Fakaofo",
	},
	{
		value: 533,
		name: "(UTC+12:00) Pacific/Fiji",
		zoneId: "Pacific/Fiji",
	},
	{
		value: 534,
		name: "(UTC+12:00) Pacific/Funafuti",
		zoneId: "Pacific/Funafuti",
	},
	{
		value: 535,
		name: "(UTC-06:00) Pacific/Galapagos",
		zoneId: "Pacific/Galapagos",
	},
	{
		value: 536,
		name: "(UTC-09:00) Pacific/Gambier",
		zoneId: "Pacific/Gambier",
	},
	{
		value: 537,
		name: "(UTC+11:00) Pacific/Guadalcanal",
		zoneId: "Pacific/Guadalcanal",
	},
	{
		value: 538,
		name: "(UTC+10:00) Pacific/Guam",
		zoneId: "Pacific/Guam",
	},
	{
		value: 539,
		name: "(UTC-10:00) Pacific/Honolulu",
		zoneId: "Pacific/Honolulu",
	},
	{
		value: 540,
		name: "(UTC-10:00) Pacific/Johnston",
		zoneId: "Pacific/Johnston",
	},
	{
		value: 541,
		name: "(UTC+13:00) Pacific/Kanton",
		zoneId: "Pacific/Kanton",
	},
	{
		value: 542,
		name: "(UTC+14:00) Pacific/Kiritimati",
		zoneId: "Pacific/Kiritimati",
	},
	{
		value: 543,
		name: "(UTC+11:00) Pacific/Kosrae",
		zoneId: "Pacific/Kosrae",
	},
	{
		value: 544,
		name: "(UTC+12:00) Pacific/Kwajalein",
		zoneId: "Pacific/Kwajalein",
	},
	{
		value: 545,
		name: "(UTC+12:00) Pacific/Majuro",
		zoneId: "Pacific/Majuro",
	},
	{
		value: 546,
		name: "(UTC-9:30) Pacific/Marquesas",
		zoneId: "Pacific/Marquesas",
	},
	{
		value: 547,
		name: "(UTC-11:00) Pacific/Midway",
		zoneId: "Pacific/Midway",
	},
	{
		value: 548,
		name: "(UTC+12:00) Pacific/Nauru",
		zoneId: "Pacific/Nauru",
	},
	{
		value: 549,
		name: "(UTC-11:00) Pacific/Niue",
		zoneId: "Pacific/Niue",
	},
	{
		value: 550,
		name: "(UTC+11:00) Pacific/Norfolk",
		zoneId: "Pacific/Norfolk",
	},
	{
		value: 551,
		name: "(UTC+11:00) Pacific/Noumea",
		zoneId: "Pacific/Noumea",
	},
	{
		value: 552,
		name: "(UTC-11:00) Pacific/Pago_Pago",
		zoneId: "Pacific/Pago_Pago",
	},
	{
		value: 553,
		name: "(UTC+09:00) Pacific/Palau",
		zoneId: "Pacific/Palau",
	},
	{
		value: 554,
		name: "(UTC-08:00) Pacific/Pitcairn",
		zoneId: "Pacific/Pitcairn",
	},
	{
		value: 555,
		name: "(UTC+11:00) Pacific/Pohnpei",
		zoneId: "Pacific/Pohnpei",
	},
	{
		value: 556,
		name: "(UTC+11:00) Pacific/Ponape",
		zoneId: "Pacific/Ponape",
	},
	{
		value: 557,
		name: "(UTC+10:00) Pacific/Port_Moresby",
		zoneId: "Pacific/Port_Moresby",
	},
	{
		value: 558,
		name: "(UTC-10:00) Pacific/Rarotonga",
		zoneId: "Pacific/Rarotonga",
	},
	{
		value: 559,
		name: "(UTC+10:00) Pacific/Saipan",
		zoneId: "Pacific/Saipan",
	},
	{
		value: 560,
		name: "(UTC-11:00) Pacific/Samoa",
		zoneId: "Pacific/Samoa",
	},
	{
		value: 561,
		name: "(UTC-10:00) Pacific/Tahiti",
		zoneId: "Pacific/Tahiti",
	},
	{
		value: 562,
		name: "(UTC+12:00) Pacific/Tarawa",
		zoneId: "Pacific/Tarawa",
	},
	{
		value: 563,
		name: "(UTC+13:00) Pacific/Tongatapu",
		zoneId: "Pacific/Tongatapu",
	},
	{
		value: 564,
		name: "(UTC+10:00) Pacific/Truk",
		zoneId: "Pacific/Truk",
	},
	{
		value: 565,
		name: "(UTC+12:00) Pacific/Wake",
		zoneId: "Pacific/Wake",
	},
	{
		value: 566,
		name: "(UTC+12:00) Pacific/Wallis",
		zoneId: "Pacific/Wallis",
	},
	{
		value: 567,
		name: "(UTC+10:00) Pacific/Yap",
		zoneId: "Pacific/Yap",
	},
	{
		value: 568,
		name: "(UTC+01:00) Poland",
		zoneId: "Poland",
	},
	{
		value: 569,
		name: "(UTC+00:00) Portugal",
		zoneId: "Portugal",
	},
	{
		value: 570,
		name: "(UTC+09:00) ROK",
		zoneId: "ROK",
	},
	{
		value: 571,
		name: "(UTC+08:00) Singapore",
		zoneId: "Singapore",
	},
	{
		value: 572,
		name: "(UTC-04:00) SystemV/AST4",
		zoneId: "SystemV/AST4",
	},
	{
		value: 573,
		name: "(UTC-04:00) SystemV/AST4ADT",
		zoneId: "SystemV/AST4ADT",
	},
	{
		value: 574,
		name: "(UTC-06:00) SystemV/CST6",
		zoneId: "SystemV/CST6",
	},
	{
		value: 575,
		name: "(UTC-06:00) SystemV/CST6CDT",
		zoneId: "SystemV/CST6CDT",
	},
	{
		value: 576,
		name: "(UTC-05:00) SystemV/EST5",
		zoneId: "SystemV/EST5",
	},
	{
		value: 577,
		name: "(UTC-05:00) SystemV/EST5EDT",
		zoneId: "SystemV/EST5EDT",
	},
	{
		value: 578,
		name: "(UTC-10:00) SystemV/HST10",
		zoneId: "SystemV/HST10",
	},
	{
		value: 579,
		name: "(UTC-07:00) SystemV/MST7",
		zoneId: "SystemV/MST7",
	},
	{
		value: 580,
		name: "(UTC-07:00) SystemV/MST7MDT",
		zoneId: "SystemV/MST7MDT",
	},
	{
		value: 581,
		name: "(UTC-08:00) SystemV/PST8",
		zoneId: "SystemV/PST8",
	},
	{
		value: 582,
		name: "(UTC-08:00) SystemV/PST8PDT",
		zoneId: "SystemV/PST8PDT",
	},
	{
		value: 583,
		name: "(UTC-09:00) SystemV/YST9",
		zoneId: "SystemV/YST9",
	},
	{
		value: 584,
		name: "(UTC-09:00) SystemV/YST9YDT",
		zoneId: "SystemV/YST9YDT",
	},
	{
		value: 585,
		name: "(UTC+03:00) Turkey",
		zoneId: "Turkey",
	},
	{
		value: 586,
		name: "(UTC+00:00) UCT",
		zoneId: "UCT",
	},
	{
		value: 587,
		name: "(UTC-09:00) US/Alaska",
		zoneId: "US/Alaska",
	},
	{
		value: 588,
		name: "(UTC-10:00) US/Aleutian",
		zoneId: "US/Aleutian",
	},
	{
		value: 589,
		name: "(UTC-07:00) US/Arizona",
		zoneId: "US/Arizona",
	},
	{
		value: 590,
		name: "(UTC-06:00) US/Central",
		zoneId: "US/Central",
	},
	{
		value: 591,
		name: "(UTC-05:00) US/East-Indiana",
		zoneId: "US/East-Indiana",
	},
	{
		value: 592,
		name: "(UTC-05:00) US/Eastern",
		zoneId: "US/Eastern",
	},
	{
		value: 593,
		name: "(UTC-10:00) US/Hawaii",
		zoneId: "US/Hawaii",
	},
	{
		value: 594,
		name: "(UTC-06:00) US/Indiana-Starke",
		zoneId: "US/Indiana-Starke",
	},
	{
		value: 595,
		name: "(UTC-05:00) US/Michigan",
		zoneId: "US/Michigan",
	},
	{
		value: 596,
		name: "(UTC-07:00) US/Mountain",
		zoneId: "US/Mountain",
	},
	{
		value: 597,
		name: "(UTC-08:00) US/Pacific",
		zoneId: "US/Pacific",
	},
	{
		value: 598,
		name: "(UTC-11:00) US/Samoa",
		zoneId: "US/Samoa",
	},
	{
		value: 599,
		name: "(UTC+00:00) UTC",
		zoneId: "Etc/UTC", // UTC -> Coordinated Universal Time
	},
	{
		value: 600,
		name: "(UTC+00:00) Universal",
		zoneId: "Etc/UTC", // Universal -> Same as UTC
	},
	{
		value: 601,
		name: "(UTC+03:00) W-SU",
		zoneId: "Europe/Moscow", // W-SU -> Moscow Standard Time
	},
	{
		value: 602,
		name: "(UTC+00:00) WET",
		zoneId: "Europe/Lisbon", // WET -> Western European Time
	},
	{
		value: 603,
		name: "(UTC+00:00) Zulu",
		zoneId: "Etc/UTC", // Zulu -> Coordinated Universal Time (UTC)
	},
	{
		value: 604,
		name: "(UTC-05:00) EST",
		zoneId: "America/New_York", // EST -> Eastern Standard Time (US)
	},
	{
		value: 605,
		name: "(UTC-10:00) HST",
		zoneId: "Pacific/Honolulu", // HST -> Hawaii Standard Time
	},
	{
		value: 606,
		name: "(UTC-07:00) MST",
		zoneId: "America/Denver", // MST -> Mountain Standard Time (US)
	},
	{
		value: 607,
		name: "(UTC+09:30) ACT",
		zoneId: "Australia/Darwin", // ACT -> Australian Central Time
	},
	{
		value: 608,
		name: "(UTC+10:00) AET",
		zoneId: "Australia/Sydney", // AET -> Australian Eastern Time
	},
	{
		value: 609,
		name: "(UTC-03:00) AGT",
		zoneId: "America/Argentina/Buenos_Aires", // AGT -> Argentina Standard Time
	},
	{
		value: 610,
		name: "(UTC+02:00) ART",
		zoneId: "Africa/Cairo", // ART -> Argentina or Arabic Standard Time (Cairo)
	},
	{
		value: 611,
		name: "(UTC-09:00) AST",
		zoneId: "America/Anchorage", // AST -> Alaska Standard Time
	},
	{
		value: 612,
		name: "(UTC-03:00) BET",
		zoneId: "America/Sao_Paulo", // BET -> Brazil Eastern Time
	},
	{
		value: 613,
		name: "(UTC+06:00) BST",
		zoneId: "Asia/Dhaka", // BST -> Bangladesh Standard Time
	},
	{
		value: 614,
		name: "(UTC+02:00) CAT",
		zoneId: "Africa/Harare", // CAT -> Central Africa Time
	},
	{
		value: 615,
		name: "(UTC-03:30) CNT",
		zoneId: "America/St_Johns", // CNT -> Newfoundland Time
	},
	{
		value: 616,
		name: "(UTC-06:00) CST",
		zoneId: "America/Chicago", // CST -> Central Standard Time (US)
	},
	{
		value: 617,
		name: "(UTC+08:00) CTT",
		zoneId: "Asia/Shanghai", // CTT -> China Standard Time
	},
	{
		value: 618,
		name: "(UTC+03:00) EAT",
		zoneId: "Africa/Nairobi", // EAT -> East Africa Time
	},
	{
		value: 619,
		name: "(UTC+01:00) ECT",
		zoneId: "Europe/Paris", // ECT -> Central European Time
	},
	{
		value: 620,
		name: "(UTC-05:00) IET",
		zoneId: "America/New_York", // IET -> Eastern Standard Time (US)
	},
	{
		value: 621,
		name: "(UTC+05:30) IST",
		zoneId: "Asia/Kolkata", // IST -> Indian Standard Time
	},
	{
		value: 622,
		name: "(UTC+09:00) JST",
		zoneId: "Asia/Tokyo", // JST -> Japan Standard Time
	},
	{
		value: 623,
		name: "(UTC+13:00) MIT",
		zoneId: "Pacific/Apia", // MIT -> Pacific/Apia
	},
	{
		value: 624,
		name: "(UTC+04:00) NET",
		zoneId: "Asia/Yerevan", // NET -> Asia/Yerevan
	},
	{
		value: 625,
		name: "(UTC+12:00) NST",
		zoneId: "Pacific/Auckland", // NST -> Pacific/Auckland (New Zealand Standard Time)
	},
	{
		value: 626,
		name: "(UTC+05:00) PLT",
		zoneId: "Asia/Karachi", // PLT -> Asia/Karachi (Pakistan Time)
	},
	{
		value: 627,
		name: "(UTC-07:00) PNT",
		zoneId: "America/Phoenix", // PNT -> America/Phoenix (Arizona)
	},
	{
		value: 628,
		name: "(UTC-04:00) PRT",
		zoneId: "America/Puerto_Rico", // PRT -> America/Puerto_Rico
	},
	{
		value: 629,
		name: "(UTC-08:00) PST",
		zoneId: "America/Los_Angeles", // PST -> "America/Los_Angeles"
	},
	{
		value: 630,
		name: "(UTC+11:00) SST",
		zoneId: "Pacific/Pago_Pago", // SST -> Samoa Standard Time
	},
	{
		value: 631,
		name: "(UTC+07:00) VST",
		zoneId: "Asia/Ho_Chi_Minh", // VST -> Vietnam Standard Time
	},
];

export default accountTimezones;
