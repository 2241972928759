import React from "react";
import { Stack, Typography } from "@mui/material";
import { Tooltip } from "../../../../styles/twozo";

const styles = {
	highlightedText: {
		fontSize: "15px",
		fontWeight: 500,
	},
	textSecondary: {
		fontSize: "14px",
		color: "rgba(0, 0, 0, 0.6)",
	},
};

export default function FollowersRemoved(props) {
	const { eventData } = props;

	if (!eventData) return;

	const maxFollowersToShow = 1;
	const maxFollowersForTooltip = 2;

	const hasMoreThanMaxFollowersToShow = (followers) => {
		return (
			Array.isArray(followers) && followers.length > maxFollowersToShow
		);
	};

	const getSupplementaryFollowerNames = (followers) => {
		const supplementaryFollowers = followers?.slice(maxFollowersToShow);

		const followersForTooltip = supplementaryFollowers
			.slice(0, maxFollowersForTooltip)
			.join(", "); // Index 0 (inclusive) to Index 2 (exclusive)

		return supplementaryFollowers?.length > maxFollowersForTooltip
			? `${followersForTooltip}, +${
					supplementaryFollowers.length - maxFollowersForTooltip
				} others`
			: followersForTooltip;
	};

	return (
		<React.Fragment>
			<Stack>
				<Typography style={styles.text}>
					<span style={styles.highlightedText}>
						{eventData?.newValue?.[0]}
					</span>{" "}
					{hasMoreThanMaxFollowersToShow(eventData?.newValue) ? (
						<span style={styles.highlightedText}>
							<Tooltip
								title={getSupplementaryFollowerNames(
									eventData?.newValue
								)}
								placement="top"
							>
								<span style={styles.highlightedText}>
									+
									{eventData?.newValue?.length -
										maxFollowersToShow}{" "}
								</span>
							</Tooltip>
						</span>
					) : null}
					{`${
						hasMoreThanMaxFollowersToShow(eventData?.newValue)
							? "other users have been removed from the followers of this"
							: "has been removed from the followers of this"
					} ${eventData?.category}`}
				</Typography>

				<Typography style={styles.textSecondary}>
					{eventData?.salesOwner?.name}
				</Typography>
			</Stack>
		</React.Fragment>
	);
}
