import React, { useMemo, useState } from "react";
import SearchBar from "../Elements/SearchBar";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	InputAdornment,
	MenuItem,
	MenuList,
	Stack,
	ToggleButton,
	Typography,
	useTheme,
} from "@mui/material";
import Result from "./SearchResults";
import { default as SearchIcon } from "../../assets/icons/globalSearch";
import { default as AddIcon } from "../../assets/icons/add";
import { default as CloseIcon } from "../../assets/icons/close";
import ToggleButtonGroup from "../Elements/ToggleButtonGroup";
import { useNavigate } from "react-router-dom";
import CustomSwipeableDrawer from "../Elements/CustomSwipeableDrawer";
import AddContact from "../Contacts/AddContact";
import { twozoStyles } from "../../styles/twozo";
import AddNewDeals from "../Deals/AddNewDeals";
import AddCompany from "../Companies/AddCompany";
import { useDebouncedCallback } from "use-debounce";
import {
	useCommonSearchData,
	useSearchedAndViewedData,
} from "../../hooks/services/search";
import { searchFilterConstraints } from "../../utils/searchUtils";
import { modules } from "../../utils/common/ModulesName";
import { useSearchContext } from "./SearchContext";
import Can from "../Auth/Can";
import { PERMISSIONS } from "../../utils/Auth";
import { useAuth } from "../../hooks/auth";
import { useLastRowRef } from "../../hooks/common/InfiniteScroll";
import { useModuleList } from "../../hooks/services/common";

export default function Search() {
	const theme = useTheme();
	const navigate = useNavigate();
	const classes = twozoStyles();

	//search reducer
	const { searchState, openSearchBar, closeSearchBar } = useSearchContext();

	const [searchKeyword, setSearchKeyword] = useState("");
	const [filter, setFilter] = useState(searchFilterConstraints.all);
	const [searchValue, setSearchValue] = useState("");
	const isSearchEnabled = searchKeyword.length >= 2;
	const trimmedSearchKeyWord = searchKeyword.trim();

	// query call
	const {
		isLoading: isSearchResultsLoading,
		data: searchResultsData,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useCommonSearchData(filter, searchKeyword, isSearchEnabled);

	const { data: moduleList } = useModuleList();

	const {
		data: searchedAndViewedData,
		isLoading: isLoadingSearchedAndViewedData,
	} = useSearchedAndViewedData();
	const { isUserAllowedFor } = useAuth();
	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const [field, setField] = useState({});
	const fieldData = {
		[field?.associatedField]: trimmedSearchKeyWord,
	};

	const [isOpenAddContactDialog, setIsOpenAddContactDialog] = useState(false);
	const [isOpenAddCompanyDialog, setIsOpenAddCompanyDialog] = useState(false);
	const [isOpenAddDealDialog, setIsOpenAddDealDialog] = useState(false);

	const handleSearchFilter = (_, filter) => {
		if (filter) {
			setFilter(filter);
		}
	};

	const handleSearchDebounce = useDebouncedCallback(
		(value) => {
			setSearchKeyword(value);
		},
		250 // delay in ms
	);

	const updateSearchInput = (value) => {
		setSearchValue(value);
		handleSearchDebounce(value);
	};

	const handleOpenAddContactDialog = () => {
		setIsOpenAddContactDialog(
			(isOpenAddContactDialog) => !isOpenAddContactDialog
		);
	};

	const handleOpenAddCompanyDialog = () => {
		setIsOpenAddCompanyDialog(
			(isOpenAddCompanyDialog) => !isOpenAddCompanyDialog
		);
	};

	const handleOpenAddDealDialog = () => {
		setIsOpenAddDealDialog((isOpenAddDealDialog) => !isOpenAddDealDialog);
	};

	const onAddContactClicked = () => {
		handleOpenAddContactDialog();
		setAssociatedField(modules.CONTACT);
		closeSearchBar();
		navigate("/contacts");
	};

	const onAddDealClicked = () => {
		handleOpenAddDealDialog();
		setAssociatedField(modules.DEAL);
		closeSearchBar();
		navigate("/deals");
	};

	const onAddCompanyClicked = () => {
		handleOpenAddCompanyDialog();
		setAssociatedField(modules.COMPANY);
		closeSearchBar();
		navigate("/companies");
	};

	const setAssociatedField = (source) => {
		let field = searchResultsData?.[0]?.associatedFields?.find(
			(field) => field.source === source
		);
		setField(field);
	};

	const isDisplaySearchedResults = () => {
		return (
			isSearchEnabled ||
			(Array.isArray(searchResultsData) && searchResultsData.length > 0)
		);
	};

	const isDisplaySearchedTextAndViewedData = () => {
		if (isSearchEnabled) return false;

		const hasRecentlySearched =
			Array.isArray(searchedAndViewedData?.recentlySearched) &&
			searchedAndViewedData.recentlySearched.length > 0;
		const hasRecentlyViewed =
			Array.isArray(searchedAndViewedData?.recentlyViewed) &&
			searchedAndViewedData.recentlyViewed.length > 0;

		return hasRecentlySearched || hasRecentlyViewed;
	};

	const isProductModuleEnabled = useMemo(() => {
		if (Array.isArray(moduleList)) {
			let module = moduleList.find(
				(module) => module.sourceName === modules.PRODUCT
			);
			if (module) {
				return (
					module?.isEnabled &&
					isUserAllowedFor(PERMISSIONS.product.view)
				);
			}
		}
	}, [moduleList, isUserAllowedFor]);

	const loader = (height = 90, size = "30px") => {
		return (
			<Stack
				height={`${height}vh`}
				justifyContent="center"
				alignItems="center"
			>
				<CircularProgress size={size} />
			</Stack>
		);
	};

	const isHiddenAddModuleButton = () => {
		const hiddenFilters = ["activity", "product", "note", "file"];

		return hiddenFilters.includes(filter);
	};

	const canShowAddButton = (moduleName) => {
		return moduleName === filter || searchFilterConstraints.all === filter;
	};

	const clearSearchResultsAndKeyword = () => {
		closeSearchBar();
		removeSearchedKeyword();
	};

	const removeSearchedKeyword = () => {
		updateSearchInput("");
		setFilter(searchFilterConstraints.all);
	};

	const onSearchedOptionClicked = () => {
		clearSearchResultsAndKeyword();
	};

	const handleBackDropClick = () => {
		clearSearchResultsAndKeyword();
	};

	const searchMenuOptions = [
		{
			name: "All",
			permission: true,
			value: searchFilterConstraints.all,
		},
		{
			name: "Deals",
			value: searchFilterConstraints.deal,
			permission: true,
		},
		{
			name: "Contacts",
			value: searchFilterConstraints.contact,
			permission: isUserAllowedFor(PERMISSIONS.contact.view),
		},
		{
			name: "Organizations",
			value: searchFilterConstraints.company,
			permission: isUserAllowedFor(PERMISSIONS.company.view),
		},
		{
			name: "Activities",
			value: searchFilterConstraints.activity,
			permission: true,
		},
		{
			name: "Products",
			value: searchFilterConstraints.product,
			permission: isProductModuleEnabled,
		},
		{
			name: "Files",
			value: searchFilterConstraints.file,
			permission: true,
		},
		{
			name: "Notes",
			value: searchFilterConstraints.note,
			permission: true,
		},
	];

	const getSearchMenuOptions = () => {
		let filteredSearchOptions = searchMenuOptions.filter(
			(option) => option.permission
		);
		return filteredSearchOptions;
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isOpenAddContactDialog}
				onOpen={handleOpenAddContactDialog}
				onClose={handleOpenAddContactDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddContact
						formData={fieldData}
						onClose={handleOpenAddContactDialog}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isOpenAddDealDialog}
				onOpen={handleOpenAddDealDialog}
				onClose={handleOpenAddDealDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddNewDeals
						onClose={handleOpenAddDealDialog}
						formData={fieldData}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isOpenAddCompanyDialog}
				onOpen={handleOpenAddCompanyDialog}
				onClose={handleOpenAddCompanyDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddCompany
						formData={fieldData}
						onClose={handleOpenAddCompanyDialog}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Box
				style={{
					width: searchState.isSearchbarOpen ? "900px" : "500px",
					transition: "width 0.2s",
					position: "relative",
				}}
			>
				<Backdrop
					sx={{ zIndex: theme.zIndex.drawer + 1 }}
					open={searchState.isSearchbarOpen}
					transitionDuration={200}
					onClick={handleBackDropClick}
				></Backdrop>
				<SearchBar
					borderRadius={
						searchState.isSearchbarOpen ? "6px 6px 0px 0px" : "6px"
					}
					onFocus={() => openSearchBar()}
					onChange={(event) => updateSearchInput(event.target.value)}
					value={searchValue}
					InputProps={{
						startAdornment: (
							<InputAdornment
								position="start"
								style={{
									paddingLeft: "8px",
									paddingRight: "8px",
								}}
							>
								{SearchIcon(18, 18, "rgba(0, 0, 0, 0.6)")}
							</InputAdornment>
						),
						endAdornment: searchKeyword ? (
							<InputAdornment
								position="end"
								style={{
									cursor: "pointer",
								}}
								onClick={removeSearchedKeyword}
							>
								{CloseIcon(24, 24, "rgba(0, 0, 0, 0.6)")}
							</InputAdornment>
						) : null,
					}}
				/>

				{searchState.isSearchbarOpen ? (
					<Box
						style={{
							position: "absolute",
							backgroundColor: "#fff",
							maxHeight: "90vh",
							width: "900px",
							zIndex: theme.zIndex.drawer + 2,
							borderTop: "1px solid #d5d7d7",
							borderRadius: "0px 0px 6px 6px",
							overflow: "auto",
						}}
					>
						{isDisplaySearchedResults() ? (
							<Box>
								{isSearchResultsLoading && isSearchEnabled ? (
									loader(30, "30px")
								) : (
									<Box>
										<Box
											sx={{
												textAlign: "center",
												py: 1,
												position: "sticky",
												top: 0,
												zIndex: 999,
												backgroundColor: "#fff",
											}}
										>
											<ToggleButtonGroup
												value={filter}
												onChange={handleSearchFilter}
											>
												{getSearchMenuOptions()?.map(
													(option, index) => (
														<ToggleButton
															key={index}
															value={option.value}
														>
															{option.name}
														</ToggleButton>
													)
												)}
											</ToggleButtonGroup>
										</Box>

										{!searchResultsData?.[0]
											?.associatedFields ? (
											<Box
												minHeight="80vh"
												overflow="auto"
												pb={1}
											>
												<Result
													results={searchResultsData}
													searchKeyword={
														trimmedSearchKeyWord
													}
													onSearchedOptionClicked={
														onSearchedOptionClicked
													}
													lastRowRef={lastRowRef}
													isFetching={isFetching}
													isLoadingSearchList={
														isSearchResultsLoading
													}
												/>
											</Box>
										) : (
											<Box>
												<Stack
													spacing={4}
													alignItems="center"
													justifyContent="center"
													height="35vh"
												>
													<Typography
														fontSize={17}
														fontWeight={500}
													>
														No results found!
													</Typography>

													{isHiddenAddModuleButton ? (
														<Stack
															spacing={1}
															alignItems="center"
														>
															<Typography
																fontSize={14}
																fontWeight={400}
																style={{
																	opacity: 0.6,
																}}
															>
																Would you like
																to create one?
															</Typography>

															<Stack
																direction="row"
																spacing={1}
															>
																<Can
																	permission={
																		PERMISSIONS
																			.contact
																			.create
																	}
																>
																	{canShowAddButton(
																		searchFilterConstraints.contact
																	) ? (
																		<Box>
																			<Button
																				name="contact"
																				startIcon={AddIcon(
																					20,
																					20,
																					theme
																						.palette
																						.primary
																						.main
																				)}
																				variant="contained"
																				color="secondary"
																				disableElevation
																				onClick={
																					onAddContactClicked
																				}
																			>
																				{`contact "${trimmedSearchKeyWord}"`}
																			</Button>
																		</Box>
																	) : null}
																</Can>

																{canShowAddButton(
																	searchFilterConstraints.deal
																) ? (
																	<Can
																		permission={
																			PERMISSIONS
																				.deal
																				.create
																		}
																	>
																		<Box>
																			<Button
																				startIcon={AddIcon(
																					20,
																					20,
																					theme
																						.palette
																						.primary
																						.main
																				)}
																				variant="contained"
																				color="secondary"
																				disableElevation
																				onClick={
																					onAddDealClicked
																				}
																			>
																				{`deal "${trimmedSearchKeyWord}"`}
																			</Button>
																		</Box>
																	</Can>
																) : null}

																<Can
																	permission={
																		PERMISSIONS
																			.company
																			.create
																	}
																>
																	{canShowAddButton(
																		searchFilterConstraints.company
																	) ? (
																		<Box>
																			<Button
																				startIcon={AddIcon(
																					20,
																					20,
																					theme
																						.palette
																						.primary
																						.main
																				)}
																				variant="contained"
																				color="secondary"
																				disableElevation
																				onClick={
																					onAddCompanyClicked
																				}
																			>
																				{`Organization "${trimmedSearchKeyWord}"`}
																			</Button>
																		</Box>
																	) : null}
																</Can>
															</Stack>
														</Stack>
													) : null}
												</Stack>
											</Box>
										)}
									</Box>
								)}
							</Box>
						) : isLoadingSearchedAndViewedData ? (
							loader(30, "30px")
						) : isDisplaySearchedTextAndViewedData() ? (
							<Stack>
								<Box pt={2} pb={1}>
									<Typography
										fontSize={14}
										fontWeight={500}
										px={3}
									>
										Recently Searched
									</Typography>

									<MenuList>
										{Array.isArray(
											searchedAndViewedData?.recentlySearched
										) &&
											searchedAndViewedData
												?.recentlySearched?.length >
												0 &&
											searchedAndViewedData?.recentlySearched.map(
												(keyword, index) => (
													<MenuItem
														key={index}
														style={{
															height: "40px",
														}}
														onClick={() =>
															updateSearchInput(
																keyword
															)
														}
													>
														<Stack
															direction="row"
															alignItems="center"
															spacing={3}
															pl={1}
														>
															{SearchIcon(
																18,
																18,
																"rgba(0, 0, 0, 0.6)"
															)}

															<Typography
																fontSize={15}
															>
																{keyword}
															</Typography>
														</Stack>
													</MenuItem>
												)
											)}
									</MenuList>
								</Box>

								{Array.isArray(
									searchedAndViewedData?.recentlyViewed
								) &&
								searchedAndViewedData?.recentlyViewed.length >
									0 ? (
									<Box pb={1}>
										<Typography
											fontSize={14}
											fontWeight={500}
											px={3}
										>
											Recently Viewed
										</Typography>

										<Result
											onSearchedOptionClicked={
												onSearchedOptionClicked
											}
											results={
												searchedAndViewedData.recentlyViewed
											}
										/>
									</Box>
								) : null}
							</Stack>
						) : (
							<Box>
								<Stack
									alignItems="center"
									justifyContent="center"
									height="30vh"
								>
									<Typography
										fontSize="14px"
										color="rgba(0, 0, 0, 0.6)"
									>
										No search history to show
									</Typography>
								</Stack>
							</Box>
						)}
					</Box>
				) : null}
			</Box>
		</React.Fragment>
	);
}
