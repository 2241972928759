import React, { useEffect, useMemo, useRef, useState } from "react";
import {
	Autocomplete,
	Box,
	CircularProgress,
	Divider,
	MenuItem,
	Paper,
	Popper,
	Skeleton,
	Stack,
	TextField,
} from "@mui/material";
import UnKnownImage from "../../../../../assets/images/contact/unknownContact.png";
import team from "../../../../../assets/images/contact/multipleContact.png";
import { MenuItemText } from "../../../../../styles/twozo";
import { useTeamUsersAndUsersList } from "../../../../../hooks/services/userManagement/territory";
import { useDebouncedCallback } from "use-debounce";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";

export default function UsersAndTeamsAutoComplete(props) {
	const { onChange, selectedUsersList, updateUserAndTeamsTotalCount } = props;
	const [searchValue, setSearchValue] = useState("");
	const onlyOnceRef = useRef(true);

	// query call
	const {
		data: usersAndTeamsList,
		isLoading: isLoadingUsersAndTeams,
		status: usersAndTeamsListStatus,
		isFetching: isFetchingUsersAndTeams,
		hasNextPage: hasUsersAndTeamsNextPage,
		fetchNextPage: fetchUsersAndTeamsNextPage,
	} = useTeamUsersAndUsersList(searchValue);

	useEffect(() => {
		if (usersAndTeamsListStatus === "success" && onlyOnceRef.current) {
			updateUserAndTeamsTotalCount(usersAndTeamsList.totalRecords);
			onlyOnceRef.current = false;
		}
	}, [
		usersAndTeamsListStatus,
		updateUserAndTeamsTotalCount,
		searchValue,
		usersAndTeamsList,
	]);

	const usersAndTeamsLastOptionRef = useLastRowRef(
		fetchUsersAndTeamsNextPage,
		hasUsersAndTeamsNextPage,
		isFetchingUsersAndTeams
	);

	const filteredUserAndTeamsListData = useMemo(() => {
		const usersAndTeamUsersList = [
			...(usersAndTeamsList?.users?.map((user) => ({
				...user,
				group: "Users",
			})) ?? []),
			...(usersAndTeamsList?.teams?.map((team) => ({
				...team,
				group: "Teams",
			})) ?? []),
		];

		const selectedUserIds = selectedUsersList.map((user) => user.value);

		const selectedUsersIdSet = new Set(selectedUserIds);

		const filteredUserList = usersAndTeamUsersList.filter(
			(user) => !selectedUsersIdSet.has(user.id)
		);

		return filteredUserList;
	}, [selectedUsersList, usersAndTeamsList]);

	const handleChangeAddUser = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};
	const handleSelectUser = (option) => {
		onChange(option);
		setSearchValue("");
	};

	const onInputChange = useDebouncedCallback(
		(event) => {
			handleChangeAddUser(event);
		},
		500 // delay in ms
	);
	const PopperComponent = (props) => {
		return <Popper {...props} sx={{ paddingTop: "10px" }} />;
	};
	const CustomPaper = function (props) {
		return (
			<Paper
				{...props}
				style={{
					...props.style,
					borderRadius: "8px",
					boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
					width: "300px",
				}}
			/>
		);
	};
	const renderGroup = (params) => {
		return (
			<Box key={params.key}>
				<Box fontSize={14} fontWeight={500} pl={2} pt={1} py={1}>
					{params.group}
				</Box>

				{params.children.map((child, index) => (
					<React.Fragment key={`child-${params.key}-${index}`}>
						<Box>{child}</Box>
					</React.Fragment>
				))}

				<Box pt={0.5}>
					<Divider
						sx={{
							display:
								params.group === "Users" ? "block" : "none",
						}}
					/>
				</Box>
			</Box>
		);
	};
	const renderInput = (params) => {
		return (
			<TextField
				{...params}
				variant="standard"
				InputProps={{
					...params.InputProps,
					disableUnderline: true,
					style: {
						fontSize: "15px",
						fontWeight: 400,
					},
				}}
				placeholder="Search"
				value={searchValue}
				onBlur={() => setSearchValue("")}
				onChange={onInputChange}
			/>
		);
	};

	const renderOptions = (props, option, index) => {
		return (
			<Box>
				<Box
					{...props}
					sx={{
						minHeight: "40px",
					}}
					key={index}
					onClick={() => handleSelectUser(option)}
				>
					<Stack direction="row" alignItems="center" spacing={1}>
						{option?.group === "Teams" ? (
							<img
								src={team}
								alt="img"
								width="26px"
								height="26px"
							/>
						) : (
							<img
								src={UnKnownImage}
								alt="img"
								width="26px"
								height="26px"
							/>
						)}
						<MenuItemText>{option.name}</MenuItemText>
					</Stack>
				</Box>{" "}
			</Box>
		);
	};

	const CustomListboxComponent = function (props) {
		return (
			<Box {...props}>
				{props.children}

				<Box ref={usersAndTeamsLastOptionRef}>
					{!isLoadingUsersAndTeams &&
					isFetchingUsersAndTeams &&
					hasUsersAndTeamsNextPage ? (
						<MenuItem
							style={{
								height: "40px",
								width: "100%",
							}}
						>
							<Skeleton width="200px" height="16px" />
						</MenuItem>
					) : null}
				</Box>
			</Box>
		);
	};

	return (
		<Autocomplete
			open={searchValue.trim().length > 0}
			loading={isLoadingUsersAndTeams}
			PopperComponent={PopperComponent}
			PaperComponent={CustomPaper}
			forcePopupIcon={false}
			disableClearable
			autoHighlight={true}
			options={filteredUserAndTeamsListData || []}
			groupBy={(option) => (option.users?.length > 0 ? "Teams" : "Users")}
			renderGroup={renderGroup}
			getOptionLabel={(option) => option.name}
			loadingText={
				<Stack justifyContent="center" alignItems="center">
					<CircularProgress size="20px" />
				</Stack>
			}
			ListboxComponent={CustomListboxComponent}
			renderOption={(props, option) => renderOptions(props, option)}
			renderInput={(params) => renderInput(params)}
		/>
	);
}
