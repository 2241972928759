import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SingleTimePicker from "../SingleTimePicker";

export default function TimeRange({
	onChange,
	value,
	inputRef,
	error,
	onClearError,
	removeBorder,
	textFieldStyle,
}) {
	const [timeRangeValue, setTimeRangeValue] = useState({});

	useEffect(() => {
		if (value) {
			setTimeRangeValue(value);
		}
	}, [value]);

	const handleTimeRangeValueChange = (timeRangeValue) => {
		if (onChange) {
			onChange(timeRangeValue);
		}
	};

	const updateFromTime = (value) => {
		let newValue = { ...timeRangeValue, from: value };
		setTimeRangeValue(newValue);
		handleTimeRangeValueChange(newValue);
	};

	const updateToTime = (value) => {
		let newValue = { ...timeRangeValue, to: value };
		setTimeRangeValue(newValue);
		handleTimeRangeValueChange(newValue);
	};

	return (
		<React.Fragment>
			<Box
				width="100%"
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					position: "relative",
				}}
			>
				<SingleTimePicker
					style={{
						timeFieldStyle: {
							width: "50%",
							borderRadius: "8px 0px 0px 8px",
							...textFieldStyle,
						},
						timeFieldPosition: {
							justifyContent: "start",
						},
					}}
					value={timeRangeValue.from}
					onChange={updateFromTime}
					removeBorder={removeBorder}
					onClearError={onClearError}
					inputRef={inputRef}
					error={error}
				/>

				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						position: "absolute",
						left: "50%",
						transform: "translateX(-50%)",
						backgroundColor: "#fff",
						zIndex: 2,
					}}
				>
					<Typography fontSize={14} color={"rgba(0, 0, 0, 0.6)"}>
						To
					</Typography>
				</Box>

				<SingleTimePicker
					reverse={true}
					style={{
						timeFieldStyle: {
							width: "50%",
							borderRadius: "0px 8px 8px 0px",
						},
						timeFieldPosition: {
							justifyContent: "end",
						},
					}}
					value={timeRangeValue.to}
					onChange={updateToTime}
					removeBorder={removeBorder}
					onClearError={onClearError}
					inputRef={inputRef}
					error={error}
				/>
			</Box>
		</React.Fragment>
	);
}
