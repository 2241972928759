import {
	useInfiniteQuery,
	useMutation,
	useQueryClient,
} from "@tanstack/react-query";
import { tagsListKey } from "../../../utils/queryKeys";
import { getTagsDropdownKey } from "../../../utils/queryKeys";
import {
	createTags,
	deleteTags,
	getTagsList,
	updateTags,
	addTags,
	removeTags,
	getTagsDropdownData,
} from "../../../api/tag";
import { useInvalidateTableDataBySourceName } from "../common/table";
import {
	tagDropdownListPageSize,
	tagListPageSize,
} from "../../../utils/queryConstants/tags";

const tagListSelect = (tags) => {
	return tags?.pages?.flatMap((page) =>
		page?.list?.length > 0 ? page?.list : []
	);
};

const useTagsList = () => {
	return useInfiniteQuery({
		queryKey: tagsListKey,
		queryFn: ({ pageParam }) => {
			return getTagsList({
				start: pageParam ? (pageParam - 1) * tagListPageSize + 1 : 1,
				limit: tagListPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: tagListSelect,
	});
};

const useInvalidateTags = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(tagsListKey);
	};
};

const useCreateTagMutation = () => {
	let invalidateTagsList = useInvalidateTags();
	return useMutation(createTags, {
		onSuccess: () => {
			invalidateTagsList();
		},
	});
};

const useUpdateTagMutation = () => {
	let invalidateTagsList = useInvalidateTags();
	return useMutation(updateTags, {
		onSuccess: () => {
			invalidateTagsList();
		},
	});
};

const useDeleteTagMutation = () => {
	let invalidateTagsList = useInvalidateTags();
	return useMutation(deleteTags, {
		onSuccess: () => {
			invalidateTagsList();
		},
	});
};

const tagSelect = (tags) => {
	return tags.pages.flatMap((page) => page?.options);
};

const useTagDropdownList = (searchValue) => {
	return useInfiniteQuery({
		queryKey: getTagsDropdownKey(searchValue),
		queryFn: ({ pageParam }) => {
			return getTagsDropdownData(
				{
					start: pageParam
						? (pageParam - 1) * tagDropdownListPageSize + 1
						: 1,
					limit: tagDropdownListPageSize,
				},
				searchValue
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: tagSelect,
	});
};

const useAddTags = (sourceName) => {
	let invalidateTableDataBySourceName =
		useInvalidateTableDataBySourceName(sourceName);
	return useMutation(addTags, {
		onSuccess: () => {
			invalidateTableDataBySourceName();
		},
	});
};

const useRemoveTags = (sourceName) => {
	let invalidateTableDataBySourceName =
		useInvalidateTableDataBySourceName(sourceName);
	return useMutation(removeTags, {
		onSuccess: () => {
			invalidateTableDataBySourceName();
		},
	});
};

export {
	useTagsList,
	useCreateTagMutation,
	useUpdateTagMutation,
	useDeleteTagMutation,
	useTagDropdownList,
	useAddTags,
	useRemoveTags,
};
