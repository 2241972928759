export const viewTypes = {
	roles: "roles",
	users: "users",
	teams: "teams",
	territory: "territory",
};

export const addUserFormFields = {
	firstName: "firstName",
	lastName: "lastName",
	jobTitle: "jobTitle",
	email: "email",
	mobile: "mobile",
	reportingManager: "reportingManager",
	pipelineId: "pipelineId",
	teams: "teams",
	role: "role",
};
