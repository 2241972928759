import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { default as CallIcon } from "../../../../assets/icons/call";
import Call from "../../../../assets/images/phone/call.png";

export default function IncomingCall(props) {
	const { incomingCallConnection, acceptCall, rejectCall } = props;

	return (
		<React.Fragment>
			<Stack
				alignItems="center"
				direction="row"
				spacing={2}
				justifyContent="space-between"
			>
				<Stack direction="row" alignItems="center" spacing={3}>
					<Box display="flex">
						<img
							src={Call}
							width="50px"
							height="50px"
							style={{ borderRadius: "100%" }}
						/>
					</Box>

					<Typography fontSize={13}>
						{incomingCallConnection?.parameters?.From}
					</Typography>
				</Stack>

				<Stack direction="row" spacing={3} alignItems="center">
					<Button
						variant="contained"
						color="primary"
						disableElevation
						onClick={acceptCall}
					>
						{CallIcon(20, 20, "#fff")}
					</Button>

					<Button
						variant="contained"
						color="error"
						disableElevation
						onClick={rejectCall}
					>
						<Box
							style={{
								transform: "rotate(135deg)",
							}}
							display="flex"
						>
							{CallIcon(20, 20, "#fff")}
						</Box>
					</Button>
				</Stack>
			</Stack>
		</React.Fragment>
	);
}
