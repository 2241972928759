import React, { useRef, useState } from "react";
import { Box, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import contactImage from "../../../../assets/images/contact/unknownContact.png";
import TooltipComponent from "../../../Elements/TooltipComponent";
import { default as DropDownIcon } from "../../../../assets/icons/dropDownCentered";
import {
	getDateDifferenceWithHourAndMinute,
	getDateMonthAndYearFormat,
} from "../../../../utils/DateUtils";
import { MenuItemText, TextButton } from "../../../../styles/twozo";
import Menu from "../../../Elements/Menu";
import DeleteDialog from "../../../Elements/DeleteDialog";
import { useDeleteNote } from "../../../../hooks/services/summary/notes/notes";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { useTruncatedElement } from "../../../../hooks/layout";
import MoreIconButton from "../../../Elements/MoreIconButton";

export default function NoteItem(props) {
	const {
		noteData,
		sourceName,
		entityId,
		handleEditNote,
		noteActionsRestricted,
	} = props;
	const noteContentRef = useRef();
	const theme = useTheme();
	const [noteMenuElement, setNoteMenuElement] = useState(null);
	const isNoteMenuOpened = Boolean(noteMenuElement);
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
	const [isShowingMore, setIsShowingMore] = useState(false);

	const deleteMutation = useDeleteNote(sourceName, entityId);
	const { isTruncated } = useTruncatedElement(noteContentRef);

	const openNoteMenu = (event) => {
		setNoteMenuElement(event.currentTarget);
	};

	const closeNoteMenu = () => {
		setNoteMenuElement(null);
	};

	const openDeleteDialog = () => {
		setIsDeleteDialogOpened(true);
		setNoteMenuElement(null);
	};

	const closeDeleteDialog = () => {
		setIsDeleteDialogOpened(false);
	};

	const handleEditClick = () => {
		setNoteMenuElement(null);
		handleEditNote(noteData);
	};

	const handleDeleteNote = () => {
		let deleteNoteRequest = { id: noteData?.id };
		deleteMutation.mutate(deleteNoteRequest, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.notesDeleted,
				});
				closeDeleteDialog();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	const toggleSeeMoreButton = () => {
		setIsShowingMore((isShowingMore) => !isShowingMore);
	};

	return (
		<Box>
			{/* Note Menu */}
			<Menu
				width="200px"
				anchorEl={noteMenuElement}
				open={isNoteMenuOpened}
				onClose={closeNoteMenu}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				style={{
					marginTop: "2px",
				}}
			>
				<MenuItem style={{ height: "40px" }} onClick={handleEditClick}>
					<MenuItemText
						sx={{ fontWeight: 500 }}
						color={theme.palette.secondary.contrastText}
					>
						Edit
					</MenuItemText>
				</MenuItem>

				<MenuItem style={{ height: "40px" }} onClick={openDeleteDialog}>
					<MenuItemText
						sx={{ fontWeight: 500 }}
						color={theme.palette.secondary.contrastText}
					>
						Delete
					</MenuItemText>
				</MenuItem>
			</Menu>

			{/* Delete Dialog */}
			<DeleteDialog
				open={isDeleteDialogOpened}
				title="Are you sure you want to delete this note?"
				subtitle="This action cannot be undone."
				onCancel={closeDeleteDialog}
				onDelete={handleDeleteNote}
				disableDeleteButton={deleteMutation.isLoading}
			/>

			<Stack
				direction="row"
				spacing={3}
				px={2}
				py={1.5}
				style={{
					borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
					pointerEvents: noteActionsRestricted ? "none" : "auto",
					opacity: noteActionsRestricted ? 0.6 : 1,
				}}
			>
				<img
					src={contactImage}
					style={{ marginTop: ".5px" }}
					alt="profile"
					width="40px"
					height="40px"
				/>

				<Stack spacing={1} width="100%">
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						width="100%"
					>
						<Stack direction="row" spacing={1} alignItems="center">
							<TooltipComponent
								title={noteData?.owner?.name}
								placement="top"
								verticalDisplacement={-3}
								width="259px"
							>
								<Typography
									fontSize={14}
									maxWidth="40vw"
									noWrap
									sx={{
										cursor: "pointer",
									}}
								>
									{noteData?.owner?.name}
								</Typography>
							</TooltipComponent>

							<Typography
								fontSize={14}
								color={"rgba(0, 0, 0, 0.6)"}
							>
								&#8226;
							</Typography>

							<Typography
								fontSize={14}
								color={"rgba(0, 0, 0, 0.6)"}
								minWidth={125}
							>
								{getDateMonthAndYearFormat(
									noteData?.createdTime
								)}{" "}
								{`(${getDateDifferenceWithHourAndMinute(
									noteData?.updatedTime
								)?.toLocaleLowerCase()})`}
							</Typography>

							{noteData?.isEdited ? (
								<Stack direction="row" spacing={1}>
									<Typography
										fontSize={14}
										color={"rgba(0, 0, 0, 0.6)"}
									>
										&#8226;
									</Typography>

									<Typography
										fontSize={14}
										color={"rgba(0, 0, 0, 0.6)"}
									>
										Edited
									</Typography>
								</Stack>
							) : null}
						</Stack>

						<MoreIconButton
							onClick={openNoteMenu}
							isFocused={isNoteMenuOpened}
							iconProps={{
								width: 18,
								height: 18,
							}}
						/>
					</Stack>

					<Stack>
						<Box
							ref={noteContentRef}
							style={{
								maxHeight: isShowingMore ? "none" : "64px",
								overflow: "hidden",
							}}
						>
							<Typography
								component="pre"
								fontSize={15}
								whiteSpace="break-spaces"
								sx={{ wordBreak: "break-word" }}
							>
								{noteData?.note}
							</Typography>
						</Box>

						{isTruncated ? (
							<TextButton
								onClick={toggleSeeMoreButton}
								size="small"
								sx={{
									fontWeight: 500,
									width: "fit-content",
									marginTop: "8px",
									"& .MuiButton-endIcon": {
										marginLeft: "4px",
									},
								}}
								endIcon={
									<Box
										display="flex"
										style={{
											transform: isShowingMore
												? "rotate(180deg)"
												: "rotate(0deg)",
										}}
									>
										{DropDownIcon(
											16,
											16,
											theme.palette.primary.main
										)}
									</Box>
								}
							>
								{isShowingMore ? "See Less" : "See More"}
							</TextButton>
						) : null}
					</Stack>
				</Stack>
			</Stack>
		</Box>
	);
}
