import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	MenuItem,
	Skeleton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as EditIcon } from "../../../../assets/icons/edit";
import { default as MoreIcon } from "../../../../assets/icons/more";
import { default as DropdownIcon } from "../../../../assets/icons/dropDownRight";
import { default as FavouriteOffIcon } from "../../../../assets/icons/favouriteOff";
import { default as FavouriteOnIcon } from "../../../../assets/icons/favouriteOn";
import {
	useFavData,
	useGetDashboard,
} from "../../../../hooks/services/analytics";
import Charts from "../Charts";
import Menu from "../../../Elements/Menu";
import FullScreenDialog from "../../../Elements/FullScreenDialog";
import UnderlyingData from "./UnderlyingData";
import Share from "../Share";
import { access, checkPermission } from "../Share/Utils";
import MoreIconButton from "../../../Elements/MoreIconButton";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";

export default function DashboardView() {
	const theme = useTheme();
	const navigate = useNavigate();
	let { dashboardId } = useParams();
	const fetchViewProperties = true;

	// mutation call:-
	const favMutation = useFavData(dashboardId, fetchViewProperties);

	const { data: dashboardData, isLoading: isDashboardDataLoading } =
		useGetDashboard(dashboardId, fetchViewProperties);
	const [ChartMenuElement, setChartMenuElement] = useState(false);
	const isChartMenuOpened = Boolean(ChartMenuElement);
	const [hoveredChartIndex, setHoveredChartIndex] = useState(null);
	const [isOpenUnderlyingDialog, setIsOpenUnderlyingDialog] = useState(false);
	const [selectedChartIndex, setSelectedChartIndex] = useState(null);

	const openChartMenu = (event, index) => {
		setSelectedChartIndex(index);
		setChartMenuElement(event.currentTarget);
	};

	const closeChartMenu = () => {
		setChartMenuElement(null);
		setHoveredChartIndex(null);
	};

	const openUnderlyingDialog = () => {
		setIsOpenUnderlyingDialog(true);
		closeChartMenu();
	};

	const closeUnderlyingDialog = () => {
		setIsOpenUnderlyingDialog(false);
	};

	const menuOptions = [
		{
			id: "export",
			name: "Export to email",
			icon: DropdownIcon,
			onClick: closeChartMenu,
		},
		{
			id: "download",
			name: "Download",
			icon: DropdownIcon,
			onClick: closeChartMenu,
		},
		{
			id: "underlying Data",
			name: "Underlying Data",
			icon: () => {},
			onClick: openUnderlyingDialog,
		},
	];

	const onMouseOverChart = (index) => {
		setHoveredChartIndex(index);
	};

	const onMouseOutChart = () => {
		if (!isChartMenuOpened) {
			setHoveredChartIndex(null);
		}
	};

	const toggleFavourite = (event) => {
		event.stopPropagation();

		let requestData = {
			dashboardId: dashboardData?.dashboardId,
			isFavourite: !dashboardData?.isFavourite,
		};
		favMutation.mutate(requestData, {
			onSuccess: (data) => {
				let successMessage = data?.message;
				enqueueSnackbar({
					variant: notificationVariants.success,
					message:
						successMessage ??
						notificationMessage.DashboardAddedToFav,
				});
			},
			onError: (data) => {
				let errorMessage = data?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<FullScreenDialog
				open={isOpenUnderlyingDialog}
				onClose={closeUnderlyingDialog}
				title="Underlying data"
			>
				<UnderlyingData
					viewProperties={
						dashboardData?.childViewInfo?.[selectedChartIndex]
							?.viewProperties
					}
					viewId={
						dashboardData?.childViewInfo?.[selectedChartIndex]
							?.viewId
					}
					viewTypeId={
						dashboardData?.childViewInfo?.[selectedChartIndex]
							?.viewTypeId
					}
				/>
			</FullScreenDialog>

			<Menu
				minWidth="200px"
				anchorEl={ChartMenuElement}
				open={isChartMenuOpened}
				onClose={closeChartMenu}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				style={{
					marginTop: "8px",
				}}
			>
				{menuOptions.map((menuOption) => (
					<MenuItem
						key={menuOption.id}
						style={{ height: "40px", width: "100%" }}
						onClick={menuOption.onClick}
					>
						<Stack
							width="100%"
							direction="row"
							alignItems="center"
							spacing={1.5}
							justifyContent="space-between"
						>
							<Typography fontSize={13} fontWeight={400}>
								{menuOption.name}
							</Typography>

							{menuOption.icon(20, 20, "#000", 0.6)}
						</Stack>
					</MenuItem>
				))}
			</Menu>

			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
				}}
			>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					p={1.5}
				>
					<Stack direction="row" alignItems="center" spacing={0.5}>
						<IconButton
							size="small"
							onClick={toggleFavourite}
							disabled={isDashboardDataLoading}
						>
							{dashboardData?.isFavourite
								? FavouriteOnIcon(
										20,
										20,
										theme.palette.primary.main
									)
								: FavouriteOffIcon(
										20,
										20,
										"rgba(0, 0, 0, 0.2)"
									)}
						</IconButton>

						{isDashboardDataLoading ? (
							<Skeleton
								variant="text"
								width={250}
								sx={{
									fontSize: "13px",
								}}
							/>
						) : (
							<Typography fontWeight={500} fontSize={15}>
								{dashboardData.dashboardName}
							</Typography>
						)}
					</Stack>

					{isDashboardDataLoading ? (
						<Stack direction="row" alignItems="center" spacing={2}>
							<Skeleton
								variant="rectangular"
								width={92}
								height={36}
								sx={{
									borderRadius: "6px",
								}}
							/>

							<Skeleton
								variant="rectangular"
								width={92}
								height={36}
								sx={{
									borderRadius: "6px",
								}}
							/>

							<Skeleton
								variant="circular"
								width={36}
								height={36}
							/>
						</Stack>
					) : (
						<Stack direction="row" alignItems="center" spacing={2}>
							{checkPermission(
								access.canManage,
								dashboardData.userPermission
							) && (
								<Share
									userPermission={
										dashboardData.userPermission
									}
								/>
							)}

							{checkPermission(
								access.canEdit,
								dashboardData.userPermission
							) && (
								<Button
									variant="contained"
									color="secondary"
									startIcon={EditIcon(
										20,
										20,
										theme.palette.primary.main
									)}
									onClick={() => {
										navigate(
											`/analytics/dashboard/builder/${dashboardId}`
										);
									}}
									disableElevation
								>
									Edit
								</Button>
							)}

							<IconButton size="small">
								{MoreIcon(20, 20, theme.palette.primary.main)}
							</IconButton>
						</Stack>
					)}
				</Stack>

				<Divider />

				<Box
					style={{
						backgroundColor: "#f4f5f5",
						flex: "1 1 auto",
						minHeight: 0,
						overflow: "auto",
						padding: "16px",
					}}
				>
					{isDashboardDataLoading ? (
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="center"
							height="100%"
							spacing={2}
						>
							<CircularProgress size={32} />
							<Typography>Loading</Typography>
						</Stack>
					) : dashboardData.childViewInfo?.length > 0 ? (
						<Grid container spacing={2}>
							{dashboardData.childViewInfo.map(
								(chartData, chartIndex) => (
									<Grid
										item
										key={chartData.viewId}
										md={12}
										lg={6}
									>
										<Box
											style={{
												padding: "8px",
												borderRadius: "6px",
												backgroundColor: "#fff",
												height: "380px",
											}}
											onMouseOver={() =>
												onMouseOverChart(chartIndex)
											}
											onMouseLeave={() =>
												onMouseOutChart()
											}
										>
											<Stack
												width="100%"
												direction="row"
												justifyContent="space-between"
												alignItems="center"
											>
												<Typography p={1} pb={2}>
													{chartData.viewName}
												</Typography>

												{chartIndex ===
													hoveredChartIndex && (
													<MoreIconButton
														onClick={(event) =>
															openChartMenu(
																event,
																chartIndex
															)
														}
														isFocused={
															isChartMenuOpened
														}
													/>
												)}
											</Stack>

											<Charts
												viewId={chartData.viewId}
												viewTypeId={
													chartData.viewTypeId
												}
												dashboardId={dashboardId}
											/>
										</Box>
									</Grid>
								)
							)}
						</Grid>
					) : (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="100%"
							spacing={0.5}
						>
							<Typography
								style={{
									fontSize: "18px",
									fontWeight: 500,
									opacity: "60%",
								}}
							>
								No Widgets Found
							</Typography>

							<Typography
								style={{
									fontSize: "14px",
									opacity: "60%",
								}}
							>
								Try resetting your filters or create new widget
							</Typography>
						</Stack>
					)}
				</Box>
			</Box>
		</React.Fragment>
	);
}
