import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import {
	createActivity,
	deleteMultipleActivities,
	getActivitiesCalendarViewData,
	getActivity,
	getCollaboratorList,
	getDueDateList,
	getSummaryData,
	partialBulkUpdateActivity,
	partialUpdateActivity,
	updateActivity,
} from "../../../api/activity/activityApi";
import {
	calendarViewKey,
	getActivitiesCalendarViewDataKey,
	getActivityDuedateKey,
	getActivitySummaryKey,
	getActivityTableKey,
	getCalendarViewWeekKey,
	getCollaboratorListKey,
} from "../../../utils/queryKeys/activity";
import {
	useInvalidateCommonSearch,
	useInvalidateSearchedAndViewed,
} from "../search";
import { useInvalidateDealKanbanView } from "../deal";
import { collaboratorPageSize } from "../../../utils/queryConstants/activity";
import { getActivityDataKey } from "../../../utils/queryKeys";

const useActivityData = (activityId) => {
	return useQuery(
		getActivityDataKey(activityId),
		() => getActivity(activityId),
		{
			enabled: !!activityId,
		}
	);
};

const useActivityDueDateList = (sourceId) => {
	return useQuery(
		getActivityDuedateKey(sourceId),
		() => getDueDateList(sourceId),
		{
			enabled: !!sourceId,
			staleTime: Infinity,
		}
	);
};

const useActivitySummary = (id) => {
	return useQuery(getActivitySummaryKey(id), () => getSummaryData(id), {
		select: (data) => data,
		enabled: !!id,
	});
};

const useCollaboratorList = (activityId) => {
	return useInfiniteQuery({
		queryKey: getCollaboratorListKey(activityId),
		queryFn: ({ pageParam }) => {
			return getCollaboratorList(activityId, {
				start: pageParam
					? (pageParam - 1) * collaboratorPageSize + 1
					: 1,
				limit: collaboratorPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
	});
};

const formattedActivities = (activities) => {
	return activities?.map((activity) => {
		return {
			...activity,
			startTime: new Date(activity.startTime),
			endTime: new Date(activity.endTime),
		};
	});
};

const useActivitiesCalendarViewData = (weekCount, isWeekEnd, criteria) => {
	return useQuery(
		getActivitiesCalendarViewDataKey(weekCount, isWeekEnd, criteria),
		() => getActivitiesCalendarViewData(weekCount, isWeekEnd, criteria),
		{
			select: (data) => formattedActivities(data.activities),
		}
	);
};

//Invalidate Activity data
const useInvalidateActivityData = (activityId) => {
	let queryClient = useQueryClient();
	let activityDataKey = getActivityDataKey(activityId);
	return () => {
		queryClient.invalidateQueries(activityDataKey);
	};
};

//Invalidate Calendar View Data
const useInvalidateCalendarViewWeek = (weekCount) => {
	let queryClient = useQueryClient();
	let calendarViewWeekKey = getCalendarViewWeekKey(weekCount);
	return () => {
		queryClient.invalidateQueries(calendarViewWeekKey);
	};
};

const useInvalidateCalendarView = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(calendarViewKey);
	};
};

//Invalidate Activity Summary
const useInvalidateActivitySummary = (id) => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getActivitySummaryKey(id));
	};
};

//Invalidate Activity List
const useInvalidateActivityList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries({ queryKey: getActivityTableKey() });
	};
};

// Invalidate ActivityToDo List in Summary
const useInvalidateTodoActivitesKey = (todoActivityKey) => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(todoActivityKey);
	};
};

// Invalidate ActivityCompleted List in Summary
const useInvalidateCompletedActivitesKey = (completedActivityKey) => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(completedActivityKey);
	};
};

//Invalidate Summary
const useInvalidateSummary = (summaryKey) => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(summaryKey);
	};
};

// Update Activity Status from Calendar view
const useUpdateActivityStatusFromCalendar = (weekCount) => {
	let invalidateCalendarViewWeek = useInvalidateCalendarViewWeek(weekCount);
	return useMutation(partialUpdateActivity, {
		onSuccess: () => {
			invalidateCalendarViewWeek();
		},
	});
};

//Update Activity Status
const useUpdateActivityStatus = (id) => {
	let invalidateActivitySummary = useInvalidateActivitySummary(id);
	let invalidateActivityList = useInvalidateActivityList();
	let invalidateSearchedAndViewed = useInvalidateSearchedAndViewed();
	let invalidateCalendarViewData = useInvalidateCalendarView();
	let invalidateCommonSearchKey = useInvalidateCommonSearch();
	return useMutation(partialUpdateActivity, {
		onSuccess: () => {
			invalidateActivitySummary();
			invalidateActivityList();
			invalidateSearchedAndViewed();
			invalidateCalendarViewData();
			invalidateCommonSearchKey();
		},
	});
};

//Update Activity Status from list
const useUpdateActivityStatusFromList = () => {
	let invalidateActivityList = useInvalidateActivityList();
	return useMutation(partialBulkUpdateActivity, {
		onSuccess: () => {
			invalidateActivityList();
		},
	});
};

//Update Activity Status from summary
const useUpdateActivityStatusFromSummary = (
	todoActivityKey,
	completedActivityKey,
	summaryKey
) => {
	let invalidateTodoActivityKey =
		useInvalidateTodoActivitesKey(todoActivityKey);
	let invalidateCompletedActivityKey =
		useInvalidateCompletedActivitesKey(completedActivityKey);
	let invalidateSummary = useInvalidateSummary(summaryKey);

	return useMutation(partialUpdateActivity, {
		onSuccess: () => {
			invalidateTodoActivityKey();
			invalidateCompletedActivityKey();
			if (summaryKey) {
				invalidateSummary();
			}
		},
	});
};

//Delete Activites
const useDeleteActivities = () => {
	let invalidateActivityList = useInvalidateActivityList();
	return useMutation(deleteMultipleActivities, {
		onSuccess: () => {
			invalidateActivityList();
		},
	});
};

//Create Activity
const useCreateActivity = () => {
	let invalidateActivityList = useInvalidateActivityList();
	let invalidateCalendarViewData = useInvalidateCalendarView();
	let invalidateDealKanbanView = useInvalidateDealKanbanView();
	return useMutation(createActivity, {
		onSuccess: () => {
			invalidateActivityList();
			invalidateCalendarViewData();
			invalidateDealKanbanView();
		},
	});
};

// Edit Activity
const useEditActivity = (activityId) => {
	let invalidateActivityData = useInvalidateActivityData(activityId);
	return useMutation(updateActivity, {
		onSuccess: () => {
			invalidateActivityData();
		},
	});
};

export {
	useActivityDueDateList,
	useActivitySummary,
	useUpdateActivityStatus,
	useUpdateActivityStatusFromList,
	useDeleteActivities,
	useCreateActivity,
	useCollaboratorList,
	useInvalidateActivityList,
	useActivitiesCalendarViewData,
	useUpdateActivityStatusFromCalendar,
	useEditActivity,
	useActivityData,
	useUpdateActivityStatusFromSummary,
};
