import {
	Box,
	Button,
	MenuItem,
	Radio,
	Select,
	Stack,
	TextField,
	useTheme,
} from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";
import { MenuItemText, TextButton, Tooltip } from "../../../../styles/twozo";
import { emailPattern } from "../../../../utils/validation";
import { getDropdownDataByName } from "../../../../utils/dropdownData";
import { default as AddIcon } from "../../../../assets/icons/add";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { default as RadioOffIcon } from "../../../../assets/icons/radioOff";
import { default as RadioTickedIcon } from "../../../../assets/icons/tickCircle";
import { useEffect } from "react";

export default function MultiEmailEditForm(params) {
	const { subFields, onClose, value, onSave, field, isSaving } = params;
	const theme = useTheme();
	const fieldId = field?.fieldId;

	const {
		control,
		formState: { errors },
		clearErrors,
		register,
		handleSubmit,
		setValue,
		getValues,
	} = useForm({
		defaultValues: value,
		reValidateMode: false,
		values: value,
	});

	const {
		fields: emailFields,
		append: addEmail,
		remove: handleRemoveEmail,
	} = useFieldArray({
		control,
		name: fieldId + "",
	});

	const emailValue = "EMAIL_VALUE";
	const emailType = "EMAIL_TYPE";
	const emailTypeOptions = getDropdownDataByName("EMAIL_TYPE");

	const isEmailValueLimitExceeded =
		emailFields.length >= field.config?.maxLimit;
	const emailValidationConfig = {
		required: field.config.required ? "This field mandatory field" : "",
		pattern: {
			value: emailPattern,
			message: "Invalid email address are not allowed",
		},
		maxLength: {
			value: "320",
			message: "Max. of 320 characters are allowed",
		},
	};

	const getSubFieldObject = (subFieldName) => {
		let subFieldObj;
		if (subFields && Array.isArray(subFields)) {
			subFields.forEach((field) => {
				if (field.name === subFieldName) {
					subFieldObj = field;
				}
			});
		}
		return subFieldObj;
	};

	const emailValueObj = getSubFieldObject(emailValue);
	const emailTypeObj = getSubFieldObject(emailType);

	const handlePrimaryChange = (index) => {
		const currentFields = getValues(fieldId + "");
		const updatedFields = currentFields.map((field, fieldIndex) => ({
			...field,
			isPrimary: fieldIndex === index,
		}));
		setValue(fieldId + "", updatedFields);
	};

	const handleAddEmail = (isPrimary = false) => {
		addEmail({
			[emailValueObj?.id]: "",
			[emailTypeObj?.id]: emailTypeOptions?.[0]?.value,
			isPrimary: isPrimary,
		});
	};

	const getRemovedEmptyEmailValue = (obj) => {
		const fieldId = field.fieldId?.toString();
		if (Array.isArray(obj[fieldId])) {
			obj[fieldId] = obj[fieldId].filter(
				(item) => item && item[emailValueObj?.id] !== ""
			);
		}
		return obj;
	};

	const handleSave = (emailsData) => {
		let removedEmptyEmailsData = getRemovedEmptyEmailValue(emailsData);
		onSave(removedEmptyEmailsData);
	};

	const getDefaultValue = (emailField) => {
		let defaultValue = emailTypeOptions.find((field) => {
			return emailField?.[emailTypeObj?.id] === field?.value;
		});

		return defaultValue?.value;
	};

	useEffect(() => {
		if (emailFields.length === 0) {
			handleAddEmail(true);
		}
	}, [emailFields, handleAddEmail]);

	return (
		<>
			<Stack
				direction="column"
				alignItems="center"
				justifyContent="center"
				padding={`8px ${
					emailFields?.length > 1 ? "16px" : "0px"
				} 16px 16px`}
				spacing={0.5}
				maxHeight="40vh"
			>
				<Stack
					sx={{ overflowY: "auto" }}
					spacing={0.5}
					direction="column"
					alignItems="center"
					justifyContent="flex-start"
				>
					{emailFields?.map((emailField, index) => (
						<Stack
							key={emailField.id}
							direction="row"
							minWidth="440px"
							justifyContent="center"
							alignItems={
								errors[fieldId]?.[index]?.[emailValueObj?.id]
									? "baseline"
									: "center"
							}
							spacing={1}
						>
							<Box
								width="6%"
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								<Tooltip
									placement="left"
									title={
										emailField.isPrimary
											? ""
											: "Mark as Primary"
									}
									PopperProps={{
										modifiers: [
											{
												name: "offset",
												options: {
													offset: [0, -10],
												},
											},
										],
									}}
								>
									<Radio
										checked={emailField.isPrimary}
										onChange={() =>
											handlePrimaryChange(index)
										}
										icon={RadioOffIcon(25, 25)}
										checkedIcon={RadioTickedIcon(
											18,
											18,
											theme.palette.primary.main
										)}
										style={{ padding: 0 }}
									/>
								</Tooltip>
							</Box>

							<Box width="94%" display="flex">
								<TextField
									{...register(
										`${fieldId}.${index}.${emailValueObj?.id}`,
										{ ...emailValidationConfig }
									)}
									onChange={() => {
										clearErrors(
											`${fieldId}.${index}.${emailValueObj?.id}`
										);
									}}
									placeholder="Eg: abc@abc.com"
									InputProps={{
										endAdornment: null,
									}}
									sx={{
										width: "70%",
										"& .MuiOutlinedInput-notchedOutline": {
											borderRadius: "8px 0px 0px 8px",
										},
										"& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button":
											{
												"-webkit-appearance": "none",
												margin: 0,
											},
									}}
									FormHelperTextProps={{
										sx: {
											fontSize: 13,
											ml: "8px",
										},
									}}
									error={
										!!errors[fieldId]?.[index]?.[
											emailValueObj?.id
										]
									}
									helperText={
										errors[fieldId]?.[index]?.[
											emailValueObj?.id
										]?.message
									}
								/>

								<Select
									{...register(
										`${fieldId}.${index}.${emailTypeObj?.id}`
									)}
									sx={{
										width: "30%",
										"& .MuiOutlinedInput-notchedOutline": {
											borderRadius: "0px 8px 8px 0px",
											marginLeft: "-1px",
											borderLeftWidth: 0,
										},
									}}
									defaultValue={() =>
										getDefaultValue(emailField)
									}
									error={
										!!errors[fieldId]?.[index]?.[
											emailValueObj?.id
										]
									}
								>
									{emailTypeOptions?.map((emailType) => (
										<MenuItem
											key={emailType.value}
											value={emailType.value}
										>
											<MenuItemText>
												{emailType.name}
											</MenuItemText>
										</MenuItem>
									))}
								</Select>

								<Box width="6%">
									{emailFields?.length > 1 &&
										!emailField.isPrimary && (
											<Box
												display="flex"
												width="100%"
												sx={{ opacity: 0.6 }}
												style={{
													border: "1px solid rgba(0, 0, 0, 0.2)",
													borderRadius:
														"0px 5px 5px 0px",
													borderLeft: "none",
													marginLeft: "-1px",
													display: "flex",
													marginTop: "9px",
													cursor: "pointer",
													height: "23px",
												}}
												onClick={() =>
													handleRemoveEmail(index)
												}
											>
												{CloseIcon(20, 20)}
											</Box>
										)}
								</Box>
							</Box>
						</Stack>
					))}

					{!isEmailValueLimitExceeded && (
						<Stack width="78%" alignItems="flex-start">
							<TextButton
								size="small"
								startIcon={AddIcon(
									18,
									18,
									theme.palette.secondary.contrastText
								)}
								onClick={() => handleAddEmail()}
							>
								Add
							</TextButton>
						</Stack>
					)}
				</Stack>

				<Stack
					direction="row"
					spacing={2}
					justifyContent="end"
					width="90%"
					pt={2}
				>
					<Box display="flex">
						<Button
							variant="contained"
							color="secondary"
							disableElevation
							onClick={onClose}
							disabled={isSaving}
						>
							Cancel
						</Button>
					</Box>

					<Box display="flex">
						<Button
							variant="contained"
							color="primary"
							disableElevation
							disabled={isSaving}
							onClick={handleSubmit(handleSave)}
						>
							Save
						</Button>
					</Box>
				</Stack>
			</Stack>
		</>
	);
}
