import React, { useRef, useState, useEffect } from "react";
import {
	Box,
	Stack,
	useTheme,
	Typography,
	Divider,
	MenuItem,
	Button,
	Badge,
	IconButton,
} from "@mui/material";
import { MenuItemText, Tooltip, twozoStyles } from "../../styles/twozo";
import DealMenu from "./Menu";
import KanbanView from "./kanbanView";
import ListView from "./ListView";
import ManagePipelines from "./ManagePipelines";
import { default as FilterIcon } from "../../assets/icons/filter";
import { default as DropDownIcon } from "../../assets/icons/dropDownCentered";
import { default as MenuDialog } from "../Elements/Menu";
import ForecastView from "./ForecastView";
import { default as HomeIcon } from "../../assets/icons/home";
// import { getCurrentUser } from "../../api/auth/authApi";
import CustomSwipeableDrawer from "../Elements/CustomSwipeableDrawer";
import { useSearchParams } from "react-router-dom";
import { modules } from "../../utils/common/ModulesName";
import { dealViews } from "../../utils/dealUtils";
import { usePipelineList } from "../../hooks/services/deal/pipeline";
import FilterDropdown from "../Elements/Table/Filter/FilterDropdown";
import FilterDrawer from "../Elements/Table/Filter/FilterDrawer";
import { useFilterSaveViewContext } from "../Elements/Table/Filter/Context/FilterSaveViewContext";
import { useFilterDataContext } from "../Elements/Table/Filter/Context/FilterDataContext";
import ImportModuleDialog from "../Elements/ImportModuleDialog";
import { useAuth } from "../../hooks/auth";
import { PERMISSIONS } from "../../utils/Auth";

export default function DealDashboard() {
	const theme = useTheme();
	const classes = twozoStyles();
	const menuBarRef = useRef(null);
	const dealDashboardContainerRef = useRef(null);
	const [queryParams, setQueryParams] = useSearchParams();
	const [view, setView] = useState("");
	const [pipelines, setPipelines] = useState([]);
	const [openManagePipelineDialog, setOpenManagePipelineDialog] =
		useState(false);
	const [pipelineMenuElement, setPipelineMenuElement] = useState(null);
	const isPipelineMenuOpened = Boolean(pipelineMenuElement);
	const [selectedPipeline, setSelectedPipeline] = useState({});
	const moduleName = modules.DEAL;
	const [isDealImportDialogOpened, setIsDealImportDialogOpened] =
		useState(false);
	const [isDefaultPipelineTooltipOpened, setIsDefaultPipelineTooltipOpened] =
		useState(false);

	const { filterState, openSwipeableFilter, closeSwipeableFilter } =
		useFilterDataContext();

	const { saveViewState, handleOpenSaveViewAsDialog } =
		useFilterSaveViewContext();

	const { data: pipelineList, status: pipelineListStatus } =
		usePipelineList();

	const { isUserAllowedFor } = useAuth();
	const hasShareViewPermissions = isUserAllowedFor(
		PERMISSIONS.deal.shareView
	);

	useEffect(() => {
		setView(queryParams.get("view") || dealViews.kanbanView);
		closeSwipeableFilter();
	}, [closeSwipeableFilter, queryParams]);

	useEffect(() => {
		if (Array.isArray(pipelineList) && pipelineList?.length > 0) {
			setPipelines(pipelineList);
			const defaultPipeline = pipelineList.find(
				(pipeline) => pipeline?.isSelected
			);
			setSelectedPipeline(defaultPipeline);
		}
	}, [pipelineListStatus, pipelineList]);
	const [dealDashboardStartingPosition, setDealDashboardStartingPosition] =
		useState(0);

	useEffect(() => {
		if (dealDashboardContainerRef) {
			setDealDashboardStartingPosition(
				dealDashboardContainerRef.current.getBoundingClientRect().top
			);
		}
	}, [dealDashboardContainerRef]);

	const handleViewToggleButtonGroup = (type) => {
		if (type) {
			setView(type);
			setQueryParams({ view: type });
		}
		closeSwipeableFilter();
	};

	const handleCloseImportDialog = () => {
		setIsDealImportDialogOpened(false);
	};

	const onMouseOverPipelineName = (event) => {
		setIsDefaultPipelineTooltipOpened(
			event.target.scrollWidth > event.target.clientWidth
		);
	};

	const onMouseOutPipelineName = () => {
		setIsDefaultPipelineTooltipOpened(false);
	};

	const onImportClicked = () => {
		setIsDealImportDialogOpened(true);
	};

	const toggleManagePipelineDialog = () => {
		ClosePipelineMenu();
		setOpenManagePipelineDialog(
			(openManagePipelineDialog) => !openManagePipelineDialog
		);
	};

	const openPipelineMenu = (event) => {
		setPipelineMenuElement(event.currentTarget);
	};

	const ClosePipelineMenu = () => {
		setPipelineMenuElement(null);
	};

	const handleSelectedPipline = (menu) => {
		setSelectedPipeline(menu);
		setPipelineMenuElement(null);
	};

	const isPipelineSelected = (pipelineId) => {
		return selectedPipeline?.id === pipelineId;
	};

	const renderDefaultPipeline = () => {
		return (
			<>
				<Box
					style={{ cursor: "pointer" }}
					pl={1}
					onClick={openPipelineMenu}
				>
					<Stack direction="row" spacing={0.5} alignItems="center">
						<Tooltip
							open={isDefaultPipelineTooltipOpened}
							placement="top"
							title={selectedPipeline?.name}
						>
							<Typography
								fontWeight={600}
								maxWidth="200px"
								fontSize={17}
								onMouseOver={onMouseOverPipelineName}
								onMouseLeave={onMouseOutPipelineName}
								noWrap
							>
								{selectedPipeline?.name}
							</Typography>
						</Tooltip>

						{DropDownIcon(16, 16)}
					</Stack>
				</Box>

				<Divider orientation="vertical" flexItem={true} />
			</>
		);
	};

	return (
		<React.Fragment>
			<Box>
				<ImportModuleDialog
					open={isDealImportDialogOpened}
					onClose={handleCloseImportDialog}
					disableBackdropClick={true}
					moduleName={modules.DEAL}
				/>
			</Box>

			<FilterDrawer
				moduleName={moduleName}
				hasShareViewPermissions={hasShareViewPermissions}
			/>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={openManagePipelineDialog}
				onOpen={toggleManagePipelineDialog}
				onClose={toggleManagePipelineDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box
					className={classes.addDialogContainer}
					style={{ width: "90vw" }}
				>
					<ManagePipelines onClose={toggleManagePipelineDialog} />
				</Box>
			</CustomSwipeableDrawer>

			<Box>
				<MenuDialog
					minWidth="250px"
					anchorEl={pipelineMenuElement}
					open={isPipelineMenuOpened}
					onClose={ClosePipelineMenu}
					style={{
						marginTop: "4px",
					}}
				>
					<Box
						sx={{
							maxHeight: "264px",
							overflowY: "auto",
						}}
					>
						{pipelines.map((pipeline) => (
							<MenuItem
								key={pipeline.id}
								onClick={() => handleSelectedPipline(pipeline)}
								style={{ minHeight: "40px", maxWidth: "400px" }}
								selected={isPipelineSelected(pipeline?.id)}
							>
								<Stack
									width="100%"
									direction="row"
									alignItems="center"
									justifyContent="space-between"
									spacing={1}
								>
									<MenuItemText
										style={{
											whiteSpace: "normal",
											overflowWrap: "break-word",
											maxWidth: "100%",
										}}
									>
										{pipeline.name}
									</MenuItemText>

									{pipeline?.isDefault ? (
										<Box display="flex">
											<IconButton style={{ padding: 0 }}>
												{HomeIcon(16, 16)}
											</IconButton>
										</Box>
									) : null}
								</Stack>
							</MenuItem>
						))}
					</Box>

					<Divider />
					<Box pt={1}>
						<MenuItem
							onClick={toggleManagePipelineDialog}
							style={{ height: "40px", padding: "0px" }}
						>
							<MenuItemText
								pl={2}
								py={1.5}
								fontWeight={500}
								color={theme.palette.secondary.contrastText}
							>
								Manage
							</MenuItemText>
						</MenuItem>
					</Box>
				</MenuDialog>
			</Box>

			<Box
				ref={dealDashboardContainerRef}
				style={{
					height: `calc(100vh - ${dealDashboardStartingPosition}px)`,
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					style={{
						background: "#fff",
					}}
				>
					{view !== dealViews.listView ? (
						<Box
							className={classes.menuBar}
							ref={menuBarRef}
							style={{
								padding: "0px 24px 0px 20px",
								marginLeft: filterState.isSwipeableFilterOpened
									? "250px"
									: null,
								transition: filterState.isSwipeableFilterOpened
									? theme.transitions.create("margin", {
											easing: theme.transitions.easing
												.easeOut,
											duration:
												theme.transitions.duration
													.enteringScreen,
										})
									: theme.transitions.create("margin", {
											easing: theme.transitions.easing
												.sharp,
											duration:
												theme.transitions.duration
													.leavingScreen,
										}),
							}}
						>
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								height="100%"
								py={2}
							>
								<Stack
									direction="row"
									spacing={2}
									alignItems="center"
								>
									{!filterState.isSwipeableFilterOpened && (
										<Stack
											onClick={openSwipeableFilter}
											style={{ cursor: "pointer" }}
										>
											{filterState.filterCount ? (
												<Badge
													badgeContent={
														filterState.filterCount
													}
													color="primary"
												>
													{FilterIcon(
														20,
														20,
														theme.palette.primary
															.main
													)}
												</Badge>
											) : (
												<Stack>
													{FilterIcon(
														20,
														20,
														theme.palette.primary
															.main
													)}
												</Stack>
											)}
										</Stack>
									)}

									{view !== dealViews.listView ? (
										<Box>{renderDefaultPipeline()}</Box>
									) : null}

									<Divider
										orientation="vertical"
										flexItem={true}
									/>

									<FilterDropdown
										moduleName={moduleName}
										hasShareViewPermissions={
											hasShareViewPermissions
										}
									/>

									{saveViewState.isSaveViewAsButtonVisible &&
										filterState.filterCount >= 1 && (
											<Button
												variant="contained"
												color="secondary"
												disableElevation
												onClick={
													handleOpenSaveViewAsDialog
												}
											>
												Save View
											</Button>
										)}
								</Stack>

								<Box>
									<DealMenu
										menuBarRef={menuBarRef}
										view={view}
										handleViewToggleButtonGroup={
											handleViewToggleButtonGroup
										}
										importClicked={onImportClicked}
									/>
								</Box>
							</Stack>
						</Box>
					) : null}
				</Box>

				<Box
					style={{
						flex: "1 1 auto",
						minHeight: 0,
						overflowY: "hidden",
					}}
				>
					{(function () {
						switch (view) {
							case dealViews.kanbanView:
								return (
									<Box>
										<KanbanView
											menuBarRef={menuBarRef}
											selectedPipelineId={
												selectedPipeline?.id
											}
											isSwipeableFilterOpened={
												filterState.isSwipeableFilterOpened
											}
											criteria={
												filterState.filterConditions
											}
										/>
									</Box>
								);
							case dealViews.listView:
								return (
									<ListView
										handleViewToggleButtonGroup={
											handleViewToggleButtonGroup
										}
										onImportClicked={onImportClicked}
									/>
								);
							case dealViews.forecastView:
								return (
									<Box>
										<ForecastView
											menuBarRef={menuBarRef}
											selectedPipeline={
												selectedPipeline?.id
											}
											isSwipeableFilterOpened={
												filterState.isSwipeableFilterOpened
											}
											criteria={
												filterState.filterConditions
											}
										/>
									</Box>
								);
							default:
								return null;
						}
					})()}
				</Box>
			</Box>
		</React.Fragment>
	);
}
