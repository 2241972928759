import React, { useState } from "react";
import {
	Box,
	Button,
	Divider,
	Grid,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../assets/icons/close";
import CountryMenu from "./FieldComponents/CountryComponent";
import StateMenu from "./FieldComponents/StateComponent";
import LocalityMenu from "./FieldComponents/LocalityComponent";
import PhoneNumberMenu from "./FieldComponents/PhoneNumberComponent";
import { useBuyPhoneNumber } from "../../../../hooks/services/phone";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";

export default function BuyPhoneNumberForm(props) {
	const { onClose } = props;
	const theme = useTheme();

	const [phoneNumberFormValues, setPhoneNumberFormValues] = useState(null);

	const buyPhoneNumberMutation = useBuyPhoneNumber();
	const isBuyNumberButtonDisabled =
		buyPhoneNumberMutation.isLoading || !phoneNumberFormValues;

	const onFormValueChange = (fieldValue, fieldName) => {
		setPhoneNumberFormValues((phoneNumberFormValues) => {
			return {
				...phoneNumberFormValues,
				[fieldName]: fieldValue,
			};
		});
	};

	const handlePurchaseNumber = () => {
		let phoneNumberRequestData = {
			number: phoneNumberFormValues?.phoneNumber?.number,
			locationDetails: {
				stateId: phoneNumberFormValues?.phoneNumber?.state?.id,
				localityId: phoneNumberFormValues?.phoneNumber?.locality?.id,
				countryId: phoneNumberFormValues?.phoneNumber?.country?.id,
			},
		};

		buyPhoneNumberMutation.mutate(phoneNumberRequestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.buyPhoneNumber,
				});
				onClose();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack direction="row" justifyContent="space-between">
						<Typography fontWeight={600} fontSize={17}>
							Buy new number
						</Typography>

						<IconButton
							size="small"
							style={{ padding: 0 }}
							onClick={onClose}
						>
							{CloseIcon(24, 24, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<Divider />

				<Box
					p={2}
					style={{
						flex: "1 1 auto",
						minHeight: 0,
						overflowY: "auto",
					}}
				>
					<Grid
						container
						direction="row"
						alignItems="baseline"
						justifyContent="center"
						spacing={2}
					>
						<Grid item xs={12}>
							<CountryMenu
								value={phoneNumberFormValues?.country}
								onChange={onFormValueChange}
							/>
						</Grid>

						<Grid item xs={12}>
							<StateMenu
								value={phoneNumberFormValues?.state}
								onChange={onFormValueChange}
								dependentFieldsConfig={{
									countryId:
										phoneNumberFormValues?.country?.id,
								}}
							/>
						</Grid>

						<Grid item xs={12}>
							<LocalityMenu
								value={phoneNumberFormValues?.locality}
								onChange={onFormValueChange}
								dependentFieldsConfig={{
									countryId:
										phoneNumberFormValues?.country?.id,
									stateId: phoneNumberFormValues?.state?.id,
								}}
							/>
						</Grid>

						<Grid item xs={12}>
							<PhoneNumberMenu
								value={phoneNumberFormValues?.phoneNumber}
								onChange={onFormValueChange}
								dependentFieldsConfig={{
									countryCode:
										phoneNumberFormValues?.country?.code,
									countryName:
										phoneNumberFormValues?.country?.name,
									stateName:
										phoneNumberFormValues?.state?.name,
									localityName:
										phoneNumberFormValues?.locality?.name,
								}}
							/>
						</Grid>
					</Grid>
				</Box>

				<Divider />

				<Box p={2}>
					<Button
						variant="contained"
						color="primary"
						sx={{
							"&.Mui-disabled": {
								backgroundColor: theme.palette.primary.main,
								color: "rgba(255,255,255, 0.6)",
							},
						}}
						disableElevation
						disabled={isBuyNumberButtonDisabled}
						onClick={handlePurchaseNumber}
					>
						Buy Number
					</Button>
				</Box>
			</Box>
		</React.Fragment>
	);
}
