import React, { useEffect } from "react";
import dayjs from "dayjs";
import DateTimeComponent from "../DateTimeRangeComponent";

export default function ActivityEndDateComponent(props) {
	const {
		field = {},
		updateFieldValue,
		error,
		style = {},
		value,
		clearError,
		formValues,
	} = props;

	const currentDate = dayjs().startOf("day").valueOf();
	const startDateValue = formValues[field?.config?.dependsOn]?.value;

	useEffect(() => {
		if (!value?.value) {
			updateFieldValue([{ value: currentDate }], field.fieldId);
		}
	}, [currentDate, field, updateFieldValue, value]);

	const handleChangeEndTime = (value) => {
		updateFieldValue(
			[{ ...value[0], isEndTimeChanged: true }],
			field.fieldId
		);
	};

	const getParsedValue = (value) => {
		return value ? dayjs(value) : null;
	};

	const handleDisableToField = (date) => {
		if (startDateValue) {
			return dayjs(date).isBefore(getParsedValue(startDateValue), "day");
		}
		return false;
	};

	const getMaxToDateValue = (date) => {
		// the activity duration limit one month
		let startDate = new Date(date);
		let oneMonthLater = new Date(
			startDate.getFullYear(),
			startDate.getMonth() + 1,
			startDate.getDate()
		);

		return oneMonthLater ? dayjs(oneMonthLater.getTime()) : null;
	};

	return (
		<React.Fragment>
			<DateTimeComponent
				field={field}
				error={error}
				style={style}
				clearError={clearError}
				value={value ? value : { value: dayjs(new Date()) }}
				updateFieldValue={handleChangeEndTime}
				shouldDisableDate={handleDisableToField}
				shouldDisableMonth={handleDisableToField}
				shouldDisableYear={handleDisableToField}
				maxDateTime={getMaxToDateValue(startDateValue)}
			/>
		</React.Fragment>
	);
}
