import { getAnalyticsUrl } from "../apiConfig";
import { apiClient } from "../index";
import { assertError } from "../apiHelper";

export const getDashboard = async (dashboardId, fetchViewProperties = true) => {
	let requestData = getGetDashboardRequestData(
		dashboardId,
		fetchViewProperties
	);
	return await postDashboardLayoutApi(requestData);
};

export const updateDashboard = async (dashboardData) => {
	let requestData = getUpdateDashboardRequestData(dashboardData);
	return await postDashboardLayoutApi(requestData);
};

export const createDashboard = async (dashboardData) => {
	let requestData = getCreateDashboardRequestData(dashboardData);
	return await postDashboardLayoutApi(requestData);
};

export const getChartTypeList = async () => {
	let requestData = getChartTypeRequestData();
	return await postGetChartTypeListApi(requestData);
};

export const getMetrics = async (viewTypeId) => {
	let requestData = getMetricsRequestData(viewTypeId);
	return await postMetricsApi(requestData);
};

export const getXAxisMetrics = async (moduleIds) => {
	let requestData = getXAxisMetricsRequestData(moduleIds);
	return await postXAxisMetricsApi(requestData);
};

export const getDuration = async (moduleId) => {
	let requestData = getDurationRequestData(moduleId);
	return await postDurationAPI(requestData);
};

export const getChartApplyData = async (chartProperty) => {
	let requestData = getChartApplyDataRequestData(chartProperty);
	return await postChartDataApi(requestData);
};

export const getChartViewData = async (dashboardId, viewId) => {
	let requestData = getChartViewDataRequestData(dashboardId, viewId);
	return await postChartDataApi(requestData);
};

export const getDashboardListData = async (
	reportName,
	fetchHits,
	criteria,
	sort,
	limit
) => {
	let requestData = getAllDashboardListRequestData(
		fetchHits,
		criteria,
		sort,
		limit
	);
	return await postGetAllDashboardListApi(reportName, requestData);
};

export const getUnderlyingList = async (
	viewId,
	moduleId,
	viewTypeId,
	childViewId,
	selectRequest,
	params
) => {
	let requestData = getUnderlyingListRequest(
		viewId,
		moduleId,
		viewTypeId,
		childViewId,
		selectRequest,
		params
	);

	return await postGetUnderlyingListApi(requestData);
};

export const getDashboardShareData = async (dashboardId) => {
	let requestData = getGetDashboardShareRequestData(dashboardId);
	return await postGetDashboardShareApi(requestData);
};

export const getUpdateDashboardShareData = async ({
	dashboardId,
	shareData,
}) => {
	let requestData = getUpdateDashboardShareRequestData(
		dashboardId,
		shareData
	);
	return await postGetDashboardShareApi(requestData);
};

export const getFavData = async ({ dashboardId, isFavourite }) => {
	let requestData = getFavRequestData(dashboardId, isFavourite);
	return await postDashboardLayoutApi(requestData);
};

export const getMetricsFilterField = async (moduleId) => {
	let requestData = getMetricsFilterFieldRequestData(moduleId);
	return await postMetricsFilterFieldApi(requestData);
};

const postMetricsFilterFieldApi = async (requestData) => {
	let metricsFilterFieldUrl = getMetricsFilterFieldUrl();
	return postAnalyticsApi(metricsFilterFieldUrl, requestData);
};

const postDashboardLayoutApi = async (requestData) => {
	let createDashboardUrl = getDashboardLayoutUrl();
	return postAnalyticsApi(createDashboardUrl, requestData);
};

const postGetChartTypeListApi = async (requestData) => {
	let chartTypeListUrl = getChartTypeListUrl();
	return postAnalyticsApi(chartTypeListUrl, requestData);
};

const postMetricsApi = async (requestData) => {
	let metricsUrl = getMetricsUrl();
	return postAnalyticsApi(metricsUrl, requestData);
};

const postXAxisMetricsApi = async (requestData) => {
	let xAxisMetricsUrl = getXAxisMetricsUrl();
	return postAnalyticsApi(xAxisMetricsUrl, requestData);
};

const postDurationAPI = async (requestData) => {
	let durationUrl = getDurationUrl();
	return postAnalyticsApi(durationUrl, requestData);
};

const postChartDataApi = async (requestData) => {
	let chartDataUrl = getChartDataUrl();
	return postAnalyticsApi(chartDataUrl, requestData);
};

const postGetAllDashboardListApi = async (reportName, requestData) => {
	let allDashboardListUrl = getAllDashboardListUrl(reportName);
	return postAnalyticsApi(allDashboardListUrl, requestData);
};

const postGetUnderlyingListApi = async (requestData) => {
	let underlyingListUrl = getUnderlyingListUrl();
	return postAnalyticsApi(underlyingListUrl, requestData);
};

const postGetDashboardShareApi = async (requestData) => {
	let dashboardShareUrl = getDashboardShareUrl();
	return postAnalyticsApi(dashboardShareUrl, requestData);
};

const postAnalyticsApi = async (requestUrl, requestData) => {
	let response = await apiClient.post(requestUrl, requestData);
	assertError(response, requestUrl);

	return response.data.data;
};

const getFavRequestData = (dashboardId, isFavourite) => {
	return {
		type: "fav",
		data: {
			dashboardId: dashboardId,
			isFavourite: isFavourite,
		},
	};
};

const getGetDashboardRequestData = (dashboardId, fetchViewProperties) => {
	return {
		type: "get",
		data: {
			dashboardId: dashboardId,
			fetchViewProperties: fetchViewProperties,
		},
	};
};

const getCreateDashboardRequestData = (dashboardData) => {
	return {
		type: "create",
		data: dashboardData,
	};
};

const getUpdateDashboardRequestData = (dashboardData) => {
	return {
		type: "update",
		data: dashboardData,
	};
};

const getChartTypeRequestData = () => {
	return {
		type: "list",
		data: {},
	};
};

const getMetricsRequestData = (viewTypeId) => {
	return {
		type: "get",
		data: {
			viewTypeId: viewTypeId,
		},
	};
};

const getXAxisMetricsRequestData = (moduleIds) => {
	return {
		type: "get",
		data: {
			moduleIds: moduleIds,
		},
	};
};

const getDurationRequestData = (moduleId) => {
	return {
		type: "get",
		data: {
			moduleId: moduleId,
		},
	};
};

const getChartApplyDataRequestData = (chartProperty) => {
	return {
		type: "apply",
		data: {
			...chartProperty,
		},
	};
};

const getChartViewDataRequestData = (dashboardId, viewId) => {
	return {
		type: "get",
		data: {
			dashboardId: dashboardId,
			viewId: viewId,
		},
	};
};

const getAllDashboardListRequestData = (fetchHits, criteria, sort, limit) => {
	return {
		fetchHits: fetchHits,
		criteria: criteria,
		sort: sort,
		limit: limit,
	};
};

const getMetricsFilterFieldRequestData = (moduleId) => {
	return {
		type: "get",
		data: {
			moduleId: moduleId,
		},
	};
};

const getUnderlyingListRequest = (
	viewId,
	moduleId,
	viewTypeId,
	childViewId,
	selectRequest,
	params
) => {
	return {
		type: "get",
		data: {
			viewId: viewId,
			moduleId: moduleId,
			viewTypeId: viewTypeId,
			childViewId: childViewId,
			select: selectRequest,
			limit: {
				...params,
			},
		},
	};
};

const getGetDashboardShareRequestData = (dashboardId) => {
	return {
		type: "get",
		data: {
			dashboardId: dashboardId,
		},
	};
};

const getUpdateDashboardShareRequestData = (dashboardId, shareData) => {
	return {
		type: "update",
		data: {
			dashboardId: dashboardId,
			...shareData,
		},
	};
};

const getMetricsFilterFieldUrl = () => {
	return getAnalyticsUrl() + "/filter-field";
};

const getDashboardLayoutUrl = () => {
	return getAnalyticsUrl() + "/dashboard-layout";
};

const getChartTypeListUrl = () => {
	return getAnalyticsUrl() + "/view-layout/list";
};

const getMetricsUrl = () => {
	return getAnalyticsUrl() + "/metrics";
};

const getXAxisMetricsUrl = () => {
	return getAnalyticsUrl() + "/x-axis";
};

const getDurationUrl = () => {
	return getAnalyticsUrl() + "/duration";
};

const getChartDataUrl = () => {
	return getAnalyticsUrl() + "/view-data";
};

const getUnderlyingListUrl = () => {
	return getAnalyticsUrl() + "/underlying";
};

const getDashboardShareUrl = () => {
	return getAnalyticsUrl() + "/dashboard/share";
};

const getAllDashboardListUrl = (reportName) => {
	switch (reportName) {
		case "favourites":
			return getAnalyticsUrl() + "/fav/list";
		case "all":
			return getAnalyticsUrl() + "/reports/list";
		case "curated":
			return getAnalyticsUrl() + "/curated/list";
		case "private":
			return getAnalyticsUrl() + "/private/list";
		case "shared":
			return getAnalyticsUrl() + "/shared/list";
		case "owned":
			return getAnalyticsUrl() + "/owned/list";
		case "trash":
			return getAnalyticsUrl() + "/trash/list";
		default:
			return null;
	}
};
