import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	ToggleButton,
	Typography,
	useTheme,
} from "@mui/material";
import { default as ShareIcon } from "../../../../assets/icons/share";
import Dialog from "../../../Elements/Dialog";
import ToggleButtonGroup from "../../../Elements/ToggleButtonGroup";
import { hexToRgba } from "../../../../styles/twozo";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { default as UserIcon } from "../../../../assets/icons/user";
import { default as TeamsIcon } from "../../../../assets/icons/teams";
import { default as LocationIcon } from "../../../../assets/icons/location";
import {
	useGetDashboardShare,
	useUpdateDashboardShare,
} from "../../../../hooks/services/analytics";
import AccessMenu from "./AccessMenu";
import SearchUsers from "./SearchUsers";
import { useCurrentUserDetails } from "../../../../hooks/services/userProfile";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import {
	access,
	accessName,
	checkPermission,
	getMaximumPermissionLevel,
	visibility,
} from "./Utils";
import ConfirmDialog from "../../../Elements/ConfirmDialog";

const TableHeading = styled(Typography)(() => ({
	fontSize: "14px",
	fontWeight: 400,
	opacity: 0.6,
}));

const TableCellText = styled(Typography)(() => ({
	fontSize: "15px",
	fontWeight: 400,
}));

const getDefaultShareWithData = (user, userPermission) => {
	return {
		teams: [],
		territories: [],
		everyone: {
			isVisible: false,
		},
		visibilityId: 2,
		users: [
			{
				access: userPermission,
				name: user.firstName,
				id: user.id,
				isCurrentUser: true,
			},
		],
	};
};

export default function Share(props) {
	const { userPermission } = props;
	const theme = useTheme();
	let { dashboardId } = useParams();
	const [openShareDialog, setOpenShareDialog] = useState(false);
	const [updatedShareData, setUpdatedShareData] = useState({});
	const [isOwnerDialogOpen, setIsOwnerDialogOpen] = useState(false);
	const [isMakeOwnerEnabled, setIsMakeOwnerEnabled] = useState(false);
	const [newOwnerIndex, setNewOwnerIndex] = useState(null);

	const updateShareDashboardMutation = useUpdateDashboardShare(dashboardId);
	const { data: shareData, isLoading: isShareDataLoading } =
		useGetDashboardShare(dashboardId);
	const { data: userDetails } = useCurrentUserDetails();

	useEffect(() => {
		if (!isShareDataLoading) {
			setUpdatedShareData(shareData);
		}
	}, [shareData, isShareDataLoading]);

	const handleOpenShareDialog = () => {
		setUpdatedShareData(shareData);
		setOpenShareDialog(true);
	};

	const handleCloseShareDialog = () => {
		setOpenShareDialog(false);
	};

	const handleShareVisibility = (_, type) => {
		if (type) {
			// Check share data has share with data schema
			if (type === visibility.sharedWith && !updatedShareData.users) {
				setUpdatedShareData(
					getDefaultShareWithData(userDetails.user, userPermission)
				);
			} else {
				setUpdatedShareData((shareData) => ({
					...shareData,
					visibilityId: type,
				}));
			}
		}
	};

	const handleEveryoneChange = (_, checked) => {
		if (checked) {
			setUpdatedShareData((shareData) => ({
				...shareData,
				everyone: {
					isVisible: true,
					access: 1,
				},
			}));
		} else {
			setUpdatedShareData((shareData) => ({
				...shareData,
				everyone: {
					isVisible: false,
				},
			}));
		}
	};

	const handleSave = () => {
		let shareData = { ...updatedShareData };

		// Remove share with data if visibility is just me
		if (shareData.visibilityId === visibility.justMe) {
			shareData = {
				visibilityId: visibility.justMe,
			};
		} else {
			if (!shareData.everyone.isVisible) {
				delete shareData.everyone;
			}
		}

		updateShareDashboardMutation.mutate(
			{ dashboardId: dashboardId, shareData: shareData },
			{
				onSuccess: () => {
					handleCloseShareDialog();
					enqueueSnackbar({
						variant: notificationVariants.success,
						message:
							notificationMessage.DashboardSharedSuccessfully,
					});
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.genericErrorMessage,
					});
				},
			}
		);
	};

	const updateEveryoneAccess = (access) => {
		setUpdatedShareData((shareData) => {
			const updatedShareData = { ...shareData };
			updatedShareData["everyone"]["access"] = access;
			return updatedShareData;
		});
	};

	const updateAccess = (type, index, access) => {
		setUpdatedShareData((shareData) => {
			const updatedShareData = { ...shareData };
			updatedShareData[type][index]["access"] = access;
			return updatedShareData;
		});
	};

	const changeOwner = () => {
		setUpdatedShareData((shareData) => {
			const updatedShareData = { ...shareData };
			updatedShareData["users"][newOwnerIndex]["access"] = access.owner;
			return updatedShareData;
		});
		handleCloseOwnerDialog();
	};

	const addUser = (userType, userData) => {
		setUpdatedShareData((shareData) => {
			const updatedShareData = { ...shareData };
			if (!updatedShareData[userType]) {
				updatedShareData[userType] = [];
			}

			updatedShareData[userType].push({
				...userData,
				access: 1,
			});
			return updatedShareData;
		});
	};

	const removeUser = (userType, userId) => {
		setUpdatedShareData((shareData) => {
			const updatedShareData = { ...shareData };
			updatedShareData[userType] = updatedShareData[userType].filter(
				(user) => user.id !== userId
			);
			return updatedShareData;
		});
	};

	const handleOpenOwnerDialog = (userId) => {
		const newOwnerIndex = updatedShareData.users.findIndex(
			(user) => user.id === userId
		);
		setNewOwnerIndex(newOwnerIndex);
		setIsOwnerDialogOpen(true);
	};

	const handleCloseOwnerDialog = () => {
		setIsOwnerDialogOpen(false);
		setIsMakeOwnerEnabled(false);
		setNewOwnerIndex(null);
	};

	const changeMakeOwner = (event) => {
		const { checked } = event.target;
		setIsMakeOwnerEnabled(checked);
	};

	return (
		<React.Fragment>
			<ConfirmDialog
				open={isOwnerDialogOpen}
				title="Make this person the owner?"
				subtitle="The new owner could remove you. You also might lose the ability to change settings of the report."
				childTitle={
					<Stack direction="row" spacing={1.5} alignItems="center">
						<Box display="flex">
							<Checkbox
								checked={isMakeOwnerEnabled}
								onChange={changeMakeOwner}
							/>
						</Box>

						<Typography fontSize={15} fontWeight={400}>
							I understand that this action cannot be undone
						</Typography>
					</Stack>
				}
				onCancel={handleCloseOwnerDialog}
				onConfirm={changeOwner}
				confirmButtonText="Downgrade"
				cancelButtonText="Cancel"
				confirmButtonColor="error"
				disableConfirmButton={!isMakeOwnerEnabled}
			/>

			<Dialog open={openShareDialog} title="Share Dashboard">
				<Box p={2}>
					<Box>
						<Typography
							fontSize="14px"
							fontWeight="500"
							pl={1}
							pb={0.25}
						>
							Visible To
						</Typography>

						<ToggleButtonGroup
							value={
								updatedShareData.visibilityId ||
								visibility["justMe"]
							}
							onChange={handleShareVisibility}
						>
							<ToggleButton
								value={visibility["justMe"]}
								disabled={
									!checkPermission(
										access.owner,
										userPermission
									)
								}
							>
								Just me
							</ToggleButton>

							<ToggleButton value={visibility["sharedWith"]}>
								Shared with!
							</ToggleButton>
						</ToggleButtonGroup>
					</Box>

					{updatedShareData.visibilityId ===
						visibility["sharedWith"] && (
						<Stack
							sx={{
								mt: 1.5,
								backgroundColor: hexToRgba(
									theme.palette.primary.main,
									0.12
								),
								borderRadius: "8px",
								p: "6px",
								pt: "12px",
							}}
						>
							<SearchUsers
								addUser={addUser}
								addedUsers={updatedShareData.users || []}
								addedTeams={updatedShareData.teams || []}
								addedTerritories={
									updatedShareData.territories || []
								}
							/>

							<Box pt={0.5}>
								<Table size="small" sx={{ width: "100%" }}>
									<TableHead>
										<TableRow>
											<TableCell
												style={{ width: "10%" }}
											></TableCell>

											<TableCell>
												<TableHeading>
													Users / Teams / Territories
												</TableHeading>
											</TableCell>

											<TableCell style={{ width: "30%" }}>
												<TableHeading>
													Access
												</TableHeading>
											</TableCell>
										</TableRow>
									</TableHead>

									<TableBody
										sx={{
											"& tr:last-child td, & tr:last-child th":
												{ border: 0 },
										}}
									>
										<TableRow>
											<TableCell
												style={{ textAlign: "center" }}
											>
												<Checkbox
													checked={
														updatedShareData
															.everyone.isVisible
													}
													onChange={
														handleEveryoneChange
													}
												/>
											</TableCell>

											<TableCell>
												<TableCellText>
													Everyone
												</TableCellText>
											</TableCell>

											<TableCell>
												{updatedShareData.everyone
													.isVisible ? (
													<AccessMenu
														userAccess={
															updatedShareData
																.everyone.access
														}
														maximumPermissionLevel={getMaximumPermissionLevel(
															userPermission,
															3
														)}
														updateAccess={
															updateEveryoneAccess
														}
														canRemove={false}
													/>
												) : (
													<TableCellText>
														-
													</TableCellText>
												)}
											</TableCell>
										</TableRow>

										{updatedShareData.users?.map(
											(user, index) => (
												<TableRow key={index}>
													<TableCell
														style={{
															textAlign: "center",
														}}
													>
														<Stack
															direction="row"
															justifyContent="center"
														>
															{UserIcon(
																18,
																18,
																"rgba(0, 0, 0, 0.6)"
															)}
														</Stack>
													</TableCell>

													<TableCell>
														<TableCellText>
															{user.name}{" "}
															<span
																style={{
																	display:
																		user.isCurrentUser
																			? "inline"
																			: "none",
																	color: "rgba(0, 0, 0, 0.6)",
																}}
															>
																(You)
															</span>
														</TableCellText>
													</TableCell>

													<TableCell>
														{user.isCurrentUser ? (
															<TableCellText>
																{
																	accessName[
																		user
																			.access
																	]
																}
															</TableCellText>
														) : (
															<AccessMenu
																userAccess={
																	user.access
																}
																updateAccess={(
																	access
																) =>
																	updateAccess(
																		"users",
																		index,
																		access
																	)
																}
																makeOwner={() =>
																	handleOpenOwnerDialog(
																		user.id
																	)
																}
																removeUser={() =>
																	removeUser(
																		"users",
																		user.id
																	)
																}
																maximumPermissionLevel={
																	userPermission
																}
															/>
														)}
													</TableCell>
												</TableRow>
											)
										)}

										{updatedShareData.teams?.map(
											(team, index) => (
												<TableRow key={index}>
													<TableCell
														style={{
															textAlign: "center",
														}}
													>
														<Stack
															direction="row"
															justifyContent="center"
														>
															{TeamsIcon(
																18,
																18,
																"rgba(0, 0, 0, 0.6)"
															)}
														</Stack>
													</TableCell>

													<TableCell>
														<TableCellText>
															{team.name}{" "}
															<span
																style={{
																	color: "rgba(0, 0, 0, 0.6)",
																}}
															>
																- {team.count}{" "}
																Users
															</span>
														</TableCellText>
													</TableCell>

													<TableCell>
														<TableCellText>
															<AccessMenu
																userAccess={
																	team.access
																}
																updateAccess={(
																	access
																) =>
																	updateAccess(
																		"teams",
																		index,
																		access
																	)
																}
																removeUser={() =>
																	removeUser(
																		"teams",
																		team.id
																	)
																}
																maximumPermissionLevel={getMaximumPermissionLevel(
																	userPermission,
																	3
																)}
															/>
														</TableCellText>
													</TableCell>
												</TableRow>
											)
										)}

										{updatedShareData.territories?.map(
											(territory, index) => (
												<TableRow key={index}>
													<TableCell
														style={{
															textAlign: "center",
														}}
													>
														<Stack
															direction="row"
															justifyContent="center"
														>
															{LocationIcon(
																18,
																18,
																"rgba(0, 0, 0, 0.6)"
															)}
														</Stack>
													</TableCell>

													<TableCell>
														<TableCellText>
															{territory.name}{" "}
															<span
																style={{
																	color: "rgba(0, 0, 0, 0.6)",
																}}
															>
																-{" "}
																{
																	territory.count
																}{" "}
																Users
															</span>
														</TableCellText>
													</TableCell>

													<TableCell>
														<TableCellText>
															<AccessMenu
																userAccess={
																	territory.access
																}
																updateAccess={(
																	access
																) =>
																	updateAccess(
																		"territories",
																		index,
																		access
																	)
																}
																removeUser={() =>
																	removeUser(
																		"territories",
																		territory.id
																	)
																}
																maximumPermissionLevel={getMaximumPermissionLevel(
																	userPermission,
																	3
																)}
															/>
														</TableCellText>
													</TableCell>
												</TableRow>
											)
										)}
									</TableBody>
								</Table>
							</Box>
						</Stack>
					)}

					<Stack
						direction="row"
						alignItems="center"
						justifyContent="flex-end"
						spacing={2}
						pt={3}
					>
						<Button
							variant="contained"
							color="secondary"
							disableElevation
							onClick={handleCloseShareDialog}
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							color="primary"
							disableElevation
							onClick={handleSave}
						>
							Save
						</Button>
					</Stack>
				</Box>
			</Dialog>

			<Button
				variant="contained"
				color="secondary"
				startIcon={ShareIcon(20, 20, theme.palette.primary.main)}
				disableElevation
				onClick={handleOpenShareDialog}
			>
				Share
			</Button>
		</React.Fragment>
	);
}
