import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import {
	getAssignedUserKey,
	getRolesListKey,
	getRolesTableDataKey,
	getRolesUserListKey,
	getRoleTableDataWithSortKey,
} from "../../../../utils/queryKeys/userManagement/roles";
import {
	createRole,
	deleteRole,
	getRolesList,
	getRolesTableData,
	getRolesUserList,
	linkUserToRole,
	updateRole,
	updateRoleDetails,
	getAssignedUserList,
} from "../../../../api/userManagement/roles/rolesApi";
import {
	assignedUserPageSize,
	roleDropdownListPageSize,
} from "../../../../utils/queryConstants/userManagement/Role";
import { roleListPageSize } from "../../../../utils/queryConstants/userManagement/Role";

const selectUsers = (assignedUserDetails) => {
	return assignedUserDetails.pages.flatMap((page) => page?.role?.users || []);
};

const useRoleTableData = (fieldToSort, enabled) => {
	return useInfiniteQuery({
		queryKey: getRoleTableDataWithSortKey(fieldToSort),
		queryFn: ({ pageParam }) => {
			return getRolesTableData(fieldToSort, {
				start: pageParam ? (pageParam - 1) * roleListPageSize + 1 : 1,
				limit: roleListPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		enabled: enabled,
	});
};

const roleSelect = (roles) => {
	return roles.pages.flatMap((page) => page?.options);
};

const useRolesList = (searchValue) => {
	return useInfiniteQuery({
		queryKey: getRolesListKey(searchValue),
		queryFn: ({ pageParam }) => {
			return getRolesList(
				{
					start: pageParam
						? (pageParam - 1) * roleDropdownListPageSize + 1
						: 1,
					limit: roleDropdownListPageSize,
				},
				searchValue
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: roleSelect,
	});
};

const useAssginedUserDetails = (id, enabled) => {
	return useInfiniteQuery({
		queryKey: getAssignedUserKey(id),
		queryFn: ({ pageParam }) => {
			return getAssignedUserList(id, {
				start: pageParam
					? (pageParam - 1) * assignedUserPageSize + 1
					: 1,
				limit: assignedUserPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		enabled: enabled,
		select: selectUsers,
	});
};

const useCreateRole = () => {
	let invalidateRoleList = useInvalidateRoleList();
	return useMutation(createRole, {
		onSuccess: () => {
			invalidateRoleList();
		},
	});
};

const useInvalidateRoleList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getRolesTableDataKey());
	};
};

const useUpdateRoleMutation = () => {
	let invalidateRoleList = useInvalidateRoleList();
	return useMutation(updateRole, {
		onSuccess: () => {
			invalidateRoleList();
		},
	});
};

const useLinkUserMutation = () => {
	let invalidateRoleList = useInvalidateRoleList();
	return useMutation(linkUserToRole, {
		onSuccess: () => {
			invalidateRoleList();
		},
	});
};

const useUpdateRoleDetails = () => {
	let invalidateRoleList = useInvalidateRoleList();
	return useMutation(updateRoleDetails, {
		onSuccess: () => {
			invalidateRoleList();
		},
	});
};

const useDeleteRole = () => {
	let invalidateRoleList = useInvalidateRoleList();
	return useMutation(deleteRole, {
		onSuccess: () => {
			invalidateRoleList();
		},
	});
};

const useRolesUserList = (id) => {
	let rolesUserListKey = getRolesUserListKey(id);
	return useQuery(rolesUserListKey, () => getRolesUserList(id), {
		select: (data) => data?.users,
		enabled: !!id,
	});
};

export {
	useRolesList,
	useUpdateRoleMutation,
	useLinkUserMutation,
	useRolesUserList,
	useRoleTableData,
	useCreateRole,
	useUpdateRoleDetails,
	useDeleteRole,
	useAssginedUserDetails,
};
