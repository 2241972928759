import React from "react";
import { Typography } from "@mui/material";

const styles = {
	highlightedText: {
		fontSize: "15px",
		fontWeight: 500,
	},
};

export default function SalesOwnerReassigned(props) {
	const { eventData } = props;

	if (!eventData) return;

	return (
		<React.Fragment>
			<Typography style={styles.text} pt={1.3}>
				<span style={styles.highlightedText}>
					{eventData?.salesOwner?.name}
				</span>{" "}
				reassigned the sales owner from{" "}
				<span style={styles.highlightedText}>
					{eventData?.oldValue}
				</span>{" "}
				to{" "}
				<span style={styles.highlightedText}>
					{eventData?.newValue}
				</span>
			</Typography>
		</React.Fragment>
	);
}
