import {
	emailsIds,
	shareEmailTemplatesFolderPermissions,
	type,
	viewEmailConversationPermissions,
} from "..";

export const getEmailConfigForPermission = (permissions) => {
	return [
		{
			id: emailsIds.viewEmailConversation,
			name: "View Email Conversation",
			access: permissions.viewEmailConversation.access,
			permissions: {
				type: type.radio,
				permission: [
					{
						id: viewEmailConversationPermissions.viewAllEmails,
						name: "View All Emails",
						value:
							permissions.viewEmailConversation.scope ===
							"viewAllEmails",
					},
					{
						id: viewEmailConversationPermissions.viewOnlyPrivateEmails,
						name: "View Only Private Emails",
						value:
							permissions.viewEmailConversation.scope ===
							"viewOnlyPrivateEmails",
					},
				],
			},
		},
		{
			id: emailsIds.shareEmailTemplatesFolder,
			name: "Share Email Templates ",
			access: permissions.shareEmailTemplatesFolder.access,
			permissions: {
				type: type.checkbox,
				permission: [
					{
						id: shareEmailTemplatesFolderPermissions.everyone,
						name: "Everyone",
						value: permissions.shareEmailTemplatesFolder.everyone,
						disabled: !permissions.shareEmailTemplatesFolder.access,
					},
					{
						id: shareEmailTemplatesFolderPermissions.myTeams,
						name: "My Teams",
						value: permissions.shareEmailTemplatesFolder.myTeams,
						disabled: !permissions.shareEmailTemplatesFolder.access,
					},
					{
						id: shareEmailTemplatesFolderPermissions.myTerritories,
						name: "My Territories",
						value: permissions.shareEmailTemplatesFolder
							.myTerritories,
						disabled: !permissions.shareEmailTemplatesFolder.access,
					},
				],
			},
		},
		{
			id: emailsIds.bulkEmailLimit,
			name: "Bulk Email Limit",
			access: permissions.bulkEmailLimit.access,
			permissions: {
				type: type.input,
				id: "permittedLimit",
				value: permissions.bulkEmailLimit.permittedLimit,
				placeHolder: "Emails Per Day",
				noDataMessage: "Cannot send bulk emails",
			},
		},
		{
			id: emailsIds.individualEmailLimit,
			name: "Individual Email Limit",
			access: permissions.individualEmailLimit.access,
			permissions: {
				type: type.input,
				id: "permittedLimit",
				value: permissions.individualEmailLimit.permittedLimit,
				placeHolder: "Emails Per Day",
				noDataMessage: "Cannot send emails",
			},
		},
	];
};
