import React from "react";
import AddTerritoryDialog from "../AddTerritoryDialog";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import {
	useTerritoryData,
	useUpdateTerritory,
} from "../../../../../hooks/services/userManagement/territory";
import { CircularProgress, Stack } from "@mui/material";

export default function EditTerritory(props) {
	const { onClose, territoryId } = props;

	const { data: territoryData, isLoading: isLoadingTerritoryData } =
		useTerritoryData(territoryId);
	const updateTerritoryMutation = useUpdateTerritory(territoryId);

	const updateTerritory = (territoryData) => {
		updateTerritoryMutation.mutate(territoryData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.territoryUpdated,
				});
				onClose();
			},
		});
	};

	return (
		<React.Fragment>
			{isLoadingTerritoryData ? (
				<Stack
					height="90vh"
					justifyContent="center"
					alignItems="center"
				>
					<CircularProgress />
				</Stack>
			) : (
				<AddTerritoryDialog
					title="Edit Territory"
					onClose={onClose}
					isEditMode={true}
					onUpdate={updateTerritory}
					territoryData={territoryData}
					isSaving={updateTerritoryMutation.isLoading}
				/>
			)}
		</React.Fragment>
	);
}
