import React, { useState } from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useAddTags } from "../../../hooks/services/tags";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import Dialog from "../../Elements/Dialog";
import { useModuleName } from "../../../hooks/modules";
import TagsInput from "../../Elements/AddForm/Field/FieldComponents/TagsComponent/TagsBaseComponent/TagsInput";

export default function AddTags(props) {
	const {
		isAddTagsDialogOpened,
		closeAddTagsDialog,
		selectedIds,
		onTagsAdded,
		sourceName,
	} = props;
	const theme = useTheme();

	const [selectedTags, setSelectedTags] = useState([]);
	const addTagsMutation = useAddTags(sourceName);
	const { getModuleName } = useModuleName();

	const moduleName = getModuleName(sourceName).toLowerCase();

	const handleSelectTags = (values) => {
		setSelectedTags(values);
	};

	const performCancel = () => {
		closeAddTagsDialog();
		setSelectedTags([]);
	};

	const handleAddTags = () => {
		let addTagsRequestData = getAddTagsRequest();
		if (selectedTags.length !== 0) {
			addTagsMutation.mutate(addTagsRequestData, {
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.tagsAdded,
					});
					onTagsAdded();
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.somethingWentWrong,
					});
				},
			});
		}
	};

	const getAddTagsRequest = () => {
		let addTagsRequestData = {
			source: sourceName,
			tags: selectedTags?.map((tag) =>
				tag?.value ? { id: tag.value } : { tag: tag.name }
			),
			entityIds: selectedIds,
		};
		return addTagsRequestData;
	};

	const isSaveButtonDisable = () => {
		return selectedTags.length === 0;
	};

	return (
		<React.Fragment>
			<Dialog title="Add Tags" open={isAddTagsDialogOpened}>
				<Box p={2}>
					<Stack spacing={1}>
						<Typography fontSize={13}>
							Select tags to add to the selected {moduleName}
						</Typography>

						<TagsInput
							value={selectedTags}
							onChange={handleSelectTags}
						/>
					</Stack>

					<Stack
						spacing={2}
						pt={2}
						justifyContent="right"
						direction="row"
					>
						<Button
							variant="contained"
							color="secondary"
							disableElevation
							onClick={performCancel}
							disabled={addTagsMutation.isLoading}
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							disableElevation
							onClick={handleAddTags}
							disabled={
								addTagsMutation.isLoading ||
								isSaveButtonDisable()
							}
							sx={{
								"&.Mui-disabled": {
									backgroundColor: theme.palette.primary.main,
									color: "rgba(255, 255, 255, 0.6)",
								},
							}}
						>
							Save
						</Button>
					</Stack>
				</Box>
			</Dialog>
		</React.Fragment>
	);
}
