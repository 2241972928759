import React, { useEffect, useMemo, useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme,
	FormControl,
} from "@mui/material";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { default as InfoIcon } from "../../../../assets/icons/info";
import { default as AddIcon } from "../../../../assets/icons/add";
import ActivityType from "./Type";
import ContactAutoComplete from "../../../Elements/ContactAutoComplete";
import { useQueryClient } from "@tanstack/react-query";
import { getIconByName } from "../../../../utils";
import { removeFieldsWithEmptyValues } from "../../../../utils/common";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { Controller, useForm } from "react-hook-form";
import { getDropdownDataByName } from "../../../../utils/dropdownData";
import GuestComponent from "./GuestComponent";
import { useCreateActivity } from "../../../../hooks/services/activities";
import { useUsers } from "../../../../hooks/account/user";
import { useDealRelatedEntitiesData } from "../../../../hooks/services/deal";
import Collaborator from "./CollaboratorComponent";
import { FormFieldName, MenuItemText, Tooltip } from "../../../../styles/twozo";
import { useActivityTypeList } from "../../../../hooks/services/activityType";
import { useAuth } from "../../../../hooks/auth";
import { PERMISSIONS } from "../../../../utils/Auth";
import { getDealKeyById } from "../../../../utils/queryKeys";
import ActivityDateAndTime from "./ActivityDateAndTime";
import UsersMenu from "../../../Elements/UsersMenu";
import CompanyAutoComplete from "../../../Elements/CompanyAutoComplete";
import DealAutocomplete from "../../../Elements/DealAutoCompleteComponent";
import dayjs from "dayjs";

export default function AddActivity(props) {
	const {
		companyValue,
		dealData,
		contacts,
		onActivityAdded,
		activityAddedSuccessMessage,
		toDoActivityKey,
		completedActivityKey,
		isActivityEditMode,
		activityFormData,
		onUpdate,
		updateInProgress,
		onClose,
		isAddActivityFromListView,
		openAddActivityDialog,
	} = props;

	const theme = useTheme();
	const queryClient = useQueryClient();

	const currentDate = dayjs.tz().valueOf();

	const activityStatusOptions = getDropdownDataByName("AVAILABILITY");
	const priorityOptions = getDropdownDataByName("PRIORITY");
	const reminderOptions = getDropdownDataByName("REMINDER");

	const { isUserAllowedFor } = useAuth();

	// TODO remove this once the server team implementations
	const getDefaultContactValues = () => {
		return isUserAllowedFor(PERMISSIONS.contact.view) ? contacts : [];
	};
	const dealSummaryKey = getDealKeyById(dealData?.id);

	const {
		handleSubmit,
		register,
		setValue,
		watch,
		control,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: {
			contacts: getDefaultContactValues(),
			companyData: companyValue,
			dealData: dealData,
			startTime: currentDate,
			endTime: currentDate,
			availability: activityStatusOptions[0].value,
			priority: priorityOptions[1]?.value,
			reminder: reminderOptions[0]?.value,
			...activityFormData,
		},
	});

	const [showGuests, setShowGuests] = useState(false);
	const [showLocation, setShowLocation] = useState(false);
	const [showDescription, setShowDescription] = useState(false);
	const [showRemoveGuests, setShowRemoveGuests] = useState(false);
	const [showRemoveLocation, setShowRemoveLocation] = useState(false);
	const [showRemoveDescription, setShowRemoveDescription] = useState(false);
	const [activityTypeId, setActivityTypeId] = useState("");
	const createNewActivityMutation = useCreateActivity();
	const selectedUser = watch("assignee", {});
	const selectedContacts = watch("contacts", []);

	const { isLoading: isLoadingActivityTypes, data: activityTypes } =
		useActivityTypeList();

	const defaultActivityTypeId = useMemo(() => {
		if (activityTypes?.length > 0) {
			return activityTypes?.find((activityType) => activityType?.isActive)
				?.id;
		}
	}, [activityTypes]);

	useEffect(() => {
		if (!!activityFormData && activityFormData?.typeId) {
			setActivityTypeId(activityFormData.typeId);
		} else {
			setActivityTypeId(defaultActivityTypeId);
			setValue("typeId", defaultActivityTypeId);
		}
	}, [setValue, activityFormData, defaultActivityTypeId]);

	const selectedDealData = watch("dealData", "");

	const { data: dealRelatedEntitiesData } = useDealRelatedEntitiesData(
		selectedDealData?.value
	);

	useEffect(() => {
		const selectedContacts = watch("contacts", []);
		const selectedCompany = watch("companyData", "");

		if (dealRelatedEntitiesData) {
			let dealRelatedContact = [
				{
					name: dealRelatedEntitiesData?.primaryContact?.fullName,
					value: dealRelatedEntitiesData?.primaryContact?.id,
				},
			];
			let dealRelatedCompany = dealRelatedEntitiesData?.company
				? {
						name: dealRelatedEntitiesData?.company?.name,
						value: dealRelatedEntitiesData?.company?.id,
					}
				: "";

			const shouldSetContacts =
				selectedContacts?.length === 0 &&
				(!isActivityEditMode ||
					(isActivityEditMode && selectedDealData?.value));

			const shouldSetCompany =
				!selectedCompany &&
				dealRelatedCompany &&
				(!isActivityEditMode ||
					(isActivityEditMode && selectedDealData?.value));

			if (shouldSetContacts) {
				setValue("contacts", dealRelatedContact);
			}

			if (shouldSetCompany) {
				setValue("companyData", dealRelatedCompany);
			}
		}
	}, [
		dealRelatedEntitiesData,
		watch,
		setValue,
		selectedDealData?.value,
		isActivityEditMode,
	]);

	// get activeUsers
	const { loggedInUser } = useUsers();

	useEffect(() => {
		if (activityFormData?.assignee) {
			setValue("assignee", activityFormData?.assignee);
		} else {
			setValue("assignee", loggedInUser);
		}
	}, [loggedInUser, activityFormData, setValue]);

	const getFormattedGuestData = (selectedGuests) => {
		let formattedGuests = {
			contacts: [],
			emails: [],
		};

		selectedGuests.forEach((guest) => {
			if (guest?.value === -1) {
				// if new email address
				formattedGuests.emails.push({ email: guest.email.value });
			} else {
				formattedGuests.contacts.push({
					id: guest?.value,
					emailId: guest.email?.id,
				});
			}
		});
		return formattedGuests;
	};

	const selectType = (id) => {
		setActivityTypeId(id);
		setValue("typeId", id);
	};

	const resetActivityDrawer = (activityDetails) => {
		reset({
			dealData: activityDetails?.deal
				? {
						name: activityDetails.deal?.title,
						value: activityDetails.deal?.id,
					}
				: null,
			contacts: activityDetails?.contact
				? [
						{
							name: activityDetails.contact?.name,
							value: activityDetails.contact?.id,
						},
					]
				: null,
			companyData: activityDetails?.company
				? {
						name: activityDetails.company?.name,
						value: activityDetails.company?.id,
					}
				: null,
			typeId: defaultActivityTypeId,
			startTime: currentDate,
			endTime: currentDate,
			availability: activityStatusOptions[0].value,
			priority: priorityOptions[1]?.value,
			reminder: reminderOptions[0]?.value,
			assignee: loggedInUser,
		});

		setActivityTypeId(defaultActivityTypeId);
		setShowDescription(false);
		setShowGuests(false);
		setShowLocation(false);
	};

	const onSave = (formData, shouldCloseForm) => {
		let modifiedActivityData = { ...formData };
		if (!modifiedActivityData.title) {
			modifiedActivityData.title = getActivityName();
		}

		if (modifiedActivityData?.assignee) {
			modifiedActivityData.assigneeId =
				modifiedActivityData.assignee?.value;
		}

		if (modifiedActivityData?.companyData) {
			modifiedActivityData.companyId =
				modifiedActivityData.companyData?.value;
		}

		if (modifiedActivityData?.dealData) {
			modifiedActivityData.dealId = modifiedActivityData.dealData?.value;
		}

		if (modifiedActivityData?.contacts?.length > 0) {
			modifiedActivityData.contactIds = modifiedActivityData.contacts.map(
				(contact) => contact?.value
			);
		}
		if (modifiedActivityData?.guests?.length > 0) {
			modifiedActivityData.guests = getFormattedGuestData(
				modifiedActivityData.guests
			);
		}

		delete modifiedActivityData?.assignee;
		delete modifiedActivityData?.companyData;
		delete modifiedActivityData?.dealData;
		delete modifiedActivityData?.contacts;

		removeFieldsWithEmptyValues(modifiedActivityData);
		createNewActivityMutation.mutate(modifiedActivityData, {
			onSuccess: (activityDetails) => {
				if (activityAddedSuccessMessage) {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: activityAddedSuccessMessage,
					});
				} else {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.activityAdded,
					});
					queryClient.invalidateQueries(toDoActivityKey);
					queryClient.invalidateQueries(completedActivityKey);
					queryClient.invalidateQueries(dealSummaryKey);
				}
				shouldCloseForm && !!onActivityAdded && onActivityAdded();
				shouldCloseForm && onClose();

				if (activityDetails?.isActivityCreationRequired) {
					resetActivityDrawer(activityDetails);
					setTimeout(() => {
						openAddActivityDialog();
					}, 300);
				}

				if (!shouldCloseForm) {
					reset();
					setShowDescription(false);
					setShowGuests(false);
					setShowLocation(false);
					setActivityTypeId(defaultActivityTypeId);
					setValue("typeId", defaultActivityTypeId);
					setValue("assignee", loggedInUser);
				}
			},
			onError: (error) => {
				let errorMessage = JSON.parse(error?.message);
				errorMessage?.validationErrors?.forEach((validationError) =>
					enqueueSnackbar({
						variant: notificationVariants.error,
						message:
							validationError ??
							notificationMessage.genericErrorMessage,
					})
				);
			},
		});
	};

	const handleSave = (formData) => {
		let isFormShouldClose = true;
		onSave(formData, isFormShouldClose);
	};

	const handleSaveAndNew = (formData) => {
		let shouldCloseForm = false;
		onSave(formData, shouldCloseForm);
	};

	const handleContactInputChange = (contacts) => {
		setValue("contacts", contacts);
	};

	const handleRemoveContact = (removedIndex) => {
		let removedContacts = selectedContacts.filter(
			(_, index) => index !== removedIndex
		);
		setValue("contacts", removedContacts);
	};

	const handleMarkAsDone = (isMarkAsDone) => {
		setValue("isDone", !isMarkAsDone);
	};

	const getActivityName = () => {
		let activityType = activityTypes?.find(
			(type) => type.id === activityTypeId
		);
		if (activityType) {
			return activityType.name;
		}
	};

	const handleDealChange = (value) => {
		setValue("dealData", value);
	};

	const handleCompanyChange = (value) => {
		setValue("companyData", value);
	};

	//To Update the visibility of location, guest
	useEffect(() => {
		if (activityFormData?.location) {
			setShowLocation(true);
		}

		if (activityFormData?.guests) {
			setShowGuests(true);
		}

		if (activityFormData?.description) {
			setShowDescription(true);
		}
	}, [activityFormData]);

	const handleSelectUser = (selectedUser) => {
		setValue("assignee", selectedUser);
	};

	const isLoading = isLoadingActivityTypes;

	const filteredActivityTypes = useMemo(() => {
		if (activityTypes?.length > 0) {
			return activityTypes.filter(
				(activityType) =>
					activityType?.isActive ||
					activityType?.id === activityFormData?.typeId
			);
		}
		return [];
	}, [activityFormData?.typeId, activityTypes]);

	return (
		<React.Fragment>
			{isLoading ? (
				<Stack
					height="100vh"
					alignItems="center"
					justifyContent="center"
				>
					<CircularProgress size={30} />
				</Stack>
			) : (
				<Box
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box p={2}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={2}
						>
							<Typography fontWeight={600} fontSize={17}>
								{isActivityEditMode
									? "Edit Activity"
									: "Add Activity"}
							</Typography>

							<IconButton
								onClick={props.onClose}
								size="small"
								style={{ padding: 0 }}
							>
								{CloseIcon(24, 24, theme.palette.primary.main)}
							</IconButton>
						</Stack>
					</Box>

					<Divider />

					{createNewActivityMutation.isLoading ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="100vh"
						>
							<CircularProgress size="30px" />
						</Stack>
					) : (
						<Box
							p={2}
							style={{
								flex: "1 1 auto",
								minHeight: 0,
								overflowY: "auto",
							}}
						>
							<Grid
								container
								direction="row"
								justifyContent="flex-start"
								alignItems="center"
								spacing={2}
							>
								<Grid item xs={12}>
									<Stack spacing={"6px"}>
										<FormFieldName
											color={
												!!errors.title &&
												theme.palette.error.main
											}
										>
											Title
										</FormFieldName>
										<FormControl
											fullWidth
											error={!!errors.title}
										>
											<TextField
												placeholder={getActivityName()}
												{...register("title", {
													maxLength: 255,
												})}
												error={!!errors.title}
												helperText={
													!!errors.title &&
													"The character limit was exceeded; a maximum of 255 characters are allowed."
												}
											/>
										</FormControl>
									</Stack>
								</Grid>

								<Grid item xs={12}>
									<Stack spacing={"6px"}>
										<FormFieldName>Type</FormFieldName>

										<Stack
											direction="row"
											sx={{ flexWrap: "wrap", gap: 1 }}
										>
											{filteredActivityTypes?.map(
												(type) => (
													<ActivityType
														key={type.id}
														id={type.id}
														name={type.name}
														icon={getIconByName(
															type.icon?.name
														)}
														selectType={selectType}
														selected={
															activityTypeId ===
															type.id
														}
													/>
												)
											)}
										</Stack>
									</Stack>
								</Grid>

								<Grid item xs={12}>
									<Stack spacing={"6px"}>
										<FormFieldName>
											Date and Time
										</FormFieldName>

										<Box width="100%" position="relative">
											<ActivityDateAndTime
												onStartTimeChange={(
													startTime
												) =>
													setValue(
														"startTime",
														startTime
													)
												}
												onEndTimeChange={(endTime) =>
													setValue("endTime", endTime)
												}
												startTimeValue={watch(
													"startTime"
												)}
												endTimeValue={watch("endTime")}
											/>
										</Box>
									</Stack>
								</Grid>

								<Grid item xs={12}>
									<Stack spacing={"6px"}>
										<Stack
											direction="row"
											alignItems="center"
											spacing={0.5}
										>
											<FormFieldName
												color={
													!!errors.note &&
													theme.palette.error.main
												}
											>
												Private Note
											</FormFieldName>

											<Tooltip
												title="Notes are visible within Twozo, but not to event guests."
												placement="top-start"
												PopperProps={{
													modifiers: [
														{
															name: "offset",
															options: {
																offset: [
																	-25, -5,
																],
															},
														},
													],
												}}
											>
												{InfoIcon(18, 18, "#666666")}
											</Tooltip>
										</Stack>

										<FormControl
											fullWidth
											error={!!errors.note}
										>
											<TextField
												{...register("note", {
													maxLength: 1000,
												})}
												placeholder="Private Note"
												multiline
												rows={3}
												error={!!errors.note}
												sx={{
													"& .MuiInputBase-root": {
														backgroundColor:
															"#FFF6D6",
													},
												}}
												helperText={
													!!errors.note &&
													"The character limit was exceeded; a maximum of 1000 characters are allowed."
												}
											/>
										</FormControl>
									</Stack>
								</Grid>

								<Grid item xs={12}>
									<Stack spacing={"6px"}>
										<FormFieldName>
											Sales Owner
										</FormFieldName>

										<UsersMenu
											value={selectedUser}
											onChange={handleSelectUser}
										/>
									</Stack>
								</Grid>

								<Grid item xs={12}>
									<Stack spacing={"6px"}>
										<FormFieldName>
											Collaborators
										</FormFieldName>

										<Controller
											name="collaborators"
											control={control}
											render={({
												field: { value, onChange },
											}) => (
												<Collaborator
													value={value}
													onChange={onChange}
												/>
											)}
										/>
									</Stack>
								</Grid>

								<Grid item xs={12}>
									<Stack spacing={"6px"}>
										<FormFieldName>
											Availability
										</FormFieldName>

										<Select
											{...register("availability", {
												onChange: (event) =>
													setValue(
														"availability",
														event.target.value
													),
											})}
											value={watch("availability") || ""}
										>
											{activityStatusOptions?.map(
												(status) => (
													<MenuItem
														value={
															status.value || ""
														}
														key={status.value}
													>
														<MenuItemText>
															{status.name}
														</MenuItemText>
													</MenuItem>
												)
											)}
										</Select>
									</Stack>
								</Grid>

								<Grid item xs={12}>
									<Stack spacing={"6px"}>
										<FormFieldName>Reminder</FormFieldName>

										<Select
											{...register("reminder", {
												onChange: (event) =>
													setValue(
														"reminder",
														event.target.value
													),
											})}
											value={watch("reminder") || ""}
										>
											{reminderOptions?.map(
												(reminder) => (
													<MenuItem
														value={
															reminder.value || ""
														}
														key={reminder.value}
													>
														<MenuItemText>
															{reminder.name}
														</MenuItemText>
													</MenuItem>
												)
											)}
										</Select>
									</Stack>
								</Grid>

								<Grid item xs={12}>
									<Stack spacing={"6px"}>
										<FormFieldName>Priority</FormFieldName>

										<Select
											{...register("priority", {
												onChange: (event) =>
													setValue(
														"priority",
														event.target.value
													),
											})}
											value={watch("priority") || ""}
										>
											{priorityOptions?.map(
												(priority) => (
													<MenuItem
														value={
															priority.value || ""
														}
														key={priority.value}
													>
														<MenuItemText>
															{priority.name}
														</MenuItemText>
													</MenuItem>
												)
											)}
										</Select>
									</Stack>
								</Grid>

								<Grid
									item
									xs={12}
									hidden={
										showGuests &&
										showLocation &&
										showDescription
									}
								>
									<Stack direction="row" spacing={1}>
										{!showGuests && (
											<Button
												variant="contained"
												color="secondary"
												startIcon={AddIcon(
													16,
													16,
													theme.palette.secondary
														.contrastText
												)}
												onClick={() =>
													setShowGuests(true)
												}
												disableElevation
											>
												Guests
											</Button>
										)}

										{!showLocation && (
											<Button
												variant="contained"
												color="secondary"
												startIcon={AddIcon(
													16,
													16,
													theme.palette.secondary
														.contrastText
												)}
												onClick={() =>
													setShowLocation(true)
												}
												disableElevation
											>
												Location
											</Button>
										)}

										{!showDescription && (
											<Button
												variant="contained"
												color="secondary"
												startIcon={AddIcon(
													16,
													16,
													theme.palette.secondary
														.contrastText
												)}
												onClick={() =>
													setShowDescription(true)
												}
												disableElevation
											>
												Description
											</Button>
										)}
									</Stack>
								</Grid>

								<Grid item xs={12} hidden={!showGuests}>
									<Stack spacing={"6px"}>
										<FormFieldName>Guests</FormFieldName>

										<Stack
											direction="row"
											alignItems="center"
											justifyContent="space-between"
											spacing={1}
											onMouseOver={() =>
												setShowRemoveGuests(true)
											}
											onMouseOut={() =>
												setShowRemoveGuests(false)
											}
										>
											<Controller
												name="guests"
												control={control}
												render={({
													field: { value, onChange },
												}) => (
													<GuestComponent
														value={value || []}
														onGuestChange={onChange}
													/>
												)}
											/>

											{showRemoveGuests && (
												<IconButton
													size="small"
													onClick={() => {
														setShowGuests(false);
														setValue("guests", "");
													}}
												>
													{CloseIcon(
														24,
														24,
														"#666666"
													)}
												</IconButton>
											)}
										</Stack>
									</Stack>
								</Grid>

								<Grid item xs={12} hidden={!showLocation}>
									<Stack spacing={"6px"}>
										<FormFieldName
											color={
												!!errors.location &&
												theme.palette.error.main
											}
										>
											Location
										</FormFieldName>

										<Stack
											direction="row"
											alignItems="center"
											justifyContent="space-between"
											spacing={1}
											onMouseOver={() =>
												setShowRemoveLocation(true)
											}
											onMouseOut={() =>
												setShowRemoveLocation(false)
											}
										>
											<FormControl
												fullWidth
												error={!!errors.location}
											>
												<TextField
													{...register("location", {
														maxLength: 255,
													})}
													placeholder="Location"
													name="location"
													fullWidth
													error={!!errors.location}
													helperText={
														!!errors.location &&
														"The character limit was exceeded; a maximum of 255 characters are allowed."
													}
												/>
											</FormControl>

											{showRemoveLocation && (
												<IconButton
													size="small"
													onClick={() => {
														setShowLocation(false);
														setValue(
															"location",
															""
														);
													}}
												>
													{CloseIcon(
														24,
														24,
														"#666666"
													)}
												</IconButton>
											)}
										</Stack>
									</Stack>
								</Grid>

								<Grid item xs={12} hidden={!showDescription}>
									<Stack spacing={"6px"}>
										<FormFieldName
											color={
												!!errors.description &&
												theme.palette.error.main
											}
										>
											Description
										</FormFieldName>

										<Stack
											direction="row"
											alignItems="center"
											justifyContent="space-between"
											spacing={1}
											onMouseOver={() =>
												setShowRemoveDescription(true)
											}
											onMouseOut={() =>
												setShowRemoveDescription(false)
											}
										>
											<FormControl
												fullWidth
												error={!!errors.description}
											>
												<TextField
													{...register(
														"description",
														{
															maxLength: 255,
														}
													)}
													placeholder="Description"
													fullWidth
													multiline
													maxRows={4}
													error={!!errors.description}
													helperText={
														!!errors.description &&
														"The character limit was exceeded; a maximum of 255 characters are allowed."
													}
												/>
											</FormControl>
											{showRemoveDescription && (
												<IconButton
													size="small"
													onClick={() => {
														setShowDescription(
															false
														);
														setValue(
															"description",
															""
														);
													}}
												>
													{CloseIcon(
														24,
														24,
														"#666666"
													)}
												</IconButton>
											)}
										</Stack>
									</Stack>
								</Grid>

								<Grid
									item
									xs={12}
									style={{
										opacity: isAddActivityFromListView
											? 0.6
											: 1,
									}}
								>
									<Stack spacing={"6px"}>
										<FormFieldName>Deal</FormFieldName>

										<Controller
											name="dealData"
											control={control}
											defaultValue=""
											render={({ field: { value } }) => (
												<DealAutocomplete
													disabled={
														isAddActivityFromListView
													}
													value={value}
													onChange={handleDealChange}
													onCancel={() =>
														setValue("dealData", "")
													}
												/>
											)}
										/>
									</Stack>
								</Grid>

								<Grid
									item
									xs={12}
									style={{
										opacity: isAddActivityFromListView
											? 0.6
											: 1,
									}}
								>
									<Stack spacing={"6px"}>
										<FormFieldName>Contact</FormFieldName>

										<Controller
											name="contacts"
											control={control}
											render={({ field: { value } }) => (
												<ContactAutoComplete
													multiple={true}
													disabled={
														isAddActivityFromListView
													}
													value={value || []}
													onChange={
														handleContactInputChange
													}
													onCancel={
														handleRemoveContact
													}
												/>
											)}
										/>
									</Stack>
								</Grid>

								<Grid
									item
									xs={12}
									style={{
										opacity: isAddActivityFromListView
											? 0.6
											: 1,
									}}
								>
									<Stack spacing={"6px"}>
										<FormFieldName>Company</FormFieldName>

										<Controller
											name="companyData"
											control={control}
											defaultValue=""
											render={({
												field: { value, onChange },
											}) => (
												<CompanyAutoComplete
													value={value}
													onChange={
														handleCompanyChange
													}
													onCancel={() => {
														onChange("");
													}}
													disabled={
														isAddActivityFromListView
													}
												/>
											)}
										/>
									</Stack>
								</Grid>
							</Grid>
						</Box>
					)}

					<Divider />

					<Box p={2}>
						<Stack direction="row" justifyContent="space-between">
							{isActivityEditMode ? (
								<Button
									variant="contained"
									disableElevation
									sx={{
										"&.Mui-disabled": {
											backgroundColor:
												"rgba(51, 188, 126)",
											color: "rgba(255, 255, 255, 0.6)",
										},
									}}
									onClick={handleSubmit(onUpdate)}
									disabled={updateInProgress}
								>
									Update
								</Button>
							) : (
								<Stack direction="row" spacing={2}>
									<Button
										variant="contained"
										disableElevation
										sx={{
											"&.Mui-disabled": {
												backgroundColor:
													"rgba(51, 188, 126)",
												color: "rgba(255, 255, 255, 0.6)",
											},
										}}
										onClick={handleSubmit(handleSave)}
										disabled={
											createNewActivityMutation.isLoading
										}
									>
										Save
									</Button>

									<Button
										variant="contained"
										disableElevation
										sx={{
											"&.Mui-disabled": {
												backgroundColor:
													"rgba(51, 188, 126)",
												color: "rgba(255, 255, 255, 0.6)",
											},
										}}
										onClick={handleSubmit(handleSaveAndNew)}
										disabled={
											createNewActivityMutation.isLoading
										}
									>
										Save & New
									</Button>
								</Stack>
							)}

							<Button
								variant="contained"
								color="secondary"
								disableElevation
								onClick={() =>
									handleMarkAsDone(watch("isDone"))
								}
							>
								<Stack direction="row" spacing={1}>
									<Checkbox
										checked={!!watch("isDone")}
										sx={{ padding: 0, margin: 0 }}
									/>
									<Typography fontSize={14} fontWeight={600}>
										Mark as Completed
									</Typography>
								</Stack>
							</Button>
						</Stack>
					</Box>
				</Box>
			)}
		</React.Fragment>
	);
}
