import { apiClient } from "../..";
import { searchTypes } from "../../../utils/dropdownData";
import { getTeamsUrl } from "../../apiConfig";
import { assertError } from "../../apiHelper";
import postFieldsApi from "../../fields/postFieldsApi";

export const getTeamsTableData = async (fieldToSort, pageParams) => {
	let requestData = getTeamsTableDataRequest(fieldToSort, pageParams);
	let response = postTeamsManagementApi(requestData);
	return await response;
};

export const getTeamsList = async (pageParam, searchValue) => {
	let requestData = getTeamsListRequest(pageParam, searchValue);
	return await postFieldsApi(requestData);
};

export const createTeam = async ({ name, users }) => {
	let requestData = getCreateTeamRequest(name, users);
	let response = postTeamsManagementApi(requestData);
	return await response;
};

export const getTeamUsersList = async (teamId, isManager) => {
	let requestData = getTeamUsersListRequest(teamId, isManager);
	let response = postTeamsManagementApi(requestData);
	return await response;
};

export const deleteTeam = async (teamId) => {
	let requestData = getDeleteTeamRequest(teamId);
	let response = postTeamsManagementApi(requestData);
	return await response;
};

export const getTeamData = async (teamId) => {
	let requestData = getTeamDataRequest(teamId);
	let response = postTeamsManagementApi(requestData);
	return await response;
};

export const updateTeam = async ({ name, users, id }) => {
	let requestData = getUpdateTeamRequest(name, users, id);
	let response = postTeamsManagementApi(requestData);
	return await response;
};

const getUpdateTeamRequest = (teamName, users, teamId) => {
	return {
		type: "update",
		data: {
			id: teamId,
			name: teamName,
			users: users,
		},
	};
};

const getTeamDataRequest = (teamId) => {
	return {
		type: "getUsers",
		data: {
			id: teamId,
		},
	};
};

const getDeleteTeamRequest = (teamId) => {
	return {
		type: "delete",
		data: {
			id: teamId,
		},
	};
};

const getTeamUsersListRequest = (teamId, isManager) => {
	return {
		type: "getPartialUsers",
		data: {
			id: teamId,
			fetchManagers: isManager,
		},
	};
};

const getCreateTeamRequest = (teamName, users) => {
	return {
		type: "create",
		data: {
			name: teamName,
			users: users,
		},
	};
};

const getTeamsTableDataRequest = (fieldToSort, pageParams) => {
	let teamTableDataRequest = {
		type: "list",
		data: {
			...pageParams,
		},
	};

	if (Object.keys(fieldToSort).length > 0) {
		teamTableDataRequest.data.sort = fieldToSort;
	}

	return teamTableDataRequest;
};

const getTeamsListRequest = (pageParam, searchValue = "") => {
	return {
		type: "dropdownData",
		data: {
			name: "teamList",
			criteria: {
				type: searchTypes.contains,
				value: searchValue,
			},
			...pageParam,
		},
	};
};

const postTeamsManagementApi = async (requestData) => {
	let teamsManagementUrl = getTeamsManagementUrl();
	let response = await apiClient.post(teamsManagementUrl, requestData);
	assertError(response, teamsManagementUrl);
	return response.data.data;
};

const getTeamsManagementUrl = () => {
	return getTeamsUrl() + "/mgmt";
};
