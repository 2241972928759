import React, { useEffect, useState } from "react";
import SingleDatePicker from "../../../../Elements/SingleDatePicker";
import {
	convertDayjsToUnixTimeStamp,
	convertUnixTimeStampToDayjs,
} from "../../../../../utils/DateUtils";

export default function DateComponent(props) {
	const {
		field = {},
		updateFieldValue,
		error,
		style = {},
		value,
		clearError,
	} = props;

	const [dateValue, setDateValue] = useState(null);

	useEffect(() => {
		if (value) {
			setDateValue(value?.value);
		}
	}, [value]);

	const handleChange = (event) => {
		setDateValue(convertDayjsToUnixTimeStamp(event));
		updateFieldValue(
			[{ value: convertDayjsToUnixTimeStamp(event) || "" }],
			field.fieldId
		);
		clearError(field.fieldId);
	};

	return (
		<React.Fragment>
			<SingleDatePicker
				textFieldProps={{
					fullWidth: true,
					sx: {
						backgroundColor: "#fff",
						...style,
					},
				}}
				value={convertUnixTimeStampToDayjs(dateValue)}
				error={error?.error}
				onChange={handleChange}
			/>
		</React.Fragment>
	);
}
