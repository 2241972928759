import React, { useEffect, useMemo, useState } from "react";
import {
	useGetChartType,
	useChartViewData,
} from "../../../../hooks/services/analytics";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	ArcElement,
	Title,
	Tooltip,
	Legend,
	Colors,
} from "chart.js";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import LineChart from "./LineChart";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { isEmptyObject } from "../../../../utils/common";
import { getFormattedDateMonthYearAndTime } from "../../../../utils/DateUtils";
import KpiChart from "./KpiChart";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	ArcElement,
	Title,
	Tooltip,
	Legend,
	Colors
);

ChartJS.defaults.font.family = "Lexend";

export default function Charts(props) {
	const {
		viewId,
		viewTypeId,
		dashboardId,
		previewMode = false,
		previewData,
	} = props;
	const { data: chartTypeListData, isLoading: isChartTypeListDataLoading } =
		useGetChartType();
	const {
		data: chartViewData,
		isLoading: isChartViewDataLoading,
		isError: chartViewDataError,
	} = useChartViewData(dashboardId, viewId, {
		enabled: !previewMode,
	});
	const [chartType, setChartType] = useState({});
	const [chartData, setChartData] = useState({});

	let chartRawData = useMemo(() => {
		return chartViewData || previewData;
	}, [chartViewData, previewData]);

	const parseChartData = (data, dataType) => {
		switch (dataType) {
			case "Date":
				data =
					data?.map((value) =>
						getFormattedDateMonthYearAndTime(parseInt(value))
					) || [];
				break;
			default:
				data = data?.map((value) => String(value)) || [];
				break;
		}
		return data;
	};

	useEffect(() => {
		if (chartRawData && !isEmptyObject(chartType)) {
			if (
				chartViewDataError ||
				isEmptyObject(chartRawData.graphData) ||
				isEmptyObject(chartRawData.graphMeta)
			) {
				return;
			}

			if (chartType[viewTypeId] === "kpiCard") {
				setChartData({
					label: chartRawData.graphMeta?.yAxis?.[0]?.label,
					currentValue: parseChartData(
						[chartRawData.graphData?.yAxis?.[0]?.currentValue || 0],
						chartRawData.graphMeta?.yAxis?.[0]?.dataType
					)[0],
					indicator: chartRawData.graphData?.yAxis?.[0]?.indicator,
					previousValue:
						chartRawData.graphData?.yAxis?.[0]?.previousValue,
				});
			} else {
				let chartData = {
					xAxis: {
						label: "",
						data: [],
					},
					yAxis: [],
				};

				chartData["xAxis"]["label"] =
					chartRawData.graphMeta.xAxis?.label;
				chartData["xAxis"]["data"] = parseChartData(
					chartRawData.graphData.xAxis || [],
					chartRawData.graphMeta.xAxis?.dataType
				);

				chartRawData.graphMeta?.yAxis?.forEach((meta, index) => {
					chartData.yAxis.push({
						label: meta.label,
						data: chartRawData.graphData.yAxis?.[index] || [],
					});
				});

				setChartData(chartData);
			}
		}
	}, [chartRawData, chartViewDataError, chartType, viewTypeId]);

	useEffect(() => {
		if (!isChartTypeListDataLoading) {
			const chartTypes = {};
			chartTypeListData?.viewList.forEach((chartType) => {
				chartTypes[chartType.id] = chartType.viewBy;
			});
			setChartType(chartTypes);
		}
	}, [isChartTypeListDataLoading, chartTypeListData]);

	const hasChartData = useMemo(() => {
		return (
			chartData?.yAxis?.length > 0 &&
			chartData.yAxis.some((chartData) => chartData.data.length > 0)
		);
	}, [chartData]);

	return (
		<React.Fragment>
			{!previewMode && isChartViewDataLoading ? (
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="center"
					height="70%"
					spacing={2}
				>
					<CircularProgress size={40} />
				</Stack>
			) : chartViewDataError ? (
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="center"
					height="70%"
					spacing={2}
				>
					<Typography color="rgba(0, 0, 0, 0.6)">
						Error fetching chart data
					</Typography>
				</Stack>
			) : hasChartData || chartData?.currentValue ? (
				(function () {
					switch (chartType[viewTypeId]) {
						case "pieChart":
							return <PieChart chartData={chartData} />;
						case "barChart":
							return <BarChart chartData={chartData} />;
						case "lineChart":
							return <LineChart chartData={chartData} />;
						case "kpiCard":
							return <KpiChart chartData={chartData} />;
						default:
							return null;
					}
				})()
			) : (
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="center"
					height="70%"
					spacing={2}
				>
					<Typography color="rgba(0, 0, 0, 0.6)">No Data</Typography>
				</Stack>
			)}
		</React.Fragment>
	);
}
