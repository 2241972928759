import React, { useState } from "react";
import { MenuItem, Stack, Typography, useTheme } from "@mui/material";
import { default as TimeIcon } from "../../../../../../../../../assets/icons/time";
import Menu from "../../../../../../../Menu";
import { MenuItemText } from "../../../../../../../../../styles/twozo";
import { getDropdownDataByName } from "../../../../../../../../../utils/dropdownData";
import { useFilterDataContext } from "../../../../../Context/FilterDataContext";

export default function TimeDropdown(props) {
	const { filterCondition, onChange } = props;
	const theme = useTheme();
	const { enableApplyButton } = useFilterDataContext();

	const styles = {
		field: {
			alignItems: "center",
			justifyContent: "space-between",
			width: "218px",
			height: "38px",
			padding: "0px 10px",
			backgroundColor: "rgba(51, 188, 126, 0.12)",
		},
	};

	const time = getDropdownDataByName("TIME");
	const [timeMenuElement, setTimeMenuElement] = useState(null);
	const isOpenTimeMenu = Boolean(timeMenuElement);

	const opentimeMenu = (event) => {
		setTimeMenuElement(event.currentTarget);
	};

	const closeTimeMenu = () => {
		setTimeMenuElement(null);
	};

	const handleSelectValue = (selectedValue) => {
		const updatedFilterCondition = {
			...filterCondition,
			valueEntityId: {
				...filterCondition.valueEntityId,
				value: selectedValue.value
					? selectedValue.value
					: selectedValue.id,
			},
			value: {
				...filterCondition.value,
				value: selectedValue.name,
			},
			singleSelectValue: selectedValue,
		};
		onChange(updatedFilterCondition);
		enableApplyButton();
		setTimeMenuElement(null);
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="202px"
				anchorEl={timeMenuElement}
				open={isOpenTimeMenu}
				onClose={closeTimeMenu}
				style={{ transform: "translateY(3px)" }}
				PaperProps={{
					style: {
						maxHeight: "400px",
					},
				}}
			>
				{time.map((timeData, timeIndex) => (
					<MenuItem
						style={{ height: "40px" }}
						key={timeIndex}
						onClick={() => handleSelectValue(timeData)}
					>
						<MenuItemText>{timeData.name}</MenuItemText>
					</MenuItem>
				))}
			</Menu>

			<Stack
				direction="row"
				style={{
					...styles.field,
					cursor: "pointer",
					borderRadius: "0px 0px 8px 8px",
					borderBottom: timeMenuElement
						? `1px solid ${theme.palette.primary.main}`
						: null,
				}}
				onClick={(event) => opentimeMenu(event)}
			>
				{filterCondition.value.value ? (
					<Typography fontSize={14}>
						{filterCondition.value.value}
					</Typography>
				) : (
					<Typography
						sx={{
							fontSize: "14px",
							color: "rgba(0,0,0,0.4)",
						}}
					>
						hh:mm AM
					</Typography>
				)}
				{TimeIcon(16, 16, "rgba(0,0,0,0.4)")}
			</Stack>
		</React.Fragment>
	);
}
