import React, { useState, useEffect } from "react";
import TagsInput from "./TagsInput";

export function TagsBaseComponent(props) {
	const { inputRef, field, error, clearErrors, onChange, value } = props;

	const [selectedTags, setSelectedTags] = useState([]);

	// This useEffect will run only initial render
	useEffect(() => {
		if (Array.isArray(value)) {
			// Check if the server value needs to be formatted
			const isNotInServerFormat = () => {
				return (
					Array.isArray(value) &&
					value.some((tagData) => tagData?.name)
				);
			};

			if (isNotInServerFormat()) {
				setSelectedTags(value);
				onChange(
					value.map((tagData) => {
						return { value: tagData.value };
					})
				);
			}
		}
	}, [value, onChange]);

	const handleSelectTags = (values) => {
		setSelectedTags(values);
		let tagsForRequest = values?.map((selectedTag) => {
			if (selectedTag?.value) {
				return { value: selectedTag.value };
			} else {
				return { tag: selectedTag?.name };
			}
		});
		onChange(tagsForRequest);
		clearErrors(field.id);
	};

	return (
		<React.Fragment>
			<TagsInput
				inputRef={inputRef}
				value={selectedTags}
				onChange={handleSelectTags}
				error={error}
				placeHolder={field?.placeHolder}
			/>
		</React.Fragment>
	);
}
