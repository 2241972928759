import TextComponent from "../../../../AddForm/Field/FieldComponents/TextComponent";
import { getFieldGroupStyle } from "../../../styles";

export default function TextFieldComponent(params) {
	const { field, isGroupedFieldTop, isGroupedFieldBottom } = params;

	const isGroupField = field?.config?.isGroupedField;

	const textFieldSx = isGroupField
		? {
				"& .MuiOutlinedInput-root": {
					"& fieldset": getFieldGroupStyle(
						isGroupedFieldTop,
						isGroupedFieldBottom
					),
					"&:hover fieldset": getFieldGroupStyle(
						isGroupedFieldTop,
						isGroupedFieldBottom
					),
					"&.Mui-focused fieldset": getFieldGroupStyle(
						isGroupedFieldTop,
						isGroupedFieldBottom
					),
				},
			}
		: {};

	return (
		<>
			<TextComponent field={field} textFieldSx={textFieldSx} />
		</>
	);
}
