import React from "react";

export default function Record(width = "24", height = "24", color = "#fff") {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6 16.5C8.48528 16.5 10.5 14.4853 10.5 12C10.5 9.51472 8.48528 7.5 6 7.5C3.51472 7.5 1.5 9.51472 1.5 12C1.5 14.4853 3.51472 16.5 6 16.5Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18 16.5C20.4853 16.5 22.5 14.4853 22.5 12C22.5 9.51472 20.4853 7.5 18 7.5C15.5147 7.5 13.5 9.51472 13.5 12C13.5 14.4853 15.5147 16.5 18 16.5Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6 16.5H18"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
