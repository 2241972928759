import {
	Box,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { getDropdownDataByName } from "../../../../utils/dropdownData";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { handleFieldValidation } from "../../../../utils/fieldValidation";
import { removeInvalidPhoneNumberCharacters } from "../../../../utils/phoneNumberUtils";
import { forwardRef, useCallback, useImperativeHandle } from "react";
import { MenuItemText } from "../../../../styles/twozo";

const CustomSelect = styled(Select)(() => ({
	"& .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&:hover .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
}));

const PhoneFieldEditForm = forwardRef(
	({ value, field, subFields, onError, onSave, onClose }, ref) => {
		const theme = useTheme();
		const {
			handleSubmit,
			formState: { errors },
			register,
			setValue,
			clearErrors,
		} = useForm({ values: value, reValidateMode: false });

		const getSubFieldObject = (subFieldName) => {
			let subFieldObj;
			if (!!subFields && Array.isArray(subFields)) {
				subFields.forEach((field) => {
					if (field.name === subFieldName) {
						subFieldObj = field;
					}
				});
			}

			return subFieldObj;
		};

		const phoneTypeOptions = getDropdownDataByName("PHONE_TYPE");
		const phoneTypeField = getSubFieldObject("PHONE_TYPE");
		const phoneNumberField = getSubFieldObject("PHONE_NUMBER");

		const [selectedPhoneFieldType, setSelectedPhoneFieldType] = useState(
			phoneTypeOptions?.[0]?.value
		);

		const handlePhoneFieldChange = (event) => {
			const { value } = event.target;
			setSelectedPhoneFieldType(value);
			setValue(phoneTypeField?.id + "", value);
		};

		useEffect(() => {
			if (value?.[phoneTypeField?.id]) {
				setSelectedPhoneFieldType(value[phoneTypeField.id]);
			}
		}, [phoneTypeField.id, value]);

		const submitForm = useCallback(() => {
			return handleSubmit(
				(data) => {
					onSave(data, onClose);
					onError(null);
					return data;
				},
				(errors) => {
					if (errors[phoneNumberField?.id]) {
						onError(errors[phoneNumberField?.id]);
					}
				}
			)();
		}, [handleSubmit, phoneNumberField, onError, onSave, onClose]);

		useImperativeHandle(ref, () => ({
			submitForm,
		}));

		const handlePhoneNumberFieldChange = (event) => {
			let phoneValue = event.target.value.trimStart();
			phoneValue = removeInvalidPhoneNumberCharacters(phoneValue);
			setValue(phoneNumberField?.id + "", phoneValue);
			onError(null);
			clearErrors(phoneNumberField?.id + "");
		};
		return (
			<>
				<Stack
					direction="row"
					width="100%"
					height="100%"
					overflow="hidden"
					justifyContent="space-between"
				>
					<Box width="65%" height="100%">
						<TextField
							placeholder={
								phoneNumberField?.placeHolder || "9876543210"
							}
							type="tel"
							{...register(
								phoneNumberField?.id + "",
								handleFieldValidation(field)
							)}
							onChange={(event) =>
								handlePhoneNumberFieldChange(event)
							}
							sx={{
								width: "100%",
								"& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button":
									{
										"-webkit-appearance": "none",
									},
								"& .MuiOutlinedInput-root": {
									"& fieldset": {
										border: "none",
									},
									"&:hover fieldset": {
										border: "none",
									},
									"&.Mui-focused fieldset": {
										border: "none",
									},
								},
							}}
							name="mobile"
						/>

						{errors[phoneNumberField?.id] ? (
							<Typography
								pl={1}
								pb={1.2}
								style={{
									fontSize: 13,
									width: "95%",
									marginLeft: "6px",
									color: theme.palette.error.main,
								}}
							>
								{errors[phoneNumberField?.id]?.message}
							</Typography>
						) : null}
					</Box>

					<Box
						width="35%"
						borderLeft={`1px solid ${
							errors[phoneNumberField?.id]
								? theme.palette.error.main
								: theme.palette.primary.main
						}`}
						height="100%"
					>
						<CustomSelect
							{...register(phoneTypeField?.id + "")}
							sx={{
								width: "100%",
								"& .MuiOutlinedInput-notchedOutline": {
									borderRadius: "0px 8px 8px 0px",
									marginLeft: "-1px",
								},
							}}
							name="mobileType"
							onChange={handlePhoneFieldChange}
							value={selectedPhoneFieldType}
						>
							{phoneTypeOptions.map((phoneType) => (
								<MenuItem
									key={phoneType.value}
									value={phoneType.value}
								>
									<MenuItemText>
										{phoneType.name}
									</MenuItemText>
								</MenuItem>
							))}
						</CustomSelect>
					</Box>
				</Stack>
			</>
		);
	}
);

PhoneFieldEditForm.displayName = "PhoneFieldEditForm";

export default PhoneFieldEditForm;
