import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFieldContext } from "../../FieldContext";
import { Tooltip } from "../../../../../../styles/twozo";

export default function FieldName(props) {
	const { field } = props;

	// field context
	const {
		fieldState,
		editingFieldRef,
		onFieldNameValueChange,
		OnFieldNameLimitExceeded,
		resetFieldNameError,
		onFieldValueChange,
	} = useFieldContext(field);

	const [fieldName, setFieldName] = useState(field.fieldName);
	const [isFieldNameTooltipOpened, setIsFieldNameTooltipOpened] =
		useState(false);
	const isFieldNameInEditMode =
		fieldState.isFieldNameInEditMode || fieldState.fieldInCreateMode;
	let errorMessage = "Max. of 50 characters are allowed";

	useEffect(() => {
		if (fieldState.unSavedFieldData.fieldName) {
			setFieldName(fieldState.unSavedFieldData.fieldName);
		}
	}, [fieldState.unSavedFieldData.fieldName]);

	const setFieldNameValue = (event) => {
		const { value } = event.target;
		let fieldNameCharacterLimit = 50;

		if (value.trim().length > fieldNameCharacterLimit) {
			OnFieldNameLimitExceeded();
		} else {
			resetFieldNameError();
			onFieldValueChange(
				value?.trim() ? value !== field.fieldName : false
			);
			setFieldName(value);
			onFieldNameValueChange(value);
		}
	};

	const handleOpenTooltip = (event) => {
		setIsFieldNameTooltipOpened(
			event.target.scrollWidth > event.target.clientWidth
		);
	};

	return (
		<React.Fragment>
			{isFieldNameInEditMode ? (
				<TextField
					variant="standard"
					inputRef={editingFieldRef}
					value={fieldName || ""}
					autoFocus
					onChange={setFieldNameValue}
					error={fieldState.isFieldNameLimitExceeded}
					helperText={
						fieldState.isFieldNameLimitExceeded && errorMessage
					}
				/>
			) : (
				<Box>
					<Tooltip
						title={fieldName}
						open={isFieldNameTooltipOpened}
						placement="top"
					>
						<Typography
							maxWidth="260px"
							noWrap
							fontSize={15}
							onMouseOver={handleOpenTooltip}
							onMouseLeave={() =>
								setIsFieldNameTooltipOpened(false)
							}
						>
							{fieldName}
						</Typography>
					</Tooltip>
				</Box>
			)}
		</React.Fragment>
	);
}
