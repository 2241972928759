import React, { useEffect, useState } from "react";
import DropdownBaseComponent from "../DropdownBaseComponent";
import { useQuery } from "@tanstack/react-query";
import { getActionStepOptionKey } from "../../../../../utils/queryKeys/workflow";
import { getActionStepOptionData } from "../../../../../api/workflow/workflowApi";
import { useWorkflowDropdownList } from "../../../../../hooks/services/workflow";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";

export default function MultiDropdownComponent(props) {
	const {
		field,
		metadata,
		style,
		error,
		updateFieldValue,
		value,
		clearError,
	} = props;
	const [selected, setSelected] = useState("");
	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		if (value) {
			setSelected(value);
		} else {
			setSelected("");
		}
	}, [value]);

	const {
		data: options,
		isLoading: isOptionsLoading,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useWorkflowDropdownList(field, searchValue);

	const { data: stepOptions } = useQuery(
		getActionStepOptionKey(metadata.triggerSourceId, field.fieldId, [
			{ source: metadata.actionSource, event: metadata.actionEvent },
		]),
		() =>
			getActionStepOptionData(metadata.triggerSourceId, field.fieldId, [
				{ source: metadata.actionSource, event: metadata.actionEvent },
			]),
		{
			enabled: !!(
				!metadata ||
				(field?.fieldId && field?.config?.hasStepOptions)
			),
			select: (data) => data?.stepOptions,
		}
	);

	const onFieldChange = (value) => {
		setSelected(value);
		updateFieldValue([value], field.fieldId);
		clearError(field.fieldId);
	};

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const updateSearchValue = (value) => {
		setSearchValue(value);
	};

	return (
		<React.Fragment>
			<DropdownBaseComponent
				hasStepOptions={field?.config?.hasStepOptions}
				selected={selected}
				options={options || []}
				onFieldChange={onFieldChange}
				multiDropdown={field.fieldType === "MULTI_DROPDOWN"}
				stepOptions={stepOptions || []}
				style={style}
				error={error}
				searchValue={searchValue}
				isFetching={isFetching}
				isOptionsLoading={isOptionsLoading}
				lastRowRef={lastRowRef}
				updateSearchValue={updateSearchValue}
			/>
		</React.Fragment>
	);
}
