export const actionsIds = {
	assignRecords: "assignRecords",
	bulkAssignRecords: "bulkAssignRecords",
	cloneRecords: "cloneRecords",
	bulkUpdateRecords: "bulkUpdateRecords",
	restoreRecords: "restoreRecords",
	mergeRecords: "mergeRecords",
	exportRecords: "exportRecords",
	accessView: "accessView",
	shareView: "shareView",
	importRecords: "importRecords",
	limitForCreatingRecordsThroughImport:
		"limitForCreatingRecordsThroughImport",
	limitForUpdatingRecordsThroughImport:
		"limitForUpdatingRecordsThroughImport",
	accessRecycleBin: "accessRecycleBin",
};

export const scope = {
	scope: "scope",
	globalAccess: "globalAccess",
	territoryAccess: "territoryAccess",
	restrictedAccess: "restrictedAccess",
};

export const permissionTypes = {
	view: "view",
	create: "create",
	edit: "edit",
	delete: "delete",
};

export const quotasAndForecastingNames = {
	viewQuotas: "View Quotas",
	teamView: "Team View",
	commitDeals: "Commit Deals",
};

export const emailsIds = {
	shareEmailTemplatesFolder: "shareEmailTemplatesFolder",
	viewEmailConversation: "viewEmailConversation",
	bulkEmailLimit: "bulkEmailLimit",
	individualEmailLimit: "individualEmailLimit",
};

export const shareEmailTemplatesFolderPermissions = {
	myTeams: "myTeams",
	myTerritories: "myTerritories",
	everyone: "everyone",
};

export const viewEmailConversationPermissions = {
	viewAllEmails: "viewAllEmails",
	viewOnlyPrivateEmails: "viewOnlyPrivateEmails",
};

export const activityGoalsPermission = {
	allGoals: "allGoals",
	teamsGoals: "teamsGoals",
	usersGoals: "usersGoals",
	goalsCreatedByUser: "goalsCreatedByUser",
};

export const activityGoalsIds = {
	viewGoals: "viewGoals",
	createGoals: "createGoals",
	editGoals: "editGoals",
	deleteGoals: "deleteGoals",
	recalculateGoals: "recalculateGoals",
};

export const sourceIds = {
	contacts: "contacts",
	companies: "companies",
	deals: "deals",
	products: "products",
	activities: "activities",
};

export const adminAccessIds = {
	accessAdminSettings: "accessAdminSettings",
	manageSalesActivities: "manageSalesActivities",
	manageSalesForecastCategories: "manageSalesForecastCategories",
	manageWorkFlows: "manageWorkFlows",
	manageTerritories: "manageTerritories",
	manageUsers: "manageUsers",
	manageAllTags: "manageAllTags",
	exportAllData: "exportAllData",
	migrateFromACrm: "migrateFromACrm",
	manageRoles: "manageRoles",
	manageIntegrationsForChat: "manageIntegrationsForChat",
	manageConversationalChannels: "manageConversationalChannels",
	managePhoneSettings: "managePhoneSettings",
};

export const leadGenerationIds = {
	webFormTracking: "webFormTracking",
};

export const phoneIds = {
	accessToPhone: "accessToPhone",
	allowUsersToMakePhoneCalls: "allowUsersToMakePhoneCalls",
	callLogs: "callLogs",
};

export const quotasAndForecastingIds = {
	viewQuotas: "viewQuotas",
	createQuotas: "createQuotas",
	editQuotas: "editQuotas",
	deleteQuotas: "deleteQuotas",
	recalculateQuotas: "recalculateQuotas",
	teamView: "teamView",
	hierarchyView: "hierarchyView",
	commitDeal: "commitDeal",
	overrideCommitAndBestCaseValues: "overrideCommitAndBestCaseValues",
};

export const quotasAndForecastingPermissions = {
	allQuotas: "allQuotas",
	teamQuotas: "teamQuotas",
	usersQuotas: "usersQuotas",
	quotasCreatedByUser: "quotasCreatedByUser",
	companyLevelAccess: "companyLevelAccess",
	teamLevelAccess: "teamLevelAccess",
	userLevelAccess: "userLevelAccess",
	reportLevelAccess: "reportLevelAccess",
};

export const smsIds = {
	bulkSmsLimit: "bulkSmsLimit",
	individualSmsLimit: "individualSmsLimit",
};

export const userSettingsIds = {
	manageSalesTeam: "manageSalesTeam",
};

export const followersIds = {
	dealFollowersRecords: "dealFollowersRecords",
	contactFollowersRecords: "contactFollowersRecords",
	companyFollowersRecords: "companyFollowersRecords",
	manageFollowers: "manageFollowers",
};

export const manageFollowers = {
	dealFollowers: "dealFollowers",
	companyFollowers: "companyFollowers",
	contactFollowers: "contactFollowers",
};

export const type = {
	checkbox: "checkbox",
	input: "input_value",
	radio: "radio",
};

export const getPermissionSetRequest = (
	isCreateRole,
	roleName,
	roleId,
	permissionState,
	assignedUserIds
) => {
	const request = { permissions: {} };
	request.permissions.leadGeneration = {};
	request.permissions.adminAccess = {};
	request.permissions.userSettings = {};
	request.permissions.phone = {};
	request.permissions.activityGoals = {};
	request.permissions.quotasAndForecasting = {};
	request.permissions.sources = {};
	request.permissions.sms = {};
	request.permissions.actions = {};
	request.permissions.emails = {};
	request.permissions.followers = {};

	if (assignedUserIds.length > 0) {
		request.users = assignedUserIds;
	}

	if (isCreateRole) {
		request.name = roleName;
	} else {
		request.id = roleId;
		request.name = roleName;
	}

	//followers
	for (const permission of permissionState.followersList) {
		const permissionObject = { access: permission.access };

		for (const permissionItem of permission.permissions.permission) {
			permissionObject[permissionItem.id] = permissionItem.value;
		}

		request.permissions.followers[permission.id] = permissionObject;
	}

	//Lead Generation
	for (const permission of permissionState.leadGenerationList) {
		const permissionObject = {};
		permissionObject.access = permission.access;

		for (const permissionItem of permission.permissions.permission) {
			permissionObject[permissionItem.id] = permissionItem.value;
		}
		request.permissions.leadGeneration[permission.id] = permissionObject;
	}

	//Admin Access
	for (const permission of permissionState.adminAccessList) {
		const permissionObject = {};
		permissionObject.access = permission.access;

		if (permission.permissions) {
			for (const permissionItem of permission.permissions.permission) {
				permissionObject[permissionItem.id] = permissionItem.value;
			}
		}
		request.permissions.adminAccess[permission.id] = permissionObject;
	}

	//User Settings
	for (const permission of permissionState.userSettingsList) {
		const permissionObject = {};
		permissionObject.access = permission.access;
		request.permissions.userSettings[permission.id] = permissionObject;
	}

	//Phone
	for (const permission of permissionState.phoneList) {
		const permissionObject = {};
		permissionObject.access = permission.access;

		if (permission.permissions) {
			for (const permissionItem of permission.permissions.permission) {
				permissionObject[permissionItem.id] = permissionItem.value;
			}
		}

		request.permissions.phone[permission.id] = permissionObject;
	}

	//Activity Goals
	for (const permission of permissionState.activityGoalsList) {
		const permissionObject = {};
		permissionObject.access = permission.access;

		if (permission.access && permission.permissions) {
			const scopeId = permission.permissions.permission.find(
				(permissionItem) => permissionItem.value === true
			).id;
			permissionObject.scope = scopeId;
		}

		request.permissions.activityGoals[permission.id] = permissionObject;
	}

	//Quotas and forecasting
	for (const permission of permissionState.quotasAndForecastingList) {
		const permissionObject = {};
		permissionObject.access = permission.access;

		if (permission.access && permission.permissions) {
			const scopeId = permission.permissions.permission.find(
				(permissionItem) => permissionItem.value === true
			).id;
			permissionObject.scope = scopeId;
		}

		request.permissions.quotasAndForecasting[permission.id] =
			permissionObject;
	}

	//Source
	for (const permission of permissionState.sourceList) {
		const permissionObject = {};
		permissionObject.access = permission.access;

		for (const permissionItem of permission.permissions.permission) {
			if (permissionItem.id !== scope.scope) {
				permissionObject[permissionItem.id] = permissionItem.value;
			}

			if (permissionItem.id === scope.scope) {
				permissionObject[permissionItem.id] =
					permissionItem.selectedAccess;
			}
		}
		request.permissions.sources[permission.id] = permissionObject;
	}

	//sms
	for (const permission of permissionState.smsList) {
		const permissionObject = {};
		permissionObject.access = permission.access;

		if (permission.access) {
			permissionObject[permission.permissions.id] =
				permission.permissions.value;
		}

		request.permissions.sms[permission.id] = permissionObject;
	}

	//actions
	for (const permission of permissionState.actionsList) {
		const permissionObject = {};
		permissionObject.access = permission.access;

		if (permission.permissions) {
			if (permission.permissions.type === type.checkbox) {
				for (const permissionItem of permission.permissions
					.permission) {
					permissionObject[permissionItem.id] = permissionItem.value;
				}
			} else if (
				permission.permissions.type === type.input &&
				permission.access
			) {
				permissionObject[permission.permissions.id] =
					permission.permissions.value;
			}
		}

		request.permissions.actions[permission.id] = permissionObject;
	}

	//emails
	for (const permission of permissionState.emailList) {
		const permissionObject = {};
		permissionObject.access = permission.access;

		if (permission.permissions) {
			if (permission.permissions.type === type.radio) {
				for (const permissionItem of permission.permissions
					.permission) {
					if (permissionItem.value) {
						permissionObject.scope = permissionItem.id;
					}
				}
			} else if (permission.permissions.type === type.checkbox) {
				for (const permissionItem of permission.permissions
					.permission) {
					permissionObject[permissionItem.id] = permissionItem.value;
				}
			} else if (permission.permissions.type === type.input) {
				permissionObject.permittedLimit = permission.permissions.value;
			}
		}

		request.permissions.emails[permission.id] = permissionObject;
	}

	return request;
};
