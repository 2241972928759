export const timelineTypes = {
	DEAL_CREATED: "DEAL_CREATED",

	DEAL_TITLE_UPDATED: "DEAL_TITLE_UPDATED",

	DEAL_ASSOCIATED_WITH_CONTACT: "DEAL_ASSOCIATED_WITH_CONTACT",

	DEAL_ASSOCIATED_WITH_COMPANY: "DEAL_ASSOCIATED_WITH_COMPANY",

	DEAL_VALUE_ASSIGNED: "DEAL_VALUE_ASSIGNED",

	DEAL_VALUE_UPDATED: "DEAL_VALUE_UPDATED",

	DEAL_EXPECTED_CLOSE_DATE_ASSIGNED: "DEAL_EXPECTED_CLOSE_DATE_ASSIGNED",

	DEAL_EXPECTED_CLOSE_DATE_CHANGED: "DEAL_EXPECTED_CLOSE_DATE_CHANGED",

	DEAL_PIPELINE_STAGE_MOVED: "DEAL_PIPELINE_STAGE_MOVED",

	NOW_POINT_OF_CONTACT_FOR_THIS_DEAL: "NOW_POINT_OF_CONTACT_FOR_THIS_DEAL",

	DEAL_LOST_REASON_SET: "DEAL_LOST_REASON_SET",

	DEAL_LOST_REASON_UPDATED: "DEAL_LOST_REASON_UPDATED",

	DEAL_MERGED: "DEAL_MERGED",

	CONTACT_CREATED: "CONTACT_CREATED",

	CONTACT_NAME_UPDATED: "CONTACT_NAME_UPDATED",

	SUBSCRIPTION_STATUS_CHANGED: "SUBSCRIPTION_STATUS_CHANGED",

	CONTACT_MERGED: "CONTACT_MERGED",

	CONTACT_LIFE_CYCLE_STAGE_UPDATED: "CONTACT_LIFE_CYCLE_STAGE_UPDATED",

	CONTACT_LIFE_CYCLE_STATUS_UPDATED: "CONTACT_LIFE_CYCLE_STATUS_UPDATED",

	CONTACT_CLOSED_LOST_REASON_UPDATED: "CONTACT_CLOSED_LOST_REASON_UPDATED",

	CONTACT_CLOSED_LOST_REASON_SET: "CONTACT_CLOSED_LOST_REASON_SET",

	COMPANY_CREATED: "COMPANY_CREATED",

	COMPANY_NAME_UPDATED: "COMPANY_NAME_UPDATED",

	COMPANY_MERGED: "COMPANY_MERGED",

	NOTES_ADDED: "NOTES_ADDED",

	NOTES_EDITED: "NOTES_EDITED",

	FILE_UPLOADED: "FILE_UPLOADED",

	ACTIVITY_ADDED: "ACTIVITY_ADDED",

	ACTIVITY_TITLE_UPDATED: "ACTIVITY_TITLE_UPDATED",

	ACTIVITY_NOTE_ADDED: "ACTIVITY_NOTE_ADDED",

	ACTIVITY_NOTE_EDITED: "ACTIVITY_NOTE_EDITED",

	EMAIL_SENT: "EMAIL_SENT",

	EMAIL_RECEIVED: "EMAIL_RECEIVED",

	EMAIL_REPLIED: "EMAIL_REPLIED",

	CONTACT_SALES_OWNER_REASSIGNED: "CONTACT_SALES_OWNER_REASSIGNED",

	DEAL_SALES_OWNER_REASSIGNED: "DEAL_SALES_OWNER_REASSIGNED",

	COMPANY_SALES_OWNER_REASSIGNED: "COMPANY_SALES_OWNER_REASSIGNED",

	FOLLOWERS_ADDED: "FOLLOWERS_ADDED",

	FOLLOWERS_REMOVED: "FOLLOWERS_REMOVED",
};
