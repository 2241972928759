import React, { useEffect } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import {
	areCriteriaListsEqual,
	filterConnectorsList,
} from "../../../../../../utils/FilterUtills";
import { useFilterDataContext } from "../../Context/FilterDataContext";
import SingleCriteria from "./SingleCriteria";
import { default as closeIcon } from "../../../../../../assets/icons/close";

export default function CriteriaList(props) {
	const { connector } = props;

	const styles = {
		header: {
			alignItems: "center",
			justifyContent: "space-between",
			width: "218px",
			paddingLeft: "10px",
		},
	};

	const {
		filterState,
		updateFilterUICriteriaList,
		enableApplyButton,
		disableApplyButton,
	} = useFilterDataContext();

	// Remove Filter
	const removeSingleFilterCondition = (index) => {
		const modifiedFilterCriteria = [
			...filterState.filterUICriteriaList.currentCriteria,
		];
		modifiedFilterCriteria.splice(index, 1);
		updateFilterUICriteriaList([...modifiedFilterCriteria]);
		enableApplyButton();
	};

	const onChange = (condition) => {
		let modifiedFilterCriteria = [
			...filterState.filterUICriteriaList.currentCriteria,
		];
		modifiedFilterCriteria = modifiedFilterCriteria.map((filter) =>
			filter.id === condition.id ? condition : filter
		);

		updateFilterUICriteriaList(modifiedFilterCriteria);
	};

	//To Enable and disable apply button based on applied filter conditions
	useEffect(() => {
		if (
			filterState?.filterUICriteriaList?.lastAppliedCriteria?.length >
				0 &&
			filterState.filterUICriteriaList.currentCriteria.length > 0
		) {
			const hasFilterCriteriaChanged = areCriteriaListsEqual(
				filterState.filterUICriteriaList.currentCriteria,
				filterState.filterUICriteriaList.lastAppliedCriteria
			);
			hasFilterCriteriaChanged
				? disableApplyButton()
				: enableApplyButton();
		}
	}, [
		filterState.filterUICriteriaList.currentCriteria,
		filterState?.filterUICriteriaList.lastAppliedCriteria,
		enableApplyButton,
		disableApplyButton,
	]);

	return (
		<React.Fragment>
			{filterState.filterUICriteriaList?.currentCriteria?.map(
				(data, index) => (
					<Box key={index}>
						{(connector === filterConnectorsList.AND &&
							data.connectorId.value.name ===
								filterConnectorsList.AND) ||
						(connector === filterConnectorsList.OR &&
							data.connectorId.value.name ===
								filterConnectorsList.OR) ? (
							<Box p={1}>
								<Stack>
									<Stack
										direction="row"
										style={styles.header}
										key={index}
									>
										<Typography
											fontSize={13}
											fontWeight={500}
										>
											{data.sourceId.value.name}
										</Typography>
										<IconButton
											style={{ cursor: "pointer" }}
											onClick={() =>
												removeSingleFilterCondition(
													index
												)
											}
										>
											{closeIcon(20, 20, "#000", "0.6")}
										</IconButton>
									</Stack>

									<SingleCriteria
										filterCondition={data}
										onChange={onChange}
									/>
								</Stack>
							</Box>
						) : null}
					</Box>
				)
			)}
		</React.Fragment>
	);
}
