const callRecordTypes = [
	{
		name: "Record all calls",
		value: 1,
	},
	{
		name: "Do not record calls",
		value: 2,
	},
	{
		name: "Record incoming calls",
		value: 3,
	},
	{
		name: "Record outgoing calls",
		value: 4,
	},
];

export default callRecordTypes;
