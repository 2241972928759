import React from "react";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Draggable } from "react-beautiful-dnd";
import { PERMISSIONS } from "../../../../utils/Auth";
import { useAuth } from "../../../../hooks/auth";
import DealCard from "../../DealCard";
import { dealKanbanViewPageSize } from "../../../../utils/queryConstants/deal";
import { default as DealIcon } from "../../../../assets/icons/deals";

export default function DealCardList(props) {
	const { dealPageData, pipelineStageId, isFetching, lastRowRef } = props;
	const navigate = useNavigate();
	const { pathname, search } = useLocation();
	const { isUserAllowedFor } = useAuth();

	const navigateDealDetailsPage = (dealId) => {
		navigate(dealId.toString(), {
			state: {
				prevPath: `${pathname}${search ? search : ""}`,
			},
		});
	};

	return (
		<React.Fragment>
			{dealPageData.length > 0 &&
			Array.isArray(dealPageData[0].data.deals) &&
			dealPageData[0].data.deals?.length > 0 ? (
				<Box height="100%" p={1}>
					{dealPageData.map((dealData, dealPageIndex) =>
						dealData.data.deals.map((deal, index) => (
							<Box key={deal?.id} ref={lastRowRef}>
								<Draggable
									draggableId={deal?.id?.toString()}
									index={
										dealPageIndex * dealKanbanViewPageSize +
										index
									}
									isDragDisabled={
										!isUserAllowedFor(PERMISSIONS.deal.edit)
									}
								>
									{(draggableProvided) => (
										<Box
											ref={draggableProvided.innerRef}
											{...draggableProvided.draggableProps}
											{...draggableProvided.dragHandleProps}
											sx={{
												marginBottom: "8px",
											}}
										>
											<DealCard
												deal={deal}
												pipelineStageId={
													pipelineStageId
												}
												onClick={() =>
													navigateDealDetailsPage(
														deal.id
													)
												}
											/>
										</Box>
									)}
								</Draggable>
							</Box>
						))
					)}

					<Box pb={2}>
						<Box
							hidden={
								!(
									isFetching &&
									dealPageData[dealPageData.length - 1].data
										?.hasMore
								)
							}
							style={{
								backgroundColor: "#fff",
								borderRadius: "8px",
								padding: "24px 16px",
							}}
						>
							<Stack spacing={1}>
								<Skeleton width="118px" height="18px" />
								<Skeleton width="188px" height="18px" />

								<Stack direction="row" spacing={1}>
									<Skeleton
										variant="circular"
										width="26px"
										height="26px"
									/>
									<Skeleton
										variant="circular"
										width="26px"
										height="26px"
									/>
								</Stack>
							</Stack>
						</Box>
					</Box>
				</Box>
			) : (
				<Stack
					justifyContent="center"
					alignItems="center"
					height="100%"
					p={1}
					spacing={0.5}
				>
					<Box
						sx={{
							display: "flex",
						}}
					>
						{DealIcon(30, 30, "#666666")}
					</Box>
					<Typography
						fontSize="14px"
						style={{
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						No Deals Found Here
					</Typography>
				</Stack>
			)}
		</React.Fragment>
	);
}
