import React, { useEffect, useMemo, useState } from "react";
import DropdownBaseComponent from "../DropdownBaseComponent";
import { useCompanyList } from "../../../../../hooks/services/company";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";

export default function CompanyComponent(props) {
	const { field, style, error, updateFieldValue, value, clearError } = props;
	const [selected, setSelected] = useState(null);
	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		if (value) {
			setSelected(value);
		} else {
			setSelected(null);
		}
	}, [value]);

	const {
		data: companies,
		isLoading: isLoadingCompanies,
		isFetching: isFetchingCompanies,
		hasNextPage: hasNextPage,
		fetchNextPage: fetchNextPage,
	} = useCompanyList(true, searchValue);

	const formattedOptions = useMemo(() => {
		if (Array.isArray(companies) && companies.length > 0) {
			return companies.map((option) => {
				if (option.id) {
					option["value"] = option["id"];
				} else if (option.value) {
					option["id"] = option["value"];
				}
				return option;
			});
		}

		return [];
	}, [companies]);

	const onFieldChange = (value) => {
		setSelected(value);
		updateFieldValue([value], field.fieldId);
		clearError(field.fieldId);
	};

	const updateSearchValue = (value) => {
		setSearchValue(value);
	};
	const lastRowRef = useLastRowRef(
		fetchNextPage,
		hasNextPage,
		isFetchingCompanies
	);

	return (
		<React.Fragment>
			<DropdownBaseComponent
				selected={selected}
				options={formattedOptions}
				onFieldChange={onFieldChange}
				style={style}
				error={error}
				searchValue={searchValue}
				isFetching={isFetchingCompanies}
				isOptionsLoading={isLoadingCompanies}
				lastRowRef={lastRowRef}
				updateSearchValue={updateSearchValue}
			/>
		</React.Fragment>
	);
}
