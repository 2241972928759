import React from "react";
import DateComponent from "../../../../AddForm/Field/FieldComponents/DateComponent";
import { getFieldGroupStyle } from "../../../styles";

const slotProps = { inputAdornment: { position: "end" } };
const popperProps = { placement: "bottom-end" };

export default function DateFieldComponent({
	field,
	isGroupedFieldTop,
	isGroupedFieldBottom,
}) {
	const isGroupField = field?.config?.isGroupedField;

	const textFieldProps = {
		sx: {
			width: "100%",
			"& .MuiOutlinedInput-notchedOutline": {
				borderRadius: "8px",
			},
			...(isGroupField && {
				"& .MuiOutlinedInput-root": {
					"& fieldset": getFieldGroupStyle(
						isGroupedFieldTop,
						isGroupedFieldBottom
					),
					"&:hover fieldset": getFieldGroupStyle(
						isGroupedFieldTop,
						isGroupedFieldBottom
					),
					"&.Mui-focused fieldset": getFieldGroupStyle(
						isGroupedFieldTop,
						isGroupedFieldBottom
					),
				},
			}),
		},
		inputProps: {
			style: { textAlign: "left", fontSize: "14px" },
		},
	};

	return (
		<React.Fragment>
			<DateComponent
				field={field}
				popperProps={popperProps}
				slotProps={slotProps}
				textFieldProps={textFieldProps}
			/>
		</React.Fragment>
	);
}
