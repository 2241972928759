import React from "react";
import { MenuItemText, TextButton } from "../../../../../styles/twozo";
import Menu from "../../../../Elements/Menu";
import { Divider, MenuItem, useTheme } from "@mui/material";
import { access, accessName, checkPermission } from "../Utils";

const accessList = [access["canView"], access["canEdit"], access["canManage"]];

export default function AccessMenu(props) {
	const {
		userAccess,
		updateAccess,
		maximumPermissionLevel,
		canRemove = true,
		makeOwner,
		removeUser,
	} = props;
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const openAccessMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const closeAccessMenu = () => {
		setAnchorEl(null);
	};

	const handleAccessChange = (access) => {
		updateAccess(access);
		closeAccessMenu();
	};

	const handleMakeOwner = () => {
		makeOwner();
		closeAccessMenu();
	};

	const handleRemoveUser = () => {
		removeUser();
		closeAccessMenu();
	};

	return (
		<React.Fragment>
			<TextButton
				style={{
					fontSize: "15px",
					fontWeight: 500,
				}}
				onClick={openAccessMenu}
			>
				{accessName[userAccess]}
			</TextButton>

			<Menu anchorEl={anchorEl} open={open} onClose={closeAccessMenu}>
				{accessList
					.filter((access) =>
						checkPermission(access, maximumPermissionLevel)
					)
					.map((accessId) => (
						<MenuItem
							key={accessId}
							onClick={() => handleAccessChange(accessId)}
							style={{
								height: "40px",
							}}
						>
							<MenuItemText>{accessName[accessId]}</MenuItemText>
						</MenuItem>
					))}

				{((canRemove &&
					checkPermission(
						access["canManage"],
						maximumPermissionLevel
					)) ||
					checkPermission(
						access["owner"],
						maximumPermissionLevel
					)) && <Divider />}

				{checkPermission(access["owner"], maximumPermissionLevel) && (
					<MenuItem
						onClick={handleMakeOwner}
						style={{
							height: "40px",
						}}
					>
						<MenuItemText>Make Owner</MenuItemText>
					</MenuItem>
				)}

				{canRemove &&
					checkPermission(
						access["canManage"],
						maximumPermissionLevel
					) && (
						<MenuItem
							onClick={handleRemoveUser}
							style={{
								height: "40px",
							}}
						>
							<MenuItemText
								style={{
									fontWeight: 500,
									color: theme.palette.error.main,
								}}
							>
								Remove
							</MenuItemText>
						</MenuItem>
					)}
			</Menu>
		</React.Fragment>
	);
}
