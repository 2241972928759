import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { FormFieldName, MenuItemText } from "../../../../../styles/twozo";
import {
	useAddUserMutation,
	useUpdateUserMutation,
} from "../../../../../hooks/services/userManagement/user";
import { removeFieldsWithEmptyValues } from "../../../../../utils/common";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { Controller, useForm } from "react-hook-form";
import {
	emailPattern,
	phoneNumberPattern,
} from "../../../../../utils/validation";
import { usePipelineList } from "../../../../../hooks/services/deal/pipeline";
import TeamsDropdown from "./TeamsDropdown";
import MenuSearchBar from "../../../../Elements/MenuSearchBar";
import UsersMenu from "../../../../Elements/UsersMenu";
import RolesMenu from "../../../../Elements/RolesMenu";
import { removeInvalidPhoneNumberCharacters } from "../../../../../utils/phoneNumberUtils";
import { addUserFormFields } from "../../../../../utils/userManagementResources";

const maximumCharacters = 255;
const errorMessage = `Max. of ${maximumCharacters} characters are allowed`;

const validationConfig = {
	maxLength: {
		value: maximumCharacters,
		message: errorMessage,
	},
};

export default function AddForm(props) {
	const { isEditMode, onClose, isUserDataLoading, userData, isEditable } =
		props;
	const theme = useTheme();
	const addUserMutation = useAddUserMutation();
	const updateUserMutation = useUpdateUserMutation();

	const {
		handleSubmit,
		register,
		watch,
		setValue,
		control,
		reset,
		formState: { errors },
		clearErrors,
	} = useForm({
		values: userData,
		reValidateMode: false,
	});

	const { data: pipelineList } = usePipelineList();

	const [pipelineSearchValue, setPipelineSearchValue] = useState("");

	const selectedPipelineId = watch(addUserFormFields.pipelineId);

	useEffect(() => {
		if (pipelineList?.length > 0 && !selectedPipelineId) {
			setValue(addUserFormFields.pipelineId, pipelineList[0]?.id);
		}
	}, [pipelineList, setValue, selectedPipelineId]);

	const getUserRequestData = (userData) => {
		let modifiedUserData = { ...userData };

		if (modifiedUserData?.reportingManager) {
			modifiedUserData.reportingManagerId =
				modifiedUserData.reportingManager?.value;
		}
		if (modifiedUserData?.teams?.length > 0) {
			modifiedUserData.teamIds = modifiedUserData.teams.map(
				(team) => team?.value
			);
		}
		if (modifiedUserData?.role) {
			modifiedUserData.roleId = modifiedUserData.role?.value;
		}
		delete modifiedUserData.role;
		delete modifiedUserData.teams;
		delete modifiedUserData.reportingManager;

		return modifiedUserData;
	};

	const saveUser = (userData, isFormShouldClose) => {
		let addUserFormData = getUserRequestData(userData);

		removeFieldsWithEmptyValues(addUserFormData);
		addUserMutation.mutate(addUserFormData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.userAdded,
				});

				if (isFormShouldClose) {
					onClose();
				} else {
					// Resetting the form values after save and new
					reset();
				}
			},
			onError: (error) => {
				const errorObj = JSON.parse(error.message);
				const errorMessage = errorObj.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const handleSaveAndNew = (formData) => {
		let isFormShouldClose = false;
		saveUser(formData, isFormShouldClose);
	};

	const handleSave = (formData) => {
		let isFormShouldClose = true;
		saveUser(formData, isFormShouldClose);
	};

	const updateUser = (userData) => {
		let updatedUserData = getUserRequestData(userData);
		removeFieldsWithEmptyValues(updatedUserData);
		updateUserMutation.mutate(updatedUserData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.userUpdated,
				});
				onClose();
			},
			onError: (error) => {
				error = JSON.parse(error?.message);
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const handleChangePipelines = (event) => {
		const { value } = event.target;
		setPipelineSearchValue(value);
	};

	const filteredPipelines = useMemo(() => {
		if (Array.isArray(pipelineList)) {
			if (pipelineSearchValue.trim()) {
				return pipelineList.filter((pipeline) =>
					pipeline?.name
						?.toLowerCase()
						?.startsWith(pipelineSearchValue.toLowerCase())
				);
			}
			return pipelineList;
		}
		return [];
	}, [pipelineList, pipelineSearchValue]);

	const handleSelectReportingManager = (selectedUser) => {
		setValue(addUserFormFields.reportingManager, selectedUser);
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					No Results Found
				</Typography>
			</Stack>
		);
	};

	const onSelectRole = (selectedRole, onChange) => {
		onChange(selectedRole);
		clearErrors(addUserFormFields.role);
	};

	return (
		<React.Fragment>
			{isUserDataLoading ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="100vh"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : (
				<Box
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box p={2}>
						<Stack direction="row" justifyContent="space-between">
							<Typography fontWeight={600} fontSize={17}>
								{isEditMode ? "Edit User" : "Add User"}
							</Typography>

							<IconButton
								size="small"
								style={{ padding: 0 }}
								onClick={onClose}
							>
								{CloseIcon(24, 24, theme.palette.primary.main)}
							</IconButton>
						</Stack>
					</Box>

					<Divider />

					{addUserMutation.isLoading ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="100vh"
						>
							<CircularProgress size="30px" />
						</Stack>
					) : (
						<Box
							p={2}
							style={{
								flex: "1 1 auto",
								minHeight: 0,
								overflowY: "auto",
							}}
						>
							<Grid
								container
								direction="row"
								alignItems="baseline"
								justifyContent="center"
								spacing={2}
							>
								<Grid item xs={12} md={6}>
									<Stack>
										<Stack direction="row">
											<FormFieldName
												color={
													errors.firstName &&
													theme.palette.error.main
												}
											>
												First Name
											</FormFieldName>
											<span
												style={{
													color: theme.palette.error
														.main,
													marginLeft: "5px",
												}}
											>
												*
											</span>
										</Stack>

										<TextField
											placeholder="First Name"
											{...register(
												addUserFormFields.firstName,
												{
													required:
														"This field is required",
													...validationConfig,
												}
											)}
											onChange={() => {
												clearErrors(
													addUserFormFields.firstName
												);
											}}
											error={Boolean(errors.firstName)}
										/>
									</Stack>
									{errors.firstName && (
										<Typography
											fontSize={13}
											fontWeight={500}
											color={theme.palette.error.main}
											pl={1}
										>
											{errors.firstName.message}
										</Typography>
									)}
								</Grid>

								<Grid item xs={12} md={6}>
									<Stack spacing={0.5}>
										<FormFieldName
											color={
												errors.lastName &&
												theme.palette.error.main
											}
										>
											Last Name
										</FormFieldName>

										<TextField
											placeholder="Last Name"
											{...register(
												addUserFormFields.lastName,
												{
													...validationConfig,
												}
											)}
											onChange={() => {
												clearErrors(
													addUserFormFields.lastName
												);
											}}
											error={Boolean(errors.lastName)}
										/>
									</Stack>
									{errors.lastName && (
										<Typography
											fontSize={13}
											fontWeight={500}
											color={theme.palette.error.main}
											pl={1}
										>
											{errors.lastName.message}
										</Typography>
									)}
								</Grid>

								<Grid item xs={12}>
									<Stack spacing={0.5}>
										<FormFieldName
											color={
												errors.jobTitle &&
												theme.palette.error.main
											}
										>
											Job Title
										</FormFieldName>

										<TextField
											placeholder="eg: sales representative"
											{...register(
												addUserFormFields.jobTitle,
												{
													...validationConfig,
												}
											)}
											onChange={() => {
												clearErrors(
													addUserFormFields.jobTitle
												);
											}}
											error={Boolean(errors.jobTitle)}
										/>
									</Stack>
									{errors.jobTitle && (
										<Typography
											fontSize={13}
											fontWeight={500}
											color={theme.palette.error.main}
											pl={1}
										>
											{errors.jobTitle.message}
										</Typography>
									)}
								</Grid>

								<Grid item xs={12}>
									<Stack>
										<Stack direction="row">
											<FormFieldName
												color={
													errors.email &&
													theme.palette.error.main
												}
												style={{
													opacity: isEditMode
														? 0.6
														: 1,
												}}
											>
												Email
											</FormFieldName>
											<span
												style={{
													color: theme.palette.error
														.main,
													marginLeft: "5px",
													opacity: isEditMode
														? 0.6
														: 1,
												}}
											>
												*
											</span>
										</Stack>

										<TextField
											placeholder="eg: abc@gmail.com"
											sx={{
												"& .MuiOutlinedInput-notchedOutline":
													{
														opacity: isEditMode
															? 0.6
															: 1,
													},
											}}
											{...register(
												addUserFormFields.email,
												{
													required:
														"This field is required",
													pattern: {
														value: emailPattern,
														message:
															"Please enter a valid email id",
													},
												}
											)}
											onChange={() => {
												clearErrors(
													addUserFormFields.email
												);
											}}
											error={Boolean(errors.email)}
											disabled={isEditMode}
										/>
									</Stack>
									{errors.email && (
										<Typography
											fontSize={13}
											fontWeight={500}
											color={theme.palette.error.main}
											pl={1}
										>
											{errors.email.message}
										</Typography>
									)}
								</Grid>

								<Grid item xs={12}>
									<Stack spacing={0.5}>
										<FormFieldName
											color={
												errors.mobile &&
												theme.palette.error.main
											}
										>
											Mobile
										</FormFieldName>

										<TextField
											placeholder="98765 43210"
											{...register(
												addUserFormFields.mobile,
												{
													pattern: {
														value: phoneNumberPattern,
														message:
															// eslint-disable-next-line quotes
															'Phone number must be 5-17 digits, with only characters, spaces, "( )", "-", or "+" allowed.',
													},
												}
											)}
											onChange={(event) => {
												let phoneValue =
													event.target.value.trimStart();
												phoneValue =
													removeInvalidPhoneNumberCharacters(
														phoneValue
													);
												setValue(
													addUserFormFields.mobile,
													phoneValue
												);
												clearErrors(
													addUserFormFields.mobile
												);
											}}
											error={Boolean(errors.mobile)}
										/>
									</Stack>
									{errors.mobile && (
										<Typography
											fontSize={13}
											fontWeight={500}
											color={theme.palette.error.main}
											pl={1}
										>
											{errors.mobile.message}
										</Typography>
									)}
								</Grid>

								<Grid item xs={12}>
									<Stack spacing={0.5}>
										<FormFieldName
											style={{
												opacity: !isEditable ? 0.6 : 1,
											}}
										>
											Reporting Manager
										</FormFieldName>

										<UsersMenu
											{...register(
												addUserFormFields.reportingManager
											)}
											value={watch(
												addUserFormFields.reportingManager
											)}
											onChange={
												handleSelectReportingManager
											}
											filterCriteria={{
												excludeUserId: userData?.id,
											}}
										/>
									</Stack>
								</Grid>

								<Grid item xs={12}>
									<Stack>
										<Stack direction="row">
											<FormFieldName>
												Pipeline
											</FormFieldName>
											<span
												style={{
													color: theme.palette.error
														.main,
													marginLeft: "5px",
												}}
											>
												*
											</span>
										</Stack>

										<Select
											displayEmpty
											value={selectedPipelineId || ""}
											{...register(
												addUserFormFields.pipelineId,
												{
													onChange: (event) =>
														setValue(
															addUserFormFields.pipelineId,
															event.target.value
														),
												}
											)}
											onClose={() =>
												setPipelineSearchValue("")
											}
											renderValue={(selectedPipeline) => {
												const selectedOption =
													pipelineList?.find(
														(pipeline) =>
															pipeline.id ===
															selectedPipeline
													);

												return selectedOption?.name;
											}}
											MenuProps={{
												PaperProps: {
													style: {
														maxHeight: "170px",
														maxWidth: "400px",
													},
												},
											}}
										>
											<Box
												p={0.5}
												marginTop="-8px"
												sx={{
													position: "sticky",
													top: 0,
													zIndex: 1,
													backgroundColor: "#fff",
												}}
											>
												<MenuSearchBar
													value={pipelineSearchValue}
													onChange={
														handleChangePipelines
													}
												/>
											</Box>

											{filteredPipelines?.length > 0
												? filteredPipelines.map(
														(pipeline) => (
															<MenuItem
																key={
																	pipeline.id
																}
																value={
																	pipeline.id
																}
															>
																<MenuItemText whiteSpace="wrap">
																	{
																		pipeline.name
																	}
																</MenuItemText>
															</MenuItem>
														)
													)
												: renderEmptyView()}
										</Select>
									</Stack>
								</Grid>

								<Grid item xs={12}>
									<Stack spacing={0.5}>
										<FormFieldName>Teams</FormFieldName>

										<Controller
											name={addUserFormFields.teams}
											control={control}
											render={({
												field: { value, onChange },
											}) => (
												<TeamsDropdown
													selectedTeams={value || []}
													onChange={onChange}
												/>
											)}
										/>
									</Stack>
								</Grid>

								<Grid item xs={12}>
									<Stack>
										<Stack direction="row">
											<FormFieldName
												color={
													errors.role &&
													theme.palette.error.main
												}
												style={{
													opacity: !isEditable
														? 0.6
														: 1,
												}}
											>
												Role
											</FormFieldName>
											<span
												style={{
													color: theme.palette.error
														.main,
													marginLeft: "5px",
												}}
											>
												*
											</span>
										</Stack>

										<Controller
											name={addUserFormFields.role}
											control={control}
											rules={{
												required:
													"This field is required",
											}}
											render={({
												field: { value, onChange },
											}) => (
												<RolesMenu
													error={!!errors.role}
													value={value}
													onChange={(selectedRole) =>
														onSelectRole(
															selectedRole,
															onChange
														)
													}
													disabled={!isEditable}
													onRemoveRole={() =>
														onChange("")
													}
													menuPositionConfig={{
														anchorOrigin: {
															vertical: "top",
															horizontal: "left",
														},
														transformOrigin: {
															vertical: "bottom",
															horizontal: "left",
														},
													}}
												/>
											)}
										/>

										{errors.role && (
											<Typography
												fontSize={13}
												fontWeight={500}
												color={theme.palette.error.main}
												pl={1}
											>
												{errors.role.message}
											</Typography>
										)}
									</Stack>
								</Grid>
							</Grid>
						</Box>
					)}

					<Divider />

					<Box p={2}>
						{isEditMode ? (
							<Button
								variant="contained"
								color="primary"
								sx={{
									"&.Mui-disabled": {
										backgroundColor:
											theme.palette.primary.main,
										color: "rgba(255,255,255, 0.6)",
									},
								}}
								disableElevation
								disabled={updateUserMutation.isLoading}
								onClick={handleSubmit(updateUser)}
							>
								Update
							</Button>
						) : (
							<Stack direction="row" spacing={2}>
								<Box>
									<Button
										variant="contained"
										color="primary"
										sx={{
											"&.Mui-disabled": {
												backgroundColor:
													theme.palette.primary.main,
												color: "rgba(255,255,255, 0.6)",
											},
										}}
										disabled={addUserMutation.isLoading}
										disableElevation
										onClick={handleSubmit(handleSave)}
									>
										Save
									</Button>
								</Box>

								<Box>
									<Button
										variant="contained"
										color="primary"
										sx={{
											"&.Mui-disabled": {
												backgroundColor:
													theme.palette.primary.main,
												color: "rgba(255,255,255, 0.6)",
											},
										}}
										disabled={addUserMutation.isLoading}
										disableElevation
										onClick={handleSubmit(handleSaveAndNew)}
									>
										Save & New
									</Button>
								</Box>
							</Stack>
						)}
					</Box>
				</Box>
			)}
		</React.Fragment>
	);
}
