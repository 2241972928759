// format mail address for request
export const getFormattedMailAddress = (mailAddress) => {
	if (!mailAddress) return;

	if (mailAddress?.contactEmailId) {
		return {
			name: mailAddress?.name,
			value: mailAddress?.id,
			email: {
				id: mailAddress.contactEmailId,
				value: mailAddress?.mailId,
			},
		};
	} else {
		return { newMailId: mailAddress?.mailId };
	}
};
