import React from "react";
import {
	Box,
	Button,
	Divider,
	FormControlLabel,
	IconButton,
	Radio,
	RadioGroup,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { useRolesList } from "../../../../../hooks/services/userManagement/roles";
import { scope } from "../../../../../utils/PermissionUtils";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { Controller, useForm } from "react-hook-form";
import RolesMenu from "../../../../Elements/RolesMenu";

const maximumCharacters = 255;
const maxLengthErrorMessage = `Max. of ${maximumCharacters} characters are allowed`;
const requiredErrorMessage = "This field is required.";

const validationConfig = {
	required: {
		value: true,
		message: requiredErrorMessage,
	},
	maxLength: {
		value: maximumCharacters,
		message: maxLengthErrorMessage,
	},
};

const formFields = {
	roleToClone: "roleToClone",
	roleName: "roleName",
	selectedAccess: "selectedAccess",
};

export default function CreateRole(props) {
	const { closeRoleDialog, onNextClick } = props;
	const theme = useTheme();

	const { data: rolesList } = useRolesList();

	const {
		register,
		control,
		formState: { errors },
		handleSubmit,
		clearErrors,
	} = useForm({
		defaultValues: {
			roleToClone: null,
			roleName: "",
			selectedAccess: scope.globalAccess,
		},
		reValidateMode: false,
	});

	const styles = {
		text: {
			fontSize: "14px",
			fontWeight: 500,
			padding: "5px",
		},
		subText: {
			fontSize: "14px",
			color: "rgba(0, 0, 0, 0.6)",
		},
	};

	const handleNextClick = (newRoleDetails) => {
		const roleNameExists = rolesList.some(
			(roleDetails) =>
				roleDetails.name.toLowerCase() ===
				newRoleDetails.roleName.toLowerCase()
		);
		if (roleNameExists) {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.roleNameValidation,
			});
		} else if (newRoleDetails) {
			onNextClick(
				newRoleDetails.roleToClone.value,
				newRoleDetails.roleName,
				newRoleDetails.selectedAccess
			);
		}
	};

	const handleRoleName = (selectedRole, onChange) => {
		onChange(selectedRole);
		clearErrors(formFields.roleToClone);
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
					>
						<Typography fontSize={17} fontWeight={600}>
							Create Role
						</Typography>

						<IconButton
							onClick={closeRoleDialog}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(24, 24, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<Divider />

				<Box
					p={2}
					style={{
						flex: "1 1 auto",
						minHeight: 0,
					}}
				>
					<Stack spacing={1.5}>
						<Stack>
							<Stack direction="row" alignItems="center">
								<Typography
									style={styles.text}
									color={
										errors.roleToClone &&
										theme.palette.error.main
									}
								>
									Role to Clone
								</Typography>
								<span
									style={{
										color: theme.palette.error.main,
										marginLeft: "5px",
									}}
								>
									*
								</span>
							</Stack>
							<Controller
								name={formFields.roleToClone}
								control={control}
								rules={{
									required: { ...validationConfig.required },
								}}
								render={({ field: { value, onChange } }) => (
									<RolesMenu
										value={value}
										onChange={(selectedRole) =>
											handleRoleName(
												selectedRole,
												onChange
											)
										}
										onRemoveRole={() => onChange("")}
										error={Boolean(errors?.roleToClone)}
									/>
								)}
							/>
							{errors?.roleToClone?.message && (
								<Typography color="error" style={styles.text}>
									{errors.roleToClone.message}
								</Typography>
							)}
						</Stack>

						<Stack>
							<Stack direction="row" alignItems="center">
								<Typography
									style={styles.text}
									color={
										errors?.roleName?.message &&
										theme.palette.error.main
									}
								>
									Role Name
								</Typography>
								<span
									style={{
										color: theme.palette.error.main,
										marginLeft: "5px",
									}}
								>
									*
								</span>
							</Stack>
							<TextField
								placeholder="Eg: Marketing Manager"
								{...register(formFields.roleName, {
									...validationConfig,
									onChange: () => {
										clearErrors(formFields.roleName);
									},
								})}
								error={Boolean(errors?.roleName)}
							/>
							{errors?.roleName?.message && (
								<Typography color="error" style={styles.text}>
									{errors.roleName.message}
								</Typography>
							)}
						</Stack>

						<Stack>
							<Typography style={styles.text}>
								Set the default scope for this Role
							</Typography>
							<Controller
								name={formFields.selectedAccess}
								control={control}
								render={({ field }) => (
									<RadioGroup {...field}>
										<Stack pt={1}>
											<FormControlLabel
												value={scope.globalAccess}
												control={<Radio />}
												label={
													<Stack>
														<Typography
															fontSize={15}
														>
															Global Access
														</Typography>
														<Typography
															style={
																styles.subText
															}
														>
															Can access all
															records
														</Typography>
													</Stack>
												}
											/>
										</Stack>

										<Stack pt={1}>
											<FormControlLabel
												value={scope.territoryAccess}
												control={<Radio />}
												label={
													<Stack>
														<Typography
															fontSize={15}
														>
															Territory Access
														</Typography>
														<Typography
															style={
																styles.subText
															}
														>
															Access to record in
															their territory and
															assigned records.
														</Typography>
													</Stack>
												}
											/>
										</Stack>

										<Stack pt={1}>
											<FormControlLabel
												value={scope.restrictedAccess}
												control={<Radio />}
												label={
													<Stack>
														<Typography
															fontSize={15}
														>
															Restricted Access
														</Typography>
														<Typography
															style={
																styles.subText
															}
														>
															Access only to
															assigned records
														</Typography>
													</Stack>
												}
											/>
										</Stack>
									</RadioGroup>
								)}
							></Controller>
						</Stack>
					</Stack>
				</Box>

				<Divider />

				<Box p={2}>
					<Button
						variant="contained"
						color="primary"
						disableElevation
						onClick={handleSubmit(handleNextClick)}
					>
						Next
					</Button>
				</Box>
			</Box>
		</React.Fragment>
	);
}
