import React, { useEffect, useMemo, useState } from "react";
import Autocomplete from "../../../../../Autocomplete";
import { getFieldDropdownData } from "../../../../../../../api/fields/config/configApi";
import { useQuery } from "@tanstack/react-query";
import { getDropdownKeyByFieldId } from "../../../../../../../utils/queryKeys";

export default function MultiSelectBaseComponent({
	onChange,
	value,
	field,
	id,
	error,
	inputRef,
	multiSelectSx,
	clearErrors,
}) {
	const [multiSelectValue, setMultiSelectValue] = useState([]);

	const multiSelectKey = getDropdownKeyByFieldId(field?.id);

	const { data: options } = useQuery(
		multiSelectKey,
		() =>
			getFieldDropdownData({
				id: field?.id,
			}),
		{
			enabled: !!field?.id,
			select: (data) => data?.options,
		}
	);

	const defaultOptions = useMemo(() => {
		const defaultValue = options?.filter((option) => option.isDefault);
		if (defaultValue) {
			return defaultValue;
		}
		return [];
	}, [options]);

	useEffect(() => {
		if (!value) {
			let value = defaultOptions.map((data) => data.value);
			setMultiSelectValue(defaultOptions);
			onChange(value);
		}
	}, [defaultOptions, onChange, value]);

	const handleChange = (selectedOption, onChange) => {
		const selectedValue =
			Array.isArray(selectedOption) &&
			selectedOption.map((data) => data.value);
		const value = selectedValue.length > 0 ? selectedValue : "";
		onChange(value);
		setMultiSelectValue(selectedOption);
		clearErrors(id);
	};

	// This useEffect will run only initial render
	useEffect(() => {
		// Check if the server value needs to be formatted
		const isNotServerSideFormatted =
			Array.isArray(value) &&
			value.some((selectedValue) => typeof selectedValue === "object");

		if (isNotServerSideFormatted) {
			setMultiSelectValue(value);
			onChange(value.map((selectedValue) => selectedValue?.value));
		}
	}, [options, value, onChange]);

	return (
		<React.Fragment>
			<Autocomplete
				value={multiSelectValue}
				options={options || []}
				onChange={(_, selectedOption) =>
					handleChange(selectedOption, onChange)
				}
				getOptionLabel={(option) => option.name}
				inputRef={inputRef}
				error={error}
				freeSolo={false}
				noOptionsText="No results found"
				readOnly={true}
				autocompleteSx={multiSelectSx}
				placeholder={field?.placeHolder}
			/>
		</React.Fragment>
	);
}
