import React, { useMemo, useState } from "react";
import AddActivity from "../AddActivity";
import { useQueryClient } from "@tanstack/react-query";
import { removeFieldsWithEmptyValues } from "../../../../utils/common";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { Box, CircularProgress, Stack } from "@mui/material";
import {
	useActivityData,
	useEditActivity,
} from "../../../../hooks/services/activities";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import { twozoStyles } from "../../../../styles/twozo";

export default function EditActivity(props) {
	const {
		onClose,
		activityId,
		toDoActivitesKey,
		completedActivitesKey,
		isEditActivityDialogOpened,
		openEditActivityDialog,
	} = props;
	const queryClient = useQueryClient();
	const classes = twozoStyles();

	const { data: activityFormData, isLoading: isLoadingEditActivity } =
		useActivityData(activityId);
	const updateMutation = useEditActivity(activityId);

	const formattedActivityData = useMemo(() => {
		if (activityFormData) {
			let formData = { ...activityFormData };

			if (formData?.contacts?.length > 0) {
				let formattedContacts = [];
				for (let contact of formData.contacts) {
					formattedContacts.push({
						name: contact?.fullName,
						value: contact?.id,
					});
				}
				formData.contacts = formattedContacts;
			}

			if (formData?.availability?.name) {
				formData.availability = formData.availability.value;
			}

			if (formData?.reminder?.name) {
				formData.reminder = formData.reminder.value;
			}

			if (formData?.priority?.name) {
				formData.priority = formData.priority.value;
			}

			if (formData?.status?.name) {
				formData.status = formData.status.value;
			}

			if (formData?.guests?.length > 0) {
				let formattedGuestsData = [];

				for (let guest of formData.guests) {
					if (guest?.id) {
						formattedGuestsData.push({
							name: guest.name,
							value: guest.id,
							email: guest.email,
						});
					} else {
						formattedGuestsData.push(guest);
					}
				}

				formData.guests = formattedGuestsData;
			}

			formData.typeId = formData?.activityType?.id;

			formData.companyData = formData?.company
				? {
						name: formData.company?.name,
						value: formData.company?.id,
					}
				: null;

			formData.dealData = formData?.deal
				? {
						name: formData.deal?.title,
						value: formData.deal?.id,
					}
				: null;

			formData.note = formData?.note?.note;

			formData.assignee = {
				name: formData?.assignee?.name,
				value: formData?.assignee?.id,
			};

			delete formData.activityType;
			delete formData.company;
			delete formData.deal;
			return formData;
		}
	}, [activityFormData]);

	const [newActivityData, setNewActivityData] = useState({});
	const [isAddActivityDialogOpened, setIsAddActivityDialogOpened] =
		useState(false);
	const openAddActivityDialog = () => {
		setIsAddActivityDialogOpened(true);
	};

	const closeAddActivityDialog = () => {
		setIsAddActivityDialogOpened(false);
		setNewActivityData({});
		onClose();
	};

	const getFormattedGuestData = (selectedGuests) => {
		let formattedGuests = {
			contacts: [],
			emails: [],
		};

		selectedGuests.forEach((guest) => {
			if (guest.value === -1) {
				// if new email address
				formattedGuests.emails.push({ email: guest.email.value });
			} else {
				formattedGuests.contacts.push({
					id: guest.value,
					emailId: guest.email.id,
				});
			}
		});
		return formattedGuests;
	};

	const handleUpdateActivity = (updatedActivityData) => {
		let modifiedActivityData = { ...updatedActivityData };
		if (modifiedActivityData?.assignee) {
			modifiedActivityData.assigneeId =
				modifiedActivityData.assignee?.value;
		}

		if (modifiedActivityData?.companyData) {
			modifiedActivityData.companyId =
				modifiedActivityData.companyData?.value;
		}

		if (modifiedActivityData?.dealData) {
			modifiedActivityData.dealId = modifiedActivityData.dealData?.value;
		}

		if (modifiedActivityData?.contacts?.length > 0) {
			modifiedActivityData.contactIds = modifiedActivityData.contacts.map(
				(contact) => contact?.value
			);
		}
		if (modifiedActivityData?.guests?.length > 0) {
			modifiedActivityData.guests = getFormattedGuestData(
				modifiedActivityData.guests
			);
		}

		delete modifiedActivityData?.assignee;
		delete modifiedActivityData?.companyData;
		delete modifiedActivityData?.dealData;
		delete modifiedActivityData?.contacts;

		removeFieldsWithEmptyValues(modifiedActivityData);
		updateMutation.mutate(modifiedActivityData, {
			onSuccess: (newActivityData) => {
				queryClient.invalidateQueries(toDoActivitesKey);
				queryClient.invalidateQueries(completedActivitesKey);
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.activityUpdated,
				});
				onClose();

				if (newActivityData?.isActivityCreationRequired) {
					setTimeout(() => {
						openAddActivityDialog();
						setNewActivityData(newActivityData);
					}, 300);
				}
			},
		});
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isEditActivityDialogOpened}
				onOpen={openEditActivityDialog}
				onClose={onClose}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					{isLoadingEditActivity ? (
						<Stack
							height="100vh"
							alignItems="center"
							justifyContent="center"
						>
							<CircularProgress size={30} />
						</Stack>
					) : (
						<AddActivity
							onUpdate={handleUpdateActivity}
							onClose={onClose}
							activityFormData={formattedActivityData}
							isActivityEditMode={true}
							updateInProgress={updateMutation.isLoading}
						/>
					)}
				</Box>
			</CustomSwipeableDrawer>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddActivityDialogOpened}
				onOpen={openAddActivityDialog}
				onClose={closeAddActivityDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddActivity
						onClose={closeAddActivityDialog}
						openAddActivityDialog={openAddActivityDialog}
						companyValue={
							newActivityData?.company
								? {
										name: newActivityData?.company?.name,
										value: newActivityData?.company?.id,
									}
								: null
						}
						dealData={
							newActivityData?.deal
								? {
										name: newActivityData.deal?.title,
										value: newActivityData.deal?.id,
									}
								: null
						}
						contacts={
							newActivityData?.contact
								? [
										{
											name: newActivityData?.contact
												?.name,
											value: newActivityData?.contact?.id,
										},
									]
								: null
						}
					/>
				</Box>
			</CustomSwipeableDrawer>
		</React.Fragment>
	);
}
