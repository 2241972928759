import React, { useState } from "react";
import ToolBar from "../../../EmailComposer/EmailComposerUI/ToolBar";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import LexicalAutoLinkPlugin from "../../../EmailComposer/EmailComposerUI/Plugin/AutoLinkPlugin";
import LexicalClickableLinkPlugin from "@lexical/react/LexicalClickableLinkPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { Box } from "@mui/material";
import PlaceHoldersMenu from "../../../EmailComposer/EmailComposerUI/ToolBar/PlaceHoldersMenu";
import HtmlPlugin from "../../../EmailComposer/EmailComposerUI/Plugin/HtmlPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection } from "lexical";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import Menu from "../../../../Elements/Menu";
import { onFocusEmailComposer } from "../../../EmailComposer/EmailComposerUI/ToolBar/utils/onFocusEmailComposer";

export default function TemplateInput(props) {
	const { initialTemplateContent } = props;

	const [editor] = useLexicalComposerContext();

	const [placeHolderMenuElement, setPlaceHolderMenuElement] = useState(null);
	const isPlaceHolderMenuOpened = Boolean(placeHolderMenuElement);

	const openPlaceHolderMenu = (event) => {
		setPlaceHolderMenuElement(event.currentTarget);
	};

	const closePlaceHolderMenu = () => {
		setPlaceHolderMenuElement(null);
	};

	const onSelectPlaceHolder = (placeHolderField) => {
		let placeHolderFieldName = `{${placeHolderField?.displayName}_${placeHolderField?.id}}`;

		editor.update(() => {
			const selection = $getSelection();
			if (selection) {
				selection.insertText(`$${placeHolderFieldName}`);
			}
		});
		onFocusEmailComposer(editor);
	};

	return (
		<React.Fragment>
			{/*Rich Text Editor*/}
			<Box>
				<ToolBar
					hiddenTemplateMenu={true}
					onClickPlaceHolder={openPlaceHolderMenu}
					focusPlaceHolderIcon={isPlaceHolderMenuOpened}
				/>

				<Box>
					<RichTextPlugin
						contentEditable={
							<ContentEditable
								style={{
									height: "347px",
									padding: "12px",
									overflowY: "auto",
									outline: "none",
									fontSize: "14px",
									fontFamily: "Lexend",
								}}
							/>
						}
						ErrorBoundary={LexicalErrorBoundary}
					/>
					<TabIndentationPlugin />
					<HistoryPlugin />
					<LexicalAutoLinkPlugin />
					<LexicalClickableLinkPlugin />
					<HtmlPlugin initialHtml={initialTemplateContent} />
					<AutoFocusPlugin />
				</Box>

				<Menu
					width="300px"
					anchorEl={placeHolderMenuElement}
					open={isPlaceHolderMenuOpened}
					onClose={closePlaceHolderMenu}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					sx={{
						"& .MuiMenu-list": {
							paddingTop: "0px",
						},
					}}
				>
					<PlaceHoldersMenu
						closePlaceHolderMenu={closePlaceHolderMenu}
						onSelectPlaceHolder={onSelectPlaceHolder}
					/>
				</Menu>
			</Box>
		</React.Fragment>
	);
}
