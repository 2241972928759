import React, { useState } from "react";
import Autocomplete from "../../../../Elements/Autocomplete";
import {
	Avatar,
	Box,
	Chip,
	CircularProgress,
	createFilterOptions,
	MenuItem,
	Paper,
	Skeleton,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { emailPattern } from "../../../../../utils/validation";
import { MenuItemText, Tooltip } from "../../../../../styles/twozo";
import { useAuth } from "../../../../../hooks/auth";
import { PERMISSIONS } from "../../../../../utils/Auth";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";
import { useDebouncedCallback } from "use-debounce";
import { useContactListWithEmails } from "../../../../../hooks/services/contact";

const CustomizedPaper = function (props) {
	return (
		<Paper
			{...props}
			style={{
				...props.style,
				borderRadius: "8px",
				boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
			}}
		/>
	);
};

export default function EmailRecipientInput(props) {
	const {
		value,
		handleRecipientsChange,
		renderTags,
		disabled = false,
		recipientInputSx,
		placeholder,
	} = props;

	const theme = useTheme();
	const [focussed, setFocussed] = useState(false);
	const [recipientInputValue, setRecipientInputValue] = useState("");
	const [invalidMailIndex, setInvalidMailIndex] = useState("");

	const { isUserAllowedFor } = useAuth();
	const isRecipientsListEnabled =
		isUserAllowedFor(PERMISSIONS.contact.view) &&
		Boolean(recipientInputValue);
	const {
		data: recipients,
		isLoading: isLoadingRecipients,
		isFetching: isFetchingRecipients,
		hasNextPage: hasNextPage,
		fetchNextPage: fetchNextPage,
	} = useContactListWithEmails(isRecipientsListEnabled, recipientInputValue);

	const lastOptionRef = useLastRowRef(
		fetchNextPage,
		hasNextPage,
		isFetchingRecipients
	);

	const onRecipientInputChange = (event) => {
		const { value } = event.target;
		setRecipientInputValue(value?.trimStart());
	};

	const onInputChange = useDebouncedCallback(
		(event) => {
			onRecipientInputChange(event);
		},
		500 // delay in ms
	);

	const getNewEmailValue = (emailValue) => {
		let newEmailAddress = {
			name: "",
			value: -1,
			email: {
				id: -1,
				value: emailValue,
			},
		};
		return newEmailAddress;
	};

	const isExistingRecipient = (inputValue) => {
		return value?.some((option) => inputValue === option?.email?.value);
	};

	const filteredValues = createFilterOptions({
		matchFrom: "start",
		stringify: (option) => option?.name || option?.email?.value,
	});

	const filterOptions = (options, params) => {
		if (isLoadingRecipients) {
			return options;
		}
		const { inputValue } = params;
		const filteredOption = filteredValues(options, params);

		if (inputValue.trim() && !isExistingRecipient(inputValue)) {
			let newEmail = getNewEmailValue(inputValue);
			filteredOption.unshift(newEmail);
		}
		return filteredOption;
	};

	const onChangeRecipient = (event, emailValues) => {
		const newEmailValue = event.target.value;

		if (!isExistingRecipient(newEmailValue)) {
			if (newEmailValue) {
				let newEmail = getNewEmailValue(newEmailValue);
				handleRecipientsChange([...value, newEmail]);
			} else {
				handleRecipientsChange(emailValues);
			}
		}
		setRecipientInputValue("");
	};

	const onBlurRecipientInput = (event) => {
		const inputValue = event.target.value;

		if (!isExistingRecipient(inputValue) && inputValue.trim()) {
			let newEmail = getNewEmailValue(inputValue);
			filterOptions(recipients, { inputValue: inputValue });
			handleRecipientsChange([...value, newEmail]);
		}
		setRecipientInputValue("");
		setFocussed(false);
	};

	const onMouseOverRenderTags = (option, index) => {
		if (!emailPattern.test(option?.email?.value)) {
			setInvalidMailIndex(index);
		}
	};

	const onMouseOutRenderTags = () => {
		setInvalidMailIndex("");
	};

	const renderOption = (option) => {
		let isExistingEmail = Boolean(option?.name);

		return (
			<MenuItem
				style={{
					minHeight: "40px",
					width: "100%",
				}}
			>
				<Stack>
					{isExistingEmail ? (
						<MenuItemText>{option.name}</MenuItemText>
					) : null}

					<MenuItemText
						color={
							isExistingEmail
								? "rgba(0, 0, 0, 0.6)"
								: "rgba(0, 0, 0)"
						}
					>
						{option?.email?.value}
					</MenuItemText>
				</Stack>
			</MenuItem>
		);
	};

	const CustomListboxComponent = function (props) {
		return (
			<Box {...props}>
				{props.children}

				<Box ref={lastOptionRef}>
					{!isLoadingRecipients &&
					isFetchingRecipients &&
					hasNextPage ? (
						<MenuItem
							style={{
								height: "40px",
								width: "100%",
							}}
						>
							<Skeleton width="200px" height="16px" />
						</MenuItem>
					) : null}
				</Box>
			</Box>
		);
	};

	return (
		<React.Fragment>
			<Autocomplete
				fullWidth
				freeSolo={Boolean(recipientInputValue)}
				open={Boolean(recipientInputValue)}
				options={recipients || []}
				value={value || []}
				limitTags={1}
				disabled={disabled}
				onFocus={() => {
					setFocussed(true);
				}}
				loading={isLoadingRecipients}
				loadingText={
					<Stack
						alignItems="center"
						justifyContent="center"
						height="140px"
					>
						<CircularProgress size="18px" />
					</Stack>
				}
				ListboxComponent={CustomListboxComponent}
				filterOptions={filterOptions}
				paperComponent={CustomizedPaper}
				onChange={onChangeRecipient}
				renderTags={
					renderTags ||
					((value, getTagProps) =>
						value.map((option, index) => (
							<Tooltip
								key={index}
								placement="top"
								open={invalidMailIndex === index}
								title="Please provide a valid email address before sending it."
							>
								<Chip
									key={index}
									size="small"
									color="secondary"
									deleteIcon={CloseIcon(16, 16, "#000")}
									onMouseOver={() =>
										onMouseOverRenderTags(option, index)
									}
									onMouseOut={() => onMouseOutRenderTags()}
									sx={{
										borderRadius: "8px",
										backgroundColor: emailPattern.test(
											option?.email?.value
										)
											? theme.palette.secondary.main
											: "rgba(235, 84, 84, 0.12)",
									}}
									avatar={
										<Avatar style={{ color: "#fff" }}>
											{option.name
												?.charAt(0)
												.toUpperCase() ||
												option?.email?.value
													?.charAt(0)
													.toUpperCase()}
										</Avatar>
									}
									label={
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
											justifyContent="center"
										>
											{option?.email?.value ? (
												<>
													<Typography
														fontSize={14}
														color="#000"
														hidden={
															option.value === -1
														}
													>
														{option?.name}
													</Typography>

													<Typography
														color="#000"
														hidden={
															option.value === -1
														}
													>
														-
													</Typography>

													<Typography
														fontSize={14}
														color="rgba(0, 0, 0, 0.6)"
													>
														{option.email.value}
													</Typography>
												</>
											) : null}
										</Stack>
									}
									{...getTagProps({ index })}
								/>
							</Tooltip>
						)))
				}
				renderOption={(props, option) => (
					<Box
						key={option?.emailId}
						{...props}
						style={{ padding: "0px" }}
					>
						{renderOption(option)}
					</Box>
				)}
				getOptionLabel={(option) => {
					return option?.name;
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						onChange={onInputChange}
						value={recipientInputValue}
						placeholder={
							placeholder || (focussed ? "Enter recipients" : "")
						}
						onBlur={onBlurRecipientInput}
						sx={{
							"& .MuiInputBase-root": {
								minHeight: "42px",
							},
							"& .MuiOutlinedInput-root": {
								"& fieldset": {
									border: "none",
								},
								"&:hover fieldset": {
									border: "none",
								},
								"&.Mui-focused fieldset": {
									border: "none",
								},
							},
							...recipientInputSx,
						}}
						inputProps={{
							...params.inputProps,
							style: {
								...params.inputProps?.style,
								fontSize: "14px",
							},
						}}
					/>
				)}
			/>
		</React.Fragment>
	);
}
