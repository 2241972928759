import React, { useEffect } from "react";
import PermissionList from "../PermisionList";
import { usePermissionContext } from "../../../PermissionContext";
import {
	permissionTypes,
	phoneIds,
} from "../../../../../../../utils/PermissionUtils";

export default function Phone(props) {
	const { permissions, isEditable, isCreateMode } = props;

	const {
		permissionState,
		updatePhoneList,
		initializePhoneListtForPermission,
	} = usePermissionContext();

	useEffect(() => {
		if (permissions) {
			initializePhoneListtForPermission(permissions);
		}
	}, [permissions]);

	const handleAccessChange = (event, id) => {
		if (id === phoneIds.accessToPhone) {
			if (!event.target.checked) {
				//If Access to Phone access is removed , need to disable the other fields in admin settings
				const updatedPhoneList = permissionState.phoneList.map(
					(list) => {
						if (list.id === id) {
							const updatedList = {
								...list,
								access: event.target.checked,
							};
							return updatedList;
						}

						const updatedList = {
							...list,
							access: event.target.checked,
							accessDisable: !event.target.checked,
						};

						if (list.permissions) {
							updatedList.permissions = {
								...list.permissions,
								permission: list.permissions.permission.map(
									(permission) => ({
										...permission,
										value: event.target.checked,
										disabled: !event.target.checked,
									})
								),
							};
						}

						return updatedList;
					}
				);
				updatePhoneList(updatedPhoneList);
			} else {
				const updatedPhoneList = permissionState.phoneList.map(
					(list) => {
						if (list.id === id) {
							const updatedList = {
								...list,
								access: event.target.checked,
							};
							return updatedList;
						}

						const updatedList = {
							...list,
							accessDisable: !event.target.checked,
						};

						return updatedList;
					}
				);
				updatePhoneList(updatedPhoneList);
			}
		} else {
			const updatedPhoneList = permissionState.phoneList.map((list) => {
				if (list.id === id) {
					const updatedList = {
						...list,
						access: event.target.checked,
					};

					if (list.permissions) {
						updatedList.permissions = {
							...list.permissions,
							permission: list.permissions.permission.map(
								(permission) => ({
									...permission,
									value: event.target.checked,
									disabled: !event.target.checked,
								})
							),
						};
					}

					return updatedList;
				} else {
					return list;
				}
			});
			updatePhoneList(updatedPhoneList);
		}
	};

	const onChange = (event, selectedPermission, id) => {
		if (selectedPermission.id === permissionTypes.view) {
			const updatedPhoneList = permissionState.phoneList.map((list) => {
				if (list.id === id) {
					return {
						...list,
						access: event.target.checked,
						permissions: {
							...list.permissions,
							permission: list.permissions.permission.map(
								(permission) => {
									return {
										...permission,
										value: event.target.checked,
										disabled: !event.target.checked,
									};
								}
							),
						},
					};
				} else {
					return list;
				}
			});
			updatePhoneList(updatedPhoneList);
		} else {
			const updatedPhoneList = permissionState.phoneList.map((list) => {
				if (list.id === id) {
					return {
						...list,
						permissions: {
							...list.permissions,
							permission: list.permissions.permission.map(
								(permission) =>
									permission.id === selectedPermission.id
										? {
												...permission,
												value: event.target.checked,
											}
										: permission
							),
						},
					};
				} else {
					return list;
				}
			});
			updatePhoneList(updatedPhoneList);
		}
	};

	return (
		<React.Fragment>
			<PermissionList
				isViewName="Phone"
				lists={permissionState.phoneList}
				handleAccessChange={handleAccessChange}
				onChange={onChange}
				isEditable={isEditable}
				isCreateMode={isCreateMode}
			/>
		</React.Fragment>
	);
}
