import React, { useState } from "react";
import {
	Autocomplete,
	Box,
	CircularProgress,
	MenuItem,
	Popper,
	Skeleton,
	Stack,
	TextField,
	useTheme,
} from "@mui/material";
import { useDebouncedCallback } from "use-debounce";
import { useFilterDataContext } from "../../../../../Context/FilterDataContext";
import { useValueDropDown } from "../../../../../../../../../hooks/services/common/table/filter";
import { useLastRowRef } from "../../../../../../../../../hooks/common/InfiniteScroll";
import { MenuItemText } from "../../../../../../../../../styles/twozo";
import { searchTypes } from "../../../../../../../../../utils/dropdownData";

const CustomPopper = function (props) {
	return (
		<Popper
			{...props}
			style={{ ...props.style, padding: "7px 0px", width: "202px" }}
		/>
	);
};

export default function SingleSelectField(props) {
	const { filterCondition, onChange } = props;
	const theme = useTheme();
	const { enableApplyButton } = useFilterDataContext();

	const [searchValue, setSearchValue] = useState("");
	const [focusTextField, setFocusTextField] = useState(false);

	const selectedField = filterCondition?.fieldData || {};

	const selectedDropDownId = selectedField.fieldId ?? selectedField.optionId;
	const selectedDropDownName =
		selectedField?.valueDropdown?.valueDropdownName ?? null;

	const getDropdownRequest = (searchValue) => {
		if (selectedField?.valueDropdown) {
			if (
				!!selectedField?.valueDropdown?.requiredFields &&
				selectedField?.valueDropdown?.requiredFields[0] === "id"
			) {
				return {
					id: selectedDropDownId,
					criteria: {
						type: searchTypes.contains,
						value: searchValue,
					},
				};
			} else if (
				!!selectedField?.valueDropdown?.requiredFields &&
				selectedField?.valueDropdown?.requiredFields[0] === "sourceId"
			) {
				return {
					name: selectedDropDownName,
					sourceId: filterCondition?.sourceId?.value?.id,
					criteria: {
						type: searchTypes.contains,
						value: searchValue,
					},
				};
			} else {
				return {
					name: selectedDropDownName,
					criteria: {
						type: searchTypes.contains,
						value: searchValue,
					},
				};
			}
		}
	};

	const {
		data: dropDownData,
		isLoading: isLoadingDropdownData,
		isFetching,
		hasNextPage,
		fetchNextPage,
	} = useValueDropDown(
		getDropdownRequest(searchValue),
		selectedDropDownId,
		!!selectedField?.valueDropdown
	);

	const lastOptionRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const handleSearch = (event) => {
		const { value } = event.target;
		setSearchValue(value?.trim());
	};

	const onInputChange = useDebouncedCallback((event) => {
		handleSearch(event);
	}, 500);

	const onFocusTextField = () => {
		setFocusTextField(true);
	};

	const onBlurTextField = () => {
		setFocusTextField(false);
		setSearchValue("");
	};

	const handleSelectValue = (selectedValue) => {
		const updatedFilterCondition = {
			...filterCondition,
			valueEntityId: {
				...filterCondition.valueEntityId,
				value: selectedValue.value
					? selectedValue.value
					: selectedValue.id,
			},
			value: {
				...filterCondition.value,
				value: selectedValue.name,
			},
			singleSelectValue: selectedValue,
		};
		onChange(updatedFilterCondition);
		enableApplyButton();
	};

	const CustomListboxComponent = function (props) {
		return (
			<Box {...props}>
				{props.children}

				<Box ref={lastOptionRef}>
					{!isLoadingDropdownData && isFetching && hasNextPage ? (
						<MenuItem
							style={{
								height: "40px",
								width: "100%",
							}}
						>
							<Skeleton width="200px" height="16px" />
						</MenuItem>
					) : null}
				</Box>
			</Box>
		);
	};

	return (
		<Autocomplete
			size="small"
			disableClearable
			forcePopupIcon={false}
			PopperComponent={CustomPopper}
			options={dropDownData || []}
			open={Boolean(searchValue)}
			getOptionLabel={(option) => option?.name ?? ""}
			onChange={(_, data) => handleSelectValue(data)}
			noOptionsText="No Results Found"
			value={filterCondition.singleSelectValue}
			loading={isLoadingDropdownData}
			loadingText={
				<Stack
					alignItems="center"
					justifyContent="center"
					height="140px"
				>
					<CircularProgress size="18px" />
				</Stack>
			}
			ListboxComponent={CustomListboxComponent}
			renderOption={(props, option) => (
				<Box {...props} sx={{ height: "40px" }}>
					<MenuItemText>
						{option.name ? option.name : option}
					</MenuItemText>
				</Box>
			)}
			renderInput={(params) => (
				<TextField
					sx={{
						backgroundColor: "rgba(51, 188, 126, 0.12)",
						padding: "5px 10px 0px 10px",
						width: "218px",
						minHeight: "38px",
						borderRadius: "0px 0px 8px 8px",
						borderBottom: focusTextField
							? `1px solid ${theme.palette.primary.main}`
							: null,
					}}
					{...params}
					variant="standard"
					onChange={onInputChange}
					InputProps={{
						...params.InputProps,
						disableUnderline: true,
						style: {
							fontSize: "14px",
						},
					}}
					placeholder="Search"
					onFocus={() => onFocusTextField()}
					onBlur={() => onBlurTextField()}
				/>
			)}
		/>
	);
}
