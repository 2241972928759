import React from "react";
import { Typography } from "@mui/material";

export default function ContactNameUpdated(props) {
	const { eventData } = props;

	if (!eventData) return;

	return (
		<React.Fragment>
			<Typography fontSize={15} pt={1.3}>
				<span style={{ fontSize: "15px", color: "rgba(0, 0, 0, 0.6)" }}>
					{eventData?.salesOwner?.name}
				</span>{" "}
				updated the contact name from{" "}
				<span style={{ fontSize: "15px", fontWeight: 500 }}>
					{eventData?.oldValue}
				</span>{" "}
				to{" "}
				<span style={{ fontSize: "15px", fontWeight: 500 }}>
					{eventData?.newValue}
				</span>
			</Typography>
		</React.Fragment>
	);
}
