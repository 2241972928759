function Facebook({ width = "24", height = "24", color = "#33BC7E" }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.81666 18.3697L9.8166 18.3696L9.37035 18.2571L9.35206 18.2525L9.33528 18.2612L8.92653 18.4712L8.9265 18.4712L7.31188 19.3018V17.3325V17.3081L7.29266 17.2931L6.81266 16.9181L6.81266 16.9181L6.81196 16.9176C4.9543 15.5183 3.89375 13.4552 3.89375 11.2669C3.89375 7.20568 7.52309 3.89375 12 3.89375C16.4769 3.89375 20.1063 7.20568 20.1063 11.2669C20.1063 15.3281 16.4769 18.6419 12 18.6419C11.2637 18.642 10.5303 18.5506 9.81666 18.3697ZM5.98562 21.375V21.4568L6.05843 21.4195L9.51038 19.6506C10.3243 19.8562 11.1605 19.9604 12 19.9606C17.2006 19.9606 21.425 16.072 21.425 11.2687C21.425 6.46552 17.2006 2.575 12 2.575C6.79942 2.575 2.575 6.46363 2.575 11.2669C2.575 13.9606 3.90271 16.3662 5.98562 17.961V21.375Z"
				fill={color}
				stroke={color}
				strokeWidth="0.1"
			/>
			<path
				d="M6.18608 13.8521L6.24655 13.9301L10.5884 11.5676L12.9245 14.1206L12.9614 14.1609L12.9983 14.1206L17.8133 8.86499L17.7524 8.78734L13.4635 11.1318L11.0544 8.70598L11.0178 8.66911L10.9823 8.70712L6.18608 13.8521Z"
				fill={color}
				stroke={color}
				strokeWidth="0.1"
			/>
		</svg>
	);
}

export default Facebook;
