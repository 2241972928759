import { Stack, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import SingleDatePicker from "../../../../../SingleDatePicker";
import {
	convertDayjsToUnixTimeStamp,
	convertUnixTimeStampToDayjs,
} from "../../../../../../../utils/DateUtils";

export default function DateRangeFieldBaseComponent({
	value,
	onChange,
	id,
	fromTextFieldSx,
	toTextFieldSx,
	clearErrors,
	error,
}) {
	const [dateRangeValue, setDateRangeValue] = useState({});
	const theme = useTheme();

	useEffect(() => {
		if (value) {
			setDateRangeValue(value);
		}
	}, [value]);

	const handleDateRangeValueChange = (dateRangeValue) => {
		if (onChange) {
			onChange(dateRangeValue);
		}
	};

	const onStartDateChange = (value) => {
		let newValue = {
			...dateRangeValue,
			from: convertDayjsToUnixTimeStamp(value),
		};
		setDateRangeValue(newValue);
		handleDateRangeValueChange(newValue);
		clearErrors(id);
	};

	const onEndDateChange = (value) => {
		let newValue = {
			...dateRangeValue,
			to: convertDayjsToUnixTimeStamp(value),
		};
		setDateRangeValue(newValue);
		handleDateRangeValueChange(newValue);
		clearErrors(id);
	};

	const getParsedValue = (value) => {
		return value ? dayjs(value) : null;
	};

	const handleDisable = (date) => {
		return date < getParsedValue(dateRangeValue.from);
	};

	return (
		<>
			<Stack
				direction="column"
				color={error && theme.palette.error.main}
				width="100%"
				spacing={0.5}
			>
				<SingleDatePicker
					textFieldProps={{
						sx: {
							width: "100%",
							"& .MuiOutlinedInput-notchedOutline": {
								borderRadius: "8px",
							},
							...fromTextFieldSx,
						},
						inputProps: {
							style: { textAlign: "left", fontSize: "14px" },
						},
					}}
					slotProps={{ inputAdornment: { position: "end" } }}
					popperProps={{ placement: "bottom-end" }}
					onChange={(value) => {
						onStartDateChange(value);
					}}
					value={convertUnixTimeStampToDayjs(dateRangeValue.from)}
					id={id}
					error={!!error}
				/>

				<SingleDatePicker
					reverse={true}
					textFieldProps={{
						sx: {
							width: "100%",
							"& .MuiOutlinedInput-notchedOutline": {
								borderRadius: "8px",
							},
							...toTextFieldSx,
						},
						inputProps: {
							style: { textAlign: "left", fontSize: "14px" },
						},
					}}
					slotProps={{ inputAdornment: { position: "end" } }}
					popperProps={{ placement: "bottom-end" }}
					onChange={(value) => {
						onEndDateChange(value);
					}}
					value={convertUnixTimeStampToDayjs(dateRangeValue.to)}
					error={!!error}
					shouldDisableDate={handleDisable}
					shouldDisableMonth={handleDisable}
					shouldDisableYear={handleDisable}
				/>
			</Stack>
		</>
	);
}
