import { useState, useEffect } from "react";

export const useDebouncedValue = (inputValue, delay = 500) => {
	const [debouncedValue, setDebouncedValue] = useState(inputValue);

	useEffect(() => {
		const debounceHandler = setTimeout(() => {
			setDebouncedValue(inputValue);
		}, delay);

		return () => {
			clearTimeout(debounceHandler);
		};
	}, [inputValue, delay]);

	return debouncedValue;
};
