import React, { useMemo, useState } from "react";
import {
	Box,
	CircularProgress,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as DragIcon } from "../../../../../assets/icons/drag";
import { useDashboardBuilderContext } from "../Context";
import Menu from "../../../../Elements/Menu";
import DeleteDialog from "../../../../Elements/DeleteDialog";
import { isEmptyObject } from "../../../../../utils/common";
import { useChartApplyData } from "../../../../../hooks/services/analytics";
import Charts from "../../Charts";
import MoreIconButton from "../../../../Elements/MoreIconButton";

export default function ChartWrapper(props) {
	const { config, index } = props;
	const theme = useTheme();
	const {
		getChartIcon,
		selectedChart,
		handleDeleteChart,
		selectChart,
		parseChartProperty,
	} = useDashboardBuilderContext();
	const hasChartProperty = useMemo(() => {
		return !isEmptyObject(config.viewProperties);
	}, [config.viewProperties]);
	const {
		data: chartApplyData,
		isLoading: isChartApplyDataLoading,
		isError: chartApplyDataError,
	} = useChartApplyData(parseChartProperty({ ...config }), {
		enabled: hasChartProperty,
	});

	const [deleteChartMenuElement, setDeleteChartMenuElement] = useState(null);
	const isDeleteChartMenuOpened = Boolean(deleteChartMenuElement);

	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

	const openDeleteChartMenu = (event) => {
		event.stopPropagation();
		setDeleteChartMenuElement(event.currentTarget);
	};

	const closeDeleteChartMenu = () => {
		setDeleteChartMenuElement(null);
	};

	const onRemoveClicked = (event) => {
		event.stopPropagation();
		closeDeleteChartMenu();
		setIsDeleteDialogOpen(true);
	};

	const onDeleteChart = () => {
		handleDeleteChart(index);
		setIsDeleteDialogOpen(false);
	};

	return (
		<React.Fragment>
			<DeleteDialog
				open={isDeleteDialogOpen}
				title="Are you sure you want to proceed?"
				subtitle="All of your unsaved changes will be discarded"
				confirmButtonText="Confirm"
				onCancel={() => setIsDeleteDialogOpen(false)}
				onDelete={onDeleteChart}
			/>

			<Menu
				minWidth="253px"
				anchorEl={deleteChartMenuElement}
				open={isDeleteChartMenuOpened}
				onClose={closeDeleteChartMenu}
				style={{ marginTop: "8px" }}
				PaperProps={{ style: { maxHeight: "248px" } }}
			>
				<MenuItem
					style={{ minHeight: "40px" }}
					onClick={onRemoveClicked}
				>
					<Typography
						fontSize={13}
						fontWeight={500}
						color={theme.palette.error.main}
					>
						Remove
					</Typography>
				</MenuItem>
			</Menu>
			<Box
				style={{
					height: "380px",
					backgroundColor: "white",
					borderRadius: "5px",
					cursor: "pointer",
					border:
						selectedChart === config.viewId
							? `1px solid ${theme.palette.primary.main}`
							: "1px solid #fff",
				}}
				onClick={() => {
					selectChart(config.viewId, index);
				}}
				p={2}
			>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Stack direction="row" alignItems="center" spacing={1}>
						<Box display="flex">{DragIcon(24, 24, "grey")}</Box>

						<Typography>{config.viewName}</Typography>
					</Stack>

					<Box display="flex">
						<MoreIconButton
							onClick={openDeleteChartMenu}
							isFocused={isDeleteChartMenuOpened}
						/>
					</Box>
				</Stack>
				{hasChartProperty ? (
					isChartApplyDataLoading ? (
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="center"
							height="70%"
							spacing={2}
						>
							<CircularProgress size={40} />
						</Stack>
					) : chartApplyDataError ? (
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="center"
							height="70%"
							spacing={2}
						>
							<Typography color="rgba(0, 0, 0, 0.6)">
								Error fetching chart data
							</Typography>
						</Stack>
					) : (
						chartApplyData && (
							<Box
								pt={2}
								style={{
									height: "95%",
								}}
							>
								<Charts
									viewTypeId={config.viewTypeId}
									previewMode={true}
									previewData={chartApplyData}
								/>
							</Box>
						)
					)
				) : (
					<Stack
						alignItems="center"
						justifyContent="center"
						style={{
							height: "80%",
							pointerEvents: "none",
							userSelect: "none",
						}}
					>
						<Box display="flex">
							{getChartIcon(config.meta.viewIcon.name, 45, 45)}
						</Box>

						<Typography fontSize={15}>
							{config.meta.viewIcon.displayName}
						</Typography>
					</Stack>
				)}
			</Box>
		</React.Fragment>
	);
}
