import React, { useState } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { default as ProductIcon } from "../../../assets/icons/product";
import { twozoStyles } from "../../../styles/twozo";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import AddProduct from "../AddProduct";
import Can from "../../Auth/Can";
import { PERMISSIONS } from "../../../utils/Auth";
import { useModuleName } from "../../../hooks/modules";
import { modules } from "../../../utils/common/ModulesName";

export default function NoProductFound() {
	const classes = twozoStyles();
	const theme = useTheme();
	const [isAddProductDialogOpened, setIsAddProductDialogOpened] =
		useState(false);

	const { getModuleName } = useModuleName();
	let moduleName = getModuleName(modules.PRODUCT);

	const toggleAddProductDialog = () => {
		setIsAddProductDialogOpened(
			(openAddProductDialog) => !openAddProductDialog
		);
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddProductDialogOpened}
				onOpen={toggleAddProductDialog}
				onClose={toggleAddProductDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddProduct onClose={toggleAddProductDialog} />
				</Box>
			</CustomSwipeableDrawer>

			<Stack
				alignItems="center"
				justifyContent="center"
				style={{
					position: "absolute",
					top: "45%",
					left: "42%",
					minWidth: "22%",
				}}
			>
				<Box sx={{ display: "flex" }} pb={1}>
					{ProductIcon(37, 37, "#666666")}
				</Box>
				<Typography
					fontSize={17}
					fontWeight={400}
					color="rgba(0, 0, 0, 0.6)"
				>
					No {moduleName} Found
				</Typography>
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					Try resetting your filters
					<Can permission={PERMISSIONS.product.create}>
						<span> or </span>
						<span
							onClick={toggleAddProductDialog}
							style={{ color: theme.palette.primary.main }}
						>
							Create new {moduleName.toLowerCase()}
						</span>
					</Can>
				</Typography>
			</Stack>
		</React.Fragment>
	);
}
