import React, { useState } from "react";
import { Box, Button, MenuItem, Stack, useTheme } from "@mui/material";
import Menu from "../../Elements/Menu";
import { default as AddIcon } from "../../../assets/icons/add";
import AddContact from "../AddContact";
import { MenuItemText, twozoStyles } from "../../../styles/twozo";
import { useNavigate } from "react-router-dom";
import CheckDuplicates from "../CheckDuplicates";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import { removeFieldsWithEmptyValues } from "../../../utils/common";
import { useModuleName } from "../../../hooks/modules";
import { modules } from "../../../utils/common/ModulesName";
import Can from "../../Auth/Can";
import { PERMISSIONS } from "../../../utils/Auth";
import MoreIconButton from "../../Elements/MoreIconButton";

export default function ContactMenu(props) {
	const { onImportClicked } = props;
	const theme = useTheme();
	const navigate = useNavigate();
	const classes = twozoStyles();
	const { getModuleName } = useModuleName();

	const [contactMenuElement, setContactMenuElement] = useState(null);
	const [isAddContactDialogOpened, setIsAddContactDialogOpened] =
		useState(false);
	const [isDuplicateCheckEnabled, setIsDuplicateCheckEnabled] =
		useState(false);
	const openContactMenu = Boolean(contactMenuElement);
	const [contactDetails, setContactDetails] = useState({});
	const viewName = getModuleName(modules.CONTACT);

	const toggleAddContactDialog = () => {
		setIsAddContactDialogOpened(
			(isAddContactDialogOpened) => !isAddContactDialogOpened
		);
	};

	const OpenContactMenu = (event) => {
		setContactMenuElement(event.currentTarget);
	};

	const CloseContactMenu = () => {
		setContactMenuElement(null);
	};

	const toggleCheckDuplicatesDialog = () => {
		setIsDuplicateCheckEnabled(
			(isDuplicateCheckEnabled) => !isDuplicateCheckEnabled
		);
	};

	const checkContactDuplicates = (contactData) => {
		removeFieldsWithEmptyValues(contactData);
		setContactDetails(contactData);
	};

	const onDuplicateMergeSuccess = () => {
		toggleAddContactDialog();
		toggleCheckDuplicatesDialog();
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isDuplicateCheckEnabled}
				onOpen={toggleCheckDuplicatesDialog}
				onClose={toggleCheckDuplicatesDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
				BackdropProps={{ invisible: true }}
			>
				<Box
					style={{ height: "100vh" }}
					className={classes.addDialogContainer}
				>
					<CheckDuplicates
						onClose={toggleCheckDuplicatesDialog}
						contactDetails={contactDetails}
						onDuplicateMergeSuccess={onDuplicateMergeSuccess}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddContactDialogOpened}
				onOpen={toggleAddContactDialog}
				onClose={toggleAddContactDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box
					className={classes.addDialogContainer}
					hidden={isDuplicateCheckEnabled}
				>
					<AddContact
						setIsDuplicateCheckEnabled={setIsDuplicateCheckEnabled}
						onClose={toggleAddContactDialog}
						checkContactDuplicates={checkContactDuplicates}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Box>
				<Menu
					minWidth="200px"
					anchorEl={contactMenuElement}
					open={openContactMenu}
					onClose={CloseContactMenu}
					style={{
						marginTop: "8px",
					}}
				>
					<MenuItem
						onClick={() => navigate("duplicate")}
						style={{ height: "40px" }}
					>
						<MenuItemText
							color={theme.palette.secondary.contrastText}
						>
							Manage Duplicates
						</MenuItemText>
					</MenuItem>
				</Menu>
			</Box>
			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={1}
				height="100%"
			>
				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					spacing={2}
					height="100%"
				>
					<Can permission={PERMISSIONS.contact.importRecords}>
						<Button
							variant="contained"
							color="secondary"
							onClick={onImportClicked}
							disableElevation
						>
							{`Import ${viewName}`}
						</Button>
					</Can>

					<Can permission={PERMISSIONS.contact.create}>
						<Button
							variant="contained"
							startIcon={AddIcon(20, 20, "#fff")}
							onClick={toggleAddContactDialog}
							disableElevation
						>
							{viewName}
						</Button>
					</Can>
				</Stack>

				<Can permission={PERMISSIONS.contact.mergeRecords}>
					<MoreIconButton
						onClick={OpenContactMenu}
						isFocused={openContactMenu}
					/>
				</Can>
			</Stack>
		</React.Fragment>
	);
}
