import React from "react";
import { useFilterDataContext } from "../Context/FilterDataContext";
import ConfirmDialog from "../../../ConfirmDialog";
import {
	getApplyFilterRequestData,
	isCriteriaValid,
} from "../../../../../utils/FilterUtills";
import useApplyFilter from "../../../../../hooks/filter";

export default function FilterConfirmationDialog(props) {
	const { open, onClose } = props;

	const { filterState, filterListByCondition, updateFilterUICriteriaList } =
		useFilterDataContext();

	const { applyFilter } = useApplyFilter(onClose);

	const onCancel = () => {
		const validFilters =
			filterState.filterUICriteriaList.lastAppliedCriteria.filter(
				isCriteriaValid
			);
		let requestData = getApplyFilterRequestData(validFilters);
		filterListByCondition(
			filterState.filterConditions?.filterId
				? {
						filterId: filterState.activeFilterConfig.id,
					}
				: { criteria: requestData.criteriaList }
		);

		updateFilterUICriteriaList(
			filterState.filterUICriteriaList.lastAppliedCriteria
		);
		onClose();
	};

	return (
		<React.Fragment>
			<ConfirmDialog
				open={open}
				onCancel={onCancel}
				onConfirm={applyFilter}
				title="Are you sure you want to apply the filters now?"
				subtitle="One or more filters have been updated, but they’re not applied yet."
				confirmButtonText="Apply"
			/>
		</React.Fragment>
	);
}
