// Validate a single rule:-
const validateRule = (rule, formData) => {
	const fieldValue = formData[rule.field];
	switch (rule.condition) {
		case "EQUALS":
			return fieldValue === rule.value;
		default:
			return false;
	}
};

// Validate nested rules:-
const validateRules = (rules, formData, connector) => {
	if (connector === "AND") {
		return rules.every((rule) => {
			// If the rule has a condition, No more nested rules so validate it directly
			if (rule.condition) {
				return validateRule(rule, formData);
			}
			// Otherwise, recursively validate the nested rules
			return validateRules(rule.rules, formData, rule.connector);
		});
	}

	return rules.some((rule) => {
		// If the rule has a condition, No more nested rules so validate it directly
		if (rule.condition) {
			return validateRule(rule, formData);
		}
		// Otherwise, recursively validate the nested rules
		return validateRules(rule.rules, formData, rule.connector);
	});
};

// Check field visibility:-
export const checkFieldVisibility = (field, formValues) => {
	if (!field?.criteria) {
		return true;
	}

	const rules = field.criteria?.rules;
	const connector = field.criteria?.connector || "AND";

	return validateRules(rules, formValues, connector);
};
