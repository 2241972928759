import React, { useMemo, useState } from "react";
import teamsImage from "../../../../../../assets/images/contact/multipleContact.png";
import {
	Box,
	Checkbox,
	Chip,
	CircularProgress,
	MenuItem,
	Select,
	Skeleton,
	Stack,
	Typography,
} from "@mui/material";
import { useTeamsList } from "../../../../../../hooks/services/userManagement/teams";
import MenuSearchBar from "../../../../../Elements/MenuSearchBar";
import { default as CloseIcon } from "../../../../../../assets/icons/close";
import { MenuItemText } from "../../../../../../styles/twozo";
import { useLastRowRef } from "../../../../../../hooks/common/InfiniteScroll";

export default function TeamsDropdown(props) {
	const { selectedTeams, onChange } = props;
	const [searchValue, setSearchValue] = useState("");

	const {
		data: teamsList,
		isLoading: isLoadingTeamsList,
		isFetching: isFetchingTeamsList,
		hasNextPage: hasNextTeamsPage,
		fetchNextPage: fetchNextTeamsPage,
	} = useTeamsList(searchValue);

	const lastOptionRef = useLastRowRef(
		fetchNextTeamsPage,
		hasNextTeamsPage,
		isFetchingTeamsList
	);

	const handleFilteredField = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const selectedTeamIds = useMemo(() => {
		if (selectedTeams?.length > 0) {
			return selectedTeams.map((selectedTeam) => selectedTeam.value);
		}
		return [];
	}, [selectedTeams]);

	const isSelectedTeam = (teamId) => {
		return selectedTeamIds?.includes(teamId);
	};

	const onSelectTeam = (_, child) => {
		let selectedTeamData = {
			name: child?.props?.name,
			value: child?.props?.value,
		};

		if (!isSelectedTeam(selectedTeamData?.value)) {
			onChange([...selectedTeams, selectedTeamData]);
		} else {
			unSelectTeam(selectedTeamData?.value);
		}
	};

	const unSelectTeam = (removedTeamId) => {
		const updatedteams = selectedTeams.filter(
			(team) => team?.value !== removedTeamId
		);
		onChange(updatedteams);
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					No Results Found
				</Typography>
			</Stack>
		);
	};

	const renderTeamsValue = () => {
		if (selectedTeams?.length > 0) {
			return (
				<Box
					sx={{
						display: "flex",
						flexWrap: "wrap",
						gap: 0.5,
					}}
				>
					{selectedTeams.map((team) => (
						<Chip
							size="small"
							key={team?.value}
							label={
								<Typography noWrap fontSize={14} color="#000">
									{team?.name}
								</Typography>
							}
							color="secondary"
							deleteIcon={
								<Stack
									direction="row"
									alignItems="center"
									onMouseDown={(event) =>
										event.stopPropagation()
									}
								>
									{CloseIcon(16, 16, "grey")}
								</Stack>
							}
							onDelete={() => unSelectTeam(team?.value)}
							sx={{
								borderRadius: "8px",
								marginRight: "4px",
							}}
						/>
					))}
				</Box>
			);
		} else {
			return (
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					Select
				</Typography>
			);
		}
	};

	return (
		<Select
			displayEmpty
			value={selectedTeamIds}
			onChange={onSelectTeam}
			multiple
			renderValue={renderTeamsValue}
			onOpen={() => setSearchValue("")}
			sx={{
				height: "auto",
				"& .MuiOutlinedInput-input": {
					padding: "10px 32px 10px 14px",
				},
			}}
			MenuProps={{
				autoFocus: false,
				PaperProps: {
					style: {
						maxHeight: 150,
						maxWidth: 400,
					},
				},
			}}
		>
			<Box
				p={0.5}
				marginTop="-8px"
				sx={{
					position: "sticky",
					top: 0,
					zIndex: 1,
					backgroundColor: "#fff",
				}}
			>
				<MenuSearchBar
					value={searchValue}
					onChange={handleFilteredField}
				/>
			</Box>

			{isLoadingTeamsList ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="150px"
				>
					<CircularProgress size="25px" />
				</Stack>
			) : teamsList?.length > 0 ? (
				teamsList.map((team) => (
					<MenuItem
						key={team.value}
						value={team.value}
						name={team.name}
					>
						<Stack
							style={{
								width: "100%",
							}}
							direction="row"
							alignItems="center"
							justifyContent="space-between"
						>
							<Stack
								direction="row"
								alignItems="center"
								spacing={2}
							>
								<img
									src={teamsImage}
									alt="img"
									width="20px"
									height="20px"
								/>
								<MenuItemText whiteSpace="wrap">
									{team.name}
								</MenuItemText>
							</Stack>

							<Stack>
								<Checkbox
									checked={isSelectedTeam(team.value)}
								/>
							</Stack>
						</Stack>
					</MenuItem>
				))
			) : (
				renderEmptyView()
			)}

			<Box ref={lastOptionRef}>
				{!isLoadingTeamsList &&
					isFetchingTeamsList &&
					teamsList?.length > 0 && (
						<MenuItem style={{ height: "40px" }}>
							<Skeleton width="190px" height="16px" />
						</MenuItem>
					)}
			</Box>
		</Select>
	);
}
