import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Can from "../../../../../Auth/Can";
import CanNot from "../../../../../Auth/CanNot";
import { PERMISSIONS } from "../../../../../../utils/Auth";

export default function RenderContactOnboardFieldValue(props) {
	const { fieldValue } = props;
	const theme = useTheme();
	const navigate = useNavigate();

	return (
		<React.Fragment>
			<Can permission={PERMISSIONS.contact.view}>
				<Box
					sx={{ cursor: "pointer", width: "100%" }}
					onClick={() =>
						navigate(`/contacts/${fieldValue?.id?.toString()}`)
					}
				>
					<Typography
						fontSize={14}
						fontWeight={500}
						noWrap
						color={theme.palette.primary.main}
					>
						{fieldValue?.fullName}
					</Typography>
				</Box>
			</Can>

			<CanNot permission={PERMISSIONS.contact.view}>
				<Typography fontSize={14} fontWeight={500}>
					-
				</Typography>
			</CanNot>
		</React.Fragment>
	);
}
