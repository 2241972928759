import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Menu from "../../../Elements/Menu";
import { MenuItemText } from "../../../../styles/twozo";
import MenuSearchBar from "../../../Elements/MenuSearchBar";
import { useAuth } from "../../../../hooks/auth";
import { PERMISSIONS } from "../../../../utils/Auth";

export default function SortMenu(props) {
	const {
		sortIconElement,
		closeSortMenu,
		isSortMenuOpened,
		sort,
		updateSortRequest,
		updateSortOptions,
		selectedSortOption,
	} = props;
	const { isUserAllowedFor } = useAuth();

	const [searchValue, setSearchValue] = useState("");
	const [hoveredSortOption, setHoveredSortOption] = useState(null);

	const handleSearch = (event) => {
		setSearchValue(event.target.value.toLowerCase());
	};

	const handleSortOption = (sortOption, value) => {
		updateSortOptions((selectedSortOption) => {
			if (
				!!selectedSortOption[sortOption] &&
				selectedSortOption[sortOption] === value
			) {
				delete selectedSortOption[sortOption];
				updateSortRequest({});
				return {};
			}

			updateSortRequest({
				field: sortOption,
				type: value,
			});

			return { [sortOption]: value };
		});
	};

	useEffect(() => {
		if (sort?.field) {
			updateSortOptions({ [sort.field]: sort.type });
		}
	}, [sort, updateSortOptions]);

	const onMouseOverSortOption = (id) => setHoveredSortOption(id);
	const onMouseOutSortOption = () => setHoveredSortOption(null);

	const sortOptions = [
		{
			id: "dealTitle",
			displayName: "Deal Title",
			hasPermissionToAccess: true,
		},
		{
			id: "contactName",
			displayName: "Contact Name",
			hasPermissionToAccess: isUserAllowedFor(PERMISSIONS.contact.view),
		},
		{
			id: "companyName",
			displayName: "Company Name",
			hasPermissionToAccess: true,
		},
		{
			id: "dealValue",
			displayName: "Deal Value",
			hasPermissionToAccess: true,
		},
		{
			id: "salesOwner",
			displayName: "Sales Owner",
			hasPermissionToAccess: true,
		},
		{
			id: "dealClosedOn",
			displayName: "Closing Date",
			hasPermissionToAccess: true,
		},
		{
			id: "createdBy",
			displayName: "Created By",
			hasPermissionToAccess: true,
		},
		{
			id: "updatedBy",
			displayName: "Modified By",
			hasPermissionToAccess: true,
		},
		{
			id: "createdAt",
			displayName: "Created At",
			hasPermissionToAccess: true,
		},
		{
			id: "updatedAt",
			displayName: "Modified Time",
			hasPermissionToAccess: true,
		},
		{
			id: "lastActivityDate",
			displayName: "Last Activity Time",
			hasPermissionToAccess: true,
		},
	];

	const getSortOptions = () => {
		return sortOptions.filter(
			(sortOption) => sortOption.hasPermissionToAccess
		);
	};

	const filteredSortOptions = getSortOptions().filter((option) =>
		option.displayName.toLowerCase().includes(searchValue)
	);

	return (
		<React.Fragment>
			<Box>
				<Menu
					width="290px"
					anchorEl={sortIconElement}
					open={isSortMenuOpened}
					onClose={closeSortMenu}
					transformOrigin={{
						vertical: "top",
						horizontal: 264,
					}}
				>
					<Box p={1} style={{ textAlign: "center" }}>
						<MenuItemText fontWeight={500}>Sort By</MenuItemText>
					</Box>

					<Box p={0.5}>
						<MenuSearchBar
							value={searchValue}
							onChange={handleSearch}
						/>
					</Box>

					{filteredSortOptions.map((sortOption) => (
						<Box
							key={sortOption.id}
							px={2}
							py={0.5}
							style={{
								height: "40px",
								cursor: "pointer",
								backgroundColor:
									hoveredSortOption === sortOption.id ||
									!!selectedSortOption[sortOption.id]
										? "#F4F5F5"
										: "inherit",
							}}
							onMouseOver={() =>
								onMouseOverSortOption(sortOption.id)
							}
							onMouseOut={() => onMouseOutSortOption()}
						>
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="space-between"
								width="100%"
								height="100%"
							>
								<MenuItemText>
									{sortOption.displayName}
								</MenuItemText>

								{(hoveredSortOption === sortOption.id ||
									!!selectedSortOption[sortOption.id]) && (
									<Stack direction="row" spacing={1}>
										<Box
											onClick={() =>
												handleSortOption(
													sortOption.id,
													"asc"
												)
											}
										>
											<Typography
												fontSize={12}
												fontWeight={500}
												color={
													selectedSortOption[
														sortOption.id
													] === "asc"
														? "#000"
														: "rgba(0, 0, 0, 0.4)"
												}
											>
												Asc
											</Typography>
										</Box>

										<Divider
											orientation="vertical"
											style={{ height: "20px" }}
										/>

										<Box
											onClick={() =>
												handleSortOption(
													sortOption.id,
													"desc"
												)
											}
										>
											<Typography
												fontSize={12}
												fontWeight={500}
												color={
													selectedSortOption[
														sortOption.id
													] === "desc"
														? "#000"
														: "rgba(0, 0, 0, 0.4)"
												}
											>
												Desc
											</Typography>
										</Box>
									</Stack>
								)}
							</Stack>
						</Box>
					))}
				</Menu>
			</Box>
		</React.Fragment>
	);
}
