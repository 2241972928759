import {
	alpha,
	Box,
	Button,
	Divider,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DropDownRight from "../../../assets/icons/dropDownRight";
import AddIcon from "../../../assets/icons/add";
import LifecycleStageList from "./LifecycleStageList";
import { useState } from "react";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import AddLifecycleStage from "./AddLifecycleStage";
import { twozoStyles } from "../../../styles/twozo";
import { useAllLifecycleStages } from "../../../hooks/services/lifecycleStage";
import LifecycleStageRules from "./LifecycleStageRules";
import { maxStageLimit } from "./LifecycleStageConfig";

export default function LifecycleStageManager() {
	const theme = useTheme();
	const classes = twozoStyles();
	const navigate = useNavigate();

	const { data: allLifecycleStagesList } = useAllLifecycleStages();

	const [
		isAddNewLifecycleStageDialogOpen,
		setIsAddNewLifecycleStageDialogOpen,
	] = useState(false);

	const openAddNewLifecycleStageDialog = () => {
		setIsAddNewLifecycleStageDialogOpen(true);
	};

	const closeAddNewLifecycleStageDialog = () => {
		setIsAddNewLifecycleStageDialogOpen(false);
	};

	const isAddLifecycleButtonDisabled =
		allLifecycleStagesList?.length === maxStageLimit;

	return (
		<>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddNewLifecycleStageDialogOpen}
				onOpen={openAddNewLifecycleStageDialog}
				onClose={closeAddNewLifecycleStageDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					{isAddNewLifecycleStageDialogOpen && (
						<AddLifecycleStage
							onClose={closeAddNewLifecycleStageDialog}
						/>
					)}
				</Box>
			</CustomSwipeableDrawer>

			<Box p={2}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
				>
					<Stack direction="row" alignItems="center" spacing={1}>
						<Box
							pl={1}
							style={{ cursor: "pointer" }}
							onClick={() => navigate("/settings")}
						>
							<Typography
								fontWeight={500}
								color={theme.palette.secondary.contrastText}
							>
								Admin Settings
							</Typography>
						</Box>

						{DropDownRight(16, 16)}

						<Typography fontWeight={500}>
							Contact Lifecycle Stages
						</Typography>
					</Stack>

					<Box>
						<Button
							variant="contained"
							color="primary"
							sx={{
								"&.Mui-disabled": {
									backgroundColor: "rgba(51, 188, 126)",
									color: alpha("#FFFFFF", 0.6),
									cursor: "not-allowed",
									pointerEvents: "auto",
								},
							}}
							startIcon={AddIcon(
								20,
								20,
								isAddLifecycleButtonDisabled
									? alpha("#FFFFFF", 0.6)
									: "#fff"
							)}
							onClick={openAddNewLifecycleStageDialog}
							disableElevation
							disabled={isAddLifecycleButtonDisabled}
						>
							Lifecycle Stage
						</Button>
					</Box>
				</Stack>

				<Box pl={1} pt={2}>
					<Typography fontSize={18} fontWeight={600} pb={0.5}>
						Contact Lifecycle Stages
					</Typography>

					<Typography
						fontSize={14}
						fontWeight={400}
						style={{ opacity: 0.6 }}
					>
						Customize everything about the default lifecycle stages:
						rename, reorder or disable them. Plus you can add a new
						stage that reflects your business process, like
						Evangelist or Marketing Qualified Lead. All statuses
						within a stage are customizable. If you want contacts to
						change stages automatically, use the rules at the bottom
						of this page.
					</Typography>
				</Box>
			</Box>

			<Divider />

			<Box
				style={{
					flex: "1 1 auto",
					minHeight: 0,
					maxHeight: "600px",
					overflowY: "auto",
				}}
			>
				<Box
					m={2}
					p={2}
					border="1px solid #0000001A"
					borderRadius="8px"
				>
					<LifecycleStageRules
						lifecycleStagesList={allLifecycleStagesList}
					/>

					<Divider />

					<Box
						style={{
							flex: "1 1 auto",
							minHeight: 0,
							overflowY: "auto",
						}}
					>
						<LifecycleStageList
							onOpen={openAddNewLifecycleStageDialog}
							stagesList={allLifecycleStagesList}
						/>
					</Box>
				</Box>
			</Box>
		</>
	);
}
