import { default as CloseIcon } from "../../../../../../assets/icons/close";
import {
	Box,
	Divider,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { FormFieldName, MenuItemText } from "../../../../../../styles/twozo";
import MenuSearchBar from "../../../../MenuSearchBar";
import Field from "../../../Field";
import { useMemo, useState } from "react";
import { useUpdateFieldList } from "../../../../../../hooks/services/common/field";

export default function SingleField(params) {
	const {
		field,
		moduleName,
		handleSelectChange,
		fieldState,
		selectedFieldIds,
		handleRemoveField,
		isRemoveAllowed,
	} = params;

	const theme = useTheme();

	// query call
	const { data: fieldListData } = useUpdateFieldList(moduleName);
	const [searchValue, setSearchValue] = useState("");

	// Static data: The fields array is derived from fieldListData and does not involve expensive calculations or frequent updates.
	// Therefore, using useMemo is unnecessary here.
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const fields = fieldListData?.fields || [];
	const subFields = fieldListData?.subFields || [];

	const filteredFields = useMemo(() => {
		if (Array.isArray(fields)) {
			if (searchValue?.trim()) {
				return fields.filter((field) =>
					field?.fieldName
						?.toLowerCase()
						?.startsWith(searchValue?.toLowerCase())
				);
			}
			return fields;
		}
		return [];
	}, [searchValue, fields]);

	const renderEmptyView = () => {
		return (
			<Box sx={{ cursor: "not-allowed", opacity: 0.7 }} width="100%">
				<TextField
					placeholder="Enter Value"
					sx={{ pointerEvents: "none", width: "100%" }}
				/>
			</Box>
		);
	};

	const renderNoResultsMessage = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					No Results Found
				</Typography>
			</Stack>
		);
	};

	const handleSearchField = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const onCloseClicked = () => {
		if (!isRemoveAllowed) {
			return;
		}
		handleRemoveField();
	};

	return (
		<>
			<Stack direction="row" width="100%">
				<Box height="100%" display="flex" style={{ width: "45%" }}>
					<Select
						size="small"
						displayEmpty
						name="field"
						onChange={(event) => handleSelectChange(event, fields)}
						value={field.fieldName}
						fullWidth
						renderValue={(value) => {
							if (value === "" || value === undefined) {
								return (
									<Typography
										color={"rgba(0, 0, 0, 0.5)"}
										fontSize={14}
									>
										Select
									</Typography>
								);
							} else {
								return (
									<FormFieldName>
										{field.fieldName}
										{field.config.required ? (
											<span
												style={{
													color: theme.palette.error
														.main,
													marginLeft: "5px",
												}}
											>
												*
											</span>
										) : null}
									</FormFieldName>
								);
							}
						}}
						MenuProps={{
							autoFocus: false,
							PaperProps: {
								style: {
									maxHeight: "375px",
									marginTop: "5px",
									borderRadius: "5px",
								},
							},
						}}
						error={fieldState.isErrorMode && field.fieldName === ""}
						onClose={() => setSearchValue("")}
					>
						<Box
							p={0.5}
							sx={{
								backgroundColor: "#fff",
							}}
							marginTop="-8px"
							position="sticky"
							top={0}
							zIndex={1}
						>
							<MenuSearchBar
								value={searchValue}
								onChange={handleSearchField}
								autoFocus={false}
							/>
						</Box>

						{filteredFields?.length > 0
							? filteredFields?.map((fieldData) => (
									<MenuItem
										key={fieldData.id}
										value={fieldData.fieldName}
										sx={{
											height: "40px",
										}}
										disabled={selectedFieldIds.includes(
											fieldData.id
										)}
									>
										<MenuItemText
											sx={{
												fontWeight: 500,
											}}
										>
											{fieldData.fieldName}
											{fieldData.config.required ? (
												<span
													style={{
														color: theme.palette
															.error.main,
														marginLeft: "5px",
													}}
												>
													*
												</span>
											) : null}
										</MenuItemText>
									</MenuItem>
								))
							: renderNoResultsMessage()}
					</Select>
				</Box>

				<Box style={{ width: "5%" }} px={0.5} pt={2.8}>
					<Divider />
				</Box>

				<Box
					height="100%"
					display="flex"
					width="45%"
					flexGrow={1}
					overflow="hidden"
				>
					{field.fieldName === "" ? (
						renderEmptyView()
					) : (
						<Field field={field} subFields={subFields} />
					)}
				</Box>

				<Box
					display="flex"
					style={{
						width: "5%",
						cursor: "pointer",
						paddingLeft: "8px",
					}}
					onClick={onCloseClicked}
					pt={1.5}
				>
					{isRemoveAllowed &&
						CloseIcon(20, 20, "rgba(0, 0, 0, 1)", 0.6)}
				</Box>
			</Stack>
		</>
	);
}
