import React from "react";
import { Stack, Typography } from "@mui/material";

const styles = {
	highlightedText: {
		fontSize: "15px",
		fontWeight: 500,
	},
	textSecondary: {
		fontSize: "14px",
		color: "rgba(0, 0, 0, 0.6)",
	},
};

export default function EmailReplied(props) {
	const { eventData } = props;

	if (!eventData) return;

	return (
		<React.Fragment>
			<Stack pb={1.5} mt={-1}>
				<Typography fontSize={14}>
					From : {eventData?.data?.from}
					<span
						style={{
							padding: "0px 12px",
							...styles.textSecondary,
						}}
					>
						&#8226;
					</span>
					<span style={styles.textSecondary}>
						To : {eventData?.data?.to?.[0]}{" "}
						{eventData?.data?.to?.length > 1 ? (
							<span style={styles.textSecondary}>
								+{eventData.data.to.length - 1}
							</span>
						) : null}
					</span>
				</Typography>

				<Typography style={styles.highlightedText}>
					{eventData?.data?.subject}
				</Typography>

				<Typography fontSize={14}>
					{eventData?.data?.content ?? ""}
				</Typography>
			</Stack>
		</React.Fragment>
	);
}
