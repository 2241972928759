export const getFieldGroupStyle = (isGroupedFieldHead, isGroupedFieldFoot) => {
	return {
		borderTop: isGroupedFieldHead ? "1px solid rgba(0, 0, 0, 0.1)" : "none",
		borderRadius: isGroupedFieldHead
			? "8px 8px 0 0"
			: isGroupedFieldFoot
				? "0 0 8px 8px"
				: "0px",
	};
};
