import postFieldsApi from "../fields/postFieldsApi";

export const getUserTeamTerritoryList = async (data) => {
	let requestData = getUserTeamTerritoryListRequest(data);
	return await postFieldsApi(requestData);
};

const getUserTeamTerritoryListRequest = (data) => {
	return {
		type: "dropdownData",
		data: {
			...data,
			name: "userTeamTerritoryList",
		},
	};
};
