import React, { useEffect, useMemo, useState } from "react";
import {
	Box,
	Button,
	Divider,
	IconButton,
	MenuItem,
	Stack,
	useTheme,
} from "@mui/material";
import NameField from "../FieldType/Name";
import MetricsYAxis from "../FieldType/MetricsYAxis";
import ViewByXAxis from "../FieldType/ViewByXAxis";
import { useFieldArray, useForm } from "react-hook-form";
import { isEmptyObject } from "../../../../../../utils/common";
import { FormFieldName, MenuItemText } from "../../../../../../styles/twozo";
import { default as AddIcon } from "../../../../../../assets/icons/add";
import { default as DeleteIcon } from "../../../../../../assets/icons/delete";
import { MAX_CHART_Y_AXIS_METRIC_COUNT } from "../../Config";
import Menu from "../../../../../Elements/Menu";
import { useDashboardBuilderContext } from "../../Context";
import MoreIconButton from "../../../../../Elements/MoreIconButton";

const chartPropertyYAxisMetricDefaultValue = {
	measureId: "",
	moduleData: "",
	moduleId: "",
	fieldId: "",
	label: "",
};

const chartPropertiesDefaultValue = {
	viewName: "",
	viewProperties: {
		metrics: [
			{
				...chartPropertyYAxisMetricDefaultValue,
			},
		],
		viewBy: {
			fieldId: "",
			label: "",
		},
	},
};

export default function ChartProperties(props) {
	const { chartConfiguration, onSubmit } = props;
	const theme = useTheme();
	const { setIsSelectedChartDirty } = useDashboardBuilderContext();
	const [yAxisMetricsDeleteMenuElement, setYAxisMetricsDeleteMenuElement] =
		useState(null);
	const openYAxisMetricsDeleteMenu = Boolean(yAxisMetricsDeleteMenuElement);
	const {
		control,
		handleSubmit,
		setValue,
		reset,
		getValues,
		formState: { isDirty, isValid: isApplyButtonEnabled },
		unregister,
		clearErrors,
	} = useForm({
		defaultValues: { ...chartPropertiesDefaultValue },
		reValidateMode: false,
	});
	const {
		fields: yAxisMetricsFields,
		append: appendYAxisMetric,
		remove: removeYAxisMetric,
	} = useFieldArray({
		control,
		name: "viewProperties.metrics",
	});

	useEffect(() => {
		setIsSelectedChartDirty(isDirty);
	}, [setIsSelectedChartDirty, isDirty]);

	useEffect(() => {
		const defaultValues = { ...chartPropertiesDefaultValue };
		if (chartConfiguration.viewName) {
			defaultValues["viewName"] = chartConfiguration.viewName;
		}
		if (!isEmptyObject(chartConfiguration.viewProperties)) {
			defaultValues["viewProperties"] = JSON.parse(
				JSON.stringify(chartConfiguration.viewProperties)
			); // Deep clone chartConfiguration.viewProperties

			defaultValues.viewProperties.metrics =
				defaultValues.viewProperties.metrics.map((viewProperty) => ({
					...viewProperty,
					moduleData: JSON.stringify({
						moduleId: viewProperty.moduleId,
						fieldId: viewProperty.fieldId,
					}),
				}));
		}
		reset(defaultValues);
	}, [chartConfiguration, reset]);

	const yAxisMetricsCountExceeded = useMemo(() => {
		return yAxisMetricsFields.length >= MAX_CHART_Y_AXIS_METRIC_COUNT;
	}, [yAxisMetricsFields]);

	const OpenYAxisMetricsDeleteMenu = (event) => {
		setYAxisMetricsDeleteMenuElement(event.currentTarget);
	};

	const CloseYAxisMetricsDeleteMenu = () => {
		setYAxisMetricsDeleteMenuElement(null);
	};

	const AddYAxisMetric = () => {
		if (!yAxisMetricsCountExceeded) {
			appendYAxisMetric({ ...chartPropertyYAxisMetricDefaultValue });
		}
	};

	const RemoveYAxisMetric = (index) => {
		CloseYAxisMetricsDeleteMenu();
		removeYAxisMetric(index);
	};

	const onFormSubmit = (data) => {
		onSubmit(data);
		reset({}, { keepValues: true });
	};

	return (
		<React.Fragment>
			<Menu
				anchorEl={yAxisMetricsDeleteMenuElement}
				open={openYAxisMetricsDeleteMenu}
				onClose={CloseYAxisMetricsDeleteMenu}
			>
				{yAxisMetricsFields.map((_, index) => (
					<MenuItem
						key={index}
						style={{ height: "40px" }}
						onClick={() => {
							RemoveYAxisMetric(index);
						}}
					>
						<Stack direction="row" alignItems="center" spacing={2}>
							<Box display="flex">
								{DeleteIcon(20, 20, theme.palette.error.main)}
							</Box>

							<MenuItemText color={theme.palette.error.main}>
								Remove Metric {index + 1}
							</MenuItemText>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			<form
				onSubmit={handleSubmit(onFormSubmit)}
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					flex={1}
					style={{
						overflowY: "auto",
					}}
				>
					<Box p={1}>
						<NameField
							control={control}
							clearErrors={clearErrors}
						/>
					</Box>

					<Box pt={1}>
						<Divider />
					</Box>

					<Box p={1} pt={2}>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							spacing={1}
						>
							<FormFieldName py={1}>
								Metrics (Y Axis)
							</FormFieldName>

							<Stack
								direction="row"
								alignItems="center"
								spacing={0.5}
							>
								{!yAxisMetricsCountExceeded && (
									<IconButton
										size="small"
										onClick={AddYAxisMetric}
									>
										{AddIcon(
											20,
											20,
											theme.palette.primary.main
										)}
									</IconButton>
								)}

								{yAxisMetricsFields.length > 1 && (
									<MoreIconButton
										onClick={OpenYAxisMetricsDeleteMenu}
										sx={{ transform: "rotate(90deg)" }}
										isFocused={openYAxisMetricsDeleteMenu}
									/>
								)}
							</Stack>
						</Stack>

						<Stack spacing={1}>
							{yAxisMetricsFields.map((field, index) => (
								<Box key={field.id}>
									<MetricsYAxis
										index={index}
										control={control}
										setValue={setValue}
										getValues={getValues}
									/>
								</Box>
							))}
						</Stack>
					</Box>

					<Box>
						<Divider />
					</Box>

					<Box p={1} pt={2} pb={4}>
						<FormFieldName py={1}>View By (X Axis)</FormFieldName>

						<ViewByXAxis
							control={control}
							setValue={setValue}
							unregister={unregister}
						/>
					</Box>
				</Box>

				<Box>
					<Divider />
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						p={2}
					>
						<Button
							variant="contained"
							color="secondary"
							onClick={() =>
								reset(
									{ ...chartPropertiesDefaultValue },
									{ keepDirty: true }
								)
							}
							disableElevation
						>
							Clear All
						</Button>

						<Button
							type="submit"
							variant="contained"
							disableElevation
							disabled={!isApplyButtonEnabled}
						>
							Apply
						</Button>
					</Stack>
				</Box>
			</form>
		</React.Fragment>
	);
}
