import React, { useMemo, useState, useCallback } from "react";
import CompaniesMenu from "./Menu";
import SelectedMenu from "./SelectedMenu";
import Table from "../Elements/Table";
import { sourceType } from "../../utils/queryKeys";
import { useNavigate } from "react-router-dom";
import { modules } from "../../utils/common/ModulesName";
import ImportModuleDialog from "../Elements/ImportModuleDialog";
import { FilterSaveViewProvider } from "../Elements/Table/Filter/Context/FilterSaveViewContext";
import { FilterDataProvider } from "../Elements/Table/Filter/Context/FilterDataContext";
import { default as TaskIcon } from "../../assets/icons/task";
import { default as EditIcon } from "../../assets/icons/edit";
import { default as DeleteIcon } from "../../assets/icons/delete";
import CustomSwipeableDrawer from "../Elements/CustomSwipeableDrawer";
import { useDeleteCompanyMutation } from "../../hooks/services/company";
import AddActivity from "../Summary/Activities/AddActivity";
import { Box, Stack } from "@mui/material";
import { twozoStyles } from "../../styles/twozo";
import EditCompany from "./EditCompany";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../utils/notification/notificationConfig";
import { notificationMessage } from "../../utils/notification/notificationMessages";
import DeleteDialog from "../Elements/DeleteDialog";
import { useAuth } from "../../hooks/auth";
import { PERMISSIONS } from "../../utils/Auth";
import { useModuleName } from "../../hooks/modules";
import { tableActions } from "../../utils/tableUtills";
import NoCompanyFound from "./NoCompanyFound";
import Can from "../Auth/Can";
import CanNot from "../Auth/CanNot";
import UnAuthorized from "../Auth/UnAuthorized";

export default function Companies() {
	const navigate = useNavigate();
	const classes = twozoStyles();
	// query call:-
	const { isUserAllowedFor } = useAuth();
	const hasShareViewPermissions = isUserAllowedFor(
		PERMISSIONS.company.shareView
	);

	const [isCompanyImportDialogOpened, setIsCompanyImportDialogOpened] =
		useState(false);

	const [selectedRowCompany, setSelectedRowCompany] = useState(null);
	const [openAddActivityDialog, setOpenAddActivityDialog] = useState(false);
	const [isEditFormDialogOpen, setIsEditFormDialogOpen] = useState(false);
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);

	const { getModuleName } = useModuleName();

	// delete mutation
	const deleteMutation = useDeleteCompanyMutation();

	const handleCloseImportDialog = () => {
		setIsCompanyImportDialogOpened(false);
	};

	const onImportClicked = () => {
		setIsCompanyImportDialogOpened(true);
	};

	const openCompanySummary = useCallback(
		(row) => {
			navigate(row.id.toString());
		},
		[navigate]
	);

	const handleTableRowAction = useCallback(
		(action, actionData) => {
			switch (action) {
				case tableActions.rowClickAction:
					openCompanySummary(actionData);
					break;
				default:
					break;
			}
		},
		[openCompanySummary]
	);

	const toggleAddActivityDialog = () => {
		setOpenAddActivityDialog(
			(openAddCompanyDialog) => !openAddCompanyDialog
		);
	};

	const toggleEditFormDialog = () => {
		setIsEditFormDialogOpen((openEditFormDialog) => !openEditFormDialog);
	};

	const openDeleteDialog = () => {
		setIsDeleteDialogOpened(true);
	};

	const closeDeleteDialog = () => {
		setIsDeleteDialogOpened(false);
	};

	const handleDeleteCompany = () => {
		deleteMutation.mutate(selectedRowCompany?.id, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.companyDeleted,
				});
				closeDeleteDialog();
			},
		});
	};

	const filteredTableMenuOptions = useMemo(() => {
		const onDeleteClicked = (row) => {
			setSelectedRowCompany(row);
			openDeleteDialog();
		};

		const onAddActivityClicked = (row) => {
			setSelectedRowCompany(row);
			toggleAddActivityDialog();
		};

		const onEditClicked = (row) => {
			setSelectedRowCompany(row);
			toggleEditFormDialog();
		};

		const tableMenuOptions = [
			{
				id: 1,
				name: "Edit",
				icon: EditIcon,
				action: onEditClicked,
				permission: isUserAllowedFor(PERMISSIONS.company.edit),
			},
			{
				id: 2,
				name: "Add Activity",
				icon: TaskIcon,
				action: onAddActivityClicked,
				permission: true,
			},
			{
				id: 3,
				name: "Delete",
				icon: DeleteIcon,
				action: onDeleteClicked,
				permission: isUserAllowedFor(PERMISSIONS.company.delete),
			},
		];

		let filteredTableMenuOptions = tableMenuOptions.filter(
			(option) => option.permission
		);
		return filteredTableMenuOptions;
	}, [isUserAllowedFor]);

	let moduleName = getModuleName(modules.COMPANY).toLowerCase();

	const handleOpenAddActivity = () => {
		setOpenAddActivityDialog(true);
	};

	return (
		<React.Fragment>
			<DeleteDialog
				title={`Are you sure you want to delete the ${moduleName}?`}
				subtitle={`It will delete the ${moduleName} and the data associated with it. You can retrieve it from the Recycle Bin. It remains there for 90 days.`}
				open={isDeleteDialogOpened}
				onCancel={closeDeleteDialog}
				onDelete={handleDeleteCompany}
				disableDeleteButton={deleteMutation.isLoading}
			></DeleteDialog>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isEditFormDialogOpen}
				onOpen={toggleEditFormDialog}
				onClose={toggleEditFormDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<EditCompany
						companyId={selectedRowCompany?.id}
						onClose={toggleEditFormDialog}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={openAddActivityDialog}
				onOpen={toggleAddActivityDialog}
				onClose={toggleAddActivityDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddActivity
						onClose={toggleAddActivityDialog}
						companyValue={{
							name: selectedRowCompany?.name,
							value: selectedRowCompany?.id,
						}}
						openAddActivityDialog={handleOpenAddActivity}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<ImportModuleDialog
				open={isCompanyImportDialogOpened}
				onClose={handleCloseImportDialog}
				moduleName={modules.COMPANY}
				disableBackdropClick={true}
			/>

			<FilterDataProvider>
				<FilterSaveViewProvider>
					<Can permission={PERMISSIONS.company.accessView}>
						<Table
							table={sourceType.COMPANY}
							moduleName={modules.COMPANY}
							menu={
								<CompaniesMenu
									onImportClicked={onImportClicked}
								/>
							}
							selectedMenu={<SelectedMenu />}
							handleTableRowAction={handleTableRowAction}
							isActionsVisible={true}
							noResultsFound={<NoCompanyFound />}
							hasShareViewPermissions={hasShareViewPermissions}
							tableMenuOptions={filteredTableMenuOptions}
						/>
					</Can>

					<CanNot permission={PERMISSIONS.company.accessView}>
						<Stack height="80vh">
							<UnAuthorized />
						</Stack>
					</CanNot>
				</FilterSaveViewProvider>
			</FilterDataProvider>
		</React.Fragment>
	);
}
