import React, { useEffect, useMemo, useState } from "react";
import DropdownBaseComponent from "../DropdownBaseComponent";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";
import { useContactList } from "../../../../../hooks/services/contact";

export default function ContactComponent(props) {
	const { field, style, updateFieldValue, error, value, clearError } = props;
	const [selected, setSelected] = useState(null);
	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		if (value) {
			setSelected(value);
		}
	}, [value]);

	const {
		data: contacts,
		isLoading: isLoadingContacts,
		isFetching: isFetchingContacts,
		hasNextPage: hasNextPage,
		fetchNextPage: fetchNextPage,
	} = useContactList(true, searchValue);

	const formattedOptions = useMemo(() => {
		if (Array.isArray(contacts) && contacts.length > 0) {
			return contacts.map((option) => {
				if (option.id) {
					option["value"] = option["id"];
				} else if (option.value) {
					option["id"] = option["value"];
				}
				return option;
			});
		}

		return [];
	}, [contacts]);

	const onFieldChange = (value) => {
		setSelected(value);
		updateFieldValue([value], field.fieldId);
		clearError(field.fieldId);
	};

	const updateSearchValue = (value) => {
		setSearchValue(value);
	};
	const lastRowRef = useLastRowRef(
		fetchNextPage,
		hasNextPage,
		isFetchingContacts
	);

	return (
		<React.Fragment>
			<DropdownBaseComponent
				selected={selected}
				options={formattedOptions || []}
				onFieldChange={onFieldChange}
				style={style}
				error={error}
				searchValue={searchValue}
				isFetching={isFetchingContacts}
				isOptionsLoading={isLoadingContacts}
				lastRowRef={lastRowRef}
				updateSearchValue={updateSearchValue}
			/>
		</React.Fragment>
	);
}
