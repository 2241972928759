import React from "react";

function Probability(width = "14", height = "14", color = "#33BC7E") {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.1484 16.5V18.6"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.1484 22V21C17.1484 19.9 16.2484 19 15.1484 19H9.14844C8.04844 19 7.14844 19.9 7.14844 21V22H17.1484Z"
				stroke={color}
				strokeWidth="1.5"
			/>
			<path
				d="M6.14844 22H18.1484"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5 9V6C5 3.79 6.79 2 9 2H15C17.21 2 19 3.79 19 6V9C19 12.87 15.87 16 12 16C8.13 16 5 12.87 5 9Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.53906 10.4496C2.63906 9.44961 2.03906 8.24961 2.03906 6.84961C2.03906 5.44961 3.13906 4.34961 4.53906 4.34961H5.18906C4.98906 4.80961 4.88906 5.31961 4.88906 5.84961V8.84961C4.88906 9.84961 5.09906 10.7896 5.46906 11.6496C4.71906 11.4096 4.05906 10.9696 3.53906 10.4496Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M20.4593 10.4496C21.3593 9.44961 21.9593 8.24961 21.9593 6.84961C21.9593 5.44961 20.8593 4.34961 19.4593 4.34961H18.8093C19.0093 4.80961 19.1093 5.31961 19.1093 5.84961V8.84961C19.1093 9.84961 18.8993 10.7896 18.5293 11.6496C19.2793 11.4096 19.9393 10.9696 20.4593 10.4496Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Probability;
