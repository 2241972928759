import React, { useState } from "react";
import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import { FormFieldName, MenuItemText } from "../../../../../../styles/twozo";
import MenuSearchBar from "../../../../../Elements/MenuSearchBar";
import { CircularProgress, Skeleton } from "@mui/material";
import { useCountryList } from "../../../../../../hooks/services/phone";
import { useLastRowRef } from "../../../../../../hooks/common/InfiniteScroll";
import { useDebouncedValue } from "../../../../../../hooks/debounce";

export default function CountryMenu(props) {
	const { value, onChange } = props;

	const [searchedValue, setSearchedValue] = useState("");
	const [isMenuListOpened, setIsMenuListOpened] = useState(false);

	const debouncedSearchValue = useDebouncedValue(searchedValue);

	const {
		data: countryList,
		isLoading: isLoadingCountryList,
		isFetching: isFetchingCountryList,
		hasNextPage: hasNextCountryListPage,
		fetchNextPage: fetchNextCountryListPage,
	} = useCountryList(debouncedSearchValue);

	const lastOptionRef = useLastRowRef(
		fetchNextCountryListPage,
		hasNextCountryListPage,
		isFetchingCountryList
	);

	const handleSearchValue = (event) => {
		const { value } = event.target;
		setSearchedValue(value);
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					{searchedValue && countryList?.length === 0
						? "No Results Found"
						: "No Options Found"}
				</Typography>
			</Stack>
		);
	};

	const openMenuList = () => {
		setIsMenuListOpened(true);
		setSearchedValue("");
	};

	const closeMenuList = () => {
		setIsMenuListOpened(false);
	};

	const handleSelectCountry = (selectedCountry) => {
		const fieldName = "country";
		onChange(selectedCountry, fieldName);
		closeMenuList();
	};

	return (
		<React.Fragment>
			<Stack spacing={0.5}>
				<FormFieldName>Country</FormFieldName>

				<Select
					size="small"
					fullWidth
					MenuProps={{
						autoFocus: false,
						PaperProps: {
							style: {
								marginTop: "4px",
								borderRadius: "8px",
								maxHeight: "290px",
								maxWidth: "400px",
							},
						},
					}}
					open={isMenuListOpened}
					onClose={closeMenuList}
					onOpen={openMenuList}
					displayEmpty
					renderValue={() => (
						<Typography
							fontSize={14}
							noWrap
							color={value?.name ? "#000" : "rgba(0, 0, 0, 0.6)"}
						>
							{value?.name ?? "Select"}
						</Typography>
					)}
				>
					<Box
						p={0.5}
						marginTop="-8px"
						sx={{
							position: "sticky",
							top: 0,
							zIndex: 1,
							backgroundColor: "#fff",
						}}
					>
						<MenuSearchBar
							value={searchedValue}
							onChange={handleSearchValue}
						/>
					</Box>

					{isLoadingCountryList ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="150px"
						>
							<CircularProgress size="25px" />
						</Stack>
					) : countryList?.length > 0 ? (
						countryList.map((country) => (
							<MenuItem
								key={country.id}
								style={{
									height: "40px",
								}}
								onClick={() => handleSelectCountry(country)}
							>
								<MenuItemText whiteSpace="wrap">
									{country.name}
								</MenuItemText>
							</MenuItem>
						))
					) : (
						renderEmptyView()
					)}

					<Box ref={lastOptionRef}>
						{!isLoadingCountryList &&
							isFetchingCountryList &&
							countryList?.length > 0 && (
								<MenuItem style={{ height: "40px" }}>
									<Skeleton width="190px" height="16px" />
								</MenuItem>
							)}
					</Box>
				</Select>
			</Stack>
		</React.Fragment>
	);
}
