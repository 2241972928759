import React from "react";
import MultiSelectField from "../ValueField/MultiSelectField";

export default function MultiSelectValueField(props) {
	const { filterCondition, onChange } = props;
	return (
		<React.Fragment>
			<MultiSelectField
				filterCondition={filterCondition}
				onChange={onChange}
			/>
		</React.Fragment>
	);
}
