import DropdownComponent from "../../../../AddForm/Field/FieldComponents/DropdownComponent";
import { getFieldGroupStyle } from "../../../styles";

export default function DropdownFieldComponent(params) {
	const { field, isGroupedFieldTop, isGroupedFieldBottom, values } = params;

	const isGroupField = field?.config?.isGroupedField;

	return (
		<>
			<DropdownComponent
				field={field}
				values={values}
				fieldSpecificProps={{
					dropdownSx: isGroupField && {
						".MuiOutlinedInput-notchedOutline": getFieldGroupStyle(
							isGroupedFieldTop,
							isGroupedFieldBottom
						),
						"&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
							getFieldGroupStyle(
								isGroupedFieldTop,
								isGroupedFieldBottom
							),
						"&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
							getFieldGroupStyle(
								isGroupedFieldTop,
								isGroupedFieldBottom
							),
					},
				}}
			/>
		</>
	);
}
