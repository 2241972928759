import React, { useState } from "react";
import { default as DropDownIcon } from "../../../../../../../../../assets/icons/dropDown";
import { useFilterDataContext } from "../../../../../Context/FilterDataContext";
import { useValueDropDown } from "../../../../../../../../../hooks/services/common/table/filter";
import { useLastRowRef } from "../../../../../../../../../hooks/common/InfiniteScroll";
import {
	Box,
	CircularProgress,
	ListSubheader,
	MenuItem,
	Skeleton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import Menu from "../../../../../../../Menu";
import MenuSearchBar from "../../../../../../../MenuSearchBar";
import { MenuItemText } from "../../../../../../../../../styles/twozo";
import { fieldType } from "../../../../../../../../../utils/FilterUtills";
import { searchTypes } from "../../../../../../../../../utils/dropdownData";

export default function Dropdown(props) {
	const { filterCondition, onChange } = props;
	const theme = useTheme();
	const { enableApplyButton } = useFilterDataContext();

	const styles = {
		field: {
			alignItems: "center",
			justifyContent: "space-between",
			width: "218px",
			height: "38px",
			padding: "0px 10px",
			backgroundColor: "rgba(51, 188, 126, 0.12)",
		},
	};

	const [searchValue, setSearchValue] = useState("");
	const [dropDownMenuElement, setDropDownMenuElement] = useState(null);
	const isDropDownMenuElement = Boolean(dropDownMenuElement);

	const selectedField = filterCondition?.fieldData || {};

	const selectedDropDownId = selectedField.fieldId ?? selectedField.optionId;
	const selectedDropDownName =
		selectedField?.valueDropdown?.valueDropdownName ?? null;

	const getDropdownRequest = (searchValue) => {
		if (selectedField?.valueDropdown) {
			if (
				!!selectedField?.valueDropdown?.requiredFields &&
				selectedField?.valueDropdown?.requiredFields[0] === "id"
			) {
				return {
					id: selectedDropDownId,
					criteria: {
						type: searchTypes.contains,
						value: searchValue,
					},
				};
			} else if (
				!!selectedField?.valueDropdown?.requiredFields &&
				selectedField?.valueDropdown?.requiredFields[0] === "sourceId"
			) {
				return {
					name: selectedDropDownName,
					sourceId: filterCondition?.sourceId?.value?.id,
					criteria: {
						type: searchTypes.contains,
						value: searchValue,
					},
				};
			} else {
				return {
					name: selectedDropDownName,
					criteria: {
						type: searchTypes.contains,
						value: searchValue,
					},
				};
			}
		}
	};

	const {
		data: dropDownData,
		isLoading: isLoadingDropdownData,
		isFetching,
		hasNextPage,
		fetchNextPage,
	} = useValueDropDown(
		getDropdownRequest(searchValue),
		selectedDropDownId,
		!!selectedField?.valueDropdown
	);

	const lastOptionRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const handleSearch = (event) => {
		const { value } = event.target;
		setSearchValue(value?.trim());
	};

	const openDropDownMenu = (event) => {
		setDropDownMenuElement(event.currentTarget);
		setSearchValue("");
	};

	const closeDropDownMenu = () => {
		setDropDownMenuElement(null);
	};

	const handleSelectValue = (selectedValue) => {
		const updatedFilterCondition = {
			...filterCondition,
			valueEntityId: {
				...filterCondition.valueEntityId,
				value: selectedValue.value
					? selectedValue.value
					: selectedValue.id,
			},
			value: {
				...filterCondition.value,
				value: selectedValue.name,
			},
			singleSelectValue: selectedValue,
		};
		onChange(updatedFilterCondition);
		enableApplyButton();
		setDropDownMenuElement(null);
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					No Results Found
				</Typography>
			</Stack>
		);
	};

	const renderDateIntervalListDropdown = () => {
		return (
			<>
				{dropDownData?.map((option, optionIndex) => (
					<Stack key={optionIndex}>
						<ListSubheader
							style={{
								height: "40px",
								display: "flex",
								alignItems: "center",
							}}
						>
							<MenuItemText color="#000" fontWeight={600}>
								{option.category}
							</MenuItemText>
						</ListSubheader>

						{option?.days?.map((day) => (
							<MenuItem
								key={day.id}
								value={day.id}
								style={{ height: "40px" }}
								onClick={() => handleSelectValue(day)}
							>
								<MenuItemText>{day.name}</MenuItemText>
							</MenuItem>
						))}
					</Stack>
				))}
			</>
		);
	};

	const renderGroupedDropdown = () => {
		return (
			<>
				{dropDownData?.map((option, optionIndex) => (
					<Stack key={optionIndex}>
						<ListSubheader
							style={{
								height: "40px",
								display: "flex",
								alignItems: "center",
							}}
						>
							<MenuItemText color="#000" fontWeight={600}>
								{option.name}
							</MenuItemText>
						</ListSubheader>

						{option?.options?.map((subOption) => (
							<MenuItem
								key={subOption.value}
								value={subOption.value}
								style={{ height: "40px" }}
								onClick={() => handleSelectValue(subOption)}
							>
								<MenuItemText>{subOption.name}</MenuItemText>
							</MenuItem>
						))}
					</Stack>
				))}
			</>
		);
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="202px"
				anchorEl={dropDownMenuElement}
				open={isDropDownMenuElement}
				onClose={closeDropDownMenu}
				style={{ transform: "translateX(8px) translateY(-1px)" }}
				PaperProps={{
					style: {
						maxHeight: "400px",
					},
				}}
			>
				<Box
					p={0.5}
					marginTop="-8px"
					sx={{
						position: "sticky",
						top: 0,
						zIndex: 2,
						backgroundColor: "#fff",
					}}
				>
					<MenuSearchBar
						value={searchValue}
						onChange={handleSearch}
					/>
				</Box>

				<Box>
					{isLoadingDropdownData ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="140px"
						>
							<CircularProgress size="18px" />
						</Stack>
					) : dropDownData.length > 0 ? (
						selectedDropDownName === "dateIntervalList" ? (
							renderDateIntervalListDropdown()
						) : selectedField.fieldType.name ===
								fieldType.dropdown &&
						  selectedField.dropdownType === "grouped" ? (
							renderGroupedDropdown()
						) : (
							dropDownData?.map((option, optionIndex) => (
								<MenuItem
									style={{ height: "40px" }}
									key={optionIndex}
									onClick={() => handleSelectValue(option)}
								>
									<MenuItemText fontSize={13}>
										{option.name}
									</MenuItemText>
								</MenuItem>
							))
						)
					) : (
						renderEmptyView()
					)}

					<Box ref={lastOptionRef}>
						{!isLoadingDropdownData &&
							isFetching &&
							dropDownData?.length > 0 && (
								<MenuItem style={{ height: "40px" }}>
									<Skeleton width="190px" height="12px" />
								</MenuItem>
							)}
					</Box>
				</Box>
			</Menu>

			<Stack
				direction="row"
				style={{
					...styles.field,
					cursor: "pointer",
					borderRadius: "0px 0px 8px 8px",
					borderBottom: dropDownMenuElement
						? `1px solid ${theme.palette.primary.main}`
						: null,
				}}
				onClick={(event) => openDropDownMenu(event)}
			>
				{filterCondition.value.value ? (
					<Typography fontSize={14} noWrap>
						{filterCondition.value.value}
					</Typography>
				) : (
					<Typography
						sx={{
							fontSize: "14px",
							color: "rgba(0,0,0,0.4)",
						}}
					>
						Select
					</Typography>
				)}
				<Box sx={{ pl: 1 }}>{DropDownIcon(12, 12)}</Box>
			</Stack>
		</React.Fragment>
	);
}
