import {
	Skeleton,
	Stack,
	TableBody as MUITableBody,
	TableCell,
	TableRow,
	MenuItem,
	Box,
} from "@mui/material";
import Row from "../Row";
import { useCallback, useState } from "react";
import { useTheme } from "@emotion/react";
import { MenuItemText } from "../../../../styles/twozo";
import Menu from "../../Menu";

export default function TableBody({
	isTableLoading,
	selectedColumns,
	tableData,
	handleTableRowAction,
	lastRowRef,
	isActionsVisible,
	handleCheckboxClick,
	tableMenuOptions = [],
	tableHasAnyData,
	isSelected,
	shouldShowCheckbox = true,
	noResultsFound,
}) {
	const [tableMenuElement, setTableMenuElement] = useState(null);
	const [tableRowElement, setTableRowElement] = useState(null);
	const isOpenTableMenu = Boolean(tableMenuElement);
	const theme = useTheme();

	const openTableMenu = useCallback((event, row) => {
		event.stopPropagation();
		setTableRowElement(row);
		setTableMenuElement(event.currentTarget);
	}, []);

	const closeTableMenu = () => {
		setTableMenuElement(null);
		setTableRowElement(null);
	};

	const styles = {
		menuItem: {
			fontWeight: 500,
			color: theme.palette.primary.main,
		},
	};

	return (
		<>
			<Menu
				minWidth="200px"
				anchorEl={tableMenuElement}
				open={isOpenTableMenu}
				onClose={closeTableMenu}
			>
				{tableMenuOptions.map((menu) => (
					<MenuItem
						key={menu.id}
						style={{ height: "40px" }}
						onClick={() => {
							menu.action(tableRowElement);
							closeTableMenu();
						}}
					>
						<Stack direction="row" spacing={2}>
							<Box display="flex">
								{menu.icon(20, 20, theme.palette.primary.main)}
							</Box>

							<MenuItemText style={styles.menuItem}>
								{menu.name}
							</MenuItemText>
						</Stack>
					</MenuItem>
				))}
			</Menu>
			<MUITableBody>
				{isTableLoading ? (
					new Array(10).fill(0).map((_, index) => (
						<TableRow key={index}>
							<TableCell padding="checkbox">
								<Skeleton
									variant="text"
									sx={{ fontSize: "13px" }}
								/>
							</TableCell>

							{selectedColumns.map((_, index) => (
								<TableCell
									key={index}
									style={{
										minWidth: "200px",
									}}
								>
									<Skeleton
										variant="text"
										sx={{
											fontSize: "13px",
										}}
									/>
								</TableCell>
							))}
						</TableRow>
					))
				) : tableHasAnyData ? (
					tableData.pages.map((page) =>
						page.rows.map((row) => (
							<Row
								selected={isSelected(row.id)}
								key={row.id}
								handleTableRowAction={handleTableRowAction}
								row={row}
								lastRowRef={lastRowRef}
								isActionsVisible={isActionsVisible}
								handleCheckboxClick={handleCheckboxClick}
								openTableMenu={openTableMenu}
								selectedColumns={selectedColumns}
								isMoreMenuOpened={
									tableRowElement?.id === row.id
								}
								shouldShowCheckbox={shouldShowCheckbox}
							></Row>
						))
					)
				) : (
					<TableRow>
						<TableCell
							colSpan={selectedColumns.length + 1}
							sx={{ borderBottom: "none" }}
						>
							{noResultsFound}
						</TableCell>
					</TableRow>
				)}
			</MUITableBody>
		</>
	);
}
