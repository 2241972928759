import AddOrRemoveColumns from "../../../../../Elements/Table/AddOrRemoveColumns";
import { TableHeaderLabel } from "../../../../../../styles/twozo";
import TableBody from "../../../../../Elements/Table/TableBody";
import CountOverview from "../../../../../Elements/Table/CountOverview";
import { default as MuiTable } from "@mui/material/Table";
import { useEffect, useRef, useState } from "react";
import {
	Box,
	CircularProgress,
	Stack,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { default as DropDownIcon } from "../../../../../../assets/icons/dropDown";
import { useUnderlyingList } from "../../../../../../hooks/services/analytics";
import { useLastRowRef } from "../../../../../../hooks/common/InfiniteScroll";

const sortTypes = {
	ascending: 1,
	decending: 0,
};

export default function UnderlyingDataTable(params) {
	const { viewId, viewTypeId, moduleId, childViewId } = params;

	const [selectedColumns, setSelectedColumns] = useState([]);
	const [sortRequest, setSortRequest] = useState([]);
	const [selectRequest, setSelectRequest] = useState([]);
	const [tableStructure, setTableStructure] = useState({});
	const [countSummary, setCountSummary] = useState([]);
	const tableRef = useRef(null); // Reference to find Table stating position
	const [tableStartingPosition, setTableStartingPosition] = useState(0);

	const {
		data: tableData,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isLoading: isTableDataLoading,
	} = useUnderlyingList(
		viewId,
		moduleId,
		viewTypeId,
		childViewId,
		selectRequest.length > 0 ? selectRequest : undefined,
		sortRequest
	);

	useEffect(() => {
		if (!isTableDataLoading) {
			for (
				let pageCount = 0;
				pageCount < tableData.pages.length;
				pageCount++
			) {
				if (tableData.pages[pageCount].structure) {
					setTableStructure({
						...tableData.pages[pageCount].structure,
					});
					updateSelectedColumn([
						...tableData.pages[pageCount].structure.columns,
					]);
				}
			}
		}
	}, [isTableDataLoading, tableData]);

	const updateSelectedColumn = async (columns) => {
		const selectedColumns = [];
		const selectRequest = [];

		columns.forEach((column) => {
			if (!column.hidden) {
				selectedColumns.push(column);
				selectRequest.push(column.id);
			}
		});
		setSelectRequest([...selectRequest]);
		setSelectedColumns([...selectedColumns]);
	};

	const toggleSort = async (columnIndex) => {
		const sortRequest = [];

		setSelectedColumns((selectedColumns) => {
			if (selectedColumns[columnIndex].sort === sortTypes.ascending) {
				selectedColumns[columnIndex].sort = sortTypes.decending;
				sortRequest.push({
					field: selectedColumns[columnIndex].name,
					type: "desc",
				});
			} else {
				selectedColumns[columnIndex].sort = sortTypes.ascending;
				sortRequest.push({
					field: selectedColumns[columnIndex].name,
					type: "asc",
				});
			}

			selectedColumns.forEach((column, index) => {
				if (
					column.sort === sortTypes.ascending &&
					index !== columnIndex
				) {
					selectedColumns[index].sort = sortTypes.decending;
				}
			});

			return selectedColumns;
		});

		setSortRequest(sortRequest);
	};

	useEffect(() => {
		if (tableRef) {
			setTableStartingPosition(
				tableRef.current.getBoundingClientRect().top
			);
		}
	}, [tableRef]);

	useEffect(() => {
		if (tableData && tableData.pages.length) {
			setCountSummary(
				tableData.pages[tableData.pages.length - 1].countSummary || []
			);
		}
	}, [tableData]);

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const tableHasAnyData =
		tableData &&
		tableData?.pages?.length > 0 &&
		tableData.pages[0]?.rows &&
		tableData.pages[0]?.rows?.length > 0;

	return (
		<Box
			ref={tableRef}
			m={2}
			style={{
				border: "1px solid rgba(0, 0, 0, 0.1)",
				borderRadius: "8px",
				height: `calc(100vh - ${tableStartingPosition}px)`,
			}}
		>
			{selectRequest.length === 0 && isTableDataLoading ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="60vh"
				>
					<CircularProgress />
				</Stack>
			) : (
				<TableContainer sx={{ height: "100%" }}>
					<AddOrRemoveColumns
						tableStructure={tableStructure}
						updateSelectedColumn={updateSelectedColumn}
						setTableStructure={setTableStructure}
					></AddOrRemoveColumns>

					<MuiTable sx={{ minWidth: 650 }} size="small">
						<TableHead>
							<TableRow
								sx={{
									height: "36px",
									position: "sticky",
									top: 0,
									backgroundColor: "#fff",
									zIndex: 1,
									"&:hover": {
										backgroundColor: "#fff",
									},
								}}
							>
								{selectedColumns.map((column, index) => (
									<TableCell
										key={column.name}
										style={{
											minWidth: "200px",
										}}
										onClick={
											column.isSortable
												? () => toggleSort(index)
												: null
										}
									>
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel
												style={{
													whiteSpace: "nowrap",
												}}
											>
												{column.displayName}
											</TableHeaderLabel>

											<Box
												sx={{
													display: column.isSortable
														? "flex"
														: "none",
												}}
											>
												{column.sort ===
												sortTypes.ascending ? (
													<Box
														display={"flex"}
														style={{
															transform:
																"rotate(180deg)",
														}}
													>
														{DropDownIcon(
															12,
															12,
															"rgba(0, 0, 0, 0.6)"
														)}
													</Box>
												) : (
													<Box display={"flex"}>
														{DropDownIcon(
															12,
															12,
															"rgba(0, 0, 0, 0.6)"
														)}{" "}
													</Box>
												)}
											</Box>
										</Stack>
									</TableCell>
								))}
							</TableRow>
						</TableHead>

						<TableBody
							isTableLoading={isTableDataLoading}
							selectedColumns={selectedColumns}
							shouldShowCheckbox={false}
							tableData={tableData}
							lastRowRef={lastRowRef}
							isSelected={() => {}}
							handleTableRowAction={() => {}}
							tableHasAnyData={tableHasAnyData}
						></TableBody>
					</MuiTable>

					{!isTableDataLoading && isFetching && tableHasAnyData && (
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="center"
							spacing={1}
							py={2}
						>
							<CircularProgress size={18} />

							<Typography fontSize={12}>
								Loading More Data
							</Typography>
						</Stack>
					)}

					<CountOverview countSummary={countSummary} />
				</TableContainer>
			)}
		</Box>
	);
}
