import React from "react";
import { Stack, Typography } from "@mui/material";

export default function DealCreated(props) {
	const { eventData } = props;

	if (!eventData) return;

	return (
		<React.Fragment>
			<Stack pb={1}>
				<Typography fontSize={15}>
					<span style={{ fontSize: "15px", fontWeight: 500 }}>
						{eventData?.newValue}
					</span>{" "}
					was {eventData?.action}
				</Typography>

				<Typography
					style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.6)" }}
				>
					{eventData?.salesOwner?.name}
				</Typography>
			</Stack>
		</React.Fragment>
	);
}
