import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import { default as RightArrowIcon } from "../../../../assets/icons/dropDownRight";
import { default as TimeIcon } from "../../../../assets/icons/time";
import { default as ContactIcon } from "../../../../assets/icons/contact";
import { default as CompanyIcon } from "../../../../assets/icons/company";
import React from "react";
import {
	getDateAndMonthFormat,
	getDateDifference,
} from "../../../../utils/DateUtils";
import { styles } from "../../styles";
import DealState from "../../SearchResults/ResultCard/Fields/dealState";

export default function DealMenu(props) {
	const { dealDetails } = props;

	const getExpectedCloseDate = (unixTimestamp) => {
		let formattedDate = getDateAndMonthFormat(unixTimestamp);
		const currentDate = new Date().valueOf();

		return `${formattedDate} ${getDateDifference(unixTimestamp, true)} ${
			currentDate > unixTimestamp ? "overdue" : ""
		}`;
	};

	return (
		<React.Fragment>
			<Box maxWidth="320px">
				<Box
					sx={{
						borderRadius: "8px",
						p: 2,
					}}
				>
					<Stack spacing={0.5} width="100%">
						<Stack
							direction="row"
							alignItems="strech"
							justifyContent="space-between"
						>
							<Typography
								noWrap
								style={styles.cardTitle}
								width="100%"
							>
								{dealDetails?.name}
							</Typography>
						</Stack>

						<Stack direction="row" spacing={1}>
							<Typography
								style={{ ...styles.dealCardText, opacity: 1 }}
							>
								${" "}
								{dealDetails?.premiumProduct?.monetary?.value
									? dealDetails?.premiumProduct?.monetary
											?.value
									: 0}
							</Typography>

							{dealDetails?.expectedCloseDate && (
								<>
									<Typography style={styles.text}>
										&#8226;
									</Typography>

									<Stack
										direction="row"
										alignItems="center"
										spacing={0.5}
									>
										{TimeIcon(16, 16, "#000", 0.6)}

										<Typography style={styles.text}>
											{getExpectedCloseDate(
												dealDetails.expectedCloseDate
											)}
										</Typography>
									</Stack>
								</>
							)}
						</Stack>

						<Stack
							direction="row"
							spacing={2}
							alignItems="center"
							justifyContent="space-between"
							width="100%"
						>
							<Stack direction="row" spacing={1} pt={1}>
								{dealDetails?.company && (
									<Box display="flex">
										<IconButton size="small">
											{CompanyIcon(20, 20, "#000", 0.6)}
										</IconButton>
									</Box>
								)}

								{dealDetails?.contact && (
									<Box display="flex">
										<IconButton size="small">
											{ContactIcon(20, 20, "#000", 0.6)}
										</IconButton>
									</Box>
								)}
							</Stack>

							{dealDetails?.dealState && (
								<Box>
									<DealState
										state={dealDetails?.dealState.name}
									/>
								</Box>
							)}
						</Stack>
					</Stack>
				</Box>

				<Divider />

				<Box p={2} width="100%">
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Typography style={styles.text}>
							No of Products
						</Typography>

						<Typography fontSize={13}>
							{dealDetails?.totalProductCount}
						</Typography>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={3}
					>
						<Typography style={styles.dealCardText}>
							Pipeline
						</Typography>

						<Stack
							direction="row"
							alignItems="center"
							spacing={0.5}
						>
							<Typography
								noWrap
								maxWidth="100px"
								style={styles.dealCardText}
							>
								{dealDetails?.pipeline?.name}
							</Typography>

							<Box display="flex">
								<IconButton size="small">
									{RightArrowIcon(12, 12, "#000", 0.6)}
								</IconButton>
							</Box>

							<Typography
								noWrap
								maxWidth="90px"
								style={styles.dealCardText}
							>
								{dealDetails?.pipelineStage?.name}
							</Typography>
						</Stack>
					</Stack>
				</Box>
			</Box>
		</React.Fragment>
	);
}
