import React from "react";
import { Bar } from "react-chartjs-2";
import { useTheme } from "@mui/material";

export default function BarChart({ chartData }) {
	const theme = useTheme();

	const colors = [theme.palette.primary.main, "#F4BE37"];

	const data = {
		labels: chartData["xAxis"]["data"],
		datasets: chartData.yAxis.map((data, index) => ({
			label: data["label"],
			data: data["data"],
			backgroundColor: colors[index % 2],
			barThickness: 50,
		})),
	};

	const options = {
		responsive: true,
		plugins: {
			tooltip: {
				enabled: true,
				filter: (tooltipItem) => {
					const chart = tooltipItem.chart;
					const xAxis = chart.scales.x;
					const visibleLabels = xAxis.ticks.map((tick) => tick.label);
					const dataIndex = tooltipItem.dataIndex;

					// Get the truncated label that is currently displayed
					const maxLength = 10;
					const originalLabel = chartData.xAxis.data[dataIndex];
					const truncatedLabel =
						typeof originalLabel === "string" &&
						originalLabel.length > maxLength
							? `${originalLabel.substring(0, maxLength)}...`
							: originalLabel;

					return visibleLabels.includes(truncatedLabel);
				},
			},
			legend: {
				position: "bottom",
				display: false,
			},
			title: {
				display: false,
			},
		},
		scales: {
			x: {
				title: {
					display: true,
					text: chartData["xAxis"]["label"],
					padding: {
						top: 12,
					},
				},
				ticks: {
					callback: function (value) {
						const maxLength = 10;
						const label = this.getLabelForValue(value);
						return typeof label === "string" &&
							label.length > maxLength
							? `${label.substring(0, maxLength)}...`
							: label;
					},
				},
				grid: {
					display: false,
				},
			},
			y: {
				title: {
					display: true,
					text: chartData["yAxis"][0]["label"],
					padding: {
						bottom: 12,
					},
				},
				grid: {
					display: false,
				},
				ticks: {
					// stepSize: 20,
				},
			},
		},
	};

	return (
		<React.Fragment>
			<Bar options={options} data={data} />
		</React.Fragment>
	);
}
