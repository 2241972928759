import React from "react";
import { Typography } from "@mui/material";
import { Tooltip } from "../../../../styles/twozo";

const styles = {
	highlightedText: {
		fontSize: "15px",
		fontWeight: 500,
	},
};

export default function ContactMerged(props) {
	const { eventData } = props;

	if (!eventData) return;

	const maxContactsToShow = 1;
	const maxContactsForTooltip = 2;

	const hasMoreThanMaxContactsToShow = (contacts) => {
		return Array.isArray(contacts) && contacts.length > maxContactsToShow;
	};

	const getSupplementaryContactsNames = (contacts) => {
		const supplementaryContacts = contacts?.slice(maxContactsToShow);

		const contactsForTooltip = supplementaryContacts
			.slice(0, maxContactsForTooltip)
			.join(", "); // // Index 0 (inclusive) to Index 2 (exclusive)

		return supplementaryContacts?.length > maxContactsForTooltip
			? `${contactsForTooltip}, +${
					supplementaryContacts.length - maxContactsForTooltip
				} others`
			: contactsForTooltip;
	};

	return (
		<React.Fragment>
			<Typography fontSize={15} pt={1.3}>
				<span style={styles.highlightedText}>
					{eventData?.oldValues?.[0]}
				</span>{" "}
				{hasMoreThanMaxContactsToShow(eventData?.oldValues) ? (
					<Tooltip
						title={getSupplementaryContactsNames(
							eventData?.oldValues
						)}
						placement="top"
					>
						<span
							style={{
								...styles.highlightedText,
								cursor: "pointer",
							}}
						>
							+{eventData?.oldValues?.length - maxContactsToShow}{" "}
						</span>
					</Tooltip>
				) : null}
				contacts was merged with{" "}
				<span style={{ fontSize: "15px", fontWeight: 500 }}>
					{eventData?.newValue}
				</span>{" "}
				to avoid duplicates
			</Typography>
		</React.Fragment>
	);
}
