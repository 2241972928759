import React, { useState } from "react";
import {
	Box,
	CircularProgress,
	MenuItem,
	Select,
	Skeleton,
	Stack,
	Typography,
} from "@mui/material";
import { useTerritoryDropdownList } from "../../../hooks/services/userManagement/territory";
import CloseIcon from "../../../assets/icons/close";
import { useLastRowRef } from "../../../hooks/common/InfiniteScroll";
import MenuSearchBar from "../MenuSearchBar";
import { MenuItemText } from "../../../styles/twozo";

export default function TerritoryMenu(props) {
	const {
		value,
		onChange,
		placeHolder,
		inputRef = null,
		error = false,
		territoryMenuStyle,
		handleRemoveTerritory,
	} = props;

	const [searchedValue, setSearchedValue] = useState("");

	const {
		data: territoryList,
		isLoading: isLoadingTerritories,
		isFetching: isFetchingTerritories,
		hasNextPage: hasNextTerritoriesPage,
		fetchNextPage: fetchNextTerritoriesPage,
	} = useTerritoryDropdownList(searchedValue);

	const lastOptionRef = useLastRowRef(
		fetchNextTerritoriesPage,
		hasNextTerritoriesPage,
		isFetchingTerritories
	);

	const handleSelectTerritory = (event, child) => {
		const { value } = event.target;
		onChange({ name: child?.props?.name, value: value });
	};

	const handleSearchValue = (event) => {
		const { value } = event.target;
		setSearchedValue(value);
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<MenuItemText color="rgba(0, 0, 0, 0.6)">
					{searchedValue ? "No Results Found" : "No Options Found"}
				</MenuItemText>
			</Stack>
		);
	};

	const displayCloseIcon = (territory) => {
		return value?.value === territory?.value;
	};

	return (
		<React.Fragment>
			<Select
				size="small"
				fullWidth
				value={value?.value || ""}
				inputRef={inputRef}
				onChange={handleSelectTerritory}
				sx={{ ...territoryMenuStyle }}
				MenuProps={{
					autoFocus: false,
					PaperProps: {
						style: {
							marginTop: "4px",
							borderRadius: "8px",
							maxHeight: "255px",
						},
					},
				}}
				displayEmpty
				renderValue={() => (
					<Typography
						fontSize={14}
						color={value?.name ? "#000" : "rgba(0, 0, 0, 0.6)"}
					>
						{value?.name ? value.name : placeHolder || "Select"}
					</Typography>
				)}
				onOpen={() => setSearchedValue("")}
				error={error}
			>
				<Box
					p={0.5}
					marginTop="-8px"
					sx={{
						position: "sticky",
						top: 0,
						zIndex: 1,
						backgroundColor: "#fff",
					}}
				>
					<MenuSearchBar
						value={searchedValue}
						onChange={handleSearchValue}
					/>
				</Box>

				{isLoadingTerritories ? (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="150px"
					>
						<CircularProgress size="25px" />
					</Stack>
				) : territoryList?.length > 0 ? (
					territoryList.map((territory) => (
						<MenuItem
							value={territory.value}
							key={territory.value}
							name={territory.name}
							style={{
								height: "40px",
							}}
						>
							<Stack
								width="100%"
								direction="row"
								spacing={1}
								alignItems="center"
								justifyContent="space-between"
							>
								<MenuItemText>{territory.name}</MenuItemText>

								{displayCloseIcon(territory) ? (
									<Box
										display="flex"
										onClick={handleRemoveTerritory}
									>
										{CloseIcon(20, 20, "#000", 0.6)}
									</Box>
								) : null}
							</Stack>
						</MenuItem>
					))
				) : (
					renderEmptyView()
				)}

				<Box ref={lastOptionRef}>
					{!isLoadingTerritories &&
						isFetchingTerritories &&
						territoryList?.length > 0 && (
							<MenuItem style={{ height: "40px" }}>
								<Skeleton width="190px" height="16px" />
							</MenuItem>
						)}
				</Box>
			</Select>
		</React.Fragment>
	);
}
