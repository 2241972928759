import React, { useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../assets/icons/close";
import { default as CallIcon } from "../../../assets/icons/call";
import { default as MailIcon } from "../../../assets/icons/mail";
import { hexToRgba } from "../../../styles/twozo";
import DealRelatedContacts from "./RelatedContacts";
import Files from "./Files";
import Activities from "./Activities";
import Notes from "./Notes";
import { useLocation, useNavigate } from "react-router-dom";
import { default as ContactIcon } from "../../../assets/icons/singleContact";
import { default as CompanyIcon } from "../../../assets/icons/company";
import { getDateMonthAndYearFormat } from "../../../utils/DateUtils";
import Menu from "../../Elements/Menu";
import ContactCard from "../../Elements/ContactCard";
import CompanyCard from "../../Elements/CompanyCard";
import TooltipComponent from "../../Elements/TooltipComponent";
import { useModuleName } from "../../../hooks/modules";
import { modules } from "../../../utils/common/ModulesName";
import { useDealSwipeableSummaryData } from "../../../hooks/services/deal";
import EmailComposerDrawer from "../../Mail/EmailComposer/EmailComposerDrawer";
import { PERMISSIONS } from "../../../utils/Auth";
import Can from "../../Auth/Can";

export default function SwipeableSummary(props) {
	const { dealId } = props;
	const theme = useTheme();
	const navigate = useNavigate();
	const { pathname, search } = useLocation();
	const { getModuleName } = useModuleName();

	const [contactMenuElement, setContactMenuElement] = useState(null);
	const isContactMenuOpened = Boolean(contactMenuElement);
	const [companyMenuElement, setCompanyMenuElement] = useState(null);
	const isCompanyMenuOpened = Boolean(companyMenuElement);
	const [isEmailComposerOpened, setIsEmailComposerOpened] = useState(false);

	const viewName = getModuleName(modules.DEAL);

	const { isLoading: isLoadingDealSummaryData, data: dealSummaryData } =
		useDealSwipeableSummaryData(dealId);

	const contactDataWithEmail = dealSummaryData?.primaryContact?.primaryEmail
		? {
				name: dealSummaryData.primaryContact?.fullName,
				value: dealSummaryData.primaryContact?.id,
				email: dealSummaryData.primaryContact.primaryEmail,
			}
		: null;

	const openContactMenu = (event) => {
		setContactMenuElement(event.currentTarget);
	};

	const closeContactMenu = () => {
		setContactMenuElement(null);
	};

	const openCompanyMenu = (event) => {
		setCompanyMenuElement(event.currentTarget);
	};

	const closeCompanyMenu = () => {
		setCompanyMenuElement(null);
	};

	const openEmailComposer = () => {
		setIsEmailComposerOpened(true);
	};

	const closeEmailComposer = () => {
		setIsEmailComposerOpened(false);
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="320px"
				anchorEl={contactMenuElement}
				open={isContactMenuOpened}
				onClose={closeContactMenu}
				style={{
					marginTop: "6px",
				}}
			>
				<ContactCard contactDetails={dealSummaryData?.primaryContact} />
			</Menu>

			<Menu
				minWidth="320px"
				anchorEl={companyMenuElement}
				open={isCompanyMenuOpened}
				onClose={closeCompanyMenu}
				style={{
					marginTop: "6px",
				}}
			>
				<CompanyCard companyDetails={dealSummaryData?.company} />
			</Menu>

			{isEmailComposerOpened ? (
				<EmailComposerDrawer
					onClose={closeEmailComposer}
					composerValues={{
						toAddress: contactDataWithEmail
							? [contactDataWithEmail]
							: [],
					}}
					sourceName={modules.DEAL}
					entityId={dealId}
				/>
			) : null}

			{isLoadingDealSummaryData ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="100vh"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : (
				<Box
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box p={2}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={2}
						>
							<Typography fontWeight={600} fontSize={17}>
								{`${viewName} Summary`}
							</Typography>

							<IconButton
								onClick={props.onClose}
								size="small"
								style={{ padding: 0 }}
							>
								{CloseIcon(24, 24, theme.palette.primary.main)}
							</IconButton>
						</Stack>
					</Box>

					<Divider />

					<Box
						style={{
							flex: "1 1 auto",
							minHeight: 0,
							overflowY: "auto",
						}}
					>
						<Box p={3}>
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="space-between"
							>
								<Stack
									direction="row"
									alignItems="center"
									spacing={3}
								>
									<Box
										sx={{
											backgroundColor: hexToRgba(
												theme.palette.primary.main,
												0.12
											),
											borderRadius: "8px",
											py: 1,
											minWidth: "90px",
											textAlign: "center",
										}}
									>
										<Typography
											fontSize={17}
											fontWeight={500}
											color={
												theme.palette.secondary
													.contrastText
											}
										>
											{dealSummaryData?.dealValue?.symbol}{" "}
											{dealSummaryData?.dealValue?.value}
										</Typography>
									</Box>

									<Stack
										style={{
											cursor: "pointer",
										}}
									>
										<Box
											onClick={() =>
												navigate(dealId.toString(), {
													state: {
														prevPath: `${pathname}${search}`,
													},
												})
											}
										>
											<TooltipComponent
												title={dealSummaryData?.title}
											>
												<Typography
													noWrap
													style={{
														fontSize: 15,
														fontWeight: 600,
														maxWidth: "270px",
													}}
												>
													{dealSummaryData?.title}
												</Typography>
											</TooltipComponent>
										</Box>

										<Stack
											direction="row"
											alignItems="center"
											justifyContent="flex-start"
											spacing={2}
										>
											<Can
												permission={
													PERMISSIONS.company.view
												}
											>
												{dealSummaryData?.company ? (
													<Stack
														direction="row"
														alignItems="center"
														spacing={0.5}
														onClick={
															openCompanyMenu
														}
														color={
															theme.palette
																.primary.main
														}
													>
														{CompanyIcon(
															16,
															16,
															theme.palette
																.primary.main
														)}

														<TooltipComponent
															title={
																dealSummaryData
																	?.company
																	?.name
															}
														>
															<Typography
																style={{
																	fontSize: 14,
																	fontWeight: 500,
																	maxWidth:
																		"105px",
																}}
																noWrap
															>
																{
																	dealSummaryData
																		?.company
																		?.name
																}
															</Typography>
														</TooltipComponent>
													</Stack>
												) : null}
											</Can>

											<Can
												permission={
													PERMISSIONS.contact.view
												}
											>
												<Stack
													direction="row"
													alignItems="center"
													spacing={0.5}
													onClick={openContactMenu}
													color={
														theme.palette.primary
															.main
													}
												>
													{ContactIcon(
														16,
														16,
														theme.palette.primary
															.main
													)}

													<TooltipComponent
														title={
															dealSummaryData
																?.primaryContact
																?.fullName
														}
													>
														<Typography
															style={{
																fontSize: 14,
																fontWeight: 500,
																maxWidth:
																	"105px",
															}}
															noWrap
														>
															{
																dealSummaryData
																	?.primaryContact
																	?.fullName
															}
														</Typography>
													</TooltipComponent>
												</Stack>
											</Can>
										</Stack>
									</Stack>
								</Stack>

								<Stack
									direction="row"
									alignItems="center"
									spacing={1.5}
								>
									<Can
										permission={
											PERMISSIONS.email
												.individualEmailLimit
										}
									>
										<Can
											permission={
												PERMISSIONS.contact.view
											}
										>
											<Button
												variant="contained"
												startIcon={MailIcon(
													20,
													20,
													theme.palette.secondary
														.contrastText
												)}
												color="secondary"
												disableElevation
												onClick={openEmailComposer}
											>
												Mail
											</Button>
										</Can>
									</Can>

									<Button
										variant="contained"
										startIcon={CallIcon(
											20,
											20,
											theme.palette.secondary.contrastText
										)}
										color="secondary"
										disableElevation
									>
										Call
									</Button>
								</Stack>
							</Stack>
						</Box>

						<Box px={3} pb={3}>
							<table
								style={{
									borderCollapse: "separate",
									borderSpacing: "0px 8px",
								}}
							>
								<tbody>
									<tr>
										<td>
											<Typography fontSize={15} pr={3}>
												Expected Close Date :
											</Typography>
										</td>

										<td>
											<Typography
												fontSize={15}
												fontWeight={500}
											>
												{dealSummaryData?.expectedCloseDate
													? getDateMonthAndYearFormat(
															dealSummaryData?.expectedCloseDate
														)
													: "-"}
											</Typography>
										</td>
									</tr>

									<tr>
										<td>
											<Typography fontSize={15}>
												Pipeline :
											</Typography>
										</td>

										<td>
											<Typography
												fontSize={15}
												fontWeight={500}
											>
												{
													dealSummaryData?.pipeline
														?.name
												}
											</Typography>
										</td>
									</tr>

									<tr>
										<td>
											<Typography fontSize={15}>
												Pipeline Stage :
											</Typography>
										</td>

										<td>
											<Typography
												fontSize={15}
												fontWeight={500}
											>
												{
													dealSummaryData
														?.pipelineStage?.name
												}
											</Typography>
										</td>
									</tr>

									<tr>
										<td>
											<Typography fontSize={15}>
												Sales Owner :
											</Typography>
										</td>

										<td>
											<Typography
												fontSize={15}
												fontWeight={500}
											>
												{
													dealSummaryData?.salesOwner
														?.name
												}
											</Typography>
										</td>
									</tr>
								</tbody>
							</table>
						</Box>

						<Box px={2} pb={4}>
							<Stack spacing={1.5}>
								<Can permission={PERMISSIONS.contact.view}>
									<DealRelatedContacts dealId={dealId} />
								</Can>
								<Notes dealId={dealId} />
								<Files dealId={dealId} />
								<Activities dealId={dealId} />
							</Stack>
						</Box>
					</Box>
				</Box>
			)}
		</React.Fragment>
	);
}
