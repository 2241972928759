import { getRootKey } from "../../rootKey";

const teamsKey = "teams";

// teams query key
export const getTeamsKey = () => {
	return [...getRootKey(), teamsKey];
};

// query keys
export const getTeamsListKey = (searchValue) => {
	return [...getTeamsKey(), "teamList", searchValue];
};

export const getTeamTableDataKey = () => {
	return [...getTeamsKey(), "teamTableData"];
};

export const getTeamTableDataWithSortKey = (fieldToSort = {}) => {
	return [...getTeamTableDataKey(), fieldToSort];
};

// query key methods
export const getAllTeamUsersListKey = (teamId) => {
	return [...getTeamsKey(), String(teamId), "teamUserList"];
};

export const getTeamUsersListKey = (teamId, isManager) => {
	return [...getAllTeamUsersListKey(teamId), String(isManager)];
};

export const getTeamDataKey = (teamId) => {
	return [...getTeamsKey(), String(teamId), "teamData"];
};
