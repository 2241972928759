import React, { useState } from "react";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { default as RightArrowIcon } from "../../../../../assets/icons/dropDownRight";
import { default as EditIcon } from "../../../../../assets/icons/edit";
import { default as DeleteIcon } from "../../../../../assets/icons/close";
import { default as TickIcon } from "../../../../../assets/icons/tick";
import { default as InfoIcon } from "../../../../../assets/icons/info";
import MenuSearchBar from "../../../../Elements/MenuSearchBar";
import { Tooltip, hexToRgba } from "../../../../../styles/twozo";
import { emailPattern } from "../../../../../utils/validation";

export default function BulkMailMenu(props) {
	const {
		toAddress,
		onCloseBulkMailMenu,
		onRemoveToAddress,
		handleSelectPersonalizeMail,
		handleCancelPersonalizeMail,
		handleSavePersonalizedMail,
		isMailAlreadyPersonalized,
		isHighlightEditMail,
	} = props;
	const theme = useTheme();
	const [hoveredRecipientIndex, setHoveredRecipientIndex] = useState(null);
	const [searchValue, setSearchValue] = useState("");
	const [personalizedMailIndex, setPersonalizedMailIndex] = useState("");

	const handleCloseBulkMailMenu = () => {
		onCloseBulkMailMenu();
	};

	const handleSearch = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const selectPersonalizeMail = (index, recipient) => {
		setPersonalizedMailIndex(index);
		handleSelectPersonalizeMail(recipient);
	};

	const onSavePersonalizeMail = (recipient) => {
		setPersonalizedMailIndex("");
		handleSavePersonalizedMail(recipient);
	};

	const cancelPersonalizeMail = () => {
		setPersonalizedMailIndex("");
		handleCancelPersonalizeMail();
	};

	const isValidEmailAddress = (email) => {
		return emailPattern.test(email.value);
	};

	const getContactName = (recipient) => {
		if (recipient?.name) {
			return recipient.name;
		} else {
			let contactName = recipient?.email?.value?.split("@")?.[0];
			return contactName;
		}
	};

	const getToAddress = () => {
		if (searchValue) {
			return toAddress?.filter(
				(toAddress) =>
					//filter for search
					toAddress.email.value
						.toLowerCase()
						.startsWith(searchValue.toLowerCase()) ||
					toAddress.name
						.toLowerCase()
						.startsWith(searchValue.toLowerCase())
			);
		}
		return toAddress;
	};

	const isPersonalizedMail = (index) => {
		return personalizedMailIndex === index;
	};

	const isRecipientHovered = (index) => {
		return hoveredRecipientIndex === index;
	};

	const isEditingPersonalizeMail = () => {
		return !!personalizedMailIndex || personalizedMailIndex === 0;
	};

	const getRecipientBackgroundColor = (index) => {
		if (isPersonalizedMail(index) && isHighlightEditMail) {
			return hexToRgba("#EB5454", 0.12);
		} else if (isPersonalizedMail(index)) {
			return theme.palette.secondary.main;
		} else if (isRecipientHovered(index)) {
			return "#F4F5F5";
		}
		return "#fff";
	};

	return (
		<React.Fragment>
			<Stack height="100%">
				<Box height="18%">
					<Stack
						direction="row"
						sx={{ padding: "12px 12px 0px 12px" }}
						width="100%"
						alignItems="center"
					>
						<Box width="90%">
							<Typography
								fontSize={14}
								fontWeight={500}
								textAlign="center"
							>
								{`${toAddress?.length} Recipients`}
							</Typography>
						</Box>

						<Box width="10%">
							<IconButton onClick={handleCloseBulkMailMenu}>
								{RightArrowIcon(
									16,
									16,
									theme.palette.primary.main
								)}
							</IconButton>
						</Box>
					</Stack>

					<Box p={1}>
						<MenuSearchBar
							value={searchValue}
							onChange={handleSearch}
						/>
					</Box>
				</Box>

				<Stack height="82%" sx={{ overflowY: "auto" }}>
					{getToAddress()?.length > 0 ? (
						getToAddress()?.map((recipient, index) => (
							<Box
								key={index}
								onMouseOver={() =>
									setHoveredRecipientIndex(index)
								}
								onMouseOut={() =>
									setHoveredRecipientIndex(null)
								}
								style={{
									backgroundColor:
										getRecipientBackgroundColor(index),
									padding: "12px 8px 12px 16px",
								}}
							>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="center"
								>
									{isValidEmailAddress(recipient.email) ? (
										<Box width="160px">
											<Typography
												fontSize={14}
												color="#000"
											>
												{getContactName(recipient)}
											</Typography>

											<Typography
												pt={0.5}
												fontSize={14}
												color="rgba(0, 0, 0, 0.6)"
												noWrap
											>
												{recipient?.email?.value}
											</Typography>
										</Box>
									) : (
										<Box>
											<Typography
												fontSize={14}
												color="#000"
											>
												{getContactName(recipient)}
											</Typography>

											<Stack
												pt={0.5}
												direction="row"
												alignItems="center"
												spacing={0.6}
											>
												<Typography
													fontSize={14}
													color={
														theme.palette.error.main
													}
												>
													Invalid Email Address
												</Typography>

												<Box display="flex">
													{InfoIcon(
														13,
														13,
														theme.palette.error.main
													)}
												</Box>
											</Stack>
										</Box>
									)}

									{isRecipientHovered(index) &&
									!isEditingPersonalizeMail() ? (
										<Stack direction="row">
											{isValidEmailAddress(
												recipient.email
											) ? (
												<IconButton
													onClick={() =>
														selectPersonalizeMail(
															index,
															recipient
														)
													}
												>
													<Tooltip
														title="Personalize Mail"
														PopperProps={{
															modifiers: [
																{
																	name: "offset",
																	options: {
																		offset: [
																			-25,
																			-1,
																		],
																	},
																},
															],
														}}
													>
														{EditIcon(
															18,
															18,
															theme.palette
																.primary.main
														)}
													</Tooltip>
												</IconButton>
											) : null}

											<IconButton
												onClick={() =>
													onRemoveToAddress(index)
												}
											>
												{DeleteIcon(
													18,
													18,
													theme.palette.primary.main
												)}
											</IconButton>
										</Stack>
									) : null}

									{isPersonalizedMail(index) ? (
										<Stack direction="row">
											<IconButton
												onClick={cancelPersonalizeMail}
											>
												{DeleteIcon(
													18,
													18,
													"#000",
													0.6
												)}
											</IconButton>

											<IconButton
												onClick={() =>
													onSavePersonalizeMail(
														recipient
													)
												}
											>
												{TickIcon(
													18,
													18,
													theme.palette.primary.main
												)}
											</IconButton>
										</Stack>
									) : null}

									{isMailAlreadyPersonalized(recipient) &&
									!isRecipientHovered(index) &&
									!isPersonalizedMail(index) ? (
										<IconButton>
											{EditIcon(
												18,
												18,
												"rgba(0, 0, 0, 0.3)"
											)}
										</IconButton>
									) : null}
								</Stack>
							</Box>
						))
					) : (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="80%"
						>
							<Typography
								fontSize={14}
								color="rgba(0, 0, 0, 0.6)"
							>
								No recipient found
							</Typography>
						</Stack>
					)}
				</Stack>
			</Stack>
		</React.Fragment>
	);
}
