import React from "react";
import EmailRecipientInput from "../../../../Mail/EmailComposer/EmailComposerUI/EmailRecipientInput";

const guestInputSx = {
	"& .MuiOutlinedInput-root": {
		height: "auto",
		minHeight: "42px ",
		padding: "8px 14px",
		"& input": {
			padding: "0px",
		},
		"& fieldset": {
			border: "1px solid rgba(0, 0, 0, 0.1)",
		},
		"&:hover fieldset": {
			border: "1px solid rgba(0, 0, 0, 0.3)",
		},
		"&.Mui-focused fieldset": {
			border: "1px solid #33BC7E",
		},
	},
};

export default function GuestComponent(props) {
	const { onGuestChange, value } = props;

	return (
		<React.Fragment>
			<EmailRecipientInput
				value={value}
				handleRecipientsChange={onGuestChange}
				recipientInputSx={guestInputSx}
				placeholder="Select"
			/>
		</React.Fragment>
	);
}
