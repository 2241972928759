import { getRootKey } from "../../rootKey";

const territoryKey = "territory";

// territory query key
export const getTerritoryKey = () => {
	return [...getRootKey(), territoryKey];
};

// query keys
export const getTerritoryTableDataKey = () => {
	return [...getTerritoryKey(), "territoryList"];
};

export const getTerritoryTableDataWithSortKey = (fieldToSort = {}) => {
	return [...getTerritoryTableDataKey(), fieldToSort];
};

export const getTerritoryDropdownListKey = (searchValue) => {
	return [...getTerritoryKey(), "territoryDropdownList", searchValue];
};

// query key methods
export const getTerritoryUserListKey = (territoryId) => {
	return [...getTerritoryKey(), String(territoryId), "territoryUsersList"];
};

export const getTerritoryDataKey = (territoryId) => {
	return [...getTerritoryKey(), String(territoryId), "territoryData"];
};

export const getTeamUsersAndUsersListKey = (searchValue) => {
	return [...getTerritoryKey(), "teamUsersAndUsersList", searchValue];
};
