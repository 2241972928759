import React from "react";
import { Typography } from "@mui/material";

export default function ContactLifecycleStatusUpdated(props) {
	const { eventData } = props;

	if (!eventData) return;

	return (
		<React.Fragment>
			<Typography fontSize={15} pt={1.25}>
				Life cycle status for this contact was {eventData?.action} from{" "}
				<span style={{ fontSize: "15px", fontWeight: 500 }}>
					{eventData?.oldValue}
				</span>{" "}
				to{" "}
				<span style={{ fontSize: "15px", fontWeight: 500 }}>
					{eventData?.newValue}
				</span>
			</Typography>
		</React.Fragment>
	);
}
