import React, { useState } from "react";
import {
	Box,
	Checkbox,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { default as TickCircle } from "../../../../../../assets/icons/tickCircle";
import { default as RoundCheckBoxOffIcon } from "../../../../../../assets/icons/roundCheckBoxOff";
import { default as AddIcon } from "../../../../../../assets/icons/add";
import { default as DragIcon } from "../../../../../../assets/icons/drag";
import { default as CloseIcon } from "../../../../../../assets/icons/close";
import { MenuItemText, Tooltip } from "../../../../../../styles/twozo";
import { default as DropDownIcon } from "../../../../../../assets/icons/dropDown";
import Menu from "../../../../../Elements/Menu";
import { useSelectReducer } from "../../FieldContext/SelectReducer";
import { fieldTypes } from "../../../../../../utils/dataFieldsUtils";
import DragDropContextWithNonce from "../../../../../Elements/DragDropContextWithNonce";

export default function DropdownFieldChoices(props) {
	const {
		field,
		onClosedDropdownChoiceMenu,
		dropdownChoices = [],
		updateDropdownChoices,
		isFieldEditingRestricted,
	} = props;
	const theme = useTheme();

	// select reducer
	const {
		selectState,
		onCreateSelectChoices,
		onResetSelectChoices,
		onChoiceCreationError,
		onDuplicateChoiceError,
		errorOnCharactersLimitExceeded,
	} = useSelectReducer();

	const [dropdownChoicesElement, setDropdownChoicesElement] = useState(null);
	const openDropdownChoicesMenu = Boolean(dropdownChoicesElement);

	const [editableChoiceIndex, setEditableChoiceIndex] = useState(null);
	const [isNewOptionCreated, setIsNewOptionCreated] = useState(false);

	// dropdown field types
	const isStaticDropdown = field.config.dropdownType === fieldTypes.static;
	const isHiddenOptionDropdown =
		field.config.dropdownType === fieldTypes.hiddenOption;
	const isHybridDropdown = field.config.dropdownType === fieldTypes.hybrid;

	const OpenDropdownChoicesMenu = (event) => {
		if (isHiddenOptionDropdown || isFieldEditingRestricted) {
			return;
		}
		setDropdownChoicesElement(event.currentTarget);
	};
	const hasError =
		selectState.hasCreationError ||
		selectState.hasDuplicateError ||
		selectState.isLimitExceeded;

	const validateOnChoiceMenuClose = (_, reason) => {
		if (selectState.hasDuplicateError || selectState.isLimitExceeded) {
			return;
		}

		if (reason === "backdropClick") {
			if (isDuplicateOption()) {
				onDuplicateChoiceError();
				return;
			}
			closeDropdownChoicesMenu();
		}
	};

	const getFilteredEmptyOptions = () => {
		let filteredEmptyChoices = dropdownChoices.filter((choice) => {
			return choice.name !== "";
		});

		return filteredEmptyChoices;
	};

	const isEmptyOption = () => {
		return getFilteredEmptyOptions()?.length < 0;
	};

	const closeDropdownChoicesMenu = () => {
		onClosedDropdownChoiceMenu();
		setIsNewOptionCreated(false);
		setDropdownChoicesElement(null);
		setEditableChoiceIndex(null);
		updateDropdownChoices(getFilteredEmptyOptions());
		onResetSelectChoices();
	};

	const updateDefaultOption = (choice, index) => {
		let modifiedDropdownChoices = getUpdatedDropdownChoices(index);
		updateDropdownChoices(modifiedDropdownChoices);
	};

	const getUpdatedDropdownChoices = (index) => {
		let clonedDropdownChoices = [...dropdownChoices];
		let modifiedDropdownChoices = clonedDropdownChoices.map(
			(dropdown, choiceIndex) => {
				if (dropdown.isDefault) {
					delete dropdown.isDefault;
				} else if (index === choiceIndex) {
					return { ...dropdown, isDefault: true };
				}

				return dropdown;
			}
		);

		return modifiedDropdownChoices;
	};

	const handleOptionEdit = (index, value) => {
		const updatedOptionName = dropdownChoices.map((option, optionIndex) => {
			if (optionIndex === index) return { ...option, name: value };
			return option;
		});

		let maxChoiceLength = 126;
		if (value.trim().length > maxChoiceLength) {
			errorOnCharactersLimitExceeded();
		} else {
			updateDropdownStateOnOptionEdit(value);
			setEditableChoiceIndex(index);
			updateDropdownChoices(updatedOptionName);
		}
	};

	const updateDropdownStateOnOptionEdit = (value) => {
		if (value !== "") {
			onResetSelectChoices();
		} else {
			onCreateSelectChoices();
		}
	};

	const isDuplicateOption = () => {
		const uniqueChoices = new Set();
		for (const choice of dropdownChoices) {
			const choiceName = choice.name.trim().toLowerCase();
			if (choiceName !== "" && uniqueChoices.has(choiceName)) {
				return true;
			}
			uniqueChoices.add(choiceName);
		}

		return false;
	};

	const validateAndDispatchDropdownState = () => {
		if (isEmptyOption()) {
			onChoiceCreationError();
			return false;
		} else if (isDuplicateOption()) {
			onDuplicateChoiceError();
			return false;
		}

		return true;
	};

	const isDropdownOptionsValid = () => {
		if (!isChoiceLimitExceeded()) {
			return;
		}

		if (hasError) {
			return;
		}

		if (selectState.isCreateMode) {
			onChoiceCreationError();
		} else {
			return validateAndDispatchDropdownState();
		}
	};

	const handleDropdownChoiceValidation = () => {
		if (isDropdownOptionsValid()) {
			addDropdownChoice();
		}
	};

	const addDropdownChoice = () => {
		let modifiedDropdownChoices = [];
		let firstEmptyChoice = {
			name: "",
			isFirstOption: true,
			isNew: true,
		};

		let secondEmptyChoice = {
			name: "",
			isNew: true,
		};

		if (dropdownChoices.length < 1) {
			modifiedDropdownChoices = [
				...dropdownChoices,
				firstEmptyChoice,
				secondEmptyChoice,
			];
		} else {
			modifiedDropdownChoices = [...dropdownChoices, firstEmptyChoice];
		}
		setIsNewOptionCreated(true);
		onCreateSelectChoices();
		updateDropdownChoices(modifiedDropdownChoices);
		setEditableChoiceIndex(dropdownChoices.length);
	};

	const isOptionEditableOrNew = (choice) => {
		if (isStaticDropdown) {
			return;
		}
		return choice.isEditableOption || choice.isNew;
	};

	const editDropdownChoiceName = (index, choice) => {
		if (hasError || isNewOptionCreated) {
			return;
		}

		if (isDuplicateOption()) {
			onDuplicateChoiceError();
		} else {
			if (isOptionEditableOrNew(choice)) {
				setEditableChoiceIndex(index);
			}
		}
	};

	const removeDropdownChoice = (choiceIndex) => {
		if (isOptionRemovable(choiceIndex)) {
			removeChoiceAndUpdateDropdown(choiceIndex);
			onResetSelectChoices();
			setEditableChoiceIndex(null);
			setIsNewOptionCreated(false);
		}
	};

	const removeChoiceAndUpdateDropdown = (choiceIndex) => {
		let updatedDropdownChoices = [...dropdownChoices];
		updatedDropdownChoices.splice(choiceIndex, 1);
		updateDropdownChoices([...updatedDropdownChoices]);
	};

	const reorderChoice = (choice, startIndex, endIndex) => {
		const result = Array.from(choice);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const onChoiceDragEnd = (result, choice) => {
		if (
			!result.destination ||
			!dropdownChoices[result.destination.index].isEditableOption
		) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const updatedOptionOrderData = reorderChoice(
			choice,
			result.source.index,
			result.destination.index
		);

		updateDropdownChoices(updatedOptionOrderData);
	};

	const isChoiceLimitExceeded = () => {
		let isSystemField = field.config.isDefaultField;
		let maxSystemFieldChoicesCount = 100;
		let maxCustomFieldChoicesCount = 10;

		if (isSystemField) {
			return dropdownChoices.length < maxSystemFieldChoicesCount;
		}
		return dropdownChoices.length < maxCustomFieldChoicesCount;
	};

	const isOptionRemovable = (choiceIndex) => {
		let defaultChoices = 2;

		if (dropdownChoices.length > defaultChoices) {
			if (!hasError && !isNewOptionCreated) {
				return true;
			}

			return editableChoiceIndex === choiceIndex;
		}
	};

	const hasDropdownChoiceError = (choice) => {
		let defaultChoicesCount = 2;
		let maxChoiceLength = 126;

		if (dropdownChoices.length === defaultChoicesCount && hasError) {
			if (selectState.isLimitExceeded) {
				return choice.name.length >= maxChoiceLength;
			}
			return choice.name === "" || isDuplicateOption();
		}

		return hasError;
	};

	const getNotAllowedCursor = () => {
		if (hasError) {
			return "not-allowed";
		}
	};

	const isOptionInEditOrNew = (choice, index) => {
		return editableChoiceIndex === index || choice.name === "";
	};

	const isDragEnabled = () => {
		return hasError || isNewOptionCreated;
	};

	const isEditableOrNewHybridOption = (choice) => {
		if (choice.isEditableOption || choice.isNew) {
			return;
		}

		return isHybridDropdown;
	};

	return (
		<React.Fragment>
			<Box
				pl={1}
				pr={0.5}
				py={0.2}
				sx={{
					backgroundColor: (theme) => theme.palette.secondary.main,
					borderRadius: "3px",
				}}
				onClick={OpenDropdownChoicesMenu}
			>
				<Stack direction="row" spacing={0.5}>
					<Typography fontSize={14}>
						{dropdownChoices.length}{" "}
						{dropdownChoices.length > 1 ? "Choices" : "Choice"}{" "}
						Added
					</Typography>

					{!isHiddenOptionDropdown &&
						DropDownIcon(
							18,
							18,
							theme.palette.secondary.contrastText
						)}
				</Stack>
			</Box>

			<Menu
				style={{
					maxWidth: "450px",
				}}
				anchorEl={dropdownChoicesElement}
				open={openDropdownChoicesMenu}
				onClose={validateOnChoiceMenuClose}
			>
				<DragDropContextWithNonce
					onDragEnd={(result) =>
						onChoiceDragEnd(result, dropdownChoices)
					}
				>
					<Droppable droppableId="dropdown-choices">
						{(provided) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
							>
								<Box maxHeight="200px" overflow="auto">
									{dropdownChoices.map((choice, index) => (
										<Draggable
											draggableId={index.toString()}
											index={index}
											key={index}
											isDragDisabled={isDragEnabled()}
										>
											{(provided) => (
												<Box
													ref={provided.innerRef}
													{...provided.draggableProps}
												>
													<Box
														px={
															!isStaticDropdown
																? 1
																: 0
														}
														display="flex"
														key={index}
														style={{
															minHeight: "40px",
														}}
														sx={{
															"&:hover": {
																backgroundColor:
																	"#F4F5F5",
															},
														}}
													>
														<Stack
															width="100%"
															direction="row"
															justifyContent="space-between"
															alignItems="center"
															spacing={2}
															pl={
																!isOptionEditableOrNew(
																	choice
																) && 2.5
															}
														>
															<Stack
																direction="row"
																alignItems="center"
																spacing={1}
																width="100%"
															>
																<Stack
																	direction="row"
																	alignItems="center"
																>
																	<Box
																		display="flex"
																		sx={{
																			cursor: getNotAllowedCursor(),
																		}}
																		{...provided.dragHandleProps}
																	>
																		{isOptionEditableOrNew(
																			choice
																		)
																			? DragIcon(
																					20,
																					20,
																					"#000000"
																				)
																			: null}
																	</Box>

																	<Box display="flex">
																		<Tooltip
																			title={
																				choice.isDefault
																					? "Default Choice"
																					: "Mark as Default Choice"
																			}
																			placement="left"
																			PopperProps={{
																				modifiers:
																					[
																						{
																							name: "offset",
																							options:
																								{
																									offset: [
																										0,
																										-8,
																									],
																								},
																						},
																					],
																			}}
																		>
																			<Checkbox
																				checked={
																					!!choice.isDefault
																				}
																				icon={RoundCheckBoxOffIcon(
																					17,
																					17
																				)}
																				checkedIcon={TickCircle(
																					17,
																					17,
																					theme
																						.palette
																						.primary
																						.main
																				)}
																				onChange={() =>
																					updateDefaultOption(
																						choice,
																						index
																					)
																				}
																				disabled={
																					isNewOptionCreated
																				}
																				value={
																					choice.value
																				}
																				sx={{
																					p: 0,
																					cursor: getNotAllowedCursor(),
																				}}
																			/>
																		</Tooltip>
																	</Box>
																</Stack>

																<Box maxWidth="310px">
																	{isOptionInEditOrNew(
																		choice,
																		index
																	) ? (
																		<Box>
																			<TextField
																				variant="standard"
																				value={
																					choice.name
																				}
																				onClick={() =>
																					setEditableChoiceIndex(
																						index
																					)
																				}
																				onChange={(
																					event
																				) =>
																					handleOptionEdit(
																						index,
																						event
																							.target
																							.value
																					)
																				}
																				InputProps={{
																					style: {
																						fontSize: 13,
																					},
																				}}
																				fullWidth
																				autoFocus={
																					choice?.isFirstOption
																				}
																				FormHelperTextProps={{
																					sx: {
																						fontSize: 13,
																						fontWeight: 500,
																					},
																				}}
																				error={hasDropdownChoiceError(
																					choice
																				)}
																				helperText={
																					hasDropdownChoiceError(
																						choice
																					) &&
																					selectState.errorMessage
																				}
																			/>
																		</Box>
																	) : (
																		<MenuItemText
																			fontSize={
																				13
																			}
																			style={{
																				opacity:
																					isEditableOrNewHybridOption(
																						choice
																					) ||
																					isStaticDropdown
																						? 0.5
																						: 1,
																			}}
																			sx={{
																				cursor: getNotAllowedCursor(),
																				width: "300px",
																				wordBreak:
																					"break-word",
																			}}
																			onClick={() =>
																				editDropdownChoiceName(
																					index,
																					choice
																				)
																			}
																			pr={
																				isStaticDropdown
																					? 2
																					: 0
																			}
																		>
																			{
																				choice.name
																			}
																		</MenuItemText>
																	)}
																</Box>
															</Stack>

															{isOptionEditableOrNew(
																choice
															) ? (
																<Box
																	display="flex"
																	onClick={() =>
																		removeDropdownChoice(
																			index,
																			choice
																		)
																	}
																	sx={{
																		cursor: isOptionRemovable(
																			index
																		)
																			? "pointer"
																			: "not-allowed",
																		ml: 2,
																		opacity:
																			dropdownChoices.length ===
																			2
																				? 0.6
																				: 1,
																	}}
																>
																	{CloseIcon(
																		20,
																		20,
																		"#000"
																	)}
																</Box>
															) : null}
														</Stack>
													</Box>
												</Box>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</Box>
							</div>
						)}
					</Droppable>
				</DragDropContextWithNonce>

				{!isStaticDropdown ? (
					<Box
						onClick={handleDropdownChoiceValidation}
						style={{
							minHeight: "40px",
							cursor:
								hasError || !isChoiceLimitExceeded()
									? "not-allowed"
									: "pointer",
						}}
						pt={1.5}
						pl={dropdownChoices.length > 0 ? 3.5 : 3}
					>
						<Stack
							direction="row"
							spacing={2}
							alignItems="center"
							sx={{
								opacity:
									hasError || !isChoiceLimitExceeded()
										? 0.6
										: 1,
							}}
						>
							{AddIcon(
								20,
								20,
								theme.palette.secondary.contrastText
							)}

							<Typography
								fontSize={14}
								color={theme.palette.secondary.contrastText}
							>
								Add Choice
							</Typography>
						</Stack>
					</Box>
				) : null}
			</Menu>
		</React.Fragment>
	);
}
