import { getRootKey } from "../rootKey";

export const getAnalyticsKey = () => {
	return [...getRootKey(), "analytics"];
};

export const getAnalyticsDashboardKey = () => {
	return [...getAnalyticsKey(), "dashboard"];
};

export const getAnalyticsDashboardDataKey = (
	dashboardId,
	fetchViewProperties = false
) => {
	return [
		...getAnalyticsDashboardKey(),
		"fetchViewProperties",
		String(dashboardId),
		String(fetchViewProperties),
	];
};

export const getAnalyticsDashboardShareKey = (dashboardId) => {
	return [
		...getAnalyticsDashboardKey(),
		"dashboard",
		"share",
		String(dashboardId),
	];
};

export const getAnalyticsMetricsKey = (viewTypeId) => {
	return [...getAnalyticsDashboardKey(), "metrics", String(viewTypeId)];
};

export const getAnalyticsXAxisMetricsKey = (moduleIds) => {
	return [...getAnalyticsDashboardKey(), "x-axis", String(moduleIds)];
};

export const getAnalyticsDurationKey = (moduleId) => {
	return [...getAnalyticsDashboardKey(), "duration", String(moduleId)];
};

export const getChartApplyDataKey = (chartProperty) => {
	return [
		...getAnalyticsDashboardKey(),
		"chart",
		"apply-data",
		JSON.stringify(chartProperty),
	];
};

export const getChartViewDataKey = (dashboardId, viewId) => {
	return [
		...getAnalyticsDashboardKey(),
		"chart",
		"view-data",
		String(dashboardId),
		String(viewId),
	];
};

export const getMetricsFilterFieldKey = (moduleId) => {
	return [...getAnalyticsDashboardKey(), "filter-field", String(moduleId)];
};

export const getDashboardListKey = (dashboard, fetchHits, criteria, sort) => {
	return [
		...getAnalyticsDashboardKey(),
		"list",
		dashboard,
		String(fetchHits),
		JSON.stringify(criteria),
		JSON.stringify(sort),
	];
};

export const getAllDashboardListKey = () => {
	return [...getAnalyticsDashboardKey(), "list"];
};

export const getAnalyticsUnderlyingListKey = (
	viewId,
	moduleId,
	viewTypeId,
	childViewId,
	select
) => {
	return [
		...getAnalyticsKey(),
		"underlyingList",
		String(viewId),
		String(moduleId),
		String(viewTypeId),
		String(childViewId),
		JSON.stringify(select),
	];
};
