import React from "react";
import { Stack, Typography } from "@mui/material";

export default function ActivityAdded(props) {
	const { eventData } = props;

	if (!eventData) return;

	return (
		<React.Fragment>
			<Stack pt={!eventData?.activityNote ? 1.25 : 0}>
				<Typography fontSize={15}>
					{" "}
					<span style={{ fontSize: "15px", fontWeight: 500 }}>
						{eventData?.newValue} -
					</span>{" "}
					<span
						style={{
							fontSize: "15px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						{eventData?.salesOwner?.name}
					</span>
				</Typography>

				{eventData?.activityNote ? (
					<Typography
						sx={{
							wordBreak: "break-word",
						}}
						style={{
							fontSize: "14px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						{eventData.activityNote}
					</Typography>
				) : null}
			</Stack>
		</React.Fragment>
	);
}
