import React, { useMemo, useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	Divider,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { default as DropDownIcon } from "../../../../../assets/icons/dropDownCentered";
import {
	TableCellText,
	TableHeaderLabel,
	hexToRgba,
} from "../../../../../styles/twozo";
import UnKnownImage from "../../../../../assets/images/contact/unknownContact.png";
import { default as DeleteIcon } from "../../../../../assets/icons/delete";

import { Controller, useFieldArray, useForm } from "react-hook-form";

import UsersOrTeamsAutoComplete from "../UsersAndTeamsAutoComplete";

const maximumCharacters = 255;
const maxLengthErrorMessage = `Max. of ${maximumCharacters} characters are allowed`;

const validationConfig = {
	maxLength: {
		value: maximumCharacters,
		message: maxLengthErrorMessage,
	},
};

const formFields = {
	territoryName: "territoryName",
	description: "description",
	users: "users",
};

export default function AddTerritoryDialog(props) {
	const {
		title,
		onClose,
		onSave,
		onUpdate,
		isSaving,
		territoryData,
		isEditMode,
	} = props;

	const theme = useTheme();
	const [usersAndTeamsTotalCount, setUsersAndTeamsTotalCount] =
		useState(null);
	const [selectedUserIds, setSelectedUserIds] = useState([]);
	const [tableRowHovered, setTableRowHovered] = useState(null);
	const [selectedTerritoryId, setSelectedTerritoryId] = useState(null);
	const [isAllUserSelected, setIsAllUserSelected] = useState(false);

	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
		setValue,
		watch,
		clearErrors,
	} = useForm({
		defaultValues: {
			territoryName: territoryData?.name || "",
			description: territoryData?.description || "",
			users:
				territoryData?.users?.map((member) => ({
					...member,
					value: member.id,
				})) || [],
		},
		reValidateMode: false,
	});

	const {
		fields: territoryUsersList,
		append: addTerritoryUser,
		remove: deleteTerritoryMember,
	} = useFieldArray({
		control,
		name: formFields.users,
	});

	const territoryUsers = territoryUsersList.filter(
		(list) => !list?.isUserNotAdded
	);

	const isUserAndTeamsNotAdded = useMemo(() => {
		return territoryUsersList.some((list) => list?.isUserNotAdded);
	}, [territoryUsersList]);

	const isUserAdditionDisabled =
		isUserAndTeamsNotAdded ||
		territoryUsersList.length === usersAndTeamsTotalCount;

	const onMouseOverTableRow = (id) => setTableRowHovered(id);
	const onMouseOutTableRow = () => setTableRowHovered(null);

	const handleTableCell = (territory) => {
		setSelectedTerritoryId(territory.id);
	};

	const updateSelectedUsersId = (userList) => {
		let newSelectedUserIds = userList
			.filter((user) => !user.isUserNotAdded)
			.map((user) => user.id);
		setSelectedUserIds(newSelectedUserIds);
	};

	const updateUserAndTeamsTotalCount = (count) => {
		setUsersAndTeamsTotalCount(count);
	};

	const selectUser = (data, selectedIndex) => {
		if (data?.group === "Teams") {
			let selectedUsersId = territoryUsersList.map((user) => user.value);

			const filteredTeamsUserList = data.users.filter(
				(user) => !selectedUsersId.includes(user.id)
			);

			const updatedTeamsUserList = filteredTeamsUserList.map((user) => ({
				...user,
				value: user.id,
			}));

			const filteredEmptyUserList = territoryUsersList.filter(
				(user) => user.name !== ""
			);

			let filteredUserList = [
				...filteredEmptyUserList,
				...updatedTeamsUserList,
			];

			setValue(formFields.users, filteredUserList);

			if (isAllUserSelected) {
				updateSelectedUsersId(filteredUserList);
			}
		} else {
			let updatedTerritoryUserList = territoryUsersList.map(
				(member, index) => {
					if (index === selectedIndex) {
						return {
							...member,
							name: data.name,
							email: data.email,
							role: {
								...member.role,
								name: data.role.name,
								id: data.role.id,
							},
							id: data.id || data.value,
							isUserNotAdded: false,
							value: data.id,
						};
					}

					return member;
				}
			);

			setValue(formFields.users, updatedTerritoryUserList);

			if (isAllUserSelected) {
				updateSelectedUsersId(updatedTerritoryUserList);
			}
		}
		setSelectedTerritoryId(null);
	};

	const handleSelectAllUsers = (event) => {
		if (event.target.checked) {
			const newSelected = territoryUsersList
				.filter((territoryMember) => !territoryMember.isUserNotAdded)
				.map((territoryMember) => territoryMember.id);
			setSelectedUserIds(newSelected);
			setIsAllUserSelected(true);
		} else {
			setSelectedUserIds([]);
			setIsAllUserSelected(false);
		}
	};

	const isUserSelected = (id) => selectedUserIds.indexOf(id) !== -1;

	const handleSelectUser = (event, id) => {
		event.stopPropagation();
		const selectedIndex = selectedUserIds?.indexOf(id);
		let newSelectedIds = [];

		if (selectedIndex === -1) {
			newSelectedIds = newSelectedIds.concat(selectedUserIds, id);
		} else {
			newSelectedIds = [
				...selectedUserIds.slice(0, selectedIndex),
				...selectedUserIds.slice(selectedIndex + 1),
			];
		}
		setSelectedUserIds(newSelectedIds);
	};

	const removeMultipleUserFromTerritory = () => {
		let remainingUsers = territoryUsersList.filter(
			(list) => !selectedUserIds.includes(list.id)
		);
		setValue(formFields.users, remainingUsers);
		setSelectedUserIds([]);
	};

	const getCountOfTerritoryUsers = (territoryUsers) => {
		let singleDigitCount = 9;
		if (
			territoryUsers.length > singleDigitCount ||
			territoryUsers.length === 0
		) {
			return territoryUsers.length;
		}
		return `0${territoryUsers.length}`;
	};

	const getFilteredTerritoryUsers = () => {
		let filteredUsersList = territoryUsersList
			.filter((user) => user.name !== "")
			.map((user) => user.value);
		return filteredUsersList;
	};

	const getCreateRequestData = (newTerritoryData) => {
		let requestData = {};
		requestData.name = newTerritoryData.territoryName;
		requestData.description = newTerritoryData.description;
		requestData.users = getFilteredTerritoryUsers();
		return requestData;
	};

	const getUpdateRequestData = (updatedTerritoryData) => {
		let requestData = { ...territoryData };
		requestData.name = updatedTerritoryData.territoryName;
		requestData.description = updatedTerritoryData.description;
		requestData.users = getFilteredTerritoryUsers();
		return requestData;
	};

	const handleSave = (newTerritaryData) => {
		if (isValidTerritory() && newTerritaryData) {
			let requestData = getCreateRequestData(newTerritaryData);
			onSave(requestData);
		}
	};

	const handleUpdate = (updatedTerritoryData) => {
		if (isValidTerritory() && updatedTerritoryData) {
			let requestData = getUpdateRequestData(updatedTerritoryData);
			onUpdate(requestData);
		}
	};

	const isValidTerritory = () => {
		let filteredTeamMemberList = territoryUsersList.filter(
			(teamMember) => teamMember.name !== ""
		);
		return (
			watch(formFields.territoryName)?.trim() !== "" &&
			filteredTeamMemberList.length > 0
		);
	};

	const isDeleteButtonVisible = (territoryMember) => {
		return (
			!territoryMember.isUserNotAdded &&
			!selectedUserIds.includes(tableRowHovered) &&
			tableRowHovered === territoryMember.id
		);
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
					>
						<Typography fontSize={17} fontWeight={600}>
							{title}
						</Typography>

						<IconButton
							onClick={onClose}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(24, 24, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>
				<Divider />
				<Box
					px={2}
					py={2}
					style={{
						flex: "1 1 auto",
						minHeight: 0,
					}}
				>
					<Box
						height="100%"
						style={{
							display: "flex",
							flex: "1 1 auto",
							flexDirection: "column",
						}}
					>
						<Box pb={selectedUserIds.length >= 1 ? 2 : 3}>
							<Stack pb={1}>
								<Typography
									fontSize={14}
									fontWeight={500}
									padding="5px"
									color={
										errors?.territoryName?.message &&
										theme.palette.error.main
									}
								>
									Territory Name{" "}
									<span
										style={{
											color: theme.palette.error.main,
										}}
									>
										*
									</span>
								</Typography>

								<TextField
									{...register(formFields.territoryName, {
										...validationConfig,
										onChange: () =>
											clearErrors(
												formFields.territoryName
											),
									})}
									placeholder="Eg: USA"
									error={Boolean(errors?.territoryName)}
								/>
								{errors?.territoryName?.message && (
									<Typography
										color="error"
										style={{
											fontSize: "14px",
											fontWeight: 500,
											padding: "5px",
										}}
									>
										{errors.territoryName.message}
									</Typography>
								)}
							</Stack>

							<Stack>
								<Typography
									fontSize={14}
									fontWeight={500}
									padding="5px"
									color={
										errors?.description?.message &&
										theme.palette.error.main
									}
								>
									Description
								</Typography>

								<TextField
									{...register(formFields.description, {
										maxLength: {
											...validationConfig.maxLength,
										},
										onChange: () =>
											clearErrors(formFields.description),
									})}
									placeholder="Details like location and scope for business"
									multiline
									rows={3}
									error={Boolean(errors?.description)}
								/>

								{errors?.description?.message && (
									<Typography
										color="error"
										style={{
											fontSize: "14px",
											fontWeight: 500,
											padding: "5px",
										}}
									>
										{errors.description.message}
									</Typography>
								)}
							</Stack>
						</Box>

						{selectedUserIds.length > 0 ? (
							<Box pb={2}>
								<Stack
									alignItems="flex-end"
									justifyContent="center"
								>
									<Typography
										fontSize={14}
										fontWeight={600}
										color={theme.palette.primary.main}
										borderRadius="8px"
										bgcolor={theme.palette.secondary.main}
										style={{
											padding: "4px 8px",
											cursor: "pointer",
										}}
										onClick={
											removeMultipleUserFromTerritory
										}
									>
										Remove from Territory (
										{getCountOfTerritoryUsers(
											selectedUserIds
										)}
										)
									</Typography>
								</Stack>
							</Box>
						) : null}

						<Box
							sx={{
								border: "1px solid rgba(0, 0, 0, 0.1)",
								borderRadius: "8px",
								overflowY: "auto",
								flex: "1 1 auto",
							}}
						>
							<TableContainer>
								<Table size="small">
									<TableHead>
										<TableRow
											style={{
												width: "100%",
												height: "36px",
											}}
										>
											<TableCell
												padding="checkbox"
												style={{ width: "10%" }}
											>
												<Stack
													alignItems="center"
													justifyContent="center"
												>
													<Checkbox
														indeterminate={
															selectedUserIds.length >
																0 &&
															selectedUserIds.length <
																territoryUsers.length
														}
														checked={
															territoryUsers.length >
																0 &&
															selectedUserIds.length ===
																territoryUsers.length
														}
														onChange={
															handleSelectAllUsers
														}
													/>
												</Stack>
											</TableCell>

											<TableCell style={{ width: "30%" }}>
												<Stack
													direction="row"
													alignItems="center"
													spacing={0.5}
												>
													<TableHeaderLabel>
														User
													</TableHeaderLabel>
													{DropDownIcon(
														12,
														12,
														"rgba(0, 0, 0, 0.5)"
													)}
												</Stack>
											</TableCell>

											<TableCell style={{ width: "30%" }}>
												<Stack
													direction="row"
													alignItems="center"
													spacing={0.5}
												>
													<TableHeaderLabel>
														Email
													</TableHeaderLabel>
													{DropDownIcon(
														12,
														12,
														"rgba(0, 0, 0, 0.5)"
													)}
												</Stack>
											</TableCell>

											<TableCell style={{ width: "20%" }}>
												<Stack
													direction="row"
													alignItems="center"
													spacing={0.5}
												>
													<TableHeaderLabel>
														Role
													</TableHeaderLabel>
													{DropDownIcon(
														12,
														12,
														"rgba(0, 0, 0, 0.5)"
													)}
												</Stack>
											</TableCell>

											<TableCell
												style={{ width: "10%" }}
											></TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										<TableRow style={{ width: "100%" }}>
											<TableCell
												style={{ width: "10%" }}
											></TableCell>

											<TableCell colSpan={4}>
												<Typography
													fontWeight={500}
													fontSize={14}
													color={
														theme.palette.secondary
															.contrastText
													}
													onClick={() =>
														addTerritoryUser({
															name: "",
															email: "-",
															role: {
																name: "-",
															},
															value: null,
															isUserNotAdded: true,
														})
													}
													style={{
														pointerEvents:
															isUserAdditionDisabled
																? "none"
																: "auto",
														cursor: isUserAdditionDisabled
															? "not-allowed"
															: "pointer",
														opacity:
															isUserAdditionDisabled
																? 0.6
																: 1,
													}}
												>
													+ Add Users or Teams
												</Typography>
											</TableCell>
										</TableRow>

										{territoryUsersList.map(
											(territoryMember, index) => (
												<TableRow
													key={index}
													selected={isUserSelected(
														territoryMember.id
													)}
													onMouseOver={() =>
														onMouseOverTableRow(
															territoryMember.id
														)
													}
													onMouseOut={
														onMouseOutTableRow
													}
												>
													<TableCell
														padding="checkbox"
														style={{
															width: "10%",
														}}
													>
														<Stack
															alignItems="center"
															justifyContent="center"
														>
															{territoryMember.isUserNotAdded ? (
																<Stack
																	alignItems="center"
																	onClick={() =>
																		deleteTerritoryMember(
																			index
																		)
																	}
																>
																	{CloseIcon(
																		20,
																		20,
																		"rgba(0, 0, 0, 0.6)"
																	)}
																</Stack>
															) : (
																<Checkbox
																	checked={isUserSelected(
																		territoryMember.id
																	)}
																	onClick={(
																		event
																	) =>
																		handleSelectUser(
																			event,
																			territoryMember.id
																		)
																	}
																/>
															)}
														</Stack>
													</TableCell>

													<TableCell
														style={{
															borderBottom:
																selectedTerritoryId ===
																territoryMember.id
																	? `2px solid ${theme.palette.primary.main}`
																	: null,
															width: "30%",
														}}
														onClick={() =>
															handleTableCell(
																territoryMember
															)
														}
													>
														{territoryMember.isUserNotAdded ? (
															<Box>
																<Controller
																	name={`${formFields.users}.${index}`}
																	control={
																		control
																	}
																	render={({
																		field,
																	}) => (
																		<UsersOrTeamsAutoComplete
																			field={
																				field
																			}
																			onChange={(
																				selectedUser
																			) =>
																				selectUser(
																					selectedUser,
																					index
																				)
																			}
																			selectedUsersList={
																				territoryUsersList
																			}
																			updateUserAndTeamsTotalCount={
																				updateUserAndTeamsTotalCount
																			}
																		/>
																	)}
																/>
															</Box>
														) : (
															<Stack
																direction="row"
																alignItems="center"
																spacing={1}
															>
																<img
																	src={
																		UnKnownImage
																	}
																	alt="img"
																	width="26px"
																	height="26px"
																/>
																<TableCellText>
																	{
																		territoryMember.name
																	}
																</TableCellText>
															</Stack>
														)}
													</TableCell>

													<TableCell
														style={{
															width: "30%",
														}}
													>
														<TableCellText>
															{
																territoryMember.email
															}
														</TableCellText>
													</TableCell>

													<TableCell
														style={{
															width: "20%",
														}}
													>
														<TableCellText>
															{
																territoryMember
																	.role.name
															}
														</TableCellText>
													</TableCell>

													<TableCell
														sx={{
															py: 0,
															pl: 0,
															pr: 1,
															width: "10%",
															minWidth: "60px",
														}}
													>
														{isDeleteButtonVisible(
															territoryMember
														) && (
															<Stack alignItems="flex-end">
																<Box
																	style={{
																		width: "fit-content",
																		border: `1px solid ${theme.palette.primary.main}`,
																		borderRadius:
																			"8px",
																		padding:
																			"2px 8px",
																	}}
																>
																	<IconButton
																		size="small"
																		onClick={() =>
																			deleteTerritoryMember(
																				index
																			)
																		}
																	>
																		{DeleteIcon(
																			20,
																			20,
																			theme
																				.palette
																				.error
																				.main
																		)}
																	</IconButton>
																</Box>
															</Stack>
														)}
													</TableCell>
												</TableRow>
											)
										)}
									</TableBody>
								</Table>

								<Stack
									direction="row"
									justifyContent="flex-end"
									py={1}
									px={2}
									sx={{
										position: "fixed",
										bottom: "108px",
										right: "40px",
										backgroundColor:
											theme.palette.secondary.main,
										borderRadius: "8px",
									}}
								>
									<Typography
										style={{
											fontSize: "14px",
											fontWeight: "500",
											color: "rgba(0, 0, 0, 0.6)",
										}}
									>
										Total :{" "}
										{getCountOfTerritoryUsers(
											territoryUsers
										)}
									</Typography>
								</Stack>
							</TableContainer>
						</Box>
					</Box>
				</Box>

				<Divider />
				{isEditMode ? (
					<Box p={2}>
						<Button
							variant="contained"
							color="primary"
							disableElevation
							disabled={isSaving}
							onClick={handleSubmit(handleUpdate)}
							style={{
								color: isValidTerritory()
									? "#FFFFFF"
									: hexToRgba("#FFFFFF", 0.6),
							}}
						>
							Update
						</Button>
					</Box>
				) : (
					<Box p={2}>
						<Button
							variant="contained"
							color="primary"
							disableElevation
							onClick={handleSubmit(handleSave)}
							disabled={isSaving}
							style={{
								color: isValidTerritory()
									? "#FFFFFF"
									: hexToRgba("#FFFFFF", 0.6),
							}}
						>
							Save
						</Button>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}
