import {
	getDependentDropdownKeyByFieldId,
	getDropdownKeyByFieldId,
	getDropdownKeyByFieldName,
} from "../commonQueryKeys";
import { getRootKey } from "../rootKey";

// workflow key
const getWorkflowKey = () => {
	return [...getRootKey(), "workflow"];
};

// query keys
export const fieldTypeKey = [...getWorkflowKey(), "fieldType"];

export const automationFilterKey = [...getWorkflowKey(), "automationFilter"];

export const workFlowHistoryFilterKey = [...getWorkflowKey(), "historyFilter"];

export const workflowFilterUserListKey = [
	...getWorkflowKey(),
	"filterUserList",
];

export const workflowNamesListKey = [...getWorkflowKey(), "workflowNames"];

export const workflowFilterActionsListKey = [
	...getWorkflowKey(),
	"filterActionsList",
];

// query key methods
export const getComparatorKey = (fieldTypeId) => {
	return [...getWorkflowKey(), "Comparator", String(fieldTypeId)];
};

export const getCriteriaFieldsKey = (triggerSourceId, triggerEventId) => {
	return [
		...getWorkflowKey(),
		"CriteriaFields",
		String(triggerSourceId),
		String(triggerEventId),
	];
};

export const getActionListKey = (triggerSource) => {
	return [...getWorkflowKey(), "ActionList", String(triggerSource)];
};

export const getActionFieldsKey = (
	triggerSource,
	actionSource,
	actionEvent
) => {
	return [
		...getWorkflowKey(),
		"ActionFields",
		String(triggerSource),
		String(actionSource),
		String(actionEvent),
	];
};

export const getActionStepOptionKey = (triggerSource, fieldId, actions) => {
	return [
		...getWorkflowKey(),
		"ActionStepOptions",
		String(triggerSource),
		String(fieldId),
		JSON.stringify(actions),
	];
};

export const getWorkflowDataKey = (workflowId) => {
	return [...getWorkflowKey(), "workflowAutomationData", String(workflowId)];
};

export const getWorkflowHistoryKey = (historyId) => {
	return [...getWorkflowKey(), "workflowHistoryData", String(historyId)];
};

export const getTemplateListKey = (templateId) => {
	return [...getWorkflowKey(), "Template", String(templateId)];
};

export const getAllTemplateListKey = () => {
	return [...getWorkflowKey(), "TemplateList"];
};

export const getWorkflowListKey = (
	filterCriteria = {},
	searchText = "",
	automationSortData
) => {
	return [
		...getWorkflowKey(),
		"WorkflowList",
		filterCriteria,
		searchText,
		automationSortData,
	];
};

export const getAllWorkflowListKey = () => {
	return [...getWorkflowKey(), "WorkflowList"];
};

export const getWorkflowHistoryListKey = (filterCriteria, sort) => {
	return [...getWorkflowKey(), "WorkflowHistoryList", filterCriteria, sort];
};

export const getWorkflowDropdownKeyByName = (name, searchValue) => {
	return [...getDropdownKeyByFieldName(name), searchValue];
};

export const getWorkflowDropdownKeyById = (fieldId, searchValue) => {
	return [...getDropdownKeyByFieldId(fieldId), searchValue];
};

export const getWorkflowDependentDropdownKeyById = (
	fieldId,
	dependentId,
	searchValue
) => {
	return [
		...getDependentDropdownKeyByFieldId(fieldId, dependentId),
		searchValue,
	];
};
