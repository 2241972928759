function Whatsapp({ width = "24", height = "24", color = "#33BC7E" }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.6339 4.53501C17.6649 2.56377 15.0464 1.47771 12.2566 1.47656C6.5084 1.47656 1.82994 6.15464 1.82769 11.9046C1.82689 13.7428 2.30712 15.5368 3.21978 17.1184L1.74023 22.5225L7.26875 21.0723C8.79198 21.9031 10.507 22.3411 12.2525 22.3417H12.2567C12.2564 22.3417 12.257 22.3417 12.2567 22.3417C18.0043 22.3417 22.6832 17.6631 22.6856 11.913C22.6866 9.12635 21.6028 6.50618 19.6339 4.53501ZM12.2567 20.5804H12.2532C10.6978 20.5798 9.17226 20.1619 7.84139 19.3721L7.52491 19.1843L4.2442 20.0449L5.11991 16.8462L4.91379 16.5182C4.04613 15.1382 3.58787 13.5431 3.58855 11.9052C3.59041 7.12606 7.47897 3.23792 12.2602 3.23792C14.5754 3.23864 16.7518 4.14148 18.3883 5.77991C20.0249 7.41833 20.9256 9.59617 20.9247 11.9123C20.9228 16.6918 17.0343 20.5804 12.2567 20.5804Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.0108 14.0887C16.7502 13.9582 15.4691 13.3279 15.2302 13.2409C14.9914 13.154 14.8176 13.1105 14.6439 13.3713C14.4703 13.6322 13.9708 14.2191 13.8188 14.3931C13.6669 14.5669 13.5148 14.5888 13.2543 14.4582C12.9937 14.3278 12.1541 14.0527 11.1588 13.1649C10.3841 12.4739 9.86114 11.6206 9.70907 11.3597C9.5571 11.0989 9.6929 10.9579 9.82337 10.8279C9.94059 10.7111 10.084 10.5236 10.2143 10.3714C10.3445 10.2193 10.388 10.1105 10.4748 9.93671C10.5617 9.76276 10.5183 9.61058 10.4531 9.48019C10.388 9.34976 9.86685 8.06715 9.64972 7.5453C9.43814 7.03726 9.22334 7.1061 9.06336 7.09801C8.9116 7.09043 8.73765 7.08887 8.56396 7.08887C8.39027 7.08887 8.1079 7.1541 7.8691 7.41492C7.63026 7.67582 6.95703 8.30629 6.95703 9.58877C6.95703 10.8714 7.89078 12.1105 8.02108 12.2844C8.15134 12.4584 9.85855 15.0903 12.4725 16.2191C13.0943 16.4876 13.5796 16.6479 13.9581 16.7679C14.5823 16.9663 15.1504 16.9383 15.5994 16.8712C16.1 16.7964 17.1411 16.2409 17.3582 15.6322C17.5754 15.0235 17.5754 14.5017 17.5102 14.393C17.4451 14.2844 17.2714 14.2191 17.0108 14.0887Z"
				fill={color}
			/>
		</svg>
	);
}

export default Whatsapp;
