import {
	Stack,
	ToggleButton,
	Box,
	Divider,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ToggleButtonGroup from "../../Elements/ToggleButtonGroup";
import DuplicateContact from "./DuplicateContact";
import DuplicateCompany from "./DuplicateCompany";
import DropDownRight from "../../../assets/icons/dropDownRight";
import { twozoStyles } from "../../../styles/twozo";
import { useNavigate } from "react-router-dom";
import UnAuthorized from "../../Auth/UnAuthorized";
import Can from "../../Auth/Can";
import CanNot from "../../Auth/CanNot";
import { PERMISSIONS } from "../../../utils/Auth";

export default function ManageDuplicates(props) {
	const { moduleName, navigateURL } = props;
	const theme = useTheme();
	const navigate = useNavigate();
	const classes = twozoStyles();
	const duplicateListRef = useRef();
	const [type, setType] = useState("Contact");
	const [duplicateListStartingPosition, setDuplicateListStartingPosition] =
		useState(0);

	useEffect(() => {
		if (duplicateListRef) {
			setDuplicateListStartingPosition(
				duplicateListRef.current.getBoundingClientRect().top
			);
		}
	}, []);

	const handleViewToggleButtonGroup = (e, type) => {
		if (type) {
			setType(type);
		}
	};

	const renderDuplicateContactComponent = () => {
		return (
			<>
				<Can permission={PERMISSIONS.contact.access}>
					<DuplicateContact />
				</Can>

				<CanNot permission={PERMISSIONS.contact.access}>
					<Stack height="50vh">
						<UnAuthorized />
					</Stack>
				</CanNot>
			</>
		);
	};

	const renderDuplicateCompanyComponent = () => {
		return (
			<>
				<Can permission={PERMISSIONS.company.access}>
					<DuplicateCompany />
				</Can>

				<CanNot permission={PERMISSIONS.company.access}>
					<Stack height="50vh">
						<UnAuthorized />
					</Stack>
				</CanNot>
			</>
		);
	};

	return (
		<React.Fragment>
			<Box
				ref={duplicateListRef}
				sx={{
					height: `calc(100vh - ${duplicateListStartingPosition}px)`,
					overflowY: "hidden",
				}}
			>
				<Box className={classes.menuBar}>
					<Stack
						height="100%"
						direction="row"
						alignItems="center"
						spacing={1}
					>
						<Box
							onClick={() => navigate(navigateURL)}
							style={{ cursor: "pointer" }}
						>
							<Typography
								fontSize={15}
								fontWeight={500}
								color={theme.palette.secondary.contrastText}
							>
								{moduleName}
							</Typography>
						</Box>

						{DropDownRight(16, 16)}

						<Typography fontWeight={500} fontSize={15}>
							Manage Duplicates
						</Typography>
					</Stack>
				</Box>

				<Divider />

				<Box pt={2}>
					<Stack spacing={2}>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="center"
						>
							<ToggleButtonGroup
								value={type}
								onChange={handleViewToggleButtonGroup}
							>
								<ToggleButton value="Contact">
									Contacts
								</ToggleButton>
								<ToggleButton value="Company">
									Companies
								</ToggleButton>
							</ToggleButtonGroup>
						</Stack>
						<Stack>
							{(function () {
								switch (type) {
									case "Contact":
										return renderDuplicateContactComponent();
									case "Company":
										return renderDuplicateCompanyComponent();
									default:
										return null;
								}
							})()}
						</Stack>
					</Stack>
				</Box>
			</Box>
		</React.Fragment>
	);
}
