import {
	alpha,
	Box,
	Button,
	Divider,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import MenuSearchBar from "../../../Elements/MenuSearchBar";
import {
	useLocaleSettingsDetails,
	useUpdateLocaleSettings,
} from "../../../../hooks/services/accountSettings/crmSettings";
import { Controller, useForm } from "react-hook-form";
import { getDropdownDataByName } from "../../../../utils/dropdownData";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import TimezoneMenu from "../../../Elements/TimezoneMenu";
import { MenuItemText } from "../../../../styles/twozo";

const styles = {
	heading: {
		fontSize: "15px",
		fontWeight: 600,
	},
	subHeading: {
		fontSize: "14px",
		fontWeight: 500,
	},
};

export default function CrmSettings() {
	const theme = useTheme();
	const localeSettingsRef = useRef(null);
	const [searchLanguageValue, setSearchLanguageValue] = useState("");
	const [localeSettingsStartingPosition, setLocaleSettingsStartingPosition] =
		useState(0);
	const { data: localeSettings } = useLocaleSettingsDetails();
	const updateLocaleSettingsMutation = useUpdateLocaleSettings();

	const { control, handleSubmit, reset } = useForm({
		values: localeSettings?.preferences?.orgRegionalPreference,
	});

	const dateFormatList = getDropdownDataByName("DATE_FORMAT_LIST");
	const timeFormatList = getDropdownDataByName("TIME_FORMAT_LIST");
	const languageList = getDropdownDataByName("LANGUAGES");

	const handleSearchLanguageValue = (event) => {
		const { value } = event.target;
		setSearchLanguageValue(value);
	};

	const getFilteredLanguage = () => {
		if (searchLanguageValue.trim()) {
			return languageList?.filter((language) =>
				//filter for search
				language.name
					.toLowerCase()
					.includes(searchLanguageValue.toLowerCase())
			);
		}
		return languageList;
	};

	const filteredLanguage = getFilteredLanguage();

	useEffect(() => {
		if (localeSettingsRef) {
			setLocaleSettingsStartingPosition(
				localeSettingsRef.current.getBoundingClientRect().top
			);
		}
	}, [localeSettingsRef]);

	const handleSave = (data) => {
		const updatedData = {
			...data,
			id: localeSettings?.preferences?.id,
		};

		updateLocaleSettingsMutation.mutate(updatedData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.crmSettingsUpdated,
				});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					{"No Results Found"}
				</Typography>
			</Stack>
		);
	};

	const handleRenderValue = (selectedId, optionsList) => {
		const selectedOption = optionsList.find(
			(date) => date.value === selectedId
		);

		return selectedOption ? selectedOption.name : "Select";
	};

	return (
		<React.Fragment>
			<Box px={2} pb={0.5}>
				<Typography fontSize={14} sx={{ opacity: 0.6 }}>
					The date and time formats you select here will apply to all
					users in your CRM account (existing users plus users you add
					in future). Your users can change the language and time zone
					in their personal settings.
				</Typography>
			</Box>

			<Box
				ref={localeSettingsRef}
				style={{
					border: "1px solid rgb(0, 0, 0, 0.1)",
					borderRadius: "8px",
					overflowY: "auto",
					maxHeight: `calc(100vh - ${localeSettingsStartingPosition}px - 20px)`,
				}}
				m={1}
			>
				<Box px={2} pt={3}>
					<Typography style={styles.heading}>
						Locale Settings
					</Typography>

					<Stack spacing={2} py={2}>
						<Stack spacing={0.8}>
							<Typography style={styles.subHeading} pl={0.75}>
								Time Zone
							</Typography>

							<Controller
								name="timeZone"
								control={control}
								render={({ field: { value, onChange } }) => (
									<TimezoneMenu
										value={value}
										onChange={onChange}
										menuSx={{ width: "528px" }}
									/>
								)}
							/>

							<Typography
								fontSize={14}
								sx={{ opacity: 0.6 }}
								pl={1}
							>
								Timezone is updated automatically to match your
								computer timezone
							</Typography>
						</Stack>

						<Box>
							<Typography
								style={styles.subHeading}
								pb={0.8}
								pl={1}
							>
								Language
							</Typography>

							<Controller
								name="language"
								control={control}
								render={({ field: { value, onChange } }) => (
									<Select
										sx={{
											width: "528px",
											fontSize: "14px",
										}}
										value={value || ""}
										onChange={onChange}
										onOpen={() =>
											setSearchLanguageValue("")
										}
										MenuProps={{
											PaperProps: {
												style: {
													maxHeight: 300,
												},
											},
											autoFocus: false,
										}}
										renderValue={(selectedValue) => {
											return handleRenderValue(
												selectedValue,
												languageList
											);
										}}
									>
										<Box
											p={0.5}
											marginTop="-8px"
											sx={{
												position: "sticky",
												top: 0,
												zIndex: 1,
												backgroundColor: "#fff",
											}}
										>
											<MenuSearchBar
												value={searchLanguageValue}
												onChange={
													handleSearchLanguageValue
												}
											/>
										</Box>
										{filteredLanguage?.length > 0
											? filteredLanguage.map(
													(language) => (
														<MenuItem
															key={language.value}
															value={
																language.value
															}
															style={{
																height: "40px",
															}}
														>
															<MenuItemText>
																{language.name}
															</MenuItemText>
														</MenuItem>
													)
												)
											: renderEmptyView()}
									</Select>
								)}
							/>
						</Box>
					</Stack>

					<Divider />
				</Box>

				<Box px={2} pt={3}>
					<Typography style={styles.heading}>
						Date and Time Formats
					</Typography>

					<Stack spacing={2} py={2}>
						<Box>
							<Typography
								style={styles.subHeading}
								pl={1}
								pb={0.8}
							>
								Date Format
							</Typography>

							<Controller
								name="dateFormat"
								control={control}
								render={({ field: { value, onChange } }) => (
									<Select
										sx={{
											width: "528px",
											fontSize: "14px",
										}}
										value={value || ""}
										onChange={onChange}
										renderValue={(selectedValue) => {
											return handleRenderValue(
												selectedValue,
												dateFormatList
											);
										}}
									>
										{dateFormatList.map((date) => (
											<MenuItem
												key={date.value}
												value={date.value}
												style={{
													height: "40px",
												}}
											>
												<MenuItemText>
													{date.name}
												</MenuItemText>
											</MenuItem>
										))}
									</Select>
								)}
							/>
						</Box>

						<Box>
							<Typography
								style={styles.subHeading}
								pb={0.8}
								pl={1}
							>
								Time Format
							</Typography>

							<Controller
								name="timeFormat"
								control={control}
								render={({ field: { value, onChange } }) => (
									<Select
										sx={{
											width: "528px",
											fontSize: "14px",
										}}
										value={value || ""}
										onChange={onChange}
										renderValue={(selectedValue) => {
											return handleRenderValue(
												selectedValue,
												timeFormatList
											);
										}}
									>
										{timeFormatList.map((time) => (
											<MenuItem
												key={time.value}
												value={time.value}
												style={{
													height: "40px",
												}}
											>
												<MenuItemText>
													{time.name}
												</MenuItemText>
											</MenuItem>
										))}
									</Select>
								)}
							/>
						</Box>
					</Stack>
				</Box>

				<Stack
					direction="row"
					alignItems="center"
					spacing={1}
					px={2}
					py={3}
					sx={{
						position: "sticky",
						bottom: 0,
						backgroundColor: "white",
						zIndex: 1,
						borderTop: "1px solid rgba(0, 0, 0, 0.1)",
					}}
				>
					<Box display="flex">
						<Button
							sx={{
								"&.Mui-disabled": {
									backgroundColor: theme.palette.primary.main,
									color: "rgba(255,255,255, 0.6)",
								},
							}}
							variant="contained"
							disableElevation
							disabled={updateLocaleSettingsMutation.isLoading}
							onClick={handleSubmit(handleSave)}
						>
							Save
						</Button>
					</Box>

					<Box display="flex">
						<Button
							sx={{
								"&.Mui-disabled": {
									backgroundColor:
										theme.palette.secondary.main,
									color: alpha(
										theme.palette.secondary.contrastText,
										0.6
									),
								},
							}}
							variant="contained"
							color="secondary"
							disableElevation
							disabled={updateLocaleSettingsMutation.isLoading}
							onClick={() => reset()}
						>
							Cancel
						</Button>
					</Box>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
