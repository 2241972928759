import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { default as Home } from "../../assets/icons/home";
import { default as twozo_logo } from "../../assets/images/logo/twozo-full.png";
import { default as pageNotFound } from "../../assets/icons/pageNotFound";

export default function PageNotFound() {
	const navigate = useNavigate();

	const handleNavigate = () => {
		return navigate("/login");
	};
	return (
		<React.Fragment>
			<Stack
				alignItems="center"
				justifyContent="center"
				style={{
					minHeight: "100vh",
					backgroundColor: "#FAFAFA",
				}}
			>
				<Box
					p={5}
					style={{
						minWidth: "550px",
						backgroundColor: "#fff",
						border: "1px solid rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
					}}
				>
					<Stack
						alignItems="center"
						justifyContent="center"
						height="100%"
					>
						<Stack spacing={0.5}>
							<Box
								component="img"
								sx={{
									width: 108,
									height: 34,
								}}
								src={twozo_logo}
								alt="logo"
							/>
							<Box py={2}>{pageNotFound(100, 100)}</Box>
							<Typography fontSize={16} fontWeight={600}>
								Page not found
							</Typography>
							<Typography
								fontSize={16}
								fontWeight={400}
								color="rgba(0, 0, 0, 0.6)"
							>
								The page you&apos;re looking for doesn&apos;t
								exist. Please check the URL.
							</Typography>
							<Box pt={2}>
								<Button
									sx={{ width: "160px" }}
									variant="contained"
									startIcon={Home(17, 17, "#fff")}
									onClick={() => handleNavigate()}
									disableElevation
								>
									Back to Home
								</Button>
							</Box>
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</React.Fragment>
	);
}
