import React, { useState } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { default as DealIcon } from "../../../assets/icons/deals";
import { twozoStyles } from "../../../styles/twozo";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import AddNewDeals from "../AddNewDeals";
import Can from "../../Auth/Can";
import { PERMISSIONS } from "../../../utils/Auth";

export default function NoDealFound() {
	const classes = twozoStyles();
	const theme = useTheme();
	const [isAddDealDialogOpened, setIsAddDealDialogOpened] = useState(false);

	const toggleAddNewDealsDialog = () => {
		setIsAddDealDialogOpened((openAddDealDialog) => !openAddDealDialog);
	};
	const noResultsMessage = {
		title: "No Deal Found",
		message: "Try resetting your filters ",
		subText: "Create new deal",
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddDealDialogOpened}
				onOpen={toggleAddNewDealsDialog}
				onClose={toggleAddNewDealsDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddNewDeals onClose={toggleAddNewDealsDialog} />
				</Box>
			</CustomSwipeableDrawer>

			<Stack
				alignItems="center"
				justifyContent="center"
				style={{
					position: "absolute",
					top: "45%",
					left: "42%",
					minWidth: "22%",
				}}
			>
				<Box sx={{ display: "flex" }} pb={1}>
					{DealIcon(37, 37, "#666666")}
				</Box>
				<Typography
					fontSize={17}
					fontWeight={400}
					color="rgba(0, 0, 0, 0.6)"
				>
					{noResultsMessage.title}
				</Typography>
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					{noResultsMessage.message}
					<Can permission={PERMISSIONS.deal.create}>
						<span>or </span>
						<span
							onClick={toggleAddNewDealsDialog}
							style={{ color: theme.palette.primary.main }}
						>
							{noResultsMessage.subText}
						</span>
					</Can>
				</Typography>
			</Stack>
		</React.Fragment>
	);
}
