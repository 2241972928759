import { Typography } from "@mui/material";
import React from "react";
import { getDateAndTimeFormat } from "../../../../../../utils/DateUtils";

export default function RenderDateTimeFieldValue(props) {
	const { fieldValue } = props;

	return (
		<React.Fragment>
			<Typography fontSize={14}>
				{fieldValue ? getDateAndTimeFormat(fieldValue) : null}
			</Typography>
		</React.Fragment>
	);
}
