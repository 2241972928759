import React, { useState } from "react";
import { Stack, TextField, useTheme } from "@mui/material";
import { useFilterDataContext } from "../../../../../Context/FilterDataContext";

export default function TextInputField(props) {
	const { filterCondition, onChange } = props;
	const theme = useTheme();
	const { enableApplyButton } = useFilterDataContext();

	const styles = {
		field: {
			alignItems: "center",
			justifyContent: "space-between",
			width: "218px",
			height: "38px",
			padding: "0px 10px",
			backgroundColor: "rgba(51, 188, 126, 0.12)",
		},
	};

	const [focusTextField, setFocusTextField] = useState(false);

	const onFocusTextField = () => {
		setFocusTextField(true);
	};

	const onBlurTextField = () => {
		setFocusTextField(false);
	};

	const handleInputChange = (event) => {
		const { value } = event.target;

		const updatedFilterCondition = {
			...filterCondition,
			value: {
				...filterCondition.value,
				value: value,
			},
		};
		onChange(updatedFilterCondition);
		enableApplyButton();
	};

	return (
		<Stack
			direction="row"
			style={{
				...styles.field,
				borderRadius: "0px 0px 8px 8px",
				borderBottom: focusTextField
					? `1px solid ${theme.palette.primary.main}`
					: null,
			}}
		>
			<TextField
				name={filterCondition.value.fieldName}
				value={filterCondition.value.value}
				onChange={(event) => handleInputChange(event)}
				variant="standard"
				InputProps={{
					disableUnderline: true,
				}}
				placeholder="Enter Here"
				onFocus={() => onFocusTextField()}
				onBlur={() => onBlurTextField()}
			/>
		</Stack>
	);
}
