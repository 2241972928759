import { Box } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import LexendLatinWoff2 from "../../assets/font/Lexend/lexend_latin.woff2";
import LexendLatinExtWoff2 from "../../assets/font/Lexend/lexend_latin_ext.woff2";
import LexendVietnameseWoff2 from "../../assets/font/Lexend/lexend_vietnamese.woff2";
import { default as CheckBoxOnIcon } from "../../assets/icons/checkboxOn";
import { default as CheckBoxOffIcon } from "../../assets/icons/checkBoxOff";
import { default as CheckBoxIntIcon } from "../../assets/icons/checkBoxInt";
import { default as RadioOffIcon } from "../../assets/icons/radioOff";
import { default as RadioCheckedIcon } from "../../assets/icons/radioChecked";
import { default as DropDownIcons } from "../../assets/icons/dropDownCentered";
import React from "react";
import { renderTimeViewClock } from "@mui/x-date-pickers";

const light_theme = createTheme({
	palette: {
		primary: {
			main: "#33BC7E",
			contrastText: "#fff",
		},
		secondary: {
			main: "#E7F7F0",
			contrastText: "#2EA871",
		},
		error: {
			main: "#EB5454",
		},
		warning: {
			main: "#EB54541F",
			contrastText: "#EB5454",
		},
	},
	typography: {
		fontFamily: [
			"Lexend",
			"sans-serif",
			"-apple-system",
			"BlinkMacSystemFont",
			"Segoe UI",
			"Roboto",
			"Oxygen",
			"Ubuntu",
			"Cantarell",
			"Fira Sans",
			"Droid Sans",
			"Helvetica Neue",
		].join(", "),
	},
	overrides: {
		MuiButton: {
			raisedPrimary: {
				color: "#fff",
			},
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: "capitalize",
					borderRadius: "8px",
					fontSize: "14px",
					fontWeight: 600,
					height: "36px",
				},
			},
		},
		MuiAutocomplete: {
			styleOverrides: {
				noOptions: {
					fontSize: "13px",
					textAlign: "center",
					paddingLeft: "0px",
				},
			},
		},
		MuiIconButton: {
			defaultProps: {
				color: "primary",
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					"& .MuiOutlinedInput-notchedOutline": {
						borderRadius: "8px",
					},
					"& .MuiOutlinedInput-root": {
						height: "auto",
						minHeight: "42px ",
						padding: "8px 14px",
						"& input": {
							padding: "0px",
						},
						"& fieldset": {
							border: "1px solid rgba(0, 0, 0, 0.1)",
						},
						"&:hover fieldset": {
							border: "1px solid rgba(0, 0, 0, 0.3)",
						},
						"&.Mui-focused fieldset": {
							border: "1px solid #33BC7E",
						},
					},
				},
			},
			defaultProps: {
				inputProps: {
					style: {
						fontSize: "14px",
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					height: "42px",
					borderRadius: "8px",
					"& .MuiOutlinedInput-notchedOutline": {
						border: "1px solid rgba(0, 0, 0, 0.1)",
					},
					"&:hover .MuiOutlinedInput-notchedOutline": {
						border: "1px solid rgba(0, 0, 0, 0.3)",
					},
					"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
						border: "1px solid #33BC7E",
					},
				},
			},
			defaultProps: {
				inputProps: {
					style: {
						fontSize: "14px",
					},
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					fontSize: "14px",
					height: "42px",
					"& .MuiOutlinedInput-notchedOutline": {
						borderRadius: "8px",
						border: "1px solid rgba(0, 0, 0, 0.1)",
					},
					"&:hover .MuiOutlinedInput-notchedOutline": {
						border: "1px solid rgba(0, 0, 0, 0.3)",
					},
					"& .MuiSelect-icon": {
						top: "calc(50% - 0.6em)",
						right: "14px",
						opacity: 0.6,
					},
					"& .MuiSelect-iconOpen": {
						top: "calc(50% - 0.8em)",
					},
				},
			},
			defaultProps: {
				IconComponent: (props) => (
					<Box {...props}>{DropDownIcons(14, 14)}</Box>
				),
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					padding: "2px",
					"&.Mui-disabled": {
						opacity: 0.6,
					},
				},
			},
			defaultProps: {
				icon: CheckBoxOffIcon(22, 22),
				checkedIcon: CheckBoxOnIcon(22, 22, "#33BC7E"),
				indeterminateIcon: CheckBoxIntIcon(22, 22, "#33BC7E"),
			},
		},
		MuiRadio: {
			defaultProps: {
				icon: RadioOffIcon(22, 22),
				checkedIcon: RadioCheckedIcon(22, 22, "#33BC7E"),
			},
		},
		MuiSwitch: {
			styleOverrides: {
				root: {
					width: 30,
					height: 18,
					padding: 0,
					"& .MuiSwitch-switchBase": {
						padding: 2,
						margin: 2,
						"&.Mui-checked": {
							transform: "translateX(12px)",
							color: "#fff",
							"& + .MuiSwitch-track": {
								borderRadius: 20,
								backgroundColor: "#fff",
								opacity: 1,
								border: "1.5px solid #33BC7E",
							},
							"& .MuiSwitch-thumb": {
								backgroundColor: "#33BC7E",
								width: 10,
								height: 10,
								border: "none",
							},
							"&.Mui-disabled + .MuiSwitch-track": {
								opacity: 0.7,
							},
						},
						"&.Mui-focusVisible .MuiSwitch-thumb": {
							color: "#33BC7E",
							border: "6px solid #fff",
						},
						"&.Mui-disabled .MuiSwitch-thumb": {
							color: "#33BC7E",
							opacity: 0.7,
						},
						"&.Mui-disabled + .MuiSwitch-track": {
							opacity: 0.7,
						},
					},
					"& .MuiSwitch-thumb": {
						boxSizing: "border-box",
						width: 10,
						height: 10,
						backgroundColor: "#fff",
						border: "1.5px solid rgba(0, 0, 0, 0.4)",
						boxShadow: "none",
					},
					"& .MuiSwitch-track": {
						borderRadius: 20,
						backgroundColor: "#fff",
						opacity: 1,
						border: "1.5px solid rgba(0, 0, 0, 0.4)",
					},
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					"&:hover": {
						backgroundColor: "#F4F5F5",
					},
				},
			},
		},
		MuiTable: {
			styleOverrides: {
				root: {
					// border collapse inherit removes the thick border on the odd numbered rows
					borderCollapse: "inherit",
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					height: "40px",
					"&:hover": {
						backgroundColor: "rgba(0, 0, 0, 0.02)",
						cursor: "pointer",
					},
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					color: "#000000",
				},
			},
		},
		MuiDesktopDateTimePicker: {
			defaultProps: {
				viewRenderers: {
					hours: renderTimeViewClock,
					minutes: renderTimeViewClock,
					seconds: renderTimeViewClock,
				},
			},
		},
		MuiDateCalendar: {
			styleOverrides: {
				root: {
					"& .MuiPickersDay-root": {
						"&.Mui-selected": {
							backgroundColor: "#33BC7E",
							borderRadius: "8px",
							"&:hover": {
								border: "1px solid #E7F7F0",
								color: "#fff",
							},
						},
					},
					"& .MuiPickersDay-dayWithMargin": {
						borderRadius: "8px",
						fontSize: "14px",
						fontWeight: 500,
						"&:hover": {
							border: "1px solid #33BC7E",
							color: "#33BC7E",
						},
					},
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: `
                *::-webkit-scrollbar {
                    width: 10px;
                    height: 10px;
                }
                
                *::-webkit-scrollbar-track {
                    -webkit-border-radius: 10px;
                    border-radius: 10px;
                }
                
                *::-webkit-scrollbar-thumb {
                    border: 2px solid rgba(0, 0, 0, 0);
                    background-clip: padding-box;
                    -webkit-border-radius: 10px;
                    border-radius: 10px;
                    background-color: rgba(0, 0, 0, 0.08);
                    outline: rgba(0, 0, 0, 0.08);
                }
                
                ::-webkit-scrollbar-thumb:hover {
                    background-color: rgba(0, 0, 0, 0.4);
                    outline: rgba(0, 0, 0, 0.4);
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 100;
                  font-display: swap;
                  src: url(${LexendVietnameseWoff2}) format('woff2');
                  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 100;
                  font-display: swap;
                  src: url(${LexendLatinExtWoff2}) format('woff2');
                  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 100;
                  font-display: swap;
                  src: url(${LexendLatinWoff2}) format('woff2');
                  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 200;
                  font-display: swap;
                  src: url(${LexendVietnameseWoff2}) format('woff2');
                  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 200;
                  font-display: swap;
                  src: url(${LexendLatinExtWoff2}) format('woff2');
                  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 200;
                  font-display: swap;
                  src: url(${LexendLatinWoff2}) format('woff2');
                  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 300;
                  font-display: swap;
                  src: url(${LexendVietnameseWoff2}) format('woff2');
                  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 300;
                  font-display: swap;
                  src: url(${LexendLatinExtWoff2}) format('woff2');
                  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 300;
                  font-display: swap;
                  src: url(${LexendLatinWoff2}) format('woff2');
                  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 400;
                  font-display: swap;
                  src: url(${LexendVietnameseWoff2}) format('woff2');
                  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 400;
                  font-display: swap;
                  src: url(${LexendLatinExtWoff2}) format('woff2');
                  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 400;
                  font-display: swap;
                  src: url(${LexendLatinWoff2}) format('woff2');
                  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 500;
                  font-display: swap;
                  src: url(${LexendVietnameseWoff2}) format('woff2');
                  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 500;
                  font-display: swap;
                  src: url(${LexendLatinExtWoff2}) format('woff2');
                  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 500;
                  font-display: swap;
                  src: url(${LexendLatinWoff2}) format('woff2');
                  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 600;
                  font-display: swap;
                  src: url(${LexendVietnameseWoff2}) format('woff2');
                  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 600;
                  font-display: swap;
                  src: url(${LexendLatinExtWoff2}) format('woff2');
                  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 600;
                  font-display: swap;
                  src: url(${LexendLatinWoff2}) format('woff2');
                  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 700;
                  font-display: swap;
                  src: url(${LexendVietnameseWoff2}) format('woff2');
                  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 700;
                  font-display: swap;
                  src: url(${LexendLatinExtWoff2}) format('woff2');
                  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 700;
                  font-display: swap;
                  src: url(${LexendLatinWoff2}) format('woff2');
                  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 800;
                  font-display: swap;
                  src: url(${LexendVietnameseWoff2}) format('woff2');
                  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 800;
                  font-display: swap;
                  src: url(${LexendLatinExtWoff2}) format('woff2');
                  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 800;
                  font-display: swap;
                  src: url(${LexendLatinWoff2}) format('woff2');
                  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 900;
                  font-display: swap;
                  src: url(${LexendVietnameseWoff2}) format('woff2');
                  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 900;
                  font-display: swap;
                  src: url(${LexendLatinExtWoff2}) format('woff2');
                  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
                }
                
                @font-face {
                  font-family: 'Lexend';
                  font-style: normal;
                  font-weight: 900;
                  font-display: swap;
                  src: url(${LexendLatinWoff2}) format('woff2');
                  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                }
            `,
		},
	},
});

export const LightTheme = light_theme;
