import React, { useMemo, useState } from "react";
import MenuSearchBar from "../../../../Elements/MenuSearchBar";
import { useUserTeamTerritoryList } from "../../../../../hooks/services/userManagement";
import {
	Box,
	ClickAwayListener,
	Collapse,
	MenuItem,
	Stack,
	useTheme,
} from "@mui/material";
import { MenuItemText } from "../../../../../styles/twozo";
import { default as UserIcon } from "../../../../../assets/icons/user";
import { default as TeamsIcon } from "../../../../../assets/icons/teams";
import { default as LocationIcon } from "../../../../../assets/icons/location";

export default function SearchUsers(props) {
	const { addUser, addedUsers, addedTeams, addedTerritories } = props;
	const theme = useTheme();
	const [searchKeyword, setSearchKeyword] = useState("");
	const [openSearchResult, setOpenSearchResult] = useState(false);

	const { data: userListData } = useUserTeamTerritoryList(
		{
			criteria: {
				value: searchKeyword,
				type: "contains",
			},
			requiredUserCount: true,
		},
		{
			enabled: !!searchKeyword,
		}
	);

	const updateSearchKeyword = (event) => {
		const { value } = event.target;
		setSearchKeyword(value);
		if (value.length > 0) {
			openSearchResultComponent();
		} else {
			closeSearchResultComponent();
		}
	};

	const openSearchResultComponent = () => {
		setOpenSearchResult(true);
	};
	const closeSearchResultComponent = () => {
		setOpenSearchResult(false);
	};

	const getUserIcon = (userType) => {
		switch (userType) {
			case "USER":
				return UserIcon(16, 16, "rgba(0, 0, 0, 0.6)");
			case "TEAM":
				return TeamsIcon(14, 14, "rgba(0, 0, 0, 0.6)");
			case "TERRITORY":
				return LocationIcon(14, 14, "rgba(0, 0, 0, 0.6)");
		}
	};

	const handleAddUser = (userType, id, name, count) => {
		let type = "";

		switch (userType) {
			case "USER":
				type = "users";
				addUser(type, { id, name });
				break;
			case "TEAM":
				type = "teams";
				addUser(type, { id, name, count });
				break;
			case "TERRITORY":
				type = "territories";
				addUser(type, { id, name, count });
				break;
		}

		closeSearchResultComponent();
	};

	const filteredUserListData = useMemo(() => {
		if (!userListData || userListData.length === 0) {
			return [];
		}

		// Map types to corresponding arrays for lookup
		const typeMap = {
			USER: new Set(addedUsers.map(({ id }) => id)),
			TEAM: new Set(addedTeams.map(({ id }) => id)),
			TERRITORY: new Set(addedTerritories.map(({ id }) => id)),
		};

		// Filter out items where value exists in respective set
		return userListData.filter(
			({ type, value }) => !typeMap[type]?.has(value)
		);
	}, [userListData, addedUsers, addedTeams, addedTerritories]);

	return (
		<React.Fragment>
			<ClickAwayListener onClickAway={closeSearchResultComponent}>
				<Box>
					<MenuSearchBar
						placeholder="Search Users, Teams, Territories"
						backgroundColor="#fff"
						value={searchKeyword}
						onChange={updateSearchKeyword}
						onFocus={() => {
							searchKeyword.length > 0 &&
								openSearchResultComponent();
						}}
					/>

					<Collapse
						in={
							openSearchResult &&
							filteredUserListData &&
							filteredUserListData.length > 0
						}
						timeout={200}
						style={{
							position: "absolute",
							width: "250px",
							marginLeft: "48px",
							zIndex: theme.zIndex.drawer + 1,
						}}
						unmountOnExit
					>
						<Box
							style={{
								backgroundColor: "#fff",
								maxHeight: "30vh",
								borderRadius: "6px",
								marginTop: "4px",
								boxShadow:
									"0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
								padding: "4px 0px",
							}}
						>
							{filteredUserListData?.map((user, index) => (
								<MenuItem
									key={index}
									style={{ height: "40px" }}
									onClick={() => {
										handleAddUser(
											user.type,
											user.value,
											user.name,
											user.count
										);
									}}
								>
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="space-between"
										spacing={0.5}
										width="100%"
									>
										<MenuItemText noWrap>
											{user.name}
										</MenuItemText>

										<Box display="flex">
											{getUserIcon(user.type)}
										</Box>
									</Stack>
								</MenuItem>
							))}
						</Box>
					</Collapse>
				</Box>
			</ClickAwayListener>
		</React.Fragment>
	);
}
