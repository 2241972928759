import React, { useMemo, useState } from "react";
import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import MenuSearchBar from "../MenuSearchBar";
import { getDropdownDataByName } from "../../../utils/dropdownData";
import { MenuItemText } from "../../../styles/twozo";

const timeZoneList = getDropdownDataByName("ACCOUNT_TIMEZONE");

export default function TimezoneMenu(props) {
	const { value, onChange, menuSx } = props;

	const [searchedTimeZoneValue, setSearchedTimeZoneValue] = useState("");

	const handleSearchTimeZoneValue = (event) => {
		const { value } = event.target;
		setSearchedTimeZoneValue(value);
	};

	const filteredTimeZoneList = useMemo(() => {
		if (searchedTimeZoneValue.trim()) {
			return timeZoneList?.filter((timeZone) =>
				timeZone.name
					.toLowerCase()
					.includes(searchedTimeZoneValue.toLowerCase())
			);
		}
		return timeZoneList;
	}, [searchedTimeZoneValue]);

	const selectedTimeZoneName = useMemo(() => {
		let selectedTimeZone = timeZoneList.find(
			(timeZone) => timeZone.value === value
		);
		if (selectedTimeZone?.name) {
			return selectedTimeZone.name;
		}
		return "Select Time Zone";
	}, [value]);

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					No Results Found
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			<Select
				sx={{ width: "100%", ...menuSx }}
				value={value || ""}
				onChange={onChange}
				onOpen={() => setSearchedTimeZoneValue("")}
				renderValue={() => {
					return selectedTimeZoneName;
				}}
				MenuProps={{
					autoFocus: false,
					PaperProps: {
						style: {
							maxHeight: 250,
						},
					},
				}}
			>
				<Box
					p={0.5}
					marginTop="-8px"
					sx={{
						position: "sticky",
						top: 0,
						zIndex: 1,
						backgroundColor: "#fff",
					}}
				>
					<MenuSearchBar
						value={searchedTimeZoneValue}
						onChange={handleSearchTimeZoneValue}
					/>
				</Box>

				{filteredTimeZoneList?.length > 0
					? filteredTimeZoneList.map((timeZone, index) => (
							<MenuItem
								key={index}
								value={timeZone.value || ""}
								style={{ height: "40px" }}
							>
								<MenuItemText pl={2}>
									{timeZone.name}
								</MenuItemText>
							</MenuItem>
						))
					: renderEmptyView()}
			</Select>
		</React.Fragment>
	);
}
