import React from "react";
import {
	Box,
	Button,
	Divider,
	IconButton,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { useUpdateRoleMutation } from "../../../../../hooks/services/userManagement/roles";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { useForm } from "react-hook-form";

const maximumCharacters = 255;
const maxLengthErrorMessage = `Max. of ${maximumCharacters} characters are allowed`;
const requiredErrorMessage = "This field is required.";

const validationConfig = {
	required: {
		value: true,
		message: requiredErrorMessage,
	},
	maxLength: {
		value: maximumCharacters,
		message: maxLengthErrorMessage,
	},
};

const formFields = {
	role: "role",
};

export default function RenameRole(props) {
	const { onClose, selectedRole } = props;
	const theme = useTheme();

	const {
		register,
		formState: { errors, isDirty },
		handleSubmit,
		clearErrors,
	} = useForm({
		values: { role: selectedRole.name },
		reValidateMode: false,
	});

	const updateRoleMutation = useUpdateRoleMutation();

	const onRenameRole = (formData) => {
		let requestData = {
			id: selectedRole.id,
			name: formData.role,
		};

		updateRoleMutation.mutate(requestData, {
			onSuccess: () => {
				onClose();
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.roleUpdated,
				});
			},
			onError: (error) => {
				let errorMessage = error.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message:
						errorMessage ?? notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	const isSaveButtonDisabled = updateRoleMutation.isLoading || !isDirty;

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
					>
						<Typography fontSize={17} fontWeight={600}>
							Rename Role
						</Typography>

						<IconButton
							onClick={onClose}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(24, 24, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<Divider />

				<Box
					p={2}
					style={{
						flex: "1 1 auto",
						minHeight: 0,
						overflowY: "auto",
					}}
				>
					<Stack>
						<Stack direction="row" alignItems="center">
							<Typography
								fontSize={14}
								fontWeight={500}
								padding="5px"
								color={
									errors?.role?.message
										? theme.palette.error.main
										: "#000"
								}
							>
								Role Name
							</Typography>
							<span
								style={{
									color: theme.palette.error.main,
								}}
							>
								*
							</span>
						</Stack>
						<TextField
							{...register(formFields.role, {
								...validationConfig,
								onChange: () => clearErrors(formFields.role),
							})}
							error={Boolean(errors?.role)}
						/>
						{errors?.role?.message && (
							<Typography
								sx={{
									color: theme.palette.error.main,
									fontSize: 14,
									fontWeight: 500,
									margin: "5px",
								}}
							>
								{errors.role.message}
							</Typography>
						)}
					</Stack>
				</Box>

				<Divider />

				<Box p={2}>
					<Stack direction="row" spacing={1}>
						<Button
							variant="contained"
							disableElevation
							onClick={handleSubmit(onRenameRole)}
							disabled={isSaveButtonDisabled}
							sx={{
								"&.Mui-disabled": {
									backgroundColor: theme.palette.primary.main,
									color: "rgba(255,255,255, 0.6)",
								},
							}}
						>
							Save
						</Button>

						<Button
							variant="contained"
							color="secondary"
							disableElevation
							onClick={onClose}
							disabled={updateRoleMutation.isLoading}
						>
							Cancel
						</Button>
					</Stack>
				</Box>
			</Box>
		</React.Fragment>
	);
}
