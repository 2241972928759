import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import SingleDatePicker from "../../../../SingleDatePicker";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";
import {
	convertDayjsToUnixTimeStamp,
	convertUnixTimeStampToDayjs,
} from "../../../../../../utils/DateUtils";

export default function DateComponent({ field, inputRef, ...rest }) {
	const { control, clearErrors } = useFormContext();

	const handleDateChange = (date, onChange) => {
		let selectedDate = convertDayjsToUnixTimeStamp(date);
		onChange(selectedDate);
		clearErrors(field.id);
	};

	return (
		<React.Fragment>
			<Controller
				name={field.id + ""}
				control={control}
				rules={handleFieldValidation(field)}
				render={({
					field: { onChange, value, ...renderField },
					fieldState: { error },
				}) => (
					<SingleDatePicker
						placeholder={field?.placeHolder}
						{...renderField}
						{...rest}
						id={field.id + ""}
						onChange={(event) => handleDateChange(event, onChange)}
						value={convertUnixTimeStampToDayjs(value)}
						inputRef={inputRef}
						error={error}
					/>
				)}
				defaultValue=""
			></Controller>
		</React.Fragment>
	);
}
