import SingleTimePicker from "../../../../Elements/SingleTimePicker";

export default function TimeComponent(params) {
	const { field = {}, error, updateFieldValue, value, clearError } = params;

	const handleChange = (timeValue) => {
		updateFieldValue([{ value: timeValue || "" }], field.fieldId);
		clearError(field.fieldId);
	};

	return (
		<>
			<SingleTimePicker
				style={{
					timeFieldStyle: {
						backgroundColor: "#fff",
						borderRadius: 8,
						Width: "100%",
					},
				}}
				error={error?.error}
				value={value ? value.value : ""}
				onChange={handleChange}
			/>
		</>
	);
}
