import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import {
	createTeam,
	deleteTeam,
	getTeamData,
	getTeamUsersList,
	getTeamsList,
	getTeamsTableData,
	updateTeam,
} from "../../../../api/userManagement/teams/teamsApi";
import {
	getAllTeamUsersListKey,
	getTeamDataKey,
	getTeamsListKey,
	getTeamTableDataKey,
	getTeamTableDataWithSortKey,
	getTeamUsersListKey,
} from "../../../../utils/queryKeys/userManagement/teams";
import {
	teamListPageSize,
	teamsDropdownListPageSize,
} from "../../../../utils/queryConstants/userManagement/Team";

const useTeamsTableData = (fieldToSort, enabled) => {
	return useInfiniteQuery({
		queryKey: getTeamTableDataWithSortKey(fieldToSort),
		queryFn: ({ pageParam }) => {
			return getTeamsTableData(fieldToSort, {
				start: pageParam ? (pageParam - 1) * teamListPageSize + 1 : 1,
				limit: teamListPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		enabled: enabled,
	});
};

const selectTeamsList = (teams) => {
	return teams.pages.flatMap((page) => page?.options);
};

const useTeamsList = (searchValue) => {
	return useInfiniteQuery({
		queryKey: getTeamsListKey(searchValue),
		queryFn: ({ pageParam }) => {
			return getTeamsList(
				{
					start: pageParam
						? (pageParam - 1) * teamsDropdownListPageSize + 1
						: 1,
					limit: teamsDropdownListPageSize,
				},
				searchValue
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: selectTeamsList,
	});
};

const useTeamUsersList = ({ teamId, isManager }) => {
	let teamUsersListKey = getTeamUsersListKey(teamId, isManager);
	return useQuery(
		teamUsersListKey,
		() => getTeamUsersList(teamId, isManager),
		{
			select: (data) => data.users,
			enabled: !!teamId,
		}
	);
};

const useTeamData = (teamId) => {
	let teamDataKey = getTeamDataKey(teamId);
	return useQuery(teamDataKey, () => getTeamData(teamId), {
		select: (data) => data.team,
		enabled: !!teamId,
	});
};

const useInvalidateTeamsList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getTeamTableDataKey());
	};
};

const useInvalidateTeamUsersList = (teamId) => {
	let queryClient = useQueryClient();
	let teamUsersListKey = getAllTeamUsersListKey(teamId);
	return () => {
		queryClient.invalidateQueries(teamUsersListKey);
	};
};

const useInvalidateTeamData = (teamId) => {
	let queryClient = useQueryClient();
	let teamDataKey = getTeamDataKey(teamId);
	return () => {
		queryClient.invalidateQueries(teamDataKey);
	};
};

const useCreateTeam = () => {
	let invalidateTeamsList = useInvalidateTeamsList();
	return useMutation(createTeam, {
		onSuccess: () => {
			invalidateTeamsList();
		},
	});
};

const useUpdateTeam = (teamId) => {
	let invalidateTeamsList = useInvalidateTeamsList();
	let invalidateTeamData = useInvalidateTeamData(teamId);
	let invalidatePartialUsersList = useInvalidateTeamUsersList(teamId);
	return useMutation(updateTeam, {
		onSuccess: () => {
			invalidateTeamsList();
			invalidateTeamData();
			invalidatePartialUsersList();
		},
	});
};

const useDeleteTeam = () => {
	let invalidateTeamsList = useInvalidateTeamsList();
	return useMutation(deleteTeam, {
		onSuccess: () => {
			invalidateTeamsList();
		},
	});
};

export {
	useTeamsTableData,
	useTeamUsersList,
	useCreateTeam,
	useUpdateTeam,
	useDeleteTeam,
	useTeamData,
	useTeamsList,
};
