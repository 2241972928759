import React from "react";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import {
	Button,
	Dialog,
	ToggleButtonGroup,
	tooltipClasses,
	OutlinedInput,
	Typography,
	Zoom,
} from "@mui/material";
import { default as MaterialUiTooltip } from "@mui/material/Tooltip/Tooltip";

const styles = () => ({
	menuBar: {
		padding: "0px 24px",
		height: "50px",
	},
	addDialogContainer: {
		width: "40vw",
		minHeight: "100vh",
		backgroundColor: "#fff",
		borderRadius: "10px 0px 0px 10px",
	},
	userProfileContainer: {
		width: "24vw",
		minHeight: "100vh",
		backgroundColor: "#fff",
		borderRadius: "0px 10px 10px 0px",
	},
	mailViewContainer: {
		width: "45vw",
		height: "100vh",
		padding: "62px 8px 8px 8px",
	},
	mailViewCard: {
		backgroundColor: "#fff",
		border: "1px solid rgba(0, 0, 0, 0.1)",
		borderRadius: "10px",
		height: "100%",
		width: "100%",
	},
	detailsContainer: {
		border: "1px solid rgba(0, 0, 0, 0.1)",
		borderRadius: "8px",
		padding: "8px",
		height: "100%",
	},
	summaryHead: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		maxWidth: "126px",
		cursor: "pointer",
	},
	summaryData: {
		width: "150px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
	email_font_bold: {
		fontWeight: "bold",
	},
	email_italic: {
		fontStyle: "italic",
	},
	email_underline: {
		textDecoration: "underline",
	},
	email_strikethrough: {
		textDecoration: "line-through",
	},
	email_quote: {
		borderLeft: "2px solid #D9D9D9",
		marginLeft: 0,
		marginRight: "8px",
		paddingLeft: "8px",
	},
	email_paragraph: {
		margin: "0px",
		marginTop: "4px",
		fontSize: "14px",
	},
	colorPickerSaturation: {
		width: "100%",
		position: "relative",
		height: "200px",
		backgroundImage:
			"linear-gradient(transparent, black), linear-gradient(to right, white, transparent)",
		userSelect: "none",
	},
	colorPickerSaturationCursor: {
		position: "absolute",
		width: "20px",
		height: "20px",
		border: "2px solid #ffffff",
		borderRadius: "50%",
		boxShadow: "0 0 15px #00000026",
		boxSizing: "border-box",
		transform: "translate(-10px, -10px)",
	},
	colorPickerColor: {
		border: "1px solid #ccc",
		borderRadius: "8px",
		width: "48px",
		height: "20px",
	},
	colorPickerHue: {
		width: "100%",
		position: "relative",
		height: "8px",
		backgroundImage:
			"linear-gradient(to right, rgb(255, 0, 0), rgb(255, 255, 0), rgb(0, 255, 0), rgb(0, 255, 255), rgb(0, 0, 255), rgb(255, 0, 255), rgb(255, 0, 0))",
		userSelect: "none",
		borderRadius: "12px",
	},
	colorPickerHueCursor: {
		position: "absolute",
		width: "16px",
		height: "16px",
		border: "2px solid #ffffff",
		borderRadius: "50%",
		boxShadow: "#0003 0 0 0 0.5px",
		boxSizing: "border-box",
		transform: "translate(-10px, -4px)",
	},
});

export const hexToRgba = (hex, opacity = 1) => {
	let c;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split("");
		if (c.length === 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = "0x" + c.join("");
		return (
			"rgba(" +
			[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
			"," +
			opacity +
			")"
		);
	}
	throw new Error("Bad Hex");
};

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
	({ theme }) => ({
		"& .MuiToggleButtonGroup-grouped": {
			margin: theme.spacing(0.5),
			paddingLeft: theme.spacing(1.5),
			paddingRight: theme.spacing(1.5),
			height: "30px",
			border: 0,
			color: "rgba(0, 0, 0, 0.6)",
			textTransform: "capitalize",
			fontSize: "14px",
			fontWeight: 500,
			"&.Mui-disabled": {
				border: 0,
			},
			"&.Mui-selected": {
				backgroundColor: theme.palette.primary.main,
				height: "30px",
				color: "#fff",
				"&:hover": {
					backgroundColor: theme.palette.primary.dark,
				},
			},
			"&:not(:first-of-type)": {
				borderRadius: "5px",
			},
			"&:first-of-type": {
				borderRadius: "5px",
			},
			"&:not(:last-child)": {
				marginRight: theme.spacing(1),
			},
		},
	})
);

export const CustomisedToggleButtonGroup = styled(ToggleButtonGroup)(
	({ theme }) => ({
		"& .MuiToggleButtonGroup-grouped": {
			margin: theme.spacing(0.5),
			border: 0,
			"&.Mui-disabled": {
				border: 0,
			},
			"&:not(:first-of-type)": {
				borderRadius: theme.shape.borderRadius,
			},
			"&:first-of-type": {
				borderRadius: theme.shape.borderRadius,
			},
		},
	})
);

export const StyledDwarfToggleButtonGroup = styled(ToggleButtonGroup)(
	({ theme }) => ({
		"& .MuiToggleButtonGroup-grouped": {
			margin: theme.spacing(0.5),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
			height: "10px",
			border: 0,
			color: "rgba(0, 0, 0, 0.6)",
			textTransform: "capitalize",
			fontSize: "14px",
			fontWeight: 500,
			"&.Mui-disabled": {
				border: 0,
			},
			"&.Mui-selected": {
				backgroundColor: theme.palette.primary.main,
				height: "20px",
				color: "#fff",
				"&:hover": {
					backgroundColor: theme.palette.primary.dark,
				},
			},
			"&:not(:first-of-type)": {
				borderRadius: "5px",
			},
			"&:first-of-type": {
				borderRadius: "5px",
			},
			"&:not(:last-child)": {
				marginRight: theme.spacing(1),
			},
		},
	})
);

export const CustomisedDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(0),
	},
	"& .MuiDialogTitle-root": {
		padding: theme.spacing(0),
	},
	"& .MuiPaper-root": {
		width: "550px",
		borderRadius: "10px",
		position: "absolute",
		top: 40,
	},
}));

export const FullScreenCustomisedDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(0),
	},
	"& .MuiDialogTitle-root": {
		padding: theme.spacing(0),
	},
	"& .MuiPaper-root": {
		borderRadius: "10px",
		position: "absolute",
		top: 0,
		bottom: 0,
		height: "100%",
	},
}));

export const TableHeaderLabel = styled(Typography)(() => ({
	fontSize: "14px",
	fontWeight: 500,
	color: "rgba(0, 0, 0, 0.6)",
}));

export const FormFieldName = styled(Typography)(() => ({
	fontSize: "14px",
	fontWeight: 500,
	paddingLeft: "6px",
}));

export const SummaryFieldName = styled(Typography)(() => ({
	fontSize: "15px",
	fontWeight: 500,
}));

export const TableCellText = styled(Typography)(() => ({
	fontSize: "15px",
}));

export const MenuItemText = styled(Typography)(() => ({
	fontSize: "14px",
	wordBreak: "break-word",
}));

export const ProfileText = styled(Typography)(() => ({
	fontSize: "13px",
	fontWeight: 500,
	cursor: "pointer",
}));

export const DwarfButton = styled(Button)(() => ({
	height: "28px",
	padding: "0px 10px",
}));

export const DealTimelineButton = styled(Button)(() => ({
	height: "35px",
	padding: "12px",
	fontSize: 15,
	fontWeight: 400,
	width: "max-content",
}));

export const TextButton = styled(Button)(() => ({
	height: "auto",
	minWidth: "auto",
	padding: "0px",
}));

export const AddFormTextButton = styled(Button)(() => ({
	height: "auto",
	minWidth: "auto",
	padding: "0px",
	marginRight: "7px",
	marginTop: "-22px",
}));

export const ColoredOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
	backgroundColor: hexToRgba(theme.palette.primary.main, 0.12),
	height: "auto",
	borderRadius: "8px",
	"& .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&:hover .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
}));

export const Tooltip = styled(({ className, ...props }) => (
	<MaterialUiTooltip
		{...props}
		arrow
		classes={{ popper: className }}
		TransitionComponent={Zoom}
	/>
))(() => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: "#272937",
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#272937",
		maxWidth: "410px",
	},
}));

export const twozoStyles = makeStyles((theme) => styles(theme));
export const rawTwozoStyles = styles();
