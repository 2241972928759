import React, { useEffect, useState } from "react";
import {
	Box,
	Divider,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { FormFieldName, MenuItemText } from "../../../../../../styles/twozo";
import MenuSearchBar from "../../../../../Elements/MenuSearchBar";
import { CircularProgress, Skeleton } from "@mui/material";
import { usePhoneNumberList } from "../../../../../../hooks/services/phone";
import { useLastRowRef } from "../../../../../../hooks/common/InfiniteScroll";
import { useDebouncedValue } from "../../../../../../hooks/debounce";
const fieldName = "phoneNumber";

export default function PhoneNumberMenu(props) {
	const { value, onChange, dependentFieldsConfig } = props;
	const theme = useTheme();

	const [searchedValue, setSearchedValue] = useState("");
	const [isMenuListOpened, setIsMenuListOpened] = useState(false);

	const debouncedSearchValue = useDebouncedValue(searchedValue);

	let isPhoneNumberListEnabled =
		dependentFieldsConfig?.countryCode &&
		dependentFieldsConfig?.countryName &&
		dependentFieldsConfig?.stateName &&
		dependentFieldsConfig?.localityName;
	const {
		data: phoneNumberList,
		isLoading: isLoadingPhoneNumberList,
		isFetching: isFetchingPhoneNumberList,
		hasNextPage: hasNextPhoneNumberListPage,
		fetchNextPage: fetchNextPhoneNumberListPage,
	} = usePhoneNumberList(
		dependentFieldsConfig?.countryCode,
		dependentFieldsConfig?.countryName,
		dependentFieldsConfig?.stateName,
		dependentFieldsConfig?.localityName,
		debouncedSearchValue,
		isPhoneNumberListEnabled
	);

	useEffect(() => {
		if (Array.isArray(phoneNumberList) && phoneNumberList.length > 0) {
			const checkIfTheValueMatches = phoneNumberList.some(
				(phoneNumber) => phoneNumber.number === value?.number
			);

			if (!searchedValue && (!value || !checkIfTheValueMatches)) {
				const defaultStateValue = phoneNumberList.find(
					(phoneNumber) => phoneNumber?.isDefault
				);
				onChange(defaultStateValue, fieldName);
			}
		}
	}, [onChange, value, searchedValue, phoneNumberList]);

	const lastOptionRef = useLastRowRef(
		fetchNextPhoneNumberListPage,
		hasNextPhoneNumberListPage,
		isFetchingPhoneNumberList
	);

	const handleSearchValue = (event) => {
		const { value } = event.target;
		setSearchedValue(value);
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					{searchedValue && phoneNumberList?.length === 0
						? "No Results Found"
						: "No Options Found"}
				</Typography>
			</Stack>
		);
	};

	const openMenuList = () => {
		setIsMenuListOpened(true);
		setSearchedValue("");
	};

	const closeMenuList = () => {
		setIsMenuListOpened(false);
	};

	const handleSelectPhoneNumber = (selectedLocality) => {
		onChange(selectedLocality, fieldName);
		closeMenuList();
	};

	const renderValue = () => {
		return value?.number ? (
			<Stack direction="row" alignItems="center" spacing={1.5}>
				<MenuItemText
					whiteSpace="wrap"
					color={theme.palette.secondary.contrastText}
				>
					{value?.price}
				</MenuItemText>

				<Divider
					flexItem
					orientation="vertical"
					style={{ height: "40px" }}
				/>

				<MenuItemText whiteSpace="wrap">
					<span>{value?.number}</span> <span> - </span>{" "}
					<span
						style={{
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						{value?.locality?.name}, {value?.state?.name},{" "}
						{value?.country?.name}
					</span>
				</MenuItemText>
			</Stack>
		) : (
			<Typography fontSize={14} noWrap color={"rgba(0, 0, 0, 0.6)"}>
				Select
			</Typography>
		);
	};

	return (
		<React.Fragment>
			<Stack spacing={0.5}>
				<FormFieldName>Numbers</FormFieldName>

				<Select
					size="small"
					fullWidth
					MenuProps={{
						autoFocus: false,
						PaperProps: {
							style: {
								marginTop: "4px",
								borderRadius: "8px",
								maxHeight: "290px",
								maxWidth: "400px",
							},
						},
					}}
					open={isMenuListOpened}
					onClose={closeMenuList}
					onOpen={openMenuList}
					displayEmpty
					renderValue={renderValue}
				>
					<Box
						p={0.5}
						marginTop="-8px"
						sx={{
							position: "sticky",
							top: 0,
							zIndex: 1,
							backgroundColor: "#fff",
						}}
					>
						<MenuSearchBar
							value={searchedValue}
							onChange={handleSearchValue}
						/>
					</Box>

					{isLoadingPhoneNumberList &&
					dependentFieldsConfig?.countryCode ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="150px"
						>
							<CircularProgress size="25px" />
						</Stack>
					) : phoneNumberList?.length > 0 ? (
						phoneNumberList.map((phoneNumber, index) => (
							<MenuItem
								key={index}
								style={{
									height: "40px",
								}}
								onClick={() =>
									handleSelectPhoneNumber(phoneNumber)
								}
							>
								<MenuItemText whiteSpace="wrap">
									<span>{phoneNumber?.number}</span>{" "}
									<span> - </span>{" "}
									<span
										style={{
											color: "rgba(0, 0, 0, 0.6)",
										}}
									>
										{phoneNumber?.locality?.name},{" "}
										{phoneNumber?.state?.name},{" "}
										{phoneNumber?.country?.name}
									</span>
								</MenuItemText>
							</MenuItem>
						))
					) : (
						renderEmptyView()
					)}

					<Box ref={lastOptionRef}>
						{!isLoadingPhoneNumberList &&
							isFetchingPhoneNumberList &&
							phoneNumberList?.length > 0 && (
								<MenuItem style={{ height: "40px" }}>
									<Skeleton width="190px" height="16px" />
								</MenuItem>
							)}
					</Box>
				</Select>
			</Stack>
		</React.Fragment>
	);
}
