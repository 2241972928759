import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import { default as DropDownRightIcon } from "../../../assets/icons/dropDownRight";
import { default as AddIcon } from "../../../assets/icons/add";
import { default as DropDownIcon } from "../../../assets/icons/dropDownCentered";
import { TableHeaderLabel, twozoStyles } from "../../../styles/twozo";
import { useNavigate } from "react-router-dom";
import MenuSearchBar from "../../Elements/MenuSearchBar";
import PhoneNumberTableRow from "./PhoneNumberTableRow";
import BuyPhoneNumberForm from "./BuyPhoneNumber";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import { usePhoneNumbersTableData } from "../../../hooks/services/phone";
import { useLastRowRef } from "../../../hooks/common/InfiniteScroll";
import { useDebouncedValue } from "../../../hooks/debounce";

const sortTypes = {
	ascending: "asc",
	decending: "desc",
};

export default function PhoneNumbers() {
	const classes = twozoStyles();
	const theme = useTheme();
	const tableRef = useRef();
	const navigate = useNavigate();

	const [tableStartingPosition, setTableStartingPosition] = useState(null);
	const [isPhoneNumberDrawerOpened, setIsPhoneNumberDrawerOpened] =
		useState(false);
	const [searchedValue, setSearchedValue] = useState("");
	const [sortRequestForDisplayName, setSortRequestForDisplayName] = useState({
		field: "displayName",
		type: sortTypes.ascending,
	});
	const isAscendingOrder =
		sortRequestForDisplayName.type === sortTypes.ascending;
	const debouncedSearchValue = useDebouncedValue(searchedValue);

	const {
		data: phoneNumberTableData,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isLoading: isTableLoading,
	} = usePhoneNumbersTableData(
		sortRequestForDisplayName,
		debouncedSearchValue
	);

	const tableHasAnyData = phoneNumberTableData?.length > 0;

	const lastRowRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	useEffect(() => {
		if (tableRef) {
			setTableStartingPosition(
				tableRef.current.getBoundingClientRect().top
			);
		}
	}, []);

	const openBuyPhoneNumberDrawer = () => {
		setIsPhoneNumberDrawerOpened(true);
	};

	const closeBuyPhoneNumberDrawer = () => {
		setIsPhoneNumberDrawerOpened(false);
	};

	const toggleSortByDisplayName = () => {
		if (tableHasAnyData) {
			setSortRequestForDisplayName((sortRequest) => {
				return {
					...sortRequest,
					type:
						sortRequest.type === sortTypes.ascending
							? sortTypes.decending
							: sortTypes.ascending,
				};
			});
		}
	};

	const handleSearch = (event) => {
		const { value } = event.target;
		setSearchedValue(value.trim());
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isPhoneNumberDrawerOpened}
				onOpen={openBuyPhoneNumberDrawer}
				onClose={closeBuyPhoneNumberDrawer}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<BuyPhoneNumberForm onClose={closeBuyPhoneNumberDrawer} />
				</Box>
			</CustomSwipeableDrawer>

			<Box className={classes.menuBar}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
					height="100%"
				>
					<Stack direction="row" alignItems="center" spacing={1}>
						<Box
							style={{ cursor: "pointer" }}
							onClick={() => navigate("/settings")}
						>
							<Typography
								fontWeight={500}
								fontSize={15}
								color={theme.palette.secondary.contrastText}
							>
								Admin Settings
							</Typography>
						</Box>

						{DropDownRightIcon(16, 16)}

						<Typography fontWeight={500} fontSize={15}>
							Phone Numbers
						</Typography>
					</Stack>

					<Box hidden={!tableHasAnyData && !debouncedSearchValue}>
						<Button
							variant="contained"
							disableElevation
							startIcon={AddIcon(20, 20, "#fff")}
							onClick={openBuyPhoneNumberDrawer}
						>
							Buy Number
						</Button>
					</Box>
				</Stack>
			</Box>

			<Box px={3} py={2}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Box>
						<Typography fontSize={17} fontWeight={600}>
							Phone Numbers
						</Typography>

						<Typography fontSize={14} sx={{ opacity: 0.6 }}>
							Once you have numbers, you can start making and
							receiving calls in Twozo.
						</Typography>
					</Box>

					<Box
						sx={{ width: "300px" }}
						hidden={!tableHasAnyData && !debouncedSearchValue}
					>
						<MenuSearchBar
							minHeight="38px"
							value={searchedValue}
							onChange={handleSearch}
						/>
					</Box>
				</Stack>
			</Box>

			<Box
				ref={tableRef}
				sx={{
					border: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
					height: `calc(100vh - ${tableStartingPosition + 8}px)`,
					overflowY: "auto",
				}}
				mx={1}
			>
				<TableContainer sx={{ maxHeight: "100%" }}>
					<Table stickyHeader sx={{ minWidth: 650 }} size="small">
						<TableHead>
							<TableRow sx={{ height: "36px" }}>
								<TableCell
									width="35%"
									onClick={toggleSortByDisplayName}
								>
									<Stack
										direction="row"
										alignItems="center"
										spacing={0.5}
									>
										<TableHeaderLabel>
											Display Name
										</TableHeaderLabel>

										<Box
											hidden={
												!tableHasAnyData &&
												!searchedValue
											}
											style={{
												transform: isAscendingOrder
													? "rotate(0deg)"
													: "rotate(180deg)",
											}}
										>
											{DropDownIcon(
												12,
												12,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Box>
									</Stack>
								</TableCell>

								<TableCell>
									<Stack
										direction="row"
										alignItems="center"
										spacing={0.5}
									>
										<TableHeaderLabel>
											Phone Number
										</TableHeaderLabel>
									</Stack>
								</TableCell>

								<TableCell width="55px" />
							</TableRow>
						</TableHead>

						<TableBody>
							{isTableLoading ? (
								<TableRow
									sx={{
										"&:hover": {
											backgroundColor: "#0000",
										},
										height: "55vh",
									}}
								>
									<TableCell
										colSpan={2}
										sx={{ borderBottom: 0 }}
									>
										<Stack
											height="100%"
											justifyContent="center"
											alignItems="center"
											width="100%"
										>
											<CircularProgress />
										</Stack>
									</TableCell>
								</TableRow>
							) : tableHasAnyData ? (
								phoneNumberTableData?.map(
									(phoneNumberRowData) => (
										<PhoneNumberTableRow
											key={phoneNumberRowData?.id}
											phoneNumberRowData={
												phoneNumberRowData
											}
										/>
									)
								)
							) : (
								<TableRow
									sx={{
										"&:hover": {
											backgroundColor: "#0000",
										},
									}}
								>
									<TableCell
										colSpan={2}
										style={{ borderBottom: "none" }}
									>
										{debouncedSearchValue ? (
											<Stack
												alignItems="center"
												justifyContent="center"
												height="55vh"
											>
												<Typography
													fontSize={14}
													color="rgba(0, 0, 0, 0.6)"
												>
													No Results Found
												</Typography>
											</Stack>
										) : (
											<Stack
												alignItems="center"
												justifyContent="center"
												height="55vh"
												width="100%"
												spacing={2}
											>
												<Typography
													fontWeight={400}
													fontSize={14}
													sx={{ opacity: 0.6 }}
												>
													Initiate the process by
													purchasing a new number.
												</Typography>

												<Button
													variant="contained"
													disableElevation
													startIcon={AddIcon(
														20,
														20,
														"#fff"
													)}
													onClick={
														openBuyPhoneNumberDrawer
													}
												>
													Buy Number
												</Button>
											</Stack>
										)}
									</TableCell>
								</TableRow>
							)}

							<TableRow
								sx={{
									"&:hover": {
										backgroundColor: "#0000",
									},
								}}
							>
								<TableCell
									colSpan={2}
									sx={{ borderBottom: "none" }}
								>
									<Box ref={lastRowRef}>
										{!isTableLoading &&
											isFetching &&
											tableHasAnyData && (
												<Stack
													direction="row"
													alignItems="center"
													justifyContent="center"
													spacing={1}
													py={2}
												>
													<CircularProgress
														size={18}
													/>

													<Typography fontSize={12}>
														Loading More Data
													</Typography>
												</Stack>
											)}
									</Box>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</React.Fragment>
	);
}
