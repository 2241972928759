import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import EmailBaseComponent from "./EmailBaseComponent";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";

export default function EmailComponent({
	field,
	subField,
	inputRef,
	fieldSpecificProps: { valueSx, typeSx } = {},
}) {
	const { control, clearErrors } = useFormContext();

	return (
		<React.Fragment>
			<Controller
				name={field.id + ""}
				control={control}
				rules={handleFieldValidation(field, subField)}
				render={({
					field: { ...renderField },
					fieldState: { error },
				}) => (
					<EmailBaseComponent
						{...renderField}
						subField={subField}
						id={field.id + ""}
						field={field}
						inputRef={inputRef}
						error={error}
						clearErrors={clearErrors}
						valueSx={valueSx}
						typeSx={typeSx}
					/>
				)}
				defaultValue={null}
			></Controller>
		</React.Fragment>
	);
}
