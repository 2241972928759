import React from "react";
import { Button, Stack, Typography } from "@mui/material";

export default function WelcomeScreen() {
	return (
		<React.Fragment>
			<Stack alignItems="center" py={1} spacing={2}>
				<Stack alignItems="center" spacing={0.5}>
					<Typography fontSize={13} fontWeight={500}>
						Welcome to Twozo Caller
					</Typography>

					<Typography fontSize={13}>
						Get started by buying your first phone number
					</Typography>
				</Stack>

				<Button
					variant="contained"
					color="primary"
					disableElevation
					onClick={() => {}} // Need to Open buy phone number drawer
				>
					Buy Number
				</Button>
			</Stack>
		</React.Fragment>
	);
}
