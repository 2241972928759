import { Box, Stack, TextField } from "@mui/material";
import CompanyFieldComponent from "./FieldComponents/CompanyFieldComponent";
import ContactFieldComponent from "./FieldComponents/ContactFieldComponent";
import DateFieldComponent from "./FieldComponents/DateFieldComponent";
import DateRangeFieldComponent from "./FieldComponents/DateRangeFieldComponent";
import MonetaryFieldComponent from "./FieldComponents/MonetaryFieldComponent";
import TimeRangeFieldComponent from "./FieldComponents/TimeRangeFieldComponent";
import DateTimeRangeFieldComponent from "./FieldComponents/DateTimeRangeFieldComponent";
import { useWatch } from "react-hook-form";
import TimeFieldComponent from "./FieldComponents/TimeFieldComponent";
import TerritoryComponent from "../../AddForm/Field/FieldComponents/TerritoryComponent";
import SalesOwnerComponent from "../../AddForm/Field/FieldComponents/SalesOwnerComponent";
import PhoneFieldComponent from "./FieldComponents/PhoneFieldComponent";
import EmailFieldComponent from "./FieldComponents/EmailFieldComponent";
import TextAreaComponent from "../../AddForm/Field/FieldComponents/TextAreaComponent";
import DropdownFieldComponent from "./FieldComponents/DropdownFieldComponent";
import TextFieldComponent from "./FieldComponents/TextFieldComponent";
import MultiSelectFieldComponent from "./FieldComponents/MultiSelectFieldComponent";

const fieldComponents = {
	OWNER: SalesOwnerComponent,
	TIME: TimeFieldComponent,
	DATE: DateFieldComponent,
	CONTACT: ContactFieldComponent,
	CONTACT_ONBOARD: ContactFieldComponent,
	COMPANY: CompanyFieldComponent,
	COMPANY_ONBOARD: CompanyFieldComponent,
	SINGLE_STRING: TextFieldComponent,
	PHONES: PhoneFieldComponent,
	PHONE: PhoneFieldComponent,
	EMAIL: EmailFieldComponent,
	EMAILS: EmailFieldComponent,
	MONETARY: MonetaryFieldComponent,
	SINGLE_NUMBER: TextFieldComponent,
	TEXT: TextAreaComponent,
	TERRITORY: TerritoryComponent,
	DROPDOWN: DropdownFieldComponent,
	MULTI_SELECT: MultiSelectFieldComponent,
	DATE_RANGE: DateRangeFieldComponent,
	TIME_RANGE: TimeRangeFieldComponent,
	DATE_TIME_RANGE: DateTimeRangeFieldComponent,
};

const FieldNotImplemented = () => {
	return (
		<Box sx={{ cursor: "not-allowed", opacity: 0.7 }} width="100%">
			<TextField
				placeholder="Enter Value"
				sx={{ pointerEvents: "none", width: "100%" }}
			/>
		</Box>
	);
};

export default function Field(params) {
	const { field, subFields, isGroupedFieldTop, isGroupedFieldBottom } =
		params;

	const formValues = useWatch();
	const FieldComponent = fieldComponents[field?.type];

	return (
		<>
			<Stack direction="column" spacing={0.5} width="100%">
				{FieldComponent ? (
					<FieldComponent
						field={field}
						values={formValues}
						isGroupedFieldTop={isGroupedFieldTop}
						isGroupedFieldBottom={isGroupedFieldBottom}
						subFields={subFields[field?.type]}
					/>
				) : (
					<FieldNotImplemented />
				)}
			</Stack>
		</>
	);
}
