import React from "react";
import { Stack, Typography } from "@mui/material";

export default function ActivityNoteEdited(props) {
	const { eventData } = props;

	if (!eventData) return;

	return (
		<React.Fragment>
			<Stack pb={1}>
				<Typography>
					<span style={{ fontSize: "15px", fontWeight: 500 }}>
						Activity note edited by
					</span>{" "}
					-{" "}
					<span
						style={{
							fontSize: "15px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						{eventData?.salesOwner?.name}
					</span>
				</Typography>

				<Typography
					fontSize={14}
					fontWeight={500}
					style={{
						wordBreak: "break-word",
					}}
				>
					{eventData?.activityNote}
				</Typography>
			</Stack>
		</React.Fragment>
	);
}
