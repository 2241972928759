import {
	Box,
	Button,
	MenuItem,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import {
	useRules,
	useUpdateRules,
} from "../../../../hooks/services/lifecycleStage";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { enqueueSnackbar } from "notistack";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import MenuSearchBar from "../../../Elements/MenuSearchBar";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { useState, useEffect, useMemo } from "react";
import { DEAL_STAGE_TYPES } from "../LifecycleStageConfig";
import { MenuItemText } from "../../../../styles/twozo";

export default function LifecycleStageRules({ lifecycleStagesList }) {
	// query call:-
	const { data: rulesList } = useRules();

	// mutation call:-
	const updateRule = useUpdateRules();

	const [stageOnDealAdded, setStageOnDealAdded] = useState({
		name: "",
		value: "",
	});
	const [stageOnDealWon, setStageOnDealWon] = useState({
		name: "",
		value: "",
	});

	const [stageOnDealAddedSearchValue, setStageOnDealAddedSearchValue] =
		useState("");
	const [stageOnDealWonSearchValue, setStageOnDealWonSearchValue] =
		useState("");

	useEffect(() => {
		if (Array.isArray(lifecycleStagesList) && Array.isArray(rulesList)) {
			rulesList.forEach(({ type, stageId }) => {
				const matchedRule = lifecycleStagesList.find(
					(stage) => stage.id === stageId
				);
				if (matchedRule) {
					const { name, id } = matchedRule;
					type === DEAL_STAGE_TYPES.DEAL_ADDED
						? setStageOnDealAdded({ name, value: id })
						: setStageOnDealWon({ name, value: id });
				}
			});
		}
	}, [rulesList, lifecycleStagesList]);

	const handleChangeFirstRule = (event, child) => {
		const { value } = event.target;
		setStageOnDealAdded({
			name: child.props.name || "",
			value: value,
		});
	};

	const filteredActiveStageOnDealAddedList = useMemo(() => {
		if (!lifecycleStagesList) return [];

		const searchValue = stageOnDealAddedSearchValue.trim().toLowerCase();

		return lifecycleStagesList.filter(
			(stage) =>
				stage.isActive &&
				(!searchValue || stage.name.toLowerCase().includes(searchValue))
		);
	}, [lifecycleStagesList, stageOnDealAddedSearchValue]);

	const handleSearchFirstRuleValue = (event) => {
		const { value } = event.target;
		setStageOnDealAddedSearchValue(value);
	};

	const handleRemoveStageOnDealAdded = () => {
		setStageOnDealAdded({ name: "", value: "" });
	};

	const handleChangeSecondRule = (event, child) => {
		const { value } = event.target;
		setStageOnDealWon({
			name: child.props.name,
			value: value,
		});
	};

	const filteredActiveStageOnDealWonList = useMemo(() => {
		if (!lifecycleStagesList) return [];

		const searchValue = stageOnDealWonSearchValue.trim().toLowerCase();

		return lifecycleStagesList.filter(
			(stage) =>
				stage.isActive &&
				(!searchValue || stage.name.toLowerCase().includes(searchValue))
		);
	}, [lifecycleStagesList, stageOnDealWonSearchValue]);

	const handleSearchSecondRuleValue = (event) => {
		const { value } = event.target;
		setStageOnDealWonSearchValue(value);
	};

	const handleRemoveStageOnDealWon = () => {
		setStageOnDealWon({ name: "", value: "" });
	};

	const renderEmptyView = (options) => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					{options?.length === 0
						? "No Options Found"
						: "No Results Found"}
				</Typography>
			</Stack>
		);
	};

	const getRulesRequestData = () => {
		const rules = [];

		if (stageOnDealAdded.value) {
			rules.push({
				type: DEAL_STAGE_TYPES.DEAL_ADDED,
				stageId: stageOnDealAdded.value,
			});
		}

		if (stageOnDealWon.value) {
			rules.push({
				type: DEAL_STAGE_TYPES.DEAL_WON,
				stageId: stageOnDealWon.value,
			});
		}

		return rules;
	};

	const handleSaveRules = () => {
		let requestData = getRulesRequestData();
		updateRule.mutate(requestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.lifecycleStageUpdated,
				});
			},
		});
	};

	const isStageOnDealWonCloseIconVisible = (option) => {
		return stageOnDealWon.value === option?.id;
	};

	const isStageOnDealAddCloseIconVisible = (option) => {
		return stageOnDealAdded.value === option?.id;
	};

	return (
		<>
			<Typography fontSize={18} fontWeight={600} py={1}>
				Rules for changing stages
			</Typography>

			<Stack spacing={2} pb={3}>
				<Box>
					<Typography fontSize={13} fontWeight={500} pl={1} pb="6px">
						{"Whenever a deal is added, change contact's stage to"}
					</Typography>

					<Select
						sx={{ width: "530px" }}
						onChange={handleChangeFirstRule}
						displayEmpty
						onOpen={() => setStageOnDealAddedSearchValue("")}
						value={stageOnDealAdded.value}
						MenuProps={{
							autoFocus: false,
						}}
						renderValue={() => (
							<Typography
								noWrap
								fontSize={14}
								color={
									stageOnDealAdded.value
										? "#000"
										: "rgba(0, 0, 0, 0.6)"
								}
							>
								{stageOnDealAdded.name
									? stageOnDealAdded.name
									: "Select"}
							</Typography>
						)}
					>
						<Box
							p={0.5}
							marginTop="-8px"
							sx={{
								position: "sticky",
								top: 0,
								zIndex: 1,
								backgroundColor: "#fff",
							}}
						>
							<MenuSearchBar
								value={stageOnDealAddedSearchValue}
								onChange={handleSearchFirstRuleValue}
							/>
						</Box>

						{filteredActiveStageOnDealAddedList?.length > 0
							? filteredActiveStageOnDealAddedList?.map(
									(option) => (
										<MenuItem
											value={option.id || ""}
											key={option.id}
											name={option?.name || ""}
										>
											<Stack
												direction="row"
												spacing={1}
												alignItems="center"
												justifyContent="space-between"
												width="100%"
											>
												<MenuItemText
													maxWidth="500px"
													style={{
														whiteSpace: "normal",
														overflowWrap:
															"break-word",
													}}
												>
													{option.name}
												</MenuItemText>

												{isStageOnDealAddCloseIconVisible(
													option
												) ? (
													<Box
														display="flex"
														onClick={
															handleRemoveStageOnDealAdded
														}
													>
														{CloseIcon(
															20,
															20,
															"#000",
															0.6
														)}
													</Box>
												) : null}
											</Stack>
										</MenuItem>
									)
								)
							: renderEmptyView()}
					</Select>
				</Box>

				<Box>
					<Typography fontSize={13} fontWeight={500} pl={1} pb="6px">
						{"Whenever a deal is won, change contact's stage to"}
					</Typography>

					<Select
						sx={{ width: "530px" }}
						onChange={handleChangeSecondRule}
						displayEmpty
						onOpen={() => setStageOnDealWonSearchValue("")}
						value={stageOnDealWon.value}
						MenuProps={{
							autoFocus: false,
						}}
						renderValue={() => (
							<Typography
								noWrap
								fontSize={14}
								color={
									stageOnDealWon.value
										? "#000"
										: "rgba(0, 0, 0, 0.6)"
								}
							>
								{stageOnDealWon.name
									? stageOnDealWon.name
									: "Select"}
							</Typography>
						)}
					>
						<Box
							p={0.5}
							marginTop="-8px"
							sx={{
								position: "sticky",
								top: 0,
								zIndex: 1,
								backgroundColor: "#fff",
							}}
						>
							<MenuSearchBar
								value={stageOnDealWonSearchValue}
								onChange={handleSearchSecondRuleValue}
							/>
						</Box>

						{filteredActiveStageOnDealWonList?.length > 0
							? filteredActiveStageOnDealWonList?.map(
									(option) => (
										<MenuItem
											value={option.id || ""}
											key={option.id}
											name={option?.name || ""}
										>
											<Stack
												direction="row"
												spacing={1}
												alignItems="center"
												justifyContent="space-between"
												width="100%"
											>
												<MenuItemText>
													{option.name}
												</MenuItemText>

												{isStageOnDealWonCloseIconVisible(
													option
												) ? (
													<Box
														display="flex"
														onClick={
															handleRemoveStageOnDealWon
														}
													>
														{CloseIcon(
															20,
															20,
															"#000",
															0.6
														)}
													</Box>
												) : null}
											</Stack>
										</MenuItem>
									)
								)
							: renderEmptyView()}
					</Select>
				</Box>
			</Stack>

			<Stack
				direction="row"
				justifyContent="flex-start"
				spacing={2}
				pb={3}
			>
				<Button
					variant="contained"
					disableElevation
					onClick={handleSaveRules}
					disabled={updateRule.isLoading}
				>
					Save
				</Button>

				<Button variant="contained" color="secondary" disableElevation>
					Cancel
				</Button>
			</Stack>
		</>
	);
}
