import React, { useRef, useState } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { twozoStyles } from "../../styles/twozo";
import SettingsMenu from "./Menu";
import MenuSearchBar from "../Elements/MenuSearchBar";
import { useEffect } from "react";
import NavigationBar from "./NavigationBar";
import { useSettingsMenu } from "../../hooks/settings/adminSettings/index.js";
const drawerWidth = 250;

export default function Settings() {
	const classes = twozoStyles();

	const settingsMenuList = useSettingsMenu();

	const topBarRef = useRef();
	const [topStartingPosition, setTopBarStartingPosition] = useState(0);

	useEffect(() => {
		if (topBarRef) {
			setTopBarStartingPosition(
				topBarRef.current?.getBoundingClientRect().top
			);
		}
	}, []);

	return (
		<React.Fragment>
			<Box>
				<Box
					className={classes.menuBar}
					sx={{
						position: "sticky",
						backgroundColor: "#fff",
						zIndex: 1,
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						height="100%"
					>
						<Box>
							<Typography fontWeight={600}>
								Admin Settings
							</Typography>
						</Box>

						<Box width="300px">
							<MenuSearchBar />
						</Box>
					</Stack>
				</Box>

				<Divider />

				<Box
					ref={topBarRef}
					sx={{
						display: "flex",
						height: `calc(100vh - ${topStartingPosition}px)`,
					}}
				>
					<Box
						sx={{
							width: drawerWidth,
							overflowY: "auto",
							padding: "16px 0px 16px 8px",
						}}
					>
						<NavigationBar settingsMenuList={settingsMenuList} />
					</Box>

					<Box
						sx={{
							flexGrow: 1,
							width: `calc(100% - ${drawerWidth}px)`,
							overflowY: "auto",
							border: "1px solid rgba(0, 0, 0, 0.1)",
							borderRadius: "8px",
							margin: "8px",
						}}
					>
						<Stack spacing={4} px={2} py={1}>
							{settingsMenuList?.map((settingMenuData) => (
								<Stack
									key={settingMenuData.id}
									spacing={1}
									pt={1}
									ref={settingMenuData.ref}
								>
									<Typography fontSize={17} fontWeight={500}>
										{settingMenuData.name}
									</Typography>

									<Box>
										{settingMenuData.hasSubSettings ? (
											<Stack spacing={3} pt={3}>
												{settingMenuData.settings.map(
													(
														subSettingsData,
														index
													) => (
														<SettingsMenu
															key={index}
															{...subSettingsData.subSettings}
														/>
													)
												)}
											</Stack>
										) : (
											<SettingsMenu
												{...settingMenuData}
											/>
										)}
									</Box>
								</Stack>
							))}
						</Stack>
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	);
}
