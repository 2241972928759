import {
	useInfiniteQuery,
	useMutation,
	useQueryClient,
	useQuery,
} from "@tanstack/react-query";
import {
	buyPhoneNumber,
	deletePhoneNumber,
	getAvailablePhoneNumberList,
	getContactPhoneNumberList,
	getCountryList,
	getLocalityList,
	getPhoneCallToken,
	getPhoneNumberList,
	getPhoneNumbersTableData,
	getStateList,
	updatePhoneNumber,
} from "../../../api/phone/phoneApi";
import {
	availablePhoneNumberListPageSize,
	contactPhoneNumberListPageSize,
	countryDropdownListPageSize,
	localityDropdownListPageSize,
	phoneNumberDropdownListPageSize,
	phoneNumberTableListPageSize,
	stateDropdownListPageSize,
} from "../../../utils/queryConstants/phone";
import {
	getAvailablePhoneNumberListKey,
	getContactPhoneNumberListKey,
	getCountryDropdownKey,
	getLocalityDropdownKey,
	getPhoneNumberDropdownKey,
	getPhoneNumbersTableDataWithSortKey,
	getStateDropdownKey,
	phoneCallTokenKey,
	phoneNumbersTableDataKey,
} from "../../../utils/queryKeys/phone";

const availablePhoneNumberListSelect = (phoneNumberList) => {
	return phoneNumberList.pages.flatMap((page) => page?.options);
};

const useAvailablePhoneNumberList = () => {
	return useInfiniteQuery({
		queryKey: getAvailablePhoneNumberListKey(),
		queryFn: ({ pageParam }) => {
			return getAvailablePhoneNumberList({
				start: pageParam
					? (pageParam - 1) * availablePhoneNumberListPageSize + 1
					: 1,
				limit: availablePhoneNumberListPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: availablePhoneNumberListSelect,
	});
};

const contactPhoneNumberSelect = (phoneNumberList) => {
	return phoneNumberList.pages.flatMap((page) => page?.options);
};

const useContactPhoneNumberList = (searchValue) => {
	return useInfiniteQuery({
		queryKey: getContactPhoneNumberListKey(searchValue),
		queryFn: ({ pageParam }) => {
			return getContactPhoneNumberList(
				{
					start: pageParam
						? (pageParam - 1) * contactPhoneNumberListPageSize + 1
						: 1,
					limit: contactPhoneNumberListPageSize,
				},
				searchValue
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: contactPhoneNumberSelect,
	});
};

const useGetCallToken = () => {
	return useQuery(phoneCallTokenKey, () => getPhoneCallToken(), {
		select: (data) => data.token,
	});
};

const phoneNumberListSelect = (tags) => {
	return tags?.pages?.flatMap((page) =>
		page?.list?.length > 0 ? page?.list : []
	);
};

const usePhoneNumbersTableData = (fieldToSort, searchValue) => {
	return useInfiniteQuery({
		queryKey: getPhoneNumbersTableDataWithSortKey(fieldToSort, searchValue),
		queryFn: ({ pageParam }) => {
			return getPhoneNumbersTableData(
				{
					start: pageParam
						? (pageParam - 1) * phoneNumberTableListPageSize + 1
						: 1,
					limit: phoneNumberTableListPageSize,
				},
				fieldToSort,
				searchValue
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: phoneNumberListSelect,
	});
};

const countrySelect = (countryList) => {
	return countryList.pages.flatMap((page) => page?.options);
};

const useCountryList = (searchValue) => {
	return useInfiniteQuery({
		queryKey: getCountryDropdownKey(searchValue),
		queryFn: ({ pageParam }) => {
			return getCountryList(
				{
					start: pageParam
						? (pageParam - 1) * countryDropdownListPageSize + 1
						: 1,
					limit: countryDropdownListPageSize,
				},
				searchValue
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: countrySelect,
	});
};

const stateSelect = (stateList) => {
	return stateList.pages.flatMap((page) => page?.options);
};

const useStateList = (countryId, searchValue) => {
	return useInfiniteQuery({
		queryKey: getStateDropdownKey(countryId, searchValue),
		queryFn: ({ pageParam }) => {
			return getStateList(
				countryId,
				{
					start: pageParam
						? (pageParam - 1) * stateDropdownListPageSize + 1
						: 1,
					limit: stateDropdownListPageSize,
				},
				searchValue
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		enabled: Boolean(countryId),
		select: stateSelect,
	});
};

const localitySelect = (stateList) => {
	return stateList.pages.flatMap((page) => page?.options);
};

const useLocalityList = (countryId, stateId, searchValue) => {
	return useInfiniteQuery({
		queryKey: getLocalityDropdownKey(countryId, stateId, searchValue),
		queryFn: ({ pageParam }) => {
			return getLocalityList(
				countryId,
				stateId,
				{
					start: pageParam
						? (pageParam - 1) * localityDropdownListPageSize + 1
						: 1,
					limit: localityDropdownListPageSize,
				},
				searchValue
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		enabled: Boolean(countryId),
		select: localitySelect,
	});
};

const phoneNumberSelect = (numberList) => {
	return numberList.pages.flatMap((page) => page?.options);
};

const usePhoneNumberList = (
	countryCode,
	countryName,
	stateName,
	localityName,
	searchValue,
	isPhoneNumberListEnabled
) => {
	return useInfiniteQuery({
		queryKey: getPhoneNumberDropdownKey(
			countryCode,
			countryName,
			stateName,
			localityName,
			searchValue
		),
		queryFn: ({ pageParam }) => {
			return getPhoneNumberList(
				countryCode,
				countryName,
				stateName,
				localityName,
				{
					start: pageParam
						? (pageParam - 1) * phoneNumberDropdownListPageSize + 1
						: 1,
					limit: phoneNumberDropdownListPageSize,
				},
				searchValue
			);
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		enabled: Boolean(isPhoneNumberListEnabled),
		select: phoneNumberSelect,
	});
};

const useInvalidatePhoneNumbersTableList = () => {
	let queryClient = useQueryClient();

	return () => {
		queryClient.invalidateQueries(phoneNumbersTableDataKey);
	};
};

// Buy Phone number mutation
const useBuyPhoneNumber = () => {
	const invalidatePhoneNumbersTableList =
		useInvalidatePhoneNumbersTableList();
	return useMutation(buyPhoneNumber, {
		onSuccess: () => {
			invalidatePhoneNumbersTableList();
		},
	});
};

// Delete Phone number mutation
const useDeletePhoneNumber = () => {
	const invalidatePhoneNumbersTableList =
		useInvalidatePhoneNumbersTableList();
	return useMutation(deletePhoneNumber, {
		onSuccess: () => {
			invalidatePhoneNumbersTableList();
		},
	});
};

// Update Phone number mutation
const useUpdatePhoneNumber = () => {
	const invalidatePhoneNumbersTableList =
		useInvalidatePhoneNumbersTableList();
	return useMutation(updatePhoneNumber, {
		onSuccess: () => {
			invalidatePhoneNumbersTableList();
		},
	});
};

export {
	usePhoneNumbersTableData,
	useCountryList,
	useStateList,
	useLocalityList,
	usePhoneNumberList,
	useBuyPhoneNumber,
	useUpdatePhoneNumber,
	useDeletePhoneNumber,
	useAvailablePhoneNumberList,
	useContactPhoneNumberList,
	useGetCallToken,
};
