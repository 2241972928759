import React from "react";
import { TextField } from "@mui/material";
import { phoneRegexPattern } from "../../../WorkflowConfig/config";

export default function PhoneComponent(props) {
	const {
		style = {},
		field = {},
		updateFieldValue,
		error,
		value,
		clearError,
		...rest
	} = props;

	const handleChange = (e) => {
		let { value } = e.target;
		value = value.trimStart().replace(phoneRegexPattern, "");
		updateFieldValue([{ value: value || "" }], field.fieldId);
		clearError(field.fieldId);
	};

	return (
		<React.Fragment>
			<TextField
				{...rest}
				value={value?.value || ""}
				placeholder="-Enter-"
				type="tel"
				inputProps={{
					style: {
						fontSize: "15px",
					},
				}}
				sx={{
					backgroundColor: "#fff",
					borderRadius: 2,
					"& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button":
						{
							"-webkit-appearance": "none",
						},
					...style,
				}}
				onChange={handleChange}
				error={error?.error}
				fullWidth
			/>
		</React.Fragment>
	);
}
