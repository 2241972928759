import React from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";

export default function FileUploaded(props) {
	const { eventData } = props;
	const theme = useTheme();

	if (!eventData) return;

	const handleDownloadFile = (fileId) => {
		window.location.href = `/api/file/download?fileId=${fileId}`;
	};

	return (
		<React.Fragment>
			<Stack pb={1}>
				<Typography fontSize={15}>
					<span style={{ fontSize: "15px", fontWeight: 500 }}>
						File {eventData?.action} by
					</span>{" "}
					<span
						style={{
							fontSize: "15px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						{eventData?.salesOwner?.name}
					</span>
				</Typography>

				<Box
					onClick={() => handleDownloadFile(eventData?.attachmentId)}
					style={{
						cursor: "pointer",
						width: "fit-content",
					}}
				>
					<Typography
						fontSize={14}
						fontWeight={500}
						color={theme.palette.secondary.contrastText}
						style={{
							wordBreak: "break-word",
						}}
					>
						{eventData?.file}
					</Typography>
				</Box>
			</Stack>
		</React.Fragment>
	);
}
