import React, { useState } from "react";
import {
	Box,
	MenuItem,
	Paper,
	Popper,
	Skeleton,
	Stack,
	TextField,
} from "@mui/material";
import { default as MuiAutocomplete } from "@mui/material/Autocomplete";
import { useDebouncedCallback } from "use-debounce";
import { MenuItemText } from "../../../../../styles/twozo";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";
import { useContactPhoneNumberList } from "../../../../../hooks/services/phone";

const CustomPopper = function (props) {
	return <Popper {...props} style={{ ...props.style, padding: "4px 0px" }} />;
};

const CustomPaper = function (props) {
	return (
		<Paper
			{...props}
			style={{
				...props.style,
				borderRadius: "8px",
				boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
				maxWidth: "250px",
			}}
		/>
	);
};

export default function PhoneNumberInput(props) {
	const { onChange, value } = props;

	const [phoneNumberInputValue, setPhoneNumberInputValue] = useState("");

	const {
		data: phoneNumberList,
		isLoading: isLoadingPhoneNumbers,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useContactPhoneNumberList(phoneNumberInputValue);

	const lastOptionRef = useLastRowRef(fetchNextPage, hasNextPage, isFetching);

	const onBlurPhoneNumberInput = () => {
		setPhoneNumberInputValue("");
	};

	const onInputChange = (event) => {
		const { value } = event.target;
		setPhoneNumberInputValue(value?.trimStart());

		onChange({ displayName: "", number: value?.trimStart() });
	};

	const onPhoneNumberInputChange = useDebouncedCallback(
		(event) => {
			onInputChange(event);
		},
		500 // delay in ms
	);

	const renderOption = (option) => {
		return (
			<MenuItem
				style={{
					minHeight: "40px",
					width: "100%",
				}}
			>
				<Stack>
					<MenuItemText noWrap maxWidth="200px">
						{option.displayName}
					</MenuItemText>

					<MenuItemText color={"rgba(0, 0, 0, 0.6)"}>
						{option?.number}
					</MenuItemText>
				</Stack>
			</MenuItem>
		);
	};

	const CustomListboxComponent = function (props) {
		return (
			<Box {...props} style={{ maxHeight: "125px" }}>
				{props.children}

				<Box ref={lastOptionRef}>
					{!isLoadingPhoneNumbers && isFetching && hasNextPage ? (
						<MenuItem
							style={{
								height: "40px",
								width: "100%",
							}}
						>
							<Skeleton width="200px" height="16px" />
						</MenuItem>
					) : null}
				</Box>
			</Box>
		);
	};

	const handleSelectPhoneNumber = (_, value) => {
		let shouldShowDialPad = true;
		onChange(value, shouldShowDialPad);
		setPhoneNumberInputValue("");
	};

	return (
		<MuiAutocomplete
			size="small"
			freeSolo
			fullWidth
			value={value}
			onChange={handleSelectPhoneNumber}
			open={Boolean(phoneNumberInputValue)}
			options={phoneNumberList || []}
			PopperComponent={CustomPopper}
			PaperComponent={CustomPaper}
			disableClearable
			getOptionLabel={(option) => {
				return option?.number || "";
			}}
			renderOption={(props, option) => {
				return (
					<Box {...props} key={option?.id} style={{ padding: "0px" }}>
						{renderOption(option)}
					</Box>
				);
			}}
			ListboxComponent={CustomListboxComponent}
			renderInput={(params) => (
				<TextField
					{...params}
					placeholder={"Enter name or number"}
					value={phoneNumberInputValue}
					onChange={onPhoneNumberInputChange}
					onBlur={onBlurPhoneNumberInput}
					inputProps={{
						...params.inputProps,
						style: {
							...params.inputProps?.style,
							fontSize: "14px",
							color: "#fff",
							paddingLeft: "12px",
						},
					}}
					InputProps={{
						...params.InputProps,
					}}
					sx={{
						"& .MuiInputBase-root": {
							backgroundColor: "#323441",
							borderRadius: "8px",
						},
						"& .MuiOutlinedInput-notchedOutline": {
							borderRadius: "8px",
						},
						"& .MuiOutlinedInput-root": {
							"& fieldset": {
								border: "none",
							},
							"&:hover fieldset": {
								border: "none",
							},
							"&.Mui-focused fieldset": {
								border: "none",
							},
						},
					}}
				/>
			)}
		/>
	);
}
