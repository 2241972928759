import { Box, IconButton, Stack, Typography } from "@mui/material";
import { default as CallIcon } from "../../../../assets/icons/call";
import { default as MailIcon } from "../../../../assets/icons/mail";
import { default as ContactIcon } from "../../../../assets/icons/contact";
import React from "react";
import { styles } from "../../styles";

export default function ContactMenu(props) {
	const { contactDetails } = props;

	return (
		<React.Fragment>
			<Box maxWidth="320px">
				<Stack direction="row" spacing={2} p={2}>
					<Box>
						<IconButton size="small">
							{ContactIcon(25, 25, "#000", 0.6)}
						</IconButton>
					</Box>

					<Stack spacing={1.5} width="80%">
						<Stack spacing={0.5}>
							<Typography
								noWrap
								style={styles.cardTitle}
								width="100%"
							>
								{contactDetails?.name}
							</Typography>

							{contactDetails?.company && (
								<Typography
									noWrap
									width="100%"
									style={styles.text}
								>
									{contactDetails.company.name}
								</Typography>
							)}
						</Stack>

						<Stack spacing={0.5}>
							{contactDetails?.phones && (
								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
								>
									{CallIcon(16, 16, "#000000", 0.6)}

									<Typography style={styles.text}>
										{contactDetails.phones.number}{" "}
										{contactDetails.phones?.count &&
											"+" + contactDetails.phones?.count}
									</Typography>
								</Stack>
							)}

							{contactDetails?.emails && (
								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
								>
									{MailIcon(16, 16, "#000000", 0.6)}

									<Typography
										style={styles.text}
										maxWidth="200px"
										noWrap
									>
										{contactDetails.emails.value}{" "}
									</Typography>

									<Typography style={styles.text}>
										{contactDetails.emails?.count &&
											"+" + contactDetails.emails?.count}
									</Typography>
								</Stack>
							)}
						</Stack>
					</Stack>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
