import { Typography, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import React, {
	forwardRef,
	useCallback,
	useImperativeHandle,
	useEffect,
	useState,
} from "react";
import { Controller } from "react-hook-form";
import { handleFieldValidation } from "../../../../utils/fieldValidation";
import TagsInput from "../../AddForm/Field/FieldComponents/TagsComponent/TagsBaseComponent/TagsInput";

const textFieldSx = {
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			border: "none",
		},
		"&:hover fieldset": {
			border: "none",
		},
		"&.Mui-focused fieldset": {
			border: "none",
		},
	},
};

const TagFieldEditForm = forwardRef(
	({ value, field, onError, onSave, onClose }, ref) => {
		const theme = useTheme();
		const fieldId = field?.fieldId;

		const {
			handleSubmit,
			formState: { errors },
			control,
			setValue,
			clearErrors,
		} = useForm({ values: { [fieldId]: value } });

		const [selectedTags, setSelectedTags] = useState([]);

		useEffect(() => {
			// This useEffect is used to handle the edit case only:
			// If `value` is an object with a `tag` property, we assume it's valid data from the server or edit case,

			const isValidServerSideData = () => {
				return (
					Array.isArray(value) &&
					value.some((tagData) => tagData?.tag)
				);
			};

			if (isValidServerSideData()) {
				setSelectedTags(
					value.map((tagData) => {
						return { name: tagData?.tag, value: tagData?.id };
					})
				);
				setValue(
					fieldId + "",
					value.map((tag) => {
						return {
							value: tag.id,
						};
					})
				);
			}
		}, [value, setValue, fieldId]);

		const handleSelectTags = (values, onChange) => {
			setSelectedTags(values);
			let tagsForRequest = values?.map((selectedTag) => {
				if (selectedTag?.value) {
					return { value: selectedTag.value };
				} else {
					return { tag: selectedTag?.name };
				}
			});
			onChange(tagsForRequest);
			clearErrors(fieldId + "");
			onError(null);
		};

		const submitForm = useCallback(() => {
			return handleSubmit(
				(data) => {
					onSave(data, onClose);
					onError(null);
					return data;
				},
				(errors) => {
					if (errors[fieldId]) {
						onError(errors[fieldId]);
					}
				}
			)();
		}, [handleSubmit, fieldId, onError, onSave, onClose]);

		useImperativeHandle(ref, () => ({
			submitForm,
		}));

		return (
			<>
				<Controller
					control={control}
					name={fieldId + ""}
					defaultValue=""
					rules={handleFieldValidation(field)}
					render={({ field: { onChange, ...fieldData } }) => (
						<TagsInput
							{...fieldData}
							value={selectedTags}
							onChange={(data) =>
								handleSelectTags(data, onChange)
							}
							placeHolder={field?.placeHolder}
							textFieldSx={textFieldSx}
							isTagAdditionAllowed={false}
						/>
					)}
				></Controller>

				{errors[fieldId] ? (
					<Typography
						pl={1}
						pb={1.2}
						style={{
							fontSize: 13,
							marginLeft: "6px",
							color: theme.palette.error.main,
						}}
					>
						{errors[fieldId]?.message}
					</Typography>
				) : null}
			</>
		);
	}
);

TagFieldEditForm.displayName = "TagFieldEditForm";

export default TagFieldEditForm;
