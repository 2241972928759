import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { default as DialPadIcon } from "../../../../assets/icons/dialPad";
import { default as CallIcon } from "../../../../assets/icons/call";
import PhoneNumberInput from "./PhoneNumberInput/Index";
import RecentCalls from "./RecentCalls";
import { FormFieldName } from "../../../../styles/twozo";
import DialPad from "./DialPad";

export default function Dialer(props) {
	const { makeOutgoingCall } = props;

	const [isDialPadOpened, setIsDialPadOpened] = useState(false);
	const [phoneNumberData, setPhoneNumberData] = useState({
		displayName: "",
		number: "",
	});

	const openDialPad = () => {
		setIsDialPadOpened(true);
	};

	const onSelectContactPhoneNumber = (
		selectedContactPhoneNumber,
		shouldShowDialPad
	) => {
		setPhoneNumberData(
			selectedContactPhoneNumber
				? selectedContactPhoneNumber
				: {
						displayName: "",
						number: "",
					}
		);

		shouldShowDialPad && openDialPad();
	};

	const onDialPadNumberSelect = (selectedNumber) => {
		setPhoneNumberData((phoneNumberData) => {
			let { displayName, number } = phoneNumberData;
			return {
				displayName: displayName ? displayName : "",
				number: (number += selectedNumber),
			};
		});
	};

	return (
		<React.Fragment>
			<Box>
				<FormFieldName pb={0.5}>Number</FormFieldName>

				<PhoneNumberInput
					onChange={onSelectContactPhoneNumber}
					value={phoneNumberData}
				/>
			</Box>

			<Box pt={0.5}>
				{isDialPadOpened ? (
					<DialPad handleNumberClick={onDialPadNumberSelect} />
				) : (
					<RecentCalls />
				)}
			</Box>

			<Box pt={2}>
				<Stack alignItems="center">
					{isDialPadOpened ? (
						<Button
							variant="contained"
							color="primary"
							disableElevation
							onClick={() => makeOutgoingCall(phoneNumberData)}
						>
							<Stack
								spacing={1.5}
								direction="row"
								alignItems="center"
							>
								{CallIcon(20, 20, "#fff")}

								<Typography fontSize={13} fontWeight={600}>
									Call
								</Typography>
							</Stack>
						</Button>
					) : (
						<Button
							variant="contained"
							color="primary"
							disableElevation
							onClick={openDialPad}
						>
							{DialPadIcon(20, 20, "#fff")}
						</Button>
					)}
				</Stack>
			</Box>
		</React.Fragment>
	);
}
