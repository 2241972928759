import React, { useState } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { default as ActivityIcon } from "../../../assets/icons/activity";
import { twozoStyles } from "../../../styles/twozo";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import AddActivity from "../../Summary/Activities/AddActivity";

export default function NoActivityFound() {
	const classes = twozoStyles();
	const theme = useTheme();
	const [isAddActivityDialogOpened, setIsAddActivityDialogOpened] =
		useState(false);

	const toggleAddActivityDialog = () => {
		setIsAddActivityDialogOpened(
			(openAddActivityDialog) => !openAddActivityDialog
		);
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddActivityDialogOpened}
				onOpen={toggleAddActivityDialog}
				onClose={toggleAddActivityDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddActivity onClose={toggleAddActivityDialog} />
				</Box>
			</CustomSwipeableDrawer>

			<Stack
				alignItems="center"
				justifyContent="center"
				style={{
					position: "absolute",
					top: "45%",
					left: "42%",
				}}
			>
				<Box pb={1} sx={{ display: "flex" }}>
					{ActivityIcon(37, 37, "#666666")}
				</Box>

				<Typography
					fontSize={17}
					fontWeight={400}
					color="rgba(0, 0, 0, 0.6)"
				>
					No Activities Found
				</Typography>

				<Typography fontSize={14} color="rgba(0, 0, 0, 0.6)">
					Try resetting your filters or{" "}
					<span
						onClick={toggleAddActivityDialog}
						style={{ color: theme.palette.primary.main }}
					>
						Create new activity
					</span>
				</Typography>
			</Stack>
		</React.Fragment>
	);
}
