import { Box, Divider, Stack, Switch, Typography } from "@mui/material";
import React, { useState } from "react";
import ConfirmDialog from "../../../Elements/ConfirmDialog";
import { useUpdateCurrencyStatus } from "../../../../hooks/services/currency";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";

export default function CurrencyList(props) {
	const { currencyData } = props;
	const successNotificationTitle = "Success!";

	const styles = {
		text: {
			fontSize: "15px",
		},
	};

	const currencyHeadings = [
		"Status",
		"Currency",
		"Symbol",
		"Current conversion Rate",
	];

	const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
	const [currencyToChangeStatus, setCurrencyToChangeStatus] = useState({});
	const updateCurrencyStatus = useUpdateCurrencyStatus();

	const handleDeactivateCurrency = (currencyToChangeStatus) => {
		const request = {
			id: currencyToChangeStatus.id,
			isEnabled: !currencyToChangeStatus.isEnabled,
		};
		updateCurrencyStatus.mutate(request, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					title: successNotificationTitle,
					message: notificationMessage.currencyDeactivated,
				});
				setIsDeactivateDialogOpen(false);
			},
			onError: (error) => {
				error = JSON.parse(error?.message);
				let errorMessage = error?.message;

				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const handleCloseDeactivateDialog = () => {
		setIsDeactivateDialogOpen(false);
	};

	const handleCancelDeactivate = () => {
		handleCloseDeactivateDialog();
	};

	const handleChangeStatus = (currency) => {
		setCurrencyToChangeStatus(currency);
		setIsDeactivateDialogOpen(currency.isEnabled);
		if (!currency.isEnabled) {
			const request = { id: currency.id, isEnabled: !currency.isEnabled };
			updateCurrencyStatus.mutate(request, {
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.currencyActivated,
					});
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.errorMessage,
					});
				},
			});
		}
	};

	return (
		<React.Fragment>
			<ConfirmDialog
				open={isDeactivateDialogOpen}
				title="Are you sure you want to deactivate this currency?"
				subtitle="Deactivating this currency will retain in the existing records but won't be available when you edit or create a record"
				onCancel={handleCancelDeactivate}
				onConfirm={() =>
					handleDeactivateCurrency(currencyToChangeStatus)
				}
				confirmButtonText="Deactivate"
				cancelButtonText="Cancel"
			/>

			{currencyData?.currency?.list ? (
				<>
					<Stack direction="row" width="100%" px={3} py={1}>
						{currencyHeadings.map((heading, index) => (
							<Box key={index} width="33.3%">
								<Typography
									fontSize={14}
									fontWeight={500}
									style={{
										opacity: 0.6,
									}}
								>
									{heading}
								</Typography>
							</Box>
						))}
					</Stack>

					<Divider />

					{currencyData?.currency?.list.map((currency) => (
						<Stack key={currency.id}>
							<Stack direction="row" width="100%" px={3} py={1}>
								<Box width="33.3%">
									<Switch
										checked={currency.isEnabled}
										onChange={() =>
											handleChangeStatus(currency)
										}
									/>
								</Box>

								<Box width="33.3%">
									<Typography
										style={{
											...styles.text,
											opacity: 0.9,
										}}
									>
										{currency.currency.name} (
										{currency.currency.currencyCode})
									</Typography>
								</Box>

								<Box width="33.3%">
									<Typography
										style={{
											...styles.text,
											opacity: 0.9,
										}}
									>
										{currency.symbol}
									</Typography>
								</Box>

								<Box width="33.3%">
									<Typography style={{ ...styles.text }}>
										{
											currency.exchangeRate.baseCurrency
												.value
										}{" "}
										{
											currency.exchangeRate.baseCurrency
												.currencyCode
										}{" "}
										={" "}
										{
											currency.exchangeRate.targetCurrency
												.value
										}{" "}
										{
											currency.exchangeRate.targetCurrency
												.currencyCode
										}
									</Typography>
								</Box>
							</Stack>

							<Divider />
						</Stack>
					))}
				</>
			) : null}
		</React.Fragment>
	);
}
