import React from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { default as CallIcon } from "../../../../assets/icons/call";
import { default as DialPadIcon } from "../../../../assets/icons/dialPad";
import { default as NoteIcon } from "../../../../assets/icons/note";
import { default as RecordIcon } from "../../../../assets/icons/record";
import { default as MuteIcon } from "../../../../assets/icons/mute";
import { default as HoldIcon } from "../../../../assets/icons/hold";
import { default as OutgoingCallIcon } from "../../../../assets/icons/outgoingCall";
import Call from "../../../../assets/images/phone/call.png";

export default function CallerScreen(props) {
	const {
		incomingCallConnection,
		isOutgoingCallInitiated,
		isCallInprogress,
		rejectCall,
		outgoingPhoneNumber,
	} = props;
	const theme = useTheme();

	const callActions = [
		{
			id: 1,
			name: "Dialpad",
			icon: DialPadIcon,
			action: () => {},
		},
		{
			id: 2,
			name: "Note",
			icon: NoteIcon,
			action: () => {},
		},
		{
			id: 3,
			name: "Record",
			icon: RecordIcon,
			action: () => {},
		},
		{
			id: 4,
			name: "Mute",
			icon: MuteIcon,
			action: () => {},
		},
		{
			id: 5,
			name: "Hold",
			icon: HoldIcon,
			action: () => {},
		},
		{
			id: 6,
			name: "Transfer",
			icon: OutgoingCallIcon,
			action: () => {},
		},
	];

	return (
		<React.Fragment>
			<Stack spacing={2}>
				{isOutgoingCallInitiated ? (
					<Typography
						fontSize={16}
						fontWeight={600}
						color={theme.palette.secondary.contrastText}
					>
						Connecting Call to...
					</Typography>
				) : null}

				<Stack
					alignItems="center"
					direction="row"
					spacing={2}
					justifyContent="space-between"
				>
					<Stack direction="row" alignItems="center" spacing={3}>
						<Box display="flex">
							<img
								src={Call}
								width="50px"
								height="50px"
								style={{ borderRadius: "100%" }}
							/>
						</Box>

						<Typography fontSize={13}>
							{incomingCallConnection?.parameters?.From ||
								outgoingPhoneNumber?.number}
						</Typography>
					</Stack>

					<Stack direction="row" spacing={3} alignItems="center">
						<Button
							variant="contained"
							color="error"
							disableElevation
							onClick={rejectCall}
						>
							<Box
								style={{
									transform: "rotate(135deg)",
								}}
								display="flex"
							>
								{CallIcon(20, 20, "#fff")}
							</Box>
						</Button>
					</Stack>
				</Stack>

				{isCallInprogress ? (
					<Stack direction="row" spacing={4} pt={1}>
						{callActions.map((callAction) => (
							<Stack
								key={callAction.id}
								alignItems="center"
								spacing={0.5}
								style={{ cursor: "pointer" }}
							>
								<Box display="flex">
									{" "}
									{callAction.icon(24, 24, "#fff")}
								</Box>
								<Typography fontSize={13}>
									{callAction.name}
								</Typography>
							</Stack>
						))}
					</Stack>
				) : null}
			</Stack>
		</React.Fragment>
	);
}
