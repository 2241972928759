import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import MultiSelectBaseComponent from "./MultiSelectBaseComponent";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";

export default function MultiSelectComponent({
	field,
	subField,
	inputRef,
	fieldSpecificProps: { multiSelectSx = {} } = {},
}) {
	const {
		control,
		formState: { errors },
		clearErrors,
	} = useFormContext();

	return (
		<React.Fragment>
			<Controller
				control={control}
				name={field.id + ""}
				rules={handleFieldValidation(field)}
				defaultValue=""
				render={({ field: { onChange, value } }) => (
					<MultiSelectBaseComponent
						onChange={onChange}
						value={value}
						subField={subField}
						id={field.id + ""}
						field={field}
						error={errors[field.id]}
						inputRef={inputRef}
						multiSelectSx={multiSelectSx}
						clearErrors={clearErrors}
					/>
				)}
			></Controller>
		</React.Fragment>
	);
}
