export const getBaseUrl = () => {
	return "/api";
};

export const getFieldsUrl = () => {
	return "/fields";
};

export const getContactsUrl = () => {
	return "/contact";
};

export const getSettingsUrl = () => {
	return "/settings";
};

export const getProductsUrl = () => {
	return "/product";
};

export const getCompanyUrl = () => {
	return "/contact/company";
};

export const getDealUrl = () => {
	return "/deal";
};

export const getActivityUrl = () => {
	return "/activity";
};

export const getTimelineUrl = () => {
	return "/timeline";
};

export const getActivityTypeUrl = () => {
	return "/activity/type";
};

export const getNoteUrl = () => {
	return "/note";
};

export const getFileUrl = () => {
	return "/file";
};

export const getFileStorageUrl = () => {
	return "/storage";
};

export const getTableUrl = (table) => {
	return `/${table}/list`;
};

export const getIconUrl = () => {
	return "/icon/list";
};

export const getPipelineUrl = () => {
	return "/pipeline";
};

export const getImportUrl = () => {
	return "/import";
};

export const getAccountsUrl = () => {
	return "/accounts";
};

export const getWebformUrl = () => {
	return "/webform";
};

export const getContactDuplicateUrl = () => {
	return "/contact/duplicate";
};

export const getFilterUrl = () => {
	return "/filter";
};

export const getMailUrl = () => {
	return "/email";
};

export const getNotificationsUrl = () => {
	return "/notifications";
};

export const getTeamsUrl = () => {
	return "/team";
};

export const getTagsUrl = () => {
	return "/tag";
};

export const getEntityUrl = () => {
	return "/entity";
};

export const getCalendarSyncUrl = () => {
	return "/calendar/sync";
};

export const getContactSyncUrl = () => {
	return "/contact/sync";
};

export const getTerritoryUrl = () => {
	return "/territory";
};

export const getUserUrl = () => {
	return "/user";
};

export const getRolesUrl = () => {
	return "/role";
};

export const getSearchUrl = () => {
	return "/search";
};

export const getAppUrl = () => {
	return "/app";
};

export const getNotificationUrl = () => {
	return "/notification";
};

export const getAccountSettingsUrl = () => {
	return "/account/settings";
};

export const getWorkFlowUrl = () => {
	return "/workflow";
};

export const getAnalyticsUrl = () => {
	return "/crm/analytics";
};

export const getFollowersUrl = () => {
	return "/followers";
};

export const getLifecycleStageUrl = () => {
	return "/lifecycle";
};

export const getCallUrl = () => {
	return "/call";
};

export const getPhoneUrl = () => {
	return "/phone";
};

export const headers = {
	"Access-Control-Allow-Origin": "*",
	"Content-Type": "application/json",
};
