import {
	Box,
	Button,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { default as CloseIcon } from "../../assets/icons/close";
import { default as UnKnownImage } from "../../assets/images/contact/unknownContact.png";
import { ProfileText } from "../../styles/twozo";
import { default as VectorIcon } from "../../assets/icons/vector";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../utils/notification/notificationConfig";
import { notificationMessage } from "../../utils/notification/notificationMessages";
import { resources } from "../../utils/userProfileResources";
import { useSignOutMutation } from "../../hooks/services/auth";
import { useInvalidateApp } from "../../hooks/services/common";
import { useCurrentUserDetails } from "../../hooks/services/userProfile";
import ChangePassword from "./ChangePassword";
import Dialog from "../Elements/Dialog";
import FullScreenDialog from "../Elements/FullScreenDialog";
import MyAccount from "./MyAccount";

export default function UserProfile(props) {
	const { onClose } = props;
	const theme = useTheme();
	const navigate = useNavigate();

	const { data: userDetails } = useCurrentUserDetails();

	// mutation call
	const signOutMutation = useSignOutMutation();

	// root key
	const invalidateApp = useInvalidateApp();

	const styles = {
		text: {
			fontSize: "13px",
			fontWeight: 400,
		},
	};

	const logout = () => {
		// invalidate global query key
		invalidateApp();

		signOutMutation.mutate(
			{},
			{
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.logout,
					});
					navigate("/login");
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.errorMessage,
					});
				},
			}
		);
	};

	const [isChangePasswordDialogOpened, setIsChangePasswordDialogOpened] =
		useState(false);

	const openChangePasswordDialog = () => {
		setIsChangePasswordDialogOpened(true);
	};

	const closeChangePasswordDialog = () => {
		setIsChangePasswordDialogOpened(false);
	};

	//MyAccount
	const [isMyAccountDialogOpened, setIsMyAccountDialogOpened] =
		useState(false);

	const openMyAccountDialog = () => {
		setIsMyAccountDialogOpened(true);
	};

	const closeMyAccountDialog = () => {
		setIsMyAccountDialogOpened(false);
	};

	return (
		<React.Fragment>
			<Dialog open={isChangePasswordDialogOpened} title="Change Password">
				<ChangePassword
					closeChangePasswordDialog={closeChangePasswordDialog}
				/>
			</Dialog>

			<FullScreenDialog
				open={isMyAccountDialogOpened}
				onClose={closeMyAccountDialog}
				title="My Account"
			>
				<MyAccount onClose={closeMyAccountDialog} />
			</FullScreenDialog>

			<Stack spacing={3}>
				<Box>
					<Box
						style={{
							position: "absolute",
							right: theme.spacing(2),
							marginTop: "10px",
						}}
					>
						<IconButton
							onClick={onClose}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(25, 25, theme.palette.primary.main)}
						</IconButton>
					</Box>

					<Stack width="100%" alignItems="center" p={3} spacing={1}>
						<img
							src={UnKnownImage}
							alt="profile"
							width="70px"
							height="70px"
							style={{ borderRadius: "100%" }}
						/>

						<Box style={{ textAlign: "center" }}>
							<Typography
								fontSize={16}
								fontWeight={600}
								maxWidth="20vw"
								noWrap
							>
								{userDetails?.user.firstName}{" "}
								{userDetails?.user?.lastName}
							</Typography>

							<Typography
								fontSize={14}
								fontWeight={400}
								style={{ opacity: 0.6 }}
							>
								{userDetails?.user.email}
							</Typography>
						</Box>
					</Stack>

					<Divider />

					<Stack p={1.5} spacing={2}>
						<ProfileText
							color={theme.palette.primary.main}
							onClick={openMyAccountDialog}
						>
							My Account
						</ProfileText>

						<ProfileText
							color={theme.palette.primary.main}
							onClick={openChangePasswordDialog}
						>
							Change Password
						</ProfileText>
					</Stack>

					<Divider />

					<Stack p={1.5} spacing={1}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
						>
							<Typography style={styles.text}>
								Organization
							</Typography>

							<Button variant="text">
								{userDetails?.organization.name}
							</Button>
						</Stack>

						<Stack
							direction="row"
							justifyContent="space-between"
							pr={1}
						>
							<Typography style={styles.text}>
								Subscription
							</Typography>

							<Stack
								direction="row"
								justifyContent="space-between"
								spacing={1}
							>
								<ProfileText>Free</ProfileText>
								<span style={{ opacity: 0.4 }}>&#8226;</span>

								<Stack
									direction="row"
									justifyContent="space-between"
								>
									<Box>
										{VectorIcon(
											16,
											16,
											theme.palette.primary.main
										)}
									</Box>

									<ProfileText
										color={theme.palette.primary.main}
									>
										Upgrade
									</ProfileText>
								</Stack>
							</Stack>
						</Stack>
					</Stack>

					<Divider />

					<Stack p={1.5} spacing={2}>
						{resources.map((resource, index) => (
							<Stack direction="row" spacing={2} key={index}>
								<Box display="flex">
									<IconButton style={{ padding: 0 }}>
										{resource.icon(
											20,
											20,
											theme.palette.primary.main
										)}
									</IconButton>
								</Box>

								<ProfileText color={theme.palette.primary.main}>
									{resource.text}
								</ProfileText>
							</Stack>
						))}
					</Stack>

					<Divider />
				</Box>

				<Stack alignItems="center">
					<Button
						variant="contained"
						disableElevation
						color="warning"
						onClick={logout}
					>
						Sign Out
					</Button>
				</Stack>
			</Stack>
		</React.Fragment>
	);
}
