import React, { useEffect } from "react";
import PermissionList from "../PermisionList";
import { usePermissionContext } from "../../../PermissionContext";
import {
	adminAccessIds,
	permissionTypes,
} from "../../../../../../../utils/PermissionUtils";

export default function AdminAccess(props) {
	const { permissions, isEditable, isCreateMode } = props;

	const {
		permissionState,
		updateAdminAccessList,
		initializeAdminAccessListForPermission,
	} = usePermissionContext();

	useEffect(() => {
		if (permissions) {
			initializeAdminAccessListForPermission(permissions);
		}
	}, [permissions]);

	const handleAccessChange = (event, id) => {
		if (id === adminAccessIds.accessAdminSettings) {
			if (!event.target.checked) {
				//If Access Admin Settings access is removed , need to disable the other fields in admin settings

				const updatedAdminAccessList =
					permissionState.adminAccessList.map((list) => {
						if (list.id === id) {
							const updatedList = {
								...list,
								access: event.target.checked,
							};
							return updatedList;
						}

						const updatedList = {
							...list,
							access: event.target.checked,
							accessDisable: !event.target.checked,
						};

						if (list.permissions) {
							updatedList.permissions = {
								...list.permissions,
								permission: list.permissions.permission.map(
									(permission) => ({
										...permission,
										value: event.target.checked,
										disabled: !event.target.checked,
									})
								),
							};
						}

						return updatedList;
					});

				updateAdminAccessList(updatedAdminAccessList);
			} else {
				const updatedAdminAccessList =
					permissionState.adminAccessList.map((list) => {
						if (list.id === id) {
							const updatedList = {
								...list,
								access: event.target.checked,
								accessDisable: !event.target.checked,
							};

							return updatedList;
						}
						const updatedList = {
							...list,
							accessDisable: !event.target.checked,
						};

						return updatedList;
					});

				updateAdminAccessList(updatedAdminAccessList);
			}
		} else {
			const updatedAdminAccessList = permissionState.adminAccessList.map(
				(list) => {
					if (list.id === id) {
						const updatedList = {
							...list,
							access: event.target.checked,
						};

						if (list.permissions) {
							updatedList.permissions = {
								...list.permissions,
								permission: list.permissions.permission.map(
									(permission) => ({
										...permission,
										value: event.target.checked,
										disabled: !event.target.checked,
									})
								),
							};
						}

						return updatedList;
					} else {
						return list;
					}
				}
			);
			updateAdminAccessList(updatedAdminAccessList);
		}
	};

	const onChange = (event, selectedPermission, id) => {
		if (selectedPermission.id === permissionTypes.view) {
			const updatedAdminAccessList = permissionState.adminAccessList.map(
				(list) => {
					if (list.id === id) {
						return {
							...list,
							access: event.target.checked,
							permissions: {
								...list.permissions,
								permission: list.permissions.permission.map(
									(permission) => {
										return {
											...permission,
											value: event.target.checked,
											disabled: !event.target.checked,
										};
									}
								),
							},
						};
					} else {
						return list;
					}
				}
			);
			updateAdminAccessList(updatedAdminAccessList);
		} else {
			const updatedAdminAccessList = permissionState.adminAccessList.map(
				(list) => {
					if (list.id === id) {
						return {
							...list,
							permissions: {
								...list.permissions,
								permission: list.permissions.permission.map(
									(permission) =>
										permission.id === selectedPermission.id
											? {
													...permission,
													value: event.target.checked,
												}
											: permission
								),
							},
						};
					} else {
						return list;
					}
				}
			);
			updateAdminAccessList(updatedAdminAccessList);
		}
	};

	return (
		<React.Fragment>
			<PermissionList
				isViewName="Admin Access"
				lists={permissionState.adminAccessList}
				handleAccessChange={handleAccessChange}
				onChange={onChange}
				isEditable={isEditable}
				isCreateMode={isCreateMode}
			/>
		</React.Fragment>
	);
}
