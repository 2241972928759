import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import {
	getFromAddressDropdownKey,
	getMailListKey,
	getMailDataKey,
	getMailFilterDataKey,
	getMetricsKey,
	getMetricsInsightsKey,
	getSingleConversationDataKey,
	getMailListWithFilterKey,
} from "../../../utils/queryKeys";
import {
	getMailList,
	updateMailState,
	updateScheduledMail,
	sendMail,
	getFromAddressList,
	getMailData,
	sendBulkMail,
	deleteSingleMail,
	getFilterData,
	getSingleConversationData,
	saveDraftMail,
	getMetrics,
	getMetricsInsights,
	deleteForever,
	replyMail,
	uploadFiles,
	markAsDone,
	discardDrafts,
	saveBulkDraftMail,
	forwardMail,
} from "../../../api/email";
import { mailListName } from "../../../utils/EmailUtils/mailListName";
import { useInvalidateConversationList } from "../conversations";
import { emailListPageSize } from "../../../utils/queryConstants/email";

const useMailList = (mailListName, filter, isMailListEnabled) => {
	return useInfiniteQuery(
		{
			queryKey: getMailListWithFilterKey(mailListName, filter),
			queryFn: (pageParam) =>
				getMailList(mailListName, filter, {
					start: pageParam?.pageParam
						? (pageParam?.pageParam - 1) * emailListPageSize + 1
						: 1,
					limit: emailListPageSize,
				}),
			getNextPageParam: (lastPage, allPages) => {
				return lastPage && lastPage.hasMore
					? allPages.length + 1
					: undefined;
			},
		},
		{
			enabled: isMailListEnabled,
		}
	);
};

const getFormattedFilterData = (filtersData) => {
	if (filtersData) {
		let formattedData = {};
		Object.keys(filtersData).forEach((key) => {
			if (filtersData[key]?.id) {
				formattedData[key] = filtersData[key].id;
			} else {
				formattedData[key] = filtersData[key];
			}
		});
		return formattedData;
	} else {
		return {};
	}
};

const useFilterData = (mailListName) => {
	const mailFilterDataKey = getMailFilterDataKey(mailListName);
	return useQuery(mailFilterDataKey, () => getFilterData(mailListName), {
		select: (data) => getFormattedFilterData(data.filter),
	});
};

const useSingleConversationData = (conversationId) => {
	const singleConversationDataKey =
		getSingleConversationDataKey(conversationId);
	return useQuery(
		singleConversationDataKey,
		() => getSingleConversationData(conversationId),
		{
			enabled: !!conversationId,
			select: (conversationData) => conversationData.data,
		}
	);
};

const useMailData = (mailId, isTrash) => {
	const mailDataKey = getMailDataKey(mailId);
	return useQuery(mailDataKey, () => getMailData(mailId, isTrash), {
		enabled: !!mailId,
		select: (data) => data?.thread,
	});
};

const useFromAddressDropdownList = () => {
	const fromAddressDropdownKey = getFromAddressDropdownKey();
	return useQuery(fromAddressDropdownKey, () => getFromAddressList(), {
		select: (data) => data?.fromAddresses,
	});
};

const useInvalidateMailList = (mailListName) => {
	let queryClient = useQueryClient();
	const mailListKey = getMailListKey(mailListName);
	return () => {
		queryClient.invalidateQueries(mailListKey);
	};
};

const useInvalidateDraftMailList = () => {
	let queryClient = useQueryClient();
	const mailListKey = getMailListKey(mailListName.DRAFT);
	return () => {
		queryClient.invalidateQueries(mailListKey);
	};
};

const useInvalidateSendMailList = () => {
	let queryClient = useQueryClient();
	const sendMailListKey = getMailListKey(mailListName.SENT);
	return () => {
		queryClient.invalidateQueries(sendMailListKey);
	};
};

const useInvalidateScheduledMailList = () => {
	let queryClient = useQueryClient();
	const scheduledMailListKey = getMailListKey(mailListName.SCHEDULED);
	return () => {
		queryClient.invalidateQueries(scheduledMailListKey);
	};
};

const useInvalidateTrashMailList = () => {
	let queryClient = useQueryClient();
	const trashedMailListKey = getMailListKey(mailListName.TRASHED);
	return () => {
		queryClient.invalidateQueries(trashedMailListKey);
	};
};

const useInvalidateMailData = (threadId) => {
	let queryClient = useQueryClient();
	const mailDatatKey = getMailDataKey(threadId);
	return () => {
		queryClient.invalidateQueries(mailDatatKey);
	};
};

const useInvalidateInboxMailList = () => {
	let queryClient = useQueryClient();
	const inboxMailListKey = getMailListKey(mailListName.INBOX);
	return () => {
		queryClient.invalidateQueries(inboxMailListKey);
	};
};

const useInvalidateAwaitingResponseList = () => {
	let queryClient = useQueryClient();
	const awaitingResponseListKey = getMailListKey(
		mailListName.AWAITING_RESPONSE
	);
	return () => {
		queryClient.invalidateQueries(awaitingResponseListKey);
	};
};

const useUpdateMailState = (mailListName) => {
	let invalidateMailList = useInvalidateMailList(mailListName);
	return useMutation(updateMailState, {
		onSuccess: () => {
			invalidateMailList();
		},
	});
};

const useUpdateMailStateForConversation = (sourceName, entityId) => {
	let invalidateConversationList = useInvalidateConversationList(
		sourceName,
		entityId
	);
	return useMutation(updateMailState, {
		onSuccess: () => {
			invalidateConversationList();
		},
	});
};

const useUpdateScheduledMail = (sourceName, entityId) => {
	let invalidateConversationList = useInvalidateConversationList(
		sourceName,
		entityId
	);
	let invalidateScheduledMailList = useInvalidateScheduledMailList();
	return useMutation(updateScheduledMail, {
		onSuccess: () => {
			invalidateConversationList();
			invalidateScheduledMailList();
		},
	});
};

const useInvalidateSingleConversation = (conversationId) => {
	let queryClient = useQueryClient();
	const singleConversationKey = getSingleConversationDataKey(conversationId);
	return () => {
		queryClient.invalidateQueries(singleConversationKey);
	};
};

const useMetrics = (metricId) => {
	const metricsKey = getMetricsKey(metricId);
	return useQuery(metricsKey, () => getMetrics(metricId), {
		enabled: Boolean(metricId),
		select: (data) => data.metrics,
	});
};

const useMetricsInsights = (metricId, metricType) => {
	const metricsInsightsKey = getMetricsInsightsKey(metricId, metricType);
	return useQuery(
		metricsInsightsKey,
		() => getMetricsInsights(metricId, metricType),
		{
			enabled: Boolean(metricId),
			select: (data) => data.metrics,
		}
	);
};

const useDeleteForever = () => {
	let invalidateTrashMailList = useInvalidateTrashMailList();
	return useMutation(deleteForever, {
		onSuccess: () => {
			invalidateTrashMailList();
		},
	});
};

const useForwardMail = (mailId, mailListName, sourceName, entityId) => {
	const invalidateMailList = useInvalidateMailList(mailListName);
	const invalidateMailData = useInvalidateMailData(mailId);
	const invalidateConversationList = useInvalidateConversationList(
		sourceName,
		entityId
	);
	return useMutation(forwardMail, {
		onSuccess: () => {
			invalidateMailList();
			invalidateMailData();
			invalidateConversationList();
		},
	});
};

const useSendMail = (sourceName, entityId) => {
	const invalidateSendMailList = useInvalidateSendMailList();
	let invalidateScheduledMailList = useInvalidateScheduledMailList();
	let invalidateConversationList = useInvalidateConversationList(
		sourceName,
		entityId
	);
	return useMutation(sendMail, {
		onSuccess: () => {
			invalidateSendMailList();
			invalidateScheduledMailList();
			invalidateConversationList();
		},
	});
};

const useSaveDraftMail = (
	sourceName,
	entityId,
	threadId,
	conversationId,
	mailListName
) => {
	const invalidateDraftMailList = useInvalidateDraftMailList();
	let invalidateConversationList = useInvalidateConversationList(
		sourceName,
		entityId
	);
	let invalidateMailData = useInvalidateMailData(threadId);
	let invalidateSingleConversation =
		useInvalidateSingleConversation(conversationId);
	let invalidateMailList = useInvalidateMailList(mailListName);
	return useMutation(saveDraftMail, {
		onSuccess: () => {
			invalidateDraftMailList();
			invalidateConversationList();
			invalidateMailData();
			invalidateSingleConversation();
			invalidateMailList();
		},
	});
};

const useSaveBulkDraftMail = () => {
	return useMutation(saveBulkDraftMail, {
		onSuccess: () => {},
	});
};

const useSendBulkMail = () => {
	const invalidateSendMailList = useInvalidateSendMailList();
	return useMutation(sendBulkMail, {
		onSuccess: () => {
			invalidateSendMailList();
		},
	});
};

const useDeleteMail = (mailListName, threadId, sourceName, entityId) => {
	const invalidateMailList = useInvalidateMailList(mailListName);
	const invalidateMailData = useInvalidateMailData(threadId);
	const invalidateConversationList = useInvalidateConversationList(
		sourceName,
		entityId
	);
	return useMutation(deleteSingleMail, {
		onSuccess: () => {
			invalidateMailList();
			invalidateMailData();
			invalidateConversationList();
		},
	});
};

const useReplyMail = (mailId, mailListName, sourceName, entityId) => {
	const invalidateMailData = useInvalidateMailData(mailId);
	const invalidateMailList = useInvalidateMailList(mailListName);
	const invalidateConversationList = useInvalidateConversationList(
		sourceName,
		entityId
	);
	return useMutation(replyMail, {
		onSuccess: () => {
			invalidateMailData();
			invalidateMailList();
			invalidateConversationList();
		},
	});
};

const useUploadFilesFromEmail = () => {
	return useMutation(uploadFiles, {
		onSuccess: () => {},
	});
};

const useMarkAsDone = () => {
	const invalidateAwaitingResponseList = useInvalidateAwaitingResponseList();
	const invalidateInboxMailList = useInvalidateInboxMailList();
	return useMutation(markAsDone, {
		onSuccess: () => {
			invalidateAwaitingResponseList();
			invalidateInboxMailList();
		},
	});
};

const useDiscardDraftsMail = (mailListName, threadId, sourceName, entityId) => {
	const invalidateMailList = useInvalidateMailList(mailListName);
	const invalidateMailData = useInvalidateMailData(threadId);
	const invalidateConversationList = useInvalidateConversationList(
		sourceName,
		entityId
	);
	return useMutation(discardDrafts, {
		onSuccess: () => {
			invalidateMailData();
			invalidateMailList();
			invalidateConversationList();
		},
	});
};

export {
	useMailList,
	useInvalidateMailList,
	useUpdateMailState,
	useUpdateScheduledMail,
	useUpdateMailStateForConversation,
	useFromAddressDropdownList,
	useSaveDraftMail,
	useSendMail,
	useMailData,
	useSendBulkMail,
	useDeleteMail,
	useFilterData,
	useMetrics,
	useMetricsInsights,
	useDeleteForever,
	useSingleConversationData,
	useReplyMail,
	useUploadFilesFromEmail,
	useMarkAsDone,
	useDiscardDraftsMail,
	useSaveBulkDraftMail,
	useForwardMail,
	useInvalidateMailData,
};
